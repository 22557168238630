import axios from "axios";

axios.defaults.headers.common["Content-Type"] = "application/json";

const AxiosService = () => {
  let AuthorizationToken = null;

  function addHeaders(userConfig) {
    let globalHeaders = {
      "X-Source": "ninja-influence"
    };
    // You can set global headers here
    if (!AuthorizationToken) {
      AuthorizationToken = window.localStorage.getItem("access_token");
    }
    globalHeaders["Authorization"] = `Bearer ${AuthorizationToken}`;

    const emulatingUser = window.sessionStorage.getItem("emulating_user_id");
    if (emulatingUser) {
      globalHeaders["X-NinjaInfluence-Emulating-User"] = emulatingUser;
    }

    const masterUserId = window.localStorage.getItem("master_user_id");
    if (masterUserId) {
      globalHeaders["X-Team-Master-Id"] = masterUserId;
    }

    const { headers } = userConfig;
    // Return extended config
    return {
      headers: {
        ...globalHeaders,
        ...headers
      }
    };
  }

  // Set authorization token
  function setAuthorizationToken(token) {
    AuthorizationToken = token;
  }

  // GET method
  function get(endPoint, userConfig = {}) {
    return axios.get(endPoint, addHeaders(userConfig));
  }

  // POST method
  function post(endPoint, params = {}, userConfig = {}) {
    return axios.post(endPoint, params, addHeaders(userConfig));
  }

  // PUT method
  function put(endPoint, params = {}, userConfig = {}) {
    return axios.put(endPoint, params, addHeaders(userConfig));
  }

  // Patch method
  function patch(endPoint, params = {}, userConfig = {}) {
    return axios.patch(endPoint, params, addHeaders(userConfig));
  }

  function del(endPoint, userConfig = {}) {
    return axios.delete(endPoint, addHeaders(userConfig));
  }

  return {
    setAuthorizationToken,
    get,
    post,
    put,
    patch,
    del
  };
};

// let's return back our create method as the default.
export default AxiosService();

