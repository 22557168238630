const CURRENCY_SYMBOLS = {
  AED: "$",
  AFN: "؋",
  ALL: "$",
  AMD: "AMD",
  ANG: "ƒ",
  AOA: "$",
  ARS: "$",
  AUD: "$",
  AWG: "$",
  AZN: "ман",
  BAM: "KM",
  BBD: "$",
  BDT: "$",
  BGN: "лв",
  BHD: "$",
  BIF: "$",
  BMD: "$",
  BND: "$",
  BOB: "$b",
  BRL: "R$",
  BSD: "$",
  BTN: "$",
  BWP: "P",
  BYN: "$",
  BZD: "BZ$",
  CAD: "$",
  CDF: "$",
  CHF: "CHF",
  CLP: "$",
  CNH: "$",
  CNT: "$",
  CNY: "¥",
  COP: "$",
  CRC: "₡",
  CUC: "₱",
  CUP: "₱",
  CVE: "$",
  CZK: "Kč",
  DJF: "$",
  DKK: "kr",
  DOP: "RD$",
  DZD: "$",
  EGP: "£",
  ERN: "$",
  ETB: "$",
  EUR: "€",
  FJD: "$",
  FKP: "£",
  GBP: "£",
  GEL: "$",
  GGP: "$",
  GHS: "$",
  GIP: "£",
  GMD: "$",
  GNF: "$",
  GTQ: "Q",
  GYD: "$",
  HKD: "$",
  HNL: "L",
  HRK: "kn",
  HTG: "$",
  HUF: "Ft",
  IDR: "Rp",
  ILS: "₪",
  IMP: "$",
  INR: "$",
  IQD: "$",
  IRR: "﷼",
  ISK: "kr",
  JEP: "$",
  JMD: "J$",
  JOD: "$",
  JPY: "¥",
  KES: "лв",
  KGS: "$",
  KHR: "៛",
  KID: "$",
  KMF: "$",
  KPW: "₩",
  KRW: "₩",
  KWD: "$",
  KYD: "$",
  KZT: "лв",
  LAK: "₭",
  LBP: "£",
  LKR: "₨",
  LRD: "$",
  LSL: "$",
  LYD: "$",
  MAD: "$",
  MDL: "$",
  MGA: "$",
  MKD: "ден",
  MMK: "$",
  MNT: "₮",
  MOP: "$",
  MRO: "$",
  MUR: "₨",
  MVR: "$",
  MWK: "$",
  MXN: "MT",
  MYR: "RM",
  MZN: "$",
  NAD: "$",
  NGN: "₦",
  NIO: "C$",
  NIS: "$",
  NOK: "kr",
  NPR: "₨",
  NTD: "$",
  NZD: "$",
  OMR: "﷼",
  PAB: "B/.",
  PEN: "S/.",
  PGK: "$",
  PHP: "Php",
  PKR: "₨",
  PLN: "zł",
  PRB: "$",
  PYG: "Gs",
  QAR: "﷼",
  RON: "lei",
  RSD: "Дин.",
  RUB: "руб",
  RWF: "$",
  SAR: "﷼",
  SBD: "$",
  SCR: "₨",
  SDG: "$",
  SEK: "kr",
  SGD: "$",
  SHP: "£",
  SLL: "$",
  SLS: "$",
  SOS: "S",
  SRD: "$",
  SSP: "$",
  STD: "$",
  SVC: "$",
  SYP: "£",
  SZL: "$",
  THB: "฿",
  TJS: "$",
  TMT: "$",
  TND: "$",
  TOP: "$",
  TRY: "TL",
  TTD: "TT$",
  TVD: "$",
  TWD: "NT$",
  TZS: "$",
  UAH: "₴",
  UGX: "$",
  USD: "$",
  UYU: "$U",
  UZS: "лв",
  VEF: "Bs",
  VND: "₫",
  VUV: "$",
  WST: "$",
  XAF: "$",
  XCD: "$",
  XOF: "$",
  XPF: "$",
  YER: "﷼",
  ZAR: "R",
  ZMW: "$",
  ZWL: "$"
};
export default CURRENCY_SYMBOLS;