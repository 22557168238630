<template>
  <ul class="filter-nav filter-nav-main my-2">
    <li class="flex-column">
      <div class="brand-container flex-middle">
        <router-link class="navbar-brand" to="/">
          <img src="/app/img/brand/logo-black.png" height="60" class="navbar-brand-img my-4" alt="Ninja Influence">
        </router-link>
      </div>
    </li>
    <li v-if="emulatingUser && emulatingUser.email" class="nav-item nav-item--emulating flex-middle">
      <span class="mr-3">Emulating user {{emulatingUser.email}}</span>
      <button class="btn btn-stop-emulating" @click="stopEmulatingUser">Stop</button>
    </li>
    <li class="nav-item flex-middle">
      <router-link class="nav-link nav-link-icon py-3 px-4 w-100" to="/app/influencers">
        <i class="fas fa-search mr-2"></i>
        <span class="nav-link-inner--text">Search Influencers</span>
      </router-link>
    </li>
    <li v-if="hasAccess('campaigns:view')" class="nav-item flex-middle">
      <router-link class="nav-link nav-link-icon py-3 px-4 w-100" to="/app/campaigns">
        <i class="fas fa-bullhorn mr-2"></i>
        <span class="nav-link-inner--text">Campaigns</span>
      </router-link>
    </li>
    <li v-if="hasAccess('campaigns:view') && hasAccess('emails:view')" class="nav-item flex-middle">
      <router-link class="nav-link nav-link-icon py-3 px-4 w-100" to="/app/inbox">
        <i class="fas fa-envelope mr-2"></i>
        <span class="nav-link-inner--text">Inbox</span>
      </router-link>
    </li>
    <li v-if="hasAccess('integrations:view')" class="nav-item flex-middle">
      <router-link class="nav-link nav-link-icon py-3 px-4 w-100" to="/app/settings/integrations">
        <img src="/app/img/icons/common/shopping-basket.svg"
             class="nav-link-inner--icon mr-2" style="margin-left:-.3rem">
        <span class="nav-link-inner--text">E-commerce integrations</span>
      </router-link>
    </li>
    <li v-if="hasAccess('integrations:view')" class="nav-item flex-middle">
      <router-link class="nav-link nav-link-icon py-3 px-4 w-100" to="/app/settings/email">
        <img src="/app/img/icons/common/email.svg"
             class="nav-link-inner--icon mr-2" style="margin-left:-.2rem">
        <span class="nav-link-inner--text">Connect emails</span>
      </router-link>
    </li>
    <li class="nav-item flex-middle">
      <router-link class="nav-link nav-link-icon py-3 px-4 w-100" to="/app/settings">
        <i class="fas fa-cog mr-2"></i>
        <span class="nav-link-inner--text">Account</span>
      </router-link>
    </li>
    <li v-if="authUser.is_admin" class="nav-item flex-middle">
      <router-link class="nav-link nav-link-icon py-3 px-4 w-100" to="/app/admin">
        <i class="fas fa-user-cog mr-2"></i>
        <span class="nav-link-inner--text">Admin</span>
      </router-link>
    </li>

    <li class="nav-item nav-item--logout flex-middle">
      <a
        href="#"
        class="nav-link nav-link-icon py-3 px-4 w-100"
        @click="logOut"
      >
        <i class="fas fa-sign-out-alt mr-2"></i>
        <span class="nav-link-inner--text">Sign out</span>
      </a>
    </li>
  </ul>
</template>
<script>
import Configs from "../configs";
import { mapState, mapGetters } from "vuex";

export default {
  name: "left-nav",
  props: {
    logo: {
      type: String,
      default: "/app/img/icons/mstile-150x150.png",
      description: "filterbar app logo"
    },
  },
  computed: {
    ...mapState({
      authUser: state => state.user.authUser.is_admin,
      emulatingUser: state => state.user.emulatingUser,
    }),
    ...mapGetters({
      hasAccess: 'user/hasAccess',
    }),
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  data() {
    return {
      configs: Configs
    };
  },
  methods: {
    logOut() {
      this.$store.dispatch("user/signOut");
      setTimeout(() => {
        window.location.href = "/app";
      }, 100);
    },
    stopEmulatingUser() {
      this.$store.dispatch("user/stopEmulatingUser");
      this.$router.push("/app/admin");
    }
  }
};
</script>
<style lang="scss">
</style>
