import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

const AuthLayout = () => import("@/layout/AuthLayout");

Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/app/login",
      component: AuthLayout,
      children: [
        {
          path: "/app/login",
          name: "login",
          component: () => import("../../views/Login.vue"),
          beforeEnter: (to, from, next) => {
            if (store.state.user.isLoggedIn) {
              const shop = to.query.shop || store.state.user.authUser.shop;
              let path = shop ? `/app/settings/integrations/shopify?shop=${shop}&state=${store.state.user.authUser.state}` : "/app/influencers";
              location.href = path;
            } else {
              next();
            }
          }
        }
      ]
    },
    {
      path: "/app/register",
      component: AuthLayout,
      children: [
        {
          path: "/app/register",
          name: "register",
          component: () => import("../../views/Signup.vue")
        },
        {
          path: "/app/register_forgot",
          name: "register_forgot",
          component: () => import("../../views/Forgot.vue")
        },
        {
          path: "/app/register_add_password",
          name: "addPassword",
          component: () => import("../../views/AddPassword.vue")
        },
        {
          path: "/app/register/:store_id",
          name: "register_store",
          component: () => import("../../views/Register.vue"),
          props: true
        }
      ]
    },
    {
      path: "/app/signup",
      component: AuthLayout,
      children: [
        {
          path: "/app/signup",
          name: "signup",
          component: () => import("../../views/Signup.vue")
        },
      ]
    }
  ]
});
