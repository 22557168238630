<template>
  <avatar
    :username="username"
    :size="size"
    :src="imageUrl">
  </avatar>
</template>
<script>
import Avatar from "vue-avatar";

export default {
  name: "base-avatar",
  components: {
    Avatar,
  },
  props: {
    size: {
      type: Number,
      default: 36,
    },
    username: {
      type: String,
      default: "No name",
    },
    src: {
      type: String,
    }
  },
  data() {
    return {
      imageUrl: null,
    }
  },
  mounted() {
    this.getLeadPictureUrl();
  },
  methods: {
    getLeadPictureUrl() {
      let img = new Image();
      img.onload = () => {
        this.imageUrl = this.src;
      };
      img.src = this.src;
    },
  }
};
</script>