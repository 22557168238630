/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import Vue from "vue";

import ajax from "../helpers";
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import { MESSAGES } from "../../constants";

const state = {
  isLoggedIn: window.localStorage.hasOwnProperty("access_token"),
  completedCheckout: window.localStorage.hasOwnProperty("completedCheckout"),
  authUser: {
    access_token: "" || window.localStorage.getItem("access_token"),
    user_id: null || window.localStorage.getItem("user_id"),
    email: "" || window.localStorage.getItem("email"),
    is_admin: false,
    master_user_id: null,
    scopes: JSON.parse(window.localStorage.getItem("scopes") || "[]"),
    api_key: null,
    shop: window.localStorage.getItem("shop"),
    state: window.localStorage.getItem("state"),
    shopify_success: window.localStorage.getItem("shopify_success"),
    default_currency: "USD",
    default_imv_coef: "0",
    active_campaigns: 0,
    max_active_campaigns: 0,
    credits: 0,
    // exports_allowed: true,
    refill_enabled: false,
    refill_credit_threshold: null,
    refill_credit_amount: null,
    userAccount: {
      fullName: "",
      phone: "",
      company: "",
      country: null,
      state: null
    },
    role: null
  },
  emulatingUser: {
    id: "" || window.sessionStorage.getItem("emulating_user_id"),
    email: "" || window.sessionStorage.getItem("emulating_user_email"),
    credits: "" || window.sessionStorage.getItem("emulating_user_credits"),
    customer_id: "" || window.sessionStorage.getItem("emulating_user_customer_id"),
    default_currency: "" || window.sessionStorage.getItem("emulating_user_default_currency"),
    max_active_campaigns: "" || window.sessionStorage.getItem("emulating_user_max_active_campaigns"),
    active_campaigns: "" || window.sessionStorage.getItem("emulating_user_active_campaigns"),
    plan_id: "" || window.sessionStorage.getItem("emulating_user_plan_id"),
  },
  subUsers: [],
  currencies: [],
  route: "/",
  countries: [],
  states: {
    usStates: [],
    canadaProvincies: [],
    australiaStates: []
  }
};

const getters = {
  hasViewAccess(state) {
    return scope => (state.authUser.scopes.includes(`${scope}:full`) || state.authUser.scopes.includes(`${scope}:view`));
  },
  hasFullAccess(state) {
    return scope => state.authUser.scopes.includes(`${scope}:full`);
  },
  hasAccess(state, getters) {
    return scopeString => {
      const scopeArray = scopeString && scopeString.split(":");
      if (!scopeArray || scopeArray.length < 2) return true;
      const scope = scopeArray[0];
      const access = scopeArray[1];
      return access === "full" ? getters.hasFullAccess(scope) : getters.hasViewAccess(scope);
    };
  }
};

const actions = {
  setRoute({ commit }, payload) {
    commit("SET_ROUTE", payload);
  },
  async signIn({ commit, dispatch }, payload) {
    try {
      const { data } = await ajax.post("/api/auth", payload);
      const dataWithShopAndState = { ...data, shop: payload.shop, state: payload.state, shopify_success: payload.shopify_success };
      commit("LOG_IN", dataWithShopAndState);
      return dataWithShopAndState;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async isCheckoutCompleted({ commit, dispatch }, payload) {
    try {
      const { data } = await ajax.get("/api/user/is_checkout_completed");
      const is_checkout_completed = data["is_checkout_completed"]
      if (is_checkout_completed) {
        commit("COMPLETE_CHECKOUT");
      }
      return is_checkout_completed;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async signUp({ dispatch }, payload) {
    try {
      const { data } = await ajax.post("/api/user/register", payload);
      return await dispatch("signIn", {
        email: payload.email,
        password: payload.password,
        shop: payload.shop,
        state: payload.state,
        shopify_success: data.shopify_success ? 1 : "",
      });
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async signUpNew({ dispatch }, payload) {
    try {
      const { data } = await ajax.post("/api/user/signup", payload);
      return await dispatch("signIn", {
        email: payload.email,
        password: payload.password,
        shop: payload.shop,
        state: payload.state,
        shopify_success: data.shopify_success ? 1 : "",
      });
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async forgotPassword({ dispatch }, payload) {
    const email = payload.email;
    try {
      const { data } = await ajax.get("/api/user/forget_password?email=" + email);
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return err;
    }
  },
  async forgetPasswordCode({ dispatch }, payload) {
    try {
      const { data } = await ajax.post("/api/user/forget_password_code", payload);
      console.log('response forget_password_code', data);
    } catch (err) {
      //if got error redirect to  the previous page
      console.log('catch err forget_password_code', err)
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async addNewPassword({ dispatch }, payload) {
    try {
      const { data } = await ajax.post("/api/user/new_password", payload);

      console.log('payload', payload);
      console.log('response addNewPassword', data);

      const res_end = dispatch("signIn", {
        email: payload.email,
        token: payload.recaptcha_token,
        password: payload.password,
        shop: payload.shop,
        state: payload.state,
        shopify_success: data.shopify_success ? 1 : "",
      });
    } catch (err) {
      //if got error redirect to  the previous page
      console.log('catch err addNewPassword', err)
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async checkAuthMe({ commit, dispatch }) {
    try {
      const { status, data } = await ajax.get("/api/user/current");
      if (status === 200) {
        commit("SET_AUTH", data);
        if (window.userpilot) {
          window.userpilot.identify(
            data.id,
            {
              email: data.email
            }
          );
        }

        return data;
      }
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async searchUsers({ dispatch }, payload) {
    try {
      const { data } = await ajax.get("/api/admin/users?query=" + payload);
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async getUserDetail({ dispatch }, payload) {
    try {
      const { data } = await ajax.get("/api/admin/users/" + payload);
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async setUserDetail({ dispatch }, payload) {
    try {
      const { id, updates } = payload;
      const { data } = await ajax.post("/api/admin/users/" + id, updates);
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async loadCountries({ dispatch, commit }, payload) {
    try {
      // if (state.countries.length !== 0)
      //   return;
      const { data } = await ajax.get("/api/user/countries_and_states");
      commit("SET_COUNTRIES", data);
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async updatePassword({ dispatch }, payload) {
    const { data } = await ajax.post("api/user/update_password", payload);
    return data;
  },
  startEmulatingUser({ commit }, payload) {
    commit("SET_EMULATING_USER", payload);
  },
  stopEmulatingUser({ commit }) {
    commit("SET_EMULATING_USER", {});
  },
  signOut({ commit }) {
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("user_id");
    window.localStorage.removeItem("email");
    window.localStorage.removeItem("completedCheckout");
    commit("SET_EMULATING_USER", {});
    commit("LOG_OUT");
  },

  async setCustomizations({ commit, dispatch }, payload) {
    try {
      const result = await ajax.post("/api/user/customizations", payload);
      if (result.data) {
        commit("SET_CUSTOMIZATIONS", result.data);
      }
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async getCurrenciesList({ commit, dispatch }) {
    try {
      const { data } = await ajax.get("/api/user/currencies");
      let currenciesList = [];
      Object.keys(data).forEach(code => {
        currenciesList.push({ code: code, description: data[code] });
      });
      commit("SET_CURRENCIES", currenciesList);
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async getCurrentPlan({ dispatch }) {
    try {
      const result = await ajax.get("/api/user/active_plan", { "id": this.user_id});
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async getCreditUsage({ dispatch }, payload) {
    try {
      const { start, end } = payload;
      const { data } = await ajax.get(`/api/billing/credit_usage?start=${encodeURIComponent(start)}&end=${encodeURIComponent(end)}`);

      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },

  async getRefillPrefs({ dispatch }) {
    try {
      const result = await ajax.get("/api/user/refill");
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async updateRefillPrefs({ dispatch }, payload) {
    try {
      const result = await ajax.post("/api/user/refill", payload);
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async getSubUsers({ dispatch, commit }) {
    try {
      const { data } = await ajax.get("/api/user/team");

      commit("SET_SUB_USERS", data);
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async deleteSubUser({ dispatch, commit }, payload) {
    try {
      const { data } = await ajax.del(`/api/user/team/${payload}`);
      commit("DELETE_SUB_USER", payload);
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async inviteSubUser({ dispatch, commit }, payload) {
    try {
      const { data } = await ajax.post(`/api/user/team/invite`, payload);

      //commit("ADD_SUB_USER", data);

      dispatch("getSubUsers");

      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      throw err;
    }
  },
  async updateSubUserNew({ dispatch, commit }, payload) {
    try {

      const requestPayload = {
        members_to_update: [
          payload
        ]
      };
      const { data } = await ajax.put(`/api/user/team/update`, requestPayload);

      dispatch("getSubUsers");
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      throw err;
    }
  },
  async getAccount({ dispatch, commit }, payload) {
    try {
      const { data } = await ajax.get("/api/user/get_account");
      commit("RECEIVED_USER_ACCOUNT", {
        fullName: data.username,
        phone: data.phone_number,
        company: data.company_name,
        country: data.country && data.country.alpha_2,
        state: data.state && data.state.code
      });

    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async setAccount({ dispatch, commit }, payload) {
    try {
      let requestPayload = {
        fullName: payload.fullName,
        phone: payload.phone,
        company: payload.company,
        country: payload.country && payload.country.alpha2,
        state: payload.state && payload.state.code
      }
      const { data } = await ajax.post(`/api/user/set_account`, requestPayload);

      dispatch("setAlert", {
        showAlert: true,
        content: MESSAGES["settings__user_update-success"],
        alertClass: "success"
      }, { root: true });
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async updateSubUser({ dispatch, commit }, payload) {
    try {
      const { id } = payload;
      const { data } = await ajax.put(`/api/user/team/${encodeURIComponent(id)}`, payload);
      commit("UPDATE_SUB_USER", payload);
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  setCurrencies({ commit }, payload) {
    commit("SET_CURRENCIES", payload);
  }
};

const mutations = {
  COMPLETE_CHECKOUT(state, payload) {
    window.localStorage.setItem("completedCheckout", true);
    state.completedCheckout = true;
  },
  LOG_IN(state, payload) {
    if (payload.hasOwnProperty("access_token")) {
      window.localStorage.setItem("access_token", payload.access_token);
      ajax.setAuthorizationToken(payload.access_token);

      state.authUser.access_token = payload.access_token;
      state.isLoggedIn = true;
    }
    if (payload.hasOwnProperty("shop")) {
      state.authUser.shop = payload.shop;
      window.localStorage.setItem("shop", payload.shop ? payload.shop : '');
    }
    if (payload.hasOwnProperty("state")) {
      state.authUser.state = payload.state;
      window.localStorage.setItem("state", payload.state ? payload.state : '');
    }
    if (payload.hasOwnProperty("shopify_success")) {
      state.authUser.state = payload.state;
      window.localStorage.setItem("shopify_success", payload.shopify_success ? 1 : '');
    }
    if (payload.hasOwnProperty("id")) {
      state.authUser.user_id = payload.id;
      window.localStorage.setItem("user_id", payload.id);
    }
    if (payload.hasOwnProperty("email")) {
      state.authUser.email = payload.email;
      window.localStorage.setItem("email", payload.email);
    }
    if (payload.hasOwnProperty("as_user")) {
      state.authUser.as_user = payload.email;
      window.localStorage.setItem("email", payload.email);
    }
  },
  LOG_OUT(state) {
    state.isLoggedIn = "";
    state.authUser = {
      access_token: "",
      user_id: null,
      email: "",
    };
    state.completedCheckout = null
  },
  SET_AUTH(state, payload) {
    if (payload.hasOwnProperty("id")) {
      state.authUser.user_id = payload.id;
      window.localStorage.setItem("user_id", payload.id);
    }
    if (payload.hasOwnProperty("email")) {
      state.authUser.email = payload.email;
      window.localStorage.setItem("email", payload.email);
    }
    if (payload.hasOwnProperty("master_user_id")) {
      state.authUser.master_user_id = payload.master_user_id;
      window.localStorage.setItem("master_user_id", payload.master_user_id);
    }
    if (payload.hasOwnProperty("role")) {
      state.authUser.role = payload.role;
    }
    if (payload.hasOwnProperty("scopes")) {
      state.authUser.scopes = payload.scopes || [];
      //state.authUser.scopes = ["emails:full", "integrations:full", "billing:full", "campaigns:full", "team:full", "search:full"]; // for test
      window.localStorage.setItem("scopes", JSON.stringify(state.authUser.scopes));
    }
    if (payload.hasOwnProperty("is_admin")) {
      state.authUser.is_admin = payload.is_admin;
      window.localStorage.setItem("is_admin", payload.is_admin);
    }
    if (payload.hasOwnProperty("shop")) {
      state.authUser.shop = payload.shop;
    }
    if (payload.hasOwnProperty("state")) {
      state.authUser.state = payload.state;
    }
    if (payload.hasOwnProperty("shopify_success")) {
      state.authUser.shopify_success = payload.shopify_success;
    }
    if (payload.hasOwnProperty("active_campaigns")) {
      state.authUser.active_campaigns = payload.active_campaigns;
    }
    if (payload.hasOwnProperty("credits")) {
      state.authUser.credits = payload.credits;
    }
    // if (payload.hasOwnProperty("exports_allowed")) {
    //   state.authUser.exports_allowed = payload.exports_allowed;
    // }
    if (payload.hasOwnProperty("default_currency")) {
      state.authUser.default_currency = payload.default_currency;
    }
    if (payload.hasOwnProperty("max_active_campaigns")) {
      state.authUser.max_active_campaigns = payload.max_active_campaigns;
    }
    if (payload.hasOwnProperty("refill_credit_amount")) {
      state.authUser.refill_credit_amount = payload.refill_credit_amount;
    }
    if (payload.hasOwnProperty("refill_credit_threshold")) {
      state.authUser.refill_credit_amount = payload.refill_credit_threshold;
    }
    if (payload.hasOwnProperty("refill_enabled")) {
      state.authUser.refill_credit_amount = payload.refill_enabled;
    }
    if (payload.hasOwnProperty("default_imv_coef")) {
      state.authUser.default_imv_coef = payload.default_imv_coef;
    }

  },
  SET_CURRENCIES(state, payload) {
    state.currencies = payload;
  },
  SET_CUSTOMIZATIONS(state, payload) {
    state.default_currency = payload.default_currency;
    state.default_imv_coef = payload.default_imv_coef;
  },
  SET_EMULATING_USER(state, payload) {
    state.emulatingUser = payload;
    if (payload && payload.id) {
      window.sessionStorage.setItem("emulating_user_id", payload.id);
      window.sessionStorage.setItem("emulating_user_email", payload.email);
      window.sessionStorage.setItem("emulating_user_credits", payload.id);
      window.sessionStorage.setItem("emulating_user_customer_id", payload.id);
      window.sessionStorage.setItem("emulating_user_default_currency", payload.email);
      window.sessionStorage.setItem("emulating_user_max_active_campaigns", payload.id);
      window.sessionStorage.setItem("emulating_user_active_campaigns", payload.email);
      window.sessionStorage.setItem("emulating_user_plan_id", payload.id);
    } else {
      window.sessionStorage.removeItem("emulating_user_id");
      window.sessionStorage.removeItem("emulating_user_email");
      window.sessionStorage.removeItem("emulating_user_credits");
      window.sessionStorage.removeItem("emulating_user_customer_id");
      window.sessionStorage.removeItem("emulating_user_default_currency");
      window.sessionStorage.removeItem("emulating_user_max_active_campaigns");
      window.sessionStorage.removeItem("emulating_user_active_campaigns");
      window.sessionStorage.removeItem("emulating_user_plan_id");
    }
  },
  SET_SUB_USERS(state, payload) {
    //Vue.set(state, "subUsers", payload);
    state.subUsers = payload;
  },
  ADD_SUB_USER(state, payload) {
    state.subUsers.push(payload);
  },
  UPDATE_SUB_USER(state, payload) {
    const { id, scopes } = payload;
    const index = state.subUsers.map(e => e.id).indexOf(id);
    if (index > -1) {
      state.subUsers[index] = { ...state.subUsers[index], scopes };
    }
    state.subUsers = [...state.subUsers];
  },
  DELETE_SUB_USER(state, payload) {
    const index = state.subUsers.map(e => e.id).indexOf(payload);
    if (index > -1) {
      state.subUsers.splice(index, 1);
    }
  },
  RECEIVED_USER_ACCOUNT(state, payload) {
    state.authUser.userAccount.fullName = payload.fullName;
    state.authUser.userAccount.phone = payload.phone;
    state.authUser.userAccount.company = payload.company;
    state.authUser.userAccount.country = payload.country;
    state.authUser.userAccount.state = payload.state;
  },
  SET_COUNTRIES(state, payload) {
    state.countries = payload.countries;
    state.states.usStates = payload.us_states;
    state.states.canadaProvincies = payload.canada_provinces;
    state.states.australiaStates = payload.australia_states;
  },
  SET_ROUTE(state, payload) {
    if (state.route !== payload) {
      if (window.userpilot) {
        window.userpilot.reload();
      }
      if (window.UE) {
        window.UE.pageHit({
          apiKey: "1g9V2L",
          email: state.authUser.email,
          user_id: state.authUser.user_id,
          "Ninja Influence user": true,
        })
      }
      state.route = payload;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
