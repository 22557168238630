module.exports = {
  services: {
    google: {
      analytics_id: "UA-52626549-10",
      client_id: process.env.VUE_APP_GOOGLE_SIGN_IN_CLIENT_ID
    },
    outlook: {
      client_id: process.env.VUE_APP_OUTLOOK_OAUTH2_CLIENT_ID
    },
    ecwid: {
      client_id: "ninjaoutreach-dev"
    },
    shopify: {
      client_id: "3e5ade5a44caa959f5eb57efd5bd12aa"
    },
    "3dcart": {
      client_id: "7226a5a19eee70f5a2904bfbfe48c8ea"
    },
    bigcartel: {
      client_id: "BjViQEHNkHGPKc3M0YGksuzVjEABR4"
    },
    slack: {
      client_id: "497302805011.1097748565111",
    },
    stripe: {
      public_key: process.env.VUE_APP_STRIPE_PUBLIC_KEY
    }
  },
  site: {
    name: "Ninja Influence",
    logo: {
      title: "Ninja Influence",
    },
    url: process.env.VUE_APP_SITE_URL ? process.env.VUE_APP_SITE_URL : "app.ninjainfluence.com",
    inbox: {
      refresh_timeout: 60, // in second
    }
  }
};
