import Vue from "vue";
import VueGtm from '@gtm-support/vue2-gtm';
import { datadogRum } from '@datadog/browser-rum';
import App from "./App.vue";
import store from "@/store";
import router from "./router";
import NinjaOutreachXAuth from "../../plugins/NinjaOutreachXAuth";

Vue.config.productionTip = false;

Vue.use(NinjaOutreachXAuth);

const enableGtm = process.env.VUE_APP_ENABLE_GTM === "true";
Vue.use(VueGtm, {
  id: 'GTM-TPBTWCJ',
  enabled: enableGtm,
  debug: true,
  vueRouter: router,
  parentElement: document.head,
  loadScript: true,
});

const enableDatadog = process.env.VUE_APP_ENABLE_DATADOG === "true";
if (enableDatadog) {
  datadogRum.init({
    applicationId: '734f48a2-ff2f-4869-a5f5-b222e87fe174',
    clientToken: 'pub545a794fe1dc6dcbddf1d48917d008f3',
    site: 'datadoghq.com',
    service: 'ninjainfluence',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
  });

  datadogRum.startSessionReplayRecording();
}

let auth = false;
if (store.state.user.isLoggedIn) {
  auth = true;
} else {
  auth = false;
}

router.beforeEach((to, from, next) => {
  if (auth) {
    location.href = "/app/influencers";
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
