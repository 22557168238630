export class SortValue {
    constructor(sortItem, direction) {
        this.sortItem = sortItem;
        this.direction = direction;
    }

    getNext() {
        if (this.direction === SortDirection.Ascending)
            return new SortValue(this.sortItem, SortDirection.Descending)
        else
            return undefined;
    }
}

const BULK_MAX_LEADS_COUNT = 100;
const BULK_MAX_SEARCH_RESULT = 30;

export const SortDirection = {
    Ascending: "ascending",
    Descending: "descending"
}

export class SortItem {
    constructor(label, value) {
        this.label = label;
        this.value = value;
    }
}

export const WP_ORDER_BY_ITEMS = [
    new SortItem("Message Date", "messageDate"),
    new SortItem("Campaign", "campaign"),
    new SortItem("Status", "status"),
    new SortItem("Coupon", "coupon"),
];

export const WP_FILTER_BY_ITEMS = [
    { label: "All", value: "all" },
    { label: "Unread messages", value: "unread_messages" },
    { label: "Not connected yet", value: "not_connected_yet" }
];

import { MESSAGES } from "../../constants";
import ajax from "../helpers";

const state = {
    influencersList: [],
    orderBy: undefined,
    filterBy: WP_FILTER_BY_ITEMS[0],
    searchText: "",
    isInfluencersLoading: false,
    bulkSending: {
        campaigns: [],
        stages: [],
        selectedLeads: [],
        searchKeywords: "",
        isAllLeadsSelected: false,
        maxSearchResult: BULK_MAX_SEARCH_RESULT
    }
};

const getters = {
    influencerResultList: state => {

        // Copy array in order to not modify it
        var result = state.influencersList.slice();

        if (state.filterBy && state.filterBy.value === "has_coupon")
            result = state.influencersList.filter(i => i.coupons);
        if (state.filterBy && state.filterBy.value === "has_agreement_link")
            result = state.influencersList.filter(i => i.agreementLink);
        if (state.filterBy && state.filterBy.value === "has_tracking_link")
            result = state.influencersList.filter(i => i.trackingLink);

        if (state.orderBy && state.orderBy.sortItem.value === "status") {
            if (state.orderBy.direction === SortDirection.Ascending)
                result = result.sort((a, b) => a.stage > b.stage ? 1 : -1);
            else
                result = result.sort((a, b) => a.stage > b.stage ? -1 : 1);
        }

        if (state.orderBy && state.orderBy.sortItem.value === "campaign") {
            if (state.orderBy.direction === SortDirection.Ascending)
                result = result.sort((a, b) => a.campaign.toLowerCase() > b.campaign.toLowerCase() ? 1 : -1);
            else
                result = result.sort((a, b) => a.campaign.toLowerCase() > b.campaign.toLowerCase() ? -1 : 1);
        }

        if (state.orderBy && state.orderBy.sortItem.value === "coupon") {
            if (state.orderBy.direction === SortDirection.Ascending)
                result = result.sort((a, b) => a.coupons.length > b.coupons.length ? -1 : 1);
            else
                result = result.sort((a, b) => a.coupons.length > b.coupons.length ? 1 : -1);
        }

        if (state.orderBy && state.orderBy.sortItem.value === "messageDate") {
            if (state.orderBy.direction === SortDirection.Ascending)
                result = result.sort((a, b) => a.coupons.length > b.coupons.length ? -1 : 1);
            else
                result = result.sort((a, b) => a.coupons.length > b.coupons.length ? 1 : -1);

            result = result.sort((a, b) => {
                if (a.hasLastMessage && b.hasLastMessage) {
                    if (state.orderBy.direction === SortDirection.Ascending)
                        return a.lastMessageDate > b.lastMessageDate ? -1 : 1;
                    else
                        return a.lastMessageDate > b.lastMessageDate ? 1 : -1;
                }

                return 0;
            });
        }

        if (state.filterBy.value !== "all") {
            if (state.filterBy.value === "unread_messages")
                result = result.filter(i => i.hasLastMessage);
            else if (state.filterBy.value === "not_connected_yet")
                result = result.filter(i => !i.hasLastMessage);
        }

        if (state.searchText)
            result = result.filter(i => (i.username && i.username.includes(state.searchText)) ||
                (i.fullName && i.fullName.includes(state.searchText)) ||
                (i.biography && i.biography.includes(state.searchText))
            );

        return result;
    },
    bulkCampaignsStages: state => {
        var result = [];

        state.bulkSending.campaigns.forEach(campaign => {
            if (campaign.selected) {
                campaign.stages.forEach(stage => {
                    const index = result.findIndex(s => s.name === stage.name);
                    if (index < 0) {
                        result.push(stage)
                    }
                });
            }
        });

        return result;
    },
    allLeads: state => {
        var result = [];

        const selectedStages = getters.bulkCampaignsStages(state)
            .filter(st => { return st.selected })
            .map(st => { return st.name });

        state.bulkSending.campaigns.forEach(campaign => {
            // filter leads according selected campaigns
            if (campaign.selected) {
                campaign.leads.forEach(lead => {

                    let leadStageKey = lead.stage;
                    let leadStageName = campaign.stage_names[leadStageKey];

                    // filter leads according selected stages
                    const index = selectedStages.findIndex(s => s === leadStageName);
                    if (index >= 0) {
                        result.push(lead)
                    }
                });
            }
        });

        return result;
    },
    allLeadEmails: state => {
        let allLeads = getters.allLeads(state);
        var result = [];
        allLeads.forEach(lead => {
            if (lead.data && lead.data.emails)
                result = result.concat(lead.data.emails);
        });
        return result;
    },
    selectedLeadsEmails: state => {
        var result = [];
        state.bulkSending.selectedLeads.forEach(lead => {
            if (lead.data && lead.data.emails)
                result = result.concat(lead.data.emails);
        });
        return result;
    }, 
    filteredLeads: state => {
        var result = getters.allLeads(state);

        const bulkSearchText = state.bulkSending.searchKeywords;

        result.forEach(lead => {
            lead.fullName = lead.data && lead.data.full_name;
            lead.biography = lead.data && lead.data.biography

            if (bulkSearchText)
                result = result.filter(i => (i.username && i.username.includes(bulkSearchText)) ||
                    (i.fullName && i.fullName.includes(bulkSearchText)) ||
                    (i.biography && i.biography.includes(bulkSearchText))
                );
        });

        if (result.length > BULK_MAX_SEARCH_RESULT) {
            result.splice(BULK_MAX_SEARCH_RESULT)
        }

        return result;
    },
    bulkMaxRecipientsCount: state => {
        return BULK_MAX_LEADS_COUNT;
    }, 
    bulkLeadsOverLimit: state => {
        let selectedLeadsEmails = getters.selectedLeadsEmails(state);
        return selectedLeadsEmails.length > BULK_MAX_LEADS_COUNT;
    }
};

const actions = {
    async getInfluencers({ commit, dispatch }, payload) {

        this.state.isInfluencersLoading = true;
        commit("SET_LOADING", true)
        const { data } = await ajax.get(`/api/campaigns/workspace`);
        commit("SET_LOADING", false)

        data.forEach(profile => {
            profile.fullName = profile.data.full_name;
            profile.biography = profile.data.biography
            profile.lastMessageText = "";
            profile.addingCoupon = false;
            profile.addingAgreementLink = false;
            profile.addingTrackingLink = false;
            profile.agreementLink = "";

            profile.trackingLinks = profile.tracking_link;

            profile.editCouponValue = "";
            profile.editTrackingLinkValue = "";
            profile.editAgreementLinkValue = "";

            profile.hasLastMessage = !!profile.last_message;

            if (profile.hasLastMessage) {
                profile.lastMessageText = profile.last_message.last_email_body_preview;
                profile.lastMessage = profile.last_message;
                profile.lastMessageDate = Date.parse(profile.last_message.last_email_at);
            }

            if (!profile.profile_pic)
                profile.profile_pic = "/app/img/avatar/ninjainfluence-default.png";

            profile.stages = [];
            for (const [key, value] of Object.entries(profile.stage_names)) {
                profile.stages.push({
                    label: value,
                    value: key
                });
            }

            profile.loadingCoupons = false;
            profile.loadingTrackingLinks = false;
        });

        commit("RECEIVED_INFLUENCERS", data);
    },
    setFilterBy({ commit, dispatch }, payload) {
        commit("SET_FILTER_BY", payload);
    },
    setOrderBy({ commit, state }, sortItem) {
        var currentSortValue = state.orderBy;

        if (!currentSortValue) {
            commit("SET_ORDER_BY", new SortValue(sortItem, SortDirection.Ascending));
        } else {
            if (currentSortValue.sortItem.value == sortItem.value) {
                commit("SET_ORDER_BY", currentSortValue.getNext());
            } else {
                commit("SET_ORDER_BY", new SortValue(sortItem, SortDirection.Ascending));
            }
        }
    },
    resetOrderBy({ commit, dispatch }, payload) {
        commit("RESET_ORDER_BY", payload);
    },
    setSearchText({ commit, dispatch }, payload) {
        commit("SET_SEARCH_TEXT", payload);
    },
    updateLoadingCoupons({ commit, dispatch }, payload) {
        commit("UPDATE_LOADING_COUPONS", payload);
    },
    updateLoadingTrackingLinks({ commit, dispatch }, payload) {
        commit("UPDATE_LOADING_TRACKINGLINKS", payload);
    },

    async updateLead({
        commit,
        dispatch
    }, payload) {
        let data = await dispatch("campaign/getSingleLead", {
            campaign_id: payload.campaignId,
            lead_id: payload.leadId
        }, { root: true });

        let leadData = {
            leadId: payload.leadId,
            coupons: data.coupons.map(c => { return c.code }),
            trackingLinks: data.content_pieces.map(c => { return c.url })
        }

        commit("UPDATE_LEAD", leadData);
    },

    //#region Agreement link handling
    setAddingAgreementLink({ commit }, payload) {
        commit("SET_ADDING_AGREEMENTLINK", payload.id);
    },
    cancelAgreementLinkAdding({ commit }, payload) {
        commit("CANCEL_ADDING_AGREEMENTLINK", payload.id);
    },
    setAgreementLink({ commit }, payload) {
        commit("SET_AGREEMENTLINK", payload.id);
    },
    removeAgreementLink({ commit }, payload) {
        commit("REMOVE_AGREEMENTLINK", payload.id);
    },
    updateAgreementLink({ commit }, payload) {
        commit("UPDATE_AGREEMENTLINK", payload);
    },
    //#endregion

    async setInfluencerStage({ commit, dispatch }, payload) {
        const { data } = await dispatch(
            "campaign/changeLeadStage",
            {
                campaign_id: payload.influencer.campaign_id,
                lead_id: payload.influencer.id,
                stage_id: payload.stage.value
            }, { root: true }
        );

        if (data && data.stage_id == payload.stage.value) {
            commit("UPDATE_STAGE", payload);
        }

        dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["workspace__change_status-success"],
            alertClass: "success"
        }, { root: true });
    },
    async getCashierSession({ commit, dispatch }, payload) {
        try {
            const { data } = await ajax.post(
                `/api/billing/deposit`,
                payload
            );
            return data;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },

    //#region BulkSending
    async getCampaigns({ commit, dispatch }, payload) {
        const { data } = await dispatch("campaign/getCampaignNames", {}, { root: true });
        commit("BULK_SET_CAMPAIGNS", data);
    },
    async selectCampaign({ commit, dispatch }, payload) {
        commit("BULK_SELECT_CAMPAIGN", payload);
    },
    async selectStage({ commit, dispatch }, payload) {
        commit("BULK_SELECT_STAGE", payload);
    },
    async selectAllLeads({ commit, dispatch }, payload) {
        commit("BULK_SELECT_ALLLEADS", payload);
    },
    async selectLead({ commit, dispatch }, payload) {
        commit("BULK_SELECT_LEAD", payload);
    },
    async removeLead({ commit, dispatch }, payload) {
        commit("BULK_REMOVE_LEAD", payload);
    },
    async setBulkSearchKeywords({ commit, dispatch }, payload) {
        commit("BULK_SET_SEARCHKEYWORDS", payload);
    },
    async fetchAllLeads({ commit, state, dispatch }, payload) {

        state.bulkSending.campaigns.forEach(async (campaign) => {
            if (!campaign.leadsUpdated) {

                let data = await dispatch("campaign/getCampaignLeadsPreview", {
                    campaign_id: campaign.id
                }, { root: true });

                const campaignData = {
                    campaignId: campaign.id,
                    leads: data
                }

                commit("BULK_CAMPAIGN_SETLEADS", campaignData);
            }

            //console.log(data);
        });
    },
    async bulkReset({ commit, dispatch }, payload) {
        commit("BULK_RESET", payload);
    },
    //#endregion
};

const mutations = {
    SET_LOADING(state, result) {
        state.isInfluencersLoading = result;
    },
    RECEIVED_INFLUENCERS(state, result) {
        state.influencersList = result;
    },
    SET_FILTER_BY(state, result) {
        state.filterBy = result;
    },
    SET_ORDER_BY(state, sortValue) {
        state.orderBy = sortValue;
    },
    RESET_ORDER_BY(state) {
        state.orderBy = "";
    },
    SET_SEARCH_TEXT(state, result) {
        state.searchText = result;
    },

    UPDATE_STAGE(state, result) {
        const index = state.influencersList.findIndex(s => s.id === result.influencer.id)
        state.influencersList[index].stage = result.stage.label;
    },
    UPDATE_LEAD(state, result) {
        const index = state.influencersList.findIndex(s => s.id === result.leadId)
        state.influencersList[index].coupons = result.coupons;
        state.influencersList[index].trackingLinks = result.trackingLinks;
    },
    UPDATE_LOADING_COUPONS(state, result) {
        const index = state.influencersList.findIndex(s => s.id === result.leadId)
        state.influencersList[index].loadingCoupons = result.value;

    },
    UPDATE_LOADING_TRACKINGLINKS(state, result) {
        const index = state.influencersList.findIndex(s => s.id === result.leadId)
        state.influencersList[index].loadingTrackingLinks = result.value;
    },

    //#region Agreement link handling
    SET_ADDING_AGREEMENTLINK(state, result) {
        const index = state.influencersList.findIndex(s => s.id === result)
        state.influencersList[index].addingAgreementLink = true;
    },
    CANCEL_ADDING_AGREEMENTLINK(state, result) {
        const index = state.influencersList.findIndex(s => s.id === result)
        state.influencersList[index].addingAgreementLink = false;
    },
    REMOVE_AGREEMENTLINK(state, result) {
        const index = state.influencersList.findIndex(s => s.id === result);
        state.influencersList[index].agreementLink = "";
    },
    UPDATE_AGREEMENTLINK(state, result) {
        const index = state.influencersList.findIndex(s => s.id === result.influencer.id);
        state.influencersList[index].editAgreementLinkValue = result.value;
    },
    SET_AGREEMENTLINK(state, result) {
        const index = state.influencersList.findIndex(s => s.id === result);
        state.influencersList[index].agreementLink = state.influencersList[index].editAgreementLinkValue;
        state.influencersList[index].editAgreementLinkValue = "";
        state.influencersList[index].addingAgreementLink = false;
    },
    //#endregion

    //#region Bulk Sending
    BULK_SET_CAMPAIGNS(state, result) {
        result.forEach(campaign => {
            campaign.selected = false;
            campaign.leads = [];
            campaign.leadsUpdated = false;

            var stages = [];
            Object.keys(campaign.stage_names).forEach(stageKey => {
                stages.push({
                    id: stageKey,
                    name: campaign.stage_names[stageKey],
                    leadsCount: campaign.stage_sizes[stageKey],
                    selected: false
                });
            });
            campaign.stages = stages;
        });
        state.bulkSending.campaigns = result || [];
    },
    BULK_SELECT_CAMPAIGN(state, data) {
        const index = state.bulkSending.campaigns.findIndex(s => s.id === data.campaignId);
        state.bulkSending.campaigns[index].selected = data.selectValue;
    },
    BULK_SELECT_STAGE(state, data) {
        state.bulkSending.campaigns.forEach(campaign => {
            campaign.stages.forEach(stage => {
                if (stage.name === data.stageName)
                    stage.selected = data.selectValue;
            });
        });
    },
    BULK_SELECT_ALLLEADS(state, data) {
        state.bulkSending.isAllLeadsSelected = data.selectValue;

        if (data.selectValue)
            state.bulkSending.selectedLeads = getters.allLeads(state);
        else
            state.bulkSending.selectedLeads = []
    },
    BULK_CAMPAIGN_SETLEADS(state, data) {
        const index = state.bulkSending.campaigns.findIndex(s => s.id === data.campaignId);
        state.bulkSending.campaigns[index].leadsUpdated = true;

        var leadsWithEmails = [];
        data.leads.forEach((lead) => {
            if (lead.data.emails == "<this field is only available in the premium tier>")
                lead.data.emails = [];

            if (lead.data.emails && lead.data.emails.length > 0)
                leadsWithEmails.push(lead);

            lead.selected = false;
        });

        state.bulkSending.campaigns[index].leads = leadsWithEmails;
    },
    BULK_REMOVE_LEAD(state, data) {
        const index = state.bulkSending.selectedLeads.findIndex(s => s.id === data.leadId);
        if (index >= 0) {
            state.bulkSending.selectedLeads.splice(index, 1);
        }
    },
    BULK_SELECT_LEAD(state, data) {
        var allLeads = getters.allLeads(state);
        const index = allLeads.findIndex(s => s.id === data.leadId);
        if (index >= 0) {
            state.bulkSending.selectedLeads.push(allLeads[index]);
        }
    },
    BULK_SET_SEARCHKEYWORDS(state, data) {
        state.bulkSending.searchKeywords = data;
    },
    BULK_RESET(state, data) {
        state.bulkSending.searchKeywords = "";
        state.bulkSending.selectedLeads = [];
        state.bulkSending.isAllLeadsSelected = false;
    }
    //endregion
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};