<template>
  <nav class="flex-between">
    <slot></slot>
  </nav>
</template>
<script>
export default {
  name: "right-nav"
};
</script>
