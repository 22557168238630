<template>
  <div :class="[componentClasses]">
    <div class="nav-tabs-navigation">
      <div class="nav-tabs-wrapper">
        <slot name="nav"></slot>
      </div>
    </div>
    <div class="nav-tabs-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "tabs-layout",
  props: {
    componentClasses: {
      type: [String, Object],
      default: "",
      description: "Component css classes"
    }
  },
//   mounted() {
//     this.$nextTick(function () {
//       debugger;
//       console.log(this.componentClasses);
//     });
//   }
};
</script>
