const INSTAGRAM_LOCATIONS = {
  "Cape Verde": {
    "Boa Vista": ["Vila de Sal Rei"],
    Praia: ["Praia"],
    Sal: ["Espargos", "Santa Maria"],
    "Santa Catarina do Fogo": ["Cova Figueira"],
    "São Vicente": ["Mindelo"],
    "Tarrafal de São Nicolau": ["Tarrafal de Sao Nicolau"]
  },
  "Sint Maarten (Dutch part)": {},
  "Wallis and Futuna": { "Circonscription de Sigave": ["Sigave"] },
  "South Georgia and the South Sandwich Islands": {},
  "Saint Kitts and Nevis": {
    "Saint George Basseterre": ["Basseterre"],
    "Saint James Windward": ["Newcastle"],
    "Saint Thomas Lowland": ["Cotton Ground"],
    "Trinity Palmetto Point": ["Trinity"]
  },
  Pitcairn: {},
  "Saint Martin (French part)": {},
  "Christmas Island": {},
  "Bonaire, Sint Eustatius and Saba": {
    Bonaire: ["Dorp Antriol", "Dorp Tera Kora"]
  },
  "Virgin Islands, British": {},
  Bhutan: {
    Chirang: ["Tsirang"],
    Paro: ["Paro"],
    Punakha: ["Punakha"],
    "Punakha Dzongkhag": ["Punakha"],
    Thimphu: ["Thimphu"],
    "Thimphu Dzongkhag": ["Thimphu"],
    Tongsa: ["Trongsa"]
  },
  "Burkina Faso": {
    Centre: ["Ouagadougou"],
    "Centre-Est": [],
    "Centre-Ouest": ["Gouloure"],
    "High-Basins": ["Bobo-Dioulasso"],
    Nord: ["Yako"],
    "Plateau-Central": ["Ziniaré"],
    Sahel: ["Dori"]
  },
  "Falkland Islands (Malvinas)": {},
  Afghanistan: {
    Balkh: [],
    Daykundi: ["Khadir"],
    Helmand: ["Gereshk"],
    Herat: ["Herat", "Karukh"],
    Kandahar: ["Kandahar"],
    Lowgar: ["Pul-e `Alam"],
    Nangarhar: ["Jalalabad"],
    Panjshir: [],
    Kabul: ["Kabul"]
  },
  Luxembourg: {
    "Esch-sur-Alzette": ["Dudelange"],
    Luxembourg: ["Dudelange", "Esch-sur-Alzette", "Mersch", "Luxembourg"]
  },
  Philippines: {
    "Autonomous Region in Muslim Mindanao": [
      "Balimbing",
      "Cotabato",
      "Marawi City"
    ],
    Caraga: [
      "Basilisa",
      "Bayugan",
      "Bislig",
      "Butuan",
      "Cabadbaran",
      "General Luna",
      "Patin-ay",
      "Pilar",
      "San Francisco",
      "Surigao",
      "Surigao City",
      "Tandag"
    ],
    "Eastern Visayas": [
      "Abuyog",
      "Anahawan",
      "Babatngon",
      "Baybay",
      "Borongan",
      "Calbayog City",
      "Catarman",
      "Catbalogan",
      "Doos",
      "Hinunangan",
      "Maasin",
      "Naval",
      "Ormoc",
      "Ormoc City",
      "Palo",
      "Palompon",
      "Panalanoy",
      "Tacloban City"
    ],
    Mimaropa: [
      "Aborlan",
      "Algeciras",
      "Baheli",
      "Balabac",
      "Balatero",
      "Bulalacao",
      "Busuanga",
      "Buyabod",
      "Calapan",
      "Caruray",
      "Casian",
      "Coron",
      "El Nido",
      "Mamburao",
      "Narra",
      "Pancol",
      "Pinamalayan",
      "Puerto Galera",
      "Puerto Princesa",
      "Puerto Princesa City",
      "Romblon",
      "Roxas",
      "Sablayan",
      "Salvacion",
      "San Jose",
      "San Pedro",
      "San Vicente",
      "Tiniguiban"
    ],
    "Northern Mindanao": [
      "Agusan",
      "Cagayan de Oro",
      "Don Carlos",
      "Iligan",
      "Jasaan",
      "La Roxas",
      "Libertad",
      "Lumbia",
      "Mahinog",
      "Malaybalay",
      "Mantampay",
      "Oroquieta",
      "Ozamiz",
      "Ozamiz City",
      "Tagoloan",
      "Tangub",
      "Valencia"
    ],
    Soccsksargen: [
      "General Santos",
      "Glan",
      "Iligan City",
      "Isulan",
      "Kabacan",
      "Kidapawan",
      "Koronadal",
      "Magpet",
      "Midsayap",
      "Surallah",
      "Tacurong",
      "Tango",
      "Tupi"
    ],
    "Zamboanga Peninsula": [
      "Dapitan",
      "Dipolog",
      "Dipolog City",
      "Katipunan",
      "Molave",
      "Muricay",
      "Pagadian",
      "Zamboanga",
      "Zamboanga City"
    ],
    ARMM: ["Cotabato City", "Marawi City", "Isabela"],
    Bicol: [
      "Bacacay",
      "Baligang",
      "Bariw",
      "Buhi",
      "Bulan",
      "Cadlan",
      "Caramoan",
      "Caramoran",
      "Daet",
      "Goa",
      "Iriga City",
      "Irosin",
      "Legaspi",
      "Malilipot",
      "Masbate",
      "Nabua",
      "Palestina",
      "Polangui",
      "San Rafael",
      "San Roque",
      "Tagas",
      "Tiwi",
      "Bato",
      "Legazpi",
      "Naga",
      "Santa Elena",
      "Sorsogon",
      "Tabaco"
    ],
    "Cagayan Valley": [
      "Alicia",
      "Aparri",
      "Basco",
      "Bayombong",
      "Camalaniugan",
      "Cauayan",
      "Claveria",
      "Rizal",
      "San Antonio",
      "Santa",
      "Santiago",
      "Solano",
      "Tuguegarao City",
      "Ramon"
    ],
    Calabarzon: [
      "Alaminos",
      "Amadeo",
      "Ambulong",
      "Angono",
      "Antipolo",
      "Aurora",
      "Bacoor",
      "Bagalangit",
      "Bagong Pagasa",
      "Balagtasin",
      "Balibago",
      "Banaybanay",
      "Baras",
      "Bauan",
      "Bignay Uno",
      "Binan",
      "Binangonan",
      "Bolo",
      "Bulihan",
      "Bungoy",
      "Cabuyao",
      "Cainta",
      "Calaca",
      "Calamba",
      "Calatagan",
      "Calauag",
      "Calauan",
      "Calubcub Dos",
      "Carmona",
      "Casuguran",
      "Catanauan",
      "Cavinti",
      "Cavite City",
      "Concepcion Ibaba",
      "Cuenca",
      "Dasmarinas",
      "Del Monte",
      "General Nakar",
      "General Trias",
      "Gumaca",
      "Ilihan",
      "Imus",
      "Indang",
      "Javalera",
      "Kawit",
      "Kinalaglagan",
      "Laiya",
      "Las Pinas",
      "Lilio",
      "Lipa City",
      "Lobo",
      "Looc",
      "Lopez",
      "Los Banos",
      "Lucban",
      "Lucena",
      "Lucena City",
      "Luisiana",
      "Luksuhin",
      "Lumbang",
      "Mabini",
      "Malabanan",
      "Malanday",
      "Malaruhatan",
      "Malvar",
      "Mamatid",
      "Mangas",
      "Matabungkay",
      "Mauban",
      "Mendez-Nunez",
      "Morong",
      "Nagcarlan",
      "Naic",
      "Nasugbu",
      "Noveleta",
      "Nueva",
      "Padre Burgos",
      "Paete",
      "Pagbilao",
      "Pangil",
      "Pateros",
      "Patuto",
      "Payapa",
      "Perez",
      "Pila",
      "Pililla",
      "Pinugay",
      "Pulong Santa Cruz",
      "Puting Kahoy",
      "Real",
      "Sampaloc",
      "San Celestio",
      "San Francisco",
      "San Juan",
      "San Luis",
      "San Pablo",
      "San Pascual",
      "Santa Cruz",
      "Santor",
      "Sariaya",
      "Silang",
      "Taal",
      "Tagaytay",
      "Taguig",
      "Talisay",
      "Tanauan",
      "Tanay",
      "Tayabas",
      "Ternate",
      "Tignoan",
      "Batangas",
      "Bay",
      "Rodriguez",
      "San Mateo",
      "San Pedro",
      "Santa Rosa",
      "Santo Tomas",
      "Taytay",
      "Teresa"
    ],
    "Central Luzon": [
      "Angat",
      "Angeles City",
      "Apalit",
      "Arayat",
      "Babo-Pangulo",
      "Bagac",
      "Bagong-Sikat",
      "Balanga",
      "Baler",
      "Balibago",
      "Baliuag",
      "Balsic",
      "Bocaue",
      "Bongabon",
      "Botolan",
      "Bulaon",
      "Bustos",
      "Cabanatuan City",
      "Cabangan",
      "Calumpit",
      "Candaba",
      "Capas",
      "Care",
      "Del Pilar",
      "Dinalongan",
      "Dinalupihan",
      "Dingalan",
      "Dolores",
      "Gapan",
      "General Mamerto Natividad",
      "General Tinio",
      "Guiguinto",
      "Guimba",
      "Guyong",
      "Hagonoy",
      "Jaen",
      "Laur",
      "Limay",
      "Loma de Gato",
      "Lubao",
      "Mabalacat",
      "Mabalacat City",
      "Mabayo",
      "Magalang",
      "Mapalad",
      "Marilao",
      "Mariveles",
      "Masantol",
      "Masinloc",
      "Mexico",
      "Meycauayan",
      "Morong",
      "Munoz",
      "Norzagaray",
      "Olongapo",
      "Olongapo City",
      "Orani",
      "Pandacaqui",
      "Pandi",
      "Pantabangan",
      "Pantubig",
      "Parang",
      "Pias",
      "Pilar",
      "Pio",
      "Plaridel",
      "Poblacion, San Felipe",
      "Porac",
      "Pulilan",
      "Quezon",
      "Ramos",
      "San Antonio",
      "San Benito",
      "San Ildefonso",
      "San Jose del Monte",
      "San Juan",
      "San Leonardo",
      "San Marcelino",
      "San Miguel",
      "San Narciso",
      "San Nicolas",
      "San Simon",
      "Santa Ana",
      "Santa Cruz",
      "Santa Maria",
      "Santol",
      "San Vicente",
      "Sexmoan",
      "Subic",
      "Tarlac City",
      "Telabastagan",
      "Bulacan",
      "Hermosa",
      "Iba",
      "Malolos",
      "Orion",
      "Rizal",
      "San Fernando",
      "Talavera"
    ],
    "Central Visayas": [
      "Baclayon",
      "Badian",
      "Bais",
      "Bais City",
      "Balamban",
      "Bantayan",
      "Baugo",
      "Biking",
      "Bogo",
      "Buagsong",
      "Calero",
      "Carcar",
      "Carmen",
      "Cogan",
      "Consolacion",
      "Cordova",
      "Daanbantayan",
      "Danao",
      "Dumaguete",
      "Esperanza",
      "Gabi",
      "Guiwanon",
      "Jagna",
      "Lapu-Lapu City",
      "Loboc",
      "Logon",
      "Loon",
      "Mainit",
      "Mandaue City",
      "Minglanilla",
      "Moalboal",
      "Oslob",
      "Panglao",
      "Pinamungahan",
      "Santa Catalina",
      "Santa Fe",
      "Sibulan",
      "Siquijor",
      "Tabalong",
      "Talisay",
      "Talisay City",
      "Tanjay",
      "Tapilon",
      "Tawala",
      "Cebu City",
      "Tagbilaran City"
    ],
    Cordillera: [
      "Baguio",
      "Baguio City",
      "Banaue",
      "Bayabas",
      "Bontoc",
      "Itogon",
      "Mankayan",
      "Natubleng",
      "Sagada",
      "Tabuk",
      "La Trinidad"
    ],
    Davao: [
      "Babak",
      "Bato",
      "Davao",
      "Davao City",
      "Digos",
      "Kaputian",
      "Limao",
      "Manay",
      "Polomolok",
      "Samal",
      "Santa Cruz",
      "Tagum",
      "Tupi",
      "Mati"
    ],
    Ilocos: [
      "Agoo",
      "Anda",
      "Aringay",
      "Bacnotan",
      "Bantay",
      "Batac City",
      "Bauang",
      "Bolinao",
      "Bued",
      "Calasiao",
      "Callaguip",
      "Candon",
      "Catuday",
      "Dagupan",
      "Guiset East",
      "Ilioilio",
      "Laoag",
      "Lingayen",
      "Macabuboni",
      "Manaoag",
      "Mangaldan",
      "Natividad",
      "Pagudpud",
      "Paringao",
      "San Fernando",
      "San Juan",
      "Santa Barbara",
      "Santa Maria",
      "Santiago",
      "Tagudin",
      "Urdaneta",
      "Vigan",
      "San Nicolas"
    ],
    "Metro Manila": [
      "Calumpang",
      "Mandaluyong City",
      "Manila",
      "Pasig City",
      "Port Area",
      "Quezon City",
      "San Juan",
      "Tanza",
      "Makati City",
      "Quiapo"
    ],
    "National Capital Region": [
      "Caloocan City",
      "Las Pinas",
      "Mandaluyong City",
      "Pasay",
      "Pasig",
      "San Juan",
      "Tanza",
      "Makati City",
      "Manila",
      "Quezon City",
      "Taguig"
    ],
    "Western Visayas": [
      "Binonga",
      "Bolo",
      "Brgy. Tinigao, Kalibo",
      "Buruanga",
      "Cadagmayan Norte",
      "Cadiz",
      "Cagbang",
      "Culasi",
      "Hacienda Refugio",
      "Iloilo",
      "Kalibo (poblacion)",
      "La Carlota",
      "Malabonot",
      "Manapla",
      "Miagao",
      "Murcia",
      "Pakiad",
      "Pandan",
      "Paraiso",
      "Passi",
      "Quipot",
      "Roxas",
      "Roxas City",
      "San Miguel",
      "Saravia",
      "Sipalay",
      "Talisay",
      "Tarong",
      "Victorias",
      "Yapak",
      "Bacolod City",
      "Iloilo City"
    ]
  },
  Lithuania: {
    "Alytaus apskritis": ["Alytus", "Druskininkai"],
    Alytus: ["Alytus", "Druskininkai", "Varena"],
    "Kauno apskritis": [
      "Aleksotas",
      "Dainava (Kaunas)",
      "Eiguliai",
      "Kaunas",
      "Kedainiai",
      "Silainiai"
    ],
    "Klaipėda County": [
      "Gargždai",
      "Klaipėda",
      "Kretinga",
      "Palanga",
      "Šilutė"
    ],
    "Klaipedos apskritis": ["Klaipeda", "Kretinga", "Palanga"],
    "Marijampolė County": ["Marijampolė"],
    Panevėžys: ["Biržai", "Panevezys", "Rokiškis"],
    Siauliai: [
      "Joniškis",
      "Kelmė",
      "Kuršėnai",
      "Naujoji Akmenė",
      "Radviliškis",
      "Šiauliai"
    ],
    "Tauragė County": ["Jurbarkas", "Tauragė"],
    Telsiai: ["Mažeikiai", "Plungė", "Telšiai"],
    Utena: ["Anykščiai", "Utena", "Visaginas"],
    "Utenos apskritis": ["Anyksciai", "Utena"],
    Vilnius: [
      "Elektrėnai",
      "Fabijoniskes",
      "Lazdynai",
      "Lentvaris",
      "Naujamiestis",
      "Rasos",
      "Seskine",
      "Ukmerge",
      "Vilnius"
    ],
    Kaunas: ["Jonava", "Kėdainiai", "Raseiniai", "Kaunas"]
  },
  Liechtenstein: { Vaduz: ["Vaduz"] },
  Libya: {
    "Al Jufrah": ["Waddan"],
    "Al Marj": ["Al Marj"],
    "Al Marqab": ["Tarhuna"],
    "An Nuqat al Khams": ["Zuwarah"],
    "Az Zawiyah": ["Zawiya"],
    Banghazi: ["Benghazi"],
    Darnah: ["Darnah"],
    "Jabal al Gharbi": ["Gharyan"],
    Misratah: ["Misratah", "Zliten"],
    "Sha'biyat al Butnan": ["Tobruk"],
    "Sha'biyat al Wahat": ["Ajdabiya", "Al Burayqah"],
    "Sha'biyat an Nuqat al Khams": ["Zuwarah"],
    "Sha'biyat Banghazi": ["Benghazi"],
    "Sha'biyat Misratah": ["Misratah"],
    Surt: ["Sirte"],
    Tripoli: ["Tagiura", "Tripoli"]
  },
  Liberia: {
    "Bong County": [],
    "Grand Bassa County": ["Buchanan"],
    "Grand Gedeh County": ["Zwedru"],
    "Sinoe County": ["Greenville"],
    "Montserrado County": ["Monrovia"]
  },
  Lesotho: { "Butha-Buthe": ["Butha-Buthe"], Maseru: ["Maseru"] },
  Lebanon: {
    "Baalbek-Hermel": ["Baalbek", "El Hermel"],
    Beqaa: ["Joubb Jannine", "Zahle"],
    "Liban-Nord": ["Amioun", "Batroun", "Bcharre", "Tripoli"],
    "Liban-Sud": ["Jezzine", "Sidon", "Tyre"],
    "Mohafazat Baalbek-Hermel": ["Baalbek"],
    "Mohafazat Mont-Liban": ["Byblos", "Jounieh"],
    "Mont-Liban": [
      "Baabda",
      "Beit ed Dine",
      "Bhamdoun el Mhatta",
      "Djounie",
      "Jbail",
      "Jdaidet el Matn"
    ],
    Nabatiye: ["Ain Ebel", "Bent Jbail"],
    "South Governorate": ["Sidon", "Tyre"],
    "Mohafazat Liban-Nord": ["Batroun", "Tripoli"],
    "Mohafazat Beqaa": ["Zahle"],
    Beyrouth: ["Ra's Bayrut", "Beirut"]
  },
  Latvia: {
    Aglona: ["Aglona"],
    "Aluksnes Novads": ["Alūksne"],
    "Amatas Novads": ["Drabesi"],
    Babīte: ["Pinki"],
    "Bauskas Novads": ["Bauska"],
    Carnikava: ["Carnikava"],
    "Cesu Novads": ["Cēsis"],
    Daugavpils: ["Daugavpils"],
    "Dobeles novads": ["Dobele"],
    Dundaga: ["Dundaga"],
    Ergli: ["Ergli"],
    "Jekabpils Rajons": ["Jekabpils"],
    Jurmala: ["Jūrmala"],
    Liepaja: ["Liepāja"],
    "Limbazu novads": ["Limbaži"],
    "Ludzas novads": ["Ludza"],
    Mālpils: ["Malpils"],
    Ogre: ["Ogre"],
    Olaine: ["Olaine"],
    Pavilostas: ["Pavilosta"],
    "Salaspils Novads": ["Salaspils"],
    "Saldus Municipality": ["Saldus"],
    "Siguldas Novads": ["Sigulda"],
    "Talsi Municipality": ["Talsi"],
    "Tukuma novads": ["Tukums"],
    Valmiera: ["Valmiera"],
    Ventspils: ["Ventspils"],
    Riga: ["Riga"],
    Rezekne: ["Rēzekne"],
    "Kuldigas novads": ["Kuldīga"],
    Jelgava: ["Jelgava"],
    "Jēkabpils Municipality": ["Jēkabpils"]
  },
  "Lao People's Democratic Republic": {
    Bokeo: ["Ban Houayxay"],
    "Khoueng Savannakhet": ["Savannakhet"],
    Louangnamtha: ["Louangphabang"],
    "Luang Prabang Province": ["Luang Prabang"],
    "Vientiane Province": [
      "Muang Kasi",
      "Vangviang",
      "Vang Vieng",
      "Vientiane"
    ],
    Xiangkhouang: [],
    "Vientiane Prefecture": ["Vientiane"],
    Champasak: ["Pakse"]
  },
  Kyrgyzstan: {
    Chuy: ["Bishkek", "Lebedinovka"],
    "Chuyskaya Oblast'": ["Tokmok"],
    "Issyk-Kul": ["Balykchy", "Cholpon-Ata", "Karakol"],
    "Jalal-Abad oblast": ["Bazar-Korgon", "Jalal-Abad"],
    Naryn: ["At-Bashi"],
    Osh: ["Osh", "Uzgen"],
    "Ysyk-Koel": ["Balykchy", "Pokrovka"],
    Talas: ["Talas"],
    "Osh Oblasty": ["Osh"],
    "Gorod Bishkek": ["Bishkek"]
  },
  Peru: {
    Amazonas: ["Chachapoyas"],
    Ancash: ["Caras", "Chimbote", "Huaraz"],
    Cajamarca: ["Cajamarca", "Chirinos", "Jaén"],
    "Departamento de Moquegua": ["Moquegua"],
    Huancavelica: ["Huancavelica"],
    Huanuco: ["Queropalca"],
    "Madre de Dios": ["Puerto Maldonado", "Tambopata"],
    Moquegua: ["Ilo", "Moquegua"],
    Pasco: ["Huayllay", "Oxapampa"],
    Puno: ["Juliaca", "Puno", "Yunguyo"],
    "Region de Huanuco": ["Huánuco", "Tingo Maria"],
    "Region de San Martin": ["Bellavista", "Moyobamba"],
    "San Martin": ["Rioja"],
    Tumbes: ["Tumbes"],
    Apurimac: ["Abancay", "Andahuaylas"],
    Arequipa: ["Arequipa", "Chivay", "Coporaque"],
    Ayacucho: ["Ayacucho"],
    Callao: ["Callao"],
    Cusco: ["Cusco", "Maras", "Pisac", "Santa Ana", "Santa Teresa", "Urubamba"],
    Ica: ["Chincha Alta", "Nazca", "Paracas", "Pisco", "Santiago", "Ica"],
    Junin: ["Huancayo", "Huasahuasi", "Jarpa", "Tarma"],
    "La Libertad": [
      "Angasmarca",
      "Buenos Aires",
      "Moche",
      "Pacasmayo",
      "Pueblo Nuevo",
      "Trujillo"
    ],
    Lambayeque: ["Lambayeque", "Pimentel", "Chiclayo"],
    Lima: [
      "Asia",
      "Aucallama",
      "Barranca",
      "Chancay",
      "Chosica",
      "Huaral",
      "Independencia",
      "La Molina",
      "Mala",
      "Nuevo Imperial",
      "Paramonga",
      "Punta Hermosa",
      "San Isidro",
      "San Luis",
      "Surco",
      "Lima"
    ],
    "Lima region": [
      "Chancay",
      "Chilca",
      "Chosica",
      "Huaral",
      "Huacho",
      "San Isidro"
    ],
    Loreto: ["Alianza Cristiana", "Iquitos"],
    Piura: [
      "Chulucanas",
      "La Breita",
      "Marcavelica",
      "Sullana",
      "Talara",
      "Piura"
    ],
    Tacna: ["Tacna"],
    Ucayali: ["Pucallpa"]
  },
  Kuwait: {
    "Al Aḩmadī": [
      "Al Ahmadi",
      "Al Fahahil",
      "Al Fintas",
      "Al Mahbulah",
      "Al Manqaf",
      "Al Wafrah",
      "Ar Riqqah"
    ],
    "Al Asimah": ["Ar Rabiyah", "Ash Shamiyah", "Kuwait City", "Ad Dasmah"],
    "Al Farwaniyah": ["Al Farwaniyah", "Janub as Surrah"],
    "Mubārak al Kabīr": ["Mubarak al Kabir", "Sabah as Salim"],
    "Muhafazat Hawalli": ["Ar Rumaythiyah", "As Salimiyah", "Hawalli", "Salwa"],
    "Muhafazat al Jahra'": ["Al Jahra", "Al Jahra'"],
    Hawalli: ["Hawalli", "Salwa", "Bayan"]
  },
  Paraguay: {
    "Alto Parana": ["Ciudad del Este", "Presidente Franco", "Santa Rita"],
    Amambay: ["Pedro Juan Caballero"],
    Caaguazu: ["Coronel Oviedo"],
    Central: [
      "Aregua",
      "Colonia Mariano Roque Alonso",
      "Fernando de la Mora",
      "Lambare",
      "Limpio",
      "Nemby",
      "San Antonio",
      "San Lorenzo",
      "Villa Elisa"
    ],
    Concepcion: ["Concepcion"],
    Cordillera: ["Caacupe", "San Bernardino"],
    "Departamento de Concepcion": ["Concepción", "Horqueta"],
    "Departamento de la Cordillera": ["Caacupe"],
    "Departamento de Misiones": ["San Juan Bautista"],
    "Departamento de Neembucu": ["Pilar"],
    "Departamento de Paraguari": ["Paraguari"],
    "Departamento de Presidente Hayes": ["Villa Hayes"],
    Guaira: ["Villarrica"],
    Misiones: ["San Juan Bautista"],
    Neembucu: ["Pilar"],
    Paraguari: ["Paraguari"],
    Asuncion: ["Asunción"],
    "Departamento Central": [
      "Capiatá",
      "Mariano Roque Alonso",
      "Villa Elisa",
      "San Lorenzo",
      "Lambaré"
    ],
    "Departamento de Itapua": ["Encarnación"],
    "Departamento del Alto Parana": ["Santa Rita"],
    "Departamento del Amambay": ["Pedro Juan Caballero"],
    "Departamento del Guaira": ["Villarrica"]
  },
  "Papua New Guinea": {
    "Eastern Highlands Province": ["Goroka"],
    "East New Britain Province": ["Kokopo"],
    "Madang Province": ["Madang"],
    "Milne Bay Province": ["Alotau"],
    Morobe: ["Lae"],
    "Morobe Province": ["Lae"],
    "National Capital": ["Port Moresby"],
    "New Ireland": [],
    "Northern Province": ["Popondetta"],
    "Southern Highlands Province": ["Mendi"],
    "West New Britain Province": ["Kimbe"],
    Bougainville: ["Arawa"],
    "Western Province": ["Daru"]
  },
  Panama: {
    "Bocas del Toro": [
      "Almirante",
      "Barranco",
      "Bastimentos",
      "Bocas del Toro",
      "Changuinola"
    ],
    Chiriqui: [
      "Boquete",
      "Cerro Punta",
      "Chiriqui",
      "David",
      "Palmira",
      "Potrerillos Abajo",
      "Puerto Armuelles"
    ],
    Cocle: ["Anton", "Cocle", "La Pintada", "Penonome", "Rio Hato"],
    Colon: ["Cacique", "Palmas Bellas", "Portobelo"],
    "Guna Yala": ["Puerto Obaldia", "Tubuala"],
    Herrera: ["Chitre"],
    "Los Santos": ["Las Tablas", "Pedasi", "Santo Domingo"],
    "Provincia de Cocle": ["Penonomé"],
    "Provincia de Veraguas": ["Santiago"],
    Veraguas: ["Canazas", "Rio de Jesus", "Santiago de Veraguas"],
    "Panama Oeste": [
      "Ancon",
      "Arraijan",
      "Bejuco",
      "Cabra Numero Uno",
      "La Cabima",
      "La Ensenada",
      "Las Colinas",
      "Nuevo Arraijan",
      "Panama",
      "Paraiso",
      "San Miguel",
      "San Miguelito",
      "Tocumen",
      "Veracruz",
      "La Chorrera"
    ],
    "Provincia de Chiriqui": ["Volcan", "David"],
    "Provincia de Colon": ["Colón"],
    "Provincia de Panama": [
      "Alcalde Diaz",
      "Ancon",
      "Parque Lefevre",
      "San Miguelito",
      "Panama City"
    ]
  },
  "Korea, Republic of": {
    "Chungcheongbuk-do": ["Cheongju-si", "Chinch'on", "Koesan", "Okcheon"],
    "Gangwon-do": [
      "Chuncheon",
      "Gangneung",
      "Hwacheon",
      "Kang-neung",
      "Kosong",
      "Neietsu",
      "Santyoku",
      "Seoseok",
      "Sindong",
      "Sokcho",
      "Tonghae",
      "Toseong",
      "Wonju",
      "Yanggu"
    ],
    Gwangju: ["Gwangju"],
    "Gyeonggi-do": [
      "Ansan-si",
      "Anseong",
      "Anyang-si",
      "Bucheon-si",
      "Cheongpyeong",
      "Gapyeong",
      "Goyang-si",
      "Guri-si",
      "Gwangju",
      "Hanam",
      "Hwado",
      "Hwaseong-si",
      "Icheon-si",
      "Kwangju",
      "Kwangmyong",
      "Munsan",
      "Namyangju",
      "Osan",
      "Seongnam-si",
      "Suwon",
      "Suwon-si",
      "Tanhyeon",
      "Uijeongbu-si",
      "Wabu",
      "Yangju",
      "Yeoju"
    ],
    "Gyeongsangnam-do": [
      "Changnyeong",
      "Changwon",
      "Chinju",
      "Gimhae",
      "Goseong",
      "Jinju",
      "Kimhae",
      "Kyosai",
      "Miryang",
      "Sinhyeon",
      "Yangsan"
    ],
    "Jeju-do": ["Gaigeturi", "Jeju City", "Jeju-si", "Seogwipo"],
    "Jeollabuk-do": [
      "Changsu",
      "Gunsan",
      "Iksan",
      "Jeongeup",
      "Jeonju",
      "Jinan-gun",
      "Kimje",
      "Koch'ang",
      "Kunsan",
      "Nangen",
      "Puan",
      "Wanju"
    ],
    "Jeollanam-do": [
      "Haenam",
      "Hwasun",
      "Kurye",
      "Kwangyang",
      "Mokpo",
      "Moppo",
      "Naju",
      "Suncheon",
      "Yeosu"
    ],
    Ulsan: ["Ulsan"],
    Seoul: ["Seoul"],
    "Sejong-si": ["Sejong"],
    Incheon: ["Ganghwa-gun", "Incheon"],
    "Gyeongsangbuk-do": [
      "Eisen",
      "Enjitsu",
      "Gimcheon",
      "Gumi",
      "Gyeongju",
      "Gyeongsan-si",
      "Hayang",
      "Heung-hai",
      "Kyonju",
      "Pohang",
      "Sangju",
      "Waegwan",
      "Andong"
    ],
    Daejeon: ["Daejeon"],
    Daegu: ["Daegu"],
    "Chungcheongnam-do": [
      "Cheonan",
      "Fuyo",
      "Gongju",
      "Hongseong",
      "Kinzan",
      "Nonsan",
      "Seonghwan",
      "Suisan",
      "Taisen-ri",
      "Asan"
    ],
    Busan: ["Kijang", "Busan"]
  },
  "Korea, Democratic People's Republic of": {
    "Hamgyong-namdo": ["Yonggwang-up"],
    "Kangwon-do": ["Wonsan"],
    Pyongyang: ["Chunghwa", "Pyongyang"]
  },
  Kiribati: {},
  "Palestine, State of": {
    "Gaza Strip": ["Al Burayj", "Gaza", "Khuza`ah"],
    Jenin: ["Jenin"],
    Ramallah: ["Ramallah"],
    Tulkarm: ["Tulkarm"],
    "West Bank": [
      "Barta`ah ash Sharqiyah",
      "Bethlehem",
      "Dura",
      "East Jerusalem",
      "Hebron",
      "Janin",
      "Jericho",
      "Nablus",
      "Nazlat `Isa",
      "Old City",
      "Ramallah",
      "Salfit",
      "Shuyukh al `Arrub",
      "Tulkarm"
    ],
    "": ["Jericho", "Khan Yunis", "Yuta", "Rafah", "Nablus", "Hebron", "Gaza"],
    Bethlehem: ["Bethlehem"],
    Tubas: ["Tubas"]
  },
  Kenya: {
    Baringo: ["Kabarnet"],
    Bomet: ["Bomet"],
    Bungoma: ["Bungoma"],
    Embu: ["Embu"],
    "Embu District": ["Embu"],
    "Garissa District": ["Garissa"],
    Isiolo: ["Isiolo", "Maua"],
    Kajiado: ["Magadi", "Namanga", "Ngong"],
    "Kajiado District": ["Magadi"],
    Kakamega: ["Kakamega"],
    Kericho: ["Kericho"],
    "Kericho District": ["Kericho"],
    Kiambu: ["Kiambu", "Kikuyu", "Limuru"],
    "Kiambu District": ["Kiambu"],
    Kilifi: ["Kilifi", "Malindi"],
    "Kilifi District": ["Kilifi"],
    "Kirinyaga District": ["Keruguya"],
    Kisii: ["Kisii"],
    "Kisii District": ["Kisii"],
    Kisumu: ["Kisumu"],
    "Kitui District": ["Kitui"],
    Kwale: ["Gazi"],
    Laikipia: ["Nanyuki", "Naro Moru", "Nyahururu", "Rumuruti"],
    Lamu: ["Lamu"],
    Machakos: ["Athi River", "Machakos"],
    Makueni: ["Makueni", "Mtito Andei"],
    Marsabit: ["Marsabit"],
    Meru: ["Meru"],
    Migori: ["Migori"],
    Mombasa: ["Mombasa"],
    "Murang'a District": ["Murang'a"],
    "Nairobi Area": ["Nairobi", "Pumwani", "Thika"],
    Nakuru: ["Naivasha", "Nakuru", "Rongai"],
    Narok: ["Narok"],
    Nyandarua: ["Ol Kalou"],
    Nyeri: ["Nyeri"],
    "Nyeri District": ["Nyeri"],
    Siaya: ["Siaya"],
    "Taita Taveta": ["Voi"],
    "Trans Nzoia": ["Kitale"],
    Turkana: ["Lodwar"],
    "Uasin Gishu": ["Eldoret"],
    Vihiga: ["Mbale"],
    "West Pokot": ["Kapenguria"],
    "Nakuru District": ["Naivasha", "Nakuru"],
    "Nairobi Province": ["Thika", "Nairobi"],
    "Mombasa District": ["Mombasa"],
    "Machakos District": ["Athi River", "Machakos"]
  },
  Kazakhstan: {
    "Aktyubinskaya Oblast'": ["Aqtöbe"],
    "Almaty Oblysy": [
      "Burunday",
      "Energeticheskiy",
      "Esik",
      "Kapshagay",
      "Pervomayskiy",
      "Sarkand",
      "Taldykorgan",
      "Taldyqorghan",
      "Talghar",
      "Tekeli",
      "Zharkent"
    ],
    Aqmola: ["Kokshetau", "Stepnogorsk"],
    "Aqmola Oblysy": ["Stepnogorsk"],
    Aqtoebe: ["Aqtobe"],
    Atyrau: ["Atyrau"],
    "Batys Qazaqstan": ["Aqsay", "Oral"],
    Mangghystau: ["Aktau", "Beyneu", "Zhanaozen"],
    "North Kazakhstan": ["Petropavl"],
    "Ongtustik Qazaqstan": [
      "Aksu",
      "Bayaldyr",
      "Saryaghash",
      "Shymkent",
      "Turkestan"
    ],
    Qaraghandy: [
      "Abay",
      "Balqash",
      "Karagandy",
      "Qarazhal",
      "Temirtau",
      "Zhezqazghan"
    ],
    "Qostanay Oblysy": ["Kostanay", "Rudnyy"],
    "Qyzylorda Oblysy": [
      "Ayteke Bi",
      "Kyzylorda",
      "Kyzyl-Orda",
      "Tasboget",
      "Zhosaly"
    ],
    "Soltustik Qazaqstan": ["Shchuchinsk"],
    "Zhambyl Oblysy": ["Karatau", "Merke", "Taraz"],
    "West Kazakhstan": ["Aksay", "Oral"],
    "South Kazakhstan": ["Kentau", "Shymkent"],
    "Pavlodar Oblysy": ["Ekibastuz", "Pavlodar"],
    "Mangistauskaya Oblast'": ["Aktau"],
    Karaganda: ["Balqash", "Shakhtinsk", "Temirtau", "Karagandy"],
    "East Kazakhstan": [
      "Ayagoz",
      "Ridder",
      "Ust-Kamenogorsk",
      "Zyryanovsk",
      "Semey"
    ],
    "Atyrau Oblysy": ["Atyrau"],
    "Astana Qalasy": ["Astana"],
    "Almaty Qalasy": ["Almaty"]
  },
  Jordan: {
    Ajloun: [],
    Amman: [
      "Al Bunayyat ash Shamaliyah",
      "Al Jubayhah",
      "Al Quwaysimah",
      "Amman",
      "Wadi as Sir"
    ],
    Aqaba: ["Aqaba"],
    Balqa: ["As Salt"],
    Irbid: ["`Anjarah", "Aydun", "Irbid", "Kurayyimah", "Sal", "Umm Qays"],
    Jerash: ["Jarash", "Jerash", "Sakib"],
    Karak: ["Al Qasr", "Karak", "Karak City", "Safi"],
    "Ma’an": ["Al Quwayrah", "Ma'an", "Petra"],
    Madaba: ["Madaba"],
    Mafraq: ["Mafraq"],
    Tafielah: ["At Tafilah"],
    Zarqa: ["Russeifa", "Zarqa"],
    "Amman Governorate": ["Sahab", "Amman"]
  },
  Jersey: { "St Helier": ["Saint Helier"] },
  Palau: {
    Koror: ["Koror", "Koror Town"],
    Melekeok: ["Melekeok"],
    Ngchesar: ["Ngchesar Hamlet"],
    Peleliu: ["Kloulklubed"]
  },
  Pakistan: {
    "Azad Kashmir": ["Athmuqam", "Bhimbar", "Kotli", "Muzaffarabad"],
    Balochistan: ["Dera Bugti", "Kohlu", "Quetta"],
    "Federally Administered Tribal Areas": ["Miran Shah"],
    "Gilgit-Baltistan": ["Barishal", "Gilgit", "Idgah", "Khapalu", "Skardu"],
    Islamabad: ["Islamabad"],
    "Khyber Pakhtunkhwa": [
      "Abbottabad",
      "Bannu",
      "Hangu",
      "Haripur",
      "Kohat",
      "Lakki",
      "Mardan",
      "Mingora",
      "Swabi",
      "Tangi",
      "Peshawar"
    ],
    Punjab: [
      "Alipur",
      "Bahawalpur",
      "Bhawana",
      "Chakwal",
      "Chiniot",
      "Chunian",
      "Gojra",
      "Gujranwala",
      "Hafizabad",
      "Hazro",
      "Jand",
      "Kahna",
      "Kamalia",
      "Khanpur",
      "Khewra",
      "Malakwal City",
      "Mandi Bahauddin",
      "Mianwali",
      "Murree",
      "Nankana Sahib",
      "Narowal",
      "Okara",
      "Rawalpindi",
      "Sahiwal",
      "Shahkot",
      "Sharqpur",
      "Sialkot",
      "Vehari",
      "Sargodha",
      "Multan",
      "Lahore",
      "Kasur",
      "Jhelum",
      "Gujrat",
      "Faisalabad",
      "Dinga"
    ],
    Sindh: [
      "Dadu",
      "Daur",
      "Hyderabad",
      "Johi",
      "Kambar",
      "Khairpur",
      "Khanpur",
      "Kotri",
      "Larkana",
      "Malir Cantonment",
      "Mirpur Khas",
      "Nawabshah",
      "Ratodero",
      "Sukkur",
      "Talhar",
      "Moro",
      "Karachi"
    ]
  },
  Japan: {
    "Fukushima-ken": [
      "Fukushima",
      "Inawashiro",
      "Iwaki",
      "Koriyama",
      "Miharu",
      "Namie",
      "Nihommatsu",
      "Sukagawa"
    ],
    Ishikawa: [
      "Hakui",
      "Kanazawa",
      "Kanazawa-shi",
      "Komatsu",
      "Nonoichi",
      "Tsurugi-asahimachi"
    ],
    Iwate: [
      "Hanamaki",
      "Ichinoseki",
      "Kamaishi",
      "Miyako",
      "Mizusawa",
      "Morioka",
      "Shizukuishi",
      "Tono",
      "Yamada"
    ],
    Kagoshima: [
      "Akune",
      "Kagoshima",
      "Kagoshima-shi",
      "Kanoya",
      "Kaseda-shirakame",
      "Naze",
      "Nishinoomote"
    ],
    Kumamoto: [
      "Aso",
      "Honmachi",
      "Kikuchi",
      "Kumamoto",
      "Minamata",
      "Tamana",
      "Uto",
      "Yamaga",
      "Yatsushiro"
    ],
    Nagasaki: [
      "Hirado",
      "Iki",
      "Isahaya",
      "Nagasaki",
      "Nagasaki-shi",
      "Obita",
      "Omura",
      "Sasebo",
      "Togitsu"
    ],
    "Saga Prefecture": ["Karatsu", "Ureshinomachi-shimojuku"],
    "Shiga Prefecture": [
      "Hikone",
      "Kitahama",
      "Kusatsu",
      "Moriyama",
      "Nagahama",
      "Otsu-shi"
    ],
    Yamagata: [
      "Kaminoyama",
      "Nagai",
      "Sakata",
      "Shinjo",
      "Tendo",
      "Tsuruoka",
      "Yamagata",
      "Yonezawa",
      "Yuza"
    ],
    Yamanashi: [
      "Enzan",
      "Fujikawaguchiko",
      "Fujiyoshida",
      "Hokuto",
      "Isawa",
      "Kofu",
      "Kofu-shi",
      "Nirasaki",
      "Uenohara"
    ],
    Yamaguchi: [
      "Hagi",
      "Hikari",
      "Hofu",
      "Iwakuni",
      "Nagato",
      "Shimonoseki",
      "Yanai",
      "Yamaguchi",
      "Ube"
    ],
    Wakayama: [
      "Gobo",
      "Iwade",
      "Kainan",
      "Koya",
      "Tanabe",
      "Wakayama-shi",
      "Wakayama",
      "Hashimoto"
    ],
    Toyama: [
      "Fukumitsu",
      "Himi",
      "Kamiichi",
      "Nishishinminato",
      "Takaoka",
      "Toyama-shi",
      "Uozu",
      "Yatsuomachi-higashikumisaka",
      "Toyama"
    ],
    Tottori: ["Kurayoshi", "Tottori", "Yonago"],
    Tokyo: [
      "Chofu",
      "Chofugaoka",
      "Fussa",
      "Hachiōji",
      "Higashimurayama-shi",
      "Kamirenjaku",
      "Kokubunji",
      "Mitaka-shi",
      "Musashino",
      "Nishi-Tokyo-shi",
      "Ome",
      "Roppongi",
      "Urayasu",
      "Tokyo",
      "Machida",
      "Hino"
    ],
    Tokushima: ["Ishii", "Tokushima", "Anan"],
    Tochigi: [
      "Ashikaga",
      "Imaichi",
      "Kanuma",
      "Kuroiso",
      "Mashiko",
      "Mibu",
      "Mooka",
      "Motegi",
      "Nikkō",
      "Oyama",
      "Ujiie",
      "Utsunomiya",
      "Yaita",
      "Tochigi",
      "Sano"
    ],
    Shizuoka: [
      "Atami",
      "Fujieda",
      "Fujinomiya",
      "Fukuroi",
      "Gotemba",
      "Gotenba",
      "Hamakita",
      "Itō",
      "Iwata",
      "Kakegawa",
      "Kanaya",
      "Kosai-shi",
      "Mishima",
      "Numazu",
      "Shimada",
      "Shimoda",
      "Yaizu",
      "Shizuoka",
      "Mori",
      "Hamamatsu",
      "Fuji"
    ],
    Shimane: ["Izumo", "Masuda", "Matsue", "Sakaiminato", "Hamada"],
    Shiga: ["Hikone", "Moriyama", "Nagahama"],
    Saitama: [
      "Ageo",
      "Asaka",
      "Chichibu",
      "Hanno",
      "Hanyu",
      "Hasuda",
      "Hatogaya-honcho",
      "Honjo",
      "Iwatsuki",
      "Kasukabe",
      "Kawagoe",
      "Kawaguchi",
      "Kazo",
      "Konosu",
      "Koshigaya",
      "Kumagaya",
      "Kurihashi",
      "Ogawa",
      "Okegawa",
      "Sakado",
      "Satte",
      "Sayama",
      "Shiki",
      "Shimotoda",
      "Shobu",
      "Soka",
      "Sugito",
      "Tokorozawa",
      "Wako",
      "Yashio-shi",
      "Yono",
      "Yoshikawa",
      "Saitama"
    ],
    Saga: ["Karatsu", "Kashima", "Tosu", "Saga"],
    Ōsaka: [
      "Daitocho",
      "Hirakata",
      "Ibaraki",
      "Ikeda",
      "Izumi",
      "Izumiotsu",
      "Izumisano",
      "Kaizuka",
      "Kashihara",
      "Matsubara",
      "Mino",
      "Minoh",
      "Moriguchi",
      "Osaka-shi",
      "Suita",
      "Takaishi",
      "Takatsuki",
      "Toyonaka",
      "Yao",
      "Sakai",
      "Osaka"
    ],
    Okinawa: [
      "Chatan",
      "Ginowan",
      "Hirara",
      "Ishikawa",
      "Itoman",
      "Katsuren-haebaru",
      "Nago",
      "Naha",
      "Naha-shi",
      "Tomigusuku",
      "Okinawa",
      "Ishigaki"
    ],
    Okayama: [
      "Ibara",
      "Kamogatacho-kamogata",
      "Kasaoka",
      "Kurashiki",
      "Niimi",
      "Okayama-shi",
      "Takahashi",
      "Tamano",
      "Soja",
      "Okayama"
    ],
    Oita: [
      "Beppu",
      "Hiji",
      "Hita",
      "Kitsuki",
      "Nakatsu",
      "Ōita",
      "Takedamachi",
      "Tsukawaki",
      "Tsukumiura",
      "Usuki",
      "Saiki"
    ],
    Niigata: [
      "Arai",
      "Gosen",
      "Itoigawa",
      "Joetsu",
      "Kamo",
      "Mitsuke",
      "Murakami",
      "Nagaoka",
      "Niigata-shi",
      "Niitsu",
      "Ojiya",
      "Sanjo",
      "Shibata",
      "Shiozawa",
      "Suibara",
      "Tsubame",
      "Yoshida-kasugacho",
      "Niigata"
    ],
    Nara: [
      "Gose",
      "Haibara-akanedai",
      "Ikoma",
      "Kashihara-shi",
      "Nara-shi",
      "Tawaramoto",
      "Tenri",
      "Sakurai",
      "Nara"
    ],
    Nagano: [
      "Chino",
      "Hakuba",
      "Hotaka",
      "Iiyama",
      "Komoro",
      "Matsumoto",
      "Nakano",
      "Okaya",
      "Omachi",
      "Shiojiri",
      "Suwa",
      "Suzaka",
      "Tatsuno",
      "Ueda",
      "Saku",
      "Nagano",
      "Ina",
      "Iida"
    ],
    Miyazaki: ["Kobayashi", "Miyazaki-shi", "Nichinan", "Nobeoka", "Miyazaki"],
    Miyagi: [
      "Furukawa",
      "Ishinomaki",
      "Iwanuma",
      "Kakuda",
      "Matsushima",
      "Rifu",
      "Sendai-shi",
      "Shiogama",
      "Shiroishi",
      "Tomiya",
      "Watari",
      "Sendai"
    ],
    Mie: [
      "Ise",
      "Kameyama",
      "Kawage",
      "Komono",
      "Kuwana",
      "Nabari",
      "Owase",
      "Suzuka",
      "Tsu",
      "Ueno-ebisumachi",
      "Yokkaichi",
      "Toba"
    ],
    Kyoto: [
      "Ayabe",
      "Fukuchiyama",
      "Kameoka",
      "Kamigyo-ku",
      "Maizuru",
      "Muko",
      "Uji",
      "Kyoto"
    ],
    Kochi: ["Ino", "Muroto-misakicho", "Nakamura", "Kochi", "Aki"],
    Kanagawa: [
      "Atsugi",
      "Fujisawa",
      "Hadano",
      "Hakone",
      "Hayama",
      "Hiratsuka",
      "Isehara",
      "Minami-rinkan",
      "Ninomiya",
      "Odawara",
      "Oiso",
      "Yugawara",
      "Zama",
      "Zushi",
      "Yokosuka",
      "Yokohama",
      "Miura",
      "Kawasaki",
      "Kamakura",
      "Chigasaki"
    ],
    Kagawa: ["Marugame", "Sakaidecho", "Takamatsu"],
    Ibaraki: [
      "Daigo",
      "Hitachi",
      "Hitachi-Naka",
      "Ishige",
      "Ishioka",
      "Itako",
      "Iwai",
      "Iwase",
      "Kasama",
      "Kashima-shi",
      "Koga",
      "Mito-shi",
      "Mitsukaido",
      "Moriya",
      "Naka",
      "Oarai",
      "Ryugasaki",
      "Sakai",
      "Takahagi",
      "Tomobe",
      "Toride",
      "Ushiku",
      "Yuki",
      "Tsukuba",
      "Mito",
      "Ami"
    ],
    Hyōgo: [
      "Akashi",
      "Amagasaki",
      "Ashiya",
      "Fukura",
      "Himeji",
      "Itami",
      "Kakogawa",
      "Kariya",
      "Kawanishi",
      "Nishinomiya",
      "Nishinomiya-hama",
      "Nishiwaki",
      "Sandacho",
      "Sasayama",
      "Sumoto",
      "Takarazuka",
      "Toyooka",
      "Yamazakicho-nakabirose",
      "Yashiro",
      "Ono",
      "Miki",
      "Kobe"
    ],
    Hokkaido: [
      "Abashiri",
      "Asahikawa",
      "Ashibetsu",
      "Bibai",
      "Bihoro",
      "Chitose",
      "Ebetsu",
      "Fukagawa",
      "Hakodate",
      "Ishikari",
      "Iwamizawa",
      "Iwanai",
      "Kitami",
      "Kushiro",
      "Mombetsu",
      "Motomachi",
      "Muroran",
      "Musa",
      "Nanae",
      "Nayoro",
      "Nemuro",
      "Niseko Town",
      "Obihiro",
      "Otaru",
      "Otofuke",
      "Sapporo",
      "Shimo-furano",
      "Shiraoi",
      "Tomakomai",
      "Yoichi",
      "Date"
    ],
    Hiroshima: [
      "Fukuyama",
      "Hatsukaichi",
      "Hiroshima-shi",
      "Kure",
      "Mihara",
      "Miyajima",
      "Miyoshi",
      "Onomichi",
      "Otake",
      "Takehara",
      "Hiroshima"
    ],
    Gunma: [
      "Annaka",
      "Isesaki",
      "Kiryu",
      "Maebashi",
      "Maebashi-shi",
      "Numata",
      "Shibukawa",
      "Takasaki",
      "Tamamura",
      "Tatebayashi",
      "Tomioka",
      "Ota",
      "Fujioka"
    ],
    Gifu: [
      "Gero",
      "Gifu City",
      "Gifu-shi",
      "Godo",
      "Gujo",
      "Inuyama",
      "Kakamigahara",
      "Mino",
      "Minokamo",
      "Mizunami",
      "Nakatsugawa",
      "Ogaki",
      "Tajimi",
      "Takayama",
      "Tarui",
      "Toki"
    ],
    Fukuoka: [
      "Amagi",
      "Dazaifu",
      "Fukuoka-shi",
      "Iizuka",
      "Kitakyushu",
      "Koga",
      "Kurume",
      "Maebaru-chuo",
      "Nakama",
      "Nakatsu",
      "Nogata",
      "Okawa",
      "Sasaguri",
      "Tagawa",
      "Yanagawa",
      "Kanda",
      "Fukuoka"
    ],
    Fukui: [
      "Fukui-shi",
      "Maruoka",
      "Mikuni",
      "Sabae",
      "Takefu",
      "Tsuruga",
      "Obama"
    ],
    Ehime: ["Iyo", "Masaki", "Matsuyama", "Ozu", "Uwajima", "Saijo"],
    Chiba: [
      "Abiko",
      "Funabashi",
      "Hasaki",
      "Ichihara",
      "Kamogawa",
      "Kashiwa",
      "Katsuura",
      "Kawaguchi",
      "Kisarazu",
      "Matsudo",
      "Mobara",
      "Nagareyama",
      "Narita",
      "Noda",
      "Shiroi",
      "Shisui",
      "Tateyama",
      "Sakura",
      "Chiba",
      "Asahi"
    ],
    Aomori: [
      "Aomori",
      "Aomori Shi",
      "Goshogawara",
      "Hachinohe",
      "Misawa",
      "Hirosaki"
    ],
    Akita: ["Yokote", "Yuzawa", "Akita"],
    Aichi: [
      "Chiryu",
      "Gamagori",
      "Handa",
      "Hekinan",
      "Ichinomiya",
      "Inazawa",
      "Inuyama",
      "Iwakura",
      "Kanie",
      "Kariya",
      "Kasugai",
      "Komaki",
      "Konan",
      "Nagoya-shi",
      "Nishio",
      "Seto",
      "Sobue",
      "Tahara",
      "Takahama",
      "Taketoyo",
      "Tokoname",
      "Toyokawa",
      "Tsushima",
      "Toyota",
      "Toyohashi",
      "Okazaki",
      "Obu",
      "Nagoya",
      "Anjo"
    ]
  },
  Oman: {
    "Ad Dakhiliyah": ["Izki", "Nizwa"],
    "Al Batinah South": [
      "Al Khaburah",
      "Al Liwa'",
      "Al Sohar",
      "As Suwayq",
      "Barka'",
      "Rustaq",
      "Saham",
      "Shinas"
    ],
    "Al Buraimi": ["Al Buraymi", "Buraimi"],
    "Al Wusta": ["Hayma'"],
    "Ash Sharqiyah": ["Badiyah", "Ibra'", "Sur"],
    "Az Zahirah": ["`Ibri"],
    "Muhafazat ad Dakhiliyah": ["Izki", "Sufalat Sama'il"],
    "Muhafazat Masqat": ["As Sib al Jadidah", "Bawshar", "Muscat"],
    Musandam: ["Khasab"],
    Zufar: ["Salalah"],
    "Al Batinah North Governorate": ["Sohar", "Saham"],
    Dhofar: ["Salalah"],
    Muscat: ["Seeb", "Muscat"]
  },
  Norway: {
    Buskerud: [
      "Drammen",
      "Geilo",
      "Gol",
      "Hemsedal",
      "Honefoss",
      "Kongsberg",
      "Lierbyen",
      "Mjondalen",
      "Royken",
      "Tofte",
      "Tranby",
      "Troim",
      "Vik"
    ],
    "Nord-Trondelag Fylke": ["Hell", "Meraker", "Steinkjer", "Stjordal"],
    "Sogn og Fjordane Fylke": [
      "Aurlandsvangen",
      "Eivindvik",
      "Farnes",
      "Forde",
      "Gaupne",
      "Hermansverk",
      "Stryn",
      "Vik"
    ],
    Troms: [
      "Finnsnes",
      "Hamnvik",
      "Hansnes",
      "Harstad",
      "Hatteng",
      "Olderdalen",
      "Setermoen",
      "Storsteinnes",
      "Tennevoll",
      "Tromso"
    ],
    Akershus: [
      "As",
      "Asker",
      "Billingstad",
      "Bjorkelangen",
      "Blakstad",
      "Drobak",
      "Fjerdingby",
      "Jessheim",
      "Klofta",
      "Kolbotn",
      "Lillestrøm",
      "Lysaker",
      "Nesoddtangen",
      "Raholt",
      "Sandvika",
      "Skui",
      "Teigebyen",
      "Ski"
    ],
    "Aust-Agder": ["Lillesand", "Risør", "Tvedestrand", "Arendal"],
    Finnmark: ["Honningsvåg", "Karasjohka", "Alta"],
    Hedmark: [
      "Engerdal",
      "Innbygda",
      "Kongsvinger",
      "Koppang",
      "Skotterud",
      "Hamar",
      "Elverum"
    ],
    "Hordaland Fylke": [
      "Askøy",
      "Bones",
      "Dale",
      "Hylkje",
      "Indre Arna",
      "Jondal",
      "Kinsarvik",
      "Knappskog",
      "Leirvik",
      "Odda",
      "Rosendal",
      "Sandsli",
      "Storebo",
      "Sveio",
      "Ulvik",
      "Voss",
      "Ytrebygda",
      "Bergen"
    ],
    "More og Romsdal fylke": [
      "Andalsnes",
      "Brattvag",
      "Bruhagen",
      "Eidsvag",
      "Falkhytta",
      "Fiska",
      "Hopen",
      "Kristiansund",
      "Langevag",
      "Midsund",
      "Steinshamn",
      "Valderoy",
      "Valldal",
      "Molde",
      "Ålesund"
    ],
    "Nordland Fylke": [
      "Evjen",
      "Gravdal",
      "Husoya",
      "Kabelvag",
      "Kjopsvik",
      "Leinesfjorden",
      "Leknes",
      "Mo i Rana",
      "Nesna",
      "Ramberg",
      "Reine",
      "Svolvær",
      "Narvik",
      "Bodø"
    ],
    Oppland: [
      "Bismo",
      "Dombas",
      "Fall",
      "Fossbergom",
      "Gjøvik",
      "Grua",
      "Jevnaker",
      "Lena",
      "Ringebu",
      "Segalstad bru",
      "Skreia",
      "Slidre",
      "Vang",
      "Lillehammer"
    ],
    Oslo: ["Sjolyststranda", "Oslo"],
    "Oslo County": ["Oslo"],
    Østfold: [
      "Askim",
      "Halden",
      "Rakkestad",
      "Skjaerhalden",
      "Skjeberg",
      "Sarpsborg",
      "Moss",
      "Fredrikstad"
    ],
    "Rogaland Fylke": [
      "Algard",
      "Bryne",
      "Eike",
      "Forsand",
      "Hjelmelandsvagen",
      "Randaberg",
      "Sola",
      "Tananger",
      "Vormedal",
      "Stavanger",
      "Sandnes",
      "Haugesund"
    ],
    "Sor-Trondelag Fylke": ["Brekstad", "Malvik", "Trondheim"],
    Telemark: [
      "Amot",
      "Bo",
      "Kragero",
      "Langesund",
      "Notodden",
      "Porsgrunn",
      "Rjukan",
      "Skien"
    ],
    "Troms Fylke": ["Harstad", "Tromsø"],
    "Vest-Agder Fylke": [
      "Farsund",
      "Flekkefjord",
      "Liknes",
      "Mandal",
      "Tingvatn",
      "Tonstad",
      "Vennesla",
      "Kristiansand"
    ],
    Vestfold: [
      "Asgardstrand",
      "Berger",
      "Horten",
      "Larvik",
      "Stavern",
      "Tjøme",
      "Tønsberg",
      "Sandefjord"
    ]
  },
  Jamaica: {
    Clarendon: ["Kellits", "May Pen"],
    Hanover: ["Lucea", "Sandy Bay"],
    "Saint Andrew": [
      "Constant Spring",
      "Half Way Tree",
      "Mona Heights",
      "New Kingston"
    ],
    "Saint Ann": [
      "Discovery Bay",
      "Ocho Rios",
      "Runaway Bay",
      "Saint Ann's Bay"
    ],
    "Saint Catherine": ["Linstead", "Old Harbour", "Portmore", "Spanish Town"],
    "Saint Mary": ["Oracabessa"],
    "Saint Thomas": ["Bath"],
    Trelawny: ["Duncans", "Falmouth", "Wakefield"],
    Westmoreland: ["Negril", "Savanna-la-Mar"],
    "Saint James": ["Anchovy", "Montego Bay"],
    Portland: ["Buff Bay", "Port Antonio"],
    Manchester: ["Mandeville", "Porus"],
    Kingston: ["Kingston"]
  },
  Italy: {
    Veneto: [
      "Abano Terme",
      "Adria",
      "Albignasego",
      "Alleghe",
      "Altavilla Vicentina",
      "Angiari",
      "Arbizzano-Santa Maria",
      "Arzignano",
      "Asiago",
      "Asolo",
      "Auronzo",
      "Bardolino",
      "Bassano del Grappa",
      "Beccacivetta-Azzano",
      "Bibione",
      "Bovolone",
      "Burano",
      "Bussolengo",
      "Caldiero",
      "Caldogno",
      "Campalto",
      "Campodarsego",
      "Camponogara",
      "Candide",
      "Caorle",
      "Caselle",
      "Casoni",
      "Castelfranco Veneto",
      "Cavallino",
      "Cerea",
      "Chiampo",
      "Cittadella",
      "Cola",
      "Cortina d'Ampezzo",
      "Creazzo",
      "Crepaldo",
      "Crespano del Grappa",
      "Crispi Cavour",
      "Dossobuono",
      "Dueville",
      "Eraclea",
      "Falcade Alto",
      "Feltre",
      "Garda",
      "Giudecca",
      "Iesolo",
      "Lamosano",
      "Lancenigo-Villorba",
      "Legnago",
      "Lido di Iesolo",
      "Livinallongo del Col di Lana",
      "Lonigo",
      "Lumignano",
      "Malcesine",
      "Mirano",
      "Mogliano Veneto",
      "Monselice",
      "Montecchio Maggiore",
      "Montecchio Maggiore-Alte Ceccato",
      "Montegrotto Terme",
      "Murano",
      "Noale",
      "Noventa",
      "Oderzo",
      "Padova",
      "Paese",
      "Peschiera del Garda",
      "Pieve di Soligo",
      "Piove di Sacco-Piovega",
      "Ponzano",
      "Ponzano Veneto",
      "Portogruaro",
      "Porto Tolle",
      "Porto Viro",
      "Rosà",
      "Rosolina",
      "San Bonifacio",
      "San Donà di Piave",
      "San Giovanni Lupatoto",
      "San Martino Buon Albergo",
      "Santa Giustina in Colle",
      "Sant'Anna",
      "Scardovari",
      "Schio",
      "Selva di Cadore",
      "Tencarola",
      "Thiene",
      "Trissino",
      "Valdagno",
      "Varago",
      "Vigonza",
      "Villafranca di Verona",
      "Villaggio Montegrappa",
      "Vittorio Veneto",
      "Vo",
      "Zelarino",
      "Zevio",
      "Vicenza",
      "Verona",
      "Venice",
      "Treviso",
      "Spresiano",
      "Spinea",
      "Rovigo",
      "Pescantina",
      "Padua",
      "Montebelluna",
      "Mira",
      "Mestre",
      "Marcon",
      "Malo",
      "Lido",
      "Este",
      "Conegliano",
      "Chioggia",
      "Belluno"
    ],
    Umbria: [
      "Assisi",
      "Bastia umbra",
      "Cannara",
      "Casteltodino",
      "Chiugiana-La Commenda",
      "Citta della Pieve",
      "Città di Castello",
      "Deruta",
      "Fontignano",
      "Gubbio",
      "Lacugnano",
      "Magione",
      "Milano",
      "Montone",
      "Narni",
      "Orvieto",
      "Ponte Felcino",
      "Rivotorto",
      "San Niccolo di Celle",
      "Santa Maria degli Angeli",
      "Spello",
      "Todi",
      "Umbertide",
      "Via Lippia",
      "Terni",
      "Spoleto",
      "Perugia",
      "Foligno"
    ],
    Tuscany: [
      "Albinia",
      "Asciano",
      "Bagno a Ripoli",
      "Barberino Val d'Elsa",
      "Calenzano",
      "Campi Bisenzio",
      "Campo nell'Elba",
      "Capalbio",
      "Capanne-Prato-Cinquale",
      "Capannori",
      "Capoliveri",
      "Cascina",
      "Casole d'Elsa",
      "Castagneto Carducci",
      "Castelfiorentino",
      "Castelfranco di Sotto",
      "Castellina in Chianti",
      "Castelnuovo di Val di Cecina",
      "Certaldo",
      "Chiusdino",
      "Cinigiano",
      "Colle di Val d'Elsa",
      "Cortona",
      "Cutigliano",
      "Fiesole",
      "Figline Valdarno",
      "Foiano della Chiana",
      "Fornacelle",
      "Forte dei Marmi",
      "Gaiole in Chianti",
      "Giglio Castello",
      "Grassina Ponte a Ema",
      "Greve in Chianti",
      "La California",
      "La Rotta",
      "Lastra a Signa",
      "Lorenzana",
      "Madonna dell'Acqua",
      "Manciano",
      "Mantignano-Ugnano",
      "Marciana",
      "Marciana Marina",
      "Marina di Carrara",
      "Marina di Grosseto",
      "Marina di Pisa-Tirrenia-Calambrone",
      "Massa Marittima",
      "Massarosa",
      "Mercatale",
      "Monsummano Terme",
      "Montalcino",
      "Montecatini Terme",
      "Montecatini-Terme",
      "Montelupo Fiorentino",
      "Montemurlo",
      "Montepulciano",
      "Monteriggioni",
      "Monte San Savino",
      "Montescudaio",
      "Montevarchi",
      "Monticiano",
      "Montignoso",
      "Palaia",
      "Palazzuolo sul Senio",
      "Panzano in Chianti",
      "Pescia",
      "Pienza",
      "Poggibonsi",
      "Poggio a Caiano",
      "Ponsacco",
      "Porto Azzurro",
      "Porto Ercole",
      "Portoferraio",
      "Radda in Chianti",
      "Radicondoli",
      "Rosignano Solvay-Castiglioncello",
      "Sambuca Pistoiese",
      "San Gimignano",
      "San Giovanni d'Asso",
      "San Giovanni Valdarno",
      "San Giustino Valdarno",
      "San Miniato Basso",
      "Santa Luce",
      "San Vincenzo",
      "San Vito-Cerreto",
      "Scandicci",
      "Scarperia",
      "Seggiano",
      "Semproniano",
      "Sesto Fiorentino",
      "Signa",
      "Sovicille",
      "Terontola",
      "Volterra",
      "Viareggio",
      "Siena",
      "Sansepolcro",
      "San Miniato",
      "Prato",
      "Pontedera",
      "Pistoia",
      "Pisa",
      "Piombino",
      "Pietrasanta",
      "Massa",
      "Lucca",
      "Livorno",
      "Grosseto",
      "Fucecchio",
      "Follonica",
      "Florence",
      "Empoli",
      "Cecina",
      "Carrara",
      "Camaiore",
      "Arezzo",
      "Agliana"
    ],
    "Trentino-Alto Adige": [
      "Badia",
      "Braies",
      "Bressanone",
      "Bruneck",
      "Campo Tures",
      "Canazei",
      "Cogolo",
      "Commezzadura",
      "Corvara in Badia",
      "Curon Venosta",
      "Dobbiaco",
      "Fondo",
      "Laives",
      "Malé",
      "Malles Venosta",
      "Mezzana",
      "Mezzocorona",
      "Nova Levante",
      "Ortisei",
      "Ossana",
      "Pietramurata",
      "Predazzo",
      "Riva del Garda",
      "San Candido",
      "San Martino in Passiria",
      "San Pietro",
      "Santa Cristina Valgardena",
      "Santa Valburga",
      "Sesto",
      "Siror",
      "Siusi",
      "Sporminore",
      "Tenno",
      "Torbole sul Garda",
      "Valdaora di Mezzo",
      "Velturno",
      "Vigolo Vattaro",
      "Zambana",
      "Trento",
      "Rovereto",
      "Pergine Valsugana",
      "Merano",
      "Brixen",
      "Bolzano",
      "Arco"
    ],
    "The Marches": [
      "Arquata del Tronto",
      "Belforte del Chienti",
      "Borgo Pace",
      "Castelfidardo",
      "Castelraimondo",
      "Castelsantangelo sul Nera",
      "Cerreto d'Esi",
      "Chiaravalle",
      "Civitanova Marche",
      "Cupra Marittima",
      "Falconara Marittima",
      "Folignano",
      "Frontino",
      "Gabicce Mare",
      "Iesi",
      "Jesi",
      "Le Grazie di Ancona",
      "Marotta",
      "Montecosaro",
      "Montefiore dell'Aso",
      "Montegranaro",
      "Numana",
      "Osimo",
      "Pietra la Croce",
      "Pinocchio di Ancona",
      "Portocivitanova",
      "Porto Recanati",
      "Porto Sant'Elpidio",
      "Recanati",
      "San Benedetto del Tronto",
      "Sant'Elpidio a Mare",
      "Sarnano",
      "Sirolo",
      "Tavullia",
      "Tolentino",
      "Urbania",
      "Urbino",
      "Valentina",
      "Villanova",
      "Senigallia",
      "Porto San Giorgio",
      "Pesaro",
      "Macerata",
      "Grottammare",
      "Fermo",
      "Fano",
      "Fabriano",
      "Ascoli Piceno",
      "Ancona"
    ],
    Sicily: [
      "Aci Castello",
      "Aci Catena",
      "Acireale",
      "Agira",
      "Bagheria",
      "Barcellona Pozzo di Gotto",
      "Barrafranca",
      "Belmonte Mezzagno",
      "Belpasso",
      "Belvedere-Piano Tavola",
      "Biancavilla",
      "Bronte",
      "Butera",
      "Caltanissetta",
      "Canalicchio",
      "Canicattini Bagni",
      "Canneto",
      "Carlentini",
      "Carrozziere",
      "Casa Santa",
      "Casteldaccia",
      "Castellammare del Golfo",
      "Castellana Sicula",
      "Castelvetrano",
      "Cava d'Aliga",
      "Ciavolo",
      "Cinisi",
      "Comiso",
      "Favara",
      "Favignana",
      "Ficarazzi",
      "Francofonte",
      "Gaggi",
      "Gangi",
      "Gela",
      "Giardini-Naxos",
      "Giarre",
      "Grammichele",
      "Gratteri",
      "Gravina di Catania",
      "Grotte",
      "Itala",
      "Kamma",
      "Lampedusa",
      "Lentini",
      "Leonforte",
      "Letojanni",
      "Lipari",
      "Maletto",
      "Malfa",
      "Marinella",
      "Mascali",
      "Mascalucia",
      "Mazara del Vallo",
      "Mazzarino",
      "Misilmeri",
      "Misterbianco",
      "Monreale",
      "Montalbano Elicona",
      "Nicolosi",
      "Nicosia",
      "Novara di Sicilia",
      "Nuovo Centro Urbano Poggioreale",
      "Pachino",
      "Palagonia",
      "Partanna",
      "Partinico",
      "Paternò",
      "Pedara",
      "Piazza Armerina",
      "Porto Empedocle",
      "Portopalo di Capo Passero",
      "Priolo Gargallo",
      "Raffadali",
      "Ragalna",
      "Ramacca",
      "Randazzo",
      "Ravanusa",
      "Riposto",
      "Rosolini",
      "San Giovanni la Punta",
      "San Leone Mose",
      "Santa Marina Salina",
      "San Vito Lo Capo",
      "Sciacca",
      "Scoglitti",
      "Scordia",
      "Siracusa",
      "Syracuse",
      "Taormina",
      "Termini Imerese",
      "Terrasini",
      "Trecastagni",
      "Tremestieri Etneo",
      "Valledolmo",
      "Viagrande",
      "Villabate",
      "Villasmundo",
      "Vittoria",
      "Trapani",
      "Scicli",
      "San Cataldo",
      "Riesi",
      "Ribera",
      "Ragusa",
      "Pozzallo",
      "Palermo",
      "Noto",
      "Niscemi",
      "Modica",
      "Milazzo",
      "Messina",
      "Menfi",
      "Marsala",
      "Licata",
      "Ispica",
      "Floridia",
      "Enna",
      "Corleone",
      "Cefalù",
      "Catania",
      "Carini",
      "Canicattì",
      "Caltagirone",
      "Avola",
      "Augusta",
      "Alcamo",
      "Agrigento",
      "Adrano"
    ],
    Sardinia: [
      "Alghero",
      "Armungia",
      "Arzachena",
      "Assemini",
      "Austis",
      "Bari Sardo",
      "Baunei",
      "Bosa",
      "Buggerru",
      "Cabras",
      "Cala Gonone",
      "Calasetta",
      "Carbonia",
      "Castiadas",
      "Domus de Maria",
      "Golfo Aranci",
      "Golfo Arnaci",
      "Guspini",
      "Iglesias",
      "Ittiri",
      "La Caletta",
      "Nuoro",
      "Olia Speciosa",
      "Oliena",
      "Oristano",
      "Orosei",
      "Palau",
      "Poggio dei Pini",
      "Porto Cervo",
      "Porto Torres",
      "Pula",
      "Quartucciu",
      "Quartu Sant'Elena",
      "Sanluri",
      "Santa Teresa Gallura",
      "San Teodoro",
      "Selargius",
      "Sestu",
      "Settimo San Pietro",
      "Stintino",
      "Su Planu",
      "Tempio Pausania",
      "Villacidro",
      "Villasimius",
      "Sorso",
      "Sinnai",
      "Sassari",
      "Olbia",
      "Monserrato",
      "Macomer",
      "La Maddalena",
      "Capoterra",
      "Cagliari"
    ],
    Piedmont: [
      "Alpignano",
      "Arona",
      "Bardonecchia",
      "Barge",
      "Barolo",
      "Bersezio",
      "Biella",
      "Borgaro Torinese",
      "Borgomanero",
      "Borgo San Dalmazzo",
      "Borgosesia",
      "Bra",
      "Breo",
      "Briga Alta",
      "Cameri",
      "Camino",
      "Camo",
      "Candelo",
      "Carmagnola",
      "Casale Monferrato",
      "Caselle Torinese",
      "Castelletto Stura",
      "Castiglione Falletto",
      "Chieri",
      "Chivasso",
      "Cinzano",
      "Cirié",
      "Claviere",
      "Collegno",
      "Cossato",
      "Domodossola",
      "Formazza",
      "Fossano",
      "Galliate",
      "Garessio",
      "Giaveno",
      "Grugliasco",
      "Guarene",
      "Leinì",
      "Lesna",
      "Moncalieri",
      "Mondovì",
      "Monforte d'Alba",
      "Nichelino",
      "Oleggio",
      "Omegna",
      "Orbassano",
      "Ornavasso",
      "Orta San Giulio",
      "Pallanza-Intra-Suna",
      "Pianezza",
      "Piossasco",
      "Piozzo",
      "Prali",
      "Quarna Sopra",
      "Revigliasco",
      "Rivarolo Canavese",
      "Rivoli",
      "Roburent",
      "Saluzzo",
      "Sambuco",
      "San Mauro Torinese",
      "Santena",
      "Santo Stefano Belbo",
      "Sauze d'Oulx",
      "Savigliano",
      "Stresa",
      "Terdobbiate",
      "Tonengo-Casale",
      "Trasquera",
      "Trecate",
      "Trivero-Prativero-Ponzone",
      "Trofarello",
      "Vinovo",
      "Viverone",
      "Volpiano",
      "Vercelli",
      "Verbania",
      "Venaria Reale",
      "Valenza",
      "Turin",
      "Tortona",
      "Settimo Torinese",
      "Pinerolo",
      "Novi Ligure",
      "Novara",
      "Ivrea",
      "Cuneo",
      "Asti",
      "Alessandria",
      "Alba",
      "Acqui Terme"
    ],
    Molise: ["Civitacampomarano", "Isernia", "Termoli", "Campobasso"],
    Lombardy: [
      "Abbadia Lariana",
      "Abbiategrasso",
      "Adro",
      "Agrate Brianza",
      "Albano Sant'Alessandro",
      "Alzano Lombardo",
      "Angera",
      "Anzano del Parco",
      "Arcore",
      "Arese",
      "Arluno",
      "Artogne",
      "Bagnolo Mella",
      "Baranzate",
      "Bareggio",
      "Barzio",
      "Bellagio",
      "Berlingo",
      "Biassono",
      "Blevio",
      "Bodio Lomnago",
      "Bollate",
      "Bormio",
      "Bosco Ex Parmigiano",
      "Bresso",
      "Brivio",
      "Brugherio",
      "Buffalora-Bettole",
      "Busto Arsizio",
      "Busto Garolfo",
      "Cadenabbia",
      "Calolziocorte",
      "Canegrate",
      "Canonica",
      "Cantu",
      "Carate Brianza",
      "Caronno Pertusella",
      "Carugate",
      "Casalmaggiore",
      "Casalpusterlengo",
      "Cassano d'Adda",
      "Cassano Magnago",
      "Castano Primo",
      "Castegnato",
      "Castellanza",
      "Castel Mella",
      "Castiglione delle Stiviere",
      "Cernobbio",
      "Cernusco Lombardone",
      "Cernusco sul Naviglio",
      "Cerro Maggiore",
      "Cesano Boscone",
      "Cesano Maderno",
      "Cesate",
      "Chiaravalle",
      "Chiavenna",
      "Cilavegna",
      "Cinisello Balsamo",
      "Clusane",
      "Codogno",
      "Collebeato",
      "Cologne",
      "Cologno Monzese",
      "Concesio",
      "Corbetta",
      "Cormano",
      "Cornaredo",
      "Corsico",
      "Corte Franca",
      "Curno",
      "Cusano Milanino",
      "Dalmine",
      "Darfo Boario Terme",
      "Desenzano del Garda",
      "Desio",
      "Esine",
      "Fagnano Olona",
      "Figino",
      "Fonteno",
      "Gandellino",
      "Garbagnate Milanese",
      "Gardone Val Trompia",
      "Gavirate",
      "Gazzada Schianno",
      "Ghedi",
      "Giussano",
      "Gorgonzola",
      "Grassobbio",
      "Gussago",
      "Induno Olona",
      "Iseo",
      "Laglio",
      "Lainate",
      "Leno",
      "Linate",
      "Livigno",
      "Lodi",
      "Lonate Pozzolo",
      "Luino",
      "Lumezzane",
      "Malgrate",
      "Malnate",
      "Mandello del Lario",
      "Manerbio",
      "Melegnano",
      "Melzo",
      "Menaggio",
      "Merate",
      "Monte",
      "Montichiari",
      "Morbegno",
      "Mortara",
      "Moscazzano",
      "Mottella",
      "Muggiò",
      "Nerviano",
      "Nova Milanese",
      "Novate Milanese",
      "Novedrate",
      "Novegro-Tregarezzo-San Felice",
      "Olgiate Comasco",
      "Olgiate Olona",
      "Ornago",
      "Ospitaletto",
      "Ossuccio",
      "Osteno",
      "Ottobiano",
      "Padenghe sul Garda",
      "Paderno Dugnano",
      "Palazzolo sull'Oglio",
      "Pandino",
      "Parabiago",
      "Paratico",
      "Passirana",
      "Pasturo",
      "Paullo",
      "Pero",
      "Pieve Porto Morone",
      "Pioltello",
      "Pognana Lario",
      "Ponte San Pietro",
      "Porto Ceresio",
      "Rescaldina",
      "Rezzato",
      "Romano Banco",
      "Rovato",
      "Rozzano",
      "Salice Terme",
      "Salò",
      "Samarate",
      "San Bovio-San Felice",
      "San Donato Milanese",
      "San Fedele Intelvi",
      "San Fermo della Battaglia",
      "San Giuliano Milanese",
      "San Pellegrino Terme",
      "San Rocco al Porto",
      "San Sebastiano",
      "Sant'Antonio",
      "Sarezzo",
      "Schilpario",
      "Sedriano",
      "Segrate",
      "Senago",
      "Seriate",
      "Sesto San Giovanni",
      "Seveso",
      "Sirmione",
      "Siviano",
      "Solaro",
      "Solarolo",
      "Somma Lombardo",
      "Sormano",
      "Stezzano",
      "Stradella",
      "Sumirago",
      "Suzzara",
      "Telgate",
      "Tirano",
      "Tradate",
      "Travaco Siccomario",
      "Tremezzo",
      "Trezzano Rosa",
      "Trezzano sul Naviglio",
      "Trezzo sull'Adda",
      "Valmadrera",
      "Val Masino",
      "Varedo",
      "Varenna",
      "Verolanuova",
      "Vezza d'Oglio",
      "Viadana",
      "Villa",
      "Villanterio",
      "Villasanta",
      "Vimercate",
      "Vimodrone",
      "Vizzola Ticino",
      "Voghera",
      "Vigevano",
      "Varese",
      "Treviglio",
      "Travagliato",
      "Sondrio",
      "Seregno",
      "Saronno",
      "Rho",
      "Pavia",
      "Opera",
      "Nave",
      "Monza",
      "Milano",
      "Milan",
      "Meda",
      "Mariano Comense",
      "Mantova",
      "Magenta",
      "Lissone",
      "Limbiate",
      "Legnano",
      "Lecco",
      "Gallarate",
      "Erba",
      "Cremona",
      "Crema",
      "Concorezzo",
      "Como",
      "Chiari",
      "Carpenedolo",
      "Caravaggio",
      "Brescia",
      "Bergamo",
      "Albino"
    ],
    Liguria: [
      "Alassio",
      "Albenga",
      "Albisola Marina",
      "Bergeggi",
      "Bogliasco",
      "Borgio",
      "Camogli",
      "Celle Ligure",
      "Ceriale",
      "Cicagna",
      "Dolceacqua",
      "Finale Ligure",
      "La Spezia",
      "Lavagna",
      "Le Grazie",
      "Lerici",
      "Loano",
      "Moneglia",
      "Monterosso al Mare",
      "Noli",
      "Portofino",
      "Portovenere",
      "Pra",
      "Recco",
      "Riomaggiore",
      "Rocchetta Nervina",
      "San Remo",
      "Santa Margherita Ligure",
      "San Teodoro",
      "Sarzana",
      "Sassello",
      "Sestri Levante",
      "Taggia",
      "Valleggia",
      "Vernazza",
      "Villa Faraldi",
      "Ventimiglia",
      "Varazze",
      "Savona",
      "Sanremo",
      "Rapallo",
      "Imperia",
      "Genoa",
      "Chiavari",
      "Arenzano"
    ],
    Latium: [
      "Acilia-Castel Fusano-Ostia Antica",
      "Alatri",
      "Albano Laziale",
      "Anguillara",
      "Ariccia",
      "Artena",
      "Bolsena",
      "Borgorose",
      "Bracciano",
      "Campagnano di Roma",
      "Case Campoli-Panetta",
      "Cassino",
      "Castiglione in Teverina",
      "Cave",
      "Cerveteri",
      "Ciampino",
      "Cisterna di Latina",
      "Civita Castellana",
      "Colle Verde",
      "Ferentino",
      "Fiano Romano",
      "Fiumicino",
      "Fiumicino-Isola Sacra",
      "Formello",
      "Fossignano",
      "Frascati",
      "Fregene",
      "Genzano di Roma",
      "Grottaferrata",
      "Guidonia",
      "Guidonia Montecelio",
      "Jenne",
      "Ladispoli",
      "La Massimina-Casal Lumbroso",
      "Lariano",
      "Lido di Ostia",
      "Lubriano",
      "Marina di Ardea-Tor San Lorenzo",
      "Marina San Nicola",
      "Mentana",
      "Micigliano",
      "Minturno",
      "Molella",
      "Montefiascone",
      "Monte Migliore-La Selvotta",
      "Monterotondo",
      "Monte San Giovanni Campano",
      "Olevano Romano",
      "Ponza",
      "Priverno",
      "Rocca di Papa",
      "Roccasecca",
      "San Bartolomeo",
      "San Cesareo",
      "San Felice Circeo",
      "Santa Marinella",
      "Segni",
      "Selcetta",
      "Setteville",
      "Sezze",
      "Sperlonga",
      "Tarquinia",
      "Tivoli",
      "Tor Lupara",
      "Torvaianica",
      "Tuscania",
      "Valmontone",
      "Velletri",
      "Vetralla",
      "Villanova",
      "Vitinia",
      "Vitorchiano",
      "Zagarolo",
      "Viterbo",
      "Terracina",
      "Sora",
      "Rome",
      "Rieti",
      "Pomezia",
      "Palestrina",
      "Nettuno",
      "Marino",
      "Latina",
      "Gaeta",
      "Frosinone",
      "Formia",
      "Fondi",
      "Colleferro",
      "Civitavecchia",
      "Ceccano",
      "Ardea",
      "Aprilia",
      "Anzio"
    ],
    "Friuli Venezia Giulia": [
      "Azzano Decimo",
      "Buia",
      "Buttrio",
      "Cavazzo Carnico",
      "Codroipo",
      "Colugna",
      "Cordenons",
      "Duino",
      "Gemona",
      "Grado",
      "Lignano Sabbiadoro",
      "Lusevera",
      "Maniago",
      "Monfalcone",
      "Moruzzo",
      "Muggia",
      "Pontebba",
      "Porcia",
      "San Daniele del Friuli",
      "San Vito al Tagliamento",
      "Tarcento",
      "Tavagnacco",
      "Tramonti di Sopra",
      "Udine",
      "Trieste",
      "Sacile",
      "Rosa",
      "Pordenone",
      "Gorizia"
    ],
    "Emilia-Romagna": [
      "Albareto",
      "Arceto",
      "Baganzola",
      "Baggiovara",
      "Bardi",
      "Bedonia",
      "Bellaria-Igea Marina",
      "Bertinoro",
      "Bettola",
      "Borgonovo Valtidone",
      "Budrio",
      "Calestano",
      "Carpi Centro",
      "Casalecchio di Reno",
      "Castelfranco Emilia",
      "Castell'Arquato",
      "Castel Maggiore",
      "Castel San Giovanni",
      "Castel San Pietro Terme",
      "Colorno",
      "Coriano",
      "Faenza",
      "Fidenza",
      "Fiorano",
      "Fiorano Modenese",
      "Formigine",
      "Fosso Ghiaia",
      "Fratta Terme",
      "Gambettola",
      "Goro",
      "Limidi",
      "Lugo",
      "Maranello",
      "Marina di Ravenna",
      "Migliaro",
      "Misano Adriatico",
      "Modigliana",
      "Monghidoro",
      "Monterenzio",
      "Nonantola",
      "Novellara",
      "Osteria Grande",
      "Panighina",
      "Pianoro",
      "Piumazzo",
      "Ponticella",
      "Porto Garibaldi",
      "Reggio nell'Emilia",
      "Rivazzurra",
      "Riviera",
      "Rubiera",
      "Salsomaggiore Terme",
      "San Felice sul Panaro",
      "San Giovanni in Persiceto",
      "San Giuseppe",
      "San Lazzaro",
      "San Piero in Bagno",
      "Sant'Agata Bolognese",
      "Sant'Agata sul Santerno",
      "Santa Giustina",
      "Santa Monica-Cella",
      "Scandiano",
      "Soragna",
      "Spilamberto",
      "Varano de' Melegari",
      "Vigolzone",
      "Villanova",
      "Zola Predosa",
      "Vignola",
      "Sassuolo",
      "Rimini",
      "Riccione",
      "Ravenna",
      "Piacenza",
      "Parma",
      "Modena",
      "Mirandola",
      "Imola",
      "Guastalla",
      "Forlì",
      "Ferrara",
      "Correggio",
      "Cesenatico",
      "Cesena",
      "Cervia",
      "Cento",
      "Cattolica",
      "Carpi",
      "Bologna"
    ],
    Campania: [
      "Afragola",
      "Agerola",
      "Amalfi",
      "Anacapri",
      "Ariano Irpino-Martiri",
      "Arpino",
      "Arzano",
      "Atrani",
      "Aversa",
      "Bacoli",
      "Bagnoli",
      "Baiano",
      "Barano d'Ischia",
      "Baronissi",
      "Battipaglia",
      "Bellizzi",
      "Bivio Santa Cecilia",
      "Boscoreale",
      "Boscotrecase",
      "Brusciano",
      "Caiazzo",
      "Caivano",
      "Calvizzano",
      "Capezzano Inferiore",
      "Capri",
      "Capua",
      "Cardito",
      "Casagiove",
      "Casalnuovo di Napoli",
      "Casandrino",
      "Casavatore",
      "Casoria",
      "Castellammare di Stabia",
      "Castel Volturno",
      "Cava De Tirreni",
      "Cellole",
      "Cercola",
      "Cervinara",
      "Cicciano",
      "Cimitile",
      "Crispano",
      "Dragonea",
      "Eboli",
      "Ercolano",
      "Fisciano",
      "Forio",
      "Frattamaggiore",
      "Frattaminore",
      "Fuorni",
      "Giffoni Valle Piana",
      "Giugliano in Campania",
      "Gragnano",
      "Grumo Nevano",
      "Ischia Porto",
      "Lacco Ameno",
      "Laura",
      "Lauro",
      "Licinella-Torre di Paestum",
      "Limatola",
      "Lusciano",
      "Macerata Campania",
      "Maddaloni",
      "Marano di Napoli",
      "Marcianise",
      "Marigliano",
      "Marina di Camerota",
      "Marina di Casal Velino",
      "Massa Lubrense",
      "Masseria Vecchia Ovest",
      "Melito di Napoli",
      "Mercato San Severino",
      "Meta",
      "Minori",
      "Mondragone",
      "Monte di Procida",
      "Montefalcione",
      "Monterusciello",
      "Montesarchio",
      "Mugnano di Napoli",
      "Napoli",
      "Nocera Inferiore",
      "Nocera Superiore",
      "Nola",
      "Orta di Atella",
      "Ottaviano",
      "Palinuro",
      "Palma Campania",
      "Parete",
      "Piano di Sorrento",
      "Piazza-Tralia-Pendolo",
      "Piedimonte Matese",
      "Pietrelcina",
      "Pimonte",
      "Poggiomarino",
      "Policastro Bussentino",
      "Pollena Trocchia",
      "Pomigliano d'Arco",
      "Pompei",
      "Pontecagnano",
      "Portici",
      "Positano",
      "Praiano",
      "Pratole",
      "Procida",
      "Qualiano",
      "Quarto",
      "Ravello",
      "Sala Consilina",
      "San Felice A Cancello",
      "San Gennaro Vesuviano",
      "San Giorgio a Cremano",
      "San Giuseppe Vesuviano",
      "San Mango sul Calore",
      "San Marcellino",
      "San Marco",
      "San Marco Evangelista",
      "San Mauro Cilento",
      "San Michele",
      "San Nicola la Strada",
      "San Prisco",
      "Santa Maria a Vico",
      "Santa Maria Capua Vetere",
      "Sant'Anastasia",
      "Sant'Angelo",
      "Sant'Antimo",
      "Sant'Antonio Abate",
      "Sant'Arpino",
      "San Vitaliano",
      "Sarno",
      "Saviano",
      "Solofra",
      "Somma Vesuviana",
      "Terzigno",
      "Teverola",
      "Torre del Greco",
      "Trecase",
      "Trentola-Ducenta",
      "Vietri sul Mare",
      "Villa Literno",
      "Villammare",
      "Villaricca",
      "Volla",
      "Vico Equense",
      "Torre Annunziata",
      "Sorrento",
      "Scafati",
      "Salerno",
      "Pozzuoli",
      "Pagani",
      "Naples",
      "Ischia",
      "Caserta",
      "Benevento",
      "Avellino",
      "Angri",
      "Agropoli",
      "Acerra"
    ],
    Calabria: [
      "Amato",
      "Ardore Marina",
      "Cassano Allo Ionio",
      "Corigliano Calabro",
      "Corigliano Scalo",
      "Cutro",
      "Gioiosa Ionica",
      "Guardia Piemontese",
      "Lauro",
      "Le Castella",
      "Locri",
      "Maierato",
      "Marina di Gioiosa Ionica",
      "Montepaone Lido",
      "Nicastro-Sambiase",
      "Palmi",
      "Polistena",
      "Quattromiglia",
      "Ricadi",
      "Roccelletta",
      "Rossano Stazione",
      "San Giovanni in Fiore",
      "San Mango d'Aquino",
      "San Nicola Arcella",
      "San Nicolo",
      "Scalea",
      "Sellia Marina",
      "Serra Pedace",
      "Sibari",
      "Siderno",
      "Squillace Lido",
      "Taurianova",
      "Tropea",
      "Vibo Valentia",
      "Villa San Giovanni",
      "Rosarno",
      "Reggio Calabria",
      "Paola",
      "Lamezia Terme",
      "Gioia Tauro",
      "Crotone",
      "Cosenza",
      "Cirò Marina",
      "Catanzaro",
      "Castrovillari"
    ],
    Basilicate: [
      "Bernalda",
      "Castelmezzano",
      "Lavello",
      "Maratea",
      "Pomarico",
      "Roccanova",
      "Venosa",
      "Viggiano",
      "Potenza",
      "Policoro",
      "Melfi",
      "Matera"
    ],
    Apulia: [
      "Acquaviva delle Fonti",
      "Adelfia",
      "Alberobello",
      "Apricena",
      "Avetrana",
      "Bari",
      "Barletta",
      "Bitetto",
      "Bitonto",
      "Bitritto",
      "Borgagne",
      "Campi Salentina",
      "Canosa di Puglia",
      "Capurso",
      "Carovigno",
      "Casamassima",
      "Casarano",
      "Castellana",
      "Castellana Grotte",
      "Castellaneta",
      "Ceglie Messapica",
      "Conversano",
      "Copertino",
      "Corato",
      "Crispiano",
      "Felline",
      "Francavilla Fontana",
      "Gagliano del Capo",
      "Galatina",
      "Galatone",
      "Gemini",
      "Gioia del Colle",
      "Giovinazzo",
      "Gravina in Puglia",
      "Grumo Appula",
      "Lamie di Olimpie-Selva",
      "Laterza",
      "Latiano",
      "Leverano",
      "Lizzano",
      "Locorotondo",
      "Maglie",
      "Manduria",
      "Margherita di Savoia",
      "Marina di Ginosa",
      "Martano",
      "Maruggio",
      "Massafra",
      "Matino",
      "Mesagne",
      "Modugno",
      "Mola di Bari",
      "Monopoli",
      "Montalbano",
      "Monteiasi",
      "Monte Sant'Angelo",
      "Nardò",
      "Noci",
      "Noicattaro",
      "Oria",
      "Orta Nova",
      "Otranto",
      "Palo del Colle",
      "Paolo VI",
      "Parco Scizzo-Parchitello",
      "Peschici",
      "Polignano a Mare",
      "Porto Cesareo",
      "Ruvo di Puglia",
      "San Giorgio Ionico",
      "San Giovanni Rotondo",
      "Sannicandro di Bari",
      "Sannicandro Garganico",
      "Sannicola",
      "San Pancrazio Salentino",
      "San Paolo",
      "San Pietro in Lama",
      "San Pietro Vernotico",
      "San Severo",
      "Santa Cesarea Terme",
      "San Vito dei Normanni",
      "Seclì",
      "Squinzano",
      "Statte",
      "Surbo",
      "Taurisano",
      "Taviano",
      "Terlizzi",
      "Torre San Giovanni",
      "Trepuzzi",
      "Tricase",
      "Triggiano",
      "Trinitapoli",
      "Valenzano",
      "Veglie",
      "Vieste",
      "Turi",
      "Trani",
      "Torremaggiore",
      "Taranto",
      "Sava",
      "Rutigliano",
      "Putignano",
      "Palagiano",
      "Ostuni",
      "Mottola",
      "Molfetta",
      "Martina Franca",
      "Manfredonia",
      "Lucera",
      "Lecce",
      "Grottaglie",
      "Ginosa",
      "Gallipoli",
      "Foggia",
      "Fasano",
      "Cerignola",
      "Brindisi",
      "Bisceglie",
      "Andria",
      "Altamura"
    ],
    "Aosta Valley": [
      "Aosta",
      "Charvensod",
      "Cogne",
      "Courmayeur",
      "Paquier",
      "Pollein",
      "Saint-Vincent"
    ],
    Abruzzo: [
      "Avezzano",
      "Calascio",
      "Cappadocia",
      "Cappelle sul Tavo",
      "Castiglione a Casauria",
      "Controguerra",
      "Coppito",
      "Francavilla al Mare",
      "L’Aquila",
      "Martinsicuro",
      "Montesilvano Marina",
      "Ortona",
      "Pietracamela",
      "Pineto",
      "Roccaraso",
      "Roseto degli Abruzzi",
      "San Salvo",
      "Sant'Omero",
      "Sulmona",
      "Tagliacozzo",
      "Teramo",
      "Vasto",
      "Villa Raspa",
      "Silvi",
      "Pescara",
      "Lanciano",
      "Giulianova",
      "Chieti"
    ]
  },
  Israel: {
    "Tel Aviv": [
      "Azor",
      "Bat Yam",
      "Bene Beraq",
      "Bnei Brak",
      "Giv`atayim",
      "Giv‘atayim",
      "Giv`at Shemu'el",
      "Herzliya Pituah",
      "Herzliyya",
      "Jaffa",
      "Kefar Shemaryahu",
      "Or Yehuda",
      "Ramat HaSharon",
      "Yafo",
      "Tel Aviv",
      "Ramat Gan",
      "Kiryat Ono",
      "Holon",
      "Herzliya"
    ],
    "Southern District": [
      "Arad",
      "Ashdod",
      "Ashqelon",
      "Ashquelon",
      "Beersheba",
      "Dimona",
      "`En Boqeq",
      "Kiryat Gat",
      "Lehavim",
      "Midreshet Ben-Gurion",
      "Mitzpe Ramon",
      "Ofakim",
      "Ofaqim",
      "Qiryat Gat",
      "Sderot",
      "Sederot",
      "Yeroham",
      "Rahat",
      "Netivot",
      "Eilat"
    ],
    "Northern District": [
      "Afula",
      "`Akko",
      "Bet She'an",
      "Dan",
      "El Mazra`a",
      "Er Reina",
      "Judeida Makr",
      "Kafir Yasif",
      "Kafr Misr",
      "Karmi’el",
      "Kaukab Abu el Hija",
      "Kefar Rosh HaNiqra",
      "Kefar Tavor",
      "Maghar",
      "Migdal Ha‘Emeq",
      "Nahariya",
      "Qiryat Shemona",
      "Ramat Yishay",
      "Rosh Pinna",
      "Safed",
      "Sakhnin",
      "Tamra",
      "Tiberias",
      "Nazareth"
    ],
    Jerusalem: [
      "Abu Ghaush",
      "Ariel",
      "Bet Shemesh",
      "Mevo Betar",
      "Modiin Ilit",
      "West Jerusalem",
      "Jerusalem"
    ],
    Haifa: [
      "`Atlit",
      "El Fureidis",
      "Nesher",
      "Qesarya",
      "Qiryat Ata",
      "Qiryat Bialik",
      "Qiryat Moẕqin",
      "Qiryat Yam",
      "Tirat Carmel",
      "Tirat Karmel",
      "Umm el Fahm",
      "Haifa",
      "Hadera"
    ],
    "Central District": [
      "Bet Dagan",
      "Even Yehuda",
      "Gan Yavne",
      "Gedera",
      "Hod HaSharon",
      "Jaljulya",
      "Kafr Qasim",
      "Kefar Habad",
      "Kfar Yona",
      "Lod",
      "Modiin",
      "Ness Ziona",
      "Petaẖ Tiqwa",
      "Qalansuwa",
      "Raanana",
      "Ra'anana",
      "Ramla",
      "Rishon LeZiyyon",
      "Rosh Ha‘Ayin",
      "Shoham",
      "Tirah",
      "Yehud",
      "Yavne",
      "Rehovot",
      "Netanya",
      "Kfar Saba"
    ],
    "": ["Ariel"]
  },
  "Isle of Man": {
    Douglas: ["Douglas"],
    "Port Erin": ["Port Erin"],
    "": ["Douglas"]
  },
  Ireland: {
    Ulster: [
      "Buncrana",
      "Carndonagh",
      "Convoy",
      "Donegal",
      "Letterkenny",
      "Monaghan",
      "Ramelton"
    ],
    Munster: [
      "Adare",
      "Annacotty",
      "Blarney",
      "Cahersiveen",
      "Carrick-on-Suir",
      "Carrigaline",
      "Castlemartyr",
      "Cill Airne",
      "Clonmel",
      "Cluain Meala",
      "Cobh",
      "Dingle",
      "Dunmore East",
      "Ennis",
      "Hospital",
      "Island",
      "Killorglin",
      "Kilrush",
      "Luimneach",
      "Mallow",
      "Passage West",
      "Tralee",
      "Waterford",
      "Limerick",
      "Cork"
    ],
    Leinster: [
      "An Muileann gCearr",
      "Arklow",
      "Athgarvan",
      "Athlone",
      "Athy",
      "Balally",
      "Balbriggan",
      "Ballinteer",
      "Beaumont",
      "Blackrock",
      "Blanchardstown",
      "Bray",
      "Cabinteely",
      "Cabra",
      "Carlingford",
      "Castleknock",
      "Celbridge",
      "Clane",
      "Clondalkin",
      "Crumlin",
      "Donaghmede",
      "Donnybrook",
      "Droichead Nua",
      "Drumcondra",
      "Dundalk",
      "Dún Laoghaire",
      "Enniscorthy",
      "Finglas",
      "Foxrock",
      "Gorey",
      "Graiguenamanagh",
      "Greystones",
      "Howth",
      "Kilcock",
      "Kilcullen",
      "Kildare",
      "Kinnegad",
      "Laytown",
      "Leixlip",
      "Loch Garman",
      "Lucan",
      "Malahide",
      "Marino",
      "Maynooth",
      "Mount Merrion",
      "Navan",
      "New Ross",
      "Oldbawn",
      "Rathmines",
      "Rialto",
      "Ringsend",
      "Sandyford",
      "Shankill",
      "Skerries",
      "Stradbally",
      "Wicklow",
      "Wexford",
      "Tullamore",
      "Tallaght",
      "Swords",
      "Portlaoise",
      "Naas",
      "Mullingar",
      "Kilkenny",
      "Dundrum",
      "Dublin",
      "Drogheda",
      "Carlow",
      "Ashbourne"
    ],
    Connaught: [
      "Ballina",
      "Ballinrobe",
      "Boyle",
      "Castlebar",
      "Clifden",
      "Gaillimh",
      "Oranmore",
      "Westport",
      "Sligo",
      "Galway"
    ]
  },
  "Northern Mariana Islands": { Saipan: ["Saipan"] },
  "Norfolk Island": {},
  Niue: {},
  Iraq: {
    "Al Muthanna": ["Ar Rumaythah", "As Salman", "As Samawah"],
    "Al Qadisiyah": ["Ad Diwaniyah"],
    Anbar: ["Al Fallujah", "Fallujah", "Hit", "Ramadi"],
    "An Najaf": ["An Najaf", "Kufa", "Najaf"],
    Arbil: ["Erbil", "Ruwandiz", "Shaqlawah", "Xebat"],
    "As Sulaymaniyah": [
      "As Sulaymaniyah",
      "Derbendixan",
      "Halabjah al Jadidah",
      "Jamjamal",
      "Kelar",
      "Qeladize",
      "Qeredagh",
      "Raniye",
      "Seyid Sadiq"
    ],
    Babil: ["Al Hillah"],
    "Basra Governorate": [
      "Abi al Khasib",
      "Al Basrah",
      "Al Basrah al Qadimah",
      "Al Qurnah",
      "Az Zubayr",
      "Umm Qasr"
    ],
    Dahuk: ["Dihok", "Saymayl", "Zawitah", "Zaxo"],
    "Dhi Qar": ["An Nasiriyah", "Ar Rifa`i", "Ash Shatrah"],
    Diyala: ["Al Khalis", "Al Miqdadiyah", "Baqubah", "Khanaqin"],
    "Muhafazat Babil": ["Al Hillah"],
    "Muhafazat Kirkuk": ["Dibis", "Kirkuk"],
    "Muhafazat Wasit": ["Kut"],
    Ninawa: ["'Aqrah", "Mosul"],
    Nineveh: ["Mosul"],
    "Salah ad Din Governorate": [
      "Ad Dawr",
      "Ad Dujayl",
      "Balad",
      "Bayji",
      "Samarra'",
      "Tikrit",
      "Tozkhurmato"
    ],
    Wasit: ["Al Kut", "As Suwayrah"],
    "Salah ad Din": ["Tikrit"],
    "Muhafazat Karbala'": ["Karbala"],
    "Muhafazat Arbil": ["Erbil"],
    Maysan: ["Al `Amarah", "Al Maymunah"],
    "Mayorality of Baghdad": ["Abu Ghurayb", "At Tarmiyah", "Baghdad"],
    Kirkuk: ["Kirkuk"],
    Basra: ["Basrah"],
    Baghdad: ["Baghdad"]
  },
  "Iran, Islamic Republic of": {
    Ardabil: ["Ardabil"],
    "Azarbayjan-e Gharbi": [
      "Bukan",
      "Mahabad",
      "Maku",
      "Miandoab",
      "Orumiyeh",
      "Piranshahr"
    ],
    "Chaharmahal and Bakhtiari": ["Shahrekord"],
    Gilan: ["Bandar-e Anzali", "Fuman", "Hashtpar", "Rasht"],
    Golestan: [
      "Bandar-e Gaz",
      "Bandar-e Torkaman",
      "Gonbad-e Kavus",
      "Gorgan",
      "Ramian"
    ],
    Hamadan: ["Hamadan", "Kabudarahang"],
    Ilam: ["Dehloran", "Eyvan", "Ilam"],
    Kermanshah: ["Javanrud", "Kermanshah", "Paveh"],
    "Khorasan-e Jonubi": ["Birjand"],
    "Kohgiluyeh va Buyer Ahmad": ["Dehdasht", "Dogonbadan"],
    Kordestan: ["Baneh", "Divandarreh", "Marivan", "Sanandaj", "Sarvabad"],
    Lorestan: ["Dorud", "Khorramabad"],
    "Ostan-e Ardabil": ["Ardabil"],
    "Ostan-e Hamadan": ["Hamadan", "Malayer"],
    "Ostan-e Ilam": ["Ilam"],
    "Ostan-e Khorasan-e Shomali": ["Shirvan"],
    "Ostan-e Lorestan": ["Borujerd"],
    Qazvin: ["Qazvin", "Takestan"],
    Qom: ["Qom"],
    "Sistan and Baluchestan": ["Chabahar", "Nikshahr", "Rasak", "Zahedan"],
    Tehran: [
      "Damavand",
      "Eslamshahr",
      "Pishva",
      "Shahre Jadide Andisheh",
      "Shahr-e Qods",
      "Shahriar",
      "Tajrish",
      "Tehran"
    ],
    Zanjan: ["Abhar", "Alvand", "Zanjan"],
    Yazd: ["Abarkuh", "Khavas Kuh", "Meybod", "Tabas", "Taft", "Yazd"],
    Semnān: ["Mahdishahr", "Shahrud", "Semnan"],
    "Razavi Khorasan": ["Nishabur", "Sabzevar", "Mashhad"],
    "Ostan-e Tehran": ["Shahriar", "Varamin", "Tehran", "Damavand"],
    "Ostan-e Qazvin": ["Qazvin"],
    "Ostan-e Khorasan-e Jonubi": ["Birjand"],
    "Ostan-e Kermanshah": ["Kermanshah"],
    "Ostan-e Golestan": ["Gorgan"],
    "Ostan-e Gilan": ["Rasht"],
    "Ostan-e Azarbayjan-e Gharbi": ["Urmia", "Mahabad"],
    Māzandarān: [
      "`Abbasabad",
      "Babol",
      "Babolsar",
      "Behshahr",
      "Chalus",
      "Fereydunkenar",
      "Nowshahr",
      "Nur",
      "Qa'em Shahr",
      "Ramsar",
      "Tonekabon",
      "Sari",
      "Neka",
      "Amol"
    ],
    Markazi: ["Khomeyn", "Saveh", "Arak"],
    Khuzestan: [
      "Andimeshk",
      "Bandar-e Mahshahr",
      "Behbahan",
      "Dezful",
      "Izeh",
      "Khorramshahr",
      "Masjed Soleyman",
      "Ramhormoz",
      "Shush",
      "Ahvaz",
      "Abadan"
    ],
    Kerman: [
      "Bam",
      "Kuhbanan",
      "Manujan",
      "Qal`eh Ganj",
      "Rafsanjan",
      "Sirjan",
      "Zarand",
      "Kerman"
    ],
    Isfahan: ["Kashan", "Rehnan", "Isfahan"],
    Hormozgan: [
      "Bandar 'Abbas",
      "Bandar-e Khamir",
      "Bandar-e Lengeh",
      "Jask",
      "Minab",
      "Qeshm",
      "Kish"
    ],
    Fars: ["Lamerd", "Lar", "Marvdasht", "Shiraz"],
    "East Azerbaijan": [
      "Ahar",
      "Maragheh",
      "Marand",
      "Mianeh",
      "Osku",
      "Tabriz"
    ],
    Bushehr: [
      "Bandar-e `Asaluyeh",
      "Bandar-e Bushehr",
      "Bandar-e Ganaveh",
      "Borazjan",
      "Deyr",
      "Jam",
      "Kangan",
      "Bushehr"
    ],
    Alborz: ["Karaj"]
  },
  Indonesia: {
    "Maluku Utara": ["Bobong", "Jambulang", "Labuha", "Sofifi", "Ternate"],
    "Sulawesi Barat": ["Majene", "Polewali"],
    "Sulawesi Tenggara": ["Katabu", "Kendari", "Lasehao"],
    Yogyakarta: ["Yogyakarta", "Sleman", "Bantul"],
    "West Sumatra": [
      "Lubukbergalung",
      "Mandarahan",
      "Maninjau",
      "Muara Siberut",
      "Padangpanjang",
      "Sijunjung",
      "Tabing",
      "Solok",
      "Payakumbuh",
      "Pariaman",
      "Padang",
      "Bukittinggi"
    ],
    "West Sulawesi": ["Majene", "Mamuju"],
    "West Papua": ["Bintuni", "Manokwari", "Saonek", "Sorong"],
    "West Nusa Tenggara": [
      "Bangekdewa",
      "Bima",
      "Gili Air",
      "Jelateng Timur",
      "Kampungbaru",
      "Karangbugis",
      "Karang Kuripan",
      "Karangsubagan",
      "Kute",
      "Labuhanaji",
      "Lelongken",
      "Mambalan",
      "Meraran",
      "Pemenang",
      "Prawira",
      "Praya",
      "Sekongkang Bawah",
      "Selong",
      "Sembalunbumbung",
      "Sembalunlawang",
      "Sumbawa Besar",
      "Tawun",
      "Woja",
      "Mataram"
    ],
    "West Kalimantan": [
      "Bengkayang",
      "Manismata",
      "Mempawah",
      "Pemangkat",
      "Sungai Raya",
      "Singkawang",
      "Pontianak"
    ],
    "West Java": [
      "Arjawinangun",
      "Banjaran",
      "Bunisakit",
      "Cangkuang",
      "Caringin",
      "Ciampea",
      "Cibinong",
      "Cicurug",
      "Cikupa",
      "Cileungsir",
      "Cileunyi",
      "Cimahi",
      "Cimaja",
      "Ciranjang-hilir",
      "Citeureup",
      "Curug",
      "Depok",
      "Desa Benteng",
      "Gunungbatu",
      "Jatibarang",
      "Karangsembung",
      "Kawalu",
      "Kresek",
      "Margabakti",
      "Margahayukencana",
      "Neglasari",
      "Padalarang",
      "Pakuwon",
      "Palimanan",
      "Pamanukan",
      "Pameungpeuk",
      "Pamulang",
      "Parung",
      "Pasarkemis",
      "Paseh",
      "Pasirangin Satu",
      "Pelabuhanratu",
      "Rengasdengklok",
      "Sambungjaya",
      "Sawangan",
      "Singaparna",
      "Soreang",
      "Sumber",
      "Wanaraja",
      "Weru",
      "Teluknaga",
      "Tasikmalaya",
      "Sumedang",
      "Sukabumi",
      "Serpong",
      "Purwakarta",
      "Ngawi",
      "Majalengka",
      "Lembang",
      "Kuningan",
      "Indramayu",
      "Cirebon",
      "Ciputat",
      "Cikarang",
      "Cikampek",
      "Ciamis",
      "Bogor",
      "Bekasi",
      "Banjar",
      "Bandung"
    ],
    "South Sumatra": [
      "Baturaja",
      "Lubuklinggau",
      "Pageralam",
      "Plaju",
      "Prabumulih",
      "Sekayu",
      "Talangbetutu",
      "Tanjungagung",
      "Terawas",
      "Palembang",
      "Lahat"
    ],
    "South Sulawesi": [
      "Bantaeng",
      "Galesong",
      "Pangkajene",
      "Rantepang",
      "Rantepao",
      "Sinjai",
      "Uluale",
      "Watampone",
      "Sengkang",
      "Parepare",
      "Palopo",
      "Makassar"
    ],
    "South Kalimantan": [
      "Amuntai",
      "Banjarbaru",
      "Barabai",
      "Batulicin",
      "Birayang",
      "Kandangan",
      "Karangintan",
      "Martapura",
      "Palaihari",
      "Satui",
      "Tanjung",
      "Banjarmasin"
    ],
    "Southeast Sulawesi": ["Kendari"],
    "Riau Islands": ["Tanjungpinang", "Urung", "Tanjung Pinang", "Kijang"],
    Riau: [
      "Balaipungut",
      "Pulaukijang",
      "Siak Sri Indrapura",
      "Pekanbaru",
      "Dumai"
    ],
    Papua: ["Abepura", "Biak", "Nabire", "Sentani", "Jayapura"],
    "North Sumatra": [
      "Ambarita",
      "Berastagi",
      "Binjai",
      "Deli Tua",
      "Kabanjahe",
      "Padangsidempuan",
      "Perbaungan",
      "Percut",
      "Rantauprapat",
      "Sampuran",
      "Siabu",
      "Sibolga",
      "Sunggal",
      "Tebingtinggi",
      "Tongging",
      "Stabat",
      "Pematangsiantar",
      "Medan",
      "Kisaran"
    ],
    "North Sulawesi": [
      "Pineleng",
      "Tondano",
      "Wori",
      "Tomohon",
      "Manado",
      "Bitung"
    ],
    "North Maluku": ["Sofifi", "Ternate"],
    "North Kalimantan": [
      "Long Pujungan",
      "Sungaiboh",
      "Tanjung Palas",
      "Tarakan"
    ],
    Maluku: [
      "Amahai",
      "Ambon",
      "Ambon City",
      "Kairatu",
      "Kota Ternate",
      "Tual"
    ],
    Lampung: [
      "Bandarlampung",
      "Bukitkemuning",
      "Kedaton",
      "Kenali",
      "Kotaagung",
      "Kotabumi",
      "Krui",
      "Panjang",
      "Surabaya",
      "Terbanggi Besar",
      "Metro",
      "Bandar Lampung"
    ],
    Jambi: ["Jambi City", "Mendaha", "Sekernan", "Sungai Penuh", "Simpang"],
    Jakarta: ["Jakarta"],
    Gorontalo: ["Tilamuta", "Gorontalo"],
    "East Nusa Tenggara": [
      "Airnona",
      "Cempa",
      "Ende",
      "Kamalaputi",
      "Komodo",
      "Lewoleba",
      "Mesa",
      "Nemberala",
      "Padedewatu",
      "Retijawa",
      "Ruteng",
      "Soe",
      "Tebara",
      "Waewaso",
      "Waingapu",
      "Kupang"
    ],
    "East Kalimantan": [
      "Batusopang",
      "City of Balikpapan",
      "Loa Janan",
      "Loakulu",
      "Longkali",
      "Muarabadak",
      "Samboja",
      "Samarinda",
      "Bontang",
      "Balikpapan"
    ],
    "East Java": [
      "Babat",
      "Bandung",
      "Bangil",
      "Bangkalan",
      "Banjar",
      "Boyolangu",
      "Buduran",
      "Dampit",
      "Depok",
      "Driyorejo",
      "Gambiran Satu",
      "Gampengrejo",
      "Gedangan",
      "Gongdanglegi Kulon",
      "Gubengairlangga",
      "Gubugklakah Barat",
      "Gununganyar",
      "Jagirsidosermo",
      "Kamal",
      "Kebomas",
      "Kedungasem",
      "Kedungwaru",
      "Kemiri Gede",
      "Kemuning",
      "Kencong",
      "Kepanjen",
      "Kertosono",
      "Krajan",
      "Kraksaan",
      "Krian",
      "Kudusan",
      "Labansari",
      "Lawang",
      "Merakurak",
      "Merik",
      "Mojoagung",
      "Mulyorejo",
      "Pacarkeling",
      "Paciran",
      "Pakisaji",
      "Pandaan",
      "Pare",
      "Plalangan",
      "Prapen",
      "Prigen",
      "Sedatigede",
      "Semampir",
      "Siderejo",
      "Singojuruh",
      "Singosari",
      "Soko",
      "Sonogunting",
      "Sritanjung",
      "Srono",
      "Tanggulangin",
      "Temenggungan",
      "Tulangan Utara",
      "Wedoro",
      "Wongsorejo",
      "Tulungagung",
      "Tuban",
      "Trenggalek",
      "Surabaya",
      "Sumenep",
      "Situbondo",
      "Sidoarjo",
      "Sampang",
      "Probolinggo",
      "Ponorogo",
      "Pasuruan",
      "Panji",
      "Pamekasan",
      "Nganjuk",
      "Mojokerto",
      "Malang",
      "Madiun",
      "Lumajang",
      "Lamongan",
      "Kediri",
      "Jombang",
      "Jember",
      "Gresik",
      "Bondowoso",
      "Bojonegoro",
      "Blitar",
      "Batu",
      "Banyuwangi"
    ],
    "Daerah Istimewa Yogyakarta": [
      "Bantul",
      "Gamping Lor",
      "Godean",
      "Kasihan",
      "Melati",
      "Pandak",
      "Pundong",
      "Sewon",
      "Sleman",
      "Srandakan",
      "Wonosari",
      "Yogyakarta",
      "Depok"
    ],
    "Central Sulawesi": ["Donggala", "Luwuk", "Poso", "Tentena", "Palu"],
    "Central Kalimantan": [
      "Kualakapuas",
      "Kumai",
      "Pangkalanbuun",
      "Petakbehandang",
      "Semuda",
      "Sampit",
      "Palangkaraya"
    ],
    "Central Java": [
      "Ambarawa",
      "Baki",
      "Balapulang",
      "Baturaden",
      "Buaran",
      "Candi Prambanan",
      "Cepu",
      "Colomadu",
      "Comal",
      "Dukuhturi",
      "Gombong",
      "Grogol",
      "Jaten",
      "Jatiroto",
      "Jogonalan",
      "Karanganom",
      "Kedungwuni",
      "Kroya",
      "Kutoarjo",
      "Lasem",
      "Margasari",
      "Mertoyudan",
      "Mlonggo",
      "Mranggen",
      "Muntilan",
      "Pecangaan",
      "Purwodadi",
      "Purwodadi Grobogan",
      "Rembangan",
      "Selogiri",
      "Sidareja",
      "Trucuk",
      "Wangon",
      "Wedi",
      "Wiradesa",
      "Wonosobo",
      "Weleri",
      "Ungaran",
      "Tegal",
      "Surakarta",
      "Sragen",
      "Semarang",
      "Salatiga",
      "Purwokerto",
      "Purbalingga",
      "Pemalang",
      "Pekalongan",
      "Pati",
      "Magelang",
      "Kudus",
      "Klaten",
      "Kartasura",
      "Demak",
      "Boyolali",
      "Blora",
      "Batang",
      "Banyumas"
    ],
    Bengkulu: ["Curup", "Bengkulu"],
    Banten: [
      "Citeureup",
      "Curug",
      "Labuan",
      "Rangkasbitung",
      "Timurjaya",
      "Tangerang",
      "South Tangerang",
      "Serang",
      "Pandeglang"
    ],
    "Bangka–Belitung Islands": [
      "Manggar",
      "Muntok",
      "Sungailiat",
      "Tanjung Pandan",
      "Pangkalpinang"
    ],
    Bali: [
      "Banjar",
      "Banjar Ababi",
      "Banjar Ambengan",
      "Banjar Batanbuah",
      "Banjar Batanpoh",
      "Banjar Buahan",
      "Banjar Delodrurung",
      "Banjar Desa",
      "Banjar Gunungpande",
      "Banjar Jabejero",
      "Banjar Kampungbugis",
      "Banjar Karangsuling",
      "Banjar Kedisan",
      "Banjar Kelodan",
      "Banjar Kembangsari",
      "Banjar Kerobokan",
      "Banjar Kertajiwa",
      "Banjar Kertasari",
      "Banjar Mambalkajanan",
      "Banjar Manukayaanyar",
      "Banjar Medura",
      "Banjar Pande",
      "Banjar Parekan",
      "Banjar Pasekan",
      "Banjar Pesalakan",
      "Banjar Sedang",
      "Banjar Sempidi",
      "Banjar Taman",
      "Banjar Taro Kelod",
      "Banjar Tebongkang",
      "Banjar Tengah",
      "Banjar Triwangsakeliki",
      "Banjar Triwangsakelusa",
      "Banjar Trunyan",
      "Biaslantang Kaler",
      "Bualu",
      "Bungkulan",
      "Bunutan",
      "Dalangdesa",
      "Jabajero",
      "Jungutbatu Kaja Dua",
      "Kangin",
      "Karyadharma",
      "Kelanabian",
      "Kubu",
      "Kuta",
      "Kutampi",
      "Lembongan Kawan",
      "Loloan Timur",
      "Munduk",
      "Negara",
      "Pecatu",
      "Ped",
      "Pegongan",
      "Pekutatan",
      "Peneng",
      "Tampekan",
      "Tegalalang",
      "Ubud",
      "Tabanan",
      "Singaraja",
      "Klungkung",
      "Karangasem",
      "Jimbaran",
      "Denpasar"
    ],
    Aceh: [
      "Bireun",
      "Karangbaru",
      "Lampuuk",
      "Mata Ie",
      "Meulaboh",
      "Peureulak",
      "Sabang",
      "Sigli",
      "Singkil",
      "Susoh",
      "Tapaktuan",
      "Terangun",
      "Lhokseumawe",
      "Langsa",
      "Banda Aceh"
    ]
  },
  India: {
    "Andaman and Nicobar": ["Bamboo Flat", "Port Blair"],
    "Dadra and Nagar Haveli": ["Amli", "Silvassa"],
    Kashmir: ["Anantnag", "Jammu", "Kishtwar", "Leh", "Pahalgam", "Srinagar"],
    Laccadives: ["Kavaratti"],
    NCT: ["Delhi", "Karol Bagh", "Nangloi Jat", "New Delhi", "Pitampura"],
    Pondicherry: ["Puducherry"],
    "Union Territory of Puducherry": ["Karaikal", "Puducherry"],
    "West Bengal": [
      "Asansol",
      "Bagdogra",
      "Bali",
      "Balurghat",
      "Bara Bazar",
      "Baranagar",
      "Barasat",
      "Baruipur",
      "Bolpur",
      "Chandannagar",
      "Dam Dam",
      "Darjeeling",
      "Darjiling",
      "Diamond Harbour",
      "Dinhata",
      "Dum Dum",
      "Falakata",
      "Garui",
      "Habra",
      "Haldia",
      "Halisahar",
      "Howrah",
      "Jalpaiguri",
      "Kalimpang",
      "Kalna",
      "Kanchrapara",
      "Kharagpur",
      "Krishnanagar",
      "Mahiari",
      "Memari",
      "Murshidabad",
      "Naihati",
      "Naksalbari",
      "Pandua",
      "Puruliya",
      "Raiganj",
      "Ranaghat",
      "Raniganj",
      "Rishra",
      "Serampore",
      "Shiliguri",
      "Siliguri",
      "Singur",
      "Taki",
      "Kolkata",
      "Kalyani",
      "Durgapur",
      "Bankura"
    ],
    "Uttar Pradesh": [
      "Agra",
      "Akbarpur",
      "Amanpur",
      "Amethi",
      "Babina",
      "Bagpat",
      "Behat",
      "Bhadohi",
      "Budaun",
      "Bulandshahr",
      "Chandpur",
      "Dadri",
      "Deoband",
      "Deoria",
      "Etawah",
      "Farrukhabad",
      "Fatehgarh",
      "Fatehpur",
      "Firozabad",
      "Fyzabad",
      "Gangoh",
      "Ghazipur",
      "Gorakhpur",
      "Greater Noida",
      "Hamirpur",
      "Hapur",
      "Hathras",
      "Jaunpur",
      "Jhansi",
      "Kairana",
      "Kannauj",
      "Kanth",
      "Kiratpur",
      "Kosi",
      "Lakhimpur",
      "Loni",
      "Mainpuri",
      "Manjhanpur",
      "Mathura",
      "Mawana",
      "Meerut",
      "Mirzapur",
      "Moradabad",
      "Muradnagar",
      "Muzaffarnagar",
      "Padrauna",
      "Pilibhit",
      "Raebareli",
      "Rath",
      "Renukoot",
      "Saharanpur",
      "Sambhal",
      "Shamli",
      "Sitapur",
      "Sultanpur",
      "Unnao",
      "Vrindavan",
      "Varanasi",
      "Rampur",
      "Orai",
      "Noida",
      "Mohan",
      "Mau",
      "Lucknow",
      "Kanpur",
      "Ghaziabad",
      "Basti",
      "Bareilly",
      "Banda",
      "Bahraich",
      "Bah",
      "Azamgarh",
      "Ayodhya",
      "Allahabad",
      "Aligarh"
    ],
    Uttarakhand: [
      "Almora",
      "Bazpur",
      "Chakrata",
      "Dehradun",
      "Haridwar",
      "Kashipur",
      "Khatima",
      "Kichha",
      "Kotdwara",
      "Mussoorie",
      "Nainital",
      "Naini Tal",
      "Pauri",
      "Pithoragarh",
      "Ramnagar",
      "Ranikhet",
      "Roorkee",
      "Rudraprayag",
      "Uttarkashi",
      "Rishikesh",
      "Haldwani"
    ],
    Tripura: ["Agartala", "Dharmanagar", "Kailashahar"],
    Telangana: [
      "Adilabad",
      "Balapur",
      "Gaddi Annaram",
      "Khammam",
      "Kukatpalli",
      "Lal Bahadur Nagar",
      "Malkajgiri",
      "Mancherial",
      "Medak",
      "Nalgonda",
      "Narayanpet",
      "Nirmal",
      "Nizamabad",
      "Quthbullapur",
      "Secunderabad",
      "Serilingampalle",
      "Suriapet",
      "Tandur",
      "Uppal Kalan",
      "Warangal",
      "Karimnagar",
      "Hyderabad"
    ],
    "Tamil Nadu": [
      "Alandur",
      "Ambattur",
      "Ambur",
      "Anthiyur",
      "Arakkonam",
      "Arcot",
      "Ariyalur",
      "Arni",
      "Attur",
      "Avadi",
      "Bhavani",
      "Bodinayakkanur",
      "Chetput",
      "Chidambaram",
      "Colachel",
      "Cuddalore",
      "Dharapuram",
      "Dharmapuri",
      "Dindigul",
      "Erode",
      "Gandhi Nagar",
      "Gingee",
      "Gobichettipalayam",
      "Gudalur",
      "Guduvancheri",
      "Harur",
      "Hosur",
      "Injambakkam",
      "Kanchipuram",
      "Kangayam",
      "Kanniyakumari",
      "Karaikudi",
      "Karur",
      "Keelakarai",
      "Kodaikanal",
      "Korampallam",
      "Kotagiri",
      "Kovilpatti",
      "Krishnagiri",
      "Madipakkam",
      "Manali",
      "Mannargudi",
      "Mettur",
      "Nagercoil",
      "Namakkal",
      "Palani",
      "Palladam",
      "Pallavaram",
      "Pallipattu",
      "Panruti",
      "Pattukkottai",
      "Perambalur",
      "Perundurai",
      "Perungudi",
      "Pollachi",
      "Polur",
      "Pudukkottai",
      "Ramanathapuram",
      "Rameswaram",
      "Saint Thomas Mount",
      "Sattur",
      "Sivaganga",
      "Sivakasi",
      "Sriperumbudur",
      "Sulur",
      "Tambaram",
      "Thiruvarur",
      "Thoothukudi",
      "Tiruchengode",
      "Tiruchirappalli",
      "Tirunelveli",
      "Tiruppur",
      "Tiruvannamalai",
      "Villupuram",
      "Vriddhachalam",
      "Wellington",
      "Vellore",
      "Thanjavur",
      "Salem",
      "Ooty",
      "Madurai",
      "Kumbakonam",
      "Coimbatore",
      "Chennai",
      "Avinashi"
    ],
    Sikkim: ["Gangtok"],
    Rajasthan: [
      "Abu",
      "Ajmer",
      "Alwar",
      "Bagar",
      "Beawar",
      "Behror",
      "Bharatpur",
      "Bhinmal",
      "Bhiwadi",
      "Bikaner",
      "Bilara",
      "Bundi",
      "Chaksu",
      "Chhabra",
      "Chittaurgarh",
      "Churu",
      "Dausa",
      "Didwana",
      "Dungarpur",
      "Fatehpur",
      "Ganganagar",
      "Jaitaran",
      "Jalor",
      "Jhalawar",
      "Jhunjhunun",
      "Karauli",
      "Kekri",
      "Kishangarh",
      "Kotputli",
      "Kuchaman",
      "Ladnun",
      "Losal",
      "Makrana",
      "Malpura",
      "Manoharpur",
      "Merta",
      "Mount Abu",
      "Nagaur",
      "Nathdwara",
      "Nokha",
      "Phulera",
      "Pilani",
      "Pilibangan",
      "Pipar",
      "Pokaran",
      "Pratapgarh",
      "Pushkar",
      "Raipur",
      "Rajsamand",
      "Rawatbhata",
      "Ringas",
      "Sardarshahr",
      "Sawai Madhopur",
      "Shahpura",
      "Sikar",
      "Sujangarh",
      "Suratgarh",
      "Udaipur",
      "Tonk",
      "Sirohi",
      "Rajgarh",
      "Pali",
      "Nawalgarh",
      "Nawa",
      "Nagar",
      "Kota",
      "Jodhpur",
      "Jaisalmer",
      "Jaipur",
      "Hanumangarh",
      "Dig",
      "Bhilwara",
      "Barmer",
      "Baran",
      "Banswara",
      "Balotra"
    ],
    Punjab: [
      "Adampur",
      "Ajnala",
      "Amloh",
      "Anandpur",
      "Banga",
      "Banur",
      "Barnala",
      "Batala",
      "Begowal",
      "Bhadaur",
      "Bhatinda",
      "Bhawanigarh",
      "Bhikhi",
      "Bhogpur",
      "Budhlada",
      "Dasuya",
      "Dhanaula",
      "Dhariwal",
      "Dhilwan",
      "Dhuri",
      "Dirba",
      "Doraha",
      "Faridkot",
      "Fazilka",
      "Ferozepore",
      "Garhshankar",
      "Hoshiarpur",
      "Jagraon",
      "Jaito",
      "Jalalabad",
      "Kharar",
      "Kotkapura",
      "Malaut",
      "Maur",
      "Mohali",
      "Morinda",
      "Mukerian",
      "Muktsar",
      "Nabha",
      "Nakodar",
      "Pathankot",
      "Phillaur",
      "Rahon",
      "Rajpura",
      "Rampura",
      "Ropar",
      "Samrala",
      "Sardulgarh",
      "Sirhind",
      "Sultanpur",
      "Sunam",
      "Talwandi Bhai",
      "Talwara",
      "Tarn Taran",
      "Zira",
      "Sangrur",
      "Phagwara",
      "Patti",
      "Patiala",
      "Moga",
      "Mansa",
      "Machhiwara",
      "Ludhiana",
      "Khanna",
      "Jalandhar",
      "Bathinda",
      "Basi",
      "Amritsar",
      "Abohar"
    ],
    Odisha: [
      "Angul",
      "Asika",
      "Balangir",
      "Balasore",
      "Bargarh",
      "Bhubaneshwar",
      "Brahmapur",
      "Burla",
      "Dhenkanal",
      "Khurda",
      "Koraput",
      "Kuchaiburi",
      "Nayagarh",
      "Paradip",
      "Patnagarh",
      "Puri",
      "Raurkela",
      "Sonepur",
      "Sundargarh",
      "Talcher",
      "Sambalpur",
      "Jajpur",
      "Cuttack",
      "Bhubaneswar",
      "Barbil"
    ],
    "National Capital Territory of Delhi": ["Bawana", "New Delhi", "Delhi"],
    Nagaland: ["Dimapur", "Kohima", "Mokokchung", "Wokha", "Mon"],
    Mizoram: ["Kolasib", "Lunglei", "Aizawl"],
    Meghalaya: ["Cherrapunji", "Tura", "Shillong"],
    Manipur: ["Imphal"],
    Maharashtra: [
      "Ahmadnagar",
      "Ahmednagar",
      "Airoli",
      "Akot",
      "Alibag",
      "Ambarnath",
      "Amravati",
      "Artist Village",
      "Arvi",
      "Ashti",
      "Badlapur",
      "Baramati",
      "Bhandara",
      "Bhayandar",
      "Bhiwandi",
      "Bhor",
      "Bhusaval",
      "Boisar",
      "Borivli",
      "Buldana",
      "Chakan",
      "Chalisgaon",
      "Chanda",
      "Chikhli",
      "Chopda",
      "Dahanu",
      "Daund",
      "Dhule",
      "Dombivali",
      "Dombivli",
      "Gadhinglaj",
      "Ghoti Budrukh",
      "Hingoli",
      "Ichalkaranji",
      "Igatpuri",
      "Indapur",
      "Kalyan",
      "Kannad",
      "Karad",
      "Karjat",
      "Khadki",
      "Khamgaon",
      "Kharakvasla",
      "Khed",
      "Khopoli",
      "Khuldabad",
      "Kodoli",
      "Kopargaon",
      "Koregaon",
      "Latur",
      "Lonavla",
      "Mahabaleshwar",
      "Mahad",
      "Malegaon",
      "Malvan",
      "Manchar",
      "Matheran",
      "Murbad",
      "Nagothana",
      "Nanded",
      "Navi Mumbai",
      "Neral",
      "Nipani",
      "Osmanabad",
      "Paithan",
      "Palghar",
      "Pandharpur",
      "Parbhani",
      "Phaltan",
      "Pimpri",
      "Powai",
      "Pusad",
      "Rahuri",
      "Rajgurunagar",
      "Rajura",
      "Ramtek",
      "Ratnagiri",
      "Sangamner",
      "Sangli",
      "Satara",
      "Shahapur",
      "Shegaon",
      "Shirdi",
      "Shirpur",
      "Shivaji Nagar",
      "Solapur",
      "Tuljapur",
      "Tumsar",
      "Udgir",
      "Ulhasnagar",
      "Uran",
      "Wai",
      "Wardha",
      "Washim",
      "Yeola",
      "Yavatmal",
      "Virar",
      "Thane",
      "Raver",
      "Pune",
      "Pen",
      "Panvel",
      "Nashik",
      "Nandurbar",
      "Nagpur",
      "Mumbai",
      "Kolhapur",
      "Jalna",
      "Gondia",
      "Chiplun",
      "Aurangabad",
      "Akola"
    ],
    "Madhya Pradesh": [
      "Ashta",
      "Bagli",
      "Banda",
      "Barwani",
      "Berasia",
      "Dabra",
      "Damoh",
      "Datia",
      "Dewas",
      "Dhamnod",
      "Dhar",
      "Guna",
      "Harda",
      "Hoshangabad",
      "Itarsi",
      "Kasrawad",
      "Khandwa",
      "Khargone",
      "Lakhnadon",
      "Maheshwar",
      "Maksi",
      "Mandla",
      "Mandsaur",
      "Nagda",
      "Narsinghgarh",
      "Parasia",
      "Pithampur",
      "Punasa",
      "Raisen",
      "Ratlam",
      "Rewa",
      "Satna",
      "Sehore",
      "Sendhwa",
      "Seoni",
      "Shahdol",
      "Shujalpur",
      "Singrauli",
      "Sohagpur",
      "Tikamgarh",
      "Ujjain",
      "Umaria",
      "Vidisha",
      "Sidhi",
      "Morena",
      "Jhabua",
      "Jabalpur",
      "Indore",
      "Gwalior",
      "Chhindwara",
      "Bhopal",
      "Betul",
      "Aron",
      "Agar"
    ],
    Kerala: [
      "Adur",
      "Alleppey",
      "Aluva",
      "Angamali",
      "Attingal",
      "Beypore",
      "Cannanore",
      "Chengannur",
      "Cherthala",
      "Cochin",
      "Erattupetta",
      "Guruvayur",
      "Irinjalakuda",
      "Kalpetta",
      "Kanhangad",
      "Kayamkulam",
      "Kizhake Chalakudi",
      "Kochi",
      "Kodungallur",
      "Kunnamangalam",
      "Kunnamkulam",
      "Mahe",
      "Mavelikara",
      "Muvattupuzha",
      "Nedumangad",
      "Ottappalam",
      "Panamaram",
      "Panmana",
      "Pariyapuram",
      "Pathanamthitta",
      "Payyanur",
      "Perumbavoor",
      "Ponnani",
      "Shertallai",
      "Shoranur",
      "Taliparamba",
      "Tellicherry",
      "Thiruvananthapuram",
      "Trichur",
      "Varkala",
      "Tirur",
      "Thrissur",
      "Palakkad",
      "Nadapuram",
      "Munnar",
      "Manjeri",
      "Malappuram",
      "Kozhikode",
      "Kottayam",
      "Kollam",
      "Kasaragod",
      "Kannur",
      "Idukki",
      "Alappuzha"
    ],
    Karnataka: [
      "Anekal",
      "Bagalkot",
      "Bangalore",
      "Belgaum",
      "Bellary",
      "Bhalki",
      "Bhatkal",
      "Bijapur",
      "Canacona",
      "Chikmagalūr",
      "Chintamani",
      "Chitradurga",
      "Dandeli",
      "Dasarahalli",
      "Gadag",
      "Gokak",
      "Gokarna",
      "Gulbarga",
      "Hampi",
      "Heggadadevankote",
      "Honavar",
      "Hoskote",
      "Hospet",
      "Hubli",
      "Jamkhandi",
      "Karkal",
      "Karkala",
      "Karwar",
      "Kolar",
      "Koppal",
      "Kumta",
      "Kunigal",
      "Kushalnagar",
      "Madikeri",
      "Malur",
      "Mandya",
      "Manvi",
      "Melukote",
      "Mudhol",
      "Mudigere",
      "Nelamangala",
      "Puttur",
      "Raichur",
      "Ramanagaram",
      "Sakleshpur",
      "Sandur",
      "Sankeshwar",
      "Shimoga",
      "Sirsi",
      "Tiptur",
      "Udipi",
      "Udupi",
      "Ullal",
      "Yadgir",
      "Yelahanka",
      "Yellapur",
      "Wadi",
      "Sagar",
      "Mysore",
      "Mangalore",
      "Hassan",
      "Davangere",
      "Bidar",
      "Bengaluru"
    ],
    Jharkhand: [
      "Barki Saria",
      "Bokaro Steel City",
      "Chaibasa",
      "Chatra",
      "Dhanbad",
      "Dumka",
      "Godda",
      "Hazaribagh",
      "Kanke",
      "Ramgarh",
      "Ranchi",
      "Jamshedpur"
    ],
    "Jammu and Kashmir": [
      "Anantnag",
      "Doda",
      "Kargil",
      "Kathua",
      "Kishtwar",
      "Kulgam",
      "Pattan",
      "Pulwama",
      "Sopur",
      "Srinagar",
      "Udhampur",
      "Samba",
      "Punch",
      "Jammu"
    ],
    "Himachal Pradesh": [
      "Baddi",
      "Banjar",
      "Bilaspur",
      "Chamba",
      "Dalhousie",
      "Dharmsala",
      "Hamirpur",
      "Jogindarnagar",
      "Kalka",
      "Kulu",
      "Manali",
      "Nagar",
      "Nahan",
      "Nalagarh",
      "Palampur",
      "Rohru",
      "Sarahan",
      "Shimla",
      "Sundarnagar",
      "Una",
      "Solan",
      "Mandi",
      "Dharamsala"
    ],
    Haryana: [
      "Ambala",
      "Bahadurgarh",
      "Bhiwani",
      "Faridabad",
      "Fatehabad",
      "Hansi",
      "Hisar",
      "Hodal",
      "Jhajjar",
      "Kaithal",
      "Karnal",
      "Narnaul",
      "Narwana",
      "Palwal",
      "Panipat",
      "Pataudi",
      "Pehowa",
      "Ratia",
      "Rewari",
      "Rohtak",
      "Safidon",
      "Samalkha",
      "Shahabad",
      "Sirsa",
      "Sohna",
      "Sonipat",
      "Tohana",
      "Yamunanagar",
      "Panchkula",
      "Nuh",
      "Jind",
      "Indri",
      "Gurgaon",
      "Bawal"
    ],
    Gujarat: [
      "Adalaj",
      "Ahwa",
      "Amreli",
      "Amroli",
      "Ankleshwar",
      "Bardoli",
      "Bedi",
      "Bharuch",
      "Bhuj",
      "Bilimora",
      "Chhota Udepur",
      "Chotila",
      "Dabhoi",
      "Dakor",
      "Deesa",
      "Dhandhuka",
      "Dholka",
      "Dhrol",
      "Dwarka",
      "Gandevi",
      "Gandhidham",
      "Gandhinagar",
      "Ghandinagar",
      "Ghogha",
      "Gondal",
      "Halol",
      "Halvad",
      "Himatnagar",
      "Jalalpur",
      "Jamnagar",
      "Jasdan",
      "Jetpur",
      "Junagadh",
      "Kadi",
      "Kanodar",
      "Karamsad",
      "Keshod",
      "Lunavada",
      "Mandal",
      "Mandvi",
      "Mendarda",
      "Modasa",
      "Morbi",
      "Mundra",
      "Nadiad",
      "Olpad",
      "Padra",
      "Palanpur",
      "Pardi",
      "Patan",
      "Petlad",
      "Radhanpur",
      "Rajpipla",
      "Rapar",
      "Sanand",
      "Sarkhej",
      "Siddhapur",
      "Sihor",
      "Songadh",
      "Surat",
      "Surendranagar",
      "Umreth",
      "Valsad",
      "Vapi",
      "Vasa",
      "Veraval",
      "Visnagar",
      "Vyara",
      "Vadodara",
      "Una",
      "Rajkot",
      "Porbandar",
      "Limbdi",
      "Godhra",
      "Botad",
      "Bhavnagar",
      "Anjar",
      "Anand",
      "Ahmedabad"
    ],
    Goa: [
      "Arambol",
      "Benaulim",
      "Calangute",
      "Candolim",
      "Colva",
      "Cuncolim",
      "Curchorem",
      "Jua",
      "Madgaon",
      "Marmagao",
      "Panaji",
      "Panjim",
      "Ponda",
      "Quepem",
      "Sancoale",
      "Sanquelim",
      "Sanvordem",
      "Taleigao",
      "Vagator"
    ],
    "Daman and Diu": ["Diu", "Daman"],
    Chhattisgarh: [
      "Ambikapur",
      "Balod",
      "Bhilai",
      "Champa",
      "Deori",
      "Dhamtari",
      "Durg",
      "Jagdalpur",
      "Mungeli",
      "Sakti",
      "Raipur",
      "Raigarh",
      "Korba",
      "Bilaspur"
    ],
    Chandigarh: ["Chandigarh"],
    Assam: [
      "Barpeta",
      "Bongaigaon",
      "Dhemaji",
      "Dhing",
      "Dhubri",
      "Dhuburi",
      "Dibrugarh",
      "Digboi",
      "Diphu",
      "Dispur",
      "Goalpara",
      "Haflong",
      "Hailakandi",
      "Hojai",
      "Karimganj",
      "Kokrajhar",
      "Morigaon",
      "Nazira",
      "North Lakhimpur",
      "Rangia",
      "Sibsagar",
      "Silchar",
      "Sonari",
      "Tezpur",
      "Tinsukia",
      "Udalguri",
      "Jorhat",
      "Guwahati",
      "Golaghat"
    ],
    "Arunachal Pradesh": [
      "Itanagar",
      "Naharlagun",
      "Pasighat",
      "Tawang",
      "Margherita"
    ],
    "Andhra Pradesh": [
      "Akkarampalle",
      "Amalapuram",
      "Anantapur",
      "Bapatla",
      "Bhimavaram",
      "Chicacole",
      "Chirala",
      "Gajuwaka",
      "Giddalur",
      "Gudivada",
      "Kadapa",
      "Kadiri",
      "Kavali",
      "Kurnool",
      "Machilipatnam",
      "Madanapalle",
      "Mangalagiri",
      "Markapur",
      "Nandigama",
      "Nandyal",
      "Narasapuram",
      "Narasaraopet",
      "Nellore",
      "Ongole",
      "Palasa",
      "Rajahmundry",
      "Renigunta",
      "Samalkot",
      "Tadpatri",
      "Tanuku",
      "Tuni",
      "Visakhapatnam",
      "Vizianagaram",
      "Yanam",
      "Yarada",
      "Vijayawada",
      "Kakinada",
      "Guntur"
    ],
    Bihar: [
      "Araria",
      "Arrah",
      "Banka",
      "Begusarai",
      "Bettiah",
      "Bhabhua",
      "Bhagalpur",
      "Buxar",
      "Chapra",
      "Dehri",
      "Dinapore",
      "Gopalganj",
      "Hajipur",
      "Hilsa",
      "Jamalpur",
      "Jamui",
      "Jaynagar",
      "Katihar",
      "Madhubani",
      "Munger",
      "Muzaffarpur",
      "Nawada",
      "Piro",
      "Purnia",
      "Saharsa",
      "Samastipur",
      "Sitamarhi",
      "Siwan",
      "Supaul",
      "Patna",
      "Gaya",
      "Darbhanga"
    ]
  },
  Iceland: {
    East: ["Hoefn"],
    Northwest: ["Sauðarkrokur"],
    South: ["Hveragerdi", "Selfoss", "Vestmannaeyjar"],
    "Southern Peninsula": ["Grindavik", "Reykjanesbaer"],
    West: ["Akranes", "Borgarnes", "Olafsvik", "Stykkisholmur"],
    Westfjords: ["Isafjoerdur"],
    Northeast: ["Husavik", "Laugar", "Akureyri"],
    "Capital Region": [
      "Alftanes",
      "Garðabaer",
      "Kopavogur",
      "Mosfellsbaer",
      "Reykjavik"
    ]
  },
  Hungary: {
    Bekes: ["Békés", "Békéscsaba", "Gyula", "Oroshaza", "Szarvas"],
    "Borsod-Abaúj-Zemplén": [
      "Bogacs",
      "Kazincbarcika",
      "Miskolc",
      "Ricse",
      "Sátoraljaújhely",
      "Szerencs",
      "Tiszaujvaros"
    ],
    Heves: ["Eger", "Hatvan"],
    "Heves megye": ["Eger", "Hatvan", "Heves"],
    "Jász-Nagykun-Szolnok": [
      "Jaszbereny",
      "Kisujszallas",
      "Mezotur",
      "Szolnok"
    ],
    "Nograd megye": [
      "Balassagyarmat",
      "Batonyterenye",
      "Legend",
      "Salgotarjan"
    ],
    Pest: [
      "Budaors",
      "Cegled",
      "Dabas",
      "Dunaharaszti",
      "Fot",
      "Maglod",
      "Mogyorod",
      "Monor",
      "Pomaz",
      "Szentendre",
      "Urom",
      "Visegrad",
      "Zsambek"
    ],
    "Szabolcs-Szatmár-Bereg": [
      "Mariapocs",
      "Nagyhalasz",
      "Nagykallo",
      "Nyiregyhaza"
    ],
    Tolna: ["Paks"],
    "Tolna megye": ["Dombovar", "Paks", "Szekszárd", "Tolna"],
    Zala: ["Hévíz", "Keszthely", "Nagykanizsa", "Zalaegerszeg"],
    "Veszprem megye": [
      "Ajka",
      "Badacsonytomaj",
      "Balatonfured",
      "Revfueloep",
      "Tapolca",
      "Tihany",
      "Varpalota",
      "Veszprém",
      "Zanka",
      "Papa"
    ],
    Vas: ["Koszeg", "Sarvar", "Szentgotthard", "Szombathely", "Vasvar"],
    "Somogy megye": [
      "Balatonlelle",
      "Balatonszabadi",
      "Marcali",
      "Nagyatad",
      "Siófok",
      "Somogyvar",
      "Zamardi",
      "Kaposvár"
    ],
    "Pest megye": [
      "Abony",
      "Albertirsa",
      "Budakeszi",
      "Dabas",
      "Dunaharaszti",
      "Dunakeszi",
      "Érd",
      "Fot",
      "Gödöllő",
      "Monor",
      "Nagykoros",
      "Szentendre",
      "Szigethalom",
      "Vác",
      "Pecel",
      "Gyal",
      "God"
    ],
    "Komárom-Esztergom": [
      "Acs",
      "Dorog",
      "Esztergom",
      "Kisber",
      "Komárom",
      "Tatabánya",
      "Tata"
    ],
    "Hajdú-Bihar": ["Hajdunanas", "Hajduszoboszlo", "Debrecen"],
    "Győr-Moson-Sopron": ["Csorna", "Mosonmagyaróvár", "Sopron", "Győr"],
    Fejér: [
      "Bicske",
      "Dunaújváros",
      "Szarliget",
      "Székesfehérvár",
      "Val",
      "Mor"
    ],
    "Csongrad megye": ["Mako", "Morahalom", "Szeged"],
    Budapest: [
      "Budapest III. keruelet",
      "Budapest II. keruelet",
      "Budapest I. keruelet",
      "Budapest IV. keruelet",
      "Budapest IX. keruelet",
      "Budapest VIII. keruelet",
      "Budapest VII. keruelet",
      "Budapest VI. keruelet",
      "Budapest XIII. keruelet",
      "Budapest XII. keruelet",
      "Budapest XI. keruelet",
      "Budapest XIV. keruelet",
      "Budapest X. keruelet",
      "Budapest XXI. keruelet",
      "Budapest"
    ],
    Baranya: ["Mohács", "Pellerd", "Pécs"],
    "Bács-Kiskun": [
      "Ersekcsanad",
      "Kalocsa",
      "Kecskemét",
      "Kiskunfelegyhaza",
      "Kiskunhalas",
      "Kiskunmajsa",
      "Lajosmizse",
      "Lakitelek",
      "Tompa",
      "Baja"
    ]
  },
  "Hong Kong": {
    "Islands District": ["Tung Chung"],
    "Kowloon City": ["Kowloon"],
    "Sai Kung": ["Sai Kung"],
    "Sai Kung District": ["Sai Kung"],
    "Sham Shui Po": ["Sham Shui Po"],
    "Sha Tin": ["Shatin", "Sha Tin"],
    "Tai Po": ["Tai Po"],
    "Tsuen Wan District": ["Tsuen Wan"],
    "Tuen Mun": ["Tuen Mun"],
    Wanchai: ["Wan Chai"],
    "Wong Tai Sin": ["Wong Tai Sin"],
    "Yuen Long District": ["Yuen Long Kau Hui"],
    "Central and Western District": ["Central", "Hong Kong"]
  },
  Honduras: {
    Atlantida: ["El Porvenir", "La Ceiba", "Tela"],
    "Bay Islands": ["Coxen Hole", "French Harbor", "Sandy Bay"],
    Choluteca: ["Ciudad Choluteca"],
    Comayagua: ["Comayagua", "Siguatepeque"],
    Copan: ["Santa Rosa de Copan"],
    Cortes: ["El Porvenir", "La Guama", "La Lima", "San Pedro Sula"],
    "Departamento de Colon": ["Tocoa"],
    "Departamento de Comayagua": ["Comayagua", "Siguatepeque"],
    "Departamento de El Paraiso": ["Danli", "El Paraiso"],
    "Departamento de Santa Barbara": ["Santa Barbara"],
    "Departamento de Valle": ["San Lorenzo"],
    "Departamento de Yoro": ["El Progreso", "Morazan", "Olanchito", "Yoro"],
    "Francisco Morazan": ["Tegucigalpa"],
    Olancho: ["Juticalpa"],
    "Santa Barbara": ["Quimistan", "Santa Barbara"],
    Valle: ["San Lorenzo"],
    Yoro: ["El Progreso", "Yoro"],
    "Departamento de Francisco Morazan": ["Tegucigalpa"],
    "Departamento de Cortes": [
      "Choloma",
      "Cofradia",
      "Villanueva",
      "San Pedro Sula"
    ],
    "Departamento de Atlantida": ["Tela", "La Ceiba"]
  },
  "Holy See (Vatican City State)": {},
  Nigeria: {
    Abia: ["Aba", "Bende", "Umuahia"],
    "Abuja Federal Capital Territory": ["Abuja", "Kuje"],
    Bayelsa: ["Yenagoa"],
    Benue: ["Katsina-Ala", "Makurdi"],
    "Benue State": ["Makurdi"],
    Borno: ["Maiduguri"],
    "Borno State": ["Maiduguri"],
    "Cross River State": ["Calabar", "Obudu", "Ogoja"],
    "Ebonyi State": ["Abakaliki"],
    Ekiti: [
      "Ado-Ekiti",
      "Emure-Ekiti",
      "Igbara-Odo",
      "Ijero-Ekiti",
      "Ipoti",
      "Oke Ila"
    ],
    Enugu: ["Agbani", "Awgu", "Enugu", "Enugu-Ezike", "Nsukka"],
    Gombe: ["Gombe", "Pindiga"],
    "Gombe State": ["Gombe"],
    Imo: ["Nkwerre", "Owerri"],
    Jigawa: ["Birnin Kudu"],
    Kano: ["Gezawa", "Kano", "Rano", "Wudil"],
    Kebbi: ["Birnin Kebbi", "Jega", "Zuru"],
    "Kebbi State": ["Birnin Kebbi", "Kamba"],
    Kogi: ["Abocho", "Ajaokuta", "Kabba", "Lokoja"],
    "Kogi State": ["Lokoja"],
    Kwara: ["Ilorin", "Offa"],
    "Nasarawa State": ["Keffi"],
    Nassarawa: ["Keffi", "Lafia"],
    Niger: ["Bida", "Lapai", "Minna", "Suleja"],
    "Niger State": ["Minna", "Suleja"],
    Ogun: [
      "Abeokuta",
      "Abigi",
      "Ado Odo",
      "Ifo",
      "Ijebu-Igbo",
      "Ijebu-Ode",
      "Ilaro",
      "Iperu",
      "Odeda",
      "Ota",
      "Shagamu"
    ],
    Ondo: ["Akure", "Ondo", "Owo"],
    "Ondo State": ["Akure"],
    Osun: [
      "Effon Alaiye",
      "Ejigbo",
      "Gbongan",
      "Ikire",
      "Ikirun",
      "Ile-Ife",
      "Ilesa",
      "Ipetumodu",
      "Iwo",
      "Osogbo",
      "Oyan"
    ],
    Oyo: ["Ajaawa", "Alapa", "Ibadan", "Lalupon", "Ogbomoso", "Oyo"],
    "Sokoto State": ["Sokoto"],
    "Taraba State": ["Jalingo"],
    "Yobe State": ["Damaturu"],
    "Zamfara State": ["Gusau"],
    "Katsina State": ["Katsina"],
    "Kano State": ["Kano"],
    "Kaduna State": ["Kajuru", "Makera", "Zaria", "Kaduna"],
    "Imo State": ["Owerri"],
    FCT: ["Abuja"],
    "Enugu State": ["Nsukka", "Enugu"],
    "Ekiti State": [],
    Edo: ["Ekpoma", "Uselu", "Benin City"],
    Delta: ["Asaba", "Kwale", "Ogwashi-Uku", "Sapele", "Ughelli", "Warri"],
    Bauchi: ["Bauchi"],
    Anambra: [
      "Awka",
      "Enugu-Ukwu",
      "Igbo-Ukwu",
      "Ihiala",
      "Nkpor",
      "Nnewi",
      "Onitsha",
      "Ozubulu"
    ],
    "Akwa Ibom State": ["Abak", "Uyo"],
    Adamawa: ["Jimeta", "Yola"],
    "Abia State": ["Aba"],
    "Kwara State": ["Ilorin"],
    Lagos: [
      "Agege",
      "Akodo",
      "Badagry",
      "Ebute Ikorodu",
      "Ejirin",
      "Epe",
      "Festac Town",
      "Ifako",
      "Ikorodu",
      "Ikotun",
      "Ikoyi",
      "Makoko",
      "Mushin",
      "Ojota",
      "Oshodi",
      "Somolu",
      "Surulere",
      "Lagos",
      "Ikeja"
    ],
    "Ogun State": ["Sagamu", "Abeokuta"],
    "Osun State": ["Osogbo"],
    "Oyo State": ["Ibadan"],
    "Plateau State": ["Jos"],
    "Rivers State": [
      "Bonny",
      "Buguma",
      "Elele",
      "Emuoha",
      "Okrika",
      "Port Harcourt"
    ]
  },
  Haiti: {
    Artibonite: ["Desarmes", "Gonayiv", "Saint-Marc", "Verrettes"],
    Centre: ["Hinche"],
    "Departement de l'Artibonite": ["Gonaïves"],
    "Departement de l'Ouest": ["Petionville", "Port-au-Prince"],
    Grandans: ["Jérémie"],
    Ouest: [
      "Carrefour",
      "Croix des Bouquets",
      "Delmas 73",
      "Grangwav",
      "Kenscoff",
      "Petionville",
      "Port-au-Prince"
    ],
    "Sud-Est": ["Jacmel"],
    Sud: ["Les Cayes"],
    Nord: ["Okap"]
  },
  Niger: {
    Agadez: [],
    "Dosso Region": ["Dosso"],
    Maradi: [],
    Niamey: ["Niamey"],
    Tahoua: [],
    Zinder: ["Zinder"],
    "Tillaberi Region": ["Say"]
  },
  Guyana: {
    "Cuyuni-Mazaruni Region": ["Bartica"],
    "East Berbice-Corentyne Region": ["New Amsterdam"],
    "Upper Demerara-Berbice Region": ["Linden"],
    "Demerara-Mahaica Region": ["Georgetown"]
  },
  "Guinea-Bissau": {
    "Cacheu Region": ["Cacheu"],
    Gabu: ["Gabú"],
    Bissau: ["Bissau"]
  },
  Nicaragua: {
    "Atlantico Sur": ["Corn Island"],
    Carazo: ["Diriamba", "Jinotepe"],
    Chinandega: ["Chinandega", "Corinto", "El Viejo", "Jiquilillo"],
    "Departamento de Boaco": ["Boaco"],
    "Departamento de Carazo": ["Diriamba", "Jinotepe"],
    "Departamento de Chinandega": ["Chinandega"],
    "Departamento de Chontales": ["Juigalpa"],
    "Departamento de Esteli": ["Esteli"],
    "Departamento de Granada": ["Granada"],
    "Departamento de Leon": ["León"],
    "Departamento de Managua": ["Managua"],
    "Departamento de Masaya": ["Masaya"],
    "Departamento de Matagalpa": ["Matagalpa"],
    "Departamento de Nueva Segovia": ["Ocotal"],
    Esteli: ["Esteli"],
    Granada: ["Granada", "Nandaime"],
    Jinotega: ["Jinotega"],
    Leon: ["Leon"],
    Madriz: ["Somoto"],
    Managua: ["Ciudad Sandino", "Managua", "San Rafael del Sur"],
    Masaya: ["Catarina"],
    Matagalpa: ["Matagalpa"],
    Rivas: ["Rivas", "San Juan del Sur", "Tola"],
    "Departamento de Rivas": ["Rivas"],
    "Departamento de Jinotega": ["Jinotega"]
  },
  Guinea: {
    "Conakry Region": ["Camayenne", "Conakry"],
    Kindia: ["Coyah"],
    "Labe Region": ["Labé"],
    "Nzerekore Region": ["Lola"],
    "Kankan Region": ["Kankan"]
  },
  Guernsey: { "St Peter Port": ["Saint Peter Port"] },
  Guatemala: {
    "Alta Verapaz": ["San Pedro Carcha"],
    "Baja Verapaz": ["San Jeronimo"],
    Chimaltenango: ["Chimaltenango", "El Tejar"],
    Chiquimula: ["Chiquimula", "Esquipulas"],
    "Departamento de Alta Verapaz": ["Coban"],
    "Departamento de Baja Verapaz": ["Rabinal", "Salama"],
    "Departamento de Chimaltenango": ["Chimaltenango"],
    "Departamento de Chiquimula": ["Esquipulas"],
    "Departamento de El Progreso": ["Guastatoya"],
    "Departamento de Escuintla": ["Escuintla"],
    "Departamento de Huehuetenango": ["Huehuetenango"],
    "Departamento de Jalapa": ["Jalapa"],
    "Departamento de Jutiapa": ["Jutiapa"],
    "Departamento del Quiche": ["Chichicastenango"],
    "Departamento de Quetzaltenango": ["Coatepeque", "Quetzaltenango"],
    "Departamento de Retalhuleu": ["Retalhuleu"],
    "Departamento de San Marcos": ["Malacatan"],
    "Departamento de Totonicapan": ["Totonicapán"],
    "Departamento de Zacapa": ["Zacapa"],
    "El Progreso": ["Sanarate"],
    Escuintla: [
      "Escuintla",
      "La Gomera",
      "Puerto San Jose",
      "San Vicente Pacaya"
    ],
    Guatemala: [
      "Chinautla",
      "Fraijanes",
      "Guatemala City",
      "Mixco",
      "Petapa",
      "Santa Catarina Pinula",
      "Villa Canales",
      "Villa Nueva"
    ],
    Huehuetenango: ["Chiantla", "Huehuetenango"],
    Izabal: ["Morales", "Puerto Barrios"],
    Jalapa: ["Jalapa"],
    Jutiapa: ["Asuncion Mita", "Jutiapa"],
    Peten: ["Melchor de Mencos"],
    Quetzaltenango: ["Coatepeque", "El Palmar", "Quetzaltenango"],
    Retalhuleu: ["Retalhuleu"],
    Sacatepequez: ["Alotenango", "Antigua Guatemala", "San Lucas Sacatepequez"],
    "San Marcos": ["Comitancillo", "Ixchiguan", "San Marcos"],
    "Santa Rosa": ["Barberena", "Cuilapa", "Taxisco"],
    Solola: [
      "Panajachel",
      "San Pedro La Laguna",
      "Santa Cruz La Laguna",
      "Santa Lucia Utatlan",
      "Solola"
    ],
    Suchitepeque: ["Mazatenango", "San Juan Bautista"],
    Zacapa: ["Teculutan", "Zacapa"],
    "Departamento de Sacatepequez": ["Antigua Guatemala"],
    "Departamento del Peten": ["Flores"],
    "Departamento de Guatemala": [
      "Amatitlán",
      "Mixco",
      "Petapa",
      "San Juan Sacatepequez",
      "Villa Nueva",
      "Guatemala City"
    ]
  },
  "New Zealand": {
    "Chatham Islands": ["Waitangi"],
    Gisborne: ["Gisborne"],
    "Hawke's Bay": ["Hastings", "Napier", "Napier City", "Taradale"],
    Northland: ["Ahipara", "Kerikeri", "Ngunguru", "Paihia", "Whangarei"],
    Tasman: ["Richmond"],
    "West Coast": ["Hokitika", "Westport"],
    Wellington: [
      "Brooklyn",
      "Kelburn",
      "Lower Hutt",
      "Masterton",
      "Otaki",
      "Paraparaumu",
      "Porirua",
      "Upper Hutt",
      "Waipawa",
      "Wellington"
    ],
    Waikato: [
      "Cambridge",
      "Coromandel",
      "Matamata",
      "Otorohanga",
      "Raglan",
      "Tairua",
      "Te Kauwhata",
      "Thames",
      "Tokoroa",
      "Turangi",
      "Whitianga",
      "Taupo",
      "Hamilton"
    ],
    Taranaki: ["Hawera", "New Plymouth"],
    Southland: ["Gore", "Invercargill", "Te Anau"],
    Otago: [
      "Arrowtown",
      "Kingston",
      "Milton",
      "Oamaru",
      "Queenstown",
      "Wanaka",
      "Dunedin"
    ],
    Nelson: ["Nelson"],
    Marlborough: ["Blenheim", "Picton"],
    "Manawatu-Wanganui": ["Waiouru", "Wanganui", "Palmerston North", "Levin"],
    Canterbury: [
      "Amberley",
      "Ashburton",
      "Kaiapoi",
      "Kaikoura",
      "Methven",
      "Pleasant Point",
      "Prebbleton",
      "Rakaia",
      "Timaru",
      "Christchurch"
    ],
    "Bay of Plenty": ["Opotiki", "Rotorua", "Whakatane", "Tauranga"],
    Auckland: [
      "Mangere",
      "Manukau",
      "Manukau City",
      "Muriwai Beach",
      "Pakuranga",
      "Papakura",
      "Parakai",
      "Pukekohe East",
      "Red Hill",
      "Rosebank",
      "Rothesay Bay",
      "Takanini",
      "Tamaki",
      "Waitakere",
      "Warkworth",
      "Wellsford",
      "Wiri",
      "North Shore",
      "Auckland"
    ]
  },
  Guam: {
    "": ["Tamuning"],
    Barrigada: ["Barrigada Village"],
    Dededo: ["Dededo Village"],
    Hagatna: ["Guam Government House"],
    Mangilao: ["Mangilao Village"],
    "Mongmong-Toto-Maite": ["Mongmong-Toto-Maite Village"],
    Tamuning: ["Tamuning-Tumon-Harmon Village"]
  },
  "New Caledonia": {
    "South Province": ["Dumbea", "Mont-Dore", "Noumea", "Paita"]
  },
  Guadeloupe: {
    "": ["Le Moule"],
    Guadeloupe: [
      "Baie-Mahault",
      "Basse-Terre",
      "Capesterre-Belle-Eau",
      "Grand-Bourg",
      "Le Gosier",
      "Le Moule",
      "Les Abymes",
      "Petit-Bourg",
      "Pointe-a-Pitre",
      "Sainte-Anne",
      "Sainte-Rose"
    ]
  },
  Grenada: {
    "Carriacou and Petite Martinique": ["Hillsborough"],
    "Saint Andrew": ["Grenville"],
    "Saint George": ["Saint George's"]
  },
  Greenland: {
    Kujalleq: ["Narsaq"],
    Qaasuitsup: ["Ilulissat", "Qasigiannguit", "Uummannaq"],
    Sermersooq: ["Nuuk", "Tasiilaq"]
  },
  Netherlands: {
    Flevoland: [
      "Almere Stad",
      "Biddinghuizen",
      "Emmeloord",
      "Lelystad",
      "Zeewolde"
    ],
    Overijssel: [
      "Almelo",
      "Dalfsen",
      "Den Ham",
      "Deventer",
      "Enschede",
      "Giethoorn",
      "Haaksbergen",
      "Hardenberg",
      "Hengelo",
      "Kampen",
      "Losser",
      "Oldenzaal",
      "Raalte",
      "Schalkhaar",
      "Steenwijk",
      "Vollenhove",
      "Wanneperveen",
      "Wierden",
      "Zwolle"
    ],
    "Provincie Drenthe": [
      "Assen",
      "Coevorden",
      "Emmen",
      "Hoogeveen",
      "Klazienaveen",
      "Meppel"
    ],
    "Provincie Flevoland": [
      "Almere Stad",
      "Dronten",
      "Emmeloord",
      "Lelystad",
      "Urk",
      "Zeewolde"
    ],
    Utrecht: [
      "Amersfoort",
      "Baarn",
      "Bunnik",
      "Bunschoten",
      "De Bilt",
      "IJsselstein",
      "Leusden",
      "Lopik",
      "Maarsbergen",
      "Maarssen",
      "Nieuwegein",
      "Rhenen",
      "Soest",
      "Spechtenkamp",
      "Utrecht",
      "Veenendaal",
      "Vianen",
      "Wijk bij Duurstede",
      "Woerden",
      "Zeist"
    ],
    Zeeland: [
      "Goes",
      "Middelburg",
      "Scharendijke",
      "Sluis",
      "Terneuzen",
      "Veere",
      "Vlissingen"
    ],
    "South Holland": [
      "Alblasserdam",
      "Alphen aan den Rijn",
      "Barendrecht",
      "Benthuizen",
      "Bergschenhoek",
      "Berkel en Rodenrijs",
      "Bleiswijk",
      "Bloemendaal",
      "Bodegraven",
      "Boskoop",
      "Brielle",
      "Capelle aan den IJssel",
      "Delfshaven",
      "De Lier",
      "Goedereede",
      "Gorinchem",
      "Hellevoetsluis",
      "Hillegom",
      "Honselersdijk",
      "Katwijk aan Zee",
      "Leiderdorp",
      "Leimuiden",
      "Lisse",
      "Maassluis",
      "Middelharnis",
      "Naaldwijk",
      "Noordwijk-Binnen",
      "Noordwijkerhout",
      "Oegstgeest",
      "Oud-Beijerland",
      "Oude Wetering",
      "Papendrecht",
      "Pijnacker",
      "Poeldijk",
      "Reeuwijk",
      "Rhoon",
      "Ridderkerk",
      "Rijswijk",
      "Rozenburg",
      "Sassenheim",
      "Schoonhoven",
      "'s-Gravenland",
      "Sliedrecht",
      "Spijkenisse",
      "The Hague",
      "Vlaardingen",
      "Voorburg",
      "Voorhout",
      "Voorschoten",
      "Waddinxveen",
      "Wassenaar",
      "Ypenburg",
      "Zwijndrecht",
      "Zoetermeer",
      "Schiedam",
      "Scheveningen",
      "Rotterdam",
      "Monster",
      "Leiden",
      "Leerdam",
      "Gouda",
      "Dordrecht",
      "Delft"
    ],
    "Provincie Zeeland": [
      "Flushing",
      "Middelburg",
      "Terneuzen",
      "Zierikzee",
      "Goes"
    ],
    "Provincie Utrecht": [
      "Baarn",
      "Breukelen",
      "Doorn",
      "Hoogland",
      "IJsselstein",
      "Leusden",
      "Lopik",
      "Maarssen",
      "Mijdrecht",
      "Rhenen",
      "Soest",
      "Vianen",
      "Woerden",
      "Woudenberg",
      "Zeist",
      "Veenendaal",
      "Utrecht",
      "Nieuwegein",
      "Amersfoort"
    ],
    "Provincie Overijssel": [
      "Borne",
      "Dalfsen",
      "Deventer",
      "Goor",
      "Haaksbergen",
      "Hardenberg",
      "Kampen",
      "Losser",
      "Oldenzaal",
      "Ommen",
      "Raalte",
      "Staphorst",
      "Steenwijk",
      "Tubbergen",
      "Vriezenveen",
      "Wierden",
      "Zwolle",
      "Hengelo",
      "Enschede",
      "Almelo"
    ],
    "Provincie Gelderland": [
      "Aalten",
      "Barneveld",
      "Bemmel",
      "Bennekom",
      "Beuningen",
      "Borculo",
      "Brummen",
      "Culemborg",
      "Dieren",
      "Doesburg",
      "Duiven",
      "Eerbeek",
      "Eibergen",
      "Elburg",
      "Elst",
      "Epe",
      "Ermelo",
      "Est",
      "Geldermalsen",
      "Groesbeek",
      "Hattem",
      "Lichtenvoorde",
      "Lochem",
      "Lunteren",
      "Nijkerk",
      "Nunspeet",
      "Oosterbeek",
      "Putten",
      "Tiel",
      "Twello",
      "Vaassen",
      "Velp",
      "Wageningen",
      "Wijchen",
      "Winterswijk",
      "Zaltbommel",
      "Zevenaar",
      "Zutphen",
      "Nijmegen",
      "Harderwijk",
      "Ede",
      "Doetinchem",
      "Arnhem",
      "Apeldoorn"
    ],
    "North Holland": [
      "Aalsmeer",
      "Amstelveen",
      "Amsterdam-Zuidoost",
      "Bloemendaal",
      "Broek in Waterland",
      "Castricum",
      "Den Burg",
      "Den Helder",
      "Diemen",
      "Duivendrecht",
      "Egmond aan Zee",
      "Enkhuizen",
      "Harenkarspel",
      "Heemskerk",
      "Heemstede",
      "Heerhugowaard",
      "Heiloo",
      "Hoofddorp",
      "Kortenhoef",
      "Laren",
      "Medemblik",
      "Naarden",
      "Opmeer",
      "Ouderkerk aan de Amstel",
      "Oud-Loosdrecht",
      "Purmerend",
      "Schagen",
      "Uitgeest",
      "Velsen",
      "Velsen-Zuid",
      "Volendam",
      "Weesp",
      "Zaandam",
      "Zaanstad",
      "Zandvoort",
      "Uithoorn",
      "Huizen",
      "Hoorn",
      "Hilversum",
      "Haarlem",
      "Bussum",
      "Beverwijk",
      "Amsterdam",
      "Alkmaar"
    ],
    "North Brabant": [
      "Aarle-Rixtel",
      "Asten",
      "Bergeijk",
      "Bergen op Zoom",
      "Berghem",
      "Boxmeer",
      "Boxtel",
      "Cranendonck",
      "Cuijk",
      "Dongen",
      "Geertruidenberg",
      "Geffen",
      "Geldrop",
      "Goirle",
      "Heesch",
      "Helmond",
      "Hoge Vucht",
      "Keldonk",
      "Klundert",
      "Loon op Zand",
      "Meerhoven",
      "Nuenen",
      "Oirschot",
      "Oisterwijk",
      "Oosterhout",
      "Oss",
      "Prinsenbeek",
      "Rijen",
      "Roosendaal",
      "Rucphen",
      "Schijndel",
      "'s-Hertogenbosch",
      "Sint-Oedenrode",
      "Someren",
      "Son",
      "Steenbergen",
      "Tongelre",
      "Uden",
      "Valkenswaard",
      "Veghel",
      "Veldhoven",
      "Villapark",
      "Vught",
      "Waalre",
      "Waalwijk",
      "Waspik",
      "Werkendam",
      "Wilbertoord",
      "Woensdrecht",
      "Woudrichem",
      "Zundert",
      "Tilburg",
      "Made",
      "Grave",
      "Eindhoven",
      "Eersel",
      "Breda",
      "Best"
    ],
    Limburg: [
      "America",
      "Beek",
      "Born",
      "Brunssum",
      "Eijsden",
      "Geleen",
      "Gennep",
      "Kerkrade",
      "Limbricht",
      "Maasbree",
      "Maastricht",
      "Montfort",
      "Nuth",
      "Puth",
      "Roermond",
      "Stein",
      "Tegelen",
      "Vaals",
      "Valkenburg",
      "Venray",
      "Weert",
      "Venlo",
      "Sittard",
      "Heerlen"
    ],
    Groningen: [
      "Appingedam",
      "Delfzijl",
      "Hoogezand",
      "Korrewegwijk",
      "Leek",
      "Stadskanaal",
      "Veendam",
      "Winschoten",
      "Groningen"
    ],
    Gelderland: [
      "Apeldoorn",
      "Arnhem",
      "Bennekom",
      "Bergharen",
      "Bredeweg",
      "Brummen",
      "Culemborg",
      "Doetinchem",
      "Druten",
      "Duiven",
      "Ede",
      "Ederveen",
      "Elburg",
      "Elst",
      "Epe",
      "Ermelo",
      "Groesbeek",
      "Harderwijk",
      "Harskamp",
      "Heerde",
      "Heteren",
      "Lienden",
      "Lindenholt",
      "Nijkerk",
      "Nijmegen",
      "Oosterbeek",
      "Otterlo",
      "Putten",
      "Spankeren",
      "Tiel",
      "Twello",
      "Velp",
      "Wageningen",
      "Westervoort",
      "Wijchen",
      "Winterswijk",
      "Wolfheze",
      "Zaltbommel",
      "Zevenaar",
      "Zutphen",
      "Nunspeet"
    ],
    Friesland: [
      "Burgum",
      "Dokkum",
      "Drachten",
      "Franeker",
      "Grou",
      "Harlingen",
      "Heerenveen",
      "Joure",
      "Koudum",
      "Lemmer",
      "Marsum",
      "Oost-Vlieland",
      "Oranjewoud",
      "Oudehaske",
      "Sneek",
      "West-Terschelling",
      "Wolvega",
      "Leeuwarden"
    ],
    Drenthe: [
      "Anloo",
      "Borger",
      "Coevorden",
      "Emmen",
      "Hoogeveen",
      "Klazienaveen",
      "Meppel",
      "Assen"
    ]
  },
  Greece: {
    Peloponnese: [
      "Argos",
      "Arkhaia Korinthos",
      "Corinth",
      "Gargalianoi",
      "Gefyra",
      "Gytheio",
      "Kalamata",
      "Kyparissia",
      "Ligourion",
      "Loutraki",
      "Nafplio",
      "Nafplion",
      "Porto Cheli",
      "Sparti",
      "Tripoli",
      "Xylokastro"
    ],
    "West Macedonia": [
      "Florina",
      "Grevena",
      "Kastoria",
      "Ptolemaida",
      "Servia",
      "Kozani"
    ],
    "West Greece": [
      "Agrinio",
      "Aigio",
      "Aiyira",
      "Amaliada",
      "Ano Kastritsion",
      "Archaia Olympia",
      "Ayios Nikolaos",
      "Kalavryta",
      "Kardamas",
      "Messolonghi",
      "Patra",
      "Pyrgos",
      "Nafpaktos"
    ],
    Thessaly: [
      "Argalasti",
      "Kalampaka",
      "Karditsa",
      "Larisa",
      "Patitirion",
      "Proastio",
      "Skiathos",
      "Skopelos",
      "Trikala",
      "Tyrnavos",
      "Vamvakou",
      "Volos",
      "Zagora",
      "Larissa"
    ],
    "South Aegean": [
      "Adamas",
      "Afantou",
      "Agia Marina",
      "Amorgos",
      "Andros",
      "Ano Mera",
      "Ano Syros",
      "Antiparos",
      "Archangelos",
      "Emporeio",
      "Ermoupolis",
      "Faliraki",
      "Filotion",
      "Folegandros",
      "Ialysos",
      "Kalymnos",
      "Kardamaina",
      "Kea",
      "Kimolos",
      "Kremasti",
      "Kythnos",
      "Lardos",
      "Megisti",
      "Mesaria",
      "Milos",
      "Mykonos",
      "Naousa",
      "Naxos",
      "Oia",
      "Paros",
      "Patmos",
      "Pylion",
      "Sikinos",
      "Symi",
      "Tinos",
      "Trianta",
      "Ziparion",
      "Rodos",
      "Rhodes",
      "Kos",
      "Ios",
      "Fira"
    ],
    "North Aegean": [
      "Eresos",
      "Mithymna",
      "Myrina",
      "Mytilene",
      "Mytilini",
      "Samos",
      "Vrontados",
      "Chios"
    ],
    "Ionian Islands": [
      "Acharavi",
      "Ambelokipoi",
      "Argostolion",
      "Gaios",
      "Ithaki",
      "Kerkyra",
      "Kontokali",
      "Lefkada",
      "Lefkimmi",
      "Lithakia",
      "Lixouri",
      "Nidri",
      "Perama",
      "Sami",
      "Valsamata",
      "Zakynthos",
      "Corfu"
    ],
    Epirus: ["Arta", "Parga", "Preveza", "Ioannina"],
    "East Macedonia and Thrace": [
      "Elaiochori",
      "Orestiada",
      "Prinos",
      "Xylagani",
      "Xanthi",
      "Thasos",
      "Komotini",
      "Kavala",
      "Drama",
      "Alexandroupoli"
    ],
    Crete: [
      "Agia Marina",
      "Agios Nikolaos",
      "Anogeia",
      "Arkalochori",
      "Atsipopoulon",
      "Daratsos",
      "Gazi",
      "Gouvai",
      "Ierapetra",
      "Irakleion",
      "Kissamos",
      "Kolympari",
      "Limin Khersonisou",
      "Makry-Gialos",
      "Malia",
      "Palaiochora",
      "Palekastro",
      "Panormos",
      "Perama",
      "Perivolia",
      "Rethymno",
      "Skhisma",
      "Stalis",
      "Heraklion",
      "Chania"
    ],
    "Central Macedonia": [
      "Agios Nikolaos",
      "Agios Pavlos",
      "Alexandreia",
      "Diavata",
      "Edessa",
      "Epanomi",
      "Filiro",
      "Gerakarou",
      "Giannitsa",
      "Kalamaria",
      "Kallithea",
      "Katerini",
      "Kilkis",
      "Leptokarya",
      "Naousa",
      "Nea Moudhania",
      "Nea Poteidaia",
      "Nea Potidhaia",
      "Nea Roda",
      "Neos Marmaras",
      "Nikiti",
      "Ouranoupolis",
      "Panorama",
      "Pefkochori",
      "Peraia",
      "Plagiari",
      "Portaria",
      "Stratonion",
      "Thermi",
      "Triandria",
      "Veroia",
      "Thessaloniki",
      "Serres"
    ],
    "Central Greece": [
      "Ayios Konstandinos",
      "Chalkida",
      "Delphi",
      "Dhrosia",
      "Itea",
      "Kamena Vourla",
      "Karpenisi",
      "Kato Tithorea",
      "Kymi",
      "Makrakomi",
      "Politika",
      "Roviai",
      "Thebes",
      "Lamia"
    ],
    Attica: [
      "Acharnes",
      "Aegina",
      "Agia Paraskevi",
      "Agioi Anargyroi",
      "Agios Dimitrios",
      "Agios Dimitrios Kropias",
      "Agios Ioannis Rentis",
      "Aigaleo",
      "Alimos",
      "Anavyssos",
      "Argithea",
      "Argyroupoli",
      "Aspropyrgos",
      "Chaidari",
      "Dhafni",
      "Ekali",
      "Elefsina",
      "Elliniko",
      "Filothei",
      "Glyfada",
      "Kaisariani",
      "Kallithea",
      "Kamatero",
      "Karellas",
      "Keratsini",
      "Khalandrion",
      "Kifisia",
      "Kifissia",
      "Kipseli",
      "Koropi",
      "Kythira",
      "Lavrio",
      "Limin Mesoyaias",
      "Lykovrysi",
      "Mandra",
      "Marousi",
      "Megalochori",
      "Megara",
      "Melissia",
      "Moskhaton",
      "Nea Erythraia",
      "Nea Filadelfeia",
      "Nea Ionia",
      "Nea Makri",
      "Neo Psychiko",
      "Nikaia",
      "Oropos",
      "Paiania",
      "Palaio Faliro",
      "Pallini",
      "Papagou",
      "Pefki",
      "Penteli",
      "Peristeri",
      "Petroupolis",
      "Piraeus",
      "Poros",
      "Psychiko",
      "Rafina",
      "Salamina",
      "Saronida",
      "Spetses",
      "Tavros",
      "Thrakomakedones",
      "Vari",
      "Vathi",
      "Voula",
      "Vouliagmeni",
      "Vyronas",
      "Ydra",
      "Ymittos",
      "Perama",
      "Cholargos",
      "Athens"
    ]
  },
  Gibraltar: { "": ["Gibraltar"] },
  Nepal: {
    "Eastern Region": ["Biratnagar", "Lobujya", "Namche Bazar", "Rajbiraj"],
    "Far Western": ["Dhangarhi"],
    "Western Region": ["Butwal", "Chitre", "Jomsom", "Nawal", "Pokhara"],
    "Mid Western": ["Jumla", "Nepalgunj"],
    "Central Region": [
      "Bharatpur",
      "Janakpur",
      "Kirtipur",
      "Panaoti",
      "Patan",
      "Kathmandu"
    ]
  },
  Ghana: {
    "Ashanti Region": [
      "Ejura",
      "Kumasi",
      "Mampong",
      "Mamponteng",
      "Obuasi",
      "Tafo"
    ],
    "Brong-Ahafo": ["Kintampo", "Sunyani", "Techiman"],
    "Central Region": [
      "Apam",
      "Cape Coast",
      "Dunkwa",
      "Elmina",
      "Kasoa",
      "Saltpond",
      "Swedru",
      "Winneba"
    ],
    "Eastern Region": ["Aburi", "Begoro", "Koforidua", "Nsawam"],
    "Northern Region": ["Tamale", "Yendi"],
    "Upper East Region": ["Bolgatanga", "Navrongo"],
    Volta: ["Anloga", "Ho", "Hohoe"],
    "Western Region": ["Axim", "Sekondi-Takoradi", "Tarkwa", "Takoradi"],
    "Upper West Region": ["Wa"],
    "Greater Accra Region": [
      "Achiaman",
      "Dome",
      "Gbawe",
      "Nungua",
      "Teshie",
      "Teshi Old Town",
      "Tema",
      "Medina Estates",
      "Accra"
    ]
  },
  Nauru: {},
  Germany: {
    Thuringia: [
      "Apolda",
      "Arnstadt",
      "Bad Langensalza",
      "Eisenach",
      "Eisenberg",
      "Gera",
      "Gotha",
      "Greiz",
      "Hildburghausen",
      "Ilmenau",
      "Meiningen",
      "Mühlhausen",
      "Nordhausen",
      "Rudolstadt",
      "Schmalkalden",
      "Schmiedefeld am Rennsteig",
      "Sondershausen",
      "Sonneberg",
      "Suhl",
      "Veilsdorf",
      "Zeulenroda",
      "Weimar",
      "Saalfeld",
      "Jena",
      "Erfurt",
      "Altenburg"
    ],
    "Schleswig-Holstein": [
      "Ahrensburg",
      "Bad Bramstedt",
      "Bad Oldesloe",
      "Bad Schwartau",
      "Bad Segeberg",
      "Bargteheide",
      "Barmstedt",
      "Barsbuettel",
      "Bonningstedt",
      "Brammer",
      "Eckernforde",
      "Elmshorn",
      "Eutin",
      "Fehmarn",
      "Geesthacht",
      "Glinde",
      "Glücksburg",
      "Halstenbek",
      "Harrislee",
      "Heide",
      "Heikendorf",
      "Heiligenhafen",
      "Husum",
      "Itzehoe",
      "Kaltenkirchen",
      "Lasbek",
      "Lauenburg",
      "Luebeck",
      "Neumünster",
      "Neustadt in Holstein",
      "Norddorf",
      "Norderstedt",
      "Pellworm",
      "Pinneberg",
      "Ploen",
      "Preetz",
      "Ratekau",
      "Ratzeburg",
      "Reher",
      "Reinbek",
      "Rellingen",
      "Rendsburg",
      "Rodenas",
      "Sankt Peter-Ording",
      "Scharbeutz",
      "Schwarzenbek",
      "Stockelsdorf",
      "Suderlugum",
      "Tinnum",
      "Tornesch",
      "Travemuende",
      "Travemünde",
      "Uetersen",
      "Wangels",
      "Wedel",
      "Westerland",
      "Schleswig",
      "Lübeck",
      "Kiel",
      "Flensburg"
    ],
    "Saxony-Anhalt": [
      "Aschersleben",
      "Benneckenstein",
      "Bernburg",
      "Blankenburg",
      "Calbe",
      "Dieskau",
      "Elbingerode",
      "Friedersdorf",
      "Gardelegen",
      "Genthin",
      "Grafenhainichen",
      "Halberstadt",
      "Halle Neustadt",
      "Halle (Saale)",
      "Jessen",
      "Köthen",
      "Merseburg",
      "Naumburg",
      "Oschersleben",
      "Quedlinburg",
      "Salzwedel",
      "Sangerhausen",
      "Stassfurt",
      "Stendal",
      "Thale",
      "Wernigerode",
      "Wittenberg",
      "Wolfen",
      "Wolmirsleben",
      "Zeitz",
      "Zerbst",
      "Magdeburg",
      "Halle",
      "Dessau"
    ],
    Saxony: [
      "Albertstadt",
      "Aue",
      "Auerbach",
      "Bautzen",
      "Bitterfeld-Wolfen",
      "Coswig",
      "Crimmitschau",
      "Delitzsch",
      "Dippoldiswalde",
      "Ebersbach",
      "Eilenburg",
      "Falkenstein",
      "Frankenberg",
      "Freiberg",
      "Freital",
      "Glauchau",
      "Grimma",
      "Haselbachtal",
      "Heidenau",
      "Hohenstein-Ernstthal",
      "Hoyerswerda",
      "Kamenz",
      "Kurort Oberwiesenthal",
      "Lichtenstein",
      "Limbach-Oberfrohna",
      "Lommatzsch",
      "Marienberg",
      "Markkleeberg",
      "Meerane",
      "Meissen",
      "Mittweida",
      "Mochau",
      "Neukirchen",
      "Niesky",
      "Oschatz",
      "Pirna",
      "Plauen",
      "Radeberg",
      "Radebeul",
      "Rathmannsdorf",
      "Reichenbach/Vogtland",
      "Riesa",
      "Schkeuditz",
      "Schneeberg",
      "Schwarzenberg",
      "Stadt Wehlen",
      "Taucha",
      "Torgau",
      "Weissenborn",
      "Werdau",
      "Zabeltitz",
      "Zittau",
      "Zschopau",
      "Zwenkau",
      "Zwickau",
      "Leipzig",
      "Görlitz",
      "Dresden",
      "Chemnitz",
      "Borna"
    ],
    Saarland: [
      "Beckingen",
      "Bexbach",
      "Blieskastel",
      "Dillingen",
      "Eppelborn",
      "Friedrichsthal",
      "Heusweiler",
      "Homburg",
      "Illingen",
      "Kirkel",
      "Kleinblittersdorf",
      "Lebach",
      "Losheim",
      "Marpingen",
      "Merzig",
      "Neunkirchen",
      "Oberthal",
      "Ottweiler",
      "Riegelsberg",
      "Saarlouis",
      "Saarwellingen",
      "Sankt Ingbert",
      "Sankt Wendel",
      "Schiffweiler",
      "Schmelz",
      "Schwalbach",
      "Sulzbach",
      "Tholey",
      "Wadern",
      "Wadgassen",
      "Saarbrücken"
    ],
    "Rheinland-Pfalz": [
      "Andernach",
      "Bacharach",
      "Bad Bergzabern",
      "Bad Duerkheim",
      "Bad Ems",
      "Bad Kreuznach",
      "Bad Neuenahr-Ahrweiler",
      "Bell",
      "Bendorf",
      "Betzdorf",
      "Bingen am Rhein",
      "Bitburg",
      "Boppard",
      "Budenheim",
      "Ediger-Eller",
      "Frankenthal",
      "Gartenstadt",
      "Hassloch",
      "Hochstetten-Dhaun",
      "Idar-Oberstein",
      "Kerschenbach",
      "Kirschweiler",
      "Konz",
      "Lahnstein",
      "Landau",
      "Landau in der Pfalz",
      "Landstuhl",
      "Limburgerhof",
      "Ludwigshafen am Rhein",
      "Mayen",
      "Meuspath",
      "Montabaur",
      "Morbach",
      "Munster-Sarmsheim",
      "Mutterstadt",
      "Neustadt",
      "Nieder-Ingelheim",
      "Niederwerth",
      "Nurburg",
      "Ober-Olm",
      "Oestrich-Winkel",
      "Otzingen",
      "Ransbach-Baumbach",
      "Remagen",
      "Sargenroth",
      "Schifferstadt",
      "Sinzig",
      "Speyer",
      "Trier",
      "Windhagen",
      "Wittlich",
      "Worms",
      "Worth am Rhein",
      "Zweibrücken",
      "Pirmasens",
      "Neuwied",
      "Mainz",
      "Koblenz",
      "Kaiserslautern",
      "Germersheim",
      "Diez",
      "Alzey"
    ],
    "North Rhine-Westphalia": [
      "Ahlen",
      "Alfter",
      "Alsdorf",
      "Altena",
      "Altstadt Nord",
      "Altstadt Sud",
      "Arnsberg",
      "Ascheberg",
      "Attendorn",
      "Bad Driburg",
      "Bad Honnef",
      "Bad Lippspringe",
      "Bad Oeynhausen",
      "Bad Salzuflen",
      "Baesweiler",
      "Balve",
      "Barntrup",
      "Bayenthal",
      "Beckum",
      "Bedburg",
      "Bergheim",
      "Bergisch Gladbach",
      "Bergneustadt",
      "Bestwig",
      "Beverungen",
      "Bilderstoeckchen",
      "Blomberg",
      "Bocholt",
      "Bonen",
      "Borken",
      "Bornheim",
      "Brakel",
      "Brilon",
      "Bruehl",
      "Bruggen",
      "Bruhl",
      "Bueren",
      "Burbach",
      "Burscheid",
      "Castrop-Rauxel",
      "Coesfeld",
      "Detmold",
      "Deutz",
      "Dinslaken",
      "Dormagen",
      "Dorsten",
      "Drensteinfurt",
      "Drolshagen",
      "Dueren",
      "Düren",
      "Eitorf",
      "Elsdorf",
      "Emmerich",
      "Emsdetten",
      "Engelskirchen",
      "Enger",
      "Ennepetal",
      "Erftstadt",
      "Erkelenz",
      "Erkrath",
      "Erndtebruck",
      "Erwitte",
      "Eschweiler",
      "Espelkamp",
      "Euskirchen",
      "Finnentrop",
      "Frechen",
      "Freudenberg",
      "Gangelt",
      "Geilenkirchen",
      "Geldern",
      "Gelsenkirchen",
      "Gescher",
      "Geseke",
      "Gevelsberg",
      "Gladbeck",
      "Goch",
      "Greven",
      "Grevenbroich",
      "Gronau",
      "Guetersloh",
      "Gummersbach",
      "Haan",
      "Hagen",
      "Halle",
      "Haltern",
      "Halver",
      "Hamminkeln",
      "Harsewinkel",
      "Hattingen",
      "Havixbeck",
      "Heiligenhaus",
      "Heinsberg",
      "Hemer",
      "Hennef",
      "Herdecke",
      "Herford",
      "Herzogenrath",
      "Hilchenbach",
      "Hille",
      "Hochfeld",
      "Holzwickede",
      "Höxter",
      "Huckelhoven",
      "Humboldtkolonie",
      "Hunxe",
      "Hürth",
      "Iserlohn",
      "Issum",
      "Kaarst",
      "Kalk",
      "Kalkar",
      "Kall",
      "Kamen",
      "Kamp-Lintfort",
      "Kempen",
      "Kerpen",
      "Kevelaer",
      "Kierspe",
      "Kirchlengern",
      "Kleve",
      "Koeln",
      "Konigswinter",
      "Korschenbroich",
      "Kreuzau",
      "Kreuztal",
      "Lage",
      "Langenfeld",
      "Langerwehe",
      "Lanstrop",
      "Leichlingen",
      "Lemgo",
      "Lengerich",
      "Lennestadt",
      "Leverkusen",
      "Lichtenau",
      "Lindlar",
      "Linnich",
      "Lippstadt",
      "Lohmar",
      "Luedenscheid",
      "Lünen",
      "Marsberg",
      "Mechernich",
      "Meckenheim",
      "Meerbusch",
      "Meiderich",
      "Meinerzhagen",
      "Menden",
      "Mengenich",
      "Merheim",
      "Meschede",
      "Mettingen",
      "Mettmann",
      "Moers",
      "Mohnesee",
      "Monheim am Rhein",
      "Monschau",
      "Muelheim",
      "Muelheim (Ruhr)",
      "Muenster",
      "Netphen",
      "Nettetal",
      "Neuehrenfeld",
      "Neuenkirchen",
      "Neuenrade",
      "Neunkirchen",
      "Neustadt/Nord",
      "Neustadt/Sued",
      "Niederkassel",
      "Niederzier",
      "Nippes",
      "Nordkirchen",
      "Nörvenich",
      "Nottuln",
      "Numbrecht",
      "Ochtrup",
      "Odenthal",
      "Oer-Erkenschwick",
      "Oerlinghausen",
      "Olfen",
      "Olpe",
      "Olsberg",
      "Opladen",
      "Ostbevern",
      "Overath",
      "Petershagen",
      "Plettenberg",
      "Porta Westfalica",
      "Radevormwald",
      "Raesfeld",
      "Ratingen",
      "Recke",
      "Recklinghausen",
      "Rees",
      "Rhede",
      "Rheinberg",
      "Rheine",
      "Rietberg",
      "Rommerskirchen",
      "Rosrath",
      "Salzkotten",
      "Sankt Augustin",
      "Schermbeck",
      "Schleiden",
      "Schmallenberg",
      "Schwalmtal",
      "Schwelm",
      "Schwerte",
      "Selm",
      "Sendenhorst",
      "Siegburg",
      "Simmerath",
      "Spenge",
      "Sprockhovel",
      "Stadtlohn",
      "Steinfurt",
      "Steinhagen",
      "Steinheim",
      "Stolberg",
      "Straelen",
      "Sundern",
      "Telgte",
      "Troisdorf",
      "Unna",
      "Velbert",
      "Velen",
      "Verl",
      "Versmold",
      "Vlotho",
      "Voerde",
      "Vreden",
      "Wachtberg",
      "Wadersloh",
      "Wahn-Heide",
      "Waltrop",
      "Warburg",
      "Warendorf",
      "Warstein",
      "Wassenberg",
      "Weeze",
      "Wegberg",
      "Weilerswist",
      "Wenden",
      "Werdohl",
      "Werl",
      "Wermelskirchen",
      "Werther",
      "Wesseling",
      "Wetter (Ruhr)",
      "Wickede",
      "Wiehl",
      "Willich",
      "Wilnsdorf",
      "Winterberg",
      "Wipperfürth",
      "Witten",
      "Xanten",
      "Zulpich",
      "Wuppertal",
      "Wesel",
      "Werne",
      "Viersen",
      "Solingen",
      "Soest",
      "Siegen",
      "Riehl",
      "Rheinbach",
      "Remscheid",
      "Rahden",
      "Pulheim",
      "Paderborn",
      "Oelde",
      "Oberhausen",
      "Neuss",
      "Münster",
      "Mülheim",
      "Much",
      "Mönchengladbach",
      "Minden",
      "Marl",
      "Lüdenscheid",
      "Lotte",
      "Krefeld",
      "Hilden",
      "Herten",
      "Herne",
      "Hamm",
      "Gütersloh",
      "Essen",
      "Dusseldorf",
      "Duisburg",
      "Dortmund",
      "Datteln",
      "Cologne",
      "Bottrop",
      "Bonn",
      "Bochum",
      "Bielefeld",
      "Bergkamen",
      "Alpen",
      "Ahaus",
      "Aachen"
    ],
    "Mecklenburg-Vorpommern": [
      "Anklam",
      "Bad Doberan",
      "Boizenburg",
      "Greifswald",
      "Grimmen",
      "Guest",
      "Loddin",
      "Ludwigslust",
      "Mecklenburg",
      "Neubrandenburg",
      "Neustrelitz",
      "Nienhagen",
      "Pampow",
      "Parchim",
      "Pasewalk",
      "Putbus",
      "Rechlin",
      "Ribnitz-Damgarten",
      "Sassnitz",
      "Waren",
      "Warnemuende",
      "Weststadt",
      "Wismar",
      "Wolgast",
      "Stralsund",
      "Schwerin",
      "Rostock"
    ],
    "Lower Saxony": [
      "Achim",
      "Aerzen",
      "Alfeld",
      "Aurich",
      "Bad Bentheim",
      "Bad Essen",
      "Bad Harzburg",
      "Bad Pyrmont",
      "Bad Salzdetfurth",
      "Bad Zwischenahn",
      "Bassum",
      "Belm",
      "Bergen",
      "Bovenden",
      "Bramsche",
      "Buchholz in der Nordheide",
      "Burgdorf",
      "Buxtehude",
      "Celle",
      "Clausthal-Zellerfeld",
      "Cloppenburg",
      "Cuxhaven",
      "Damme",
      "Dassel",
      "Delmenhorst",
      "Diepholz",
      "Dinklage",
      "Drage",
      "Drochtersen",
      "Duderstadt",
      "Einbeck",
      "Friesoythe",
      "Ganderkesee",
      "Garbsen",
      "Garrel",
      "Gifhorn",
      "Goettingen",
      "Goslar",
      "Göttingen",
      "Hagenburg",
      "Hagen im Bremischen",
      "Hamelin",
      "Hannover",
      "Hannoversch Münden",
      "Haren",
      "Hasbergen",
      "Hatten",
      "Helmstedt",
      "Hemmingen",
      "Hessisch Oldendorf",
      "Hildesheim",
      "Hilter",
      "Holzminden",
      "Hude",
      "Ilsede",
      "Isernhagen Farster Bauerschaft",
      "Jever",
      "Jork",
      "Laatzen",
      "Langelsheim",
      "Langenhagen",
      "Lehre",
      "Lehrte",
      "Leiferde",
      "Lengede",
      "Lilienthal",
      "Lingen",
      "Lohne",
      "Meppen",
      "Neu Wulmstorf",
      "Nienburg",
      "Nordenham",
      "Norderney",
      "Northeim",
      "Obernkirchen",
      "Osnabrueck",
      "Osterholz-Scharmbeck",
      "Otterndorf",
      "Oyten",
      "Papenburg",
      "Peine",
      "Rastede",
      "Rinteln",
      "Ritterhude",
      "Rotenburg",
      "Salzgitter",
      "Salzhausen",
      "Salzhemmendorf",
      "Sarstedt",
      "Scheessel",
      "Schiffdorf",
      "Schneverdingen",
      "Schortens",
      "Schuttorf",
      "Schwanewede",
      "Seesen",
      "Seevetal",
      "Sehnde",
      "Soltau",
      "Springe",
      "Stade",
      "Stadthagen",
      "Stuhr",
      "Sulingen",
      "Syke",
      "Tostedt",
      "Twist",
      "Uslar",
      "Varel",
      "Vechelde",
      "Verden",
      "Vienenburg",
      "Vierhofen",
      "Vorwerk",
      "Wallenhorst",
      "Walsrode",
      "Wardenburg",
      "Weener",
      "Werdum",
      "Westerstede",
      "Wiefelstede",
      "Wiesmoor",
      "Wildeshausen",
      "Winsen",
      "Wittingen",
      "Wittmund",
      "Wolfenbuettel",
      "Wolfenbüttel",
      "Wunstorf",
      "Zetel",
      "Zeven",
      "Wolfsburg",
      "Wilhelmshaven",
      "Vechta",
      "Uelzen",
      "Stelle",
      "Seelze",
      "Pattensen",
      "Osnabrück",
      "Oldenburg",
      "Nordhorn",
      "Norden",
      "Melle",
      "Lüneburg",
      "Leer",
      "Hanover",
      "Emden",
      "Braunschweig",
      "Apen"
    ],
    "Land Berlin": ["Buchholz", "Pankow", "Berlin"],
    Hesse: [
      "Alsfeld",
      "Altenstadt",
      "Babenhausen",
      "Bad Arolsen",
      "Bad Hersfeld",
      "Bad Homburg",
      "Bad Homburg vor der Hoehe",
      "Bad Nauheim",
      "Bad Orb",
      "Bad Schwalbach",
      "Bad Soden am Taunus",
      "Bad Vilbel",
      "Bad Wildungen",
      "Baunatal",
      "Bebra",
      "Butzbach",
      "Dieburg",
      "Dietzenbach",
      "Dillenburg",
      "Dreieich",
      "Eichenzell",
      "Eltville",
      "Eppstein",
      "Erlensee",
      "Eschborn",
      "Eschwege",
      "Felsberg",
      "Frankenberg",
      "Frankfurt am Main",
      "Friedrichsdorf",
      "Fuldatal",
      "Geisenheim",
      "Gelnhausen",
      "Giessen",
      "Ginsheim-Gustavsburg",
      "Gladenbach",
      "Griesheim",
      "Gudensberg",
      "Hadamar",
      "Haiger",
      "Hanau",
      "Hanau am Main",
      "Hattersheim",
      "Heppenheim an der Bergstrasse",
      "Herborn",
      "Hessisch Lichtenau",
      "Heuchelheim",
      "Heusenstamm",
      "Hochheim am Main",
      "Hofgeismar",
      "Hofheim am Taunus",
      "Idstein",
      "Karben",
      "Kelsterbach",
      "Kirchhain",
      "Korbach",
      "Kronberg",
      "Lampertheim",
      "Langen",
      "Langenselbold",
      "Laubach",
      "Lauterbach",
      "Lich",
      "Limburg an der Lahn",
      "Lohfelden",
      "Lollar",
      "Lorsch",
      "Maintal",
      "Marburg an der Lahn",
      "Melsungen",
      "Michelstadt",
      "Munzenberg",
      "Nentershausen",
      "Neuhof",
      "Neu Isenburg",
      "Nidda",
      "Nidderau",
      "Niederrad",
      "Ober-Ramstadt",
      "Obertshausen",
      "Oberursel",
      "Offenbach",
      "Pfungstadt",
      "Raunheim",
      "Reiskirchen",
      "Riedstadt",
      "Rimbach",
      "Rodenbach",
      "Rodgau",
      "Russelsheim",
      "Schlitz",
      "Schmitten",
      "Schotten",
      "Seeheim-Jugenheim",
      "Solms",
      "Stadtallendorf",
      "Taunusstein",
      "Trebur",
      "Usingen",
      "Vellmar",
      "Viernheim",
      "Waechtersbach",
      "Weilburg",
      "Weiterstadt",
      "Wetzlar",
      "Witzenhausen",
      "Wolfhagen",
      "Wiesbaden",
      "Seligenstadt",
      "Marburg",
      "Kassel",
      "Fulda",
      "Darmstadt",
      "Braunfels",
      "Bensheim"
    ],
    Hamburg: [
      "Altona",
      "Barmbek-Nord",
      "Bergedorf",
      "Borgfelde",
      "Eidelstedt",
      "Eimsbuettel",
      "Fuhlsbuettel",
      "Hamburg-Mitte",
      "Hamburg-Nord",
      "Harburg",
      "Kleiner Grasbrook",
      "Langenhorn",
      "Marienthal",
      "Neustadt",
      "Ohlsdorf",
      "Steilshoop",
      "Stellingen",
      "St. Georg",
      "St. Pauli",
      "Wandsbek",
      "Winterhude",
      "Hamburg",
      "Altstadt"
    ],
    Bremen: ["Bremerhaven", "Bremen"],
    Brandenburg: [
      "Bad Belzig",
      "Beelitz",
      "Brandenburg an der Havel",
      "Cottbus",
      "Eberswalde",
      "Eisenhüttenstadt",
      "Erkner",
      "Falkensee",
      "Finsterwalde",
      "Forst",
      "Frankfurt (Oder)",
      "Furstenwalde",
      "Guben",
      "Halbe",
      "Hennigsdorf",
      "Herzberg",
      "Hohen Neuendorf",
      "Kleinmachnow",
      "Kyritz",
      "Lauchhammer",
      "Luckau",
      "Luckenwalde",
      "Ludwigsfelde",
      "Melchow",
      "Munchehofe",
      "Nauen",
      "Neuenhagen",
      "Neuruppin",
      "Oranienburg",
      "Prenzlau",
      "Pritzwalk",
      "Rehfelde",
      "Rheinsberg",
      "Schonefeld",
      "Senftenberg",
      "Sperenberg",
      "Spremberg",
      "Stahnsdorf",
      "Strausberg",
      "Teltow",
      "Templin",
      "Velten",
      "Wandlitz",
      "Werder",
      "Wittenberge",
      "Wittstock",
      "Zehdenick",
      "Zossen",
      "Rathenow",
      "Potsdam",
      "Brandenburg"
    ],
    Berlin: [
      "Adlershof",
      "Altglienicke",
      "Alt-Hohenschoenhausen",
      "Baumschulenweg",
      "Berlin",
      "Berlin Schoeneberg",
      "Bezirk Kreuzberg",
      "Britz",
      "Buchholz",
      "Charlottenburg Bezirk",
      "Charlottenburg-Nord",
      "Dahlem",
      "Falkenhagener Feld",
      "Friedrichsfelde",
      "Friedrichshagen",
      "Friedrichshain Bezirk",
      "Gesundbrunnen",
      "Gropiusstadt",
      "Grunewald",
      "Halensee",
      "Hansaviertel",
      "Haselhorst",
      "Heiligensee",
      "Heinersdorf",
      "Hermsdorf",
      "Kladow",
      "Kopenick Bezirk",
      "Lankwitz",
      "Lichtenberg",
      "Lichtenrade",
      "Lichterfelde",
      "Mariendorf",
      "Marzahn",
      "Moabit",
      "Nikolassee",
      "Pankow",
      "Plaenterwald",
      "Prenzlauer Berg Bezirk",
      "Reinickendorf",
      "Rummelsburg",
      "Schmargendorf",
      "Schoneberg Bezirk",
      "Spandau",
      "Tegel",
      "Tempelhof Bezirk",
      "Tiergarten Bezirk",
      "Treptow Bezirk",
      "Wannsee",
      "Wedding Bezirk",
      "Westend",
      "Wilhelmstadt",
      "Wilmersdorf Bezirk",
      "Wittenau",
      "Mitte",
      "Berlin Treptow"
    ],
    Bavaria: [
      "Abensberg",
      "Aichach",
      "Ainring",
      "Altdorf",
      "Alzenau in Unterfranken",
      "Amberg",
      "Ansbach",
      "Aschaffenburg",
      "Bad Aibling",
      "Bad Kissingen",
      "Bad Reichenhall",
      "Bad Staffelstein",
      "Bad Toelz",
      "Bad Windsheim",
      "Bayern",
      "Bayrischzell",
      "Berchtesgaden",
      "Bischofsmais",
      "Bobingen",
      "Bogen",
      "Bogenhausen",
      "Brand",
      "Buchbrunn",
      "Buchloe",
      "Burgberg",
      "Burgebrach",
      "Burghausen",
      "Burglengenfeld",
      "Burg Unter-Falkenstein",
      "Cadolzburg",
      "Castell",
      "Chiemsee",
      "Dachau",
      "Deggendorf",
      "Dingolfing",
      "Dinkelsbühl",
      "Donauwörth",
      "Dorfen",
      "Ebersberg",
      "Eggenfelden",
      "Eichenau",
      "Feucht",
      "Forchheim",
      "Freilassing",
      "Freising",
      "Fridolfing",
      "Friedberg",
      "Fürstenfeldbruck",
      "Füssen",
      "Gaimersheim",
      "Garmisch-Partenkirchen",
      "Gebsattel",
      "Geiselwind",
      "Geisenfeld",
      "Geisenhausen",
      "Geretsried",
      "Germering",
      "Gersthofen",
      "Gilching",
      "Glonn",
      "Goldbach",
      "Grafelfing",
      "Grainau",
      "Greding",
      "Grossostheim",
      "Grunwald",
      "Gunzenhausen",
      "Gutenstetten",
      "Haidmuhle",
      "Hallbergmoos",
      "Hammelburg",
      "Hassfurt",
      "Hauzenberg",
      "Hebertshausen",
      "Hersbruck",
      "Herzogenaurach",
      "Hilpoltstein",
      "Hirschaid",
      "Hochberg",
      "Hof",
      "Holzkirchen",
      "Illertissen",
      "Immenstadt im Allgau",
      "Ismaning",
      "Karlsfeld",
      "Karlstadt",
      "Kaufbeuren",
      "Kelheim",
      "Kempten (Allgaeu)",
      "Kissing",
      "Kitzingen",
      "Kolbermoor",
      "Kreuth",
      "Kronach",
      "Krumbach",
      "Landsberg am Lech",
      "Langenzenn",
      "Lauf an der Pegnitz",
      "Leipheim",
      "Lenggries",
      "Lichtenfels",
      "Lohr am Main",
      "Mainburg",
      "Manching",
      "Margetshochheim",
      "Marktheidenfeld",
      "Marktoberdorf",
      "Marktredwitz",
      "Markt Schwaben",
      "Miesbach",
      "Mindelheim",
      "Mittenwald",
      "Moosburg",
      "Neuburg an der Donau",
      "Neufahrn bei Freising",
      "Neumarkt in der Oberpfalz",
      "Neutraubling",
      "Neu-Ulm",
      "Nördlingen",
      "Nuernberg",
      "Oberasbach",
      "Oberdolling",
      "Oberhaching",
      "Oberstaufen",
      "Ofterschwang",
      "Ottobrunn",
      "Pasing",
      "Pegnitz",
      "Penzberg",
      "Pfaffenhofen an der Ilm",
      "Pfarrkirchen",
      "Plattling",
      "Pleinfeld",
      "Poing",
      "Puchheim",
      "Pullach im Isartal",
      "Regen",
      "Regenstauf",
      "Rehau",
      "Roding",
      "Rosstal",
      "Roth",
      "Salzweg",
      "Schliersee",
      "Schollkrippen",
      "Schongau",
      "Schrobenhausen",
      "Schwabach",
      "Schwandorf in Bayern",
      "Schwangau",
      "Schwarzenbach",
      "Schweinfurt",
      "Selb",
      "Sonthofen",
      "Soyen",
      "Stadtbergen",
      "Starnberg",
      "Stegaurach",
      "Straubing",
      "Sulzbach-Rosenberg",
      "Taufkirchen",
      "Train",
      "Traunreut",
      "Traunstein",
      "Treuchtlingen",
      "Tuntenhausen",
      "Unterdiessen",
      "Unterfoehring",
      "Unterhaching",
      "Vaterstetten",
      "Vilsbiburg",
      "Vilshofen",
      "Waldkraiburg",
      "Wallerstein",
      "Weiden",
      "Weilheim",
      "Wendelstein",
      "Werneck",
      "Wertach",
      "Wolfratshausen",
      "Wolnzach",
      "Worth am Main",
      "Wuerzburg",
      "Wunsiedel",
      "Wurzburg",
      "Zirndorf",
      "Zwiesel",
      "Stein",
      "Senden",
      "Rosenheim",
      "Regensburg",
      "Planegg",
      "Passau",
      "Nuremberg",
      "Munich",
      "Memmingen",
      "Lindau",
      "Landshut",
      "Kulmbach",
      "Ingolstadt",
      "Haar",
      "Fürth",
      "Erlangen",
      "Erding",
      "Coburg",
      "Cham",
      "Bayreuth",
      "Bamberg",
      "Augsburg"
    ],
    "Baden-Württemberg Region": [
      "Aalen",
      "Abstatt",
      "Achern",
      "Affalterbach",
      "Albstadt",
      "Asperg",
      "Aulendorf",
      "Backnang",
      "Baden-Baden",
      "Bad Mergentheim",
      "Bad Peterstal-Griesbach",
      "Bad Rappenau",
      "Bad Teinach-Zavelstein",
      "Bad Urach",
      "Bad Waldsee",
      "Bad Wurzach",
      "Baiersbronn",
      "Balingen",
      "Besigheim",
      "Birkenfeld",
      "Blumberg",
      "Böblingen",
      "Boeblingen",
      "Bopfingen",
      "Botzingen",
      "Brackenheim",
      "Breisach",
      "Bretten",
      "Bruchsal",
      "Buhl",
      "Calw",
      "Crailsheim",
      "Dauchingen",
      "Denzlingen",
      "Dischingen",
      "Donaueschingen",
      "Donzdorf",
      "Dossenheim",
      "Eberbach",
      "Ehingen",
      "Eimeldingen",
      "Eislingen",
      "Ellwangen",
      "Emmendingen",
      "Engen",
      "Eppingen",
      "Erbach",
      "Esslingen",
      "Ettlingen",
      "Feldberg",
      "Filderstadt",
      "Freudenstadt",
      "Friedrichshafen",
      "Gaggenau",
      "Gaildorf",
      "Gengenbach",
      "Gerlingen",
      "Gernsbach",
      "Gottmadingen",
      "Grenzach-Wyhlen",
      "Gundelfingen",
      "Gutenbach",
      "Hausach",
      "Hechingen",
      "Heddesheim",
      "Hemsbach",
      "Herrenberg",
      "Hockenheim",
      "Holzgerlingen",
      "Ilsfeld",
      "Immendingen",
      "Immenstaad am Bodensee",
      "Kehl",
      "Ketsch",
      "Kirchheim unter Teck",
      "Konigsbronn",
      "Korb",
      "Korntal",
      "Kornwestheim",
      "Ladenburg",
      "Lahr",
      "Langenargen",
      "Langenau",
      "Laupheim",
      "Leimen",
      "Leinfelden-Echterdingen",
      "Leingarten",
      "Leonberg",
      "Loerrach",
      "Loffingen",
      "Lorch",
      "Lossburg",
      "Ludwigsburg",
      "Markdorf",
      "Meckenbeuren",
      "Mengen",
      "Merzhausen",
      "Metzingen",
      "Mosbach",
      "Muenstertal/Schwarzwald",
      "Müllheim",
      "Nagold",
      "Neckarsulm",
      "Neuhausen ob Eck",
      "Niedereschach",
      "Nurtingen",
      "Nussloch",
      "Oberkirch",
      "Oberndorf",
      "Ostfildern",
      "Pfullendorf",
      "Pfullingen",
      "Plochingen",
      "Radolfzell",
      "Rastatt",
      "Renningen",
      "Reutlingen",
      "Rheinau",
      "Rheinfelden",
      "Riedlingen",
      "Rottenburg",
      "Rottweil",
      "Rust",
      "Rutesheim",
      "Sandhausen",
      "Saulgau",
      "Schluchsee",
      "Schopfheim",
      "Schorndorf",
      "Schramberg",
      "Schriesheim",
      "Schwäbisch Gmünd",
      "Schwäbisch Hall",
      "Schwaigern",
      "Schwetzingen",
      "Sigmaringen",
      "Sindelfingen",
      "Sinsheim",
      "Sinzheim",
      "Spaichingen",
      "Steinen",
      "Stockach",
      "Stuttgart Feuerbach",
      "Stuttgart-Ost",
      "Sussen",
      "Tamm",
      "Tauberbischofsheim",
      "Trossingen",
      "Tübingen",
      "Tuebingen",
      "Tuttlingen",
      "Uberlingen",
      "Uhingen",
      "Villingen-Schwenningen",
      "Waiblingen",
      "Waldkirch",
      "Walldorf",
      "Wehr",
      "Weingarten",
      "Weinheim",
      "Weinsberg",
      "Weinstadt-Endersbach",
      "Welzheim",
      "Wernau",
      "Wiesloch",
      "Wildberg",
      "Winnenden",
      "Ulm",
      "Stuttgart",
      "Singen",
      "Ravensburg",
      "Pforzheim",
      "Offenburg",
      "Mannheim",
      "Malsch",
      "Konstanz",
      "Karlsruhe",
      "Heilbronn",
      "Heidenheim",
      "Heidelberg",
      "Göppingen",
      "Freiburg",
      "Fellbach",
      "Buchen"
    ]
  },
  Georgia: {
    Imereti: ["Kutaisi", "Tsqaltubo"],
    Kakheti: ["Sighnaghi", "Telavi"],
    "Kvemo Kartli": ["Marneuli"],
    "Mtskheta-Mtianeti": ["Mtskheta", "Step'antsminda"],
    "Samegrelo and Zemo Svaneti": ["Mest'ia", "Zugdidi"],
    "Samtskhe-Javakheti": ["Akhaltsikhe", "Borjomi"],
    "Shida Kartli": ["Gori", "Khashuri"],
    "T'bilisi": ["Tbilisi"],
    "K'alak'i T'bilisi": ["Tbilisi"],
    Ajaria: ["Kobuleti", "Makhinjauri", "Batumi"],
    Abkhazia: [
      "Bich'vinta",
      "Gagra",
      "Gali",
      "Sokhumi",
      "Stantsiya Novyy Afon",
      "Tqvarch'eli"
    ]
  },
  Namibia: {
    Hardap: ["Mariental", "Rehoboth"],
    Karas: ["Keetmanshoop", "Lüderitz"],
    "Kavango East": ["Rundu"],
    Kunene: [],
    Omaheke: ["Gobabis"],
    Oshana: ["Oshakati"],
    Oshikoto: ["Tsumeb"],
    Otjozondjupa: ["Grootfontein", "Okahandja", "Otjiwarongo"],
    "Zambezi Region": ["Katima Mulilo"],
    Khomas: ["Windhoek"],
    Erongo: ["Swakopmund", "Walvis Bay"]
  },
  Gambia: {
    "Lower River Division": ["Sankwia"],
    "North Bank": [],
    Western: ["Sukuta"],
    Banjul: ["Bakau", "Banjul"]
  },
  Myanmar: {
    "Bago Region": ["Bago"],
    Kachin: ["Myitkyina"],
    Kayah: ["Loikaw"],
    "Kayah State": [],
    Kayin: ["Hpa-an"],
    "Magway Region": ["Chauk", "Magway", "Pakokku"],
    "Rakhine State": [],
    Shan: ["Lashio", "Taunggyi"],
    "Taninthayi Region": ["Dawei"],
    "Yangon Region": ["Syriam", "Yangon"],
    "Mandalay Region": [
      "Myingyan",
      "Nay Pyi Taw",
      "Pyinmana",
      "Pyin Oo Lwin",
      "Mandalay"
    ]
  },
  Gabon: {
    "Haut-Ogooué": ["Franceville"],
    Nyanga: ["Tchibanga"],
    "Ogooué-Maritime": ["Gamba", "Port-Gentil"],
    "Woleu-Ntem": ["Oyem"],
    Estuaire: ["Libreville"]
  },
  "French Southern Territories": { Kerguelen: ["Port-aux-Francais"] },
  "French Polynesia": {
    "Iles Australes": ["Moerai"],
    "Iles du Vent": ["Faaa", "Mahina", "Papao", "Papeete", "Punaauia"],
    "Iles Marquises": ["Taiohae"],
    "Iles Sous-le-Vent": ["Uturoa", "Vaitape"]
  },
  "French Guiana": {
    Guyane: ["Cayenne", "Kourou", "Matoury", "Saint-Laurent-du-Maroni"],
    "": ["Kourou", "Cayenne"]
  },
  France: {
    Aquitaine: [
      "Agen",
      "Anglet",
      "Angresse",
      "Arbonne",
      "Arcachon",
      "Bayonne",
      "Begles",
      "Bergerac",
      "Biarritz",
      "Blanquefort",
      "Bordeaux",
      "Capbreton",
      "Cenon",
      "Cestas",
      "Chancelade",
      "Dax",
      "Duras",
      "Eysines",
      "Floirac",
      "Gradignan",
      "Guethary",
      "Gujan-Mestras",
      "Jurancon",
      "Lacanau",
      "Lasseube",
      "La Teste-de-Buch",
      "Le Bouscat",
      "Le Haillan",
      "Le Porge",
      "Libourne",
      "Lormont",
      "Marmande",
      "Martillac",
      "Mont-de-Marsan",
      "Pau",
      "Pessac",
      "Saint-Emilion",
      "Saint-Jean-de-Luz",
      "Saint-Jean-Pied-de-Port",
      "Saint-Paul-les-Dax",
      "Seignosse",
      "Soorts-Hossegor",
      "Talence",
      "Villeneuve-sur-Lot",
      "Villereal"
    ],
    Auvergne: [
      "Aurillac",
      "Beaulon",
      "Chaudes-Aigues",
      "Clermont-Ferrand",
      "Issoire",
      "Le Puy-en-Velay",
      "Paulhaguet",
      "Vichy"
    ],
    Bourgogne: [
      "Ancy-le-Franc",
      "Autun",
      "Auxerre",
      "Avallon",
      "Beaune",
      "Chateau-Chinon(Ville)",
      "Chatillon-sur-Seine",
      "Clamecy",
      "Dijon",
      "Le Creusot",
      "Macon",
      "Magny-Cours",
      "Marmagne",
      "Montceau-les-Mines",
      "Pierre-de-Bresse",
      "Quetigny",
      "Sagy",
      "Saint-Gengoux-le-National",
      "Sens",
      "Velars-sur-Ouche",
      "Venarey-les-Laumes"
    ],
    "Champagne-Ardenne": [
      "Bar-sur-Aube",
      "Bezannes",
      "Dizy",
      "Reims",
      "Saint-Dizier",
      "Sedan",
      "Troyes"
    ],
    "Franche-Comte": ["Besancon", "Les Rousses", "Montbeliard", "Salbert"],
    "Haute-Normandie": [
      "Barentin",
      "Canteleu",
      "Dieppe",
      "Elbeuf",
      "Etretat",
      "Evreux",
      "La Chapelle-Reanville",
      "Le Grand-Quevilly",
      "Le Havre",
      "Le Treport",
      "Louviers",
      "Montivilliers",
      "Mont-Saint-Aignan",
      "Rouen",
      "Vernon"
    ],
    "Languedoc-Roussillon": [
      "Agde",
      "Anduze",
      "Beziers",
      "Boujan-sur-Libron",
      "Canet-en-Roussillon",
      "Capestang",
      "Carcassonne",
      "Castelnau-le-Lez",
      "Fleury",
      "Frontignan",
      "Gruissan",
      "La Grande-Motte",
      "La Tour-sur-Orb",
      "Lattes",
      "Laverune",
      "Le Barcares",
      "Le Grau-du-Roi",
      "Lodeve",
      "Lunel",
      "Mauguio",
      "Mende",
      "Meze",
      "Montpellier",
      "Montreal",
      "Narbonne",
      "Nimes",
      "Olonzac",
      "Palavas-les-Flots",
      "Perols",
      "Perpignan",
      "Port-la-Nouvelle",
      "Saint-Bauzille-de-Putois",
      "Saint-Chinian",
      "Saint-Cyprien-Plage",
      "Sainte-Marie-Plage",
      "Saint-Jean-de-Vedas",
      "Saint-Mathieu-de-Treviers",
      "Sant Genis de Fontanes",
      "Sete",
      "Villeneuve-les-Avignon",
      "Villeneuve-les-Maguelone"
    ],
    Limousin: [
      "Aubusson",
      "Brive-la-Gaillarde",
      "Chamberet",
      "Egletons",
      "Limoges",
      "Peyrat-le-Chateau"
    ],
    Lorraine: [
      "Bitche",
      "Epinal",
      "Hambach",
      "Hayange",
      "Laxou",
      "Metz",
      "Nancy",
      "Remiremont",
      "Russange",
      "Saint-Avold",
      "Sarreguemines",
      "Thionville",
      "Verdun",
      "Yutz"
    ],
    "Lower Normandy": [
      "Agneaux",
      "Alencon",
      "Bayeux",
      "Belleme",
      "Blainville-sur-Orne",
      "Cabourg",
      "Caen",
      "Deauville",
      "Flers",
      "Granville",
      "Honfleur",
      "Lisieux",
      "Montmartin-sur-Mer",
      "Pontorson",
      "Port-en-Bessin-Huppain",
      "Remalard",
      "Tesse-la-Madeleine",
      "Torigni-sur-Vire",
      "Tourlaville",
      "Trouville-sur-Mer",
      "Villers-sur-Mer"
    ],
    Picardie: [
      "Abbeville",
      "Amiens",
      "Beauvais",
      "Chantilly",
      "Creil",
      "Fort-Mahon-Plage",
      "Itancourt",
      "Jussy",
      "Laon",
      "Le Crotoy",
      "Noyon",
      "Saint-Quentin",
      "Senlis",
      "Soissons",
      "Tergnier",
      "Thiers-sur-Theve",
      "Ver-sur-Launette",
      "Vineuil-Saint-Firmin"
    ],
    "Poitou-Charentes": [
      "Availles-Limouzine",
      "Aytre",
      "Bourcefranc-le-Chapus",
      "Boyard-Ville",
      "Bressuire",
      "Chatelaillon-Plage",
      "Chauvigny",
      "Cognac",
      "Dolus-d'Oleron",
      "La Couarde-sur-Mer",
      "La Flotte",
      "La Rochelle",
      "La Roche-Posay",
      "Le Bois-Plage-en-Re",
      "Les Mathes",
      "Niort",
      "Poitiers",
      "Rochefort",
      "Royan",
      "Saintes",
      "Saint-Georges-des-Coteaux",
      "Saint-Jean-d'Angely",
      "Saint-Jean-de-Sauves",
      "Saint-Palais-sur-Mer"
    ],
    "Rhone-Alpes": [
      "Albertville",
      "Annecy",
      "Annecy-le-Vieux",
      "Aouste-sur-Sye",
      "Beaumont",
      "Belle-Plagne",
      "Bellevaux",
      "Beynost",
      "Blace",
      "Bourg-en-Bresse",
      "Bourg-Saint-Maurice",
      "Buis-les-Baronnies",
      "Caluire-et-Cuire",
      "Chambery",
      "Chamonix-Mont-Blanc",
      "Charentay",
      "Charly",
      "Chatel",
      "Chatte",
      "Collonges-sous-Saleve",
      "Courchevel",
      "Courzieu",
      "Decines-Charpieu",
      "Die",
      "Divonne-les-Bains",
      "Ecully",
      "Evian-les-Bains",
      "Fontaines-sur-Saone",
      "Grignan",
      "Guilherand-Granges",
      "Huez",
      "La Clusaz",
      "La Roche-de-Glun",
      "La Tronche",
      "Le Gua",
      "Les Deux Alpes",
      "Les Gets",
      "Lyon",
      "Megeve",
      "Meribel",
      "Meylan",
      "Meyzieu",
      "Modane",
      "Moirans",
      "Morzine",
      "Pierre-Chatel",
      "Pringy",
      "Roanne",
      "Saint-Alban-Leysse",
      "Saint-Bonnet-le-Chateau",
      "Saint-Felicien",
      "Saint-Fons",
      "Saint-Genix-sur-Guiers",
      "Saint-Gervais-les-Bains",
      "Saint-Jorioz",
      "Saint-Martin-le-Vinoux",
      "Saint-Michel-de-Maurienne",
      "Samoens",
      "Sourcieux-les-Mines",
      "Talloires",
      "Tignes",
      "Tournon-sur-Rhone",
      "Val-d'Isere",
      "Val Thorens",
      "Vienne",
      "Villefranche-sur-Saone",
      "Ville-la-Grand"
    ],
    "Provence-Alpes-Côte d'Azur": [
      "Aix-en-Provence",
      "Allauch",
      "Aubagne",
      "Bandol",
      "Barcelonnette",
      "Bargemon",
      "Beausoleil",
      "Bedarrides",
      "Biot",
      "Bonnieux",
      "Briançon",
      "Brignoles",
      "Cagnes-sur-Mer",
      "Callian",
      "Cap-d'Ail",
      "Carpentras",
      "Carqueiranne",
      "Carry-le-Rouet",
      "Cassis",
      "Cavaillon",
      "Cavalaire-sur-Mer",
      "Cereste",
      "Châteauneuf-du-Pape",
      "Chorges",
      "Cornillon-Confoux",
      "Digne-les-Bains",
      "Draguignan",
      "Embrun",
      "Eygalieres",
      "Eze",
      "Fos-sur-Mer",
      "Fréjus",
      "Gardanne",
      "Gassin",
      "Gordes",
      "Guillestre",
      "Istres",
      "La Batie-Neuve",
      "La Ciotat",
      "La Colle-sur-Loup",
      "La Crau",
      "La Garde",
      "Laragne-Monteglin",
      "La Roche-des-Arnauds",
      "La Seyne-sur-Mer",
      "La Turbie",
      "La Valette-du-Var",
      "Le Cannet",
      "Le Cannet-des-Maures",
      "Le Castellet",
      "Le Lavandou",
      "Le Pontet",
      "Le Puy-Sainte-Reparade",
      "Les Pennes-Mirabeau",
      "L'Isle-sur-la-Sorgue",
      "Lourmarin",
      "Mandelieu-la-Napoule",
      "Manosque",
      "Marignane",
      "Marseille 02",
      "Marseille 03",
      "Marseille 04",
      "Marseille 05",
      "Marseille 06",
      "Marseille 07",
      "Marseille 08",
      "Marseille 09",
      "Marseille 11",
      "Marseille 12",
      "Marseille 13",
      "Marseille 14",
      "Martigues",
      "Maussane-les-Alpilles",
      "Menton",
      "Meyreuil",
      "Miramas",
      "Monteux",
      "Mougins",
      "Ollioules",
      "Opio",
      "Oppede",
      "Orange",
      "Pertuis",
      "Peymeinade",
      "Puyloubier",
      "Ramatuelle",
      "Roquebrune-Cap-Martin",
      "Roussillon",
      "Saint-Andre-de-la-Roche",
      "Sainte-Maxime",
      "Saintes-Maries-de-la-Mer",
      "Saint-Jean-Cap-Ferrat",
      "Saint-Laurent-du-Var",
      "Saint-Paul-de-Vence",
      "Saint-Raphaël",
      "Saint-Rémy-de-Provence",
      "Saint-Tropez",
      "Salernes",
      "Salon-de-Provence",
      "Sanary-sur-Mer",
      "Sault",
      "Six-Fours-les-Plages",
      "Sorgues",
      "Tarascon",
      "Theoule-sur-Mer",
      "Vaison-la-Romaine",
      "Valensole",
      "Vallauris",
      "Ventabren",
      "Villecroze",
      "Villefranche-sur-Mer",
      "Villeneuve",
      "Vitrolles",
      "Volonne",
      "Vence",
      "Valbonne",
      "Toulon",
      "Nice",
      "Marseille",
      "Hyères",
      "Grasse",
      "Gap",
      "Carros",
      "Cannes",
      "Avignon",
      "Arles",
      "Apt",
      "Antibes"
    ],
    "Pays de la Loire": [
      "Arconnay",
      "Arnage",
      "Bouguenais",
      "Carquefou",
      "Challans",
      "Cholet",
      "Clisson",
      "Fontenay-le-Comte",
      "Guerande",
      "La Baule-Escoublac",
      "La Gueriniere",
      "La Roche-sur-Yon",
      "Laval",
      "Le Pouliguen",
      "Les Epesses",
      "Les Herbiers",
      "Les Sables-d'Olonne",
      "Lucon",
      "Mayenne",
      "Noyant-la-Gravoyere",
      "Orvault",
      "Pornic",
      "Pornichet",
      "Rezé",
      "Sables",
      "Saint-Cosme-en-Vairais",
      "Saint-Herblain",
      "Saint-Hilaire-de-Talmont",
      "Saint-Joachim",
      "Saint-Mathurin-sur-Loire",
      "Saint-Nazaire",
      "Saumur",
      "Trelaze",
      "Vertou",
      "Nantes",
      "Le Mans",
      "Angers"
    ],
    Occitanie: [
      "Agde",
      "Alan",
      "Albi",
      "Alès",
      "Balma",
      "Beaucaire",
      "Béziers",
      "Blagnac",
      "Cahors",
      "Carmaux",
      "Castelnaudary",
      "Castelsarrasin",
      "Castres",
      "Colomiers",
      "Cugnaux",
      "Figeac",
      "Frontignan",
      "Graulhet",
      "Lavaur",
      "Limoux",
      "Lunel",
      "Mauguio",
      "Mazamet",
      "Mende",
      "Moissac",
      "Muret",
      "Nîmes",
      "Pamiers",
      "Sète",
      "Tournefeuille",
      "Vauvert",
      "Toulouse",
      "Tarbes",
      "Rodez",
      "Perpignan",
      "Narbonne",
      "Montpellier",
      "Montauban",
      "Millau",
      "Lourdes",
      "Lattes",
      "Gaillac",
      "Carcassonne",
      "Auch"
    ],
    "Nouvelle-Aquitaine": [
      "Angoulême",
      "Bayonne",
      "Bergerac",
      "Biscarrosse",
      "Blanquefort",
      "Bressuire",
      "Bruges",
      "Cars",
      "Cenon",
      "Châtellerault",
      "Eysines",
      "Floirac",
      "Gradignan",
      "Leon",
      "Libourne",
      "Lons",
      "Lormont",
      "Luxe",
      "Marmande",
      "Mérignac",
      "Orthez",
      "Panazol",
      "Parthenay",
      "Pessac",
      "Rochefort",
      "Saintes",
      "Talence",
      "Taller",
      "Tarnos",
      "Thouars",
      "Tulle",
      "Ussel",
      "Royan",
      "Poitiers",
      "Périgueux",
      "Pau",
      "Niort",
      "Limoges",
      "La Rochelle",
      "Hendaye",
      "Dax",
      "Cognac",
      "Cestas",
      "Bordeaux",
      "Biarritz",
      "Arcachon",
      "Anglet",
      "Agen"
    ],
    Normandy: [
      "Alençon",
      "Argentan",
      "Barentin",
      "Bayeux",
      "Bernay",
      "Dieppe",
      "Elbeuf",
      "Eu",
      "Évreux",
      "Fécamp",
      "Gisors",
      "Granville",
      "Lisieux",
      "Louviers",
      "Maromme",
      "Tourlaville",
      "Vernon",
      "Vire",
      "Yvetot",
      "Rouen",
      "Le Havre",
      "Caen"
    ],
    "Nord-Pas-de-Calais": [
      "Arras",
      "Avion",
      "Berck",
      "Boeschepe",
      "Bondues",
      "Calais",
      "Cambrai",
      "Coudekerque-Branche",
      "Croix",
      "Dunkerque",
      "Faches-Thumesnil",
      "Fretin",
      "Halluin",
      "Haubourdin",
      "Hautmont",
      "La Madeleine",
      "Lambersart",
      "Le Touquet-Paris-Plage",
      "Lezennes",
      "Lille",
      "Maisnil-les-Ruitz",
      "Outreau",
      "Pecquencourt",
      "Roubaix",
      "Saint-Amand-les-Eaux",
      "Saint-Omer",
      "Sin-le-Noble",
      "Steenvoorde",
      "Tourcoing",
      "Valenciennes",
      "Villeneuve-d'Ascq",
      "Vimy",
      "Wattrelos",
      "Wimereux",
      "Berck-Plage"
    ],
    "Midi-Pyrenees": [
      "Albi",
      "Argeles-Gazost",
      "Aurignac",
      "Blagnac",
      "Campan",
      "Cauterets",
      "Cordes-sur-Ciel",
      "Gramat",
      "Ibos",
      "Lalbenque",
      "Lannemezan",
      "Le Sequestre",
      "L'Union",
      "Mirande",
      "Montreal",
      "Ramonville-Saint-Agne",
      "Realmont",
      "Saint-Jean",
      "Saint-Lary-Soulan",
      "Saint-Orens-de-Gameville",
      "Seissan",
      "Tarascon-sur-Ariege",
      "Tarbes",
      "Villefranche-de-Rouergue",
      "Toulouse"
    ],
    "Île-de-France": [
      "Alfortville",
      "Andresy",
      "Arcueil",
      "Argenteuil",
      "Arnouville",
      "Asnieres-sur-Seine",
      "Athis-Mons",
      "Aubervilliers",
      "Aulnay-sous-Bois",
      "Avon",
      "Bagneux",
      "Bagnolet",
      "Bezons",
      "Bievres",
      "Bobigny",
      "Bois-Colombes",
      "Bondy",
      "Boulogne-Billancourt",
      "Bourg-la-Reine",
      "Brétigny-sur-Orge",
      "Brie-Comte-Robert",
      "Brunoy",
      "Bry-sur-Marne",
      "Cachan",
      "Cergy",
      "Champigny-sur-Marne",
      "Chanteloup-en-Brie",
      "Charenton-le-Pont",
      "Châtenay-Malabry",
      "Châtillon",
      "Chatou",
      "Chaville",
      "Chessy",
      "Chevilly-Larue",
      "Chevreuse",
      "Choisy-le-Roi",
      "Clamart",
      "Clichy",
      "Colombes",
      "Combs-la-Ville",
      "Corbeil-Essonnes",
      "Coubert",
      "Coupvray",
      "Courbevoie",
      "Dammarie-les-Lys",
      "Domont",
      "Dourdan",
      "Drancy",
      "Draveil",
      "Eaubonne",
      "Élancourt",
      "Épinay-sur-Seine",
      "Eragny",
      "Ermont",
      "Étampes",
      "Évry",
      "Fontainebleau",
      "Fontenay-sous-Bois",
      "Fosses",
      "Franconville",
      "Fresnes",
      "Gagny",
      "Garches",
      "Garges-lès-Gonesse",
      "Gennevilliers",
      "Gentilly",
      "Gif-sur-Yvette",
      "Gonesse",
      "Goussainville",
      "Grigny",
      "Guyancourt",
      "Hardricourt",
      "Herblay",
      "Houilles",
      "Issy-les-Moulineaux",
      "Joinville-le-Pont",
      "Jouy-le-Moutier",
      "La Celle-Saint-Cloud",
      "La Chapelle-sur-Crecy",
      "La Courneuve",
      "La Defense",
      "La Garenne-Colombes",
      "Lagny-sur-Marne",
      "Le Blanc-Mesnil",
      "Le Bourget",
      "Le Chesnay",
      "Le Kremlin-Bicetre",
      "Le Pecq",
      "Le Perreux-sur-Marne",
      "Le Plessis-Pate",
      "Le Pre-Saint-Gervais",
      "Le Raincy",
      "Les Lilas",
      "Les Mureaux",
      "Les Pavillons-sous-Bois",
      "Les Ulis",
      "Levallois-Perret",
      "Limay",
      "Lognes",
      "Longjumeau",
      "Magny-les-Hameaux",
      "Maisons-Alfort",
      "Maisons-Laffitte",
      "Mantes-la-Jolie",
      "Marcoussis",
      "Maurepas",
      "Meaux",
      "Melun",
      "Mennecy",
      "Mere",
      "Meudon",
      "Mitry-Mory",
      "Montesson",
      "Montfermeil",
      "Montgeron",
      "Montigny-le-Bretonneux",
      "Montmagny",
      "Montmorency",
      "Montrouge",
      "Morangis",
      "Nanterre",
      "Nemours",
      "Neuilly-sur-Marne",
      "Neuilly-sur-Seine",
      "Nogent-sur-Marne",
      "Noisiel",
      "Noisy-le-Grand",
      "Noisy-le-Roi",
      "Noisy-le-Sec",
      "Orly",
      "Orsay",
      "Osny",
      "Palaiseau",
      "Pantin",
      "Pierrefitte-sur-Seine",
      "Poissy",
      "Pontoise",
      "Puteaux",
      "Rambouillet",
      "Ris-Orangis",
      "Romainville",
      "Rosny-sous-Bois",
      "Rueil-Malmaison",
      "Rungis",
      "Sagy",
      "Saint-Arnoult-en-Yvelines",
      "Saint-Cloud",
      "Saint-Denis",
      "Saint-Germain-en-Laye",
      "Saint-Mande",
      "Saint-Maur-des-Fossés",
      "Saint-Michel-sur-Orge",
      "Saint-Ouen",
      "Sannois",
      "Sarcelles",
      "Sartrouville",
      "Savigny-sur-Orge",
      "Sceaux",
      "Serris",
      "Sèvres",
      "Stains",
      "Suresnes",
      "Taverny",
      "Thiais",
      "Torcy",
      "Trappes",
      "Tremblay-en-France",
      "Us",
      "Valenton",
      "Vanves",
      "Vaureal",
      "Verneuil-sur-Seine",
      "Vigneux-sur-Seine",
      "Villejuif",
      "Villemoisson-sur-Orge",
      "Villemomble",
      "Villeneuve-la-Garenne",
      "Villeneuve-le-Roi",
      "Villeparisis",
      "Villepinte",
      "Villiers-le-Bel",
      "Villiers-sur-Marne",
      "Vincennes",
      "Viroflay",
      "Viry-Châtillon",
      "Vitry-sur-Seine",
      "Versailles",
      "Sevran",
      "Provins",
      "Plaisir",
      "Paris",
      "Montreuil",
      "Massy",
      "Malakoff",
      "Ivry-sur-Seine",
      "Créteil",
      "Chelles",
      "Antony"
    ],
    "Hauts-de-France": [
      "Abbeville",
      "Aniche",
      "Annoeullin",
      "Armentières",
      "Arras",
      "Bailleul",
      "Baron",
      "Beauvais",
      "Berck",
      "Béthune",
      "Bondues",
      "Calais",
      "Cambrai",
      "Carvin",
      "Caudry",
      "Chantilly",
      "Château-Thierry",
      "Clermont",
      "Comines",
      "Creil",
      "Croix",
      "Denain",
      "Douai",
      "Dunkirk",
      "Fourmies",
      "Gravelines",
      "Harnes",
      "Hazebrouck",
      "Hirson",
      "La Madeleine",
      "Lambersart",
      "Laon",
      "Le Portel",
      "Liévin",
      "Lomme",
      "Longuenesse",
      "Loos",
      "Marly",
      "Maubeuge",
      "Mouvaux",
      "Noyon",
      "Raismes",
      "Ronchin",
      "Roncq",
      "Roubaix",
      "Senlis",
      "Soissons",
      "Tourcoing",
      "Wasquehal",
      "Wattignies",
      "Wattrelos",
      "Valenciennes",
      "Lille",
      "Lens",
      "Hem",
      "Compiègne",
      "Avion",
      "Amiens",
      "Albert"
    ],
    "Grand Est": [
      "Cernay",
      "Chaumont",
      "Creutzwald",
      "Épernay",
      "Épinal",
      "Florange",
      "Forbach",
      "Gérardmer",
      "Grand",
      "Guebwiller",
      "Haguenau",
      "Hayange",
      "Laxou",
      "Lingolsheim",
      "Longwy",
      "Lunéville",
      "Mulhouse",
      "Obernai",
      "Rombas",
      "Sarrebourg",
      "Saverne",
      "Schiltigheim",
      "Sélestat",
      "Thionville",
      "Verdun",
      "Wittelsheim",
      "Wittenheim",
      "Woippy",
      "Yutz",
      "Troyes",
      "Toul",
      "Strasbourg",
      "Sedan",
      "Sarreguemines",
      "Reims",
      "Nancy",
      "Metz",
      "Colmar"
    ],
    Corsica: [
      "Bonifacio",
      "Brando",
      "Calvi",
      "Cargèse",
      "Cervione",
      "Corte",
      "L'Île-Rousse",
      "Lumio",
      "Pietrosella",
      "Porto-Vecchio",
      "Propriano",
      "San-Nicolao",
      "Zonza",
      "Bastia",
      "Ajaccio"
    ],
    "Centre-Val de Loire": [
      "Amboise",
      "Amilly",
      "Blois",
      "Charge",
      "Chartres",
      "Châteauroux",
      "Dreux",
      "Dry",
      "Gien",
      "Issoudun",
      "Olivet",
      "Pray",
      "Vendome",
      "Vernouillet",
      "Vierzon",
      "Tours",
      "Saran",
      "Orléans",
      "Montargis",
      "Luce",
      "Bourges"
    ],
    Centre: [
      "Argenton-sur-Creuse",
      "Bourges",
      "Chartres",
      "Chateauroux",
      "Chaumont-sur-Loire",
      "Chinon",
      "Dreux",
      "Fleury-les-Aubrais",
      "Gien",
      "Le Blanc",
      "Montargis",
      "Montbazon",
      "Nazelles-Negron",
      "Nogent-le-Roi",
      "Olivet",
      "Orgeres-en-Beauce",
      "Orleans",
      "Romorantin-Lanthenay",
      "Saint-Avertin",
      "Saint-Pierre-des-Corps",
      "Tours",
      "Sainte-Lizaigne"
    ],
    Brittany: [
      "Auray",
      "Brest",
      "Bruz",
      "Carhaix-Plouguer",
      "Cesson-Sévigné",
      "Combourg",
      "Concarneau",
      "Damgan",
      "Dinard",
      "Dinge",
      "Douarnenez",
      "Guidel-Plage",
      "Guipavas",
      "Hennebont",
      "Lamballe",
      "Landerneau",
      "Lanester",
      "Lannion",
      "Loctudy",
      "Loudeac",
      "Morlaix",
      "Paul",
      "Pleneuf-Val-Andre",
      "Plerguer",
      "Ploemeur",
      "Plogoff",
      "Plouisy",
      "Plouzane",
      "Pontivy",
      "Quimperlé",
      "Quintin",
      "Saint-Brieuc",
      "Saint-Malo",
      "Treve",
      "Vannes",
      "Vitré",
      "Rennes",
      "Quimper",
      "Lorient",
      "Dinan"
    ],
    "Bourgogne-Franche-Comte": [
      "Audincourt",
      "Autun",
      "Auxerre",
      "Beaune",
      "Besançon",
      "Hericourt",
      "Joigny",
      "Le Creusot",
      "Mâcon",
      "Nevers",
      "Pontarlier",
      "Quetigny",
      "Sens",
      "Talant",
      "Valentigney",
      "Vesoul",
      "Montbéliard",
      "Dole",
      "Dijon",
      "Belfort"
    ],
    "Auvergne-Rhone-Alpes": [
      "Aix-les-Bains",
      "Albertville",
      "Alex",
      "Annemasse",
      "Annonay",
      "Aubenas",
      "Aubiere",
      "Bonneville",
      "Bourgoin",
      "Brignais",
      "Chambéry",
      "Clermont-Ferrand",
      "Cluses",
      "Cusset",
      "Dance",
      "Ecully",
      "Firminy",
      "Fontaine",
      "Francheville",
      "Gaillard",
      "Genas",
      "Givors",
      "Issoire",
      "Jax",
      "Job",
      "Le Puy-en-Velay",
      "Les Gets",
      "Meylan",
      "Meyzieu",
      "Mions",
      "Montbrison",
      "Montluçon",
      "Moulins",
      "Oullins",
      "Oyonnax",
      "Passy",
      "Pierrelatte",
      "Riom",
      "Roanne",
      "Rumilly",
      "Sallanches",
      "Tarare",
      "Thiers",
      "Vénissieux",
      "Villefontaine",
      "Villeurbanne",
      "Vogue",
      "Voiron",
      "Voreppe",
      "Vienne",
      "Vichy",
      "Valence",
      "Montélimar",
      "Lyon",
      "Grenoble",
      "Chamonix",
      "Bron",
      "Aurillac",
      "Annecy"
    ],
    Alsace: [
      "Barr",
      "Bischheim",
      "Bischoffsheim",
      "Colmar",
      "Haguenau",
      "Illkirch-Graffenstaden",
      "Lingolsheim",
      "Mulhouse",
      "Oberhergheim",
      "Pfaffenheim",
      "Riquewihr",
      "Rouffach",
      "Saint-Louis",
      "Schiltigheim",
      "Soultz-Haut-Rhin",
      "Strasbourg"
    ]
  },
  Mozambique: {
    "Cabo Delgado Province": ["Pemba"],
    "Cidade de Maputo": ["Maputo"],
    Gaza: ["Macia", "Xai-Xai"],
    "Inhambane Province": ["Inhambane", "Maxixe"],
    Manica: ["Chimoio"],
    Maputo: ["Matola", "Ressano Garcia"],
    "Maputo City": ["Maputo"],
    "Maputo Province": ["Matola"],
    Nampula: ["Nampula"],
    "Provincia de Zambezia": ["Quelimane"],
    Sofala: ["Beira", "Dondo"],
    Zambezia: ["Quelimane"],
    Tete: ["Tete"],
    "Sofala Province": ["Beira"]
  },
  Finland: {
    Haeme: ["Haemeenlinna", "Riihimaeki", "Ypaejae"],
    Kainuu: ["Kajaani", "Kuhmo", "Sotkamo", "Vuokatti"],
    Kymenlaakso: ["Anjala", "Hamina", "Karhula", "Kotka", "Kouvola"],
    "North Karelia": ["Joensuu", "Kontiolahti", "Lieksa"],
    "Southern Savonia": [
      "Kangasniemi",
      "Maentyharju",
      "Mikkeli",
      "Pieksämäki",
      "Puumala",
      "Rantasalmi",
      "Savonlinna"
    ],
    "South Karelia": ["Imatra", "Joutseno", "Lappeenranta"],
    "Tavastia Proper": ["Forssa", "Hämeenlinna", "Riihimäki"],
    "Varsinais-Suomi": [
      "Houtskaer",
      "Naantali",
      "Nagu",
      "Raisio",
      "Turku",
      "Uusikaupunki"
    ],
    Uusimaa: [
      "Ekenäs",
      "Hanko",
      "Hyvinge",
      "Järvenpää",
      "Karis",
      "Kerava",
      "Kilo",
      "Kirkkonummi",
      "Koukkuniemi",
      "Lohja",
      "Loviisa",
      "Lovisa",
      "Maentsaelae",
      "Mäntsälä",
      "Nurmijärvi",
      "Porvoo",
      "Sibbo",
      "Sipoo",
      "Teekkarikylae",
      "Tuusula",
      "Vantaa",
      "Vihti",
      "Helsinki",
      "Espoo"
    ],
    "Southern Ostrobothnia": [
      "Ilmajoki",
      "Kauhajoki",
      "Kuortane",
      "Lapua",
      "Nurmo",
      "Seinaejoki",
      "Seinäjoki",
      "Kurikka"
    ],
    Satakunta: ["Karvia", "Lappi", "Rauma", "Ulvila", "Pori"],
    Pirkanmaa: [
      "Akaa",
      "Haemeenkyroe",
      "Lempäälä",
      "Pirkkala",
      "Valkeakoski",
      "Vammala",
      "Ylöjärvi",
      "Tampere",
      "Nokia",
      "Kangasala"
    ],
    "Päijänne Tavastia": [
      "Heinola",
      "Hollola",
      "Nastola",
      "Orimattila",
      "Lahti"
    ],
    Ostrobothnia: ["Esse", "Jakobstad", "Korsholm", "Voera", "Vaasa"],
    "Northern Savo": [
      "Iisalmi",
      "Karttula",
      "Leppävirta",
      "Nilsiae",
      "Siilinjärvi",
      "Varkaus",
      "Kuopio"
    ],
    "Northern Ostrobothnia": [
      "Haukipudas",
      "Kempele",
      "Kiiminki",
      "Kuusamo",
      "Nivala",
      "Piippola",
      "Raahe",
      "Ylivieska",
      "Oulu"
    ],
    Lapland: [
      "Ivalo",
      "Kittilae",
      "Kolari",
      "Posio",
      "Pyhaejaervi",
      "Sodankylae",
      "Tornio",
      "Utsjoki",
      "Rovaniemi",
      "Kemi"
    ],
    "Finland Proper": [
      "Kaarina",
      "Lieto",
      "Naantali",
      "Paimio",
      "Pargas",
      "Raisio",
      "Salo",
      "Uusikaupunki",
      "Turku"
    ],
    "Central Ostrobothnia": ["Kokkola"],
    "Central Finland": [
      "Äänekoski",
      "Jaemsae",
      "Jämsä",
      "Jyvaeskylae",
      "Laukaa",
      "Viitasaari",
      "Keuruu",
      "Jyväskylä"
    ]
  },
  Morocco: {
    "": ["Dakhla"],
    "Beni Mellal-Khenifra": ["Beni Mellal", "Khouribga", "Midelt"],
    "Chaouia-Ouardigha": ["Berrechid", "Bouznika", "Khouribga", "Settat"],
    "Doukkala-Abda": ["El Jadida", "Safi", "Sidi Bennour"],
    "Draa-Tafilalet": ["Errachidia", "Ouarzazate"],
    "Fes-Boulemane": ["Fes"],
    "Gharb-Chrarda-Beni Hssen": ["Kenitra"],
    "Grand Casablanca": ["Casablanca", "Mohammedia"],
    "Guelmim-Es Smara": ["Guelmim", "Tan-Tan"],
    "Guelmim-Oued Noun": ["Guelmim"],
    "Marrakech-Tensift-Al Haouz": [
      "Amizmiz",
      "Essaouira",
      "Marrakesh",
      "Moulay Brahim"
    ],
    "Meknes-Tafilalet": ["Errachidia", "Jorf", "Meknes"],
    "Oued ed Dahab-Lagouira": ["Dakhla"],
    "Rabat-Sale-Zemmour-Zaer": ["Rabat", "Sale", "Skhirat", "Temara"],
    "Tadla-Azilal": ["Anergui", "Beni Mellal", "Fkih Ben Salah"],
    "Taza-Al Hoceima-Taounate": ["Guercif", "Taounate", "Taza"],
    "Tanger-Tetouan-Al Hoceima": [
      "Al Hoceima",
      "Asilah",
      "Chefchaouene",
      "Imzouren",
      "Larache",
      "Martil",
      "Tétouan",
      "Tangier"
    ],
    "Souss-Massa": [
      "Amerzgane",
      "Imider",
      "Mhamid",
      "Ouarzazat",
      "Sidi Ifni",
      "Taghazout",
      "Tamri",
      "Taroudant",
      "Tiznit",
      "Agadir"
    ],
    "Rabat-Sale-Kenitra": [
      "Kenitra",
      "Khemisset",
      "Salé",
      "Sidi Slimane",
      "Temara",
      "Rabat"
    ],
    Oriental: [
      "Ahfir",
      "Berkane",
      "Jerada",
      "Midar",
      "Taourirt",
      "Zaio",
      "Oujda",
      "Nador"
    ],
    "Marrakesh-Safi": ["Marrakesh", "Safi", "Essaouira"],
    "Fes-Meknes": [
      "Guercif",
      "Sefrou",
      "Tahla",
      "Taounate",
      "Taza",
      "Meknes",
      "Fes"
    ],
    "Casablanca-Settat": [
      "Berrechid",
      "Bouznika",
      "El Jadida",
      "Mohammedia",
      "Settat",
      "Sidi Bennour",
      "Casablanca"
    ]
  },
  Fiji: {
    Northern: ["Labasa", "Lambasa"],
    Western: ["Lautoka", "Nadi"],
    Central: ["Suva"]
  },
  "Faroe Islands": { Streymoy: ["Argir", "Kollafjordhur"], "": ["Tórshavn"] },
  Ethiopia: {
    Amhara: ["Bahir Dar", "Dessie", "Gondar", "Kombolcha"],
    "Harari Region": ["Harar"],
    Oromiya: ["Bishoftu", "Ginir", "Ziway"],
    "Southern Nations, Nationalities, and People's Region": [
      "Hawassa",
      "Jinka",
      "Tippi"
    ],
    Tigray: ["Inda Silase"],
    Somali: ["Jijiga"],
    "Addis Ababa": ["Addis Ababa"]
  },
  Estonia: {
    Harju: ["Kehra", "Laagri", "Tallinn"],
    Järvamaa: ["Koeru"],
    Lääne: ["Haapsalu"],
    "Lääne-Virumaa": ["Rakvere"],
    Laeaene: ["Haapsalu", "Hullo"],
    Paernumaa: ["Parnu"],
    Pärnumaa: ["Pärnu"],
    Saare: ["Kuressaare"],
    Valgamaa: ["Valga"],
    Viljandimaa: ["Viljandi", "Vohma"],
    Võrumaa: ["Võru"],
    Tartu: ["Tartu"],
    "Ida-Virumaa": ["Jõhvi", "Kohtla-Järve", "Sillamäe", "Toila", "Narva"],
    Harjumaa: ["Maardu", "Tallinn"]
  },
  Eritrea: {
    "Northern Red Sea": ["Massawa"],
    "Southern Red Sea Region": ["Edd"],
    "Maekel Region": ["Asmara"],
    "Anseba Region": ["Keren"]
  },
  "Equatorial Guinea": {
    Annobon: ["Pale"],
    "Bioko Norte": ["Malabo", "Santiago de Baney"],
    Litoral: ["Bata"]
  },
  Montserrat: {},
  "El Salvador": {
    Chalatenango: ["Chalatenango"],
    "Departamento de Ahuachapan": ["Ahuachapan"],
    "Departamento de Chalatenango": ["Chalatenango"],
    "Departamento de Cuscatlan": ["Cojutepeque"],
    "Departamento de La Libertad": [
      "Antiguo Cuscatlan",
      "Quezaltepeque",
      "Santa Tecla",
      "Zaragoza"
    ],
    "Departamento de Sonsonate": ["Izalco", "Sonsonate"],
    "Departamento de Usulutan": ["Usulutan"],
    "La Libertad": [
      "Antiguo Cuscatlan",
      "La Libertad",
      "Quezaltepeque",
      "Santa Tecla"
    ],
    "La Paz": ["San Pedro Masahuat", "Zacatecoluca"],
    "La Union": ["Intipuca"],
    "San Miguel": ["San Miguel", "San Rafael Oriente"],
    "San Salvador": [
      "Aguilares",
      "Ilopango",
      "Mejicanos",
      "San Marcos",
      "San Salvador",
      "Soyapango"
    ],
    "Santa Ana": ["Chalchuapa", "Santa Ana"],
    "San Vicente": ["San Sebastian", "San Vicente"],
    Sonsonate: ["Acajutla", "Izalco", "Sonsonate", "Sonzacate"],
    "Departamento de San Vicente": ["San Vicente"],
    "Departamento de Santa Ana": ["Santa Ana"],
    "Departamento de San Salvador": [
      "Apopa",
      "Delgado",
      "Ilopango",
      "Mejicanos",
      "Soyapango",
      "San Salvador"
    ],
    "Departamento de San Miguel": ["San Miguel"]
  },
  Egypt: {
    "Al Buhayrah": ["Beheira", "Damanhur"],
    "Al Isma'iliyah": ["Ismailia"],
    "Al Jizah": [
      "Al Bawiti",
      "Al Hawamidiyah",
      "Al Jizah",
      "Awsim",
      "Madinat Sittah Uktubar"
    ],
    "Al Minya": ["Bani Mazar"],
    "As Suways": ["Ain Sukhna", "Suez"],
    Beheira: ["Damanhur"],
    Dakahlia: ["Aga"],
    "Damietta Governorate": ["Damietta"],
    Dumyat: ["Damietta", "Faraskur"],
    "Eastern Province": ["Bilbays", "Zagazig"],
    Giza: ["Giza"],
    "Kafr ash Shaykh": ["Al Hamul", "Fuwah", "Kafr ash Shaykh"],
    Luxor: ["Luxor"],
    Minya: ["Matay", "Minya"],
    "Muhafazat ad Daqahliyah": ["Al Mansurah", "Shirbin", "Talkha"],
    "Muhafazat al Fayyum": ["Al Fayyum", "Ibshaway"],
    "Muhafazat al Gharbiyah": ["Tanda"],
    "Muhafazat al Minufiyah": ["Ash Shuhada'"],
    "Muhafazat al Qahirah": ["Cairo", "Halwan"],
    "Muhafazat al Qalyubiyah": ["Al Khankah", "Banha"],
    "Muhafazat al Wadi al Jadid": ["Qasr al Farafirah"],
    "Muhafazat Bur Sa`id": ["Port Said"],
    "Muhafazat Matruh": ["Al `Alamayn", "Mersa Matruh"],
    "Muhafazat Shamal Sina'": ["Arish"],
    "Port Said": ["Port Said"],
    "South Sinai": ["Dhahab", "Nuwaybi`a", "Sharm el-Sheikh"],
    Suez: ["Suez"],
    Sohag: ["Sohag", "Tahta"],
    Sharqia: ["Zagazig"],
    "Red Sea": [
      "Bur Safajah",
      "El Gouna",
      "Makadi Bay",
      "Marsa Alam",
      "Hurghada"
    ],
    Qena: ["Esna", "Kousa", "Qina"],
    Qalyubia: ["Toukh", "Banha"],
    Monufia: ["Tala"],
    "Ismailia Governorate": ["Ismailia"],
    Gharbia: ["Tanta"],
    "Cairo Governorate": ["New Cairo", "Helwan", "Cairo"],
    Asyut: ["Assiut"],
    Aswan: ["Abu Sunbul", "Aswan"],
    Alexandria: ["Alexandria"]
  },
  Montenegro: {
    Berane: ["Berane"],
    "Bijelo Polje": ["Bijelo Polje"],
    Budva: ["Budva"],
    Danilovgrad: ["Danilovgrad"],
    "Herceg Novi": ["Herceg Novi", "Herceg-Novi"],
    Kotor: ["Kotor", "Risan"],
    "Opstina Niksic": ["Niksic"],
    "Opstina Zabljak": ["Zabljak"],
    Pljevlja: ["Pljevlja"],
    Tivat: ["Tivat"],
    Ulcinj: ["Ulcinj"],
    Podgorica: ["Podgorica"],
    Cetinje: ["Cetinje"],
    Bar: ["Bar"]
  },
  Mongolia: {
    "Central Aimak": ["Hujirt"],
    Hovd: ["Khovd"],
    "Hovsgol Aymag": ["Murun-kuren"],
    Övörhangay: ["Arvayheer", "Ongon"],
    "Ulaanbaatar Hot": ["Ulaanbaatar", "Ulan Bator"],
    Uvs: ["Naranbulag"]
  },
  Ecuador: {
    Azuay: ["Cuenca", "Gualaceo"],
    Bolivar: ["Guaranda"],
    Carchi: [],
    Chimborazo: ["Riobamba"],
    Cotopaxi: ["Latacunga"],
    "El Oro": ["Huaquillas", "Machala", "Pasaje", "Santa Rosa"],
    Esmeraldas: ["Esmeraldas", "Muisne", "Rosa Zarate"],
    Galapagos: ["Puerto Ayora", "Puerto Baquerizo Moreno", "Puerto Villamil"],
    Imbabura: ["Ibarra", "Otavalo"],
    Loja: ["Loja"],
    "Los Rios": ["Quevedo"],
    Manabi: ["Jipijapa", "Manta", "Portoviejo", "Rocafuerte"],
    "Morona-Santiago": ["Macas", "Taisha"],
    Napo: ["Tena"],
    Pastaza: ["Puyo"],
    Pichincha: ["Machachi", "Quito", "Sangolqui"],
    "Provincia de Bolivar": ["Guaranda"],
    "Provincia de Cotopaxi": ["Latacunga"],
    "Provincia del Canar": ["Azogues", "La Troncal"],
    "Provincia del Carchi": ["Tulcán"],
    "Provincia del Chimborazo": ["Riobamba"],
    "Provincia de Los Rios": ["Babahoyo", "Quevedo", "Ventanas"],
    "Provincia del Pastaza": ["Puyo"],
    "Provincia de Morona-Santiago": ["Macas"],
    "Provincia de Napo": ["Tena"],
    "Provincia de Santa Elena": ["Salinas"],
    "Provincia de Zamora-Chinchipe": ["Zamora"],
    "Santa Elena": ["Salinas", "Santa Elena"],
    "Santo Domingo de los Tsachilas": ["Santo Domingo de los Colorados"],
    Tungurahua: ["Ambato", "Pelileo"],
    "Provincia de Pichincha": ["Machachi", "Quito"],
    "Provincia de Manabi": [
      "Chone",
      "Jipijapa",
      "Tosagua",
      "Portoviejo",
      "Manta"
    ],
    "Provincia del Tungurahua": ["Ambato"],
    "Provincia de Loja": ["Loja"],
    "Provincia del Guayas": [
      "Naranjal",
      "Samborondon",
      "Milagro",
      "Guayaquil",
      "Durán"
    ],
    "Provincia del Azuay": ["Gualaceo", "Cuenca"],
    "Provincia de Imbabura": ["Atuntaqui", "Otavalo", "Ibarra"],
    "Provincia de Esmeraldas": ["Esmeraldas"],
    "Provincia de El Oro": [
      "Huaquillas",
      "Pinas",
      "Santa Rosa",
      "Zaruma",
      "Pasaje",
      "Machala"
    ],
    Guayas: [
      "El Triunfo",
      "Guayaquil",
      "La Libertad",
      "Lomas de Sargentillo",
      "Naranjal",
      "Playas",
      "Samborondon",
      "Santa Lucia",
      "Velasco Ibarra",
      "Yaguachi Nuevo",
      "Eloy Alfaro"
    ]
  },
  "Dominican Republic": {
    Azua: ["Azua"],
    Baoruco: ["Los Rios", "Neiba"],
    Barahona: ["Santa Cruz de Barahona"],
    Duarte: ["San Francisco de Macoris", "Villa Riva"],
    "Elias Pina": ["Banica"],
    "El Seibo": ["Miches", "Santa Cruz de El Seibo"],
    Espaillat: ["Gaspar Hernandez", "Moca"],
    "Hato Mayor": ["Hato Mayor del Rey"],
    "Hermanas Mirabal": ["Salcedo"],
    "La Altagracia": [
      "Boca de Yuma",
      "Otra Banda",
      "Salvaleon de Higuey",
      "San Rafael del Yuma"
    ],
    "La Romana": ["La Romana"],
    "La Vega": [
      "Concepcion de La Vega",
      "Constanza",
      "Jarabacoa",
      "Tireo Arriba"
    ],
    "Maria Trinidad Sanchez": ["Cabrera", "Rio San Juan"],
    "Monsenor Nouel": ["Bonao", "Juan Adrian", "Sabana del Puerto"],
    "Monte Cristi": ["San Fernando de Monte Cristi"],
    "Monte Plata": ["Bayaguana", "Los Botados", "Monte Plata"],
    Peravia: [],
    "Provincia de Azua": ["Azua"],
    "Provincia de Elias Pina": ["Comendador"],
    "Provincia de Independencia": ["Duverge"],
    "Provincia de Pedernales": ["Pedernales"],
    "Provincia de San Juan": ["San Juan"],
    "Provincia de Santiago": ["Tamboril"],
    "Provincia de Santiago Rodriguez": ["Sabaneta"],
    "Provincia de Santo Domingo": ["Boca Chica"],
    "Provincia Duarte": ["San Francisco de Macorís"],
    "Sanchez Ramirez": ["Cotui"],
    "San Cristobal": [
      "Bajos de Haina",
      "Cambita Garabitos",
      "El Carril",
      "San Cristobal"
    ],
    "San Juan": ["Punta Cana", "San Juan de la Maguana"],
    "San Pedro de Macoris": ["Ramon Santana", "San Pedro de Macoris"],
    Santiago: [
      "Sabana Iglesia",
      "Santiago de los Caballeros",
      "Tamboril",
      "Villa Bisono"
    ],
    "Santiago Rodriguez": ["Moncion", "Sabaneta"],
    "Santo Domingo": [
      "Boca Chica",
      "Guerra",
      "Santo Domingo Este",
      "Santo Domingo Oeste"
    ],
    Valverde: ["Esperanza", "Mao"],
    Samaná: ["Las Terrenas", "Samana", "Samaná"],
    "Puerto Plata": ["Cabarete", "Estero Hondo", "Puerto Plata"],
    "Provincia Sanchez Ramirez": ["Fantino", "Cotui"],
    "Provincia Espaillat": ["Moca"],
    "Provincia de Valverde": ["Mao"],
    "Provincia de San Pedro de Macoris": ["Quisqueya"],
    "Provincia de San Cristobal": ["Bajos de Haina", "San Cristobal"],
    "Provincia de Peravia": ["Baní"],
    "Provincia de Monsenor Nouel": ["Bonao"],
    "Provincia de La Vega": ["Jarabacoa", "Constanza"],
    "Provincia de La Romana": ["La Romana"],
    "Provincia de La Altagracia": ["Punta Cana"],
    "Provincia de Hermanas Mirabal": ["Salcedo"],
    "Provincia de Barahona": ["Cabral"],
    Nacional: [
      "Bella Vista",
      "Ensanche Luperon",
      "La Agustina",
      "La Julia",
      "San Carlos",
      "Villa Consuelo",
      "Villa Francisca",
      "Santo Domingo"
    ]
  },
  Monaco: {
    "Commune de Monaco": [
      "Fontvieille",
      "La Condamine",
      "Monaco",
      "Monte-Carlo",
      "Saint-Roman"
    ],
    "": ["Monte Carlo", "Monaco"]
  },
  Moldova: {
    Cahul: ["Cahul"],
    Chisinau: ["Chisinau", "Stauceni"],
    Donduşeni: ["Briceni"],
    Drochia: ["Drochia"],
    Floreşti: ["Floresti"],
    Gagauzia: ["Comrat"],
    Glodeni: ["Glodeni"],
    Ialoveni: ["Ialoveni"],
    "Municipiul Balti": ["Bălţi"],
    Nisporeni: ["Nisporeni"],
    Orhei: ["Orhei"],
    "Raionul Calarasi": ["Calarasi"],
    "Raionul Causeni": ["Căușeni"],
    Rîşcani: [],
    Sîngerei: ["Bilicenii Vechi"],
    Strășeni: [],
    Ungheni: ["Ungheni"],
    "Unitatea Teritoriala din Stinga Nistrului": ["Rîbniţa", "Tiraspol"],
    "Raionul Soroca": ["Soroca"],
    Hînceşti: ["Hincesti"],
    "Chișinău Municipality": ["Chisinau"]
  },
  Dominica: { "Saint George": ["Roseau"] },
  Djibouti: { Djibouti: ["Djibouti"] },
  Denmark: {
    Zealand: [
      "Frederiksberg",
      "Greve",
      "Haslev",
      "Hojby",
      "Holbaek",
      "Horve",
      "Kalundborg",
      "Korsør",
      "Maribo",
      "Naestved",
      "Nakskov",
      "Nykobing Sjaelland",
      "Præstø",
      "Ringsted",
      "Solrod Strand",
      "Stege",
      "Svogerslev",
      "Vordingborg",
      "Slagelse",
      "Roskilde",
      "Køge"
    ],
    "South Denmark": [
      "Aabenraa",
      "Ærøskøbing",
      "Billund",
      "Brejning",
      "Esbjerg",
      "Faaborg",
      "Gram",
      "Grindsted",
      "Kerteminde",
      "Kolding",
      "Nordby",
      "Nyborg",
      "Oksbol",
      "Ribe",
      "Ringe",
      "Rudkøbing",
      "Sonder Bjert",
      "Sønderborg",
      "Svendborg",
      "Varde",
      "Vejle",
      "Odense",
      "Middelfart",
      "Haderslev",
      "Fredericia"
    ],
    "North Denmark": [
      "Brønderslev",
      "Frederikshavn",
      "Hadsund",
      "Hanstholm",
      "Hirtshals",
      "Hjørring",
      "Hobro",
      "Kas",
      "Klarup",
      "Nibe",
      "Pandrup",
      "Sindal",
      "Skagen",
      "Thisted",
      "Aalborg"
    ],
    "Central Jutland": [
      "Allingabro",
      "Arhus",
      "Beder",
      "Bjerringbro",
      "Braedstrup",
      "Ebeltoft",
      "Grenå",
      "Hedensted",
      "Herning",
      "Hinnerup",
      "Holstebro",
      "Ikast",
      "Lemvig",
      "Lystrup",
      "Odder",
      "Silkeborg",
      "Skive",
      "Stilling",
      "Vildbjerg",
      "Virklund",
      "Viborg",
      "Skanderborg",
      "Randers",
      "Horsens",
      "Aarhus"
    ],
    "Capital Region": [
      "Akirkeby",
      "Albertslund",
      "Ballerup",
      "Brondbyvester",
      "Buddinge",
      "Charlottenlund",
      "Elsinore",
      "Espergaerde",
      "Farum",
      "Flong",
      "Frederiksberg",
      "Frederikssund",
      "Frederiksværk",
      "Gilleleje",
      "Glostrup",
      "Helsinge",
      "Helsingor",
      "Herlev",
      "Holte",
      "Hornbaek",
      "Humlebaek",
      "Hvidovre",
      "Ishøj",
      "Jægerspris",
      "Kongens Lyngby",
      "Nexo",
      "Rodovre",
      "Rønne",
      "Stenlose",
      "Tårnby",
      "Trorod",
      "Vallensbæk",
      "Vanlose",
      "Taastrup",
      "Hørsholm",
      "Hillerød",
      "Dragør",
      "Copenhagen",
      "Christianshavn",
      "Birkerød"
    ]
  },
  "Czech Republic": {
    "Central Bohemia": [
      "Benešov",
      "Beroun",
      "Bustehrad",
      "Hostivice",
      "Hradist'ko",
      "Kladno",
      "Knezmost",
      "Kolín",
      "Kosmonosy",
      "Kralupy nad Vltavou",
      "Kutná Hora",
      "Libcice nad Vltavou",
      "Loucen",
      "Mladá Boleslav",
      "Příbram",
      "Pruhonice",
      "Stara Hut'",
      "Vestec",
      "Zajecov"
    ],
    "Hlavni mesto Praha": ["Prague"],
    "Jihocesky kraj": [
      "Blatna",
      "České Budějovice",
      "Český Krumlov",
      "Loucovice",
      "Písek",
      "Tábor",
      "Volyne"
    ],
    "Karlovarsky kraj": [
      "Cheb",
      "Karlovy Vary",
      "Loket",
      "Mariánské Lázně",
      "Sokolov"
    ],
    "Kraj Vysocina": ["Humpolec", "Jihlava"],
    "Kralovehradecky kraj": [
      "Hradec Králové",
      "Jičín",
      "Rychnov nad Kneznou",
      "Špindlerův Mlýn",
      "Trutnov",
      "Vrchlabi"
    ],
    "Liberecky kraj": ["Česká Lípa", "Harrachov", "Liberec", "Turnov"],
    "Moravskoslezsky kraj": [
      "Celadna",
      "Havířov",
      "Opava",
      "Orlova",
      "Ostrava"
    ],
    "Olomoucky kraj": ["Bouzov", "Mohelnice", "Olomouc", "Přerov"],
    "Pardubicky kraj": ["Chrudim", "Dolni Roven", "Pardubice"],
    "Plzensky kraj": ["Klatovy", "Line", "Pilsen", "Rokycany", "Zihle"],
    Praha: [
      "Branik",
      "Hodkovicky",
      "Karlin",
      "Liben",
      "Mala Strana",
      "Prague",
      "Prosek",
      "Stare Mesto",
      "Vysehrad",
      "Vysocany"
    ],
    "South Moravian": [
      "Blansko",
      "Břeclav",
      "Brno",
      "Cerna Hora",
      "Hodonín",
      "Mikulov",
      "Mokra Hora",
      "Ricany",
      "Šlapanice",
      "Troubsko",
      "Znojmo"
    ],
    "Ustecky kraj": [
      "Děčín",
      "Krasna Lipa",
      "Kresice",
      "Most",
      "Obrnice",
      "Teplice",
      "Varnsdorf",
      "Velemin"
    ],
    Vysocina: [
      "Golcuv Jenikov",
      "Jihlava",
      "Ledec nad Sazavou",
      "Nove Mesto na Morave",
      "Trebic"
    ],
    Zlín: [
      "Kroměříž",
      "Otrokovice",
      "Uherské Hradiště",
      "Uhersky Brod",
      "Zlin",
      "Zlín"
    ]
  },
  Cyprus: {
    Keryneia: ["Kyrenia", "Lapithos"],
    Larnaka: ["Agios Tychon", "Aradippou", "Larnaca", "Pyla"],
    Lefkosia: ["Nicosia"],
    Pafos: ["Pegeia", "Polis", "Paphos"],
    Nicosia: ["Nicosia"],
    Limassol: [
      "Erimi",
      "Kyperounta",
      "Mouttagiaka",
      "Pano Polemidia",
      "Pissouri",
      "Pyrgos",
      "Sotira",
      "Limassol"
    ],
    Ammochostos: [
      "Ayia Napa",
      "Lefkonoiko",
      "Leonarisso",
      "Paralimni",
      "Protaras",
      "Rizokarpaso",
      "Trikomo",
      "Famagusta"
    ]
  },
  Curaçao: { "": ["Willemstad"] },
  Cuba: {
    Artemisa: ["San Cristobal"],
    "Ciego de Avila": ["Chambas", "Moron"],
    Cienfuegos: ["Cienfuegos"],
    Guantanamo: ["Baracoa"],
    Holguin: ["Gibara"],
    Matanzas: ["Matanzas", "Varadero"],
    "Pinar del Rio": ["Pinar del Rio", "Vinales"],
    "Provincia de Matanzas": ["Varadero"],
    "Sancti Spiritus": ["Guayos", "Trinidad", "Yaguajay"],
    "Santiago de Cuba": ["Santiago de Cuba"],
    "Villa Clara": [],
    "Provincia de Villa Clara": ["Santa Clara"],
    "La Habana": [
      "Alamar",
      "Centro Habana",
      "Cerro",
      "La Habana Vieja",
      "Havana"
    ]
  },
  Croatia: {
    "Bjelovarsko-Bilogorska Zupanija": ["Bjelovar"],
    "Dubrovacko-Neretvanska Zupanija": [
      "Cavtat",
      "Dubrovnik",
      "Korčula",
      "Metković",
      "Orebic"
    ],
    "Grad Zagreb": [
      "Jankomir",
      "Novi Zagreb",
      "Odra",
      "Sesvete",
      "Zadvorsko",
      "Zagreb",
      "Zagreb - Centar"
    ],
    Istarska: [
      "Fazana",
      "Karojba",
      "Lovran",
      "Medulin",
      "Novigrad",
      "Pazin",
      "Porec",
      "Pula",
      "Rabac",
      "Rovinj",
      "Umag",
      "Vrsar"
    ],
    "Karlovacka Zupanija": ["Karlovac", "Slunj"],
    "Licko-Senjska Zupanija": ["Jezerce", "Novalja"],
    Medimurska: ["Cakovec"],
    "Megimurska Zupanija": ["Čakovec"],
    "Pozesko-Slavonska Zupanija": ["Požega"],
    "Sibensko-Kninska Zupanija": [
      "Brodarica",
      "Knin",
      "Primosten",
      "Šibenik",
      "Tisno",
      "Tribunj",
      "Vodice",
      "Zaton"
    ],
    "Sisacko-Moslavacka Zupanija": ["Kutina", "Petrinja", "Sisak"],
    "Slavonski Brod-Posavina": ["Slavonski Brod"],
    "Varazdinska Zupanija": ["Beletinec", "Varaždin"],
    "Viroviticko-Podravska Zupanija": ["Virovitica"],
    "Vukovar-Sirmium": ["Vinkovci", "Vukovar"],
    "Vukovarsko-Srijemska": ["Vinkovci", "Vukovar"],
    Zadarska: [
      "Biograd na Moru",
      "Sukosan",
      "Turanj",
      "Ugljan",
      "Vrsi",
      "Zadar"
    ],
    "Zadarska Zupanija": ["Zadar"],
    Zagrebacka: ["Brckovljani", "Micevec", "Velika Gorica"],
    "Zagreb County": ["Samobor", "Velika Gorica", "Zapresic"],
    "Split-Dalmatia": [
      "Bol",
      "Donja Brela",
      "Hvar",
      "Imotski",
      "Jelsa",
      "Jesenice",
      "Kamen",
      "Komiza",
      "Makarska",
      "Sinj",
      "Stari Grad",
      "Supetar",
      "Trogir",
      "Vis",
      "Vranjic",
      "Split",
      "Solin"
    ],
    "Primorsko-Goranska Zupanija": [
      "Banjol",
      "Cres",
      "Crikvenica",
      "Drenova",
      "Kampor",
      "Krk",
      "Mali Lošinj",
      "Matulji",
      "Novi Vinodolski",
      "Selce",
      "Rijeka"
    ],
    "Osjecko-Baranjska Zupanija": ["Đakovo", "Osijek"],
    "Koprivnicko-Krizevacka Zupanija": ["Koprivnica"],
    Istria: ["Poreč", "Rovinj", "Pula"],
    "City of Zagreb": ["Sesvete", "Zagreb"]
  },
  "Micronesia, Federated States of": { Yap: ["Colonia"] },
  "Côte d'Ivoire": {
    "Bas-Sassandra": [],
    Comoe: ["Aboisso"],
    "Goh-Djiboua": ["Divo", "Lakota"],
    "Haut-Sassandra": ["Issia"],
    Lacs: ["Yamoussoukro"],
    Lagunes: ["Abidjan", "Abobo", "Bingerville"],
    Savanes: ["Korhogo"],
    "Sud-Comoe": ["Adiake", "Bonoua", "Grand-Bassam"],
    Zanzan: ["Sinfra"],
    Montagnes: ["Man"],
    Abidjan: ["Abidjan"]
  },
  "Costa Rica": {
    Alajuela: [
      "Alajuela",
      "Atenas",
      "Carrillos",
      "La Fortuna",
      "Orotina",
      "Quesada",
      "San Rafael"
    ],
    Cartago: ["Cartago", "Concepcion", "Cot", "San Diego", "Tres Rios"],
    Guanacaste: [
      "Bagaces",
      "Bejuco",
      "La Cruz",
      "Liberia",
      "Nicoya",
      "Samara",
      "Santa Cruz",
      "Sardinal"
    ],
    Heredia: [
      "Colon",
      "Heredia",
      "La Asuncion",
      "La Virgen",
      "San Antonio",
      "San Francisco"
    ],
    Limon: ["Cariari"],
    "Provincia de Puntarenas": ["Esparza", "Puntarenas"],
    Puntarenas: [
      "Chacarita",
      "Ciudad Cortes",
      "Esparza",
      "Golfito",
      "Jaco",
      "Paquera",
      "Parrita",
      "Puntarenas",
      "Quepos"
    ],
    "San Jose": [
      "Colima",
      "Curridabat",
      "Escazu",
      "Guadalupe",
      "Ipis",
      "Mercedes",
      "San Isidro",
      "San Jose",
      "San Marcos",
      "San Pedro",
      "San Rafael",
      "Santa Ana",
      "San Vicente"
    ],
    "Provincia de San Jose": [
      "Alajuelita",
      "Aserri",
      "Curridabat",
      "Escazu",
      "Sabanilla",
      "San Isidro",
      "San Juan",
      "San Pedro",
      "San José"
    ],
    "Provincia de Limon": ["Guapiles", "Limón"],
    "Provincia de Heredia": [
      "San Antonio",
      "San Francisco",
      "San Pablo",
      "Heredia"
    ],
    "Provincia de Guanacaste": ["Liberia", "Nicoya"],
    "Provincia de Cartago": [
      "Cartago",
      "Tres Rios",
      "Turrialba",
      "Paraiso",
      "Concepcion"
    ],
    "Provincia de Alajuela": [
      "Quesada",
      "San Ramon",
      "San Jose",
      "Naranjo",
      "Alajuela"
    ]
  },
  "Cook Islands": { Rarotonga: ["Avarua"] },
  "Congo, The Democratic Republic of the": {
    Bandundu: ["Bandundu", "Nioki"],
    "Bas-Congo": ["Kasangulu", "Matadi"],
    "Eastern Province": ["Basoko", "Bondo", "Isiro", "Watsa", "Yangambi"],
    Équateur: ["Bumba", "Businga", "Gbadolite", "Libenge"],
    Ituri: ["Bunia"],
    "Kasaï-Oriental": ["Kabinda", "Lodja", "Lubao", "Tshikapa"],
    Katanga: ["Kambove", "Likasi", "Lubumbashi"],
    "Kinshasa City": ["Kinshasa"],
    Kwilu: ["Kikwit"],
    "Mai Ndombe": ["Inongo"],
    "Province du Haut-Katanga": ["Lubumbashi"],
    "South Kivu Province": ["Bukavu"],
    Tshopo: ["Yangambi"],
    "Nord Kivu": ["Butembo", "Goma", "Beni"],
    Kasai: []
  },
  Mexico: {
    "Baja California": [
      "Benito Garcia (El Zorrillo)",
      "Buenos Aires",
      "Ciudad Morelos",
      "Ejido Doctor Alberto Oviedo Mota (El Indiviso)",
      "El Sauzal",
      "Ensenada",
      "Fraccionamiento del Valle",
      "Guadalupe Victoria",
      "La Esperanza [Granjas Familiares]",
      "Las Brisas",
      "Lomas de Santa Anita",
      "Los Algodones",
      "Luis Rodriguez (El Vergel)",
      "Mexicali",
      "Primo Tapia",
      "Rancho Verde",
      "Rosarito",
      "San Felipe",
      "San Luis",
      "Santa Anita",
      "Tecate",
      "Terrazas del Valle",
      "Tijuana"
    ],
    Mexico: [
      "Cerro La Calera",
      "Ciudad Lopez Mateos",
      "Colonia Lindavista",
      "Cuadrilla de Dolores",
      "Cuautitlan Izcalli",
      "Ejido del Tejocote",
      "Guadalupe Totoltepec",
      "Huilango",
      "Jicaltepec Cuexcontitlan",
      "Jiquilpan de Juarez",
      "Los Arana",
      "Malinalco",
      "Naucalpan de Juarez",
      "Puerto Escondido (Tepeolulco Puerto Escondido)",
      "San Pedro Arriba 3ra. Seccion",
      "Teotihuacan de Arista",
      "Tepotzotlan",
      "Texcoco de Mora",
      "Tlalnepantla",
      "Toluca",
      "Valle de Bravo",
      "Vista Hermosa"
    ],
    Tlaxcala: ["Apizaco", "Benito Juarez", "Chiautempan", "Huamantla"],
    Zacatecas: [
      "Fresnillo",
      "Guadalupe",
      "Jalpa",
      "Jerez de Garcia Salinas",
      "Loreto",
      "Rio Grande",
      "Sombrerete",
      "Valparaiso",
      "Villa de Cos",
      "Villanueva",
      "Zacatecas"
    ],
    Yucatán: [
      "Caucel",
      "Chichen-Itza",
      "Conkal",
      "Homun",
      "Izamal",
      "Kanasin",
      "Komchen",
      "Leona Vicario",
      "Motul",
      "Oxkutzkab",
      "Progreso",
      "Progreso de Castro",
      "Rio Lagartos",
      "Tecax",
      "Telchac Puerto",
      "Ticul",
      "Tizimín",
      "Valladolid",
      "Mérida"
    ],
    Veracruz: [
      "Acayucan",
      "Agua Dulce",
      "Allende",
      "Anton Lizardo",
      "Banderilla",
      "Boca del Rio",
      "Catemaco",
      "Cerro Azul",
      "Coatzintla",
      "Córdoba",
      "Cosamaloapan",
      "Cosoleacaque",
      "Cuitlahuac",
      "Heroica Alvarado",
      "Huatusco",
      "Ixtaczoquitlan",
      "Juan Rodriguez Clara",
      "La Isla",
      "Lerdo de Tejada",
      "Minatitlán",
      "Misantla",
      "Mixtequilla",
      "Naranjos",
      "Orizaba",
      "Palmira",
      "Papantla de Olarte",
      "Perote",
      "Playa de la Libertad",
      "Poza Rica de Hidalgo",
      "San Andres Tuxtla",
      "Santiago Tuxtla",
      "Tantoyuca",
      "Tihuatlan",
      "Tlapacoyan",
      "Totutla",
      "Villa Emilio Carranza",
      "Xalapa de Enriquez",
      "Xico",
      "Xalapa",
      "Veracruz",
      "Maltrata",
      "Coatzacoalcos",
      "Coatepec",
      "Alvarado",
      "Alamo"
    ],
    Tamaulipas: [
      "Aldama",
      "Altamira",
      "Ciudad Madero",
      "Ciudad Mante",
      "Ciudad Victoria",
      "Gustavo A. Madero",
      "Heroica Matamoros",
      "Las Higuerillas",
      "Nuevo Laredo",
      "Reynosa",
      "San Fernando",
      "Valle Hermoso",
      "Tampico"
    ],
    Tabasco: [
      "Balancan",
      "Balancan de Dominguez",
      "Bosque de Saloya",
      "Cardenas",
      "Frontera",
      "Huimanguillo",
      "Los Sauces",
      "Macuspana",
      "Paraiso",
      "Teapa",
      "Tenosique",
      "Villahermosa",
      "Comalcalco"
    ],
    Sonora: [
      "Agua Prieta",
      "Bahia de Kino",
      "Cananea",
      "Ciudad Obregón",
      "Empalme",
      "Estacion Zamora",
      "Guaymas",
      "Heroica Caborca",
      "Heroica Guaymas",
      "Huatabampo",
      "Magdalena de Kino",
      "Navojoa",
      "Nogales",
      "Puerto Peñasco",
      "San Luis Río Colorado",
      "Santa Ana",
      "Hermosillo"
    ],
    Sinaloa: [
      "Adolfo Ruiz Cortines",
      "Ahome",
      "Angostura",
      "El Dorado",
      "El Rosario",
      "Escuinapa",
      "Juan Jose Rios",
      "La Brecha",
      "La Cruz",
      "La Trinidad",
      "Leyva Solano",
      "Los Mochis",
      "Navolato",
      "Pueblos Unidos",
      "Villa de Costa Rica",
      "Mazatlán",
      "Guasave",
      "Guamuchil",
      "Culiacán"
    ],
    "San Luis Potosí": [
      "Cardenas",
      "Ciudad Valles",
      "Guadalupe Victoria",
      "Maitinez",
      "Matehuala",
      "Rioverde",
      "San Luis Potosi",
      "Tamazunchale"
    ],
    "Quintana Roo": [
      "Alfredo V. Bonfil",
      "Calderitas",
      "Chetumal",
      "Ciudad Chemuyil",
      "Coba",
      "Felipe Carrillo Puerto",
      "Isla Mujeres",
      "Kantunilkin",
      "Leona Vicario",
      "Playa del Carmen",
      "San Miguel de Cozumel",
      "Tulum",
      "Zona Urbana Ejido Isla Mujeres",
      "Cancún",
      "Bacalar"
    ],
    Querétaro: [
      "El Pueblito",
      "El Salitre",
      "Ezequiel Montes",
      "La Canada",
      "San Jose del Jagueey",
      "San Juan del Río",
      "San Miguel Amazcala",
      "Santa Rosa Jauregui",
      "Santiago de Queretaro",
      "Sergio Villasenor",
      "Tequisquiapan",
      "Venceremos"
    ],
    Puebla: [
      "18 de Marzo",
      "Ajalpan",
      "Atlixco",
      "Casa Blanca",
      "Chignahuapan",
      "Ciudad de Cuetzalan",
      "Grajales",
      "Huauchinango",
      "Huautla de Jimenez",
      "Huejotzingo",
      "Izucar de Matamoros",
      "Necaxa",
      "Puebla",
      "San Andres Cholula",
      "San Miguel Xoxtla",
      "Santiago Momoxpan",
      "Santo Nombre",
      "Tecamachalco",
      "Tepeaca",
      "Teziutlán",
      "Tlazcalancingo",
      "Zaragoza",
      "Tehuacán",
      "Puebla City",
      "Libres",
      "Cholula"
    ],
    Oaxaca: [
      "Brisas de Zicatela",
      "Ciudad de Huajuapam de Leon",
      "Colonia Progreso",
      "Crucecita",
      "Heroica Ciudad de Tlaxiaco",
      "Ixtepec",
      "Juchitán de Zaragoza",
      "Loma Bonita",
      "Oaxaca City",
      "Oaxaca de Juarez",
      "Pinotepa Nacional",
      "San Antonio de la Cal",
      "San Jeronimo Yahuiche",
      "San Pedro Mixtepec",
      "Santa Cruz Xoxocotlán",
      "Santa Maria Huatulco",
      "Santa Maria Tonameca",
      "Santiago Pinotepa Nacional",
      "Santo Domingo Tehuantepec",
      "Tuxtepec",
      "Salina Cruz",
      "Puerto Escondido"
    ],
    "Nuevo León": [
      "Allende",
      "Anahuac",
      "Apodaca",
      "Bosques de San Pedro",
      "Cadereyta",
      "Entronque Laredo-Salinas Victoria",
      "Estados Unidos",
      "Garza Garcia",
      "General Escobedo",
      "Hidalgo",
      "Jardines de la Silla (Jardines)",
      "Lampazos de Naranjo",
      "Linares",
      "Mitras Poniente",
      "Parque Industrial Ciudad Mitras",
      "San Nicolás de los Garza",
      "San Pedro Garza Garcia",
      "Santa Catarina",
      "Santiago",
      "Valle de Lincoln",
      "Monterrey",
      "Montemorelos",
      "Guadalupe",
      "Garcia"
    ],
    Nayarit: [
      "Acaponeta",
      "Bucerias",
      "Corral del Risco (Punta de Mita)",
      "Las Jarretaderas",
      "Lo de Marcos",
      "Puga",
      "San Clemente de Lima",
      "Santa Maria del Oro",
      "Santiago Ixcuintla",
      "Tecuala",
      "Tepic",
      "Zacualpan",
      "Ruiz",
      "Compostela"
    ],
    Morelos: [
      "Alpuyeca",
      "Colonia los Cerritos",
      "Coyotepec",
      "Cuautla",
      "Cuautla Morelos",
      "Jiutepec",
      "Jojutla",
      "Metepec",
      "Ocoyoacac",
      "Puente de Ixtla",
      "San Mateo Atenco",
      "Tehuixtla",
      "Temixco",
      "Tenango de Arista",
      "Teotihuacan de Arista",
      "Tepoztlan",
      "Tlaltizapan",
      "Tlaquiltenango",
      "Tres de Mayo",
      "Unidad Habitacional Jose Maria Morelos y Pavon",
      "Xochitepec",
      "Xoxocotla",
      "Yautepec",
      "Yecapixtla",
      "Emiliano Zapata",
      "Cuernavaca"
    ],
    Michoacán: [
      "Angahuan",
      "Apatzingán",
      "Buena Vista Tomatlan",
      "Cheran",
      "Ciudad Hidalgo",
      "Coahuayana Viejo",
      "Cuitzeo del Porvenir",
      "Fraccionamiento Metropolis II",
      "La Piedad",
      "La Piedad Cavadas",
      "Nueva Italia de Ruiz",
      "Paracho de Verduzco",
      "Patzcuaro",
      "Periban de Ramos",
      "Pureparo de Echaiz",
      "Puruandiro",
      "Sahuayo de Morelos",
      "Tepalcatepec",
      "Tomendan",
      "Yurecuaro",
      "Zamora",
      "Zacapu",
      "Uruapan",
      "Morelia",
      "Lombardia",
      "Lázaro Cárdenas"
    ],
    "Mexico City": [
      "Alvaro Obregon",
      "Col. Bosques de las Lomas",
      "Colonia del Valle",
      "Colonia Nativitas",
      "Cuajimalpa",
      "Cuauhtemoc",
      "Gustavo A. Madero",
      "Iztacalco",
      "Magdalena Contreras",
      "Miguel Hidalgo",
      "Milpa Alta",
      "San Lorenzo Acopilco",
      "Tlahuac",
      "Tlalpan",
      "Venustiano Carranza",
      "Xochimilco",
      "Polanco",
      "Mexico City",
      "Iztapalapa",
      "Coyoacán",
      "Benito Juarez",
      "Azcapotzalco"
    ],
    Jalisco: [
      "Acatic",
      "Ajijic",
      "Ameca",
      "Arandas",
      "Arenal",
      "Chapala",
      "Cihuatlan",
      "Cocula",
      "Colotlan",
      "El Grullo",
      "El Salto",
      "Emiliano Zapata (El Ranchito)",
      "Hacienda Santa Fe",
      "Huejucar",
      "Ixtapa",
      "Jalostotitlan",
      "Jaluco",
      "Jamay",
      "Jocotepec",
      "La Barca",
      "Lagos de Moreno",
      "Las Juntas",
      "Las Pintas de Arriba",
      "Las Pintitas",
      "La Tijera",
      "La Venta del Astillero",
      "Lomas del Sur",
      "Los Ruisenores",
      "Mascota",
      "Ocotlan",
      "Palomar",
      "Paseos del Valle [Fraccionamiento]",
      "Poncitlan",
      "San Juan de los Lagos",
      "San Julian",
      "San Miguel el Alto",
      "San Sebastian del Sur",
      "Santa Anita",
      "Sayula",
      "Tala",
      "Tamazula de Gordiano",
      "Tapalpa",
      "Tecalitlan",
      "Teocaltiche",
      "Tepatitlán de Morelos",
      "Tlajomulco de Zuniga",
      "Tlaquepaque",
      "Tonala",
      "Tototlan",
      "Tuxpan",
      "Villa Hidalgo",
      "Zacoalco de Torres",
      "Zapopan",
      "Zapopan2",
      "Zapotlanejo",
      "Tequila",
      "Puerto Vallarta",
      "Guadalajara",
      "Ciudad Guzmán"
    ],
    Hidalgo: [
      "Actopan",
      "Apan",
      "El Huixmi",
      "Ixmiquilpan",
      "La Providencia Siglo XXI",
      "Pachuca de Soto",
      "Pozuelos",
      "Rinconadas de San Francisco",
      "San Antonio el Paso",
      "Tecozautla",
      "Tula de Allende",
      "Tulancingo",
      "Pachuca"
    ],
    Guerrero: [
      "10 de Abril",
      "Acapulco de Juárez",
      "Arcelia",
      "Chilpancingo",
      "Chilpancingo de los Bravos",
      "Ciudad de Huitzuco",
      "Iguala de la Independencia",
      "Ixtapa",
      "Ixtapa-Zihuatanejo",
      "La Union",
      "Malinaltepec",
      "Pantla",
      "Petatlan",
      "San Luis de la Paz",
      "Taxco de Alarcon",
      "Teloloapan",
      "Tierra Colorada",
      "Tuncingo",
      "Taxco"
    ],
    Guanajuato: [
      "Abasolo",
      "Acambaro",
      "Apaseo el Grande",
      "Celaya",
      "Comonfort",
      "Cortazar",
      "Dolores Hidalgo Cuna de la Independencia Nacional",
      "Guanajuato",
      "Guanajuato City",
      "Juventino Rosas",
      "La Ermita",
      "Marfil",
      "Medina",
      "Penjamo",
      "Romita",
      "Salamanca",
      "Salvatierra",
      "San Felipe",
      "San Francisco del Rincón",
      "Silao",
      "Tarimoro",
      "Uriangato",
      "Valle de Santiago",
      "Yuriria",
      "Villagran",
      "San Miguel de Allende",
      "León",
      "Irapuato"
    ],
    "Estado de Mexico": [
      "Calimaya",
      "Chalco",
      "Ciudad Nezahualcoyotl",
      "Coacalco",
      "Cuautitlan",
      "Cuautitlán Izcalli",
      "Huixquilucan",
      "Ixtapaluca",
      "Melchor Ocampo",
      "Naucalpan",
      "Ocoyoacac",
      "San Mateo Atenco",
      "Tecamac",
      "Teotihuacan",
      "Tepotzotlan",
      "Tlalmanalco",
      "Tlalnepantla",
      "Toluca",
      "Tultepec",
      "Valle de Bravo",
      "Zumpango",
      "Xico",
      "Texcoco",
      "Metepec"
    ],
    "Estado de Baja California": [
      "Guadalupe Victoria",
      "Puebla",
      "Tecate",
      "Tijuana",
      "Rosarito",
      "Mexicali",
      "Ensenada"
    ],
    Durango: [
      "El Huarache (El Guarache)",
      "Gomez Palacio",
      "Lerdo",
      "Pueblo Nuevo",
      "San Jose de Vinedo",
      "Santiago Papasquiaro",
      "Victoria de Durango",
      "Durango"
    ],
    Colima: ["Armeria", "Manzanillo", "Tecoman", "Colima"],
    Coahuila: [
      "Arteaga",
      "Ciudad Acuña",
      "Ciudad Frontera",
      "Lamadrid",
      "Matamoros",
      "Nueva Rosita",
      "Parras de la Fuente",
      "Piedras Negras",
      "Ramos Arizpe",
      "San Pedro",
      "Zaragoza",
      "Torreón",
      "Saltillo",
      "Sabinas",
      "Nava",
      "Monclova",
      "Frontera",
      "Allende"
    ],
    Chihuahua: [
      "Chihuahua",
      "Chihuahua City",
      "Ciudad Camargo",
      "Ciudad Delicias",
      "Creel",
      "Cuauhtemoc",
      "Hidalgo del Parral",
      "Madera",
      "Manuel Ojinaga",
      "Nuevo Casas Grandes",
      "Ojinaga",
      "Parral",
      "Pedro Meoqui",
      "Puerto Palomas",
      "San Pedro Madera",
      "Saucillo",
      "Valentin Gomez Farias",
      "Jimenez",
      "Ciudad Juárez",
      "Camargo"
    ],
    Chiapas: [
      "Acala",
      "Arriaga",
      "Berriozabal",
      "Chiapa de Corzo",
      "Cintalapa de Figueroa",
      "Comalapa",
      "Comitán",
      "El Progreso",
      "Emiliano Zapata",
      "Huixtla",
      "Mapastepec",
      "Mitziton",
      "Motozintla",
      "Ocosingo",
      "Ocozocoautla de Espinosa",
      "Osumacinta",
      "Palenque",
      "Pichucalco",
      "San Cristóbal de las Casas",
      "Tapachula",
      "Tonala",
      "Yajalon",
      "Tuxtla Gutiérrez"
    ],
    Campeche: [
      "Calkini",
      "Ciudad del Carmen",
      "Colonia Emiliano Zapata",
      "Escarcega",
      "Campeche"
    ],
    "Baja California Sur": [
      "Ciudad Constitución",
      "La Paz",
      "La Playa",
      "Las Palmas",
      "San José del Cabo",
      "Todos Santos",
      "Villa Morelos",
      "Cabo San Lucas"
    ],
    Aguascalientes: [
      "Corral de Barrancos",
      "Jesus Maria",
      "Calvillo",
      "Aguascalientes"
    ]
  },
  Congo: {
    Brazzaville: ["Brazzaville"],
    Niari: ["Dolisie"],
    "Pointe-Noire": ["Loandjili", "Pointe-Noire"],
    Sangha: []
  },
  Comoros: { "Grande Comore": ["Moroni"] },
  Colombia: {
    Arauca: ["Arauca", "Municipio de Fortul", "Tame"],
    "Archipielago de San Andres, Providencia y Santa Catalina": [
      "Mountain",
      "San Andres"
    ],
    Bolivar: [
      "Cantagallo",
      "Cartagena",
      "Cordoba",
      "Santa Rosa del Sur",
      "Turbana"
    ],
    Boyaca: ["Floresta", "Nobsa", "Tunja", "Villa de Leiva"],
    Caldas: [
      "Aguadas",
      "Belalcazar",
      "La Dorada",
      "Manizales",
      "Palestina",
      "Villamaria",
      "Viterbo"
    ],
    Casanare: ["Aguazul", "Villanueva", "Yopal"],
    Cauca: ["Morales", "Puerto Tejada", "Santander de Quilichao"],
    Cesar: [
      "Chiriguana",
      "El Copey",
      "La Paz",
      "Manaure Balcon del Cesar",
      "Valledupar"
    ],
    Choco: ["Mutis"],
    Cordoba: ["Chinu", "Monteria", "San Antero"],
    "Departamento de Arauca": ["Arauca", "Tame"],
    "Departamento del Choco": ["Istmina", "Quibdó", "Tado"],
    "Departamento del Putumayo": ["Mocoa", "Orito"],
    "Departamento de Tolima": ["Chaparral", "Espinal", "Ibague", "Melgar"],
    Guaviare: ["Calamar"],
    Huila: [
      "Garzon",
      "La Plata",
      "Neiva",
      "Pitalito",
      "San Agustin",
      "Villavieja"
    ],
    "La Guajira": [
      "Barrancas",
      "Dibulla",
      "Fonseca",
      "Maicao",
      "Riohacha",
      "San Juan del Cesar",
      "Urumita",
      "Villanueva"
    ],
    Magdalena: ["El Banco", "Santa Marta"],
    Meta: ["Cubarral", "Granada", "Guamal", "Puerto Lopez", "Villavicencio"],
    Narino: ["Pasto"],
    "Norte de Santander": [
      "Cucuta",
      "Los Patios",
      "Ocana",
      "Pamplona",
      "Villa del Rosario"
    ],
    Putumayo: ["Mocoa"],
    Quindio: ["Armenia", "Buenavista", "Montenegro", "Salento"],
    "Quindio Department": ["Armenia", "Calarcá", "Circasia"],
    Risaralda: ["Dos Quebradas", "Pereira", "Santa Rosa de Cabal"],
    Santander: [
      "Barbosa",
      "Barichara",
      "Barrancabermeja",
      "Bucaramanga",
      "Contratacion",
      "Floridablanca",
      "Piedecuesta",
      "San Gil",
      "Socorro"
    ],
    Sucre: ["San Onofre", "Sincelejo", "Tolu"],
    Tolima: ["Espinal", "Flandes", "Ibague", "Melgar", "Valle de San Juan"],
    "Valle del Cauca": [
      "Andalucia",
      "Buenaventura",
      "Buga",
      "Caicedonia",
      "Cali",
      "Candelaria",
      "Cartago",
      "El Cerrito",
      "Jamundi",
      "Palmira",
      "Restrepo",
      "Roldanillo",
      "Tulua",
      "Yumbo",
      "Zarzal"
    ],
    Vichada: ["Puerto Carreno"],
    "San Andres y Providencia": ["San Andrés"],
    "Departamento de Sucre": ["Tolú", "Sincelejo"],
    "Departamento de Santander": [
      "Floridablanca",
      "Piedecuesta",
      "Velez",
      "San Gil",
      "Giron",
      "Bucaramanga",
      "Barrancabermeja"
    ],
    "Departamento de Risaralda": ["Arabia", "Pereira"],
    "Departamento de Norte de Santander": [
      "Los Patios",
      "Ocaña",
      "Pamplona",
      "Cúcuta"
    ],
    "Departamento de Narino": ["Ipiales", "Pasto"],
    "Departamento del Valle del Cauca": [
      "Buenaventura",
      "Bugalagrande",
      "Candelaria",
      "Cartago",
      "El Cerrito",
      "Florida",
      "Guacari",
      "Jamundi",
      "Roldanillo",
      "Yumbo",
      "Zarzal",
      "Tuluá",
      "Toro",
      "Sevilla",
      "Palmira",
      "Caicedonia",
      "Buga",
      "Andalucia"
    ],
    "Departamento del Meta": ["Acacias", "Granada", "Villavicencio"],
    "Departamento del Magdalena": [
      "Aracataca",
      "Cienaga",
      "Santa Marta",
      "Fundacion"
    ],
    "Departamento del Huila": ["Acevedo", "Pitalito", "Neiva"],
    "Departamento del Cesar": ["Valledupar"],
    "Departamento del Cauca": [
      "Popayan",
      "Puerto Tejada",
      "Santander de Quilichao",
      "Morales"
    ],
    "Departamento del Caqueta": ["Florencia"],
    "Departamento de La Guajira": ["Riohacha", "Maicao", "Fonseca"],
    "Departamento de Cordoba": [
      "Cerete",
      "Planeta Rica",
      "Sahagun",
      "Montería"
    ],
    "Departamento de Casanare": ["Aguazul", "Yopal"],
    "Departamento de Caldas": ["La Dorada", "Villamaria", "Manizales"],
    "Departamento de Boyaca": [
      "Chiquinquira",
      "Paipa",
      "Puerto Boyacá",
      "Sogamoso",
      "Tunja",
      "Duitama"
    ],
    "Departamento de Bolivar": ["Turbaco", "Magangué", "Cartagena"],
    Cundinamarca: [
      "Anapoima",
      "Apulo",
      "Arbelaez",
      "Bojaca",
      "Cajica",
      "Colombia",
      "Cota",
      "Facatativá",
      "Funza",
      "Fusagasuga",
      "Girardot City",
      "Guasca",
      "Guatavita",
      "La Calera",
      "La Mesa",
      "Macheta",
      "Mosquera",
      "Pasca",
      "Sibate",
      "Soacha",
      "Tocancipa",
      "Ubate",
      "Villeta",
      "Zipaquirá",
      "Pacho",
      "Madrid",
      "Chia"
    ],
    "Bogota D.C.": ["Barrio San Luis", "Bogotá"],
    Atlántico: [
      "Baranoa",
      "Juan de Acosta",
      "Malambo",
      "Palmar de Varela",
      "Puerto Colombia",
      "Sabanalarga",
      "Santo Tomas",
      "Soledad",
      "Barranquilla"
    ],
    Antioquia: [
      "Amalfi",
      "Carepa",
      "Carmen de Viboral",
      "Caucasia",
      "El Bagre",
      "El Retiro",
      "Guarne",
      "Guatape",
      "Jardin",
      "Jerico",
      "La Ceja",
      "La Estrella",
      "Los Andes",
      "Marinilla",
      "Municipio de Copacabana",
      "Necocli",
      "Rionegro",
      "San Carlos",
      "Santa Barbara",
      "Santo Domingo",
      "Santuario",
      "Turbo",
      "Urrao",
      "Yarumal",
      "Zaragoza",
      "Sabaneta",
      "Medellín",
      "Itagui",
      "Envigado",
      "Caldas",
      "Bello",
      "Barbosa",
      "Antioquia",
      "Andes"
    ],
    Amazonas: ["Leticia"]
  },
  "Cocos (Keeling) Islands": {},
  China: {
    Anhui: ["Bengbu", "Hefei"],
    "Anhui Sheng": ["Hefei", "Huangshan"],
    Fujian: [
      "Fengcheng",
      "Fuding",
      "Fuzhou",
      "Jiangkou",
      "Jinjiang",
      "Pucheng",
      "Putian",
      "Quanzhou",
      "Shaowu",
      "Xiamen"
    ],
    Gansu: ["Lanzhou"],
    "Gansu Sheng": [
      "Baiyin",
      "Lanzhou",
      "Laojunmiao",
      "Tianshui",
      "Zhangye",
      "Zuogaimanma"
    ],
    Guangxi: ["Beihai", "Guilin", "Nanning", "Yulin"],
    "Guangxi Zhuangzu Zizhiqu": [
      "Beihai",
      "Guilin",
      "Liupai",
      "Longsheng",
      "Nanning",
      "Pumiao",
      "Yangshuo"
    ],
    Guizhou: ["Duyun", "Guiyang"],
    Hebei: [
      "Baoding",
      "Chengde",
      "Handan",
      "Langfang",
      "Luanping",
      "Qinhuangdao",
      "Renqiu",
      "Zhangjiakou"
    ],
    "Henan Sheng": ["Anyang", "Zhengzhou"],
    Hunan: [
      "Changsha",
      "Fenghuang",
      "Hengyang",
      "Jiaoziya",
      "Loudi",
      "Yiyang",
      "Zhangjiajie",
      "Zhonghuping",
      "Zhuzhou"
    ],
    "Inner Mongolia": ["Baotou", "Hohhot"],
    "Inner Mongolia Autonomous Region": ["Baotou", "Hohhot"],
    Jiangxi: ["Changleng", "Jingdezhen", "Jiujiang", "Nanchang", "Xinyu"],
    "Jilin Sheng": ["Changchun"],
    "Ningsia Hui Autonomous Region": ["Yinchuan"],
    "Ningxia Huizu Zizhiqu": ["Yinchuan"],
    Qinghai: [],
    Shaanxi: ["Ankang", "Hanzhong", "Weinan", "Xi'an"],
    Shanxi: ["Datong", "Jinyuan", "Taiyuan"],
    "Tibet Autonomous Region": ["Chongdui", "Diyag", "Lhasa", "Maindong"],
    Xinjiang: ["Kashgar", "Korla", "Ürümqi"],
    "Xinjiang Uygur Zizhiqu": ["A'yi Take", "Hotan", "Sayibage"],
    Zhejiang: [
      "Deqing",
      "Dingqiao",
      "Dongyang",
      "Guli",
      "Haining",
      "Hengdian",
      "Huzhou",
      "Jiaxing",
      "Jinhua",
      "Kunyang",
      "Linhai",
      "Linping",
      "Nanlu",
      "Ningbo",
      "Ninghai",
      "Sijiqing",
      "Wenzhou",
      "Xiaoshan",
      "Zhaobaoshan",
      "Zhicheng",
      "Yiwu",
      "Hangzhou"
    ],
    Yunnan: [
      "Gejiu",
      "Jinghong",
      "Kunming",
      "Lijiang",
      "Rende",
      "Shangri-La",
      "Shimen",
      "Xiaqiaotou",
      "Dali"
    ],
    Tibet: ["Lhasa"],
    Tianjin: ["Tianjin"],
    Sichuan: [
      "Jiangyou",
      "Kangding",
      "Leshan",
      "Neijiang",
      "Suishan",
      "Yibin",
      "Zigong",
      "Zitong",
      "Chengdu"
    ],
    Shanghai: [
      "Changning",
      "Changzheng",
      "Hongkou",
      "Jiadingzhen",
      "Kangqiao",
      "Luwan",
      "Songjiang",
      "Xuhui",
      "Shanghai"
    ],
    Shandong: [
      "Boxing",
      "Heze",
      "Jiaozhou",
      "Jimo",
      "Jinan",
      "Qingdao",
      "Qingyang",
      "Qingzhou",
      "Qufu",
      "Rizhao",
      "Weifang",
      "Weihai",
      "Yantai",
      "Zhoucheng",
      "Zibo",
      "Yanta"
    ],
    Liaoning: [
      "Anshan",
      "Chaoyang",
      "Dalianwan",
      "Fushun",
      "Hushitai",
      "Jinzhou",
      "Lianshan",
      "Shenyang",
      "Sujiatun",
      "Dalian"
    ],
    Jilin: ["Fuyu", "Changchun"],
    Jiangsu: [
      "Changshu City",
      "Dazhong",
      "Gaoyou",
      "Guiren",
      "Hede",
      "Huilong",
      "Jiangxinzhou",
      "Jiangyin",
      "Kunshan",
      "Loufeng",
      "Lukou",
      "Nanjing",
      "Nantong",
      "Rengang",
      "Shilu",
      "Shuangta",
      "Suzhou",
      "Taizhou",
      "Wuxi",
      "Xiaolingwei",
      "Xuzhou",
      "Yancheng",
      "Yangzhou",
      "Yongxing",
      "Zhenjiang",
      "Changzhou"
    ],
    Hubei: [
      "Daye",
      "Guanshan",
      "Jingzhou",
      "Shiyan",
      "Xindi",
      "Yichang",
      "Wuhan"
    ],
    Henan: [
      "Hebi",
      "Kaifeng",
      "Luoyang",
      "Xinyang",
      "Xuchang",
      "Zhengzhou",
      "Nanyang",
      "Anyang"
    ],
    Heilongjiang: ["Hailin", "Harbin", "Heihe", "Mishan", "Qiqihar", "Anda"],
    Hainan: [
      "Haikou",
      "Haitangwan",
      "Jinjiang",
      "Qiongshan",
      "Sanya",
      "Xiuying"
    ],
    Guangdong: [
      "Chaozhou",
      "Chengnan",
      "Danshui",
      "Dongguan",
      "Encheng",
      "Heyuan",
      "Huanggang",
      "Huizhou",
      "Humen",
      "Jiangmen",
      "Jieyang",
      "Jiulong",
      "Licheng",
      "Nancheng",
      "Qiaodong",
      "Shantou",
      "Shaoguan",
      "Shatoujiao",
      "Shilong",
      "Shiqi",
      "Shiqiao",
      "Shiwan",
      "Shunde",
      "Taishan",
      "Wushan",
      "Xinyi",
      "Xiongzhou",
      "Yangjiang",
      "Yonghe",
      "Zhongshan",
      "Zhuhai",
      "Shenzhen",
      "Guangzhou",
      "Foshan",
      "Dasha"
    ],
    Chongqing: ["Beibei", "Wanxian", "Yangjiaping", "Chongqing"],
    Beijing: [
      "Bohai",
      "Changping",
      "Chaowai",
      "Dahuashan",
      "Daxing",
      "Dongfeng",
      "Haidian",
      "Jingshan",
      "Liqiao",
      "Longtan",
      "Mentougou",
      "Nankou",
      "Qinghe",
      "Shunyi",
      "Taihu",
      "Tianzhu",
      "Tongzhou",
      "Wangjing",
      "Beijing"
    ]
  },
  Mayotte: { "": ["Mamoudzou"], Ouangani: ["Ouangani"] },
  Chile: {
    Araucania: ["Panguipulli", "Pucon", "Temuco", "Vilcun", "Villarrica"],
    "Arica y Parinacota": ["Arica"],
    Biobio: [
      "Arauco",
      "Chiguayante",
      "Chillan",
      "Coihueco",
      "Concepcion",
      "Coronel",
      "Penco",
      "San Carlos",
      "Talcahuano",
      "Tome"
    ],
    Magallanes: ["Puerto Natales", "Punta Arenas"],
    Valparaiso: [
      "Cartagena",
      "El Tabo",
      "Hacienda La Calera",
      "Hanga Roa",
      "La Ligua",
      "Llaillay",
      "Los Andes",
      "Quilpue",
      "San Antonio",
      "Valparaiso",
      "Vina del Mar"
    ],
    Tarapacá: ["Iquique"],
    "Santiago Metropolitan": [
      "Chicureo Abajo",
      "Lampa",
      "La Pintana",
      "Lo Prado",
      "Penaflor",
      "Puente Alto",
      "San Bernardo",
      "Talagante",
      "Villa Presidente Frei, Nunoa, Santiago, Chile",
      "Vitacura",
      "Santiago",
      "Paine",
      "Melipilla",
      "Buin"
    ],
    "Region of Magallanes": ["Puerto Natales", "Punta Arenas"],
    "Region de Valparaiso": [
      "Cartagena",
      "La Ligua",
      "Quilpué",
      "San Antonio",
      "San Felipe",
      "Villa Alemana",
      "Valparaíso",
      "Quillota",
      "Los Andes",
      "Limache"
    ],
    "Region del Biobio": [
      "Bulnes",
      "Canete",
      "Chiguayante",
      "Chillan",
      "Curanilahue",
      "La Laja",
      "Lebu",
      "Mulchen",
      "Penco",
      "San Carlos",
      "Yumbel",
      "Tome",
      "Talcahuano",
      "Nacimiento",
      "Lota",
      "Los Ángeles",
      "Coronel",
      "Concepción",
      "Cabrero",
      "Arauco"
    ],
    "Region de la Araucania": [
      "Angol",
      "Carahue",
      "Loncoche",
      "Nueva Imperial",
      "Pitrufquen",
      "Traiguen",
      "Villarrica",
      "Temuco",
      "Pucon",
      "Panguipulli",
      "Lautaro"
    ],
    "Region de Arica y Parinacota": ["Arica"],
    "O'Higgins Region": [
      "Chimbarongo",
      "Graneros",
      "Machali",
      "Rengo",
      "Santa Cruz",
      "San Vicente",
      "Rancagua"
    ],
    "Maule Region": [
      "Cauquenes",
      "Colbun",
      "Constitucion",
      "San Clemente",
      "San Javier",
      "Talca",
      "Parral",
      "Molina",
      "Linares",
      "Curicó"
    ],
    "Los Ríos Region": ["Rio Bueno", "Valdivia", "La Union"],
    "Los Lagos Region": [
      "Calbuco",
      "Castro",
      "Chonchi",
      "Frutillar",
      "La Ensenada",
      "Las Animas",
      "Puerto Montt",
      "Purranque",
      "Quellon",
      "Puerto Varas",
      "Osorno",
      "Ancud"
    ],
    "Coquimbo Region": [
      "Illapel",
      "Monte Patria",
      "Ovalle",
      "La Serena",
      "Coquimbo"
    ],
    Aysén: ["Cochrane", "Coihaique", "Coyhaique"],
    Atacama: ["Diego de Almagro", "Vallenar", "Copiapó"],
    Antofagasta: [
      "San Pedro de Atacama",
      "Taltal",
      "Tocopilla",
      "Calama",
      "Antofagasta"
    ]
  },
  Chad: {
    "Chari-Baguirmi Region": ["N'Djamena"],
    "Logone Occidental Region": ["Benoy"],
    "Moyen-Chari Region": ["Sarh"],
    "Salamat Region": ["Am Timan"],
    "Ennedi-Ouest": ["Fada"],
    "Batha Region": ["Oum Hadjer", "Ati"]
  },
  "Central African Republic": {
    Bangui: ["Bangui"],
    "Basse-Kotto": ["Kembe"],
    "Haut-Mbomou": ["Obo", "Zemio"],
    "Mambéré-Kadéï": ["Carnot"],
    Mbomou: ["Bangassou"],
    "Nana-Mambéré": ["Bouar"],
    Ouaka: ["Ippy"],
    "Sangha-Mbaéré": ["Nola"],
    Ouham: ["Kabo"],
    "Ombella-M'Poko": ["Bimbo", "Damara"],
    "Haute-Kotto": ["Bria"]
  },
  "Cayman Islands": { "": ["West Bay"] },
  Mauritius: {
    "Black River District": [
      "Cascavelle",
      "Flic en Flac",
      "Petite Case Noyale"
    ],
    Flacq: [
      "Centre de Flacq",
      "Grande Riviere Sud Est",
      "Mare La Chaux",
      "Quatre Cocos"
    ],
    "Grand Port District": ["Beau Vallon", "Mahebourg", "Rose Belle"],
    Moka: ["Pailles", "Saint Pierre"],
    "Moka District": ["Pailles"],
    "Pamplemousses District": [
      "Grande Pointe aux Piments",
      "Le Hochet",
      "Triolet"
    ],
    "Port Louis District": ["Port Louis"],
    "Riviere du Rempart District": ["Goodlands", "Grand Baie", "Grand Gaube"],
    Rodrigues: ["Port Mathurin"],
    "Savanne District": ["Surinam"],
    "Plaines Wilhems District": ["Curepipe", "Vacoas", "Quatre Bornes"]
  },
  Canada: {
    "Northwest Territories": ["Yellowknife"],
    Nunavut: ["Iqaluit", "Kugluktuk", "Rankin Inlet"],
    Yukon: ["Dawson City", "Haines Junction", "Whitehorse"],
    Saskatchewan: [
      "Eyebrow",
      "La Ronge",
      "Lloydminster",
      "Meadow Lake",
      "Moose Jaw",
      "North Battleford",
      "Prince Albert",
      "Swift Current",
      "Weyburn",
      "Yorkton",
      "Saskatoon",
      "Regina"
    ],
    Quebec: [
      "Acton Vale",
      "Baie-Comeau",
      "Baie-D'Urfe",
      "Baie-Saint-Paul",
      "Beauharnois",
      "Beloeil",
      "Boisbriand",
      "Bois-des-Filion",
      "Boucherville",
      "Bromont",
      "Brossard",
      "Candiac",
      "Carleton-sur-Mer",
      "Chambly",
      "Chateauguay",
      "Chateau-Richer",
      "Cote-Saint-Luc",
      "Cowansville",
      "Deux-Montagnes",
      "Dollard-Des Ormeaux",
      "Dorval",
      "Fermont",
      "Fort-Coulonge",
      "Gaspé",
      "Granby",
      "Hampstead",
      "Havre-Saint-Pierre",
      "Jonquiere",
      "Kirkland",
      "Labelle",
      "La Malbaie",
      "L'Ancienne-Lorette",
      "La Prairie",
      "L'Assomption",
      "Les Coteaux",
      "Lorraine",
      "Magog",
      "Maniwaki",
      "Maria",
      "Mascouche",
      "Matane",
      "Mercier",
      "Mirabel",
      "Montmagny",
      "Montreal-Est",
      "Montreal-Ouest",
      "Mont-Royal",
      "Mont-Tremblant",
      "New-Richmond",
      "Nicolet",
      "Papineauville",
      "Pincourt",
      "Pointe-Claire",
      "Rawdon",
      "Repentigny",
      "Rivière-du-Loup",
      "Rosemere",
      "Rouyn-Noranda",
      "Saint-Bruno-de-Montarville",
      "Saint-Constant",
      "Saint-Damase",
      "Saint-Denis-sur-Richelieu",
      "Sainte-Adele",
      "Sainte-Anne-des-Monts",
      "Sainte-Julie",
      "Sainte-Marthe-sur-le-Lac",
      "Sainte-Thecle",
      "Sainte-Therese",
      "Saint-Eustache",
      "Saint-Jean-sur-Richelieu",
      "Saint-Jerome",
      "Saint-Laurent",
      "Saint-Lazare",
      "Saint-Leonard",
      "Saint-Pie",
      "Saint-Raymond",
      "Saint-Sauveur",
      "Salaberry-de-Valleyfield",
      "Sorel-Tracy",
      "Terrebonne",
      "Thetford-Mines",
      "Trois-Rivières",
      "Val-David",
      "Varennes",
      "Vaudreuil-Dorion",
      "Victoriaville",
      "Wakefield",
      "Warwick",
      "Westmount",
      "Sherbrooke",
      "Shawinigan",
      "Saguenay",
      "Rimouski",
      "Québec",
      "Mount Royal",
      "Montreal",
      "Longueuil",
      "Levis",
      "Laval",
      "Joliette",
      "Gatineau",
      "Drummondville",
      "Blainville",
      "Beaconsfield",
      "Amos",
      "Alma"
    ],
    "Prince Edward Island": [
      "Kensington",
      "Souris",
      "Summerside",
      "Charlottetown"
    ],
    Ontario: [
      "Amherstburg",
      "Bancroft",
      "Belleville",
      "Bells Corners",
      "Bluewater",
      "Bolton",
      "Bourget",
      "Bracebridge",
      "Bradford West Gwillimbury",
      "Brockville",
      "Cambridge",
      "Carleton Place",
      "Casselman",
      "Chatham",
      "Clarence-Rockland",
      "Cobourg",
      "Concord",
      "Elliot Lake",
      "Espanola",
      "Etobicoke",
      "Fort Erie",
      "Gananoque",
      "Gravenhurst",
      "Greater Napanee",
      "Greater Sudbury",
      "Hanover",
      "Hearst",
      "Hornepayne",
      "Huntsville",
      "Ingersoll",
      "Innisfil",
      "Kapuskasing",
      "Kenora",
      "Kingston",
      "Kirkland Lake",
      "Lambton Shores",
      "Little Current",
      "Midland",
      "Napanee",
      "Nepean",
      "Niagara Falls",
      "Norfolk County",
      "North Bay",
      "North Perth",
      "North York",
      "Omemee",
      "Oshawa",
      "Owen Sound",
      "Paris",
      "Parry Sound",
      "Perth",
      "Petawawa",
      "Petrolia",
      "Pickering",
      "Picton",
      "Port Colborne",
      "Prince Edward",
      "Quinte West",
      "Rayside-Balfour",
      "Renfrew",
      "Richmond Hill",
      "Sault Ste. Marie",
      "Skatepark",
      "Smiths Falls",
      "Spanish",
      "Stratford",
      "Temiskaming Shores",
      "Thessalon",
      "Thunder Bay",
      "Timmins",
      "Tobermory",
      "Uxbridge",
      "Wasaga Beach",
      "Welland",
      "Woodstock",
      "Windsor",
      "Willowdale",
      "Waterloo",
      "Vaughan",
      "Toronto",
      "Thorold",
      "St. Thomas",
      "St. Catharines",
      "Simcoe",
      "Scarborough",
      "Sarnia",
      "Peterborough",
      "Pembroke",
      "Ottawa",
      "Orillia",
      "Orangeville",
      "Oakville",
      "Newmarket",
      "Mississauga",
      "Milton",
      "Markham",
      "London",
      "Kitchener",
      "Keswick",
      "Hawkesbury",
      "Hamilton",
      "Guelph",
      "Cornwall",
      "Collingwood",
      "Burlington",
      "Brantford",
      "Brant",
      "Brampton",
      "Barrie",
      "Angus",
      "Ancaster",
      "Ajax"
    ],
    "Nova Scotia": [
      "Berwick",
      "Chester",
      "Cole Harbour",
      "Fall River",
      "Glace Bay",
      "Kentville",
      "Lower Sackville",
      "New Glasgow",
      "Truro",
      "Sydney",
      "Halifax",
      "Dartmouth"
    ],
    "Newfoundland and Labrador": [
      "Bay Roberts",
      "Bonavista",
      "Carbonear",
      "Conception Bay South",
      "Corner Brook",
      "Deer Lake",
      "Gambo",
      "Happy Valley-Goose Bay",
      "Labrador City",
      "Mount Pearl",
      "St. John's"
    ],
    "New Brunswick": [
      "Bathurst",
      "Dieppe",
      "Hampton",
      "Miramichi",
      "Saint John",
      "Moncton",
      "Fredericton",
      "Edmundston"
    ],
    Manitoba: [
      "Brandon",
      "Dauphin",
      "Headingley",
      "Lac du Bonnet",
      "Minnedosa",
      "Rivers",
      "Selkirk",
      "Winnipeg",
      "Thompson"
    ],
    "British Columbia": [
      "Agassiz",
      "Aldergrove",
      "Anmore",
      "Armstrong",
      "Bowen Island",
      "Burns Lake",
      "Cache Creek",
      "Campbell River",
      "Chase",
      "Coquitlam",
      "Courtenay",
      "Cumberland",
      "Delta",
      "Duncan",
      "Fernie",
      "Fort St. John",
      "Gibsons",
      "Golden",
      "Hanceville",
      "Hope",
      "Invermere",
      "Kimberley",
      "Kitimat",
      "Ladner",
      "Lions Bay",
      "Lumby",
      "Mackenzie",
      "Maple Ridge",
      "Merritt",
      "Nelson",
      "North Cowichan",
      "North Saanich",
      "North Vancouver",
      "Oak Bay",
      "Oliver",
      "Parksville",
      "Peachland",
      "Pemberton",
      "Penticton",
      "Pitt Meadows",
      "Port Alberni",
      "Powell River",
      "Prince George",
      "Prince Rupert",
      "Princeton",
      "Quesnel",
      "Revelstoke",
      "Richmond",
      "Rossland",
      "Salmo",
      "Salmon Arm",
      "Sechelt",
      "Sicamous",
      "Smithers",
      "Sooke",
      "Summerland",
      "Tofino",
      "Ucluelet",
      "Victoria",
      "Walnut Grove",
      "West Kelowna",
      "West Vancouver",
      "White Rock",
      "Williams Lake",
      "Whistler",
      "West End",
      "Vernon",
      "Vancouver",
      "Terrace",
      "Surrey",
      "Port Moody",
      "New Westminster",
      "Nanaimo",
      "Langley",
      "Langford",
      "Kelowna",
      "Kamloops",
      "Dawson Creek",
      "Cranbrook",
      "Chilliwack",
      "Burnaby",
      "Abbotsford"
    ],
    Alberta: [
      "Airdrie",
      "Banff",
      "Black Diamond",
      "Bonnyville",
      "Calmar",
      "Cochrane",
      "Didsbury",
      "Fort McMurray",
      "Fort Saskatchewan",
      "Grande Cache",
      "Grande Prairie",
      "Jasper",
      "Jasper Park Lodge",
      "Lacombe",
      "Lake Louise",
      "Medicine Hat",
      "Olds",
      "Peace River",
      "Pincher Creek",
      "Rimbey",
      "Rocky Mountain House",
      "Slave Lake",
      "Spruce Grove",
      "St. Albert",
      "Stony Plain",
      "Strathmore",
      "Sylvan Lake",
      "Taber",
      "Three Hills",
      "Veteran",
      "Wetaskiwin",
      "Sherwood Park",
      "Red Deer",
      "Okotoks",
      "Lethbridge",
      "Leduc",
      "Edmonton",
      "Cold Lake",
      "Canmore",
      "Camrose",
      "Calgary",
      "Brooks"
    ]
  },
  Mauritania: {
    Adrar: ["Atar"],
    Brakna: ["Aleg"],
    "Dakhlet Nouadhibou": [],
    "Hodh ech Chargui": ["Néma"],
    Nouakchott: ["Nouakchott"],
    "Wilaya du Trarza": ["Rosso"]
  },
  Cameroon: {
    "North Province": ["Poli"],
    "North-West Region": ["Bali", "Bamenda"],
    "South Province": ["Kribi"],
    "South-West Region": ["Buea", "Kumba", "Limbe"],
    "West Region": ["Bafoussam"],
    South: [],
    Littoral: ["Douala"],
    Centre: ["Bafia", "Yaoundé"]
  },
  Cambodia: {
    "Banteay Meanchey": [],
    Battambang: ["Battambang"],
    "Kampong Cham": ["Kampong Cham"],
    "Kampong Chhnang": ["Kampong Chhnang"],
    "Kampong Speu": ["Kampong Speu"],
    "Kampong Thom": ["Kampong Thom"],
    Kampot: ["Kampot"],
    Kandal: ["Ta Khmau"],
    "Koh Kong": ["Koh Kong", "Smach Mean Chey"],
    Kratie: ["Kratie"],
    Pailin: ["Pailin"],
    "Preah Sihanouk": ["Sihanoukville"],
    "Preah Vihear": [],
    "Prey Veng": ["Prey Veng"],
    Pursat: ["Pursat"],
    Ratanakiri: [],
    "Stung Treng": ["Stung Treng"],
    "Svay Rieng": ["Svay Rieng"],
    Takeo: ["Takeo"],
    "Siem Reap": ["Siem Reap"],
    "Phnom Penh": ["Phnom Penh"],
    Kep: ["Krong Kep"]
  },
  Burundi: {
    "Bujumbura Mairie Province": ["Bujumbura"],
    "Makamba Province": ["Makamba"],
    "Ngozi Province": ["Ngozi"]
  },
  Martinique: {
    "": ["Ducos", "Le Lamentin"],
    Martinique: [
      "Ducos",
      "Fort-de-France",
      "Le Lamentin",
      "Le Robert",
      "Les Trois-Ilets",
      "Le Vauclin",
      "Sainte-Marie",
      "Saint-Joseph"
    ]
  },
  Bulgaria: {
    Blagoevgrad: [
      "Bansko",
      "Blagoevgrad",
      "Gotse Delchev",
      "Petrich",
      "Razlog",
      "Sandanski"
    ],
    Burgas: [
      "Akhtopol",
      "Aytos",
      "Burgas",
      "Chernomorets",
      "Kiten",
      "Nesebar",
      "Pomorie",
      "Sozopol",
      "Sveti Vlas",
      "Tsarevo"
    ],
    Dobrich: ["Balchik", "Dobrich", "Kavarna"],
    Gabrovo: ["Gabrovo", "Sevlievo", "Tryavna"],
    Khaskovo: ["Haskovo", "Kharmanli", "Svilengrad"],
    Kyustendil: ["Dupnitsa"],
    Lovech: ["Lovech"],
    Montana: ["Montana"],
    "Oblast Dobrich": ["Dobrich", "Kavarna"],
    "Oblast Kardzhali": ["Kardzhali"],
    "Oblast Kyustendil": ["Dupnitsa", "Kyustendil"],
    "Oblast Montana": ["Lom"],
    "Oblast Pleven": ["Levski", "Pleven"],
    "Oblast Razgrad": ["Razgrad"],
    "Oblast Ruse": ["Rousse"],
    "Oblast Shumen": ["Shumen"],
    "Oblast Silistra": ["Silistra"],
    "Oblast Sliven": ["Sliven"],
    "Oblast Smolyan": ["Smolyan"],
    "Oblast Stara Zagora": ["Chirpan", "Kazanlak", "Stara Zagora"],
    "Oblast Targovishte": ["Popovo", "Targovishte"],
    "Oblast Vidin": ["Vidin"],
    "Oblast Vratsa": ["Vratsa"],
    "Oblast Yambol": ["Yambol"],
    Pazardzhik: ["Panagyurishte", "Pazardzhik", "Velingrad"],
    Pernik: ["Pernik", "Radomir"],
    Pleven: ["Cherven Bryag", "Pleven"],
    Razgrad: ["Razgrad"],
    Ruse: ["Ruse"],
    Shumen: ["Vurbitsa"],
    Silistra: ["Silistra"],
    Sliven: ["Sliven"],
    Smolyan: ["Rudozem", "Smolyan"],
    "Sofia-Capital": ["Sofia"],
    "Stara Zagora": ["Stara Zagora"],
    Vidin: ["Vidin"],
    Vratsa: ["Vratsa"],
    Yambol: ["Yambol"],
    Varna: ["Zlatni Pyasatsi", "Varna"],
    Sofia: ["Botevgrad", "Samokov"],
    Plovdiv: ["Asenovgrad", "Karlovo", "Rakovski", "Plovdiv"],
    "Oblast Veliko Tarnovo": [
      "Gorna Oryahovitsa",
      "Svishtov",
      "Veliko Tarnovo"
    ],
    Haskovo: ["Dimitrovgrad", "Harmanli", "Svilengrad", "Haskovo"]
  },
  "Brunei Darussalam": {
    Belait: ["Kuala Belait", "Seria"],
    "Brunei and Muara District": ["Bandar Seri Begawan"],
    Tutong: ["Tutong"],
    "Tutong District": ["Tutong"],
    "Belait District": ["Kuala Belait", "Seria"]
  },
  "Marshall Islands": {
    "Kwajalein Atoll": ["Ebaye"],
    "Majuro Atoll": ["Majuro"],
    "Rongelap Atoll": ["Rongelap"]
  },
  Malta: {
    Balzan: ["Balzan"],
    "Haz-Zabbar": ["Zabbar"],
    "Il-Belt Valletta": ["Valletta"],
    "Il-Furjana": ["Floriana"],
    "Il-Marsa": ["Marsa"],
    "Il-Mosta": ["Mosta"],
    "Il-Qala": ["Qala"],
    "In-Naxxar": ["Naxxar"],
    "Ix-Xaghra": ["Xaghra"],
    "Iz-Zejtun": ["Zejtun"],
    "L-Imdina": ["Imdina"],
    Qormi: ["Qormi"],
    "Saint Julian": ["San Giljan"],
    "Saint Lucia": ["Saint Lucia"],
    "Saint Paul’s Bay": ["San Pawl il-Bahar"],
    "Tas-Sliema": ["Sliema"],
    "Ta' Xbiex": ["Ta' Xbiex"],
    "Saint John": ["Saint John"],
    Birkirkara: ["Birkirkara"]
  },
  Brazil: {
    Tocantins: ["Taguatinga", "Palmas", "Gurupi", "Araguaína", "Alvorada"],
    Sergipe: [
      "Barra dos Coqueiros",
      "Boquim",
      "Caninde de Sao Francisco",
      "Carira",
      "Indiaroba",
      "Japaratuba",
      "Malhador",
      "Moita Bonita",
      "Neopolis",
      "Nossa Senhora das Dores",
      "Porto da Folha",
      "Propria",
      "Ribeiropolis",
      "Sao Cristovao",
      "Tobias Barreto",
      "Umbauba",
      "Maruim",
      "Laranjeiras",
      "Lagarto",
      "Itabaianinha",
      "Itabaiana",
      "Estancia",
      "Capela",
      "Aracaju"
    ],
    "Sao Paulo": [
      "Aguai",
      "Aguas de Lindoia",
      "Altinopolis",
      "Alvares Machado",
      "Americo Brasiliense",
      "Apiai",
      "Aracoiaba da Serra",
      "Avare",
      "Barra Bonita",
      "Batatais",
      "Bebedouro",
      "Bernardino de Campos",
      "Biritiba Mirim",
      "Boa Esperanca do Sul",
      "Bom Jesus dos Perdoes",
      "Borborema",
      "Braganca Paulista",
      "Brasil",
      "Buri",
      "Buritama",
      "Cabreuva",
      "Cacapava",
      "Cafelandia",
      "Cajati",
      "Cajuru",
      "Campo Limpo Paulista",
      "Campos do Jordao",
      "Cananeia",
      "Capela do Alto",
      "Capivari",
      "Carapicuiba",
      "Casa Branca",
      "Cerqueira Cesar",
      "Chavantes",
      "Cosmopolis",
      "Cubatao",
      "Dois Corregos",
      "Espirito Santo do Pinhal",
      "Fartura",
      "Fernandopolis",
      "Ferraz de Vasconcelos",
      "Francisco Morato",
      "Franco da Rocha",
      "Garca",
      "Guaira",
      "Guapiacu",
      "Guaratingueta",
      "Guariba",
      "Guaruja",
      "Ibate",
      "Ibiuna",
      "Igarapava",
      "Ipaucu",
      "Ipero",
      "Iracemapolis",
      "Itai",
      "Itajobi",
      "Itanhaem",
      "Itapecerica da Serra",
      "Itapevi",
      "Itapolis",
      "Itarare",
      "Itatinga",
      "Itirapina",
      "Jacupiranga",
      "Jardinopolis",
      "Jarinu",
      "Jose Bonifacio",
      "Juquitiba",
      "Laranjal Paulista",
      "Lencois Paulista",
      "Lucelia",
      "Macatuba",
      "Mairinque",
      "Maracai",
      "Matao",
      "Maua",
      "Miracatu",
      "Mirandopolis",
      "Mogi das Cruzes",
      "Mogi-Gaucu",
      "Mogi Guacu",
      "Mogi Mirim",
      "Mongagua",
      "Monte Aprazivel",
      "Monte Mor",
      "Morro Agudo",
      "Nova Granada",
      "Nova Odessa",
      "Olimpia",
      "Orlandia",
      "Pacaembu",
      "Palmital",
      "Panorama",
      "Paraguacu Paulista",
      "Paranapanema",
      "Pariquera Acu",
      "Paulinia",
      "Pedregulho",
      "Penapolis",
      "Piquete",
      "Piracaia",
      "Pirapora do Bom Jesus",
      "Pirapozinho",
      "Pirassununga",
      "Pitangueiras",
      "Pompeia",
      "Porangaba",
      "Porto Feliz",
      "Promissao",
      "Quata",
      "Rancharia",
      "Regente Feijo",
      "Ribeirao Pires",
      "Salto",
      "Santa Barbara d'Oeste",
      "Santa Fe do Sul",
      "Santa Gertrudes",
      "Santana de Parnaiba",
      "Santa Rosa de Viterbo",
      "Santo Anastacio",
      "São Bernardo do Campo",
      "São Caetano do Sul",
      "Sao Joao da Boa Vista",
      "Sao Joaquim da Barra",
      "Sao Jose do Rio Pardo",
      "Sao Jose do Rio Preto",
      "São José dos Campos",
      "Sao Manuel",
      "Sao Pedro",
      "Sao Roque",
      "Sao Sebastiao",
      "Sao Vicente",
      "Serra Negra",
      "Sertaozinho",
      "Severinia",
      "Tabatinga",
      "Taboao da Serra",
      "Tambau",
      "Tanabi",
      "Taquaritinga",
      "Taquarituba",
      "Taruma",
      "Taubate",
      "Teodoro Sampaio",
      "Tiete",
      "Tremembe",
      "Tupa",
      "Tupi Paulista",
      "Urupes",
      "Vargem Grande Paulista",
      "Varzea Paulista",
      "Votuporanga",
      "Votorantim",
      "Viradouro",
      "Vinhedo",
      "Valinhos",
      "Ubatuba",
      "Tatuí",
      "Suzano",
      "Sumaré",
      "Sorocaba",
      "Socorro",
      "Serrana",
      "São Paulo",
      "São Carlos",
      "Santos",
      "Santo André",
      "Santa Isabel",
      "Rio Claro",
      "Ribeirão Preto",
      "Registro",
      "Presidente Prudente",
      "Praia Grande",
      "Porto Ferreira",
      "Pontal",
      "Poa",
      "Piraju",
      "Piracicaba",
      "Pindorama",
      "Pindamonhangaba",
      "Piedade",
      "Peruibe",
      "Pereira Barreto",
      "Pedreira",
      "Pederneiras",
      "Ourinhos",
      "Osvaldo Cruz",
      "Osasco",
      "Novo Horizonte",
      "Monte Azul Paulista",
      "Monte Alto",
      "Mococa",
      "Marília",
      "Mairiporã",
      "Louveira",
      "Lorena",
      "Lins",
      "Limeira",
      "Leme",
      "Juquia",
      "Junqueiropolis",
      "Jundiaí",
      "Jaú",
      "Jandira",
      "Jales",
      "Jaguariuna",
      "Jacareí",
      "Jaboticabal",
      "Ituverava",
      "Itupeva",
      "Itu",
      "Itatiba",
      "Itaquaquecetuba",
      "Itapira",
      "Itapeva",
      "Itapetininga",
      "Indaiatuba",
      "Ilha Solteira",
      "Ilhabela",
      "Iguape",
      "Ibitinga",
      "Hortolândia",
      "Guarulhos",
      "Guararema",
      "Guararapes",
      "Guara",
      "Franca",
      "Embu",
      "Elias Fausto",
      "Duartina",
      "Diadema",
      "Descalvado",
      "Cunha",
      "Cruzeiro",
      "Cravinhos",
      "Cotia",
      "Conchas",
      "Conchal",
      "Charqueada",
      "Cerquilho",
      "Catanduva",
      "Castilho",
      "Cardoso",
      "Caraguatatuba",
      "Campinas",
      "Cajamar",
      "Caieiras",
      "Caconde",
      "Brotas",
      "Botucatu",
      "Boituva",
      "Birigui",
      "Bertioga",
      "Bauru",
      "Bastos",
      "Barueri",
      "Barrinha",
      "Barretos",
      "Bariri",
      "Bady Bassitt",
      "Auriflama",
      "Atibaia",
      "Assis",
      "Arujá",
      "Artur Nogueira",
      "Araras",
      "Araraquara",
      "Araçatuba",
      "Aparecida",
      "Angatuba",
      "Andradina",
      "Amparo",
      "Americana",
      "Agudos",
      "Adamantina"
    ],
    "Santa Catarina": [
      "Anitapolis",
      "Armacao",
      "Biguacu",
      "Campinas",
      "Campos Novos",
      "Capinzal",
      "Carianos",
      "Cocal",
      "Corrego Grande",
      "Freguesia do Ribeirao da Ilha",
      "Guaramirim",
      "Ibirama",
      "Icara",
      "Itacorubi",
      "Jaguaruna",
      "Jaraguá do Sul",
      "Lagoa",
      "Lauro Muller",
      "Morro da Cruz",
      "Navegantes",
      "Otacilio Costa",
      "Ouro",
      "Palhoca",
      "Pantanal",
      "Pantano do Sul",
      "Porto Uniao",
      "Rio do Sul",
      "Rio Negrinho",
      "Rio Tavares",
      "Santa Cecilia",
      "Santa Monica",
      "Santo Amaro da Imperatriz",
      "Sao Francisco do Sul",
      "Schroeder",
      "Sideropolis",
      "Tijucas",
      "Timbo",
      "Trindade",
      "Tubarao",
      "Xanxere",
      "Videira",
      "Urussanga",
      "Sombrio",
      "Sao Joaquim",
      "Porto Belo",
      "Pomerode",
      "Penha",
      "Orleans",
      "Mafra",
      "Laguna",
      "Lages",
      "Joinville",
      "Joaçaba",
      "Itapema",
      "Itajaí",
      "Indaial",
      "Imbituba",
      "Gaspar",
      "Forquilhinha",
      "Florianópolis",
      "Curitibanos",
      "Criciúma",
      "Correia Pinto",
      "Chapecó",
      "Celso Ramos",
      "Canoinhas",
      "Caçador",
      "Brusque",
      "Blumenau",
      "Barra Velha",
      "Balneário Camboriú",
      "Ararangua"
    ],
    Roraima: ["Boa Vista"],
    Rondonia: [
      "Ariquemes",
      "Cacoal",
      "Ji Parana",
      "Ouro Preto do Oeste",
      "Vilhena",
      "Porto Velho",
      "Pimenta Bueno",
      "Jaru"
    ],
    "Rio Grande do Sul": [
      "Arroio do Meio",
      "Arroio Grande",
      "Cacequi",
      "Cachoeirinha",
      "Candelaria",
      "Capao da Canoa",
      "Caxias do Sul",
      "Charqueadas",
      "Cruz Alta",
      "Dom Pedrito",
      "Encantado",
      "Estancia Velha",
      "Estrela",
      "Flores da Cunha",
      "Girua",
      "Gravataí",
      "Guapore",
      "Horizontina",
      "Ibiruba",
      "Ivoti",
      "Jaguarao",
      "Lagoa Vermelha",
      "Montenegro",
      "Nova Petropolis",
      "Nova Prata",
      "Osorio",
      "Pinheiro Machado",
      "Portao",
      "Rolante",
      "Santa Cruz do Sul",
      "Santana do Livramento",
      "Santa Rosa",
      "Santiago",
      "Sao Francisco de Assis",
      "Sao Gabriel",
      "Sao Jeronimo",
      "Sapucaia",
      "Sarandi",
      "Soledade",
      "Taquari",
      "Teutonia",
      "Tramandai",
      "Tres Passos",
      "Vacaria",
      "Venancio Aires",
      "Veranopolis",
      "Viamão",
      "Uruguaiana",
      "Triunfo",
      "Torres",
      "Taquara",
      "Tapes",
      "Tapejara",
      "Sapiranga",
      "São Leopoldo",
      "São Borja",
      "Santo Augusto",
      "Santo Ângelo",
      "Santa Maria",
      "Rio Pardo",
      "Rio Grande",
      "Quarai",
      "Porto Alegre",
      "Pelotas",
      "Passo Fundo",
      "Panambi",
      "Novo Hamburgo",
      "Marau",
      "Lajeado",
      "Itaqui",
      "Ijui",
      "Igrejinha",
      "Guaíba",
      "Garibaldi",
      "Frederico Westphalen",
      "Farroupilha",
      "Esteio",
      "Espumoso",
      "Erechim",
      "Carlos Barbosa",
      "Carazinho",
      "Canoas",
      "Canela",
      "Bento Gonçalves",
      "Bagé",
      "Alegrete"
    ],
    "Rio Grande do Norte": [
      "Angicos",
      "Ares",
      "Barauna",
      "Caico",
      "Canguaretama",
      "Caraubas",
      "Ceara Mirim",
      "Goianinha",
      "Jardim do Serido",
      "Macaiba",
      "Santa Cruz",
      "Santo Antonio",
      "Sao Jose de Mipibu",
      "Touros",
      "Upanema",
      "Parnamirim",
      "Parelhas",
      "Nova Cruz",
      "Nisia Floresta",
      "Natal",
      "Mossoro",
      "Macau",
      "Jucurutu",
      "Extremoz",
      "Currais Novos",
      "Areia Branca",
      "Apodi",
      "Acu"
    ],
    "Rio de Janeiro": [
      "Areal",
      "Armacao de Buzios",
      "Arraial do Cabo",
      "Barra do Piraí",
      "Belford Roxo",
      "Bom Jesus do Itabapoana",
      "Cachoeiras de Macacu",
      "Campos",
      "Cantagalo",
      "Casimiro de Abreu",
      "Duque de Caxias",
      "Iguaba Grande",
      "Itaborai",
      "Itaguai",
      "Japeri",
      "Mangaratiba",
      "Marica",
      "Natividade",
      "Nilopolis",
      "Paraiba do Sul",
      "Paty do Alferes",
      "Pinheiral",
      "Porciuncula",
      "Porto Real",
      "Quatis",
      "Queimados",
      "Rio Bonito",
      "Rio das Ostras",
      "Sao Goncalo",
      "Sao Joao da Barra",
      "Sao Joao de Meriti",
      "Seropedica",
      "Teresopolis",
      "Tres Rios",
      "Valenca",
      "Volta Redonda",
      "Vassouras",
      "Saquarema",
      "Rio de Janeiro",
      "Resende",
      "Pirai",
      "Petrópolis",
      "Paraty",
      "Paracambi",
      "Nova Iguaçu",
      "Nova Friburgo",
      "Niterói",
      "Miracema",
      "Miguel Pereira",
      "Mendes",
      "Macaé",
      "Itatiaia",
      "Itaperuna",
      "Itaocara",
      "Guapimirim",
      "Cordeiro",
      "Carmo",
      "Cabo Frio",
      "Barra Mansa",
      "Araruama",
      "Angra dos Reis"
    ],
    Piaui: [
      "Brasileira",
      "Centro",
      "Cocal",
      "Itainopolis",
      "Luis Correia",
      "Parnaiba",
      "Piracuruca",
      "Regeneracao",
      "Sao Raimundo Nonato",
      "Uniao",
      "Teresina",
      "Piripiri",
      "Picos",
      "Pedro II",
      "Floriano",
      "Esperantina",
      "Campo Maior",
      "Bom Jesus",
      "Barras",
      "Altos"
    ],
    Pernambuco: [
      "Abreu e Lima",
      "Afogados da Ingazeira",
      "Agrestina",
      "Agua Preta",
      "Altinho",
      "Aracoiaba",
      "Arcoverde",
      "Bom Conselho",
      "Bom Jardim",
      "Bonito",
      "Brejo da Madre de Deus",
      "Buique",
      "Cabo",
      "Canhotinho",
      "Cha Grande",
      "Cupira",
      "Custodia",
      "Exu",
      "Fernando de Noronha (Distrito Estadual)",
      "Flores",
      "Gameleira",
      "Ibimirim",
      "Ipubi",
      "Itamaraca",
      "Itaquitinga",
      "Jaboatao",
      "Jaboatao dos Guararapes",
      "Jatoba",
      "Lagoa do Itaenga",
      "Lajedo",
      "Maraial",
      "Pombos",
      "Rio Formoso",
      "Santa Cruz do Capibaribe",
      "Sao Joaquim do Monte",
      "Sao Lourenco da Mata",
      "Sertania",
      "Sirinhaem",
      "Surubim",
      "Tabira",
      "Tamandare",
      "Timbauba",
      "Vicencia",
      "Vitoria de Santo Antao",
      "Toritama",
      "Serra Talhada",
      "Salgueiro",
      "Ribeirao",
      "Recife",
      "Petrolina",
      "Pesqueira",
      "Paulista",
      "Palmares",
      "Ouricuri",
      "Olinda",
      "Moreno",
      "Limoeiro",
      "Itapissuma",
      "Ipojuca",
      "Igarassu",
      "Gravatá",
      "Goiana",
      "Garanhuns",
      "Floresta",
      "Feira Nova",
      "Escada",
      "Condado",
      "Catende",
      "Caruaru",
      "Carpina",
      "Bezerros",
      "Belo Jardim",
      "Barreiros",
      "Araripina",
      "Amaraji"
    ],
    Parana: [
      "Almirante Tamandare",
      "Alto Parana",
      "Ampere",
      "Araucária",
      "Assai",
      "Astorga",
      "Bela Vista",
      "Cambara",
      "Cambe",
      "Campo Mourao",
      "Carambei",
      "Chopinzinho",
      "Colombo",
      "Colorado",
      "Cornelio Procopio",
      "Coronel Vivida",
      "Engenheiro Beltrao",
      "Faxinal",
      "Foz do Iguaçu",
      "Guaratuba",
      "Ibaiti",
      "Ibipora",
      "Imbituva",
      "Jacarezinho",
      "Jaguariaiva",
      "Jataizinho",
      "Loanda",
      "Mandaguari",
      "Marechal Candido Rondon",
      "Nova Londrina",
      "Palmas",
      "Peabiru",
      "Pinhao",
      "Pitanga",
      "Pontal do Parana",
      "Porecatu",
      "Quatro Barras",
      "Rolandia",
      "São José dos Pinhais",
      "Sao Mateus do Sul",
      "Siqueira Campos",
      "Telemaco Borba",
      "Terra Boa",
      "Terra Rica",
      "Tibagi",
      "Wenceslau Braz",
      "Umuarama",
      "Toledo",
      "Sarandi",
      "Reserva",
      "Realeza",
      "Ponta Grossa",
      "Piraquara",
      "Pinhais",
      "Pato Branco",
      "Paranavai",
      "Paranaguá",
      "Palotina",
      "Palmeira",
      "Ortigueira",
      "Medianeira",
      "Maringá",
      "Marialva",
      "Londrina",
      "Lapa",
      "Irati",
      "Guarapuava",
      "Francisco Beltrão",
      "Dois Vizinhos",
      "Curitiba",
      "Cianorte",
      "Castro",
      "Cascavel",
      "Campo Largo",
      "Bandeirantes",
      "Arapongas",
      "Apucarana",
      "Antonina"
    ],
    Paraíba: [
      "Alagoa Grande",
      "Alhandra",
      "Bananeiras",
      "Bayeux",
      "Boqueirao",
      "Cuite",
      "Esperanca",
      "Mamanguape",
      "Manaira",
      "Massaranduba",
      "Nova Floresta",
      "Pianco",
      "Picui",
      "Pombal",
      "Princesa Isabel",
      "Rio Tinto",
      "Santa Luzia",
      "Sao Bento",
      "Solanea",
      "Sume",
      "Taperoa",
      "Teixeira",
      "Uirauna",
      "Sousa",
      "Santa Rita",
      "Patos",
      "Monteiro",
      "Mari",
      "João Pessoa",
      "Itaporanga",
      "Itabaiana",
      "Guarabira",
      "Coremas",
      "Conde",
      "Campina Grande",
      "Cajazeiras",
      "Cabedelo",
      "Areia"
    ],
    Para: [
      "Abaetetuba",
      "Almeirim",
      "Augusto Correa",
      "Benevides",
      "Breves",
      "Cameta",
      "Conceição do Araguaia",
      "Curuca",
      "Itaituba",
      "Jacareacanga",
      "Juruti",
      "Mocajuba",
      "Monte Alegre",
      "Oriximina",
      "Salinopolis",
      "Santarém",
      "Sao Felix do Xingu",
      "Sao Miguel do Guama",
      "Soure",
      "Tome Acu",
      "Tucurui",
      "Vigia",
      "Paragominas",
      "Marabá",
      "Castanhal",
      "Capanema",
      "Belém",
      "Barcarena",
      "Ananindeua",
      "Altamira"
    ],
    "Minas Gerais": [
      "Abaete",
      "Acucena",
      "Aguas Formosas",
      "Aimores",
      "Alem Paraiba",
      "Almenara",
      "Alpinopolis",
      "Alvinopolis",
      "Areado",
      "Arinos",
      "Astolfo Dutra",
      "Baependi",
      "Bambui",
      "Belo Oriente",
      "Botelhos",
      "Bueno Brandao",
      "Caete",
      "Camanducaia",
      "Cambuquira",
      "Campos Altos",
      "Capitolio",
      "Carandai",
      "Carmo do Rio Claro",
      "Caxambu",
      "Conceicao do Mato Dentro",
      "Conceicao do Rio Verde",
      "Conselheiro Pena",
      "Coromandel",
      "Cruzilia",
      "Eloi Mendes",
      "Esmeraldas",
      "Frutal",
      "Guanhaes",
      "Guaranesia",
      "Guaxupe",
      "Ibirite",
      "Ipaba",
      "Itacarambi",
      "Itajuba",
      "Itamarandiba",
      "Itambacuri",
      "Itanhandu",
      "Itapecerica",
      "Itau de Minas",
      "Jacutinga",
      "Janauba",
      "Januaria",
      "Jequitinhonha",
      "Joao Monlevade",
      "Joao Pinheiro",
      "Juatuba",
      "Juiz de Fora",
      "Lagoa da Prata",
      "Lagoa Formosa",
      "Lajinha",
      "Leopoldina",
      "Liberdade",
      "Lima Duarte",
      "Manhuacu",
      "Manhumirim",
      "Mar de Espanha",
      "Martinho Campos",
      "Matias Barbosa",
      "Matipo",
      "Medina",
      "Monte Santo de Minas",
      "Monte Siao",
      "Nepomuceno",
      "Nova Era",
      "Papagaios",
      "Para de Minas",
      "Paraguacu",
      "Paraisopolis",
      "Passa Quatro",
      "Pedra Azul",
      "Perdoes",
      "Piui",
      "Poços de Caldas",
      "Pompeu",
      "Porteirinha",
      "Resplendor",
      "Ribeirão das Neves",
      "Rio Casca",
      "Rio Piracicaba",
      "Rio Pomba",
      "Rio Vermelho",
      "Sacramento",
      "Salinas",
      "Santa Rita do Sapucai",
      "Santa Vitoria",
      "Santo Antonio do Monte",
      "Sao Francisco",
      "São João del Rei",
      "Sao Sebastiao do Paraiso",
      "Serro",
      "Timoteo",
      "Tiradentes",
      "Tocantins",
      "Tres Pontas",
      "Tupaciguara",
      "Vicosa",
      "Visconde do Rio Branco",
      "Vespasiano",
      "Vazante",
      "Varginha",
      "Unai",
      "Uberlândia",
      "Uberaba",
      "Uba",
      "Três Corações",
      "Teófilo Otoni",
      "Taiobeiras",
      "Sete Lagoas",
      "Sarzedo",
      "São Lourenço",
      "Santos Dumont",
      "Santa Luzia",
      "Raposos",
      "Prata",
      "Pouso Alegre",
      "Ponte Nova",
      "Pitangui",
      "Pirapora",
      "Pedro Leopoldo",
      "Patrocinio",
      "Patos de Minas",
      "Passos",
      "Paracatu",
      "Ouro Preto",
      "Ouro Branco",
      "Oliveira",
      "Nova Lima",
      "Nanuque",
      "Muzambinho",
      "Mutum",
      "Muriaé",
      "Montes Claros",
      "Monte Carmelo",
      "Monte Azul",
      "Matozinhos",
      "Mateus Leme",
      "Mariana",
      "Machado",
      "Luz",
      "Lavras",
      "Lambari",
      "Lagoa Santa",
      "Iturama",
      "Ituiutaba",
      "Itauna",
      "Itabirito",
      "Itabira",
      "Ipatinga",
      "Inhapim",
      "Governador Valadares",
      "Formiga",
      "Extrema",
      "Espinosa",
      "Espera Feliz",
      "Divinópolis",
      "Diamantina",
      "Curvelo",
      "Coronel Fabriciano",
      "Corinto",
      "Contagem",
      "Conselheiro Lafaiete",
      "Congonhas",
      "Claudio",
      "Cataguases",
      "Cassia",
      "Caratinga",
      "Carangola",
      "Capelinha",
      "Campos Gerais",
      "Campo Belo",
      "Campina Verde",
      "Campestre",
      "Campanha",
      "Cambui",
      "Buritis",
      "Brumadinho",
      "Bom Sucesso",
      "Bom Despacho",
      "Bocaiuva",
      "Betim",
      "Belo Horizonte",
      "Barroso",
      "Barbacena",
      "Arcos",
      "Araxá",
      "Araguari",
      "Andradas",
      "Alfenas"
    ],
    "Mato Grosso do Sul": [
      "Aparecida do Taboado",
      "Aquidauana",
      "Bela Vista",
      "Camapua",
      "Cassilandia",
      "Eldorado",
      "Ladario",
      "Miranda",
      "Navirai",
      "Paranaiba",
      "Sidrolandia",
      "Três Lagoas",
      "Rio Brilhante",
      "Ponta Porã",
      "Maracaju",
      "Jardim",
      "Dourados",
      "Coxim",
      "Corumbá",
      "Campo Verde",
      "Campo Grande",
      "Bonito"
    ],
    "Mato Grosso": [
      "Alta Floresta",
      "Alto Araguaia",
      "Barra do Bugres",
      "Barra do Garcas",
      "Chapada dos Guimaraes",
      "Diamantino",
      "Jaciara",
      "Nobres",
      "Nova Olimpia",
      "Pocone",
      "Pontes e Lacerda",
      "Poxoreo",
      "Várzea Grande",
      "Sinop",
      "Rondonópolis",
      "Nova Xavantina",
      "Cuiabá"
    ],
    Maranhao: [
      "Balsas",
      "Barreirinhas",
      "Carolina",
      "Codó",
      "Coroata",
      "Cururupu",
      "Estreito",
      "Grajau",
      "Humberto de Campos",
      "Itapecuru Mirim",
      "Mirador",
      "Morros",
      "Pastos Bons",
      "Pio XII",
      "Porto Franco",
      "Presidente Dutra",
      "Santa Ines",
      "Sao Bento",
      "Sao Jose de Ribamar",
      "Sao Raimundo das Mangabeiras",
      "Tuntum",
      "Tutoia",
      "Vargem Grande",
      "Timon",
      "São Luís",
      "Pinheiro",
      "Imperatriz",
      "Dom Pedro",
      "Colinas",
      "Chapadinha",
      "Caxias",
      "Bacabal"
    ],
    Goias: [
      "Aparecida de Goiania",
      "Cacu",
      "Campos Belos",
      "Catalao",
      "Cavalcante",
      "Formosa",
      "Goianesia",
      "Goianira",
      "Guapo",
      "Ipameri",
      "Ipora",
      "Itaberai",
      "Itapaci",
      "Morrinhos",
      "Neropolis",
      "Padre Bernardo",
      "Palmeiras de Goias",
      "Piracanjuba",
      "Pirenopolis",
      "Planaltina",
      "Quirinopolis",
      "Rubiataba",
      "Santa Helena de Goias",
      "Sao Luis de Montes Belos",
      "Silvania",
      "Uruacu",
      "Uruana",
      "Trindade",
      "Senador Canedo",
      "Posse",
      "Porangatu",
      "Pontalina",
      "Mineiros",
      "Luziania",
      "Jatai",
      "Jaragua",
      "Itumbiara",
      "Itapuranga",
      "Inhumas",
      "Goiatuba",
      "Goias",
      "Goiânia",
      "Crixas",
      "Cristalina",
      "Caldas Novas",
      "Anicuns",
      "Anápolis"
    ],
    "Federal District": ["Brasília"],
    "Espirito Santo": [
      "Afonso Claudio",
      "Aguia Branca",
      "Baixo Guandu",
      "Cachoeiro de Itapemirim",
      "Conceicao da Barra",
      "Domingos Martins",
      "Ecoporanga",
      "Ibatiba",
      "Iuna",
      "Jetiba",
      "Marataizes",
      "Marechal Floriano",
      "Mimoso do Sul",
      "Piuma",
      "Vitória",
      "Vila Velha",
      "Viana",
      "Serra",
      "São Mateus",
      "Nova Venecia",
      "Montanha",
      "Linhares",
      "Itapemirim",
      "Guarapari",
      "Colatina",
      "Castelo",
      "Aracruz",
      "Alegre"
    ],
    Ceara: [
      "Amontada",
      "Aquiraz",
      "Assare",
      "Aurora",
      "Barroquinha",
      "Baturite",
      "Beberibe",
      "Bela Cruz",
      "Brejo Santo",
      "Cambebba",
      "Campos Sales",
      "Caninde",
      "Caririacu",
      "Cascavel",
      "Cedro",
      "Crateus",
      "Cruz",
      "Forquilha",
      "Guaiuba",
      "Hidrolandia",
      "Ipu",
      "Jaguaribe",
      "Juazeiro do Norte",
      "Jucas",
      "Maracanau",
      "Missao Velha",
      "Mombaca",
      "Nova Russas",
      "Oros",
      "Pacatuba",
      "Paraipaba",
      "Parambu",
      "Quixada",
      "Reriutaba",
      "Russas",
      "Santa Quiteria",
      "Sao Goncalo do Amarante",
      "Tiangua",
      "Trairi",
      "Ubajara",
      "Varjota",
      "Sobral",
      "Senador Pompeu",
      "Quixeramobim",
      "Pentecoste",
      "Pedra Branca",
      "Paracuru",
      "Pacajus",
      "Novo Oriente",
      "Morada Nova",
      "Milagres",
      "Marco",
      "Jaguaruana",
      "Itapipoca",
      "Itaitinga",
      "Independencia",
      "Iguatu",
      "Ico",
      "Horizonte",
      "Granja",
      "Fortaleza",
      "Eusebio",
      "Crato",
      "Caucaia",
      "Camocim",
      "Boa Viagem",
      "Barbalha",
      "Aracoiaba",
      "Aracati",
      "Acopiara",
      "Acarau"
    ],
    Bahia: [
      "Amargosa",
      "Araci",
      "Bom Jesus da Lapa",
      "Buerarema",
      "Caetite",
      "Cafarnaum",
      "Camacari",
      "Campo Formoso",
      "Canarana",
      "Canavieiras",
      "Capim Grosso",
      "Carinhanha",
      "Catu",
      "Cicero Dantas",
      "Cipo",
      "Coaraci",
      "Conceicao do Coite",
      "Conde",
      "Correntina",
      "Cruz das Almas",
      "Entre Rios",
      "Euclides da Cunha",
      "Eunapolis",
      "Gandu",
      "Guaratinga",
      "Ibicui",
      "Ibirataia",
      "Ibotirama",
      "Iguai",
      "Ipiau",
      "Ipira",
      "Iraquara",
      "Irece",
      "Itaberaba",
      "Itacare",
      "Itajuipe",
      "Itambe",
      "Itarantim",
      "Itororo",
      "Jaguarari",
      "Jitauna",
      "Lauro de Freitas",
      "Livramento do Brumado",
      "Macaubas",
      "Madre de Deus",
      "Maragogipe",
      "Marau",
      "Mata de Sao Joao",
      "Medeiros Neto",
      "Miguel Calmon",
      "Mucuri",
      "Mundo Novo",
      "Muritiba",
      "Mutuipe",
      "Nazare",
      "Nova Soure",
      "Nova Vicosa",
      "Olindina",
      "Paratinga",
      "Paripiranga",
      "Pau Brasil",
      "Piritiba",
      "Queimadas",
      "Quijingue",
      "Riacho de Santana",
      "Ribeira do Pombal",
      "Ruy Barbosa",
      "Santa Cruz Cabralia",
      "Santaluz",
      "Santo Antonio de Jesus",
      "Saubara",
      "Seabra",
      "Senhor do Bonfim",
      "Simoes Filho",
      "Taperoa",
      "Tremedal",
      "Ubaitaba",
      "Una",
      "Vitória da Conquista",
      "Xique Xique",
      "Vera Cruz",
      "Valente",
      "Valença",
      "Tucano",
      "Serrinha",
      "Santo Amaro",
      "Salvador",
      "Prado",
      "Porto Seguro",
      "Paulo Afonso",
      "Mascote",
      "Maracas",
      "Jeremoabo",
      "Jequié",
      "Jaguaquara",
      "Jacobina",
      "Ituberá",
      "Itapetinga",
      "Itaparica",
      "Itamaraju",
      "Itabuna",
      "Ilhéus",
      "Guanambi",
      "Feira de Santana",
      "Esplanada",
      "Castro Alves",
      "Camamu",
      "Camaçari",
      "Cachoeira",
      "Brumado",
      "Barreiras",
      "Barra",
      "Alagoinhas"
    ],
    Amazonas: [
      "Barcelos",
      "Iranduba",
      "Itacoatiara",
      "Manacapuru",
      "Rio Preto da Eva",
      "Sao Gabriel da Cachoeira",
      "Tabatinga",
      "Tefe",
      "Parintins",
      "Manaus",
      "Coari"
    ],
    Amapa: ["Santana", "Macapá"],
    Alagoas: [
      "Barra de Santo Antonio",
      "Cajueiro",
      "Capela",
      "Coite do Noia",
      "Igaci",
      "Lagoa da Canoa",
      "Maribondo",
      "Matriz de Camaragibe",
      "Messias",
      "Pao de Acucar",
      "Pilar",
      "Porto Calvo",
      "Porto Real do Colegio",
      "Santana do Ipanema",
      "Sao Jose da Tapera",
      "Sao Miguel dos Campos",
      "Satuba",
      "Traipu",
      "Rio Largo",
      "Penedo",
      "Murici",
      "Marechal Deodoro",
      "Maragogi",
      "Maceió",
      "Delmiro Gouveia",
      "Coruripe",
      "Campo Alegre",
      "Batalha",
      "Atalaia",
      "Arapiraca"
    ],
    Acre: ["Brasileia", "Cruzeiro do Sul", "Feijó", "Tarauacá", "Rio Branco"]
  },
  Botswana: {
    Central: ["Gweta", "Letlhakane", "Mathathane", "Palapye", "Selebi-Phikwe"],
    "Central District": ["Serowe"],
    Gaborone: ["Gaborone"],
    "Kgatleng District": ["Mochudi"],
    Kweneng: ["Mogoditshane"],
    "Kweneng District": ["Molepolole"],
    Lobatse: ["Lobatse"],
    "North-West": ["Kasane", "Maun"],
    "South East": ["Gaborone"],
    Southern: ["Khakhea"],
    "Sowa Town": ["Palapye"],
    Ngwaketsi: ["Kanye"]
  },
  "Bosnia and Herzegovina": {
    Brčko: ["Brcko"],
    "Federation of Bosnia and Herzegovina": [
      "Bosanska Krupa",
      "Bugojno",
      "Cazin",
      "Gromiljak",
      "Ilijas",
      "Kobilja Glava",
      "Konjic",
      "Mostar",
      "Neum",
      "Sarajevo",
      "Srebrenik",
      "Travnik",
      "Tuzla",
      "Visoko",
      "Zenica",
      "Zivinice"
    ],
    "Republika Srpska": [
      "Bijeljina",
      "Doboj",
      "Gradiska",
      "Mrkonjic Grad",
      "Popovi",
      "Sipovo",
      "Srebrenica",
      "Teslic",
      "Ugljevik",
      "Trebinje",
      "Prijedor",
      "Foca",
      "Banja Luka"
    ],
    "Federation of B&H": [
      "Bihać",
      "Cazin",
      "Goražde",
      "Gracanica",
      "Gradacac",
      "Kakanj",
      "Konjic",
      "Odzak",
      "Sanski Most",
      "Velika Kladuša",
      "Visoko",
      "Zavidovici",
      "Zenica",
      "Tuzla",
      "Travnik",
      "Sarajevo",
      "Mostar",
      "Bugojno"
    ]
  },
  Mali: {
    "Bamako Region": ["Bamako"],
    Gao: ["Gao"],
    Kayes: ["Kayes"],
    Kidal: ["Kidal"],
    Mopti: [],
    Tombouctou: ["Timbuktu"],
    Koulikoro: ["Koulikoro", "Kati"]
  },
  Macao: { Macau: ["Macau"], "": ["Macao"] },
  Bolivia: {
    Chuquisaca: ["Sucre"],
    Cochabamba: ["Arani", "Chimore", "Cochabamba", "Colomi"],
    "Departamento de Pando": ["Cobija"],
    "Departamento de Potosi": ["Potosí"],
    "Departamento de Santa Cruz": ["Santa Cruz"],
    "La Paz": ["La Paz", "Mapiri"],
    Oruro: ["Oruro"],
    Pando: ["Cobija"],
    Potosi: ["Uyuni"],
    "Santa Cruz": [
      "Camiri",
      "Cotoca",
      "San Ignacio de Velasco",
      "San Matias",
      "Santa Cruz de la Sierra",
      "Santiago del Torno"
    ],
    Tarija: ["Tarija"],
    "El Beni": ["Riberalta", "San Borja", "Trinidad"],
    "Departamento de Tarija": ["Tarija"],
    "Departamento de La Paz": ["La Paz"],
    "Departamento de Cochabamba": ["Cochabamba"]
  },
  Bermuda: { "Saint George": ["Saint George"], "Hamilton city": ["Hamilton"] },
  Benin: {
    Alibori: ["Kandi"],
    Atlantique: ["Abomey-Calavi", "Ouidah"],
    "Atlantique Department": ["Ouidah"],
    "Borgou Department": ["Parakou"],
    Donga: [],
    Plateau: [],
    Queme: ["Porto-Novo"],
    Zou: ["Bohicon"],
    Mono: [],
    Littoral: ["Cotonou"],
    "Collines Department": ["Savé", "Come"]
  },
  Maldives: {
    "Baa Atholhu": ["Eydhafushi"],
    "Dhaalu Atholhu": ["Kudahuvadhoo"],
    "Gaafu Dhaalu Atholhu": ["Thinadhoo"],
    "Gnyaviyani Atoll": ["Fuvahmulah"],
    Laamu: ["Fonadhoo"],
    "Lhaviyani Atholhu": ["Naifaru"],
    Maale: ["Male"],
    "Noonu Atoll": ["Manadhoo"],
    "North Central": ["Mahibadhoo"],
    "Raa Atoll": ["Ugoofaaru"],
    Seenu: ["Meedhoo"],
    "South Province": ["Hithadhoo"],
    "Upper North": ["Dhidhdhoo"],
    "Vaavu Atholhu": ["Felidhoo"],
    "Kaafu Atoll": ["Malé"]
  },
  Malaysia: {
    Terengganu: [
      "Cukai",
      "Jertih",
      "Kertih",
      "Marang",
      "Paka",
      "Kuala Terengganu"
    ],
    Selangor: [
      "Banting",
      "Batang Berjuntai",
      "Batu Arang",
      "Jenjarum",
      "Kampong Baharu Balakong",
      "Kampung Baru Subang",
      "Kampung Tanjung Karang",
      "Kuala Selangor",
      "Kuang",
      "Petaling Jaya",
      "Port Klang",
      "Sabak Bernam",
      "Semenyih",
      "Sungai Besar",
      "Tanjung Sepat",
      "Subang Jaya",
      "Shah Alam",
      "Serendah",
      "Sepang",
      "Rawang",
      "Klang"
    ],
    Sarawak: [
      "Bintulu",
      "Data Kakus",
      "Kapit",
      "Limbang",
      "Marabu",
      "Miri",
      "Sarikei",
      "Sibu",
      "Simanggang",
      "Kuching"
    ],
    Sabah: [
      "Donggongon",
      "Kinarut",
      "Kota Kinabalu",
      "Kuala",
      "Kudat",
      "Putatan",
      "Ranau",
      "Semporna",
      "Tawau",
      "Sandakan",
      "Papar",
      "Lahad Datu",
      "Keningau",
      "Beaufort"
    ],
    Putrajaya: ["Putrajaya"],
    Perlis: ["Kangar", "Kuala Perlis"],
    Perak: [
      "Bagan Serai",
      "Batu Gajah",
      "Bidur",
      "Kampar",
      "Kampong Dungun",
      "Kuala Kangsar",
      "Lumut",
      "Pantai Remis",
      "Parit Buntar",
      "Simpang Empat",
      "Tapah Road",
      "Teluk Intan",
      "Taiping",
      "Ipoh"
    ],
    Penang: [
      "Bukit Mertajam",
      "Juru",
      "Kampung Sungai Ara",
      "Kepala Batas",
      "Nibong Tebal",
      "Perai",
      "Permatang Kuching",
      "Tanjung Tokong",
      "Tasek Glugor",
      "George Town",
      "Butterworth"
    ],
    Pahang: [
      "Bentong Town",
      "Kampung Bukit Tinggi, Bentong",
      "Kampung Tekek",
      "Kuala Lipis",
      "Tanah Rata",
      "Temerluh",
      "Raub",
      "Pekan",
      "Kuantan",
      "Jerantut"
    ],
    "Negeri Sembilan": [
      "Bahau",
      "Kampung Baharu Nilai",
      "Kuala Pilah",
      "Tampin",
      "Seremban",
      "Port Dickson"
    ],
    Melaka: [
      "Alor Gajah",
      "Batu Berendam",
      "Bemban",
      "Bukit Rambai",
      "Kampong Masjid Tanah",
      "Kampung Ayer Keroh",
      "Kampung Ayer Molek",
      "Kampung Bukit Baharu",
      "Klebang Besar",
      "Pulau Sebang",
      "Sungai Udang",
      "Malacca"
    ],
    Labuan: ["Victoria", "Labuan"],
    "Kuala Lumpur": ["Kuala Lumpur"],
    Kelantan: [
      "Gua Musang",
      "Kampong Kadok",
      "Kampong Pangkal Kalong",
      "Ketereh",
      "Kota Bharu",
      "Pasir Mas",
      "Peringat",
      "Tanah Merah",
      "Tumpat"
    ],
    Kedah: [
      "Alor Setar",
      "Alor Star",
      "Bedong",
      "Gurun",
      "Kuala Kedah",
      "Kulim",
      "Sungai Petani",
      "Kuah",
      "Jitra"
    ],
    Johor: [
      "Bakri",
      "Buloh Kasap",
      "Kampung Pasir Gudang Baru",
      "Kelapa Sawit",
      "Kluang",
      "Kota Tinggi",
      "Kulai",
      "Labis",
      "Mersing",
      "Muar",
      "Parit Raja",
      "Pekan Nenas",
      "Pontian Kechil",
      "Skudai",
      "Taman Senai",
      "Tangkak",
      "Ulu Tiram",
      "Yong Peng",
      "Segamat",
      "Johor Bahru",
      "Batu Pahat"
    ]
  },
  Belize: {
    Belize: ["Belize City", "San Pedro"],
    "Belize District": ["Belize City"],
    Cayo: ["Belmopan", "Benque Viejo del Carmen", "San Ignacio"],
    "Orange Walk District": ["Orange Walk"],
    "Stann Creek District": ["Dangriga", "Placencia"],
    "Cayo District": ["Belmopan", "San Ignacio"]
  },
  Belgium: {
    Wallonia: [
      "Amay",
      "Andenne",
      "Ans",
      "Arlon",
      "Bastogne",
      "Binche",
      "Boussu",
      "Braine-l'Alleud",
      "Charleroi",
      "Chasse Royale",
      "Chatelet",
      "Chaudfontaine",
      "Ciney",
      "Colfontaine",
      "Courcelles",
      "Couvin",
      "Dinant",
      "Dour",
      "Durbuy",
      "Enghien",
      "Eupen",
      "Farciennes",
      "Fleron",
      "Fleurus",
      "Florenville",
      "Frasnes-lez-Anvaing",
      "Gembloux",
      "Gerpinnes",
      "Grez-Doiceau",
      "Hamoir",
      "Hannut",
      "Herstal",
      "Herve",
      "Huy",
      "Jodoigne",
      "Lens",
      "Louvain-la-Neuve",
      "Marche-en-Famenne",
      "Mettet",
      "Mouscron",
      "Nivelles",
      "Quaregnon",
      "Rixensart",
      "Saint-Ghislain",
      "Saint-Nicolas",
      "Seraing",
      "Soy",
      "Sprimont",
      "Stavelot",
      "Theux",
      "Thuin",
      "Tintigny",
      "Tournai",
      "Trooz",
      "Tubize",
      "Verviers",
      "Virton",
      "Vise",
      "Walhain-Saint-Paul",
      "Wanze",
      "Waremme",
      "Waterloo",
      "Wavre",
      "Spa",
      "Namur",
      "Mons",
      "Manage",
      "Malmedy",
      "Liège",
      "La Louvière",
      "Ath"
    ],
    Flanders: [
      "Aalst",
      "Aalter",
      "Aarschot",
      "Aartselaar",
      "Alken",
      "Antwerpen",
      "Anzegem",
      "Arendonk",
      "As",
      "Asse",
      "Assenede",
      "Beernem",
      "Beerse",
      "Beersel",
      "Bekkevoort",
      "Beringen",
      "Berlaar",
      "Berlare",
      "Bever",
      "Beveren",
      "Bilzen",
      "Blankenberge",
      "Bocholt",
      "Boechout",
      "Bonheiden",
      "Bornem",
      "Borsbeek",
      "Brasschaat",
      "Brecht",
      "Bredene",
      "Brugge",
      "Buggenhout",
      "Damme",
      "Deerlijk",
      "De Haan",
      "Deinze",
      "Denderleeuw",
      "Dendermonde",
      "De Pinte",
      "Dessel",
      "Destelbergen",
      "Diegem",
      "Diepenbeek",
      "Diest",
      "Diksmuide",
      "Dilbeek",
      "Drogenbos",
      "Duffel",
      "Edegem",
      "Eeklo",
      "Essen",
      "Evergem",
      "Gavere",
      "Genk",
      "Gent",
      "Geraardsbergen",
      "Gingelom",
      "Gistel",
      "Grimbergen",
      "Grobbendonk",
      "Haacht",
      "Haaltert",
      "Halle",
      "Hamme",
      "Harelbeke",
      "Helchteren",
      "Herent",
      "Herentals",
      "Herselt",
      "Herzele",
      "Heusden",
      "Hoboken",
      "Hoeilaart",
      "Hoogstraten",
      "Houthalen",
      "Ichtegem",
      "Ieper",
      "Ingelmunster",
      "Izegem",
      "Jabbeke",
      "Kalmthout",
      "Kampenhout",
      "Kapellen",
      "Kasterlee",
      "Keerbergen",
      "Kinrooi",
      "Knokke-Heist",
      "Koksijde",
      "Kortemark",
      "Kortenberg",
      "Kortrijk",
      "Kraainem",
      "Kruibeke",
      "Kuurne",
      "Laarne",
      "Lanaken",
      "Lebbeke",
      "Lede",
      "Ledeberg",
      "Leopoldsburg",
      "Lille",
      "Lochristi",
      "Lommel",
      "Londerzeel",
      "Lubbeek",
      "Lummen",
      "Maaseik",
      "Maasmechelen",
      "Machelen",
      "Maldegem",
      "Mechelen",
      "Meise",
      "Menen",
      "Merchtem",
      "Merelbeke",
      "Meulebeke",
      "Middelkerke",
      "Moorslede",
      "Mortsel",
      "Nazareth",
      "Neerpelt",
      "Nevele",
      "Nieuwpoort",
      "Nijlen",
      "Ninove",
      "Olen",
      "Oosterzele",
      "Oostkamp",
      "Opwijk",
      "Oudenaarde",
      "Overijse",
      "Overpelt",
      "Pittem",
      "Poperinge",
      "Puurs",
      "Ranst",
      "Ravels",
      "Retie",
      "Riemst",
      "Rijkevorsel",
      "Ronse",
      "Rotselaar",
      "Rumst",
      "Scherpenheuvel-Zichem",
      "Schilde",
      "Schoten",
      "Sint-Genesius-Rode",
      "Sint-Gillis-Waas",
      "Sint-Katelijne-Waver",
      "Sint-Kruis",
      "Sint-Niklaas",
      "Sint-Pieters-Leeuw",
      "Sint-Truiden",
      "Stabroek",
      "Staden",
      "Steenokkerzeel",
      "Stekene",
      "Temse",
      "Ternat",
      "Tessenderlo",
      "Tielt",
      "Tienen",
      "Tongeren",
      "Tremelo",
      "Turnhout",
      "Veurne",
      "Vilvoorde",
      "Vorselaar",
      "Vosselaar",
      "Waasmunster",
      "Waregem",
      "Wemmel",
      "Wervik",
      "Westerlo",
      "Wetteren",
      "Wevelgem",
      "Wichelen",
      "Wingene",
      "Wommelgem",
      "Wuustwezel",
      "Ypres",
      "Zandhoven",
      "Zaventem",
      "Zedelgem",
      "Zeebrugge",
      "Zele",
      "Zelzate",
      "Zemst",
      "Zoersel",
      "Zottegem",
      "Zuienkerke",
      "Zulte",
      "Zwevegem",
      "Zwijndrecht",
      "Zonhoven",
      "Willebroek",
      "Torhout",
      "Tervuren",
      "Roeselare",
      "Putte",
      "Peer",
      "Ostend",
      "Mol",
      "Lokeren",
      "Lier",
      "Liedekerke",
      "Leuven",
      "Landen",
      "Kontich",
      "Hasselt",
      "Ghent",
      "Geel",
      "Bruges",
      "Bree",
      "Boom",
      "Balen",
      "Antwerp"
    ],
    "Brussels Capital": ["Brussels"]
  },
  Malawi: {
    "Central Region": ["Kasungu", "Lilongwe"],
    "Southern Region": ["Blantyre", "Mangochi", "Mulanje", "Nsanje"]
  },
  Belarus: {
    Gomel: ["Gomel", "Kalinkavichy", "Mazyr", "Zhlobin"],
    "Minsk City": [],
    Vitebsk: [
      "Hlybokaye",
      "Navapolatsk",
      "Orsha",
      "Pastavy",
      "Polatsk",
      "Vitebsk"
    ],
    Mogilev: ["Babruysk", "Horki", "Mahilyow", "Mogilev"],
    Minsk: [
      "Borovlyany",
      "Fanipol",
      "Kalodzishchy",
      "Khatsyezhyna",
      "Maladzyechna",
      "Malinovka",
      "Mar''ina Horka",
      "Novoye Medvezhino",
      "Nyasvizh",
      "Salihorsk",
      "Slutsk",
      "Stowbtsy",
      "Syenitsa",
      "Vilyeyka",
      "Vyaliki Trastsyanets",
      "Zhdanovichy",
      "Minsk",
      "Borisov"
    ],
    Grodnenskaya: ["Hrodna", "Masty", "Navahrudak", "Slonim", "Lida"],
    Brest: ["Baranovichi", "Byaroza", "Kobryn", "Pinsk", "Brest"]
  },
  Barbados: {
    "Christ Church": ["Oistins"],
    "Saint James": ["Holetown"],
    "Saint Michael": ["Bridgetown"],
    "Saint Peter": ["Speightstown"],
    "Saint Thomas": ["Welchman Hall"]
  },
  Bangladesh: {
    "Barisal Division": ["Barisal"],
    Dhaka: ["Azimpur", "Dhaka", "Narsingdi", "Paltan", "Tungi"],
    Khulna: ["Jessore", "Kesabpur", "Sarankhola"],
    "Khulna Division": ["Jessore", "Khulna"],
    "Mymensingh Division": ["Mymensingh"],
    Rajshahi: ["Rajshahi"],
    "Rajshahi Division": ["Bogra", "Pabna", "Rajshahi"],
    "Rangpur Division": ["Dinajpur", "Rangpur"],
    Sylhet: ["Baniachang", "Habiganj", "Sylhet"],
    "Sylhet Division": ["Habiganj", "Sylhet"],
    "Dhaka Division": ["Narayanganj", "Tangail", "Dhaka"],
    Chittagong: [
      "Chittagong",
      "Comilla",
      "Cox's Bazar",
      "Fatikchari",
      "Teknaf",
      "Feni"
    ]
  },
  Bahrain: {
    "Central Governorate": ["Dar Kulayb", "Madinat Hamad"],
    Manama: ["Jidd Hafs", "Manama", "Sitrah"],
    "Southern Governorate": [],
    Northern: ["Ar Rifa'", "Madinat `Isa", "Sitrah"],
    Muharraq: ["Al Hadd", "Al Muharraq"]
  },
  Bahamas: {
    Bimini: ["Alice Town"],
    "Cat Island": ["Arthur's Town"],
    "Central Abaco District": ["Marsh Harbour"],
    "Central Eleuthera District": ["Governor's Harbour"],
    "City of Freeport District": ["Freeport"],
    Exuma: ["George Town"],
    Freeport: ["Freeport", "Lucaya"],
    "Long Island": ["Clarence Town"],
    "Mayaguana District": ["Abraham's Bay"],
    "North Andros District": ["Andros Town"],
    "West Grand Bahama District": ["West End"],
    "New Providence District": ["Nassau"],
    "Harbour Island": ["Dunmore Town"]
  },
  Madagascar: {
    "": ["Antananarivo"],
    "Amoron'i Mania": ["Ambatofinandrahana", "Ambositra"],
    Analamanga: ["Antananarivo"],
    Analanjirofo: ["Ambodifotatra", "Soanierana Ivongo"],
    Anosy: ["Ambalasoa"],
    "Atsimo-Andrefana": ["Ankazoabo", "Toliara"],
    "Atsimo-Atsinanana": [
      "Farafangana",
      "Vangaindrano",
      "Vohipaho",
      "Vondrozo"
    ],
    Atsinanana: ["Toamasina"],
    Boeny: ["Mahajanga"],
    Diana: ["Antsiranana", "Antsohimbondrona", "Hell-Ville"],
    Ihorombe: ["Ihosy"],
    Itasy: ["Arivonimamo"],
    Melaky: ["Maintirano"],
    Vakinankaratra: ["Antsirabe"],
    "Vatovavy Fitovinany": ["Ifanadiana", "Ikongo", "Manakara", "Mananjary"]
  },
  Azerbaijan: {
    "Aghstafa Rayon": ["Ağstafa", "Saloglu"],
    Astara: ["Astara"],
    Baki: [
      "Amirdzhan",
      "Badamdar",
      "Bakixanov",
      "Baku",
      "Bilajari",
      "Bilajer",
      "Biny Selo",
      "Buzovna",
      "Khodzhi-Gasan",
      "Mardakyany",
      "Nardaran",
      "Qaracuxur",
      "Sabuncu",
      "Zabrat"
    ],
    "Gadabay Rayon": ["Ariqiran"],
    "Goygol Rayon": ["Yelenendorf"],
    Ismayilli: ["Basqal"],
    Jalilabad: ["Dzhalilabad"],
    "Mingacevir City": ["Mingelchaur"],
    Nakhichevan: ["Culfa", "Nakhchivan"],
    Oguz: ["Oguz"],
    "Qabala Rayon": ["Qutqashen"],
    "Qakh Rayon": ["Qaxbas"],
    Qazax: ["Qazax"],
    Quba: ["Hacihuseynli", "Quba"],
    Qusar: ["Qusar"],
    "Qusar Rayon": ["Qusar"],
    Sabirabad: ["Sabirabad"],
    Salyan: ["Salyan"],
    "Shaki City": ["Sheki"],
    Shirvan: [],
    Xacmaz: ["Xudat"],
    "Yevlax City": ["Yevlakh"],
    Zaqatala: ["Zaqatala"],
    Zardab: ["Zardob"],
    "Sumqayit City": ["Corat", "Sumqayit"],
    "Ganja City": ["Ganja"],
    "Baku City": ["Baku"],
    "Absheron Rayon": ["Digah", "Khirdalan", "Saray", "Xirdalan"]
  },
  "Macedonia, Republic of": {
    Bitola: ["Bitola"],
    Debar: ["Debar"],
    Gevgelija: ["Gevgelija"],
    Gostivar: ["Gostivar"],
    Karpos: ["Skopje"],
    Kavadarci: ["Kavadarci"],
    Kicevo: ["Kicevo"],
    Kumanovo: ["Kumanovo"],
    Negotino: ["Negotino"],
    Ohrid: ["Ohrid"],
    "Opstina Kicevo": ["Kicevo"],
    "Opstina Radovis": ["Radovis"],
    Prilep: ["Prilep"],
    Probistip: ["Zletovo"],
    Resen: ["Resen"],
    Struga: ["Struga"],
    Strumica: ["Strumica"],
    Tetovo: ["Tetovo"],
    Vinica: ["Vinica"],
    Veles: ["Gorno Orizari", "Veles"],
    "Opstina Stip": ["Shtip"],
    "Opstina Karpos": ["Skopje"]
  },
  Austria: {
    Burgenland: [
      "Bad Tatzmannsdorf",
      "Eisenstadt",
      "Illmitz",
      "Nickelsdorf",
      "Parndorf"
    ],
    "Lower Austria": [
      "Amstetten",
      "Baden",
      "Ebreichsdorf",
      "Fischamend Dorf",
      "Hollabrunn",
      "Horn",
      "Kaltenleutgeben",
      "Kirchberg am Walde",
      "Klosterneuburg",
      "Korneuburg",
      "Krems an der Donau",
      "Langenlois",
      "Langenzersdorf",
      "Maria Enzersdorf",
      "Mödling",
      "Perchtoldsdorf",
      "Purkersdorf",
      "Sankt Poelten",
      "Sankt Pölten",
      "Stockerau",
      "Stossing",
      "Strengberg",
      "Ternitz",
      "Traiskirchen",
      "Traismauer",
      "Tulln",
      "Weinzierl bei Krems",
      "Wiener Neustadt",
      "Schwechat",
      "Krems"
    ],
    Vorarlberg: [
      "Bezau",
      "Bludenz",
      "Brand",
      "Damuels",
      "Dornbirn",
      "Feldkirch",
      "Hohenems",
      "Kennelbach",
      "Lech",
      "Lustenau",
      "Rankweil",
      "Schnepfau",
      "Hard",
      "Bregenz"
    ],
    Vienna: ["Vienna"],
    "Upper Austria": [
      "Asten",
      "Bad Goisern",
      "Bad Ischl",
      "Bad Kreuzen",
      "Ebensee",
      "Enns",
      "Gmunden",
      "Gruenau im Almtal",
      "Leonding",
      "Marchtrenk",
      "Ried im Innkreis",
      "Rossleithen",
      "Sankt Ulrich bei Steyr",
      "Seewalchen",
      "Steyr",
      "Traun",
      "Vocklabruck",
      "Vorchdorf",
      "Wels",
      "Linz"
    ],
    Tyrol: [
      "Achenkirch",
      "Bad Haering",
      "Ebbs",
      "Ehrwald",
      "Ellmau",
      "Fieberbrunn",
      "Fiss",
      "Forchach",
      "Fugen",
      "Ischgl",
      "Jerzens",
      "Jochberg",
      "Kirchberg in Tirol",
      "Kitzbühel",
      "Leutasch",
      "Lienz",
      "Mayrhofen",
      "Natters",
      "Nauders",
      "Neustift im Stubaital",
      "Rietz",
      "Rum",
      "Sankt Anton am Arlberg",
      "Sillian",
      "Soelden",
      "Stams",
      "Steinach am Brenner",
      "Telfes im Stubai",
      "Telfs",
      "Vils",
      "Vomp",
      "Walchsee",
      "Wattenberg",
      "Wörgl",
      "Schwaz",
      "Kufstein",
      "Innsbruck"
    ],
    Styria: [
      "Admont",
      "Bad Aussee",
      "Eisenerz",
      "Flatschach",
      "Gaal",
      "Kapfenberg",
      "Knittelfeld",
      "Leoben",
      "Loipersdorf bei Fuerstenfeld",
      "Sankt Georgen ob Judenburg",
      "Schladming",
      "Spielberg bei Knittelfeld",
      "Trofaiach",
      "Graz"
    ],
    Salzburg: [
      "Ansfelden",
      "Bad Gastein",
      "Bramberg am Wildkogel",
      "Bruck an der Grossglocknerstrasse",
      "Filzmoos",
      "Flachau",
      "Fuschl am See",
      "Hallein",
      "Hallwang",
      "Hof bei Salzburg",
      "Hollersbach im Pinzgau",
      "Huttschlag",
      "Kaprun",
      "Leogang",
      "Lofer",
      "Mittersill",
      "Neukirchen am Grossvenediger",
      "Plainfeld",
      "Saalbach",
      "Saalfelden am Steinernen Meer",
      "Sankt Johann im Pongau",
      "Untertauern",
      "Werfenweng",
      "Zell am See",
      "Salzburg"
    ],
    Carinthia: [
      "Bleiburg/Pliberk",
      "Ebenthal",
      "Feistritz ob Bleiburg",
      "Feld am See",
      "Heiligenblut",
      "Klagenfurt",
      "Klagenfurt am Woerthersee",
      "Kleinkirchheim",
      "Liebenfels",
      "Maria Worth",
      "Neuhaus",
      "Spittal an der Drau",
      "Velden am Woerthersee",
      "Villach",
      "Wolfsberg"
    ]
  },
  Australia: {
    "Western Australia": [
      "Armadale",
      "Augusta",
      "Ballajura",
      "Bellevue",
      "Belmont",
      "Bridgetown",
      "Burswood",
      "Canning Vale",
      "Carnarvon",
      "City Beach",
      "Claremont",
      "Como",
      "Coogee",
      "Coolbinia",
      "Cottesloe",
      "Crawley",
      "Dampier",
      "Denmark",
      "Derby",
      "Dianella",
      "Duncraig",
      "Dunsborough",
      "East Perth",
      "Eden Hill",
      "Embleton",
      "Esperance",
      "Fremantle",
      "Geraldton",
      "Gosnells",
      "Grasmere",
      "Guildford",
      "Halls Creek",
      "Henley Brook",
      "Hillarys",
      "Iluka",
      "Innaloo",
      "Joondalup",
      "Jurien Bay",
      "Kalbarri",
      "Kalgoorlie",
      "Kallaroo",
      "Kendenup",
      "Kununurra",
      "Kwinana",
      "Mandurah",
      "Manjimup",
      "Margaret River",
      "Maylands",
      "Merredin",
      "Mindarie",
      "Mosman Park",
      "Mount Lawley",
      "Myaree",
      "Narrogin",
      "Nedlands",
      "Newman",
      "North Fremantle",
      "Osborne Park",
      "Port Hedland",
      "Port Kennedy",
      "Ravensthorpe",
      "Scarborough",
      "Shenton Park",
      "Shoalwater",
      "South Fremantle",
      "South Lake",
      "South Perth",
      "Spearwood",
      "Subiaco",
      "Swan View",
      "The Vines",
      "Thornlie",
      "Tom Price",
      "Trigg",
      "Two Rocks",
      "Victoria Park",
      "Wanneroo",
      "Warnbro",
      "Watermans Bay",
      "Wembley",
      "West Leederville",
      "West Perth",
      "Willetton",
      "Winthrop",
      "Yallingup",
      "Yanchep",
      "Yokine",
      "Rockingham",
      "Perth",
      "Leederville",
      "Karratha",
      "Busselton",
      "Bunbury",
      "Broome",
      "Albany"
    ],
    Victoria: [
      "Abbotsford",
      "Airport West",
      "Albert Park",
      "Albion",
      "Alexandra",
      "Allansford",
      "Alphington",
      "Altona Meadows",
      "Anglesea",
      "Apollo Bay",
      "Ararat",
      "Ascot Vale",
      "Ashwood",
      "Aspendale Gardens",
      "Attwood",
      "Bacchus Marsh",
      "Bairnsdale",
      "Balaclava",
      "Balnarring",
      "Balwyn",
      "Balwyn North",
      "Baxter",
      "Bayswater",
      "Beaconsfield Upper",
      "Beaumaris",
      "Bell Park",
      "Benalla",
      "Bentleigh",
      "Berwick",
      "Blackburn",
      "Blackburn North",
      "Black Rock",
      "Boronia",
      "Box Hill",
      "Breakwater",
      "Briar Hill",
      "Brighton",
      "Brown Hill",
      "Brunswick",
      "Brunswick East",
      "Brunswick West",
      "Bulleen",
      "Bundoora",
      "Burwood",
      "Burwood East",
      "Camberwell",
      "Canadian",
      "Carlton North",
      "Caroline Springs",
      "Carrum Downs",
      "Castlemaine",
      "Caulfield",
      "Caulfield East",
      "Caulfield North",
      "Caulfield South",
      "Chadstone",
      "Chelsea",
      "Cheltenham",
      "Clayton",
      "Clayton South",
      "Clifton Hill",
      "Coburg",
      "Colac",
      "Collingwood",
      "Craigieburn",
      "Cranbourne East",
      "Cranbourne West",
      "Croydon",
      "Dandenong",
      "Dandenong North",
      "Deer Park",
      "Diamond Creek",
      "Dingley",
      "Docklands",
      "Doncaster",
      "Doncaster East",
      "Donvale",
      "Dromana",
      "East Malvern",
      "East Melbourne",
      "Edithvale",
      "Elsternwick",
      "Eltham",
      "Eltham North",
      "Elwood",
      "Emerald",
      "Endeavour Hills",
      "Epping",
      "Essendon North",
      "Fairfield",
      "Fawkner",
      "Fitzroy",
      "Flemington",
      "Footscray",
      "Forest Hill",
      "Frankston East",
      "Frankston South",
      "Geelong West",
      "Glenferrie",
      "Glen Iris",
      "Glenroy",
      "Gowanbrae",
      "Greensborough",
      "Greenvale",
      "Grovedale",
      "Hallam",
      "Hamilton",
      "Hamlyn Heights",
      "Hampton",
      "Hampton Park",
      "Hastings",
      "Hawthorn",
      "Hawthorn East",
      "Hawthorn South",
      "Healesville",
      "Heidelberg",
      "Heyfield",
      "Highett",
      "Highton",
      "Hillside",
      "Hoppers Crossing",
      "Horsham",
      "Hughesdale",
      "Hunter",
      "Kealba",
      "Keilor East",
      "Kensington",
      "Kew",
      "Keysborough",
      "Kilsyth",
      "Kilsyth South",
      "Kingsbury",
      "Kingsville",
      "Knoxfield",
      "Kyabram",
      "Lalor",
      "Langwarrin",
      "Lara",
      "Leopold",
      "Long Gully",
      "Lower Plenty",
      "Macleod",
      "Mansfield",
      "Maribyrnong",
      "McCrae",
      "McKinnon",
      "Melton",
      "Melton West",
      "Merricks",
      "Middle Park",
      "Millgrove",
      "Mill Park",
      "Mitcham",
      "Moe",
      "Mont Albert North",
      "Moonee Ponds",
      "Moorabbin",
      "Morwell",
      "Mount Beauty",
      "Mount Dandenong",
      "Mount Eliza",
      "Mount Evelyn",
      "Mount Martha",
      "Mount Waverley",
      "Mulgrave",
      "Murrumbeena",
      "Narre Warren",
      "Narre Warren South",
      "Newington",
      "Newport",
      "Noble Park",
      "Noble Park North",
      "North Brighton",
      "Northcote",
      "North Fitzroy",
      "North Melbourne",
      "North Wonthaggi",
      "Notting Hill",
      "Nunawading",
      "Officer",
      "Olinda",
      "Ouyen",
      "Pakenham South",
      "Parkville",
      "Pascoe Vale",
      "Patterson Lakes",
      "Phillip Island",
      "Point Cook",
      "Port Melbourne",
      "Preston",
      "Queenscliff",
      "Research",
      "Reservoir",
      "Richmond",
      "Ringwood East",
      "Ripponlea",
      "Rowville",
      "Roxburgh Park",
      "Safety Beach",
      "Saint Albans",
      "Saint Andrews Beach",
      "Saint Kilda",
      "Sale",
      "Seaford",
      "Seddon",
      "Shepparton",
      "Skye",
      "Sorrento",
      "Southbank",
      "South Melbourne",
      "Spotswood",
      "Springvale",
      "Springvale South",
      "St Albans",
      "Stawell",
      "St Kilda East",
      "St Kilda West",
      "Sunshine West",
      "Surrey Hills",
      "Swan Hill",
      "Sydenham",
      "Tarneit",
      "Taylors Lakes",
      "Templestowe Lower",
      "Thomastown",
      "Traralgon",
      "Tyabb",
      "Wandana Heights",
      "Wangaratta",
      "Wantirna",
      "Wantirna South",
      "Warragul",
      "Warrandyte",
      "Warranwood",
      "West Melbourne",
      "Wheelers Hill",
      "Whittlesea",
      "Williams Landing",
      "Williamstown",
      "Williamstown North",
      "Windsor",
      "Wodonga",
      "Yarra Glen",
      "Yarraville",
      "Werribee",
      "Warrnambool",
      "Toorak",
      "Thornbury",
      "Sunbury",
      "St Kilda",
      "Rosebud",
      "Parkdale",
      "Mornington",
      "Mildura",
      "Mentone",
      "Melbourne",
      "Malvern East",
      "Lilydale",
      "Geelong",
      "Frankston",
      "Ferntree Gully",
      "Essendon",
      "Echuca",
      "Doreen",
      "Cranbourne",
      "Carnegie",
      "Carlton",
      "Brighton East",
      "Bendigo",
      "Ballarat"
    ],
    Tasmania: [
      "Berriedale",
      "Bridport",
      "Burnie",
      "Cygnet",
      "Geeveston",
      "Glenorchy",
      "Huonville",
      "Kingston",
      "Lauderdale",
      "Longford",
      "Mayfield",
      "Moonah",
      "New Norfolk",
      "Rosebery",
      "Sandford",
      "Sandy Bay",
      "Smithton",
      "Sorell",
      "St Helens",
      "Launceston",
      "Hobart",
      "Devonport"
    ],
    "South Australia": [
      "Adelaide Hills",
      "Angaston",
      "Avenue",
      "Barmera",
      "Clapham",
      "Clare",
      "Clarence Gardens",
      "Coober Pedy",
      "Crafers",
      "Gawler",
      "Gilles Plains",
      "Glenelg",
      "Grange",
      "Hectorville",
      "Henley Beach",
      "Honiton",
      "Hope Valley",
      "Ingle Farm",
      "Innamincka",
      "Kingscote",
      "McLaren Vale",
      "Meningie",
      "Millicent",
      "Millswood",
      "Mitcham",
      "Modbury",
      "Morphett Vale",
      "Mount Gambier",
      "Normanville",
      "North Adelaide",
      "North Brighton",
      "Port Adelaide",
      "Port Augusta",
      "Port Lincoln",
      "Port Pirie",
      "Port Willunga",
      "Quorn",
      "Royston Park",
      "Sturt",
      "Tanunda",
      "Thebarton",
      "The Sill",
      "Trinity Gardens",
      "Trott Park",
      "Unley",
      "Unley Park",
      "Victor Harbor",
      "Wayville",
      "Whyalla",
      "Woodside",
      "Prospect",
      "Murray Bridge",
      "Adelaide"
    ],
    Queensland: [
      "Annerley",
      "Ascot",
      "Atherton",
      "Balmoral",
      "Beenleigh",
      "Black Mountain",
      "Bohle Plains",
      "Booval",
      "Bowen",
      "Bracken Ridge",
      "Bridegman Downs",
      "Buderim",
      "Burleigh Heads",
      "Burleigh Waters",
      "Caboolture",
      "Camp Hill",
      "Cannon Hill",
      "Cedar Vale",
      "Chambers Flat",
      "Chandler",
      "Charters Towers",
      "Chermside West",
      "Churchill",
      "Cleveland",
      "Cooktown",
      "Coolum Beach",
      "Coopers Plains",
      "Dalby",
      "Darling Heights",
      "Deception Bay",
      "Dicky Beach",
      "East Toowoomba",
      "Eight Mile Plains",
      "Emerald",
      "Everton Park",
      "Forest Lake",
      "Fortitude Valley",
      "Goondiwindi",
      "Greenslopes",
      "Gympie",
      "Home Hill",
      "Hughenden",
      "Innisfail",
      "Kenmore Hills",
      "Logan City",
      "Lower Beechmont",
      "Magnetic Island",
      "Main Beach",
      "Mango Hill",
      "Manly West",
      "Mansfield",
      "Margate",
      "Maryborough",
      "Milton",
      "Mooloolaba",
      "Morayfield",
      "Morningside",
      "Mount Gravatt East",
      "Murrumba Downs",
      "Nambour",
      "Narangba",
      "Nerang",
      "Noosa",
      "Noosa Heads",
      "Noosaville",
      "North Booval",
      "Oxenford",
      "Palm Cove",
      "Peregian Springs",
      "Pioneer",
      "Port Douglas",
      "Proserpine",
      "Pullenvale",
      "Rainbow Beach",
      "Ravenshoe",
      "Redlynch",
      "Rockhampton",
      "Rocklea",
      "Roma",
      "Scarness",
      "Seven Hills",
      "Seventeen Mile Rocks",
      "South Brisbane",
      "Southport",
      "Springfield Lakes",
      "Spring Hill",
      "Stafford",
      "Stanthorpe",
      "Strathpine",
      "Sunnybank",
      "Sunnybank Hills",
      "Surfers Paradise",
      "Tallebudgera",
      "Tamborine Mountain",
      "Taringa",
      "Tewantin",
      "Thabeban",
      "Thagoona",
      "Tinbeerwah",
      "Toolooa",
      "Toowong",
      "Trinity Beach",
      "Tugun",
      "Upper Coomera",
      "Upper Mount Gravatt",
      "Urangan",
      "Urraween",
      "Varsity Lakes",
      "Wacol",
      "Walkerston",
      "Warwick",
      "Wellington Point",
      "West Rockhampton",
      "Wilsonton Heights",
      "Windaroo",
      "Windsor",
      "Woodford",
      "Woodridge",
      "Woolloongabba",
      "Wooloowin",
      "Woombye",
      "Woree",
      "Wynnum",
      "Yeerongpilly",
      "Yeppoon",
      "Yorkeys Knob",
      "Zillmere",
      "Victoria Point",
      "Townsville",
      "Toowoomba",
      "Palm Beach",
      "Mount Isa",
      "Mackay",
      "Inala",
      "Gold Coast",
      "Gladstone",
      "Coorparoo",
      "Clear Island Waters",
      "Carindale",
      "Caloundra",
      "Cairns",
      "Bundaberg",
      "Brisbane"
    ],
    "Northern Territory": [
      "Alice Springs",
      "Katherine",
      "Larrakeyah",
      "Ludmilla",
      "McMinns Lagoon",
      "Stuart Park",
      "Tennant Creek",
      "Palmerston",
      "Darwin"
    ],
    "New South Wales": [
      "Albion Park Rail",
      "Alexandria",
      "Allambie Heights",
      "Anna Bay",
      "Arncliffe",
      "Artarmon",
      "Ashfield",
      "Auburn",
      "Avoca Beach",
      "Balgowlah",
      "Balmain",
      "Balmain East",
      "Banksia",
      "Bankstown",
      "Banora Point",
      "Bar Beach",
      "Bateau Bay",
      "Batemans Bay",
      "Bathurst",
      "Baulkham Hills",
      "Bay View",
      "Beaumont Hills",
      "Beecroft",
      "Bega",
      "Bellevue Hill",
      "Bellingen",
      "Belmont",
      "Bermagui",
      "Berridale",
      "Berry",
      "Bilgola Beach",
      "Blackheath",
      "Blacktown",
      "Blakehurst",
      "Bomaderry",
      "Bondi Beach",
      "Bondi Junction",
      "Booker Bay",
      "Bossley Park",
      "Botany",
      "Bourke",
      "Bowral",
      "Branxton",
      "Brighton-Le-Sands",
      "Broadmeadow",
      "Bronte",
      "Brookvale",
      "Broulee",
      "Brunswick Heads",
      "Bulahdelah",
      "Bulli",
      "Bundanoon",
      "Bundeena",
      "Burringbar",
      "Burwood",
      "Cammeray",
      "Campbelltown",
      "Camperdown",
      "Campsie",
      "Cardiff South",
      "Caringbah",
      "Carlingford",
      "Carramar",
      "Casuarina",
      "Central Coast",
      "Cessnock",
      "Charlestown",
      "Chatswood",
      "Cherrybrook",
      "Chippendale",
      "Chipping Norton",
      "Chittaway Bay",
      "City of Parramatta",
      "Clontarf",
      "Clovelly",
      "Coffs Harbour",
      "Colo Vale",
      "Concord",
      "Concord West",
      "Cooks Hill",
      "Cooma",
      "Corlette",
      "Corowa",
      "Cowra",
      "Cremorne",
      "Crescent Head",
      "Cronulla",
      "Crookwell",
      "Crows Nest",
      "Dapto",
      "Darlinghurst",
      "Darling Point",
      "Darlington",
      "Darlington Point",
      "Dee Why",
      "Deniliquin",
      "Dolls Point",
      "Doonside",
      "Dorrigo",
      "Dover Heights",
      "Drummoyne",
      "Dulwich Hill",
      "Earlwood",
      "East Ballina",
      "East Corrimal",
      "Elderslie",
      "Emu Heights",
      "Engadine",
      "Enmore",
      "Epping",
      "Erina",
      "Ermington",
      "Eulomogo",
      "Fairfield",
      "Fairfield East",
      "Fairfield Heights",
      "Fairlight",
      "Falls Creek",
      "Federal",
      "Fennell Bay",
      "Forbes",
      "Forestville",
      "Forresters Beach",
      "Forster",
      "Freshwater",
      "Gateshead",
      "Gerringong",
      "Gladesville",
      "Glendale",
      "Glenhaven",
      "Glenmore Park",
      "Goonellabah",
      "Gordon",
      "Goulburn",
      "Grafton",
      "Greenwell Point",
      "Greta",
      "Greystanes",
      "Griffith",
      "Gulgong",
      "Gundaroo",
      "Gymea",
      "Haberfield",
      "Hamilton",
      "Haymarket",
      "Hazelbrook",
      "Helensburgh",
      "Henty",
      "Homebush",
      "Hornsby",
      "Horsley Park",
      "Hunters Hill",
      "Hurstville",
      "Ingleburn",
      "Ingleside",
      "Jindabyne",
      "Kangaroo Valley",
      "Kanwal",
      "Katoomba",
      "Kellyville",
      "Killara",
      "Kingfisher Shores",
      "Kingscliff",
      "Kingsford",
      "Kirrawee",
      "Kirribilli",
      "Kogarah",
      "Kogarah Bay",
      "Kurnell",
      "Lakemba",
      "Lane Cove",
      "Largs",
      "Lavington",
      "Leichhardt",
      "Leura",
      "Lewisham",
      "Lidcombe",
      "Lightning Ridge",
      "Lilyfield",
      "Lithgow",
      "Liverpool",
      "Long Jetty",
      "Lugarno",
      "Macksville",
      "Maclean",
      "Malabar",
      "Maroubra",
      "Mascot",
      "Matraville",
      "Mayfield West",
      "Menai",
      "Merewether Heights",
      "Merimbula",
      "Miller",
      "Millthorpe",
      "Milsons Point",
      "Milton",
      "Miranda",
      "Mittagong",
      "Moama",
      "Moonee Beach",
      "Moree",
      "Mosman",
      "Moss Vale",
      "Mount Druitt",
      "Mudgee",
      "Mulwala",
      "Murwillumbah",
      "Muswellbrook",
      "Nambucca",
      "Narellan",
      "Narooma",
      "Narrabeen",
      "Narrabri",
      "Narraweena",
      "Nelson Bay",
      "Neutral Bay",
      "Newport",
      "Newtown",
      "Nimbin",
      "Noraville",
      "Normanhurst",
      "North Bondi",
      "North Curl Curl",
      "North Haven",
      "North Lambton",
      "North Manly",
      "North Narrabeen",
      "North Ryde",
      "North Sydney",
      "Nowra",
      "Nyngan",
      "Old Bar",
      "Orange",
      "Padstow",
      "Palm Beach",
      "Paramatta",
      "Parkes",
      "Parramatta",
      "Peakhurst",
      "Penrith",
      "Penshurst",
      "Perthville",
      "Phillip",
      "Pitt Town",
      "Point Clare",
      "Point Piper",
      "Port Macquarie",
      "Port Stephens",
      "Pottsville Beach",
      "Prestons",
      "Punchbowl",
      "Quakers Hill",
      "Queanbeyan",
      "Ramsgate Beach",
      "Randwick",
      "Rankin Park",
      "Raymond Terrace",
      "Razorback",
      "Redfern",
      "Redhead",
      "Rhodes",
      "Richmond",
      "Riverstone",
      "Rutherford",
      "Singleton",
      "South Albury",
      "South Coogee",
      "South Grafton",
      "South Tamworth",
      "South Windsor",
      "St Leonards",
      "Strathfield",
      "Strathfield South",
      "Summer Hill",
      "Taree",
      "The Junction",
      "Tighes Hill",
      "Toongabbie",
      "Tumut",
      "Ulladulla",
      "Wahroonga",
      "Waratah",
      "Wareemba",
      "Warriewood",
      "Werrington County",
      "Westmead",
      "West Pennant",
      "West Wyalong",
      "Woolgoolga",
      "Woollahra",
      "Yagoona",
      "Wollongong",
      "Wagga Wagga",
      "Umina",
      "Tweed Heads West",
      "Tamworth",
      "Sydney",
      "Paddington",
      "Newcastle",
      "Marrickville",
      "Manly",
      "Maitland",
      "Lismore",
      "Kiama",
      "Kensington",
      "Granville",
      "Dubbo",
      "Croydon",
      "Coogee",
      "Casula",
      "Castle Hill",
      "Byron Bay",
      "Broken Hill",
      "Bondi",
      "Ballina",
      "Armidale",
      "Albury"
    ],
    "Australian Capital Territory": [
      "Beard",
      "City",
      "Flynn",
      "Hall",
      "Macarthur",
      "Forrest",
      "Canberra"
    ]
  },
  Aruba: { "": ["Oranjestad"] },
  Armenia: {
    Ararat: ["Argavand"],
    Gegharkunik: ["Lchashen", "Martuni", "Sevan"],
    Kotayk: ["Abovyan"],
    "Kotayk'i Marz": ["Garrni", "Tsaghkadzor"],
    Shirak: ["Gyumri"],
    Tavush: ["Dilijan", "Ijevan"],
    Yerevan: ["Yerevan"],
    Syunik: ["Kapan"],
    Lori: ["Spitak", "Vanadzor"],
    Armavir: ["Armavir"]
  },
  Argentina: {
    "Santiago del Estero": [
      "Argentina",
      "Fernandez",
      "Santiago del Estero",
      "Villa Atamisqui"
    ],
    Tucuman: [
      "Aguilares",
      "Alderetes",
      "Bella Vista",
      "Famailla",
      "San Miguel de Tucumán",
      "Tafi Viejo",
      "Yerba Buena",
      "Monteros"
    ],
    "Tierra del Fuego": ["Rio Grande", "Ushuaia"],
    "Santa Fe": [
      "Arroyo Seco",
      "Calchaqui",
      "Capitan Bermudez",
      "Ceres",
      "Coronda",
      "El Trebol",
      "Firmat",
      "Funes",
      "Gobernador Galvez",
      "Granadero Baigorria",
      "Las Rosas",
      "Las Toscas",
      "Maciel",
      "Recreo",
      "Roldan",
      "Rufino",
      "San Carlos Centro",
      "San Javier",
      "Santa Fe de la Vera Cruz",
      "Santo Tome",
      "Tostado",
      "Villa Canas",
      "Villa Constitucion",
      "Villa Ocampo",
      "Venado Tuerto",
      "Sunchales",
      "Santa Fe",
      "San Justo",
      "San Jorge",
      "Rosario",
      "Reconquista",
      "Rafaela",
      "Perez",
      "Las Parejas",
      "Galvez",
      "Esperanza",
      "Casilda",
      "Avellaneda",
      "Armstrong"
    ],
    "Santa Cruz": [
      "El Calafate",
      "Pico Truncado",
      "Puerto Deseado",
      "Río Turbio",
      "Río Gallegos",
      "Caleta Olivia"
    ],
    "San Luis": [
      "Libertador General San Martin",
      "Villa Mercedes",
      "San Luis",
      "Merlo"
    ],
    "San Juan": ["Calingasta", "Caucete", "Pocito", "Santa Lucia", "San Juan"],
    Salta: ["Cafayate", "Embarcación", "Orán", "Tartagal", "Salta"],
    "Rio Negro": [
      "Allen",
      "Catriel",
      "Cinco Saltos",
      "El Bolsón",
      "San Antonio Oeste",
      "San Carlos de Bariloche",
      "Viedma",
      "Villa Regina",
      "General Roca",
      "Cipolletti",
      "Bariloche"
    ],
    Neuquen: [
      "Centenario",
      "Loncopue",
      "Plaza Huincul",
      "Plottier",
      "San Martín de los Andes",
      "Villa La Angostura",
      "Zapala",
      "Neuquén"
    ],
    Misiones: [
      "Alba Posse",
      "Bernardo de Irigoyen",
      "Campo Grande",
      "Candelaria",
      "Montecarlo",
      "Puerto Eldorado",
      "Puerto Esperanza",
      "Puerto Rico",
      "San Pedro",
      "Puerto Iguazú",
      "Posadas",
      "Obera"
    ],
    Mendoza: [
      "La Consulta",
      "Lujan de Cuyo",
      "Malargüe",
      "Tupungato",
      "San Rafael",
      "San Martin",
      "Mendoza"
    ],
    "La Rioja": ["Chamical", "La Rioja", "Chilecito"],
    "La Pampa": ["General Acha", "Winifreda", "Santa Rosa", "General Pico"],
    Jujuy: [
      "Humahuaca",
      "Palpala",
      "San Salvador de Jujuy",
      "Tumbaya",
      "San Pedro"
    ],
    Formosa: ["Clorinda", "Formosa"],
    "Entre Rios": [
      "Chajari",
      "Colon",
      "Concepción del Uruguay",
      "Federacion",
      "General Ramirez",
      "San Salvador",
      "Victoria",
      "Villaguay",
      "Paraná",
      "Gualeguaychú",
      "Gualeguay",
      "Federal",
      "Diamante",
      "Crespo",
      "Concordia"
    ],
    Corrientes: [
      "Concepcion",
      "Curuzu Cuatia",
      "Paso de los Libres",
      "Saladas",
      "San Lorenzo",
      "San Luis del Palmar",
      "Monte Caseros",
      "Goya",
      "Corrientes"
    ],
    Cordoba: [
      "Almafuerte",
      "Alta Gracia",
      "Arroyito",
      "Bell Ville",
      "Capilla del Monte",
      "Corralito",
      "Cosquin",
      "Cruz del Eje",
      "Dean Funes",
      "General Levalle",
      "Hernando",
      "Huinca Renanco",
      "Jesus Maria",
      "La Calera",
      "La Falda",
      "Las Varillas",
      "Marcos Juarez",
      "Morteros",
      "Oliva",
      "Oncativo",
      "Rio Ceballos",
      "Rio Segundo",
      "Rio Tercero",
      "San Francisco",
      "Tio Pujio",
      "Unquillo",
      "Viamonte",
      "Villa Allende",
      "Villa Las Rosas",
      "Villa Nueva",
      "Villa María",
      "Villa Dolores",
      "Villa Carlos Paz",
      "Río Cuarto",
      "Laboulaye",
      "Embalse",
      "Córdoba"
    ],
    Chubut: [
      "Esquel",
      "Hoyo de Epuyen",
      "Paso de Indios",
      "Rawson",
      "Trelew",
      "Puerto Madryn",
      "Comodoro Rivadavia"
    ],
    Chaco: [
      "Barranqueras",
      "Campo Largo",
      "Fontana",
      "General Pinedo",
      "Machagai",
      "Presidencia Roque Sáenz Peña",
      "Villa Angela",
      "Resistencia"
    ],
    Catamarca: [
      "Andalgala",
      "Banado de Ovanta",
      "San Fernando del Valle de Catamarca",
      "Tinogasta"
    ],
    "Buenos Aires F.D.": [
      "Balvanera",
      "Barracas",
      "Colegiales",
      "Retiro",
      "Villa Lugano",
      "Villa Ortuzar",
      "Villa Santa Rita",
      "Buenos Aires"
    ],
    "Buenos Aires": [
      "Adrogue",
      "Arrecifes",
      "Avellaneda",
      "Belen de Escobar",
      "Bragado",
      "Brandsen",
      "Capilla del Senor",
      "Carhue",
      "Carilo",
      "Carmen de Patagones",
      "Caseros",
      "Chacabuco",
      "Chascomus",
      "Coronel Dorrego",
      "Coronel Suárez",
      "Ezeiza",
      "Florencio Varela",
      "General Rodriguez",
      "General San Martin",
      "General Villegas",
      "Hurlingham",
      "Ituzaingo",
      "Lanus",
      "Lincoln",
      "Lomas de Zamora",
      "Magdalena",
      "Marcos Paz",
      "Mar del Plata",
      "Mar del Tuyu",
      "Merlo",
      "Miramar",
      "Moron",
      "Nueve de Julio",
      "Olivos",
      "Pinamar",
      "Pontevedra",
      "Ramallo",
      "Ranchos",
      "San Carlos de Bolivar",
      "San Isidro",
      "San Justo",
      "San Miguel del Monte",
      "San Nicolás de los Arroyos",
      "Santa Catalina - Dique Lujan",
      "Tapalque",
      "Tres Arroyos",
      "Veinticinco de Mayo",
      "Veronica",
      "Zárate",
      "Villa Gesell",
      "Tigre",
      "Tandil",
      "San Pedro",
      "Quilmes",
      "Punta Alta",
      "Pilar",
      "Pergamino",
      "Olavarría",
      "Necochea",
      "Mercedes",
      "Luján",
      "La Plata",
      "Junín",
      "Dolores",
      "Chivilcoy",
      "Campana",
      "Berazategui",
      "Bahía Blanca",
      "Azul"
    ]
  },
  "Antigua and Barbuda": {
    "Parish of Saint John": ["St John's"],
    "Saint John": ["Potters Village", "Saint John's"],
    "Saint Mary": ["Bolands"],
    "Saint Paul": ["Falmouth"],
    "Saint Peter": ["Parham"]
  },
  Antarctica: {},
  Anguilla: {},
  Angola: {
    Benguela: ["Benguela", "Catumbela", "Lobito"],
    Bíe: ["Chissamba"],
    Huambo: ["Huambo"],
    "Luanda Norte": ["Luanda"],
    "Lunda Sul": ["Saurimo"],
    "Malanje Province": ["Malanje"],
    Moxico: ["Luau"],
    Namibe: ["Namibe"],
    Zaire: ["Nzeto", "Soio", "Soyo"],
    "Luanda Province": ["Luanda"],
    Huíla: ["Lubango"],
    Cabinda: ["Cabinda"]
  },
  Andorra: {
    "Andorra la Vella": ["Andorra la Vella"],
    Encamp: ["Encamp"],
    "Escaldes-Engordany": ["les Escaldes"],
    "La Massana": ["la Massana"],
    Ordino: ["Ordino"]
  },
  "American Samoa": { "Eastern District": ["Fagatogo", "Pago Pago"] },
  Algeria: {
    Adrar: ["Adrar", "Timimoun"],
    "Aïn Defla": ["Khemis Miliana"],
    Annaba: ["Annaba", "Drean"],
    Batna: ["Arris", "Batna", "Batna City", "Tazoult-Lambese"],
    Béchar: ["Béchar"],
    Béjaïa: ["Akbou", "Barbacha", "Béjaïa"],
    Biskra: ["Biskra", "Tolga"],
    "Bordj Bou Arréridj": ["Bordj Bou Arreridj", "Bordj Zemoura", "Mansourah"],
    Boumerdes: ["Boumerdas", "Dellys", "Reghaia"],
    Djelfa: ["Charef", "Djelfa"],
    "El Oued": ["Debila", "El Oued"],
    "El Tarf": ["Ben Mehidi", "El Kala"],
    Ghardaia: ["Ghardaïa"],
    Guelma: ["Guelma", "Heliopolis"],
    Illizi: ["Illizi"],
    Jijel: ["Jijel"],
    Khenchela: ["Khenchela"],
    Laghouat: ["Aflou", "Laghouat"],
    Mostaganem: ["Mostaganem"],
    Naama: [],
    Ouargla: ["Djamaa", "Hassi Messaoud", "Ouargla", "Touggourt"],
    "Oum el Bouaghi": [],
    Relizane: ["Oued Rhiou", "Relizane"],
    Sétif: ["El Eulma", "Setif", "Sétif"],
    "Sidi Bel Abbès": [],
    Skikda: ["Azzaba", "Skikda"],
    "Souk Ahras": ["Souk Ahras"],
    Tamanrasset: ["I-n-Salah", "Tamanrasset"],
    Tébessa: ["Cheria", "Hammamet", "Tébessa"],
    Tiaret: ["Sougueur", "Tiaret"],
    Tipaza: [
      "Baraki",
      "Cheraga",
      "El Affroun",
      "Kolea",
      "Saoula",
      "Tipasa",
      "Zeralda"
    ],
    Tissemsilt: ["Tissemsilt"],
    "Tizi Ouzou": ["Chemini", "Tizi Ouzou", "Tizi Rached"],
    Tlemcen: ["Nedroma", "Tlemcen"],
    Saida: ["'Ain el Hadjar", "Saida"],
    Oran: ["Bir el Djir", "Oran"],
    Mila: ["Mila"],
    Medea: ["Medea"],
    Mascara: ["Sig", "Mascara"],
    Constantine: ["Didouche Mourad", "Constantine"],
    Chlef: ["Sidi Akkacha", "Chlef", "Boukadir"],
    Bouira: ["Bouïra", "Chorfa", "Sour el Ghozlane"],
    Blida: ["Beni Mered", "Boufarik", "Chebli", "Meftah", "Souma", "Blida"],
    Algiers: [
      "Bab Ezzouar",
      "Birkhadem",
      "Bordj el Kiffan",
      "Rouiba",
      "Algiers"
    ]
  },
  Albania: {
    Berat: ["Berat"],
    Durres: ["Durres", "Ishem", "Rrashbull", "Xhafzotaj"],
    Elbasan: ["Cerrik", "Elbasan"],
    Fier: ["Divjake", "Fier"],
    Korce: ["Drenove", "Pogradec", "Udenisht"],
    "Qarku i Durresit": ["Durrës", "Shijak"],
    "Qarku i Elbasanit": ["Elbasan"],
    "Qarku i Gjirokastres": ["Permet"],
    "Qarku i Korces": ["Korçë"],
    "Qarku i Lezhes": ["Lezhë"],
    "Qarku i Shkodres": ["Shkoder"],
    "Qarku i Tiranes": ["Tirana"],
    "Qarku i Vlores": ["Sarandë", "Vlorë"],
    Shkoder: ["Gruemire", "Nicaj-Shosh", "Velipoje"],
    Tirane: [
      "Dajt",
      "Golem",
      "Kamez",
      "Kavaje",
      "Paskuqan",
      "Tirana",
      "Vaqarr"
    ],
    Vlore: ["Himare", "Sarande"],
    "Qarku i Fierit": ["Lushnje", "Fier"],
    "Qarku i Beratit": ["Berat"]
  },
  Poland: {
    "Lublin Voivodeship": [
      "Janow Podlaski",
      "Krasnobrod",
      "Krasnystaw",
      "Lublin"
    ],
    "Masovian Voivodeship": [
      "Belsk Duzy",
      "Bemowo",
      "Bielany",
      "Ciechanow",
      "Gostynin",
      "Jozefow",
      "Kabaty",
      "Konstancin-Jeziorna",
      "Legionowo",
      "Lipowiec Koscielny",
      "Marki",
      "Minsk Mazowiecki",
      "Mokotow",
      "Mysiadlo",
      "Nasielsk",
      "Ochota",
      "Ostroleka",
      "Piaseczno",
      "Plock",
      "Praga Polnoc",
      "Praga Poludnie",
      "Przasnysz",
      "Radom",
      "Rzewnie",
      "Serock",
      "Siedlce",
      "Sobienie Jeziory",
      "Sochaczew",
      "Srodmiescie",
      "Stara Kornica",
      "Targowek",
      "Ursus",
      "Warsaw",
      "Wawer",
      "Wilanow",
      "Wlochy",
      "Wola",
      "Zabki",
      "Zoliborz"
    ],
    "Pomeranian Voivodeship": [
      "Cedry Wielkie",
      "Chojnice",
      "Czluchow",
      "Gdansk",
      "Gdynia",
      "Gniewino",
      "Hel",
      "Jastarnia",
      "Kartuzy",
      "Koleczkowo",
      "Kosakowo",
      "Krynica Morska",
      "Leba",
      "Mosty",
      "Orle",
      "Puck",
      "Reda",
      "Rumia",
      "Slupsk",
      "Smoldzino",
      "Sopot",
      "Tczew",
      "Ustka",
      "Wejherowo",
      "Wierzchucino"
    ],
    "Silesian Voivodeship": [
      "Bielsko-Biala",
      "Bytom",
      "Chorzow",
      "Cielmice",
      "Czechowice-Dziedzice",
      "Czestochowa",
      "Dabrowa Gornicza",
      "Gliwice",
      "Katowice",
      "Orzesze",
      "Piekary Slaskie",
      "Pyskowice",
      "Rybnik",
      "Sosnowiec",
      "Swietochlowice",
      "Szczyrk",
      "Tychy",
      "Wodzislaw Slaski",
      "Zabrze",
      "Zawiercie"
    ],
    "Greater Poland": [
      "Jarocin",
      "Kościan",
      "Krotoszyn",
      "Międzychód",
      "Mosina",
      "Oborniki",
      "Opalenica",
      "Ostrów Wielkopolski",
      "Pila",
      "Pleszew",
      "Rawicz",
      "Slupca",
      "Sroda Wielkopolska",
      "Swarzedz",
      "Trzcianka",
      "Turek",
      "Wrzesnia",
      "Chodzież",
      "Gniezno",
      "Kalisz",
      "Kępno",
      "Koło",
      "Konin",
      "Leszno",
      "Poznan",
      "Szamotuły",
      "Wolsztyn",
      "Wronki"
    ],
    "Kujawsko-Pomorskie": [
      "Aleksandrów Kujawski",
      "Brodnica",
      "Bydgoszcz",
      "Chełmno",
      "Chełmża",
      "Ciechocinek",
      "Inowrocław",
      "Koronowo",
      "Kruszwica",
      "Lipno",
      "Mogilno",
      "Rypin",
      "Solec Kujawski",
      "Tuchola",
      "Znin",
      "Grudziądz",
      "Toruń",
      "Włocławek"
    ],
    "Lesser Poland Voivodeship": [
      "Andrychow",
      "Bochnia",
      "Brzesko",
      "Brzeszcze",
      "Bukowno",
      "Chrzanów",
      "Dąbrowa Tarnowska",
      "Gorlice",
      "Kety",
      "Lanckorona",
      "Limanowa",
      "Lososina Dolna",
      "Muszyna",
      "Nowy Sącz",
      "Nowy Targ",
      "Oświęcim",
      "Skawina",
      "Stanislawice",
      "Szczucin",
      "Tarnów",
      "Wadowice",
      "Wieliczka",
      "Zator",
      "Krakow",
      "Olkusz",
      "Trzebinia",
      "Zakopane"
    ],
    "Łódź Voivodeship": [
      "Aleksandrów Łódzki",
      "Baby",
      "Bełchatów",
      "Brzeziny",
      "Czerniewice",
      "Koluszki",
      "Lask",
      "Opoczno",
      "Pabianice",
      "Piatek",
      "Rawa Mazowiecka",
      "Sieradz",
      "Skierniewice",
      "Tomaszów Mazowiecki",
      "Wartkowice",
      "Wieluń",
      "Zgierz",
      "Kutno",
      "Łęczyca",
      "Łódź",
      "Piotrków Trybunalski",
      "Radomsko"
    ],
    "Lower Silesia": [
      "Bielawa",
      "Bogatynia",
      "Brzeg Dolny",
      "Chojnów",
      "Dzierzoniow",
      "Jawor",
      "Jelenia Góra",
      "Kowary",
      "Marciszow",
      "Milicz",
      "Nowa Ruda",
      "Olawa",
      "Olesnica",
      "Polkowice",
      "Smolec",
      "Strzegom",
      "Świdnica",
      "Trzebnica",
      "Wałbrzych",
      "Ząbkowice Śląskie",
      "Zgorzelec",
      "Złotoryja",
      "Bolesławiec",
      "Głogów",
      "Gora",
      "Kłodzko",
      "Legnica",
      "Lubin",
      "Wrocław"
    ],
    Lublin: [
      "Biłgoraj",
      "Chełm",
      "Deblin",
      "Hrubieszów",
      "Krasnystaw",
      "Międzyrzec Podlaski",
      "Parczew",
      "Poniatowa",
      "Puławy",
      "Swidnik",
      "Zamość",
      "Biała Podlaska",
      "Lublin"
    ],
    Lubusz: [
      "Drezdenko",
      "Gorzów Wielkopolski",
      "Gubin",
      "Lubniewice",
      "Lubsko",
      "Międzyrzecz",
      "Skwierzyna",
      "Słubice",
      "Wschowa",
      "Żagań",
      "Zary",
      "Szprotawa",
      "Zielona Góra"
    ],
    Mazovia: [
      "Błonie",
      "Garwolin",
      "Gostynin",
      "Grodzisk Mazowiecki",
      "Legionowo",
      "Los",
      "Marki",
      "Milanówek",
      "Mińsk Mazowiecki",
      "Mława",
      "Ostrołęka",
      "Otwock",
      "Piaseczno",
      "Pionki",
      "Płońsk",
      "Pruszków",
      "Przasnysz",
      "Pułtusk",
      "Siedlce",
      "Sierpc",
      "Sochaczew",
      "Sokołów Podlaski",
      "Warka",
      "Węgrów",
      "Wołomin",
      "Wyszków",
      "Ząbki",
      "Zielonka",
      "Żyrardów",
      "Ciechanów",
      "Płock",
      "Radom",
      "Warsaw",
      "Wola"
    ],
    "Opole Voivodeship": [
      "Grodkow",
      "Kluczbork",
      "Krapkowice",
      "Namyslow",
      "Nysa",
      "Olesno",
      "Opole",
      "Prudnik",
      "Strzelce Opolskie",
      "Brzeg"
    ],
    Podlasie: [
      "Augustów",
      "Bielsk Podlaski",
      "Goniadz",
      "Hajnówka",
      "Kolno",
      "Łapy",
      "Łomża",
      "Mońki",
      "Siemiatycze",
      "Sokółka",
      "Suwałki",
      "Zambrów",
      "Białystok",
      "Grajewo"
    ],
    Pomerania: [
      "Bytów",
      "Chojnice",
      "Czluchow",
      "Kartuzy",
      "Kwidzyn",
      "Lebork",
      "Miastko",
      "Rumia",
      "Słupsk",
      "Starogard Gdański",
      "Tczew",
      "Ustka",
      "Wejherowo",
      "Gdańsk",
      "Gdynia",
      "Malbork",
      "Puck",
      "Reda",
      "Sopot",
      "Władysławowo"
    ],
    Silesia: [
      "Będzin",
      "Bytom",
      "Chorzów",
      "Cieszyn",
      "Gliwice",
      "Jastrzębie Zdrój",
      "Jaworzno",
      "Knurow",
      "Lubliniec",
      "Mikołów",
      "Orzesze",
      "Pszczyna",
      "Pyskowice",
      "Radlin",
      "Ruda Śląska",
      "Rydułtowy",
      "Sosnowiec",
      "Świętochłowice",
      "Ustron",
      "Wisla",
      "Wodzisław Śląski",
      "Zawiercie",
      "Żory",
      "Żywiec",
      "Częstochowa",
      "Dąbrowa Górnicza",
      "Katowice",
      "Mysłowice",
      "Racibórz",
      "Rybnik",
      "Tychy",
      "Zabrze"
    ],
    "Subcarpathian Voivodeship": [
      "Jarosław",
      "Jaslo",
      "Kolbuszowa",
      "Mielec",
      "Nisko",
      "Polanczyk",
      "Przeworsk",
      "Ropczyce",
      "Stalowa Wola",
      "Tarnobrzeg",
      "Krosno",
      "Rzeszów",
      "Sanok"
    ],
    Świętokrzyskie: [
      "Busko-Zdrój",
      "Jedrzejow",
      "Maslow",
      "Ostrowiec Świętokrzyski",
      "Sandomierz",
      "Kielce"
    ],
    "Warmia-Masuria": [
      "Bartoszyce",
      "Dobre Miasto",
      "Działdowo",
      "Elblag",
      "Giżycko",
      "Gołdap",
      "Iława",
      "Kętrzyn",
      "Kortowo",
      "Lidzbark",
      "Lidzbark Warmiński",
      "Morąg",
      "Mragowo",
      "Nidzica",
      "Olecko",
      "Ostróda",
      "Piecki",
      "Szczytno",
      "Węgorzewo",
      "Braniewo",
      "Ełk",
      "Olsztyn",
      "Pisz"
    ],
    "West Pomerania": [
      "Barlinek",
      "Białogard",
      "Choszczno",
      "Darłowo",
      "Drawsko Pomorskie",
      "Goleniów",
      "Gryfice",
      "Gryfino",
      "Grzybowo",
      "Kołobrzeg",
      "Mielno",
      "Police",
      "Pyrzyce",
      "Sławno",
      "Świnoujście",
      "Walcz",
      "Wolin",
      "Koszalin",
      "Nowogard",
      "Stargard",
      "Szczecin",
      "Szczecinek"
    ]
  },
  "Åland Islands": {
    "": ["Mariehamn"],
    "Alands landsbygd": ["Jomala"],
    "Mariehamns stad": ["Mariehamn"]
  },
  Portugal: {
    "Viana do Castelo": [
      "Arcos de Valdevez",
      "Melgaco",
      "Monção",
      "Ponte da Barca",
      "Ponte de Lima",
      "Viana do Castelo",
      "Vila Praia de Ancora"
    ],
    "Vila Real": [
      "Chaves",
      "Godim",
      "Vila Pouca de Aguiar",
      "Vila Real",
      "Vilela"
    ],
    Aveiro: [
      "Aradas",
      "Arouca",
      "Esgueira",
      "Esmoriz",
      "Gafanha da Encarnacao",
      "Mealhada",
      "Murtosa",
      "Aveiro",
      "Espinho",
      "Feira",
      "Ovar"
    ],
    Azores: [
      "Angra do Heroísmo",
      "Arrifes",
      "Biscoitos",
      "Furnas",
      "Nordeste",
      "Ponta Delgada",
      "Rabo de Peixe",
      "Ribeira Grande",
      "Sao Roque do Pico",
      "Vila Franca do Campo"
    ],
    Beja: [
      "Almodovar",
      "Odemira",
      "Sao Teotonio",
      "Vila Nova de Milfontes",
      "Beja"
    ],
    Braga: [
      "Nine",
      "Real",
      "Ribeirao",
      "Vila Nova de Famalicao",
      "Barcelos",
      "Braga",
      "Esposende",
      "Fafe",
      "Guimarães"
    ],
    Bragança: ["Mirandela", "Braganca"],
    "Castelo Branco": [
      "Covilha",
      "Fundao",
      "Penamacor",
      "Proenca-a-Nova",
      "Castelo Branco"
    ],
    Coimbra: ["Lavos", "Lousã", "Montemor-o-Velho", "Tabua", "Coimbra"],
    Évora: [
      "Montemor-o-Novo",
      "Mourao",
      "Reguengos de Monsaraz",
      "Vendas Novas",
      "Vila Vicosa",
      "Evora"
    ],
    Faro: [
      "Aljezur",
      "Almancil",
      "Alvor",
      "Armacao de Pera",
      "Boliqueime",
      "Carvoeiro",
      "Castro Marim",
      "Guia",
      "Lagos",
      "Laranjeiro",
      "Loule",
      "Olhao",
      "Sagres",
      "Silves",
      "Tavira",
      "Vale",
      "Vilamoura",
      "Albufeira",
      "Faro",
      "Portimão",
      "Quarteira"
    ],
    Guarda: ["Custoias", "Manteigas", "Sequeira", "Guarda"],
    Leiria: [
      "Alcobaça",
      "Ansiao",
      "Atouguia da Baleia",
      "Caldas da Rainha",
      "Marinha Grande",
      "Nazaré",
      "Óbidos",
      "Pombal",
      "Leiria",
      "Peniche"
    ],
    Lisbon: [
      "Alcabideche",
      "Alges",
      "Azambuja",
      "Bucelas",
      "Cacem",
      "Camarate",
      "Carcavelos",
      "Carnaxide",
      "Carregado",
      "Colares",
      "Ericeira",
      "Estoril",
      "Linda-a-Velha",
      "Loures",
      "Lourinhã",
      "Mafra",
      "Malveira",
      "Massama",
      "Monte Estoril",
      "Moscavide",
      "Odivelas",
      "Oeiras",
      "Olival do Basto",
      "Pontinha",
      "Ramada",
      "Rio de Mouro",
      "Sacavem",
      "Sao Domingos de Rana",
      "Silveira",
      "Terrugem",
      "Torres Vedras",
      "Vialonga",
      "Vila Franca de Xira",
      "Amadora",
      "Belas",
      "Canecas",
      "Cascais",
      "Lisbon",
      "Parede",
      "Porto Salvo",
      "Queluz",
      "Sintra"
    ],
    Madeira: [
      "Câmara de Lobos",
      "Canical",
      "Canico",
      "Curral das Freiras",
      "Faja da Ovelha",
      "Machico",
      "Nossa Senhora do Monte",
      "Ponta do Sol",
      "Porto Moniz",
      "Santana",
      "Funchal"
    ],
    Portalegre: [
      "Alter do Chao",
      "Portugal",
      "Quinta",
      "Santo Andre",
      "Portalegre"
    ],
    Porto: [
      "Alfena",
      "Arcozelo",
      "Avintes",
      "Bougado",
      "Campo",
      "Canelas",
      "Canidelo",
      "Ermesinde",
      "Felgueiras",
      "Gondomar",
      "Grijo",
      "Leca do Bailio",
      "Lousada",
      "Matosinhos",
      "Olival",
      "Oliveira do Douro",
      "Póvoa de Varzim",
      "Senhora da Hora",
      "Valongo",
      "Vila do Conde",
      "Vila Nova de Gaia",
      "Vilar de Andorinho",
      "Maia",
      "Paredes",
      "Pedroso",
      "Porto",
      "Rio Tinto",
      "Santo Tirso",
      "Trofa"
    ],
    Santarém: [
      "Almeirim",
      "Constancia",
      "Entroncamento",
      "Ferreira do Zezere",
      "Ourem",
      "Rio Maior",
      "Samora Correia",
      "Torres Novas",
      "Abrantes",
      "Cartaxo",
      "Fátima",
      "Monsanto",
      "Santarém",
      "Tomar"
    ],
    Setúbal: [
      "Alcacer do Sal",
      "Arrentela",
      "Azeitao",
      "Cacilhas",
      "Charneca",
      "Charneca de Caparica",
      "Corroios",
      "Costa de Caparica",
      "Grandola",
      "Lavradio",
      "Palmela",
      "Piedade",
      "Pinhal Novo",
      "Porto Covo",
      "Pragal",
      "Quinta do Anjo",
      "Santo Andre",
      "Seixal",
      "Setubal",
      "Vendas",
      "Almada",
      "Amora",
      "Barreiro",
      "Caparica",
      "Moita",
      "Montijo",
      "Sesimbra",
      "Setúbal"
    ],
    Viseu: ["Lamego", "Viseu"]
  },
  "Puerto Rico": {
    Aguadilla: ["Aguadilla", "San Antonio"],
    Arecibo: ["Arecibo"],
    Barceloneta: ["Barceloneta"],
    Bayamon: ["Bayamon"],
    "Cabo Rojo": ["Cabo Rojo", "Pole Ojea"],
    Caguas: ["Bairoa", "Caguas"],
    Camuy: ["Camuy"],
    Canovanas: ["Santa Barbara"],
    Carolina: ["Carolina"],
    Catano: ["Catano"],
    Cayey: ["Cayey", "G. L. Garcia"],
    Ciales: ["Ciales"],
    Culebra: ["Culebra"],
    Dorado: ["Dorado", "San Antonio"],
    Fajardo: ["Fajardo"],
    Florida: [],
    Guanica: ["Guanica"],
    Guayama: ["Guayama"],
    Guaynabo: ["Guaynabo"],
    Hatillo: ["Carrizales"],
    Humacao: ["Humacao"],
    Isabela: ["Isabela"],
    "Juana Diaz": ["Juana Diaz"],
    Lajas: ["La Parguera"],
    Luquillo: ["Luquillo", "Playa Fortuna"],
    Manati: ["Tierras Nuevas Poniente"],
    Mayaguez: ["Mayagueez"],
    Orocovis: ["Orocovis"],
    Ponce: ["Ponce"],
    Rincon: ["Rincon"],
    "Rio Grande": ["Hato Candal"],
    "Sabana Grande": ["Sabana Grande"],
    "San Juan": ["San Juan"],
    "San Lorenzo": ["San Lorenzo"],
    "San Sebastian": ["San Sebastian"],
    "Toa Alta": ["Mucarabones", "Toa Alta"],
    "Toa Baja": ["Candelaria", "Levittown"],
    "Trujillo Alto": ["Trujillo Alto"],
    Utuado: ["Utuado"],
    "Vega Baja": ["Vega Baja"],
    Yauco: ["Yauco"],
    "": [
      "Catano",
      "Coamo",
      "Corozal",
      "Hormigueros",
      "Rio Grande",
      "San German",
      "Vega Alta",
      "Yauco",
      "Aguadilla",
      "Arecibo",
      "Barceloneta",
      "Bayamón",
      "Cabo Rojo",
      "Caguas",
      "Carolina",
      "Cayey",
      "Dorado",
      "Fajardo",
      "Guayama",
      "Guaynabo",
      "Humacao",
      "Manati",
      "Mayagüez",
      "Ponce",
      "San Juan",
      "Trujillo Alto",
      "Vega Baja"
    ]
  },
  Qatar: {
    "Al Khawr": ["Al Khawr"],
    "Al Wakrah": ["Al Wakrah", "Al Wukayr"],
    "Baladiyat al Khawr wa adh Dhakhirah": ["Al Khor"],
    "Baladiyat ar Rayyan": ["Ar Rayyan", "Ash Shahaniyah", "Dukhan"],
    "Baladiyat Umm Salal": ["Umm Salal `Ali", "Umm Salal Muhammad"],
    "Baladiyat ad Dawhah": ["Doha"]
  },
  Réunion: {
    "": ["Le Port"],
    Reunion: [
      "Cilaos",
      "Saint-Denis",
      "Saint-Leu",
      "Saint-Paul",
      "Saint-Pierre"
    ]
  },
  Romania: {
    Alba: ["Alba Iulia"],
    Arges: [],
    Bacau: ["Bacau"],
    "Bistrita-Nasaud": ["Tureac"],
    Brasov: ["Brasov", "Jibert", "Persani", "Rasnov", "Ticusu Vechi"],
    "Caras-Severin": ["Valiug"],
    Cluj: ["Cluj-Napoca", "Dej", "Turda"],
    Covasna: [],
    Galati: ["Baleni"],
    Giurgiu: ["Giurgiu"],
    Gorj: ["Motru", "Târgu Jiu"],
    Harghita: ["Miercurea-Ciuc", "Praid"],
    Iasi: ["Aroneanu", "Iasi", "Valea Lupului"],
    "Judetul Arges": ["Mioveni", "Piteşti"],
    "Judetul Bistrita-Nasaud": ["Beclean", "Bistriţa", "Nasaud"],
    "Judetul Botosani": ["Botoşani", "Dorohoi"],
    "Judetul Braila": ["Brăila"],
    "Judetul Brasov": ["Braşov", "Fagaras"],
    "Judetul Buzau": ["Buzău", "Ramnicu Sarat"],
    "Judetul Caras-Severin": ["Caransebes"],
    "Judetul Cluj": ["Campia Turzii", "Cluj-Napoca", "Dej", "Gherla", "Turda"],
    "Judetul Dambovita": ["Gaesti", "Târgovişte", "Titu"],
    "Judetul Ialomita": ["Slobozia"],
    "Judetul Mures": [
      "Ludus",
      "Sighisoara",
      "Sovata",
      "Târgu Mureş",
      "Târnăveni"
    ],
    "Judetul Salaj": ["Zalău"],
    "Judetul Timis": ["Jimbolia", "Lugoj", "Timișoara"],
    "Judetul Valcea": ["Râmnicu Vâlcea"],
    Neamt: ["Piatra Neamt"],
    Olt: ["Caracal", "Corabia", "Slatina"],
    "Satu Mare": ["Baia Mare", "Carei", "Satu Mare"],
    Sibiu: ["Sibiu"],
    Suceava: [
      "Campulung Moldovenesc",
      "Gura Humorului",
      "Suceava",
      "Vatra Dornei"
    ],
    Teleorman: ["Alexandria"],
    Timis: ["Timisoara"],
    Tulcea: ["C.A. Rosetti", "Sfantu Gheorghe", "Tulcea"],
    Vrancea: ["Adjud", "Focşani"],
    Arad: ["Ineu", "Mailat", "Arad"],
    Bihor: ["Budureasa", "Marghita", "Oradea"],
    Bucuresti: ["Bucuresti", "Bucharest"],
    Constanta: [
      "Costinesti",
      "Douazeci si Trei August",
      "Istria",
      "Limanu",
      "Mangalia",
      "Medgidia",
      "Navodari",
      "Ovidiu",
      "Palazu Mare",
      "Constanța"
    ],
    Dolj: ["Craiova"],
    Hunedoara: [
      "Hunedoara",
      "Lupeni",
      "Petrila",
      "Petroşani",
      "Santamaria-Orlea",
      "Simeria",
      "Vulcan",
      "Brad",
      "Deva"
    ],
    Ilfov: [
      "Balotesti",
      "Buftea",
      "Dobroesti",
      "Fundeni",
      "Ghermanesti",
      "Mogosoaia",
      "Otopeni",
      "Pantelimon",
      "Rosu",
      "Voluntari"
    ],
    "Judetul Alba": ["Aiud", "Blaj", "Alba Iulia", "Sebes"],
    "Judetul Bacau": ["Bacău"],
    "Judetul Galati": ["Tecuci", "Galați"],
    "Judetul Iasi": ["Iași"],
    "Judetul Neamt": ["Piatra Neamţ", "Roman"],
    "Judetul Sibiu": ["Cisnadie", "Sibiu"],
    Maramureş: ["Sighetu Marmaţiei", "Baia Mare", "Borsa"],
    Prahova: [
      "Azuga",
      "Breaza",
      "Bucov",
      "Busteni",
      "Ploieşti",
      "Sinaia",
      "Urlati",
      "Campina"
    ],
    Vaslui: ["Vaslui", "Bârlad"]
  },
  "Russian Federation": {
    Adygeya: ["Khanskaya", "Maykop", "Yablonovskiy"],
    Amur: ["Belogorsk", "Blagoveshchensk", "Shimanovsk", "Zeya"],
    Arkhangelskaya: [
      "Arkhangelsk",
      "Arkhangel'sk",
      "Koryazhma",
      "Kotlas",
      "Kuloy",
      "Lukovetskiy",
      "Novodvinsk",
      "Nyandoma",
      "Onega",
      "Severodvinsk",
      "Velsk"
    ],
    Astrakhan: ["Astrakhan'", "Kamyzyak", "Krasnyye Barrikady"],
    Belgorod: ["Belgorod", "Prokhorovka", "Shebekino", "Staryy Oskol"],
    Brjansk: ["Bryansk", "Novozybkov", "Trubchevsk"],
    Chechnya: [
      "Achkhoy-Martan",
      "Argun",
      "Avtury",
      "Grozny",
      "Groznyy",
      "Gudermes",
      "Kurchaloy",
      "Samashki",
      "Staraya Sunzha",
      "Urus-Martan"
    ],
    Ingushetiya: ["Ekazhevo", "Malgobek", "Ordzhonikidzevskaya"],
    Irkutsk: [
      "Angarsk",
      "Bratsk",
      "Irkutsk",
      "Khuzhir",
      "Nizhneudinsk",
      "Zima"
    ],
    Ivanovo: ["Ivanovo"],
    Jaroslavl: [
      "Bol'shoye Selo",
      "Pereslavl'-Zalesskiy",
      "Pesochnoye",
      "Rostov",
      "Rybinsk",
      "Yaroslavl"
    ],
    Kaliningrad: [
      "Baltiysk",
      "Chernyakhovsk",
      "Kaliningrad",
      "Polessk",
      "Svetlogorsk",
      "Svetlyy",
      "Yantarnyy",
      "Zelenogradsk"
    ],
    Kalmykiya: ["Elista", "Lagan"],
    Kaluga: [
      "Balabanovo",
      "Kaluga",
      "Kondrovo",
      "Nikola-Lenivets",
      "Obninsk",
      "Sukhinichi",
      "Tarusa"
    ],
    "Kamtchatski Kray": ["Vilyuchinsk", "Yelizovo"],
    Kamtsjatka: [
      "Klyuchi",
      "Petropavlovsk-Kamchatsky",
      "Vilyuchinsk",
      "Yelizovo"
    ],
    "Karachayevo-Cherkesiya": [
      "Cherkessk",
      "Karachayevsk",
      "Khabez",
      "Nizhniy Arkhyz",
      "Teberda",
      "Zelenchukskaya"
    ],
    Kemerovo: [
      "Belovo",
      "Kemerovo",
      "Leninsk-Kuznetsky",
      "Novokuznetsk",
      "Tashtagol",
      "Yurga",
      "Zelenogorskiy"
    ],
    Khakasiya: ["Abakan", "Sayanogorsk"],
    "Khanty-Mansiyskiy Avtonomnyy Okrug": [
      "Andra",
      "Khanty-Mansiysk",
      "Kogalym",
      "Nefteyugansk",
      "Nizhnesortymskiy",
      "Nizhnevartovsk",
      "Poykovskiy",
      "Surgut"
    ],
    Kirov: ["Kirov", "Omutninsk", "Vyatskiye Polyany"],
    "Komi Republic": ["Syktyvkar", "Ukhta"],
    Kostroma: ["Chukhloma", "Kostroma", "Krasnoye-na-Volge", "Manturovo"],
    Kurgan: ["Kurgan", "Shadrinsk"],
    "Kurganskaya Oblast'": ["Dalmatovo", "Kurgan", "Shadrinsk"],
    Kursk: ["Kursk"],
    Leningrad: [
      "Akademicheskoe",
      "Chernaya Rechka",
      "Finlyandskiy",
      "Gatchina",
      "Kalininskiy",
      "Kirovsk",
      "Korobitsyno",
      "Krestovskiy ostrov",
      "Kurortnyy",
      "Murino",
      "Otradnoye",
      "Parnas",
      "Priladozhskiy",
      "Primorsk",
      "Rybatskoye",
      "Sampsonievskiy",
      "Sapernoye",
      "Sertolovo",
      "Sosnovyy Bor",
      "Svetlanovskiy",
      "Tikhvin",
      "Toksovo",
      "Tosno",
      "Udel'naya",
      "Untolovo",
      "Vazhiny",
      "Vsevolozhsk",
      "Vyborg"
    ],
    Lipetsk: ["Lipetsk", "Yelets"],
    Magadan: ["Magadan"],
    "Mariy-El": ["Yoshkar-Ola"],
    Mordoviya: ["Saransk"],
    Moskovskaya: [
      "Annino",
      "Aprelevka",
      "Arkhangel'skoye",
      "Balashikha",
      "Barvikha",
      "Bogorodskoye",
      "Bol'shaya Setun'",
      "Bol'shoye Gryzlovo",
      "Bronnitsy",
      "Chekhov",
      "Cheremushki",
      "Chornaya",
      "Dedenevo",
      "Dmitrov",
      "Dolgoprudnyy",
      "Domodedovo",
      "Dorogomilovo",
      "Dorokhovo",
      "Dzerzhinskiy",
      "Fili",
      "Gorki Vtoryye",
      "Istra",
      "Ivanteyevka",
      "Kastanayevo",
      "Khoroshevo-Mnevniki",
      "Klin",
      "Kommunarka",
      "Koptevo",
      "Korolev",
      "Kotel'niki",
      "Kozhukhovo",
      "Krasnoarmeysk",
      "Krasnogorsk",
      "Kratovo",
      "Leninskiye Gory",
      "Lesnoy Gorodok",
      "Levoberezhnaya",
      "Levoberezhnyy",
      "Lobnya",
      "Lugovaya",
      "Lyubertsy",
      "Marfino",
      "Moskovskiy",
      "Mosrentgen",
      "Mytishchi",
      "Naro-Fominsk",
      "Nemchinovka",
      "Nikolina Gora",
      "Noginsk",
      "Novoivanovskoye",
      "Novopetrovskoye",
      "Novopodrezkovo",
      "Odintsovo",
      "Opalikha",
      "Pavlovskaya Sloboda",
      "Pavlovskiy Posad",
      "Pavshino",
      "Petrovo-Dal'neye",
      "Pirogovskiy",
      "Podol'sk",
      "Povedniki",
      "Presnenskiy",
      "Protvino",
      "Razvilka",
      "Reutov",
      "Rozhdestveno",
      "Ruza",
      "Rzhavki",
      "Semenovskoye",
      "Sergiyev Posad",
      "Shchelkovo",
      "Shcherbinka",
      "Skhodnya",
      "Solnechnogorsk",
      "Svatkovo",
      "Sychevo",
      "Troitsk",
      "Verbilki",
      "Volokolamsk",
      "Vostochnoe Degunino",
      "Vostryakovo",
      "Yakhroma",
      "Yam",
      "Yegor'yevsk",
      "Zarech'ye",
      "Zheleznodorozhnyy",
      "Zhukovskiy",
      "Zvenigorod"
    ],
    "Nenetskiy Avtonomnyy Okrug": ["Iskateley"],
    "Nizjnij Novgorod": [
      "Arzamas",
      "Bogorodsk",
      "Bor",
      "Gorodets",
      "Nizhniy Novgorod",
      "Pavlovo",
      "Sarov",
      "Semenov"
    ],
    Novgorod: ["Borovichi", "Valday", "Velikiy Novgorod"],
    "Novgorodskaya Oblast'": ["Borovichi", "Malaya Vishera", "Veliky Novgorod"],
    Omsk: ["Omsk"],
    Orenburg: ["Kuvandyk", "Novotroitsk", "Orenburg"],
    Orjol: ["Livny", "Mtsensk", "Orel"],
    "Orlovskaya Oblast'": ["Livny", "Mtsensk", "Oryol"],
    Penza: ["Kuznetsk", "Penza"],
    Perm: ["Chaykovskiy", "Cherdyn'", "Gubakha", "Kizel", "Kungur", "Perm"],
    Pskov: ["Ostrov", "Pskov"],
    "Republic of Tyva": [
      "Kostomuksha",
      "Kyzyl",
      "Petrozavodsk",
      "Sortavala",
      "Sukkozero"
    ],
    "Respublika Buryatiya": ["Taksimo", "Turka", "Ulan-Ude", "Zhemchug"],
    "Respublika Ingushetiya": ["Karabulak", "Nazran"],
    "Respublika Mariy-El": ["Volzhsk", "Yoshkar-Ola"],
    Rjazan: [
      "Novomichurinsk",
      "Ryazan'",
      "Rybnoye",
      "Sasovo",
      "Shilovo",
      "Spassk-Ryazanskiy"
    ],
    Sakha: ["Aldan", "Neryungri", "Yakutsk"],
    Sakhalin: ["Korsakov", "Yuzhno-Sakhalinsk"],
    "Sakhalinskaya Oblast'": [
      "Dolinsk",
      "Kholmsk",
      "Korsakov",
      "Okha",
      "Uglegorsk",
      "Yuzhno-Sakhalinsk"
    ],
    Samara: [
      "Krasnyy Yar",
      "Novokuybyshevsk",
      "Pokhvistnevo",
      "Samara",
      "Tol'yatti",
      "Zhigulevsk"
    ],
    Saratov: ["Balakovo", "Engel's", "Krasnoarmeysk", "Saratov"],
    Smolensk: ["Smolensk"],
    Sverdlovsk: [
      "Aramil",
      "Bobrovskiy",
      "Bogdanovich",
      "Degtyarsk",
      "Istok",
      "Lesnoy",
      "Nizhniy Tagil",
      "Pervoural'sk",
      "Shirokaya Rechka",
      "Verkhnyaya Salda",
      "Yekaterinburg"
    ],
    Tambov: ["Bokino", "Morshansk", "Tambov"],
    Tjumen: ["Tyumen"],
    Tomsk: ["Seversk", "Tomsk"],
    Tula: ["Chekalin", "Novomoskovsk", "Suvorov", "Tula"],
    Tyva: ["Ak-Dovurak", "Kyzyl"],
    Udmurtiya: ["Izhevsk", "Votkinsk"],
    Vladimir: [
      "Kirzhach",
      "Kovrov",
      "Murom",
      "Petushki",
      "Pokrov",
      "Sobinka",
      "Suzdal'",
      "Vladimir",
      "Vyazniki"
    ],
    Volgograd: [
      "Kalach-na-Donu",
      "Kotovo",
      "Krasnoslobodsk",
      "Volgograd",
      "Volzhskiy"
    ],
    Vologda: ["Cherepovets", "Vologda"],
    "Vologodskaya Oblast'": [
      "Babayevo",
      "Cherepovets",
      "Sheksna",
      "Sokol",
      "Vologda"
    ],
    Voronezj: ["Boguchar", "Podgornoye", "Pridonskoy", "Voronezh"],
    "Yamalo-Nenetskiy Avtonomnyy Okrug": [
      "Nadym",
      "Novyy Urengoy",
      "Noyabrsk",
      "Pangody",
      "Salekhard"
    ],
    Altai: ["Chemal", "Gorno-Altaysk", "Inya", "Mayma"],
    "Altai Krai": [
      "Aleysk",
      "Bayevo",
      "Kulunda",
      "Novoaltaysk",
      "Novosilikatnyy",
      "Pervomayskoye",
      "Rubtsovsk",
      "Slavgorod",
      "Yarovoye",
      "Yuzhnyy",
      "Zarinsk",
      "Zaton",
      "Barnaul",
      "Biysk"
    ],
    "Amurskaya Oblast'": [
      "Arkhara",
      "Belogorsk",
      "Progress",
      "Shimanovsk",
      "Svobodnyy",
      "Tynda",
      "Zavitinsk",
      "Zeya",
      "Blagoveshchensk"
    ],
    "Astrakhanskaya Oblast'": ["Astrakhan", "Narimanov"],
    Bashkortostan: [
      "Agidel",
      "Belebey",
      "Birsk",
      "Blagoveshchensk",
      "Davlekanovo",
      "Ishimbay",
      "Kumertau",
      "Meleuz",
      "Neftekamsk",
      "Oktyabr'skiy",
      "Serafimovskiy",
      "Sibay",
      "Sterlitamak",
      "Tuymazy",
      "Uchaly",
      "Ulukulevo",
      "Yanaul",
      "Salavat",
      "Ufa"
    ],
    "Belgorodskaya Oblast'": [
      "Borisovka",
      "Chernyanka",
      "Gubkin",
      "Shebekino",
      "Stary Oskol",
      "Belgorod"
    ],
    "Bryanskaya Oblast'": [
      "Novozybkov",
      "Pogar",
      "Unecha",
      "Zhukovka",
      "Bryansk",
      "Klintsy"
    ],
    Chelyabinsk: [
      "Asha",
      "Kopeysk",
      "Korkino",
      "Ozersk",
      "Plast",
      "Satka",
      "Snezhinsk",
      "Turgoyak",
      "Zlatoust",
      "Bakal",
      "Chelyabinsk",
      "Kusa",
      "Magnitogorsk",
      "Miass",
      "Troitsk"
    ],
    Chukot: ["Aldan", "Aykhal", "Lensk", "Neryungri", "Yakutsk"],
    Chukotka: ["Anadyr"],
    Chuvashia: ["Kanash", "Novocheboksarsk", "Shumerlya", "Cheboksary"],
    Dagestan: [
      "Agvali",
      "Buynaksk",
      "Dagestanskiye Ogni",
      "Derbent",
      "Gunib",
      "Izberbash",
      "Kaspiysk",
      "Khasavyurt",
      "Kizilyurt",
      "Kizlyar",
      "Kumukh",
      "Kyakhulay",
      "Makhachkala",
      "Miatli",
      "Tsurib"
    ],
    "Irkutskaya Oblast'": [
      "Angarsk",
      "Bodaybo",
      "Nizhneudinsk",
      "Sayansk",
      "Shelekhov",
      "Svirsk",
      "Zima",
      "Bratsk",
      "Irkutsk"
    ],
    "Ivanovskaya Oblast'": [
      "Furmanov",
      "Kineshma",
      "Shuya",
      "Vichuga",
      "Ivanovo"
    ],
    "Jewish Autonomous Oblast": ["Birobidzhan"],
    "Kabardino-Balkarskaya Respublika": [
      "Lechinkay",
      "Nartkala",
      "Terek",
      "Terskol",
      "Tyrnyauz",
      "Vol'nyy Aul",
      "Baksan"
    ],
    "Kaliningradskaya Oblast'": [
      "Baltiysk",
      "Gusev",
      "Neman",
      "Sovetsk",
      "Svetlogorsk",
      "Zelenogradsk",
      "Kaliningrad"
    ],
    "Kaluzhskaya Oblast'": ["Maloyaroslavets", "Tarusa", "Kaluga", "Obninsk"],
    Karelia: [
      "Belomorsk",
      "Kondopoga",
      "Kostomuksha",
      "Olonets",
      "Petrozavodsk",
      "Segezha",
      "Sortavala"
    ],
    "Kemerovskaya Oblast'": [
      "Belovo",
      "Krasnobrodskiy",
      "Mariinsk",
      "Mezhdurechensk",
      "Myski",
      "Osinniki",
      "Sheregesh",
      "Tashtagol",
      "Tayga",
      "Yurga",
      "Kemerovo",
      "Novokuznetsk",
      "Yaya"
    ],
    Khabarovsk: ["Amursk", "Komsomolsk-on-Amur", "Vanino", "Khabarovsk"],
    "Khanty-Mansia": [
      "Khanty-Mansiysk",
      "Kogalym",
      "Langepas",
      "Megion",
      "Nefteyugansk",
      "Nizhnevartovsk",
      "Nyagan",
      "Pyt-Yakh",
      "Sovetskiy",
      "Uray",
      "Yugorsk",
      "Surgut"
    ],
    "Kirovskaya Oblast'": ["Kirs", "Slobodskoy", "Kirov"],
    Komi: [
      "Pechora",
      "Sosnogorsk",
      "Usinsk",
      "Vorkuta",
      "Vuktyl",
      "Syktyvkar",
      "Ukhta"
    ],
    "Kostromskaya Oblast'": ["Galich", "Nerekhta", "Buy", "Kostroma"],
    "Krasnodarskiy Kray": [
      "Abinsk",
      "Abrau-Dyurso",
      "Adler",
      "Akhtyrskiy",
      "Apsheronsk",
      "Armavir",
      "Belaya Glina",
      "Belorechensk",
      "Blagovetschenskaya",
      "Dagomys",
      "Dinskaya",
      "Gelendzhik",
      "Glubokiy",
      "Golubitskaya",
      "Goryachiy Klyuch",
      "Kalininskaya",
      "Kanevskaya",
      "Kazanskaya",
      "Khosta",
      "Korenovsk",
      "Krasnaya Polyana",
      "Kropotkin",
      "Krymsk",
      "Kudepsta",
      "Kurganinsk",
      "Labinsk",
      "Lazarevskoye",
      "Leningradskaya",
      "Novokubansk",
      "Novomikhaylovskiy",
      "Novotitarovskaya",
      "Orel-Izumrud",
      "Otradnaya",
      "Pashkovskiy",
      "Pavlovskaya",
      "Platnirovskaya",
      "Primorsko-Akhtarsk",
      "Shepsi",
      "Starominskaya",
      "Starotitarovskaya",
      "Sukko",
      "Temryuk",
      "Tikhoretsk",
      "Timashëvsk",
      "Tuapse",
      "Vardane",
      "Vityazevo",
      "Vyshestebliyevskaya",
      "Vysokoye",
      "Yelizavetinskaya",
      "Yeysk",
      "Anapa",
      "Krasnodar",
      "Novorossiysk",
      "Sochi"
    ],
    "Krasnoyarskiy Kray": [
      "Achinsk",
      "Baykit",
      "Borodino",
      "Divnogorsk",
      "Dudinka",
      "Lesosibirsk",
      "Minusinsk",
      "Nazarovo",
      "Norilsk",
      "Shushenskoye",
      "Solnechnyy",
      "Sosnovoborsk",
      "Teya",
      "Uyar",
      "Yeniseysk",
      "Zelenogorsk",
      "Zheleznogorsk",
      "Kansk",
      "Krasnoyarsk"
    ],
    "Kurskaya Oblast'": ["Kurchatov", "Zheleznogorsk", "Kursk"],
    "Leningradskaya Oblast'": [
      "Boksitogorsk",
      "Ivangorod",
      "Kingisepp",
      "Kirishi",
      "Kommunar",
      "Luga",
      "Otradnoye",
      "Sertolovo",
      "Slantsy",
      "Svetogorsk",
      "Tikhvin",
      "Tosno",
      "Volkhov",
      "Vsevolozhsk",
      "Gatchina",
      "Vyborg"
    ],
    "Lipetskaya Oblast'": ["Chaplygin", "Dankov", "Yelets", "Lipetsk"],
    "Magadanskaya Oblast'": ["Magadan"],
    Moscow: [
      "Amin'yevo",
      "Annino",
      "Babushkin",
      "Bibirevo",
      "Brateyevo",
      "Chertanovo Yuzhnoye",
      "Davydkovo",
      "Gol'yanovo",
      "Ivanovskoye",
      "Kapotnya",
      "Khimki",
      "Kolomenskoye",
      "Kotlovka",
      "Kozeyevo",
      "Lefortovo",
      "Leonovo",
      "Lianozovo",
      "Likhobory",
      "Luzhniki",
      "Lyublino",
      "Mar'ina Roshcha",
      "Metrogorodok",
      "Mikhalkovo",
      "Moskovskiy",
      "Nagornyy",
      "Nekrasovka",
      "Nikol'skoye",
      "Nikulino",
      "Novokhovrino",
      "Novo-Peredelkino",
      "Novovladykino",
      "Novyye Cheremushki",
      "Ochakovo-Matveyevskoye",
      "Orekhovo-Borisovo",
      "Orekhovo-Borisovo Severnoye",
      "Ostankinskiy",
      "Otradnoye",
      "Perovo",
      "Pokrovskoye-Streshnevo",
      "Ramenki",
      "Rostokino",
      "Rublevo",
      "Ryazanskiy",
      "Severnyy",
      "Shchukino",
      "Sokol",
      "Sokol'niki",
      "Solntsevo",
      "Strogino",
      "Sviblovo",
      "Taganskiy",
      "Tekstil'shchiki",
      "Troitsk",
      "Troparevo",
      "Tsaritsyno",
      "Tyoply Stan",
      "Vagonoremont",
      "Vatutino",
      "Veshnyaki",
      "Vnukovo",
      "Vorob'yovo",
      "Yaroslavskiy",
      "Yasenevo",
      "Zamoskvorech'ye",
      "Zapadnoye Degunino",
      "Zelenograd",
      "Zhulebino",
      "Zyuzino",
      "Moscow"
    ],
    "Moscow Oblast": [
      "Balashikha",
      "Bronnitsy",
      "Chekhov",
      "Dedovsk",
      "Dmitrov",
      "Domodedovo",
      "Dzerzhinsky",
      "Elektrogorsk",
      "Elektrostal",
      "Fryanovo",
      "Fryazino",
      "Istra",
      "Kashira",
      "Khimki",
      "Klimovsk",
      "Kolomna",
      "Korolyov",
      "Krasnogorsk",
      "Krasnozavodsk",
      "Krasnoznamensk",
      "Kubinka",
      "Kupavna",
      "Lobnya",
      "Lytkarino",
      "Lyubertsy",
      "Malakhovka",
      "Monino",
      "Mozhaysk",
      "Mytishchi",
      "Noginsk",
      "Odintsovo",
      "Pavlovskiy Posad",
      "Peresvet",
      "Protvino",
      "Pushchino",
      "Pushkino",
      "Ramenskoye",
      "Ruza",
      "Selyatino",
      "Sergiyev Posad",
      "Serpukhov",
      "Shatura",
      "Solnechnogorsk",
      "Stupino",
      "Tomilino",
      "Voskresensk",
      "Zhukovskiy",
      "Zvenigorod",
      "Dubna",
      "Klin",
      "Korolev",
      "Podolsk",
      "Reutov",
      "Vidnoye"
    ],
    Murmansk: [
      "Apatity",
      "Kirovsk",
      "Kola",
      "Kovdor",
      "Monchegorsk",
      "Nikel",
      "Ostrovnoy",
      "Polyarnyy",
      "Severomorsk",
      "Teriberka",
      "Zaozersk",
      "Murmansk"
    ],
    "Nizhegorodskaya Oblast'": [
      "Arzamas",
      "Bogorodsk",
      "Chkalovsk",
      "Dzerzhinsk",
      "Gorodets",
      "Kstovo",
      "Lyskovo",
      "Mulino",
      "Nizhniy Novgorod",
      "Pavlovo",
      "Semyonov",
      "Vyksa",
      "Sarov"
    ],
    "North Ossetia": [
      "Ardon",
      "Beslan",
      "Mozdok",
      "Verkhniy Fiagdon",
      "Vladikavkaz"
    ],
    "Novosibirskaya Oblast'": [
      "Akademgorodok",
      "Berdsk",
      "Iskitim",
      "Krasnoobsk",
      "Kuybyshev",
      "Tolmachevo",
      "Ubinskoye",
      "Novosibirsk"
    ],
    "Omskaya Oblast'": ["Kalachinsk", "Omsk", "Tara"],
    "Orenburgskaya Oblast'": [
      "Abdulino",
      "Buguruslan",
      "Buzuluk",
      "Kuvandyk",
      "Orsk",
      "Gay",
      "Novotroitsk",
      "Orenburg"
    ],
    "Penzenskaya Oblast'": ["Kuznetsk", "Serdobsk", "Zarechnyy", "Penza"],
    "Perm Krai": [
      "Aleksandrovsk",
      "Berezniki",
      "Chaykovskiy",
      "Chusovoy",
      "Dobryanka",
      "Ocher",
      "Osa",
      "Polazna",
      "Solikamsk",
      "Kungur",
      "Perm"
    ],
    "Primorskiy Kray": [
      "Artëm",
      "Dalnegorsk",
      "Fokino",
      "Kavalerovo",
      "Khorol",
      "Lesozavodsk",
      "Nakhodka",
      "Novonikol'sk",
      "Novopokrovka",
      "Partizansk",
      "Pokrovka",
      "Russkiy",
      "Slavyanka",
      "Trudovoye",
      "Ussuriysk",
      "Vladivostok"
    ],
    "Pskovskaya Oblast'": ["Opochka", "Ostrov", "Velikiye Luki", "Pskov"],
    "Respublika Adygeya": ["Enem", "Maykop"],
    "Respublika Khakasiya": ["Chernogorsk", "Sayanogorsk", "Abakan"],
    "Respublika Mordoviya": ["Kovylkino", "Saransk"],
    Rostov: [
      "Aksay",
      "Azov",
      "Bataysk",
      "Donetsk",
      "Gukovo",
      "Konstantinovsk",
      "Kuleshovka",
      "Novocherkassk",
      "Rostov-na-Donu",
      "Samarskoye",
      "Severnyy",
      "Shakhty",
      "Taganrog",
      "Zverevo",
      "Rostov-on-Don",
      "Volgodonsk"
    ],
    "Ryazanskaya Oblast'": [
      "Kasimov",
      "Mikhaylov",
      "Ryazhsk",
      "Sasovo",
      "Ryazan"
    ],
    "Samarskaya Oblast'": ["Tolyatti", "Zhigulevsk", "Samara"],
    "Saratovskaya Oblast'": [
      "Atkarsk",
      "Balakovo",
      "Balashov",
      "Krasnoarmeysk",
      "Petrovsk",
      "Pugachev",
      "Marks",
      "Saratov"
    ],
    "Smolenskaya Oblast'": ["Gagarin", "Yartsevo", "Smolensk"],
    "Stavropol'skiy Kray": [
      "Aleksandriya",
      "Georgiyevsk",
      "Goryachevodskiy",
      "Kislovodsk",
      "Kurskaya",
      "Lermontov",
      "Mineralnye Vody",
      "Nevinnomyssk",
      "Stavropol'",
      "Svetlograd",
      "Svobody",
      "Yessentuki",
      "Yessentukskaya",
      "Zheleznovodsk",
      "Pyatigorsk",
      "Stavropol"
    ],
    "St.-Petersburg": [
      "Admiralteisky",
      "Avtovo",
      "Centralniy",
      "Dachnoye",
      "Grazhdanka",
      "Kolomyagi",
      "Kolpino",
      "Komendantsky aerodrom",
      "Krasnogvargeisky",
      "Kronshtadt",
      "Kupchino",
      "Kushelevka",
      "Lakhtinskiy",
      "Lomonosov",
      "Novaya Derevnya",
      "Obukhovo",
      "Ozerki",
      "Pavlovsk",
      "Pesochnyy",
      "Petergof",
      "Peterhof",
      "Petrogradka",
      "Pushkin",
      "Repino",
      "Sestroretsk",
      "Shushary",
      "Sosnovaya Polyana",
      "Sosnovka",
      "Staraya Derevnya",
      "St Petersburg",
      "Uritsk",
      "Ust'-Izhora",
      "Vasyl'evsky Ostrov",
      "Zelenogorsk",
      "Saint Petersburg"
    ],
    "Sverdlovskaya Oblast'": [
      "Aramil",
      "Asbest",
      "Irbit",
      "Kamyshlov",
      "Karpinsk",
      "Kirovgrad",
      "Krasnoufimsk",
      "Kushva",
      "Lesnoy",
      "Nizhny Tagil",
      "Pervouralsk",
      "Polevskoy",
      "Revda",
      "Serov",
      "Sredneuralsk",
      "Verkhnyaya Pyshma",
      "Verkhnyaya Salda",
      "Volchansk",
      "Arti",
      "Yekaterinburg"
    ],
    "Tambovskaya Oblast'": [
      "Kirsanov",
      "Kotovsk",
      "Rasskazovo",
      "Michurinsk",
      "Tambov"
    ],
    Tatarstan: [
      "Buinsk",
      "Chistopol'",
      "Leninogorsk",
      "Sviyazhsk",
      "Yelabuga",
      "Zainsk",
      "Zelenodolsk",
      "Kazan",
      "Naberezhnyye Chelny",
      "Nizhnekamsk"
    ],
    "Tomskaya Oblast'": [
      "Asino",
      "Kolpashevo",
      "Seversk",
      "Strezhevoy",
      "Tomsk"
    ],
    "Transbaikal Territory": ["Aginskoye", "Baley", "Borzya", "Chita"],
    "Tul'skaya Oblast'": [
      "Aleksin",
      "Kimovsk",
      "Novomoskovsk",
      "Suvorov",
      "Uzlovaya",
      "Donskoy",
      "Tula"
    ],
    "Tverskaya Oblast'": [
      "Andreapol'",
      "Bologoye",
      "Kimry",
      "Konakovo",
      "Ostashkov",
      "Rzhev",
      "Torzhok",
      "Udomlya",
      "Vydropuzhsk",
      "Zubtsov",
      "Kashin",
      "Tver"
    ],
    "Tyumenskaya Oblast'": ["Borovskiy", "Ishim", "Yalutorovsk", "Tyumen"],
    "Udmurtskaya Respublika": [
      "Glazov",
      "Igra",
      "Kez",
      "Mozhga",
      "Sarapul",
      "Votkinsk",
      "Izhevsk",
      "Uva"
    ],
    "Ulyanovsk Oblast": ["Barysh", "Dimitrovgrad", "Inza", "Ulyanovsk"],
    "Vladimirskaya Oblast'": [
      "Aleksandrov",
      "Kirzhach",
      "Murom",
      "Pokrov",
      "Kovrov",
      "Vladimir"
    ],
    "Volgogradskaya Oblast'": [
      "Dubovka",
      "Frolovo",
      "Kamyshin",
      "Pallasovka",
      "Volzhskiy",
      "Volgograd"
    ],
    "Voronezhskaya Oblast'": [
      "Borisoglebsk",
      "Buturlinovka",
      "Kalach",
      "Liski",
      "Pavlovsk",
      "Novovoronezh",
      "Voronezh"
    ],
    "Yamalo-Nenets": [
      "Gubkinskiy",
      "Labytnangi",
      "Novy Urengoy",
      "Noyabrsk",
      "Salekhard",
      "Nadym"
    ],
    "Yaroslavskaya Oblast'": ["Danilov", "Rybinsk", "Uglich", "Yaroslavl"]
  },
  Rwanda: {
    "Northern Province": ["Musanze"],
    "Southern Province": ["Nzega"],
    "Western Province": ["Kibuye"],
    Kigali: ["Kigali"]
  },
  "Saint Barthélemy": {},
  "Saint Helena, Ascension and Tristan da Cunha": {
    Ascension: ["Georgetown"],
    "Saint Helena": ["Jamestown"],
    "Tristan da Cunha": ["Edinburgh of the Seven Seas"]
  },
  "Saint Lucia": {
    Castries: ["Bisee", "Castries"],
    "Dennery Quarter": ["Dennery"],
    "Gros-Islet": ["Gros Islet"],
    Soufriere: ["Soufriere"]
  },
  "Saint Pierre and Miquelon": { "Saint-Pierre": ["Saint-Pierre"] },
  "Saint Vincent and the Grenadines": {
    Grenadines: ["Port Elizabeth"],
    "Parish of Saint George": ["Kingstown"],
    "Saint George": ["Kingstown", "Kingstown Park"]
  },
  Samoa: { Gagaifomauga: ["Safotu"], Tuamasaga: ["Apia"] },
  "San Marino": {
    Domagnano: ["Domagnano"],
    "San Marino": ["San Marino"],
    Serravalle: ["Serravalle"]
  },
  "Sao Tome and Principe": { "São Tomé Island": ["São Tomé"] },
  "Saudi Arabia": {
    "Al Jawf": ["Al Qurayyat", "Qurayyat", "Sakaka", "Sakakah", "Tubarjal"],
    "Al-Qassim": [
      "Al Fuwayliq",
      "Al Mithnab",
      "Ar Rass",
      "Buraidah",
      "Buraydah",
      "Unaizah"
    ],
    Makkah: [
      "Al Hada",
      "Al Jumum",
      "Al Muwayh",
      "Ash Shafa",
      "Jeddah",
      "Mecca",
      "Rabigh",
      "Ta'if",
      "Turabah"
    ],
    "Mintaqat al Bahah": ["Al Bahah"],
    "Mintaqat `Asir": [
      "Abha",
      "Al Majaridah",
      "An Nimas",
      "Khamis Mushait",
      "Qal`at Bishah"
    ],
    "Mintaqat Ha'il": ["Hayil"],
    "Mintaqat Tabuk": ["Duba", "Tabuk", "Umm Lajj"],
    "Al Madinah al Munawwarah": [
      "Al 'Ula",
      "Sultanah",
      "Yanbu` al Bahr",
      "Medina",
      "Yanbu"
    ],
    "Ar Riyāḑ": [
      "Ad Dawadimi",
      "Ad Dilam",
      "Al Kharj",
      "As Sulayyil",
      "Az Zulfi",
      "Marat",
      "Sajir",
      "shokhaib",
      "Tumayr",
      "Riyadh"
    ],
    "'Asir": ["Bisha", "Khamis Mushait", "Abha"],
    "Eastern Province": [
      "Abqaiq",
      "Al Awjam",
      "Al Battaliyah",
      "Al Hufuf",
      "Al Jubayl",
      "Al Khafji",
      "Al Mubarraz",
      "Al Munayzilah",
      "Al Mutayrifi",
      "Al Qarah",
      "Al Qatif",
      "Al Qaysumah",
      "At Taraf",
      "At Tubi",
      "Julayjilah",
      "Mulayjah",
      "Rahimah",
      "Safwa",
      "Sayhat",
      "Dammam",
      "Dhahran",
      "Jubail",
      "Khobar"
    ],
    Jizan: [
      "Abu `Arish",
      "Ad Darb",
      "Jizan",
      "Misliyah",
      "Mizhirah",
      "Samitah",
      "Sabya"
    ],
    "Makkah Province": ["Rabigh", "Jeddah", "Mecca"],
    Najran: ["Najran"],
    "Northern Borders": ["'Ar'ar", "Turayf", "Arar"],
    Tabuk: ["Tabuk"]
  },
  Senegal: {
    Diourbel: ["Touba"],
    Kaffrine: ["Koungheul"],
    Kaolack: ["Kaolack"],
    Kolda: ["Kolda"],
    Louga: ["Louga"],
    Matam: ["Matam"],
    "Region de Kaffrine": ["Gama"],
    "Saint-Louis": ["Saint-Louis"],
    Thies: ["Nguekhokh"],
    Ziguinchor: ["Ziguinchor"],
    Dakar: ["Grand Dakar", "Mermoz Boabab", "Pikine", "Dakar"]
  },
  Serbia: {
    "Autonomna Pokrajina Vojvodina": [
      "Backa Palanka",
      "Mali Igos",
      "Novi Sad",
      "Pancevo",
      "Petrovaradin",
      "Sremska Mitrovica",
      "Subotica",
      "Vrsac",
      "Zrenjanin"
    ],
    Branicevo: ["Požarevac"],
    Jablanica: ["Leskovac"],
    Kolubara: ["Valjevo"],
    Macva: ["Sabac"],
    Nisava: ["Niš"],
    Pcinja: ["Vranje"],
    Pirot: ["Pirot"],
    Podunavlje: ["Smederevo", "Smederevska Palanka"],
    Pomoravlje: ["Cuprija", "Jagodina"],
    Toplica: ["Prokuplje"],
    Zajecar: ["Knjazevac", "Zajecar"],
    Belgrade: ["Lazarevac", "Obrenovac", "Surcin", "Zemun", "Belgrade"],
    Bor: ["Negotin", "Bor"],
    "Central Serbia": [
      "Arangelovac",
      "Barajevo",
      "Batocina",
      "Bor",
      "Cukarica",
      "Cuprija",
      "Despotovac",
      "Gornji Milanovac",
      "Jagodina",
      "Knjazevac",
      "Kragujevac",
      "Kraljevo",
      "Krupanj",
      "Krusevac",
      "Lazarevac",
      "Leskovac",
      "Nis",
      "Nova Varos",
      "Novi Beograd",
      "Novi Pazar",
      "Obrenovac",
      "Palilula",
      "Paracin",
      "Petrovac",
      "Prokuplje",
      "Rakovica",
      "Raska",
      "Sabac",
      "Savski Venac",
      "Smederevo",
      "Smederevska Palanka",
      "Sopot",
      "Stari Grad",
      "Surcin",
      "Trstenik",
      "Uzice",
      "Valjevo",
      "Vozdovac",
      "Vracar",
      "Vranje",
      "Zabari",
      "Zajecar",
      "Zemun",
      "Zvezdara",
      "Belgrade"
    ],
    Morava: ["Gornji Milanovac", "Čačak"],
    Rasina: ["Grad", "Trstenik", "Kruševac"],
    Raska: ["Kraljevo", "Vrnjacka Banja", "Novi Pazar"],
    Sumadija: ["Kragujevac"],
    Vojvodina: [
      "Backa Palanka",
      "Becej",
      "Kanjiza",
      "Kovin",
      "Nova Pazova",
      "Pančevo",
      "Petrovaradin",
      "Ruma",
      "Sombor",
      "Sremska Mitrovica",
      "Stara Pazova",
      "Veternik",
      "Vrbas",
      "Vršac",
      "Zrenjanin",
      "Novi Sad",
      "Senta",
      "Subotica"
    ],
    Zlatibor: ["Užice"]
  },
  Seychelles: {
    "Beau Vallon": ["Beau Vallon"],
    "Bel Ombre": ["Bel Ombre"],
    Cascade: ["Cascade"],
    "English River": ["Victoria"],
    "Port Glaud": ["Port Glaud"],
    Takamaka: ["Takamaka"]
  },
  "Sierra Leone": {
    "Eastern Province": ["Koidu"],
    "Western Area": ["Freetown"]
  },
  Singapore: {
    "Central Singapore Community Development Council": ["Singapore"]
  },
  Slovakia: {
    "Banska Bystrica": [
      "Banská Bystrica",
      "Banská Štiavnica",
      "Brezno",
      "Detva",
      "Lučenec",
      "Revúca",
      "Rimavská Sobota",
      "Svaety Anton",
      "Zvolen"
    ],
    Nitra: [
      "Banovce nad Bebravou",
      "Bojnice",
      "Komárno",
      "Levice",
      "Nitra",
      "Nové Zámky",
      "Partizánske",
      "Prievidza",
      "Púchov",
      "Šaľa",
      "Sellye",
      "Štúrovo",
      "Šurany",
      "Zlate Moravce"
    ],
    Trnavsky: ["Dunajska Streda", "Piestany", "Samorin", "Trnava"],
    Zilinsky: ["Martin", "Ruzomberok", "Zilina"],
    Bratislava: [
      "Malacky",
      "Pezinok",
      "Senec",
      "Stupava",
      "Svätý Jur",
      "Bratislava"
    ],
    Kosice: [
      "Dobsina",
      "Košice",
      "Michalovce",
      "Rožňava",
      "Spišská Nová Ves",
      "Trebišov",
      "Vinne"
    ],
    Presov: [
      "Bardejov",
      "Humenné",
      "Kežmarok",
      "Lipany",
      "Medzilaborce",
      "Poprad",
      "Prešov",
      "Sabinov",
      "Snina",
      "Stará Ľubovňa",
      "Strba",
      "Stropkov",
      "Vranov nad Topl'ou",
      "Vysoké Tatry",
      "Svidník"
    ],
    Trencin: ["Myjava", "Považská Bystrica", "Trenčín"],
    Trnava: [
      "Dunajská Streda",
      "Galanta",
      "Hlohovec",
      "Piešťany",
      "Samorin",
      "Senica",
      "Skalica",
      "Holic",
      "Trnava"
    ],
    Zilina: [
      "Bytča",
      "Čadca",
      "Dolný Kubín",
      "Kysucké Nové Mesto",
      "Liptovský Mikuláš",
      "Ružomberok",
      "Martin",
      "Žilina"
    ]
  },
  Slovenia: {
    Bled: ["Bled"],
    Borovnica: ["Borovnica"],
    Brda: ["Dobrovo"],
    Izola: ["Izola"],
    "Izola-Isola": ["Izola"],
    Jesenice: ["Jesenice"],
    Jezersko: ["Zgornje Jezersko"],
    Kamnik: ["Kamnik"],
    "Koper-Capodistria": ["Koper"],
    "Kostanjevica na Krki": ["Kostanjevica na Krki"],
    Kranj: ["Kranj"],
    "Kranjska Gora": ["Kranjska Gora", "Mojstrana"],
    Lasko: ["Lasko"],
    Logatec: ["Logatec"],
    "Mestna Obcina Novo mesto": ["Novo Mesto"],
    Metlika: ["Metlika"],
    "Nova Gorica": ["Nova Gorica"],
    "Novo Mesto": ["Novo Mesto"],
    "Obcina Domzale": ["Domžale"],
    Pesnica: ["Pesnica pri Mariboru"],
    "Piran-Pirano": ["Piran", "Portoroz"],
    Ptuj: ["Ptuj"],
    Sevnica: ["Sevnica"],
    "Škofja Loka": ["Škofja Loka"],
    Solcava: ["Solcava"],
    Trbovlje: ["Trbovlje"],
    Trebnje: ["Mirna"],
    Velenje: ["Velenje"],
    Celje: ["Celje"],
    Koper: ["Koper"],
    Ljubljana: ["Ljubljana"],
    Maribor: ["Maribor"],
    "Murska Sobota": ["Murska Sobota"]
  },
  "Solomon Islands": {
    "Guadalcanal Province": ["Honiara"],
    "Western Province": ["Gizo"]
  },
  Somalia: {
    Banaadir: ["Mogadishu"],
    "Lower Juba": ["Kismayo"],
    "Lower Shabeelle": ["Merca"],
    Nugaal: ["Eyl", "Garoowe"],
    Sool: [],
    Togdheer: ["Burao"],
    "Woqooyi Galbeed": ["Berbera", "Hargeisa", "Hargeysa"],
    Awdal: ["Baki"],
    Bari: ["Bosaso"]
  },
  "South Africa": {
    Limpopo: [
      "Duiwelskloof",
      "Giyani",
      "Hoedspruit",
      "Jane Furse",
      "Louis Trichardt",
      "Mankoeng",
      "Modimolle",
      "Mokopane",
      "Nkowakowa",
      "Phalaborwa",
      "Polokwane",
      "Thabazimbi",
      "Thohoyandou",
      "Thulamahashi",
      "Tzaneen",
      "Warmbaths"
    ],
    "North-West": [
      "Brits",
      "Ga-Rankuwa",
      "Klerksdorp",
      "Lichtenburg",
      "Mmabatho",
      "Mogwase",
      "Pampierstad",
      "Potchefstroom",
      "Rustenburg"
    ],
    "Eastern Cape": [
      "Adelaide",
      "Aliwal North",
      "Bhisho",
      "Butterworth",
      "Cradock",
      "Fort Beaufort",
      "Graaff Reinet",
      "Graaff-Reinet",
      "Grahamstown",
      "Jeffrey's Bay",
      "Kirkwood",
      "Kruisfontein",
      "Port Alfred",
      "Uitenhage",
      "East London",
      "Mthatha",
      "Port Elizabeth",
      "Queenstown"
    ],
    Gauteng: [
      "Alberton",
      "Benoni",
      "Brakpan",
      "Bronkhorstspruit",
      "Brooklyn",
      "Carletonville",
      "Cullinan",
      "Diepsloot",
      "Discovery",
      "Edenvale",
      "Germiston",
      "Heidelberg",
      "Krugersdorp",
      "Mabopane",
      "Midstream",
      "Modderfontein",
      "Muldersdriseloop",
      "Randburg",
      "Randfontein",
      "Roodepoort",
      "Springs",
      "Tembisa",
      "Vanderbijlpark",
      "Vereeniging",
      "Westonaria",
      "Boksburg",
      "Centurion",
      "Johannesburg",
      "Midrand",
      "Nigel",
      "Pretoria",
      "Soweto"
    ],
    "KwaZulu-Natal": [
      "Ballitoville",
      "Berea",
      "Dundee",
      "Empangeni",
      "Eshowe",
      "eSikhawini",
      "Greytown",
      "Hluhluwe",
      "Howick",
      "Ixopo",
      "Kokstad",
      "KwaDukuza",
      "Ladysmith",
      "Margate",
      "Mpumalanga",
      "Ndwedwe",
      "Port Shepstone",
      "Scottburgh",
      "Stanger",
      "Ulundi",
      "Umkomaas",
      "Vryheid",
      "Durban",
      "Newcastle",
      "Pietermaritzburg",
      "Richards Bay"
    ],
    Mpumalanga: [
      "Barberton",
      "Bethal",
      "Carolina",
      "Ermelo",
      "Graksop",
      "Hendrina",
      "Komatipoort",
      "Kriel",
      "Lydenburg",
      "Nelspruit",
      "Piet Retief",
      "Secunda",
      "White River",
      "Witbank",
      "Balfour",
      "Delmas",
      "Middelburg",
      "Standerton"
    ],
    "Northern Cape": [
      "De Aar",
      "Douglas",
      "Prieska",
      "Upington",
      "Williston",
      "Kimberley"
    ],
    "Orange Free State": [
      "Bethlehem",
      "Boshof",
      "Bothaville",
      "Harrismith",
      "Kroonstad",
      "Parys",
      "Phuthaditjhaba",
      "Reitz",
      "Sasolburg",
      "Villiers",
      "Bloemfontein",
      "Welkom"
    ],
    "Province of North West": [
      "Bloemhof",
      "Christiana",
      "Klerksdorp",
      "Lichtenburg",
      "Mafikeng",
      "Vryburg",
      "Zeerust",
      "Brits",
      "Orkney",
      "Potchefstroom",
      "Rustenburg"
    ],
    "Western Cape": [
      "Ashton",
      "Beaufort West",
      "Bergvliet",
      "Bredasdorp",
      "Caledon",
      "Ceres",
      "Clanwilliam",
      "Constantia",
      "Grabouw",
      "Hermanus",
      "Kraaifontein",
      "Laingsburg",
      "Lansdowne",
      "Montagu",
      "Newlands",
      "Oudtshoorn",
      "Paarl",
      "Piketberg",
      "Plettenberg Bay",
      "Retreat",
      "Riversdale",
      "Rondebosch",
      "Rosebank",
      "Saldanha",
      "Sunset Beach",
      "Swellendam",
      "Vredendal",
      "Wellington",
      "Wilderness",
      "Worcester",
      "Atlantis",
      "Cape Town",
      "George",
      "Knysna",
      "Malmesbury",
      "Mossel Bay",
      "Robertson",
      "Stellenbosch"
    ]
  },
  "South Sudan": {
    "Central Equatoria": ["Juba"],
    Lakes: ["Rumbek"],
    "": ["Juba"]
  },
  Spain: {
    Melilla: ["Melilla"],
    Andalusia: [
      "Adra",
      "Aguilar",
      "Alajar",
      "Albolote",
      "Albox",
      "Alcalá de Guadaira",
      "Alcaudete",
      "Aljaraque",
      "Almonte",
      "Almuñécar",
      "Alora",
      "Antequera",
      "Arcos de la Frontera",
      "Armilla",
      "Arriate",
      "Atarfe",
      "Ayamonte",
      "Baena",
      "Bailen",
      "Barbate de Franco",
      "Baza",
      "Benahavis",
      "Berja",
      "Bormujos",
      "Brenes",
      "Canete de las Torres",
      "Cantillana",
      "Carmona",
      "Cartama",
      "Cartaya",
      "Chiclana de la Frontera",
      "Chipiona",
      "Conil de la Frontera",
      "Cordoba",
      "Cordova",
      "Coria del Río",
      "Dos Hermanas",
      "Écija",
      "El Arahal",
      "El Ejido",
      "El Puerto de Santa María",
      "Espartinas",
      "Estepa",
      "Frigiliana",
      "Fuente Palmera",
      "Gibraleón",
      "Gines",
      "Guadix",
      "Guejar-Sierra",
      "Guillena",
      "Hinojosa del Duque",
      "Huetor Vega",
      "Illora",
      "Isla Cristina",
      "Iznalloz",
      "Jerez de la Frontera",
      "Jodar",
      "La Algaba",
      "La Carlota",
      "La Carolina",
      "La Herradura",
      "La Línea de la Concepción",
      "La Rinconada",
      "Las Gabias",
      "Lebrija",
      "Linares",
      "Loja",
      "Lora del Rio",
      "Los Barrios",
      "Los Palacios y Villafranca",
      "Mairena del Alcor",
      "Mairena del Aljarafe",
      "Mancha Real",
      "Manilva",
      "Maracena",
      "Marchena",
      "Martos",
      "Mijas",
      "Moguer",
      "Mojacar",
      "Montilla",
      "Motril",
      "Nerja",
      "Nijar",
      "Ogijares",
      "Ojen",
      "Osuna",
      "Peligros",
      "Pilas",
      "Pinos Puente",
      "Posadas",
      "Pozoblanco",
      "Puente-Genil",
      "Punta Umbría",
      "Rincon de la Victoria",
      "Roquetas de Mar",
      "Rute",
      "San Fernando",
      "San Jose",
      "San Juan de Aznalfarache",
      "Sanlúcar de Barrameda",
      "Sanlucar la Mayor",
      "San Pedro",
      "San Roque",
      "Sierra",
      "Tarifa",
      "Torredonjimeno",
      "Torremolinos",
      "Torrox",
      "Úbeda",
      "Utrera",
      "Vejer de la Frontera",
      "Vélez-Málaga",
      "Vicar",
      "Villacarrillo",
      "Villamartin",
      "Villanueva de las Torres",
      "Zahara de los Atunes",
      "Zubia",
      "Aguadulce",
      "Algeciras",
      "Almería",
      "Andujar",
      "Baeza",
      "Barbate",
      "Benalmádena",
      "Cabra",
      "Cadiz",
      "Camas",
      "Coin",
      "Estepona",
      "Fuengirola",
      "Granada",
      "Huelva",
      "Jaén",
      "Lepe",
      "Lucena",
      "Málaga",
      "Marbella",
      "Puerto Real",
      "Ronda",
      "Rota",
      "Salobreña",
      "Sevilla",
      "Seville",
      "Tomares",
      "Ubrique",
      "Vera"
    ],
    Aragon: [
      "Ainsa",
      "Alcañiz",
      "Almozara",
      "Belchite",
      "Benasque",
      "Bierge",
      "Broto",
      "Calatayud",
      "Delicias",
      "Lecinena",
      "Montecanal",
      "Monzon",
      "Mora de Rubielos",
      "Puertolas",
      "Rubielos de Mora",
      "Sallent de Gallego",
      "Utebo",
      "Barbastro",
      "Fraga",
      "Huesca",
      "Jaca",
      "Tarazona",
      "Teruel",
      "Zaragoza"
    ],
    Asturias: [
      "Amieva",
      "Aviles",
      "Cangas de Onis",
      "Castandiello",
      "Castrillon",
      "Corvera de Asturias",
      "Figueras",
      "Llanes",
      "Luanco",
      "Mieres",
      "Natahoyo",
      "Nava",
      "Norena",
      "Oviedo",
      "Proaza",
      "Ribadesella",
      "Sama",
      "Gijon"
    ],
    "Balearic Islands": [
      "Alaró",
      "Alcúdia",
      "Calvià",
      "Camp de Mar",
      "Capdepera",
      "Ciutadella",
      "Deia",
      "Es Castell",
      "Felanitx",
      "Ferreries",
      "Ibiza",
      "Ibiza Town",
      "Lloret de Vistalegre",
      "Llucmajor",
      "Magaluf",
      "Mahon",
      "Manacor",
      "Marratxí",
      "Mercadal",
      "Pollença",
      "Porreres",
      "Port d'Alcudia",
      "Santa Eulària des Riu",
      "Santa Margalida",
      "Sant Antoni de Portmany",
      "Santanyí",
      "Santa Ponsa",
      "Sant Francesc de Formentera",
      "Sant Joan de Labritja",
      "Sant Josep de sa Talaia",
      "Sant Lluis",
      "S'Arenal",
      "ses Salines",
      "Soller",
      "Son Servera",
      "Valldemossa",
      "Andratx",
      "Inca",
      "Palma"
    ],
    "Basque Country": [
      "Algorta",
      "Amurrio",
      "Andoain",
      "Arrigorriaga",
      "Azkoitia",
      "Azpeitia",
      "Bakio",
      "Barakaldo",
      "Basauri",
      "Beasain",
      "Bermeo",
      "Durango",
      "Elexalde",
      "Erandio",
      "Ermua",
      "Fuenterrabia",
      "Galdakao",
      "Gasteiz / Vitoria",
      "Gernika-Lumo",
      "Getaria",
      "Getxo",
      "Hernani",
      "Irun",
      "Landa",
      "Lasarte",
      "Leioa",
      "Manaria",
      "Mondragón",
      "Mungia",
      "Onate",
      "Pasaia",
      "Plentzia",
      "Portugalete",
      "Santurtzi",
      "Santutxu",
      "Sestao",
      "Sopela",
      "Tolosa",
      "Vitoria-Gasteiz",
      "Zaldibar",
      "Zumaia",
      "Zumarraga",
      "Amorebieta",
      "Bergara",
      "Bilbao",
      "Eibar",
      "Elgoibar",
      "Errenteria",
      "Oria",
      "San Sebastian",
      "Zarautz"
    ],
    "Canary Islands": [
      "Adeje",
      "Agaete",
      "Agüimes",
      "Arafo",
      "Arucas",
      "Brena Alta",
      "Buenavista del Norte",
      "Candelaria",
      "Corralejo",
      "Cruce de Arinaga",
      "El Cotillo",
      "El Paso",
      "Gáldar",
      "Granadilla de Abona",
      "Guía de Isora",
      "Guimar",
      "Haría",
      "Icod de los Vinos",
      "La Guancha",
      "La Laguna",
      "La Oliva",
      "La Orotava",
      "Las Palmas de Gran Canaria",
      "Las Rosas",
      "Los Llanos de Aridane",
      "Los Realejos",
      "Mogán",
      "Nazaret",
      "Pajara",
      "Playa Blanca",
      "Playa de las Americas",
      "Playa del Ingles",
      "Puerto",
      "Puerto de la Cruz",
      "Puerto del Carmen",
      "Puerto del Rosario",
      "Puerto Rico",
      "Realejo Alto",
      "San Isidro",
      "San Miguel",
      "San Miguel De Abona",
      "Santa Brigida",
      "Santa Cruz de La Palma",
      "Santa Cruz de Tenerife",
      "Santa Úrsula",
      "Santiago del Teide",
      "Tacoronte",
      "Tegueste",
      "Teguise",
      "Tejeda",
      "Telde",
      "Teror",
      "Tias",
      "Tinajo",
      "Tuineje",
      "Vecindario",
      "Yaiza",
      "Antigua",
      "Arona",
      "Arrecife",
      "Guia",
      "Ingenio",
      "Maspalomas",
      "San Bartolomé",
      "Santa Lucia"
    ],
    Cantabria: [
      "Camargo",
      "Castro Urdiales",
      "Castro-Urdiales",
      "Comillas",
      "El Astillero",
      "Laredo",
      "Lierganes",
      "Los Corrales de Buelna",
      "Noja",
      "Ramales de la Victoria",
      "Reinosa",
      "San Vicente de la Barquera",
      "Torrelavega",
      "Santander"
    ],
    "Castille and León": [
      "Aranda de Duero",
      "Astorga",
      "Baltanas",
      "Bejar",
      "Bembibre",
      "Benavente",
      "Bercial",
      "Candeleda",
      "Chozas de Abajo",
      "Ciudad Rodrigo",
      "El Tiemblo",
      "Galende",
      "Hoyos del Espino",
      "Iguena",
      "León",
      "Medina del Campo",
      "Miranda de Ebro",
      "Morille",
      "Nava de la Asuncion",
      "Otero de Herreros",
      "Puebla de Sanabria",
      "Rueda",
      "Saldana",
      "Sariegos",
      "Segovia",
      "Soria",
      "Topas",
      "Toro",
      "Vega de Valcarce",
      "Villablino",
      "Villaquilambre",
      "Zamora",
      "Ávila",
      "Burgos",
      "Palencia",
      "Ponferrada",
      "Salamanca",
      "Valladolid"
    ],
    "Castille-La Mancha": [
      "Almansa",
      "Alovera",
      "Azuqueca de Henares",
      "Campo de Criptana",
      "Castillejo-Sierra",
      "Caudete",
      "Cobeta",
      "Consuegra",
      "Cotillas",
      "Cuenca",
      "Daimiel",
      "Fuensalida",
      "Guadalajara",
      "Hellin",
      "La Roda",
      "La Solana",
      "Ledanca",
      "Madridejos",
      "Manzanares",
      "Miguelturra",
      "Mora",
      "Mota del Cuervo",
      "Puertollano",
      "Siguenza",
      "Socuellamos",
      "Sonseca",
      "Talavera de la Reina",
      "Tarancon",
      "Tomelloso",
      "Torrijos",
      "Villafranca de los Caballeros",
      "Villarrobledo",
      "Zafra de Zancara",
      "Albacete",
      "Ciudad Real",
      "Illescas",
      "Toledo"
    ],
    Catalonia: [
      "Abrera",
      "Alcanar",
      "Alcarras",
      "Alcover",
      "Alella",
      "Altafulla",
      "Arenys de Mar",
      "Arenys de Munt",
      "Balaguer",
      "Banyoles",
      "Barbera del Valles",
      "Begur",
      "Bellver de Cerdanya",
      "Cabrils",
      "Cadaqués",
      "Caldes de Montbui",
      "Calonge",
      "Camprodon",
      "Canet de Mar",
      "Canovelles",
      "Cantallops",
      "Castellar del Valles",
      "Castellbisbal",
      "Castelldefels",
      "Castell-Platja d'Aro",
      "Centelles",
      "Cerdanyola del Valles",
      "Ciutat Vella",
      "Collbato",
      "Colldejou",
      "Corbera de Llobregat",
      "Corca",
      "Cornellà de Llobregat",
      "Cunit",
      "Deltebre",
      "Eixample",
      "el Catllar",
      "el Masnou",
      "el Prat de Llobregat",
      "El Vendrell",
      "Empuriabrava",
      "Esplugues de Llobregat",
      "Espot",
      "Falset",
      "Farrera",
      "Figueres",
      "Fornells de la Selva",
      "Gavà",
      "Gracia",
      "Guils de Cerdanya",
      "Gurb",
      "Horta-Guinardo",
      "La Bisbal",
      "la Bisbal d'Emporda",
      "la Garriga",
      "La Llagosta",
      "l'Ametlla de Mar",
      "La Pineda",
      "La Roca del Valles",
      "La Seu d'Urgell",
      "Les",
      "l'Escala",
      "les Corts",
      "Les Franqueses del Valles",
      "L'Hospitalet de Llobregat",
      "Llançà",
      "Llefia",
      "Lliçà de Vall",
      "Llívia",
      "Lloret de Mar",
      "Malgrat de Mar",
      "Matadepera",
      "MeteOlot",
      "Molins de Rei",
      "Mollerussa",
      "Mollet del Vallès",
      "Montblanc",
      "Montmeló",
      "Montornès del Vallès",
      "Navata",
      "Nou Barris",
      "Olesa de Montserrat",
      "Oliana",
      "Olot",
      "Palau-sator",
      "Pallejà",
      "Pals",
      "Parets del Vallès",
      "Peralada",
      "Piera",
      "Pineda de Mar",
      "Polinya",
      "Premia de Mar",
      "Regencos",
      "Ribes de Freser",
      "Ripoll",
      "Rubí",
      "Sallent",
      "Santa Coloma de Gramenet",
      "Santa Cristina d'Aro",
      "Sant Adrià de Besòs",
      "Santa Eulalia de Roncana",
      "Sant Andreu",
      "Sant Andreu de la Barca",
      "Sant Andreu de Palomar",
      "Sant Antoni",
      "Santa Perpetua de Mogoda",
      "Santa Susanna",
      "Sant Boi de Llobregat",
      "Sant Celoni",
      "Sant Cugat del Valles",
      "Sant Cugat del Vallès",
      "Sant Feliu de Guíxols",
      "Sant Feliu de Llobregat",
      "Sant Fruitos de Bages",
      "Sant Joan Despí",
      "Sant Joan de Vilatorrada",
      "Sant Just Desvern",
      "Sant Marti",
      "Sant Miquel de Fluvia",
      "Sant Pere de Ribes",
      "Sant Pere de Vilamajor",
      "Sant Pol de Mar",
      "Sant Quirze del Valles",
      "Sant Salvador de Guardiola",
      "Sants-Montjuic",
      "Sant Vicenç dels Horts",
      "Sarria-Sant Gervasi",
      "Solsona",
      "Tarrega",
      "Tavertet",
      "Tora de Riubregos",
      "Tordera",
      "Torroella de Montgri",
      "Tortosa",
      "Tossa de Mar",
      "Vallbona de les Monges",
      "Vallirana",
      "Vielha",
      "Vilademuls",
      "Vilafranca del Penedès",
      "Vilajuiga",
      "Vilanova d'Escornalbou",
      "Vilanova i la Geltrú",
      "Vilaseca",
      "Vilassar de Mar",
      "Vilobi d'Onyar",
      "Amposta",
      "Argentona",
      "Badalona",
      "Barcelona",
      "Berga",
      "Blanes",
      "Calafell",
      "Calella",
      "Cambrils",
      "Cardedeu",
      "Cubelles",
      "Esparreguera",
      "Girona",
      "Granollers",
      "Igualada",
      "Lleida",
      "Manlleu",
      "Manresa",
      "Martorell",
      "Mataró",
      "Moia",
      "Montgat",
      "Palafrugell",
      "Palamós",
      "Reus",
      "Ripollet",
      "Roses",
      "Sabadell",
      "Salou",
      "Salt",
      "Sitges",
      "Tarragona",
      "Terrassa",
      "Torelló",
      "Torredembarra",
      "Valls",
      "Vic",
      "Viladecans"
    ],
    Ceuta: ["Ceuta"],
    Extremadura: [
      "Albala",
      "Almendralejo",
      "Berrocalejo",
      "Jerte",
      "Jesus",
      "La Codosera",
      "Malpartida de Caceres",
      "Mérida",
      "Miajadas",
      "Montanchez",
      "Montijo",
      "Navalmoral de la Mata",
      "Salvatierra de los Barros",
      "Talayuela",
      "Trujillo",
      "Villanueva de la Serena",
      "Badajoz",
      "Cáceres",
      "Coria",
      "Don Benito",
      "Plasencia",
      "Zafra"
    ],
    Galicia: [
      "A Coruña",
      "A Guarda",
      "Ames",
      "Arteixo",
      "Avion",
      "Baiona",
      "Betanzos",
      "Boiro",
      "Bueu",
      "Caldas de Reis",
      "Cambre",
      "Cangas",
      "Cangas do Morrazo",
      "Carballo",
      "Carnota",
      "Cee",
      "Combarro",
      "Costa",
      "Culleredo",
      "Fene",
      "Ferrol",
      "Fisterra",
      "Lalin",
      "Laracha",
      "Monforte de Lemos",
      "Monterroso",
      "Narón",
      "Nigran",
      "O Grove",
      "Oleiros",
      "Ordes",
      "Poio",
      "Ponteareas",
      "Porrino",
      "Porto do Son",
      "Portomarin",
      "Redondela",
      "Rianxo",
      "Santa Comba",
      "Santa Marta de Ortigueira",
      "Santiago de Compostela",
      "Sanxenxo",
      "Tomino",
      "Tui",
      "Verin",
      "Vilalba",
      "Vilanova de Arousa",
      "Viveiro",
      "Xinzo de Limia",
      "Cambados",
      "Lugo",
      "Marin",
      "Moana",
      "Mos",
      "Ourense",
      "Pontevedra",
      "Sada",
      "Sarria",
      "Teo",
      "Vigo"
    ],
    "La Rioja": ["Calahorra", "Cenicero", "Haro", "Arnedo", "Logroño"],
    Madrid: [
      "Alcalá de Henares",
      "Alcobendas",
      "Alcorcón",
      "Aldea del Fresno",
      "Algete",
      "Alpedrete",
      "Arganda",
      "Arroyomolinos",
      "Barajas de Madrid",
      "Becerril de la Sierra",
      "Boadilla del Monte",
      "Brunete",
      "Buitrago del Lozoya",
      "Carabanchel",
      "Chamartin",
      "Chamberi",
      "Ciempozuelos",
      "Ciudad Lineal",
      "Collado Villalba",
      "Collado-Villalba",
      "Coslada",
      "El Alamo",
      "El Escorial",
      "Fuencarral-El Pardo",
      "Fuenlabrada",
      "Fuente el Saz",
      "Galapagar",
      "Hortaleza",
      "Humanes de Madrid",
      "Las Matas",
      "Las Rozas de Madrid",
      "Las Tablas",
      "Latina",
      "Lozoya",
      "Majadahonda",
      "Manzanares el Real",
      "Meco",
      "Mejorada del Campo",
      "Moncloa-Aravaca",
      "Moralzarzal",
      "Moratalaz",
      "Mostoles",
      "Navacerrada",
      "Nuevo Baztan",
      "Parla",
      "Pozuelo de Alarcón",
      "Puente de Vallecas",
      "Rascafria",
      "Retiro",
      "Ribatejada",
      "Salamanca",
      "San Blas",
      "San Fernando de Henares",
      "San Lorenzo de El Escorial",
      "San Martin de la Vega",
      "San Martin de Valdeiglesias",
      "San Sebastián de los Reyes",
      "Tetuan de las Victorias",
      "Torrejón de Ardoz",
      "Torrelodones",
      "Usera",
      "Vaciamadrid",
      "Valdemorillo",
      "Valdemoro",
      "Vicalvaro",
      "Villa de Vallecas",
      "Villanueva del Pardillo",
      "Villaverde",
      "Villaviciosa de Odon",
      "Aranjuez",
      "Arganzuela",
      "City Center",
      "Colmenar Viejo",
      "Getafe",
      "Guadarrama",
      "Leganés",
      "Madrid",
      "Navalcarnero",
      "Pinto",
      "Tres Cantos"
    ],
    Murcia: [
      "Abaran",
      "Águilas",
      "Alhama de Murcia",
      "Archena",
      "Beniel",
      "Bullas",
      "Calasparra",
      "Cartagena",
      "Cehegín",
      "Ceuti",
      "Cieza",
      "Jumilla",
      "La Manga del Mar Menor",
      "Las Torres de Cotillas",
      "La Unión",
      "Los Alcazares",
      "Mazarrón",
      "Molina de Segura",
      "Puerto Lumbreras",
      "San Javier",
      "San Pedro del Pinatar",
      "Santomera",
      "Torre Pacheco",
      "Torre-Pacheco",
      "Totana",
      "Yecla",
      "Alcantarilla",
      "Caravaca",
      "Lorca",
      "Mula",
      "Murcia"
    ],
    Navarre: [
      "Bera",
      "Burlata",
      "Cortes",
      "Estella",
      "Iturrama",
      "Los Arcos",
      "Primer Ensanche",
      "Sarriguren",
      "Segundo Ensanche",
      "Tafalla",
      "Pamplona",
      "Tudela"
    ],
    "Principality of Asturias": [
      "Langreo",
      "Luanco",
      "Mieres",
      "Villaviciosa",
      "Avilés",
      "Gijón",
      "Grado",
      "Llanera",
      "Llanes",
      "Lugones",
      "Oviedo",
      "Tineo"
    ],
    Valencia: [
      "Albal",
      "Albatera",
      "Alberic",
      "Alboraya",
      "Alcoy",
      "Aldaia",
      "Alfafar",
      "Algemesi",
      "Alginet",
      "Atzeneta d'Albaida",
      "Benaguasil",
      "Benetusser",
      "Benicarló",
      "Benicassim",
      "Benifaio",
      "Benimantell",
      "Benimeli",
      "Benissa",
      "Betera",
      "Bugarra",
      "Buñol",
      "Burriana",
      "Callosa d'En Sarria",
      "Callosa de Segura",
      "Calp",
      "Calpe",
      "Canada",
      "Canals",
      "Canet d'En Berenguer",
      "Carcaixent",
      "Carlet",
      "Castelló de la Plana",
      "Catarroja",
      "Chiva",
      "Cocentaina",
      "Crevillent",
      "Crevillente",
      "Cullera",
      "Daimus",
      "El Campello",
      "els Poblets",
      "Formentera de Segura",
      "Godella",
      "Groa de Murviedro",
      "Guardamar del Segura",
      "Ibi",
      "l'Alfas del Pi",
      "La Pobla de Vallbona",
      "La Pobla Llarga",
      "Lliria",
      "Manises",
      "Massamagrell",
      "Meliana",
      "Mislata",
      "Moncada",
      "Moncofar",
      "Moraira",
      "Muchamiel",
      "Mutxamel",
      "Novelda",
      "Nules",
      "Ontinyent",
      "Orpesa/Oropesa del Mar",
      "Paiporta",
      "Peñíscola",
      "Picanya",
      "Picassent",
      "Pilar de la Horadada",
      "Puig",
      "Quart de Poblet",
      "Requena",
      "Ribarroja",
      "Rojales",
      "San Juan de Alicante",
      "Santa Pola",
      "San Vicent del Raspeig",
      "Sedavi",
      "Silla",
      "Sollana",
      "Sueca",
      "Tavernes Blanques",
      "Tavernes de la Valldigna",
      "Teulada",
      "Traiguera",
      "Utiel",
      "Vall de Ebo",
      "Vergel",
      "Vila-real",
      "Villajoyosa",
      "Vinaros",
      "Xirivella",
      "Alaquas",
      "Alicante",
      "Almassora",
      "Almoradi",
      "Altea",
      "Alzira",
      "Aspe",
      "Benidorm",
      "Burjassot",
      "Castalla",
      "Denia",
      "Elche",
      "Elda",
      "Gandia",
      "Javea",
      "Oliva",
      "Onda",
      "Orihuela",
      "Paterna",
      "Sagunto",
      "Sax",
      "Torrent",
      "Torrevieja",
      "Valencia",
      "Villena",
      "Xàtiva"
    ]
  },
  "Sri Lanka": {
    Central: [
      "Dambulla",
      "Gampola",
      "Hatton",
      "Kandy",
      "Nuwara Eliya",
      "Sigiriya"
    ],
    "Eastern Province": ["Ampara", "Eravur Town", "Trincomalee"],
    "North Central Province": ["Anuradhapura", "Polonnaruwa"],
    "Northern Province": ["Jaffna", "Vavuniya"],
    "North Western Province": ["Chilaw", "Kurunegala", "Puttalam"],
    "Province of Sabaragamuwa": ["Kegalle"],
    Sabaragamuwa: ["Ratnapura"],
    Southern: [
      "Ambalangoda",
      "Bentota",
      "Galle",
      "Matara",
      "Talpe",
      "Tangalla",
      "Weligama"
    ],
    Uva: ["Badulla", "Haputale", "Wattegama"],
    Western: [
      "Battaramulla South",
      "Beruwala",
      "Colombo",
      "Galkissa",
      "Hanwella Ihala",
      "Homagama",
      "Ja Ela",
      "Kalutara",
      "Kandana",
      "Katunayaka",
      "Kelaniya",
      "Kolonnawa",
      "Maharagama",
      "Moratuwa",
      "Mulleriyawa",
      "Negombo",
      "Peliyagoda",
      "Pita Kotte",
      "Sri Jayewardenepura Kotte"
    ],
    "Central Province": ["Kandy"],
    "Southern Province": ["Galle"],
    "Western Province": [
      "Kelaniya",
      "Maharagama",
      "Moratuwa",
      "Negombo",
      "Wattala",
      "Colombo"
    ]
  },
  Sudan: {
    "Blue Nile": ["Ad-Damazin"],
    Kassala: ["Kassala"],
    Northern: ["Dongola", "Kuraymah"],
    Sinnār: ["Singa"],
    "Western Darfur": ["Geneina"],
    "White Nile": ["Al Qitena"],
    Khartoum: ["Omdurman", "Khartoum"],
    "Red Sea": []
  },
  Suriname: {
    Brokopondo: ["Brokopondo", "Brownsweg"],
    Paramaribo: ["Paramaribo"],
    Wanica: ["Lelydorp"],
    "Distrikt Paramaribo": ["Paramaribo"]
  },
  "Svalbard and Jan Mayen": {
    "Jan Mayen": ["Olonkinbyen"],
    Svalbard: ["Longyearbyen"]
  },
  Swaziland: {
    Hhohho: ["Mbabane"],
    "Lubombo District": ["Big Bend"],
    Manzini: ["Manzini"]
  },
  Sweden: {
    Jönköping: [
      "Gislaved",
      "Hillerstorp",
      "Huskvarna",
      "Jonkoping",
      "Jönköping",
      "Kaxholmen",
      "Nassjo",
      "Odensjo",
      "Skillingaryd",
      "Vaggeryd",
      "Vetlanda"
    ],
    Kronoberg: ["AElmhult", "Ljungby", "Vaexjoe"],
    Blekinge: [
      "Brakne-Hoby",
      "Hasslo",
      "Karlshamn",
      "Morrum",
      "Ronneby",
      "Karlskrona"
    ],
    Dalarna: [
      "Avesta",
      "Bjursas",
      "Borlaenge",
      "Grangesberg",
      "Hedemora",
      "Leksand",
      "Ludvika",
      "Malung",
      "Raettvik",
      "Vansbro",
      "Vikarbyn",
      "Borlänge",
      "Falun",
      "Mora"
    ],
    Gävleborg: [
      "Alfta",
      "Arbra",
      "Bergsjoe",
      "Hudiksvall",
      "Jaervsoe",
      "Ockelbo",
      "Söderhamn",
      "Gävle",
      "Sandviken"
    ],
    Gotland: ["Hemse", "Klintehamn", "Slite", "Visby"],
    Halland: [
      "Frillesas",
      "Frosakull",
      "Getinge",
      "Haverdal",
      "Hyltebruk",
      "Onsala",
      "Saro",
      "Troenninge",
      "Tvaaker",
      "Falkenberg",
      "Halmstad",
      "Kungsbacka",
      "Varberg"
    ],
    Jämtland: ["Åre", "Brunflo", "Sveg", "Östersund"],
    Kalmar: [
      "Borgholm",
      "Faerjestaden",
      "Nybro",
      "Oskarshamn",
      "Sodra Sandby",
      "Vaestervik",
      "Västervik",
      "Vimmerby",
      "Kalmar"
    ],
    Norrbotten: [
      "Arvidsjaur",
      "Gaellivare",
      "Gällivare",
      "Malmberget",
      "OEverkalix",
      "Piteå",
      "Roknas",
      "Boden",
      "Kiruna",
      "Luleå"
    ],
    Örebro: ["Kumla", "Lindesberg", "Nora", "Orebro", "Karlskoga", "Örebro"],
    Östergötland: [
      "Finspang",
      "Mantorp",
      "Motala",
      "OEdeshoeg",
      "Vadstena",
      "Linköping",
      "Norrköping"
    ],
    Skåne: [
      "AEngelholm",
      "Åhus",
      "Ängelholm",
      "Bastad",
      "Bjarnum",
      "Bjuv",
      "Bunkeflostrand",
      "Degeberga",
      "Eslov",
      "Forslov",
      "Hässleholm",
      "Hofterup",
      "Hoganas",
      "Hollviken",
      "Kaevlinge",
      "Kivik",
      "Landskrona",
      "Lomma",
      "Odakra",
      "Onnestad",
      "Oxie",
      "Rydeback",
      "Simrishamn",
      "Sjoebo",
      "Skanor med Falsterbo",
      "Staffanstorp",
      "Stehag",
      "Svedala",
      "Tomelilla",
      "Trelleborg",
      "Vejbystrand",
      "Vellinge",
      "Ystad",
      "Helsingborg",
      "Kristianstad",
      "Lund",
      "Malmo",
      "Malmoe"
    ],
    Södermanland: [
      "Akers Styckebruk",
      "Katrineholm",
      "Mariefred",
      "Nyköping",
      "Skogstorp",
      "Svalsta",
      "Trosa",
      "Eskilstuna"
    ],
    Stockholm: [
      "Akersberga",
      "Arsta",
      "Bergshamra",
      "Bjorko",
      "Bollmora",
      "Boo",
      "Brevik",
      "Bromma",
      "Djursholm",
      "Ekerö",
      "Ekeroe",
      "Fisksatra",
      "Fittja",
      "Gustavsberg",
      "Handen",
      "Haninge",
      "Hemmesta",
      "Holo",
      "Huddinge",
      "Jakobsberg",
      "Jordbro",
      "Kista",
      "Kungsaengen",
      "Lidingoe",
      "Marsta",
      "Nacka",
      "Norrtalje",
      "Nynashamn",
      "OEstermalm",
      "Osmo",
      "Östermalm",
      "Rasunda",
      "Ronninge",
      "Saltsjobaden",
      "Segeltorp",
      "Sigtuna",
      "Soedertaelje",
      "Sollentuna",
      "Solna",
      "Sundbyberg",
      "Tullinge",
      "Tumba",
      "Upplands Vaesby",
      "Vallentuna",
      "Varsta",
      "Vaxholm",
      "Södertälje",
      "Stockholm",
      "Taby"
    ],
    Uppsala: [
      "Balsta",
      "Gamla Uppsala",
      "Knivsta",
      "Marieberg",
      "OEsthammar",
      "Oregrund",
      "Saevja",
      "Skutskaer",
      "Tarnsjo",
      "Uppsala"
    ],
    Värmland: [
      "Arvika",
      "Edsvalla",
      "Kristinehamn",
      "Saeffle",
      "Sunne",
      "Torsby",
      "Karlstad"
    ],
    Västerbotten: [
      "Dorotea",
      "Fristad",
      "Hoernefors",
      "Lycksele",
      "Robertsfors",
      "Sjoberg",
      "Soedra Bergsbyn",
      "Skellefteå",
      "Umeå"
    ],
    Västernorrland: [
      "Bergeforsen",
      "Härnösand",
      "Kramfors",
      "Örnsköldsvik",
      "Solleftea",
      "Sundsvall"
    ],
    Västmanland: [
      "Fagersta",
      "Hallstahammar",
      "Hokasen",
      "Skinnskatteberg",
      "Arboga",
      "Sala",
      "Västerås"
    ],
    "Västra Götaland": [
      "Billdal",
      "Brastad",
      "Dals Langed",
      "Ed",
      "Eriksbo",
      "Falkoeping",
      "Falköping",
      "Furulund",
      "Ganghester",
      "Goeteborg",
      "Grebbestad",
      "Hammarkullen",
      "Henan",
      "Hunnebostrand",
      "Kallered",
      "Kinna",
      "Kungshamn",
      "Kvanum",
      "Landvetter",
      "Lerum",
      "Lidkoping",
      "Lindome",
      "Ljungskile",
      "Lysekil",
      "Majorna",
      "Marstrand",
      "Mellerud",
      "Moelndal",
      "Mölnlycke",
      "Munkedal",
      "Myggenas",
      "Nossebro",
      "Olstorp",
      "Partille",
      "Skoevde",
      "Skultorp",
      "Smoegen",
      "Stenungsund",
      "Stroemstad",
      "Styrso",
      "Svenljunga",
      "Tanumshede",
      "Toereboda",
      "Trollhättan",
      "Uddevalla",
      "Ulricehamn",
      "Vara",
      "Borås",
      "Gothenburg",
      "Kungälv",
      "Mariestad",
      "Skara",
      "Skövde",
      "Torslanda"
    ]
  },
  Switzerland: {
    Aargau: [
      "Aarau",
      "Baden",
      "Full",
      "Graenichen",
      "Leibstadt",
      "Meisterschwanden",
      "Mohlin",
      "Oberentfelden",
      "Oftringen",
      "Seengen",
      "Spreitenbach",
      "Teufenthal",
      "Wettingen",
      "Wohlen",
      "Zofingen"
    ],
    "Appenzell Ausserrhoden": ["Heiden", "Herisau"],
    "Appenzell Innerrhoden": ["Appenzell"],
    "Basel-Landschaft": [
      "Aesch",
      "Allschwil",
      "Binningen",
      "Birsfelden",
      "Liestal",
      "Munchenstein",
      "Muttenz",
      "Oberdorf",
      "Oberwil",
      "Pratteln",
      "Sissach"
    ],
    Glarus: ["Luchsingen"],
    Grisons: [
      "Arosa",
      "Chur",
      "Churwalden",
      "Davos",
      "Hinterrhein",
      "Klosters Serneus",
      "Laax",
      "Saint Moritz",
      "Samedan",
      "Scuol",
      "Silvaplana",
      "Thusis",
      "Tiefencastel"
    ],
    Nidwalden: ["Ennetbürgen", "Stansstad"],
    Schaffhausen: ["Neuhausen", "Schaffhausen"],
    Schwyz: [
      "Altendorf",
      "Arth",
      "Einsiedeln",
      "Freienbach",
      "Ibach",
      "Ingenbohl",
      "Küssnacht",
      "Schwyz"
    ],
    Solothurn: ["Grenchen", "Niedererlinsbach", "Olten", "Solothurn"],
    Thurgau: [
      "Amriswil",
      "Arbon",
      "Berg",
      "Frauenfeld",
      "Kreuzlingen",
      "Salmsach"
    ],
    Uri: ["Andermatt"],
    Zug: ["Baar", "Cham", "Hunenberg", "Walchwil", "Zug"],
    "Basel-City": ["Riehen", "Basel"],
    Bern: [
      "Adelboden",
      "Biel/Bienne",
      "Burgdorf",
      "Darligen",
      "Evilard",
      "Grindelwald",
      "Gstaad",
      "Interlaken",
      "Kandersteg",
      "Kehrsatz",
      "Köniz",
      "Konolfingen",
      "Langenthal",
      "Lauterbrunnen",
      "Lenk",
      "Lotzwil",
      "Meiringen",
      "Orvin",
      "Saanen",
      "Spiez",
      "Steffisburg",
      "Thun",
      "Unterseen",
      "Worb",
      "Bern",
      "Lyss"
    ],
    Fribourg: ["Fribourg", "Sales", "Treyvaux", "Bulle"],
    Geneva: [
      "Bellevue",
      "Carouge",
      "Geneve",
      "Lancy",
      "Le Grand-Saconnex",
      "Meyrin",
      "Onex",
      "Vernier",
      "Versoix",
      "Geneva"
    ],
    Jura: ["Delémont"],
    Lucerne: [
      "Adligenswil",
      "Ebikon",
      "Eich",
      "Emmen",
      "Horw",
      "Kriens",
      "Littau",
      "Luzern",
      "Root",
      "Vitznau",
      "Weggis",
      "Lucerne"
    ],
    Neuchâtel: [
      "Bevaix",
      "Fleurier",
      "La Chaux-de-Fonds",
      "Le Locle",
      "Neuchatel",
      "Saint-Blaise"
    ],
    Obwalden: ["Engelberg", "Kerns", "Giswil"],
    "Saint Gallen": [
      "Altstatten",
      "Au",
      "Bad Ragaz",
      "Gossau",
      "Jona",
      "Quarten",
      "Rapperswil",
      "Sankt Gallen",
      "St. Gallen",
      "Uzwil",
      "Walenstadt",
      "Wildhaus",
      "Wil"
    ],
    Ticino: [
      "Ascona",
      "Bellinzona",
      "Cugnasco",
      "Lavertezzo",
      "Ligornetto",
      "Locarno",
      "Magadino",
      "Mendrisio",
      "Sorengo",
      "Lugano"
    ],
    Valais: [
      "Bagnes",
      "Brig",
      "Champery",
      "Charrat-les-Chenes",
      "Evolene",
      "Fiesch",
      "Grachen",
      "Leukerbad",
      "Monthey",
      "Port-Valais",
      "Randogne",
      "Saas-Fee",
      "Saillon",
      "Sitten",
      "Verbier",
      "Visp",
      "Zermatt",
      "Sierre",
      "Sion"
    ],
    Vaud: [
      "Aigle",
      "Berolle",
      "Caux",
      "Coppet",
      "Gland",
      "L'Abbaye",
      "La Tour-de-Peilz",
      "Lutry",
      "Morges",
      "Nyon",
      "Prilly",
      "Pully",
      "Renens",
      "Sainte-Croix",
      "Trelex",
      "Vevey",
      "Villars-sur-Ollon",
      "Yverdon-les-Bains",
      "Lausanne",
      "Montreux"
    ],
    Zurich: [
      "Adliswil",
      "Aussersihl",
      "Bachenbulach",
      "Bulach",
      "Dachsen",
      "Dietikon",
      "Duebendorf / Wasserfurren",
      "Effretikon",
      "Glattbrugg / Rohr/Platten-Balsberg",
      "Horgen",
      "Kilchberg",
      "Kloten",
      "Kuesnacht / Dorf",
      "Maennedorf / Dorfkern",
      "Oberrieden / Berg",
      "Oberwinterthur (Kreis 2) / Guggenbuehl",
      "Oberwinterthur (Kreis 2) / Talacker",
      "Regensdorf",
      "Richterswil / Burghalde",
      "Seen (Kreis 3)",
      "Stadt Winterthur (Kreis 1)",
      "Stadt Winterthur (Kreis 1) / Altstadt",
      "Thalwil",
      "Uster",
      "Wallisellen",
      "Wettswil / Wettswil (Dorf)",
      "Wetzikon",
      "Winterthur",
      "Zuerich (Kreis 1)",
      "Zuerich (Kreis 11) / Oerlikon",
      "Zuerich (Kreis 1) / City",
      "Zuerich (Kreis 1) / Lindenhof",
      "Zuerich (Kreis 1) / Rathaus",
      "Zuerich (Kreis 2) / Wollishofen",
      "Zuerich (Kreis 4) / Hard",
      "Zuerich (Kreis 4) / Langstrasse",
      "Zuerich (Kreis 5)",
      "Zuerich (Kreis 5) / Escher-Wyss",
      "Zuerich (Kreis 5) / Gewerbeschule",
      "Zuerich (Kreis 7)",
      "Zuerich (Kreis 7) / Hottingen",
      "Zuerich (Kreis 8) / Seefeld",
      "Schlieren",
      "Zurich"
    ]
  },
  "Syrian Arab Republic": {
    Aleppo: ["Aleppo", "Jindayris"],
    "Ar-Raqqah": ["Ar Raqqah", "Ath Thawrah", "Tall Abyad"],
    "As-Suwayda": [],
    "Deir ez-Zor": ["Deir ez-Zor"],
    Dimashq: ["Damascus"],
    Hama: ["Souran"],
    Homs: [
      "As Sukhnah",
      "Homs",
      "Mukharram al Fawqani",
      "Tadmur",
      "Tallkalakh"
    ],
    Idlib: ["Idlib"],
    Latakia: ["Jablah", "Latakia"],
    Quneitra: ["Al Khushniyah"],
    "Rif-dimashq": ["At Tall", "Darayya"],
    Tartus: ["Satita", "Tartouss"],
    "Aleppo Governorate": ["Aleppo"],
    "Damascus Governorate": ["Damascus"],
    "Hama Governorate": ["Hama"],
    "Latakia Governorate": ["Latakia"]
  },
  Taiwan: {
    Changhua: [],
    Keelung: ["Keelung"],
    "Kinmen County": ["Jincheng"],
    Nantou: ["Puli"],
    "New Taipei": ["Banqiao"],
    Penghu: [],
    Taitung: [],
    Taiwan: [
      "Daxi",
      "Douliu",
      "Fengyuan",
      "Hengchun",
      "Hsinchu",
      "Hualian",
      "Keelung",
      "Lugu",
      "Ma-kung",
      "Nantou",
      "Puli",
      "Taichung",
      "Tainan",
      "Taitung",
      "Taitung City",
      "Taoyuan",
      "Taoyuan City",
      "Yilan",
      "Yujing",
      "Zhongxing New Village",
      "Zhubei"
    ],
    Yilan: ["Yilan"],
    "Yunlin County": [],
    Hsinchu: ["Hsinchu"],
    Hualien: ["Hualien City"],
    Kaohsiung: ["Kaohsiung", "Kaohsiung City"],
    "Taichung City": ["Taichung"],
    Tainan: ["Tainan City"],
    "Taipei City": ["Banqiao", "Taipei"],
    Taoyuan: ["Daxi"]
  },
  Tajikistan: {
    Dushanbe: ["Dushanbe"],
    "Gorno-Badakhshan": ["Khorugh"],
    "Republican Subordination": ["Tursunzoda", "Vahdat"],
    "Viloyati Khatlon": ["Norak"],
    "Viloyati Sughd": ["Isfara", "Istaravshan", "Khujand", "Panjakent"]
  },
  Tanzania: {
    "Dar es Salaam Region": ["Dar es Salaam", "Magomeni"],
    Geita: ["Chato", "Geita"],
    Kagera: ["Bukoba", "Kabanga", "Nshamba", "Rulenge"],
    Katavi: ["Mpanda"],
    Kigoma: ["Kasulu", "Kibondo", "Kigoma"],
    Kilimanjaro: ["Lembeni", "Moshi", "Same"],
    Manyara: ["Babati", "Galappo", "Magugu"],
    Morogoro: ["Geiro", "Ifakara", "Kidatu", "Kilosa", "Mikumi", "Morogoro"],
    Mtwara: ["Mtwara", "Nanganga", "Nanhyanga", "Newala Kisimani"],
    Njombe: ["Makumbako", "Njombe"],
    "Pemba South": ["Chake Chake"],
    Pwani: [
      "Bagamoyo",
      "Kibaha",
      "Kibiti",
      "Kilindoni",
      "Kisarawe",
      "Mkuranga",
      "Mlandizi",
      "Mvomero",
      "Vikindu"
    ],
    Ruvuma: ["Mahanje", "Mbinga", "Songea"],
    Shinyanga: ["Kahama", "Mwadui", "Shinyanga", "Tinde"],
    Simiyu: ["Bariadi", "Nyakabindi"],
    Singida: ["Itigi", "Kilimatinde", "Mgandu", "Singida"],
    "Zanzibar Central/South": ["Mahonda", "Nganane", "Sokoni"],
    "Zanzibar North": ["Nungwi"],
    Arusha: [
      "Kingori",
      "Kiratu",
      "Merelani",
      "Mto wa Mbu",
      "Ngorongoro",
      "Usa River",
      "Arusha"
    ],
    Dodoma: ["Dodoma"],
    Iringa: ["Izazi", "Mafinga", "Makungu", "Iringa"],
    Lindi: ["Ruangwa", "Lindi"],
    Mara: ["Bunda", "Butiama", "Issenye", "Mugumu", "Musoma", "Tarime"],
    Mbeya: ["Ipinda", "Kiwira", "Kyela", "Mlowo", "Tukuyu", "Tunduma", "Mbeya"],
    Mwanza: ["Kihangara", "Usagara", "Mwanza"],
    Rukwa: ["Sumbawanga"],
    Tabora: ["Igunga", "Nzega", "Sikonge", "Urambo", "Tabora"],
    Tanga: ["Chanika", "Lushoto", "Maramba", "Muheza", "Soni", "Tanga"],
    "Zanzibar Urban/West": ["Zanzibar"]
  },
  Thailand: {
    "Ang Thong": ["Ang Thong", "Wiset Chaichan"],
    Buriram: [
      "Buriram",
      "Chaloem Phra Kiat",
      "Nang Rong",
      "Phlapphla Chai",
      "Prakhon Chai"
    ],
    Chachoengsao: ["Bang Pakong", "Chachoengsao", "Phanom Sarakham"],
    "Chai Nat": ["Chai Nat"],
    Chaiyaphum: ["Chaiyaphum", "Kaset Sombun", "Phu Khiao"],
    "Changwat Amnat Charoen": ["Amnat Charoen"],
    "Changwat Ang Thong": ["Chaiyo"],
    "Changwat Buri Ram": ["Buriram"],
    "Changwat Chachoengsao": ["Chachoengsao"],
    "Changwat Chai Nat": ["Chainat"],
    "Changwat Chanthaburi": ["Chanthaburi"],
    "Changwat Chiang Rai": ["Chiang Rai"],
    "Changwat Chumphon": ["Chumphon"],
    "Changwat Kalasin": ["Kalasin"],
    "Changwat Kamphaeng Phet": ["Kamphaeng Phet"],
    "Changwat Kanchanaburi": ["Kanchanaburi"],
    "Changwat Lampang": ["Lampang"],
    "Changwat Lamphun": ["Lamphun"],
    "Changwat Loei": ["Loei"],
    "Changwat Lop Buri": ["Lopburi"],
    "Changwat Mukdahan": ["Mukdahan"],
    "Changwat Nakhon Pathom": ["Nakhon Pathom"],
    "Changwat Nakhon Phanom": ["Nakhon Phanom"],
    "Changwat Nakhon Ratchasima": ["Nakhon Ratchasima"],
    "Changwat Nakhon Sawan": ["Nakhon Sawan"],
    "Changwat Nakhon Si Thammarat": ["Nakhon Si Thammarat", "Thung Song"],
    "Changwat Narathiwat": ["Narathiwat"],
    "Changwat Nong Bua Lamphu": ["Nong Bua Lamphu"],
    "Changwat Pathum Thani": ["Pathum Thani"],
    "Changwat Pattani": ["Pattani"],
    "Changwat Phatthalung": ["Phatthalung"],
    "Changwat Phetchabun": ["Phetchabun"],
    "Changwat Phetchaburi": ["Phetchaburi"],
    "Changwat Phichit": ["Phichit"],
    "Changwat Prachin Buri": ["Prachin Buri"],
    "Changwat Prachuap Khiri Khan": ["Hua Hin", "Prachuap Khiri Khan"],
    "Changwat Ranong": ["Ranong"],
    "Changwat Ratchaburi": ["Ratchaburi"],
    "Changwat Rayong": ["Klaeng", "Rayong"],
    "Changwat Sakon Nakhon": ["Sakon Nakhon"],
    "Changwat Samut Sakhon": ["Samut Sakhon"],
    "Changwat Samut Songkhram": ["Samut Songkhram"],
    "Changwat Saraburi": ["Saraburi"],
    "Changwat Satun": ["Satun"],
    "Changwat Suphan Buri": ["Suphan Buri"],
    "Changwat Surat Thani": ["Chaiya", "Ko Samui", "Surat Thani"],
    "Changwat Surin": ["Surin"],
    "Changwat Trat": ["Trat"],
    "Changwat Ubon Ratchathani": [
      "Det Udom",
      "Phibun Mangsahan",
      "Ubon Ratchathani",
      "Warin Chamrap"
    ],
    "Changwat Udon Thani": [
      "Ban Nong Wua So",
      "Ban Phan Don",
      "Nam Som",
      "Nong Wua So",
      "Udon Thani"
    ],
    "Changwat Yala": ["Betong", "Yala"],
    "Changwat Yasothon": ["Yasothon"],
    "Chiang Rai": [
      "Chiang Rai",
      "Chiang Saen",
      "Mae Chan",
      "Mae Lao",
      "Mae Sai"
    ],
    "Chon Buri": [
      "Ban Bueng",
      "Bang Lamung",
      "Ban Ko Lan",
      "Ban Talat Bueng",
      "Chon Buri",
      "Phanat Nikhom",
      "Phan Thong",
      "Phatthaya",
      "Sattahip",
      "Si Racha"
    ],
    Chumphon: ["Chumphon", "Lang Suan"],
    Kalasin: ["Kalasin", "Kuchinarai", "Nong Kung Si", "Somdet"],
    "Kamphaeng Phet": [
      "Kamphaeng Phet",
      "Khanu Woralaksaburi",
      "Khlong Khlung"
    ],
    Kanchanaburi: [
      "Bo Phloi",
      "Kanchanaburi",
      "Sai Yok",
      "Si Sawat",
      "Tha Maka",
      "Tha Muang"
    ],
    "Khon Kaen": [
      "Ban Fang",
      "Ban Phai",
      "Chum Phae",
      "Khon Kaen",
      "Khuean Ubonrat"
    ],
    Lampang: ["Lampang", "Thoen"],
    Lamphun: ["Lamphun", "Li", "Pa Sang"],
    Loei: ["Chiang Khan", "Loei", "Phu Kradueng", "Wang Saphung"],
    "Lop Buri": ["Chai Badan", "Lop Buri", "Phatthana Nikhom", "Tha Luang"],
    "Mae Hong Son": ["Mae Hi"],
    "Maha Sarakham": ["Maha Sarakham"],
    Mukdahan: ["Mukdahan"],
    "Nakhon Nayok": ["Nakhon Nayok"],
    "Nakhon Pathom": [
      "Bang Len",
      "Kamphaeng Saen",
      "Nakhon Pathom",
      "Salaya",
      "Sam Phran"
    ],
    "Nakhon Phanom": ["Nakhon Phanom", "Renu Nakhon"],
    "Nakhon Ratchasima": [
      "Amphoe Sikhiu",
      "Ban Huai Thalaeng",
      "Bua Yai",
      "Chok Chai",
      "Dan Khun Thot",
      "Kham Thale So",
      "Khon Buri",
      "Nakhon Ratchasima",
      "Pak Chong"
    ],
    "Nakhon Sawan": ["Nakhon Sawan"],
    "Nakhon Si Thammarat": [
      "Ko Pha Ngan",
      "Nakhon Si Thammarat",
      "Nopphitam",
      "Pak Phanang",
      "Phra Phrom",
      "Phrom Khiri",
      "Thung Song"
    ],
    Nan: ["Bo Kluea", "Nan", "Wiang Sa"],
    Narathiwat: ["Narathiwat", "Ra-ngae", "Su-ngai Kolok", "Tak Bai"],
    "Nong Khai": ["Nong Khai", "Phon Charoen", "Tha Bo"],
    Nonthaburi: [
      "Bang Bua Thong",
      "Bang Kruai",
      "Bang Yai",
      "Mueang Nonthaburi",
      "Pak Kret"
    ],
    "Pathum Thani": [
      "Ban Lam Luk Ka",
      "Ban Rangsit",
      "Khlong Luang",
      "Pathum Thani",
      "Sam Khok",
      "Thanyaburi"
    ],
    Pattani: ["Panare", "Pattani"],
    Phangnga: [
      "Ban Ao Nang",
      "Ban Khao Lak",
      "Ban Phru Nai",
      "Khura Buri",
      "Ko Yao",
      "Takua Thung",
      "Thai Mueang"
    ],
    Phatthalung: ["Khuan Khanun", "Phatthalung"],
    Phayao: ["Dok Kham Tai", "Phayao"],
    Phetchabun: [
      "Khao Kho",
      "Lom Kao",
      "Lom Sak",
      "Nam Nao",
      "Phetchabun",
      "Wichian Buri"
    ],
    Phetchaburi: [
      "Ban Lat",
      "Cha-am",
      "Kaeng Krachan",
      "Khao Yoi",
      "Phetchaburi",
      "Tha Yang"
    ],
    Phichit: ["Bang Mun Nak", "Phichit", "Thap Khlo"],
    Phitsanulok: ["Bang Rakam", "Phitsanulok"],
    Phrae: ["Phrae"],
    "Phra Nakhon Si Ayutthaya": [
      "Ban Bang Kadi Pathum Thani",
      "Bang Ban",
      "Bang Pa-in",
      "Nakhon Luang",
      "Phak Hai",
      "Phra Nakhon Si Ayutthaya",
      "Tha Ruea",
      "Wang Noi"
    ],
    "Prachin Buri": [
      "Ban Sang",
      "Kabin Buri",
      "Na Di",
      "Prachin Buri",
      "Si Maha Phot"
    ],
    "Prachuap Khiri Khan": [
      "Bang Saphan",
      "Hua Hin",
      "Kui Buri",
      "Prachuap Khiri Khan",
      "Pran Buri",
      "Sam Roi Yot"
    ],
    Ranong: ["Kapoe", "Ranong", "Suk Samran"],
    Ratchaburi: [
      "Ban Pong",
      "Chom Bueng",
      "Photharam",
      "Ratchaburi",
      "Suan Phueng"
    ],
    Rayong: ["Ban Chang", "Klaeng", "Rayong"],
    "Roi Et": ["Ban Selaphum", "Kaset Wisai", "Roi Et"],
    "Sa Kaeo": ["Aranyaprathet", "Wang Nam Yen"],
    "Sakon Nakhon": ["Sakon Nakhon", "Sawang Daen Din"],
    "Samut Prakan": [
      "Bang Bo District",
      "Bang Sao Thong",
      "Ban Khlong Bang Sao Thong",
      "Phra Pradaeng",
      "Samut Prakan"
    ],
    "Samut Sakhon": ["Ban Phaeo", "Krathum Baen", "Samut Sakhon"],
    "Samut Songkhram": ["Amphawa", "Samut Songkhram"],
    "Sara Buri": [
      "Kaeng Khoi",
      "Muak Lek",
      "Nong Khae",
      "Phra Phutthabat",
      "Saraburi"
    ],
    Satun: ["La-ngu", "Satun"],
    "Sing Buri": ["Bang Racham", "Sing Buri", "Tha Chang"],
    Sisaket: ["Si Sa Ket"],
    Songkhla: [
      "Ban Mai",
      "Hat Yai",
      "Khlong Hoi Khong",
      "Ranot",
      "Sadao",
      "Songkhla"
    ],
    Sukhothai: [
      "Ban Na",
      "Kong Krailat",
      "Sawankhalok",
      "Si Satchanalai",
      "Sukhothai"
    ],
    "Suphan Buri": ["Doem Bang Nang Buat", "Suphan Buri"],
    "Surat Thani": [
      "Ban Na San",
      "Don Sak",
      "Koh Tao",
      "Ko Pha-Ngan",
      "Ko Samui",
      "Phunphin",
      "Surat Thani"
    ],
    Surin: ["Surin"],
    Tak: ["Ban Tak", "Mae Ramat", "Mae Sot", "Tak", "Umphang"],
    Trang: ["Hat Samran", "Huai Yot", "Kantang", "Trang"],
    Trat: ["Ko Chang Tai", "Ko Kut", "Laem Ngop", "Trat"],
    "Uthai Thani": ["Thap Than", "Uthai Thani"],
    Uttaradit: ["Lap Lae", "Uttaradit"],
    Yala: ["Betong", "Yala"],
    Yasothon: ["Yasothon"],
    Bangkok: [
      "Bang Bon",
      "Bang Kapi",
      "Bang Khae",
      "Bang Khen",
      "Bang Kho Laem",
      "Bang Khun Thian",
      "Bangkok Noi",
      "Bangkok Yai",
      "Bang Na",
      "Bang Phlat",
      "Bang Rak",
      "Bang Sue",
      "Bueng Kum",
      "Chatuchak",
      "Din Daeng",
      "Don Mueang",
      "Dusit",
      "Huai Khwang",
      "Khan Na Yao",
      "Khlong Sam Wa",
      "Khlong San",
      "Khlong Toei",
      "Lak Si",
      "Lat Phrao",
      "Min Buri",
      "Nong Chok",
      "Nong Khaem",
      "Phasi Charoen",
      "Phaya Thai",
      "Phra Khanong",
      "Phra Nakhon",
      "Pom Prap Sattru Phai",
      "Rat Burana",
      "Ratchathewi",
      "Sai Mai",
      "Samphanthawong",
      "Saphan Sung",
      "Sathon",
      "Suan Luang",
      "Taling Chan",
      "Thawi Watthana",
      "Thon Buri",
      "Thung Khru",
      "Wang Thonglang",
      "Watthana",
      "Yan Nawa",
      "Bangkok",
      "Lat Krabang",
      "Pathum Wan"
    ],
    "Changwat Bueng Kan": ["Seka"],
    "Changwat Chon Buri": [
      "Chanthaburi",
      "Chon Buri",
      "Laem Sing",
      "Sattahip",
      "Soi Dao",
      "Tha Mai",
      "Pattaya"
    ],
    "Changwat Khon Kaen": ["Khon Kaen", "Phon"],
    "Changwat Krabi": ["Krabi"],
    "Changwat Nan": ["Nan"],
    "Changwat Nonthaburi": ["Bang Kruai", "Nonthaburi"],
    "Changwat Phayao": ["Phayao", "Pong"],
    "Changwat Phitsanulok": ["Phitsanulok"],
    "Changwat Phrae": ["Phrae", "Long"],
    "Changwat Phra Nakhon Si Ayutthaya": ["Ayutthaya"],
    "Changwat Songkhla": ["Hat Yai", "Songkhla"],
    "Changwat Tak": ["Mae Sot", "Tak"],
    "Changwat Trang": ["Trang"],
    "Changwat Uttaradit": ["Uttaradit"],
    "Chiang Mai Province": [
      "Chai Prakan",
      "Chom Thong",
      "Doi Lo",
      "Hang Dong",
      "Mae On",
      "Mae Taeng",
      "Mae Wang",
      "Samoeng",
      "San Kamphaeng",
      "San Pa Tong",
      "San Sai",
      "Chiang Mai",
      "Saraphi"
    ],
    Krabi: ["Ko Lanta", "Saladan", "Krabi"],
    Phuket: [
      "Ban Chalong",
      "Ban Karon",
      "Ban Ratsada",
      "Ban Talat Nua",
      "Ban Talat Yai",
      "Mueang Phuket",
      "Nai Harn",
      "Rawai",
      "Wichit",
      "Kathu",
      "Patong",
      "Phuket",
      "Thalang"
    ]
  },
  "Timor-Leste": {
    "Cova Lima": ["Suai"],
    Lautém: ["Lospalos"],
    Liquiçá: ["Liquica", "Maubara"],
    Dili: ["Dili"],
    Manufahi: ["Same"]
  },
  Togo: {
    Plateaux: ["Badou"],
    Centrale: [],
    Kara: ["Kara"],
    Maritime: ["Lomé"]
  },
  Tokelau: {},
  Tonga: {
    "Ha`apai": ["Pangai"],
    Tongatapu: ["Nuku'alofa"],
    "Vava'u": ["Neiafu"]
  },
  "Trinidad and Tobago": {
    "Borough of Arima": ["Arima"],
    "City of Port of Spain": ["Mucurapo", "Port-of-Spain"],
    "City of San Fernando": ["Marabella", "Mon Repos", "San Fernando"],
    "Couva-Tabaquite-Talparo": ["Couva"],
    "Diego Martin": ["Petit Valley"],
    "Eastern Tobago": ["Roxborough"],
    Mayaro: ["Rio Claro"],
    "Point Fortin": ["Point Fortin"],
    "Sangre Grande": ["Sangre Grande"],
    "San Juan/Laventille": ["Laventille"],
    "Tunapuna/Piarco": ["Arouca", "Paradise", "Tunapuna"],
    Chaguanas: ["Chaguanas"],
    "Penal/Debe": ["Penal"],
    Tobago: ["Scarborough"]
  },
  Tunisia: {
    "Al Mahdiyah": ["Chebba", "Mahdia"],
    "Al Munastir": ["Al Baqalitah", "Monastir", "Qusaybat al Madyuni"],
    Ariana: ["Ariana"],
    Banzart: ["Bizerte", "Dawwar Tinjah", "Menzel Jemil"],
    Gafsa: ["Gafsa"],
    "Gouvernorat de Ben Arous": ["Ben Arous"],
    "Gouvernorat de Bizerte": ["Bizerte", "Mateur"],
    "Gouvernorat de Gabes": ["Gabès"],
    "Gouvernorat de Jendouba": ["Tabarka"],
    "Gouvernorat de Kairouan": ["Kairouan"],
    "Gouvernorat de Kasserine": ["Kasserine", "Thala"],
    "Gouvernorat de Kebili": ["Douz"],
    "Gouvernorat de l'Ariana": ["Aryanah"],
    "Gouvernorat de Mahdia": ["Chebba", "Mahdia"],
    "Gouvernorat de Medenine": ["Medenine", "Midoun", "Zarzis"],
    "Gouvernorat de Siliana": ["Siliana"],
    "Gouvernorat de Tozeur": ["Nefta", "Tozeur"],
    "Gouvernorat de Zaghouan": ["Zaghouan"],
    Jundubah: ["Jendouba"],
    Madanin: ["Houmt Souk", "Midoun", "Zarzis"],
    Manouba: ["Manouba"],
    Nabul: ["Al Hammamat", "Beni Khiar", "Nabeul", "Qulaybiyah"],
    Qabis: ["Bou Attouche"],
    Qibili: ["Douz", "Kebili"],
    Safaqis: ["Sfax"],
    Susah: ["Hammam Sousse", "Sousse"],
    Tataouine: ["Tataouine"],
    Tawzar: ["Nefta", "Tozeur"],
    Tunis: [
      "Al Marsa",
      "Carthage",
      "Hammam-Lif",
      "La Goulette",
      "Sidi Bou Said",
      "Tunis"
    ],
    "Gouvernorat de Monastir": ["Skanes", "Monastir"],
    "Gouvernorat de Nabeul": ["Hammamet", "Korba", "Nabeul", "Douane"],
    "Gouvernorat de Sfax": ["Gremda", "Sfax"],
    "Gouvernorat de Sousse": ["Hammam Sousse", "Masakin", "Sousse"],
    "Gouvernorat de Tunis": [
      "La Goulette",
      "La Marsa",
      "Rades",
      "Carthage",
      "Tunis"
    ]
  },
  Turkey: {
    Bayburt: ["Bayburt"],
    Duezce: ["Akcakoca", "Duzce", "Golyaka", "Kaynasli"],
    Iğdır: ["Iğdır"],
    Karaman: ["Bucakkisla", "Karaman", "Yesildere"],
    Kilis: ["Kilis"],
    Kırşehir: ["Kirsehir", "Kırşehir", "Mucur"],
    Muş: ["Muş", "Varto"],
    Osmaniye: ["Osmaniye", "Toprakkale"],
    Tunceli: ["Tunceli"],
    Adana: ["Ceyhan", "Kadirli", "Kozan", "Tepecikoren", "Adana"],
    Adiyaman: ["Adıyaman"],
    Afyonkarahisar: ["Bolvadin", "Dinar", "Sandikli", "Afyonkarahisar"],
    Ağrı: ["Patnos", "Ağrı"],
    Aksaray: ["Guzelyurt", "Aksaray"],
    Amasya: ["Gediksaray", "Merzifon", "Suluova", "Amasya"],
    Ankara: [
      "Akyurt",
      "Batikent",
      "Beypazari",
      "Cankaya",
      "Etimesgut",
      "Golbasi",
      "Ikizce",
      "Kalecik",
      "Mamak",
      "Polatlı",
      "Sereflikochisar",
      "Ankara"
    ],
    Antalya: [
      "Aksu",
      "Altinyaka",
      "Avsallar",
      "Belek",
      "Beskonak",
      "Beykonak",
      "Bogazkent",
      "Cakirlar",
      "Demre",
      "Elmali",
      "Goeynuek",
      "Kalkan",
      "Kas",
      "Kemer",
      "Kizilagac",
      "Korkuteli",
      "Kumkoey",
      "Mahmutlar",
      "Muratpasa",
      "Okurcalar",
      "Serik",
      "Side",
      "Tekirova",
      "Tuerkler",
      "Alanya",
      "Antalya",
      "Gazipaşa",
      "Manavgat"
    ],
    Ardahan: ["Ardahan"],
    Artvin: [
      "Arhavi",
      "Borcka",
      "Camili",
      "Hopa",
      "Savsat",
      "Zeytinlik",
      "Artvin"
    ],
    Aydın: [
      "Davutlar",
      "Didim",
      "Germencik",
      "Kusadasi",
      "Nazilli",
      "Sarikemer",
      "Aydin",
      "Cine"
    ],
    Balıkesir: [
      "Altinoluk",
      "Altinova",
      "Ayvalik",
      "Balikesir",
      "Bandirma",
      "Burhaniye",
      "Edremit",
      "Emrutabat",
      "Erdek",
      "Gonen",
      "Marmara",
      "Sindirgi",
      "Susurluk",
      "Balıkesir"
    ],
    Bartın: ["Amasra", "Bartin", "Ulus"],
    Batman: ["Batman"],
    Bilecik: ["Bozuyuk", "Osmaneli", "Bilecik"],
    Bingöl: ["Bingol", "Ilicalar", "Solhan"],
    Bitlis: ["Ahlat", "Tatvan", "Bitlis"],
    Bolu: ["Gerede", "Goynuk", "Kibriscik", "Mudurnu", "Bolu"],
    Burdur: ["Yesilova", "Burdur"],
    Bursa: [
      "Cali",
      "Gemlik",
      "Gorukle",
      "Gursu",
      "Iznik",
      "Karacabey",
      "Kestel",
      "Kirazli",
      "Mudanya",
      "Nicaea",
      "Niluefer",
      "Orhangazi",
      "Osmangazi",
      "Soldere",
      "Yenice",
      "Yildirim",
      "Zeytinbagi",
      "Bursa",
      "Keles",
      "Yenişehir"
    ],
    Canakkale: [
      "Bakacak",
      "Biga",
      "Bozcaada",
      "Eceabat",
      "Gelibolu",
      "Gokceada",
      "Kucukkuyu",
      "Çanakkale"
    ],
    Çankırı: ["Khanjarah", "Çankırı"],
    Çorum: ["Corum", "Sungurlu", "Çorum"],
    Denizli: [
      "Buldan",
      "Gozler",
      "Honaz",
      "Merkezefendi",
      "Pamukkale",
      "Serinhisar",
      "Denizli"
    ],
    Diyarbakir: ["Baglar", "Bismil", "Egil", "Sur", "Diyarbakır"],
    Edirne: ["Kesan", "Mecidiye", "Edirne"],
    Elazığ: ["Baskil", "Elâzığ", "Palu"],
    Erzincan: ["Catalarmut", "Erzincan"],
    Erzurum: ["Camlikaya", "Horasan", "Erzurum"],
    Eskişehir: ["Eskisehir", "Eskişehir"],
    Gaziantep: ["Nizip", "Sahinbey", "Sehitkamil", "Gaziantep"],
    Giresun: ["Bulancak", "Kesap", "Tirebolu", "Giresun"],
    Guemueshane: ["Şiran", "Torul", "Gümüşhane"],
    Hakkâri: ["Cukurca", "Hakkari"],
    Hatay: [
      "Antakya",
      "Belen",
      "Denizciler",
      "Dortyol",
      "Gunyazi",
      "İskenderun"
    ],
    Isparta: ["Egirdir", "Keciborlu", "Kumdanli", "Senirkent", "Isparta"],
    Istanbul: [
      "Adalan",
      "Adalar",
      "Arikoey",
      "Bagcilar",
      "Bahcelievler",
      "Basaksehir",
      "Beylikduezue",
      "Boyalik",
      "Bueyuekcekmece",
      "Catalca",
      "Durusu",
      "Esenler",
      "Esenyurt",
      "guengoeren merter",
      "Gurpinar",
      "Icmeler",
      "Karacakoy",
      "Kemerburgaz",
      "Kinali",
      "Mahmutbey",
      "Mahmut Sevket Pasa",
      "merter keresteciler",
      "Mimarsinan",
      "Ortakoy",
      "Pendik",
      "Samandira",
      "Sancaktepe",
      "Selimpasa",
      "Sile",
      "Silivri",
      "Sinekli",
      "Sisli",
      "Sultanbeyli",
      "Sultangazi",
      "Teke",
      "Tepecik",
      "UEskuedar",
      "Umraniye",
      "Yakuplu",
      "Atasehir",
      "Eminoenue",
      "Istanbul",
      "Maltepe",
      "Zeytinburnu"
    ],
    Izmir: [
      "Alacati",
      "Aliaga",
      "Birgi",
      "Bornova",
      "Buca",
      "Candarli",
      "Cesme",
      "Dikili",
      "Foca",
      "Gaziemir",
      "Karabaglar",
      "Karaburun",
      "Karsiyaka",
      "Kemalpasa",
      "Menderes",
      "Menemen",
      "Mordogan",
      "OEdemis",
      "Ozdere",
      "Seferhisar",
      "Selcuk",
      "Urla",
      "Bergama",
      "Izmir",
      "Seferihisar",
      "Tire"
    ],
    Kahramanmaraş: ["Kahramanmaras", "Elbistan", "Kahramanmaraş"],
    Karabuek: ["Safranbolu", "Karabük"],
    Kars: ["Basgedikler", "Kars"],
    Kastamonu: ["Abana", "Akkaya", "Cide", "Tosya", "Kastamonu"],
    Kayseri: ["Develi", "Melikgazi", "Sarioglan", "Talas", "Kayseri"],
    Kırıkkale: ["Delice", "Keskin", "Kirikkale", "Yahsihan"],
    Kırklareli: [
      "Babaeski",
      "Igneada",
      "Kirklareli",
      "Vize",
      "Kırklareli",
      "Lüleburgaz"
    ],
    Kocaeli: [
      "Akcaova",
      "Balcik",
      "Derbent",
      "Kandira",
      "Karamursel",
      "Korfez",
      "Kosekoy",
      "Kullar",
      "Gebze",
      "İzmit"
    ],
    Konya: [
      "Asagipinarbasi",
      "Beysehir",
      "Bozkir",
      "Hatip",
      "Selcuklu",
      "Seydisehir",
      "Konya"
    ],
    Kütahya: ["Dagardi", "Emet", "Kutahya", "Orencik", "Simav", "Kütahya"],
    Malatya: ["Darende", "Malatya"],
    Manisa: [
      "Adala",
      "Akhisar",
      "Karaoglanli",
      "Kula",
      "Manisa",
      "Muradiye",
      "Turgutlu",
      "Salihli",
      "Soma"
    ],
    Mardin: ["Kiziltepe", "Midyat", "Nusaybin", "Mardin"],
    Mersin: [
      "Akdere",
      "Anamur",
      "Erdemli",
      "Kocahasanli",
      "Mercin",
      "Mezitli",
      "Mut",
      "Silifke",
      "Mersin",
      "Tarsus"
    ],
    Muğla: [
      "Bozburun",
      "Dalaman",
      "Datca",
      "Goecek",
      "Karatoprak",
      "Kargi",
      "Kiulukioi",
      "Marmaris",
      "OEluedeniz",
      "Ortaca",
      "Ortakent",
      "Sarigerme",
      "Turgutreis",
      "Ula",
      "Yalikavak",
      "Yaniklar",
      "Yerkesik",
      "Bodrum",
      "Fethiye",
      "Milas",
      "Mugla"
    ],
    Nevsehir: ["Avanos", "Goereme", "Urgub", "Nevşehir"],
    Nigde: ["Camardi", "Ulukisla", "Niğde"],
    Ordu: ["Fatsa", "Korgan", "Kumru", "Persembe", "Unieh", "Ordu"],
    Rize: [
      "Camlihemsin",
      "Cayeli",
      "Findikli",
      "Gundogdu",
      "Hemsin",
      "Kaptanpasa",
      "Nurluca",
      "Rize"
    ],
    Sakarya: [
      "Adapazarı",
      "Akyazi",
      "Dogancay",
      "Ferizli",
      "Geyve",
      "Hendek",
      "Karadere",
      "Karasu",
      "Kazimpasa",
      "Kocaali",
      "Sapanca",
      "Sogutlu"
    ],
    Samsun: ["Bafra", "Havza", "Taflan", "Samsun", "Terme"],
    Şanlıurfa: [
      "Birecik",
      "Bozova",
      "Camlidere",
      "Ceylanpinar",
      "Harran",
      "Sanliurfa",
      "Siverek",
      "Viranşehir"
    ],
    Siirt: ["Taliban", "Siirt"],
    Sinop: ["Ayancik", "Erfelek", "Sinop"],
    Şırnak: ["Cizre", "Silopi", "Sirnak"],
    Sivas: [
      "Divrigi",
      "Gemerek",
      "Gurun",
      "Kuskayasi",
      "Sarkisla",
      "Sivas",
      "Zara"
    ],
    Tekirdağ: [
      "Cerkezkoey",
      "Çerkezköy",
      "Çorlu",
      "Kumbag",
      "Malkara",
      "Marmara Ereglisi",
      "Sarkoey",
      "Tekirdag",
      "Tekirdağ"
    ],
    Tokat: ["Camici", "Erbaa", "Niksar", "Tokat", "Turhal", "Zile"],
    Trabzon: [
      "Arakli",
      "Arsin",
      "Derecik",
      "Macka",
      "Surmene",
      "Uzungol",
      "Vakfikebir",
      "Yomra",
      "Trabzon"
    ],
    Uşak: ["Banaz", "Kizilcasogut", "Usak", "Uşak"],
    Van: ["Van"],
    Yalova: ["Cinarcik", "Yalova"],
    Yozgat: ["Sorgun", "Yozgat"],
    Zonguldak: ["Devrek", "Egerci", "Kozlu", "Ormanli", "Zonguldak"]
  },
  Turkmenistan: { Lebap: [], Ahal: ["Kaka", "Ashgabat"], Balkan: [], Mary: [] },
  "Turks and Caicos Islands": {},
  Tuvalu: {},
  Uganda: {
    "Central Region": [
      "Entebbe",
      "Kampala",
      "Kampala Central Division",
      "Kireka",
      "Luwero",
      "Mityana",
      "Nakasongola"
    ],
    "Eastern Region": ["Jinja", "Mbale", "Soroti"],
    "Northern Region": ["Amuru", "Gulu", "Kaabong", "Moyo"],
    "Western Region": ["Kanungu", "Kasese", "Masindi", "Ntungamo", "Nyachera"]
  },
  Ukraine: {
    Cherkasy: ["Cherkasy", "Kamianka", "Korsun'-Shevchenkivs'kyy", "Uman'"],
    Crimea: [
      "Alupka",
      "Alushta",
      "Bakhchysaray",
      "Feodosiya",
      "Gaspra",
      "Hurzuf",
      "Koktebel'",
      "Massandra",
      "Myrnyy",
      "Olenevka",
      "Partenit",
      "Simeiz",
      "Simferopol",
      "Uyutne",
      "Yalta"
    ],
    Dnipropetrovsk: ["Dnipropetrovsk", "Kryvyi Rih"],
    Donetsk: ["Avdiyivka"],
    "Gorod Sevastopol": ["Sevastopol"],
    Kharkiv: ["Kharkiv", "Pechenihy", "Zmiyiv"],
    Kirovohrad: ["Kirovohrad", "Subottsi"],
    Kropyvnytskyi: ["Kropyvnytskyi", "Oleksandriya"],
    "Luhans'ka Oblast'": [
      "Krasnodon",
      "Kreminna",
      "Lisichansk",
      "Luhansk",
      "Rubizhne"
    ],
    Lviv: ["Bibrka", "L'viv"],
    "Misto Sevastopol'": ["Balaklava", "Sevastopol"],
    Mykolaiv: ["Mykolayiv"],
    "Mykolayivs'ka Oblast'": ["Mykolayiv", "Ochakiv"],
    Poltava: ["Poltava"],
    Transcarpathia: [
      "Khust",
      "Mukacheve",
      "Rakhiv",
      "Svalyava",
      "Uzhhorod",
      "Vynohradiv"
    ],
    Zhytomyr: ["Korosten'"],
    "Zhytomyrs'ka Oblast'": [
      "Berdychiv",
      "Korosten",
      "Korostyshiv",
      "Malyn",
      "Ovruch",
      "Zhytomyr"
    ],
    "Cherkas'ka Oblast'": ["Kaniv", "Smila", "Zolotonosha", "Cherkasy"],
    Chernihiv: ["Nizhyn", "Pryluky", "Chernihiv", "Mena"],
    Chernivtsi: ["Khotyn", "Novodnestrovsk", "Chernivtsi"],
    Dnipro: ["Kamianske", "Kryvyi Rih", "Nikopol", "Novomoskovsk", "Dnipro"],
    "Donets'ka Oblast'": [
      "Druzhkovka",
      "Horlivka",
      "Kramatorsk",
      "Kurakhovo",
      "Myrnohrad",
      "Donetsk",
      "Mariupol"
    ],
    "Ivano-Frankivs'ka Oblast'": [
      "Bolekhiv",
      "Burshtyn",
      "Dolyna",
      "Ivano-Frankivsk",
      "Ivano-Frankivs'k",
      "Kolomyia",
      "Nadvirna",
      "Sniatyn",
      "Kalush"
    ],
    "Kharkivs'ka Oblast'": ["Izyum", "Kharkiv"],
    "Khersons'ka Oblast'": ["Kakhovka", "Nova Kakhovka", "Kherson"],
    "Khmel'nyts'ka Oblast'": [
      "Dunaivtsi",
      "Krasyliv",
      "Netishyn",
      "Shepetivka",
      "Slavuta",
      "Khmelnytskyi"
    ],
    Kyiv: [
      "Bohuslav",
      "Borodianka",
      "Boryspil",
      "Boyarka",
      "Bucha",
      "Ivankiv",
      "Khotiv",
      "Kryva Hora",
      "Novi Petrivtsi",
      "Obukhiv",
      "Skvyra",
      "Slavutych",
      "Vasylkiv",
      "Vyshhorod",
      "Vyshneve",
      "Bila Tserkva",
      "Brovary",
      "Fastiv",
      "Irpin"
    ],
    "Kyiv City": ["Kiev"],
    "L'vivs'ka Oblast'": [
      "Boryslav",
      "Drohobych",
      "Sambir",
      "Stryi",
      "Zolochiv",
      "Brody",
      "Lviv",
      "Mykolaiv"
    ],
    Odessa: [
      "Bilhorod-Dnistrovs'kyy",
      "Krasnosilka",
      "Odesa",
      "Odessa",
      "Ovidiopol",
      "Yuzhne",
      "Izmail"
    ],
    "Poltavs'ka Oblast'": [
      "Hadyach",
      "Hrebinka",
      "Lubny",
      "Myrhorod",
      "Kremenchuk",
      "Poltava"
    ],
    "Republic of Crimea": [
      "Alushta",
      "Armyansk",
      "Bakhchisaray",
      "Dzhankoy",
      "Feodosiya",
      "Kerch",
      "Sudak",
      "Simferopol",
      "Yalta"
    ],
    "Rivnens'ka Oblast'": ["Dubno", "Ostroh", "Sarny", "Zdolbuniv", "Rivne"],
    "Sums'ka Oblast'": [
      "Hlukhiv",
      "Konotop",
      "Krolevets",
      "Lebedyn",
      "Okhtyrka",
      "Romny",
      "Shostka",
      "Sumy"
    ],
    "Ternopil's'ka Oblast'": [
      "Buchach",
      "Chortkiv",
      "Kremenets",
      "Terebovlya",
      "Ternopil"
    ],
    "Vinnyts'ka Oblast'": [
      "Kalynivka",
      "Khmilnyk",
      "Ladyzhyn",
      "Vinnytsya",
      "Zhmerynka",
      "Vinnytsia"
    ],
    "Volyns'ka Oblast'": ["Kivertsi", "Kovel", "Lutsk"],
    Zaporizhia: [
      "Energodar",
      "Vesele",
      "Vodyane",
      "Zaporizhzhya",
      "Melitopol",
      "Zaporizhia"
    ]
  },
  "United Arab Emirates": {
    "Ra's al Khaymah": ["Ras al-Khaimah"],
    "Umm al Qaywayn": ["Umm al Qaywayn"],
    "Abu Dhabi": ["Ar Ruways", "Muzayri`", "Abu Dhabi", "Al Ain"],
    Ajman: ["Ajman"],
    "Al Fujayrah": [
      "Al Fujayrah",
      "Dibba Al-Fujairah",
      "Dibba Al-Hisn",
      "Fujairah"
    ],
    "Ash Shariqah": ["Adh Dhayd", "Khawr Fakkan", "Sharjah"],
    Dubai: ["Jumeirah", "Dubai"]
  },
  "United Kingdom": {
    England: [
      "Abbots Langley",
      "Accrington",
      "Acocks Green",
      "Addlestone",
      "Aldeburgh",
      "Alderley Edge",
      "Aldridge",
      "Alfreton",
      "Alsager",
      "Alton",
      "Altrincham",
      "Ambleside",
      "Amersham",
      "Amersham on the Hill",
      "Ampthill",
      "Andover",
      "Annfield Plain",
      "Appleby-in-Westmorland",
      "Armitage",
      "Armthorpe",
      "Arnside",
      "Arundel",
      "Ashburton",
      "Ashington",
      "Ashtead",
      "Ashton in Makerfield",
      "Avebury",
      "Axminster",
      "Backworth",
      "Bacup",
      "Badminton",
      "Baildon",
      "Bakewell",
      "Baldock",
      "Bamburgh",
      "Banstead",
      "Barbican",
      "Barking",
      "Barnet",
      "Barnham",
      "Barnoldswick",
      "Barnsbury",
      "Barnstaple",
      "Barrowford",
      "Bartley Green",
      "Basildon",
      "Batley",
      "Battersea",
      "Battle",
      "Bawtry",
      "Bayswater",
      "Beaconsfield",
      "Beaminster",
      "Bebington",
      "Beccles",
      "Beckenham",
      "Becontree",
      "Bedworth",
      "Beer",
      "Beeston",
      "Beighton",
      "Belford",
      "Belper",
      "Belsize Park",
      "Belvedere",
      "Benson",
      "Berkhamsted",
      "Berwick-Upon-Tweed",
      "Bethnal Green",
      "Bexhill-on-Sea",
      "Bexley",
      "Bicester",
      "Bickenhill",
      "Bicknacre",
      "Biddulph",
      "Bideford",
      "Bidford-on-Avon",
      "Biggin Hill",
      "Biggleswade",
      "Billingham",
      "Bingley",
      "Bishop Auckland",
      "Bishops Stortford",
      "Bishops Waltham",
      "Blackheath",
      "Blacon",
      "Blagdon",
      "Blandford Forum",
      "Blaydon",
      "Blaydon-on-Tyne",
      "Bletchley",
      "Bloxwich",
      "Blyth",
      "Bodle Street",
      "Bodmin",
      "Bolsover",
      "Bootle",
      "Bordon",
      "Borehamwood",
      "Borough Green",
      "Bosham",
      "Botley",
      "Bottesford",
      "Bourne",
      "Bovey Tracey",
      "Bowes",
      "Bowthorpe",
      "Brackley",
      "Bradford-on-Avon",
      "Braintree",
      "Bramhall",
      "Bramhope",
      "Bray",
      "Bredbury",
      "Bremhill",
      "Brentwood",
      "Bridgnorth",
      "Bridgwater",
      "Bridlington",
      "Bridport",
      "Brierfield",
      "Brierley Hill",
      "Brighouse",
      "Brixham",
      "Brixton Hill",
      "Broadstairs",
      "Broadstone",
      "Broadway",
      "Bromborough",
      "Bromley",
      "Brough",
      "Brownhills",
      "Broxbourne",
      "Bude",
      "Bugbrooke",
      "Burford",
      "Burgess Hill",
      "Burnham-on-Sea",
      "Burntwood",
      "Burton upon Trent",
      "Bushey",
      "Buxton",
      "Byfleet",
      "Calne",
      "Camberley",
      "Camberwell",
      "Camborne",
      "Camden",
      "Camden Town",
      "Canvey Island",
      "Carshalton",
      "Carterton",
      "Castle Donington",
      "Castleford",
      "Caterham",
      "Catford",
      "Chalfont Saint Peter",
      "Chalford",
      "Chalgrove",
      "Chalton",
      "Chapel Allerton",
      "Chapletown",
      "Chard",
      "Chatteris",
      "Cheadle",
      "Cheadle Hulme",
      "Cheam",
      "Cheetham Hill",
      "Chelmsford",
      "Chertsey",
      "Chesham",
      "Cheshunt",
      "Chessington",
      "Chester-le-Street",
      "Cheswick Green",
      "Chippenham",
      "Chipping Campden",
      "Chipping Sodbury",
      "Chislehurst",
      "Chorleywood",
      "Christchurch",
      "Churchdown",
      "Cinderford",
      "Cirencester",
      "City of London",
      "City of Westminster",
      "Clayton",
      "Clayton-le-Woods",
      "Cleckheaton",
      "Clerkenwell",
      "Clevedon",
      "Cleveleys",
      "Clitheroe",
      "Coalville",
      "Coates",
      "Cockermouth",
      "Codicote",
      "Coggeshall",
      "Cold Ash",
      "Cold Ashton",
      "Colden Common",
      "Coleford",
      "Collier Row",
      "Colne",
      "Colyton",
      "Combe Martin",
      "Consett",
      "Cookley",
      "Corsham",
      "Cosham",
      "Cranham",
      "Cranleigh",
      "Crediton",
      "Crewe",
      "Cricklade",
      "Cromer",
      "Crosby",
      "Crouch End",
      "Crowborough",
      "Crowthorne",
      "Crumpsall",
      "Cudworth",
      "Cuxton",
      "Dagenham",
      "Danby",
      "Dartford",
      "Darton",
      "Darwen",
      "Datchet",
      "Daventry",
      "Dawlish",
      "Delph",
      "Denton",
      "Dereham",
      "Devizes",
      "Dewsbury",
      "Didcot",
      "Didsbury",
      "Dinnington",
      "Diss",
      "Dorchester",
      "Dorking",
      "Downham Market",
      "Driffield",
      "Droitwich",
      "Dronfield",
      "Dukinfield",
      "Duloe",
      "Dunstable",
      "Durham",
      "Dursley",
      "Ealing",
      "Ealing Broadway",
      "Earls Barton",
      "Earlsfield",
      "East Bergholt",
      "East Cowes",
      "East Dereham",
      "East Grinstead",
      "East Ham",
      "East Horsley",
      "East Molesey",
      "East Retford",
      "East Wittering",
      "Eastwood",
      "Eaton Socon",
      "Eccles",
      "Eccleshall",
      "Edwinstowe",
      "Egham",
      "Elland",
      "Ellesmere Port",
      "Embsay",
      "Emsworth",
      "Enfield",
      "Enfield Town",
      "Epping",
      "Erith",
      "Esher",
      "Eton",
      "Evesham",
      "Ewell",
      "Exhall",
      "Exmouth",
      "Exning",
      "Eynsham",
      "Eyton upon the Weald Moors",
      "Failsworth",
      "Fakenham",
      "Falfield",
      "Fareham",
      "Farnborough",
      "Farnham Royal",
      "Farnworth",
      "Faversham",
      "Featherstone",
      "Felixstowe",
      "Feltham",
      "Ferndown",
      "Fernhill Heath",
      "Findern",
      "Fleet",
      "Fleetwood",
      "Flitwick",
      "Folkestone",
      "Formby",
      "Fortuneswell",
      "Four Marks",
      "Frimley",
      "Frinton-on-Sea",
      "Frome",
      "Gainsborough",
      "Garforth",
      "Gerrards Cross",
      "Gillingham",
      "Glossop",
      "Glusburn",
      "Godalming",
      "Golborne",
      "Goole",
      "Goring-by-Sea",
      "Gosforth",
      "Gosport",
      "Grantham",
      "Gravesend",
      "Great Harwood",
      "Great Malvern",
      "Great Marton",
      "Great Wyrley",
      "Great Yarmouth",
      "Greenford",
      "Greenhill",
      "Grimsby",
      "Guildford",
      "Guisborough",
      "Guiseley",
      "Hackney",
      "Hadleigh",
      "Hadley Wood",
      "Hailsham",
      "Hainault",
      "Hale",
      "Halifax",
      "Halstead",
      "Halton",
      "Hammersmith",
      "Hampton",
      "Harold Wood",
      "Harpenden",
      "Harringay",
      "Harrow on the Hill",
      "Harwich",
      "Haslemere",
      "Haslingden",
      "Hatfield Heath",
      "Hathersage",
      "Havant",
      "Haverhill",
      "Hawkhurst",
      "Haxby",
      "Haydock",
      "Hayfield",
      "Hayling Island",
      "Haywards Heath",
      "Hazel Grove",
      "Heanor",
      "Heavitree",
      "Hebburn",
      "Heckmondwike",
      "Hedge End",
      "Helston",
      "Hemel Hempstead",
      "Hendon",
      "Henley in Arden",
      "Henley on Thames",
      "Henlow",
      "Herne Bay",
      "Heswall",
      "Hexham",
      "Heywood",
      "High Bentham",
      "High Coniscliffe",
      "High Peak",
      "High Wycombe",
      "Hindley",
      "Hockley",
      "Hoddesdon",
      "Holloway",
      "Honiton",
      "Hook",
      "Hook Norton",
      "Hoole",
      "Horley",
      "Hornchurch",
      "Horsforth",
      "Horsham",
      "Horwich",
      "Houghton-Le-Spring",
      "Hounslow",
      "Hucknall",
      "Hulme",
      "Huntingdon",
      "Hurstpierpoint",
      "Huttons Ambo",
      "Huyton",
      "Hyde",
      "Hythe",
      "Ilford",
      "Ilfracombe",
      "Ilkeston",
      "Ilkley",
      "Immingham",
      "Ingatestone",
      "Ingleton",
      "Ingoldmells",
      "Irlam",
      "Isleworth",
      "Ivybridge",
      "Jarrow",
      "Kearsley",
      "Keighley",
      "Kempston",
      "Kempston Hardwick",
      "Kennington",
      "Keswick",
      "Kettering",
      "Keynsham",
      "Kidderminster",
      "Kidlington",
      "Kidsgrove",
      "Kingsbury",
      "Kingsclere",
      "Kingsland",
      "King's Lynn",
      "Kings Lynn",
      "Kingston upon Thames",
      "Kingswinford",
      "Kingswood",
      "Kirkby in Ashfield",
      "Kirkham",
      "Knaresborough",
      "Knottingley",
      "Knowle",
      "Knutsford",
      "Lacock",
      "Lambeth",
      "Lancaster",
      "Lancing",
      "Leatherhead",
      "Lechlade",
      "Leek",
      "Leigh-on-Sea",
      "Leighton Buzzard",
      "Lenham",
      "Letchworth",
      "Letchworth Garden City",
      "Lincoln",
      "Liss",
      "Litherland",
      "Littleborough",
      "Liversedge",
      "Llanrothal",
      "Lofthouse",
      "Long Eaton",
      "Longfield",
      "Longridge",
      "Longsight",
      "Long Sutton",
      "Longton",
      "Loughborough",
      "Louth",
      "Lower Earley",
      "Lyme Regis",
      "Lymington",
      "Lymm",
      "Lytham St Annes",
      "Mablethorpe",
      "Macclesfield",
      "Maghull",
      "Maidenhead",
      "Maldon",
      "Maltby",
      "Malton",
      "Manchester",
      "Mangotsfield",
      "Mansfield Woodhouse",
      "Margate",
      "Mark",
      "Market Deeping",
      "Market Drayton",
      "Marple",
      "Martham",
      "Matlock",
      "Melbourne",
      "Melksham",
      "Melling",
      "Melton Mowbray",
      "Mendip",
      "Mere",
      "Mevagissey",
      "Mexborough",
      "Mickleham",
      "Middlewich",
      "Midhurst",
      "Midsomer Norton",
      "Mildenhall",
      "Millbrook",
      "Milnrow",
      "Minehead",
      "Mirfield",
      "Mitcham",
      "Morden",
      "Moreton",
      "Morpeth",
      "Mossley",
      "Mountsorrel",
      "Nailsea",
      "Nailsworth",
      "Nelson",
      "Neston",
      "Nether Poppleton",
      "Netley",
      "Newark on Trent",
      "Newburn",
      "Newbury",
      "Newcastle",
      "Newcastle under Lyme",
      "Newcastle upon Tyne",
      "Newdigate",
      "Newhaven",
      "New Malden",
      "Newmarket",
      "New Mills",
      "New Milton",
      "Newport",
      "Newport Pagnell",
      "Newton Abbot",
      "Newton Aycliffe",
      "New York",
      "Northallerton",
      "Northam",
      "North Elmham",
      "Northleach",
      "Northolt",
      "North Shields",
      "North Walsham",
      "Northwich",
      "Oadby",
      "Oakham",
      "Oakmere",
      "Orgreave",
      "Ormskirk",
      "Ossett",
      "Oswestry",
      "Otley",
      "Ottershaw",
      "Oughtibridge",
      "Oxted",
      "Paddock Wood",
      "Padiham",
      "Padstow",
      "Paignton",
      "Pangbourne",
      "Par",
      "Parkstone",
      "Penistone",
      "Penrith",
      "Penzance",
      "Perranporth",
      "Pershore",
      "Peterborough",
      "Peterlee",
      "Petersfield",
      "Petworth",
      "Pinxton",
      "Pitsea",
      "Plympton",
      "Plymstock",
      "Polzeath",
      "Ponteland",
      "Poplar",
      "Porthleven",
      "Portland",
      "Portscatho",
      "Portslade",
      "Potters Bar",
      "Poynton",
      "Prenton",
      "Prescot",
      "Princes Risborough",
      "Prudhoe",
      "Pudsey",
      "Pulborough",
      "Purfleet",
      "Purley",
      "Radlett",
      "Radstock",
      "Ramsgate",
      "Rawmarsh",
      "Rawtenstall",
      "Rayleigh",
      "Redcar",
      "Redhill",
      "Redruth",
      "Retford",
      "Richmond",
      "Rickmansworth",
      "Ringway",
      "River",
      "Rochdale",
      "Rochester",
      "Rochford",
      "Romford",
      "Romsey",
      "Rottingdean",
      "Rowhedge",
      "Royal Leamington Spa",
      "Royal Tunbridge Wells",
      "Royal Wootton Bassett",
      "Royston",
      "Royton",
      "Rugeley",
      "Ruislip",
      "Runcorn",
      "Rushden",
      "Rye",
      "Saffron Walden",
      "Saltash",
      "Saltburn-by-the-Sea",
      "Sandbach",
      "Sandown",
      "Sandy",
      "Sawbridgeworth",
      "Scarborough",
      "Scunthorpe",
      "Seaham",
      "Sedbergh",
      "Selby",
      "Selsey",
      "Send",
      "Sennen",
      "Settle",
      "Severn Beach",
      "Shalford",
      "Sharnbrook",
      "Sheerness",
      "Shefford",
      "Shepherdswell",
      "Shepperton",
      "Shepshed",
      "Shepton Mallet",
      "Sherborne",
      "Sheringham",
      "Shipley",
      "Shiremoor",
      "Shoreham-by-Sea",
      "Shurdington",
      "Sidcup",
      "Siddington",
      "Silverstone",
      "Simpson",
      "Sittingbourne",
      "Skegness",
      "Skelmersdale",
      "Sleaford",
      "Slough",
      "Smethwick",
      "Snodland",
      "Somerton",
      "Southall",
      "South Bank",
      "South Croydon",
      "South Elmsall",
      "Southend-on-Sea",
      "South Ockendon",
      "Spalding",
      "Spennymoor",
      "Staines",
      "Stalham",
      "Stalybridge",
      "Stamford",
      "Stanley",
      "St Austell",
      "Staveley",
      "Stevenage",
      "St Helens",
      "St Ives",
      "St Leonards",
      "Stocksbridge",
      "Stockton-on-Tees",
      "Stoke-on-Trent",
      "Stokesley",
      "Stony Stratford",
      "Storrington",
      "Stourbridge",
      "Stourport-on-Severn",
      "Stowmarket",
      "Stow on the Wold",
      "Stratford-upon-Avon",
      "Stretford",
      "Strood",
      "Stroud",
      "Sunbury-on-Thames",
      "Sunningdale",
      "Surbiton",
      "Sutton Coldfield",
      "Sutton in Ashfield",
      "Swadlincote",
      "Swanage",
      "Swanley",
      "Swanscombe",
      "Swinton",
      "Syston",
      "Tadley",
      "Tarvin",
      "Tavistock",
      "Teignmouth",
      "Tewkesbury",
      "Thame",
      "Thames Ditton",
      "Thatcham",
      "Thetford",
      "Thirsk",
      "Thornaby",
      "Thornaby-on-Tees",
      "Thorne",
      "Thornton Heath",
      "Thorpe Hamlet",
      "Tilbury",
      "Timperley",
      "Tisbury",
      "Tiverton",
      "Todmorden",
      "Tollesbury",
      "Tonbridge",
      "Torpoint",
      "Totland",
      "Totnes",
      "Totton",
      "Towcester",
      "Trafford Park",
      "Tring",
      "Trowbridge",
      "Truro",
      "Twickenham",
      "Tyldesley",
      "Tynemouth",
      "Uckfield",
      "Ulverston",
      "Upton upon Severn",
      "Uttoxeter",
      "Verwood",
      "Virginia Water",
      "Wadebridge",
      "Wadhurst",
      "Walkden",
      "Wallasey",
      "Wallingford",
      "Wallsend",
      "Walsall",
      "Waltham Abbey",
      "Walthamstow",
      "Walton",
      "Walton-on-Thames",
      "Wanborough",
      "Wandsworth",
      "Wantage",
      "Ware",
      "Warminster",
      "Warwick",
      "Washington",
      "Water Eaton",
      "Waterlooville",
      "Wath upon Dearne",
      "Watton at Stone",
      "Wednesbury",
      "Wednesfield",
      "Wellesbourne Mountford",
      "Welling",
      "Wellingborough",
      "Wellington",
      "Welwyn Garden City",
      "Wembley",
      "Wentworth",
      "West Bridgford",
      "West Bromwich",
      "Westbury",
      "West Drayton",
      "West End",
      "Westhoughton",
      "West Kirby",
      "West Mersea",
      "West Molesey",
      "Weston-super-Mare",
      "West Wickham",
      "Wetherby",
      "Weybridge",
      "Whalley",
      "Whickham",
      "Whitefield",
      "Whitehaven",
      "Whitley Bay",
      "Whitstable",
      "Whittlesey",
      "Wickford",
      "Widnes",
      "Wigginton",
      "Wigston Magna",
      "Willenhall",
      "Wimborne Minster",
      "Windermere",
      "Windsor",
      "Wingerworth",
      "Winkleigh",
      "Winsford",
      "Winterbourne Stoke",
      "Winterton",
      "Witham",
      "Witney",
      "Woking",
      "Wokingham",
      "Wombwell",
      "Woodbridge",
      "Woodcote",
      "Woodford Green",
      "Wood Green",
      "Woodstock",
      "Wool",
      "Woolwich",
      "Worcester",
      "Workington",
      "Worksop",
      "Worthing",
      "Wotton-under-Edge",
      "Writtle",
      "Wymondham",
      "Yateley",
      "Yatton",
      "Yeadon",
      "Yeovil",
      "Abingdon",
      "Acton",
      "Aldershot",
      "Arnold",
      "Ascot",
      "Ashford",
      "Atherstone",
      "Atherton",
      "Aylesbury",
      "Banbury",
      "Barnsley",
      "Basingstoke",
      "Bath",
      "Bedford",
      "Bedlington",
      "Bentley",
      "Beverley",
      "Billericay",
      "Birkenhead",
      "Birmingham",
      "Blackburn",
      "Blackpool",
      "Bognor Regis",
      "Bolton",
      "Boston",
      "Bournemouth",
      "Bracknell",
      "Bradford",
      "Brentford",
      "Brighton",
      "Bristol",
      "Brixton",
      "Bromsgrove",
      "Buckhurst Hill",
      "Buckingham",
      "Burnley",
      "Bury",
      "Bury St Edmunds",
      "Cambridge",
      "Cannock",
      "Canterbury",
      "Carlisle",
      "Chatham",
      "Chelsea",
      "Cheltenham",
      "Chester",
      "Chesterfield",
      "Chichester",
      "Chigwell",
      "Chorley",
      "Cleethorpes",
      "Cobham",
      "Colchester",
      "Congleton",
      "Corby",
      "Coulsdon",
      "Coventry",
      "Cowley",
      "Cramlington",
      "Crawley",
      "Croydon",
      "Darlington",
      "Deal",
      "Derby",
      "Doncaster",
      "Dover",
      "Droylsden",
      "Dudley",
      "Eastbourne",
      "Eastleigh",
      "Ely",
      "Epsom",
      "Exeter",
      "Fallowfield",
      "Falmouth",
      "Farnham",
      "Gateshead",
      "Gloucester",
      "Grays",
      "Grove",
      "Halesowen",
      "Harlow",
      "Harrogate",
      "Hartlepool",
      "Hartley",
      "Hastings",
      "Hatfield",
      "Hayes",
      "Hereford",
      "Hertford",
      "Hinckley",
      "Hitchin",
      "Hove",
      "Huddersfield",
      "Hull",
      "Ipswich",
      "Islington",
      "Kendal",
      "Kenilworth",
      "Kensington",
      "Kirkby",
      "Leeds",
      "Leicester",
      "Leigh",
      "Lewes",
      "Leyland",
      "Lichfield",
      "Littlehampton",
      "Liverpool",
      "London",
      "Lowestoft",
      "Luton",
      "Maidstone",
      "Mansfield",
      "March",
      "Market Harborough",
      "Marlow",
      "Middlesbrough",
      "Middleton",
      "Milton Keynes",
      "Molesey",
      "Morecambe",
      "Morley",
      "Nantwich",
      "Newquay",
      "Northampton",
      "Norwich",
      "Nottingham",
      "Nuneaton",
      "Oldbury",
      "Oldham",
      "Orpington",
      "Oxford",
      "Peacehaven",
      "Pinner",
      "Plymouth",
      "Pontefract",
      "Poole",
      "Portishead",
      "Portsmouth",
      "Preston",
      "Prestwich",
      "Radcliffe",
      "Ramsbottom",
      "Reading",
      "Redditch",
      "Reigate",
      "Ringwood",
      "Ripley",
      "Ripon",
      "Rossendale",
      "Rotherham",
      "Rugby",
      "Rustington",
      "Ryde",
      "Sale",
      "Salford",
      "Salisbury",
      "Seaford",
      "Sevenoaks",
      "Sheffield",
      "Shrewsbury",
      "Sidmouth",
      "Skipton",
      "Solihull",
      "Southampton",
      "Southport",
      "Southsea",
      "South Shields",
      "Stafford",
      "St Albans",
      "Stanford",
      "Stockport",
      "Stone",
      "Street",
      "Sudbury",
      "Sunderland",
      "Sutton",
      "Swindon",
      "Tamworth",
      "Taunton",
      "Telford",
      "Tipton",
      "Torquay",
      "Urmston",
      "Wakefield",
      "Warrington",
      "Watford",
      "Wells",
      "Weymouth",
      "Whitby",
      "Wigan",
      "Wilmslow",
      "Winchester",
      "Wisbech",
      "Wolverhampton",
      "Yarm",
      "Yate",
      "York"
    ],
    "Northern Ireland": [
      "Antrim",
      "Banbridge",
      "Broughshane",
      "Bushmills",
      "Carrickfergus",
      "Castlereagh",
      "Coleraine",
      "Cookstown",
      "Craigavon",
      "Crossgar",
      "Crumlin",
      "Derry",
      "Donaghadee",
      "Downpatrick",
      "Dundonald",
      "Dungannon",
      "Enniskillen",
      "Larne",
      "Limavady",
      "Lisburn",
      "Londonderry",
      "Londonderry County Borough",
      "Magherafelt",
      "Newry",
      "Newtownabbey",
      "Newtownards",
      "Omagh",
      "Portadown",
      "Portavogie",
      "Portrush",
      "Strabane",
      "Armagh",
      "Ballymena",
      "Bangor",
      "Belfast"
    ],
    Scotland: [
      "Airth",
      "Alloa",
      "Anstruther",
      "Arbroath",
      "Ardrossan",
      "Armadale",
      "Aviemore",
      "Ayr",
      "Barrhead",
      "Bathgate",
      "Bearsden",
      "Bellshill",
      "Bishopbriggs",
      "Bonnyrigg",
      "Bothwell",
      "Brechin",
      "Broxburn",
      "Buckhaven",
      "Cambuslang",
      "Carluke",
      "Carnoustie",
      "Clarkston",
      "Clydebank",
      "Coatbridge",
      "Colinton",
      "Cove",
      "Cowdenbeath",
      "Cross",
      "Cumbernauld",
      "Dalkeith",
      "Dalserf",
      "Dingwall",
      "Doune",
      "Dumbarton",
      "Dunfermline",
      "East Kilbride",
      "East Wemyss",
      "Elgin",
      "Ellon",
      "Erskine",
      "Ford",
      "Forfar",
      "Fort William",
      "Fraserburgh",
      "Galashiels",
      "Garelochhead",
      "Giffnock",
      "Glenrothes",
      "Gourock",
      "Grangemouth",
      "Hamilton",
      "Harris",
      "Hawick",
      "Helensburgh",
      "High Blantyre",
      "Inverness",
      "Inverurie",
      "Irvine",
      "Isle of Islay",
      "Isle of Lewis",
      "Isle Of Mull",
      "Isle of Skye",
      "Jedburgh",
      "Johnstone",
      "Keith",
      "Kilmarnock",
      "Kilwinning",
      "Kinross",
      "Kirkcaldy",
      "Kirkintilloch",
      "Kirkwall",
      "Largs",
      "Larkhall",
      "Laurencekirk",
      "Lerwick",
      "Linlithgow",
      "Maybole",
      "Methil",
      "Mid Calder",
      "Milngavie",
      "Montrose",
      "Motherwell",
      "Musselburgh",
      "Newtown St Boswells",
      "North Berwick",
      "Orkney",
      "Penicuik",
      "Perth",
      "Peterhead",
      "Pitlochry",
      "Port Glasgow",
      "Portree",
      "Prestwick",
      "Queensferry",
      "Ratho",
      "Rosyth",
      "Rothes",
      "Rutherglen",
      "Saint Andrews",
      "Saline",
      "Saltcoats",
      "Sandwick",
      "Scone",
      "Seafield",
      "Shetland",
      "Spean Bridge",
      "St Andrews",
      "Stepps",
      "Stewarton",
      "Stirling",
      "Stonehaven",
      "Stranraer",
      "Tranent",
      "Troon",
      "Ullapool",
      "Westhill",
      "Whitburn",
      "Wishaw",
      "Aberdeen",
      "Airdrie",
      "Dumfries",
      "Dundee",
      "Edinburgh",
      "Falkirk",
      "Glasgow",
      "Greenock",
      "Livingston",
      "Paisley",
      "Renfrew"
    ],
    Wales: [
      "Aberdare",
      "Abergele",
      "Abertillery",
      "Aberystwyth",
      "Amlwch",
      "Bangor",
      "Bethesda",
      "Brecon",
      "Bridgend",
      "Brymbo",
      "Brynmawr",
      "Buckley",
      "Builth Wells",
      "Burry Port",
      "Caerphilly",
      "Caldicot",
      "Cardigan",
      "Carmarthen",
      "Chepstow",
      "Chirk",
      "Clydach",
      "Coity",
      "Colwyn Bay",
      "Cwmbran",
      "Deeside",
      "Ebbw Vale",
      "Gorseinon",
      "Greenfield",
      "Haverfordwest",
      "Hawarden",
      "Holyhead",
      "Langstone",
      "Llanberis",
      "Llandudno",
      "Llanrhaeadr-ym-Mochnant",
      "Llanrwst",
      "Llantrisant",
      "Llantwit Major",
      "Maesteg",
      "Merthyr Mawr",
      "Merthyr Tydfil",
      "Milford Haven",
      "Mold",
      "Mountain Ash",
      "Narberth",
      "Nefyn",
      "Penarth",
      "Pontypool",
      "Pontypridd",
      "Porthcawl",
      "Prestatyn",
      "Pyle",
      "Queensferry",
      "Rhayader",
      "Rhondda",
      "Rhyl",
      "Risca",
      "Saint Clears",
      "Tenby",
      "Tonypandy",
      "Tredegar",
      "Treharris",
      "Abergavenny",
      "Ammanford",
      "Bargoed",
      "Barry",
      "Blackwood",
      "Cardiff",
      "Llanelli",
      "Monmouth",
      "Neath",
      "Newport",
      "Newtown",
      "Swansea",
      "Wrexham"
    ]
  },
  "United States": {
    "District of Columbia": ["Washington"],
    Alabama: [
      "Alabaster",
      "Albertville",
      "Alexander City",
      "Athens",
      "Atmore",
      "Auburn",
      "Bayou La Batre",
      "Bessemer",
      "Cahaba Heights",
      "Calera",
      "Centreville",
      "Chelsea",
      "Childersburg",
      "Choccolocco",
      "Clanton",
      "Dadeville",
      "Dauphin Island",
      "Decatur",
      "Eclectic",
      "Eufaula",
      "Eva",
      "Evergreen",
      "Fairfield",
      "Fairhope",
      "Florence",
      "Foley",
      "Fort Payne",
      "Gardendale",
      "Good Hope",
      "Guntersville",
      "Hartselle",
      "Harvest",
      "Homewood",
      "Hoover",
      "Indian Springs Village",
      "Jack",
      "Jacksonville",
      "Jasper",
      "Ladonia",
      "Lake Purdy",
      "Leeds",
      "Lincoln",
      "Loxley",
      "Mountain Brook",
      "Orange Beach",
      "Oxford",
      "Pelham",
      "Pell City",
      "Phenix City",
      "Pike Road",
      "Prattville",
      "Prichard",
      "Redstone Arsenal",
      "Robertsdale",
      "Saraland",
      "Scottsboro",
      "Selma",
      "Southside",
      "Spanish Fort",
      "Sylacauga",
      "Talladega",
      "Troy",
      "Trussville",
      "Tuskegee",
      "Weaver",
      "Wilsonville",
      "Winfield",
      "Anniston",
      "Birmingham",
      "Cullman",
      "Daphne",
      "Dothan",
      "Enterprise",
      "Gadsden",
      "Gulf Shores",
      "Huntsville",
      "Madison",
      "Millbrook",
      "Mobile",
      "Montgomery",
      "Moody",
      "Muscle Shoals",
      "Northport",
      "Opelika",
      "Tuscaloosa"
    ],
    Alaska: [
      "Anchor Point",
      "Barrow",
      "Cordova",
      "Craig",
      "Eagle River",
      "Elmendorf Air Force Base",
      "Girdwood",
      "Haines",
      "Healy",
      "Homer",
      "Ketchikan",
      "Palmer",
      "Petersburg",
      "Prudhoe Bay",
      "Salcha",
      "Seward",
      "Sitka",
      "Sutton-Alpine",
      "Teller",
      "Valdez",
      "Willow",
      "Y",
      "Anchorage",
      "Fairbanks",
      "Juneau"
    ],
    Arizona: [
      "Apache Junction",
      "Arizona City",
      "Avondale",
      "Benson",
      "Bisbee",
      "Bullhead City",
      "Camp Verde",
      "Carefree",
      "Catalina",
      "Catalina Foothills",
      "Chinle",
      "Chino Valley",
      "Cienega Springs",
      "Citrus Park",
      "Coolidge",
      "Cordes Lakes",
      "Corona de Tucson",
      "Cottonwood",
      "Desert Hills",
      "Dolan Springs",
      "Douglas",
      "Eagar",
      "Ehrenberg",
      "El Mirage",
      "Eloy",
      "Florence",
      "Flowing Wells",
      "Fortuna Foothills",
      "Fountain Hills",
      "Globe",
      "Gold Canyon",
      "Grand Canyon",
      "Grand Canyon Village",
      "Guadalupe",
      "Heber-Overgaard",
      "Holbrook",
      "Kayenta",
      "Kingman",
      "Lake Havasu City",
      "LeChee",
      "Litchfield Park",
      "Marana",
      "Maricopa",
      "Meadview",
      "Mohave Valley",
      "New Kingman-Butler",
      "New River",
      "Nogales",
      "Page",
      "Paradise Valley",
      "Parker",
      "Payson",
      "Pine",
      "Pinetop-Lakeside",
      "Prescott Valley",
      "Rio Verde",
      "Sahuarita",
      "San Carlos",
      "San Tan Valley",
      "Sells",
      "Show Low",
      "Sierra Vista",
      "Somerton",
      "Star Valley",
      "Sun City West",
      "Tanque Verde",
      "Tempe Junction",
      "Tolleson",
      "Tortolita",
      "Tuba City",
      "West Sedona",
      "Anthem",
      "Buckeye",
      "Casa Grande",
      "Chandler",
      "Flagstaff",
      "Gilbert",
      "Glendale",
      "Goodyear",
      "Green Valley",
      "Mesa",
      "Peoria",
      "Phoenix",
      "Prescott",
      "Queen Creek",
      "Scottsdale",
      "Sedona",
      "South Tucson",
      "Sun City",
      "Surprise",
      "Tempe",
      "Tucson",
      "Vail",
      "Yuma"
    ],
    Arkansas: [
      "Arkadelphia",
      "Blytheville",
      "Branch",
      "Cabot",
      "Camden",
      "Camp",
      "Cave Springs",
      "Centerton",
      "Fayetteville",
      "Forrest City",
      "Fort Smith",
      "Goshen",
      "Harrison",
      "Hot Springs",
      "Hot Springs National Park",
      "Hot Springs Village",
      "Jacksonville",
      "Lake Hamilton",
      "Magazine",
      "Malvern",
      "Marion",
      "Maumelle",
      "McAlmont",
      "Mountain Home",
      "Paragould",
      "Pine Bluff",
      "Romance",
      "Searcy",
      "Siloam Springs",
      "Strong",
      "Texarkana",
      "Van Buren",
      "West Memphis",
      "Batesville",
      "Bella Vista",
      "Benton",
      "Bentonville",
      "Bryant",
      "Conway",
      "El Dorado",
      "Jonesboro",
      "Little Rock",
      "Magnolia",
      "Rogers",
      "Russellville",
      "Sherwood",
      "Springdale"
    ],
    California: [
      "Adelanto",
      "Agoura",
      "Agua Dulce",
      "Aguanga",
      "Alamo",
      "Albany",
      "Aliso Viejo",
      "Alondra Park",
      "Altadena",
      "Alta Sierra",
      "Alturas",
      "Alum Rock",
      "American Canyon",
      "Anderson",
      "Angwin",
      "Apple Valley",
      "Aptos",
      "Arbuckle",
      "Arcata",
      "Arden-Arcade",
      "Arnold",
      "Aromas",
      "Arroyo Grande",
      "Atascadero",
      "Atherton",
      "Atwater",
      "Auberry",
      "Auburn",
      "Avalon",
      "Avenal",
      "Avila Beach",
      "Avocado Heights",
      "Banning",
      "Bear Valley Springs",
      "Beaumont",
      "Bella Vista",
      "Bell Gardens",
      "Belvedere",
      "Benicia",
      "Bermuda Dunes",
      "Bertsch-Oceanview",
      "Bethel Island",
      "Big Bear",
      "Big Bear City",
      "Big Bear Lake",
      "Big Pine",
      "Bishop",
      "Blackhawk",
      "Black Point-Green Point",
      "Bloomington",
      "Blue Lake",
      "Bolinas",
      "Bonny Doon",
      "Bonsall",
      "Boonville",
      "Borrego Springs",
      "Bostonia",
      "Boulder Creek",
      "Boyes Hot Springs",
      "Boyle Heights",
      "Bradbury",
      "Brawley",
      "Brentwood",
      "Bridgeport",
      "Brisbane",
      "Broadmoor",
      "Buellton",
      "Buena Park",
      "Buena Vista",
      "Burney",
      "Buttonwillow",
      "Cabazon",
      "Calexico",
      "California City",
      "Calimesa",
      "Callender",
      "Cambria",
      "Cambrian Park",
      "Cameron Park",
      "Camp Meeker",
      "Camp Pendleton South",
      "Canoga Park",
      "Canyon Lake",
      "Capitola",
      "Carmel-by-the-Sea",
      "Carmel Valley Village",
      "Carmichael",
      "Carpinteria",
      "Casa Conejo",
      "Casa de Oro-Mount Helix",
      "Castaic",
      "Castro Valley",
      "Cathedral City",
      "Cayucos",
      "Central Valley (historical)",
      "Century City",
      "Cerritos",
      "Channel Islands Beach",
      "Charter Oak",
      "Cherryland",
      "Cherry Valley",
      "Chester",
      "Chowchilla",
      "Chula Vista",
      "Citrus",
      "Citrus Heights",
      "Clayton",
      "Clearlake",
      "Cloverdale",
      "Coalinga",
      "Cobb",
      "Commerce",
      "Cool",
      "Corte Madera",
      "Cotati",
      "Coto De Caza",
      "Cottonwood",
      "Country Club",
      "Crestline",
      "Cudahy",
      "Dana Point",
      "Danville",
      "Day Valley",
      "Deer Park",
      "Del Aire",
      "Delhi",
      "Del Mar",
      "Del Monte Forest",
      "Del Rey Oaks",
      "Del Rio",
      "Desert Hot Springs",
      "Desert View Highlands",
      "Diamond Bar",
      "Diamond Springs",
      "Dinuba",
      "Discovery Bay",
      "Dixon Lane-Meadow Creek",
      "Dogtown",
      "Downieville",
      "Dublin",
      "East La Mirada",
      "East Los Angeles",
      "East Palo Alto",
      "East Pasadena",
      "East Porterville",
      "East San Gabriel",
      "Echo Park",
      "El Cajon",
      "El Centro",
      "El Cerrito",
      "Eldridge",
      "Elk Grove",
      "El Monte",
      "El Rio",
      "El Sobrante",
      "Elverta",
      "Emerald Lake Hills",
      "Emeryville",
      "Encino",
      "Exeter",
      "Fairbanks Ranch",
      "Fairfax",
      "Fair Oaks",
      "Fallbrook",
      "Farmersville",
      "Fillmore",
      "Florence-Graham",
      "Florin",
      "Folsom",
      "Foothill Farms",
      "Foothill Ranch",
      "Foresthill",
      "Forestville",
      "Fort Bragg",
      "Foster City",
      "Fountain Valley",
      "Gardena",
      "Garden Grove",
      "Glen Avon",
      "Glendale",
      "Glendora",
      "Gold River",
      "Goleta",
      "Good Hope",
      "Grand Terrace",
      "Granite Bay",
      "Greenacres",
      "Green Acres",
      "Greenfield",
      "Greenville",
      "Grover Beach",
      "Guerneville",
      "Hacienda Heights",
      "Hanford",
      "Happy Camp",
      "Harbison Canyon",
      "Hawaiian Gardens",
      "Healdsburg",
      "Hesperia",
      "Hidden Hills",
      "Hidden Meadows",
      "Highgrove",
      "Hollister",
      "Holtville",
      "Home Gardens",
      "Hughson",
      "Huntington Park",
      "Idyllwild",
      "Idyllwild-Pine Cove",
      "Indian Wells",
      "Inverness",
      "Ione",
      "Irwindale",
      "Isla Vista",
      "Jackson",
      "Jamul",
      "Joshua Tree",
      "Julian",
      "Kentfield",
      "Kenwood",
      "Kernville",
      "King City",
      "Kings Beach",
      "Kingsburg",
      "La Cañada Flintridge",
      "La Crescenta-Montrose",
      "Ladera Heights",
      "Ladera Ranch",
      "Lafayette",
      "Laguna",
      "Laguna Hills",
      "Laguna Niguel",
      "Laguna Woods",
      "La Jolla",
      "Lake Arrowhead",
      "Lake Elsinore",
      "Lake Isabella",
      "Lake Nacimiento",
      "Lakeport",
      "Lake San Marcos",
      "Lake Shasta",
      "Lakewood",
      "La Mesa",
      "La Mirada",
      "La Presa",
      "La Puente",
      "La Riviera",
      "Larkfield-Wikiup",
      "Larkspur",
      "Las Flores",
      "Las Lomas",
      "Lathrop",
      "Laton",
      "Lawndale",
      "Laytonville",
      "Lebec",
      "Lemon Grove",
      "Lennox",
      "Lincoln",
      "Lincoln Village",
      "Linda",
      "Littlerock",
      "Live Oak",
      "Loma Linda",
      "Lompoc",
      "London",
      "Lone Pine",
      "Loomis",
      "Los Alamitos",
      "Los Alamos",
      "Los Altos Hills",
      "Los Banos",
      "Los Gatos",
      "Los Olivos",
      "Los Osos",
      "Los Serranos",
      "Loyola",
      "Lucerne",
      "Lucerne Valley",
      "Magalia",
      "Malibu Beach",
      "Mammoth Lakes",
      "March Air Force Base",
      "Maricopa",
      "Marina del Rey",
      "Marysville",
      "Maxwell",
      "Mayflower Village",
      "Maywood",
      "McFarland",
      "McKinleyville",
      "Meadow Vista",
      "Mendota",
      "Menifee",
      "Menlo Park",
      "Mentone",
      "Midway City",
      "Millbrae",
      "Milpitas",
      "Mira Loma",
      "Mission Canyon",
      "Mojave",
      "Monrovia",
      "Montclair",
      "Montecito",
      "Monterey Park",
      "Monte Rio",
      "Moorpark",
      "Morada",
      "Moraga",
      "Moreno Valley",
      "Morgan Hill",
      "Moss Beach",
      "Mountain Ranch",
      "Mountain View Acres",
      "Mount Shasta",
      "Murrieta Hot Springs",
      "Muscoy",
      "National City",
      "Needles",
      "Nevada City",
      "Newark",
      "Nice",
      "Niland",
      "Nipomo",
      "Norco",
      "North El Monte",
      "North Fair Oaks",
      "North Glendale",
      "North Highlands",
      "North Hollywood",
      "North Richmond",
      "Northridge",
      "North Tustin",
      "Nuevo",
      "Oak Hills",
      "Oakhurst",
      "Oak Park",
      "Oak View",
      "Occidental",
      "Ojai",
      "Ontario",
      "Opal Cliffs",
      "Orangevale",
      "Orcutt",
      "Orinda",
      "Orland",
      "Oroville",
      "Oroville East",
      "Pacheco",
      "Pacifica",
      "Pacific Grove",
      "Palmdale",
      "Palo Cedro",
      "Palos Verdes Estates",
      "Parkway",
      "Parlier",
      "Pasatiempo",
      "Patterson",
      "Pedley",
      "Penngrove",
      "Perris",
      "Phelan",
      "Pico Rivera",
      "Piedmont",
      "Pine Mountain Club",
      "Pine Valley",
      "Pinole",
      "Pismo Beach",
      "Placentia",
      "Placerville",
      "Pleasant Hill",
      "Pleasanton",
      "Porterville",
      "Port Hueneme",
      "Portola",
      "Portola Hills",
      "Portola Valley",
      "Poway",
      "Rainbow",
      "Rancho Cordova",
      "Rancho Mirage",
      "Rancho Murieta",
      "Rancho Palos Verdes",
      "Rancho Santa Fe",
      "Rancho Santa Margarita",
      "Red Bluff",
      "Redway",
      "Redwood Shores",
      "Rescue",
      "Rialto",
      "Ridgecrest",
      "Ridgemark",
      "Rio Dell",
      "Rio Linda",
      "Ripon",
      "Riverbank",
      "Rodeo",
      "Rohnert Park",
      "Rolling Hills",
      "Rolling Hills Estates",
      "Romoland",
      "Rosamond",
      "Roseland",
      "Rosemead",
      "Rosemont",
      "Rossmoor",
      "Rowland Heights",
      "Rubidoux",
      "Running Springs",
      "Saint Helena",
      "Salida",
      "Salton City",
      "San Anselmo",
      "San Antonio Heights",
      "San Bruno",
      "San Carlos",
      "San Fernando",
      "San Jacinto",
      "San Juan Capistrano",
      "San Leandro",
      "San Lorenzo",
      "San Marcos",
      "San Marino",
      "San Martin",
      "San Mateo",
      "San Pasqual",
      "San Pedro",
      "San Rafael",
      "San Ramon",
      "Santa Clara",
      "Santa Cruz",
      "Santa Fe Springs",
      "Santa Maria",
      "Santa Rosa",
      "Santa Susana",
      "Santa Venetia",
      "Santa Ynez",
      "Saranap",
      "Sausalito",
      "Scotts Valley",
      "Seal Beach",
      "Sea Ranch",
      "Sebastopol",
      "Sedco Hills",
      "Seven Trees",
      "Shafter",
      "Shasta Lake",
      "Shingle Springs",
      "Signal Hill",
      "Silver Lake",
      "Simi Valley",
      "Solana Beach",
      "Solvang",
      "Soquel",
      "South El Monte",
      "South Gate",
      "South Pasadena",
      "South San Francisco",
      "South San Gabriel",
      "South San Jose Hills",
      "South Whittier",
      "Spring Valley",
      "Squaw Valley",
      "Stallion Springs",
      "Stanton",
      "Stevenson Ranch",
      "Strawberry",
      "Suisun",
      "Summerland",
      "Sun City",
      "Sunland",
      "Sunnyside-Tahoe City",
      "Susanville",
      "Taft",
      "Tahoe Vista",
      "Tahoma",
      "Talmage",
      "Tamalpais-Homestead Valley",
      "Tamalpais Valley",
      "Tarpey Village",
      "Tehachapi",
      "Temple City",
      "Templeton",
      "Thermal",
      "Thousand Palms",
      "Three Rivers",
      "Topanga",
      "Truckee",
      "Tujunga",
      "Tustin",
      "Twain Harte",
      "Twentynine Palms",
      "Twin Lakes",
      "Ukiah",
      "Union City",
      "Universal City",
      "Valencia",
      "Valinda",
      "Valley Center",
      "Valley Springs",
      "Val Verde",
      "Vandenberg Air Force Base",
      "Venice",
      "View Park-Windsor Hills",
      "Villa Park",
      "Vista Santa Rosa",
      "Waldon",
      "Walnut Grove",
      "Wasco",
      "Watsonville",
      "Weaverville",
      "West Athens",
      "West Bishop",
      "West Carson",
      "Westhaven-Moonstone",
      "West Hills",
      "Westlake Village",
      "Westminster",
      "Westmont",
      "West Puente Valley",
      "West Rancho Dominguez",
      "West Sacramento",
      "West Whittier-Los Nietos",
      "Westwood, Los Angeles",
      "Whittier",
      "Wildomar",
      "Williams",
      "Willits",
      "Willowbrook",
      "Willows",
      "Windsor",
      "Winters",
      "Woodacre",
      "Woodcrest",
      "Woodland Hills",
      "Wrightwood",
      "Yorba Linda",
      "Yosemite Lakes",
      "Yosemite Valley",
      "Yountville",
      "Yuba City",
      "Yucaipa",
      "Yucca Valley",
      "Agoura Hills",
      "Alameda",
      "Alhambra",
      "Alpine",
      "Anaheim",
      "Antelope",
      "Antioch",
      "Arcadia",
      "Artesia",
      "Arvin",
      "Azusa",
      "Bakersfield",
      "Baldwin Park",
      "Barstow",
      "Bell",
      "Bellflower",
      "Belmont",
      "Berkeley",
      "Beverly Hills",
      "Blythe",
      "Bonita",
      "Brea",
      "Burbank",
      "Burlingame",
      "Calabasas",
      "Camarillo",
      "Campbell",
      "Carlsbad",
      "Carson",
      "Ceres",
      "Chatsworth",
      "Chico",
      "Chino",
      "Chino Hills",
      "Claremont",
      "Clovis",
      "Coachella",
      "Colton",
      "Compton",
      "Concord",
      "Corcoran",
      "Corona",
      "Coronado",
      "Costa Mesa",
      "Covina",
      "Culver City",
      "Cupertino",
      "Cypress",
      "Daly City",
      "Davis",
      "Delano",
      "Dixon",
      "Downey",
      "Duarte",
      "El Dorado Hills",
      "El Segundo",
      "Encinitas",
      "Escondido",
      "Eureka",
      "Fairfield",
      "Fontana",
      "Fortuna",
      "Fremont",
      "Fresno",
      "Fullerton",
      "Galt",
      "Gilroy",
      "Grass Valley",
      "Half Moon Bay",
      "Hawthorne",
      "Hayward",
      "Hemet",
      "Hercules",
      "Hermosa Beach",
      "Highland",
      "Hollywood",
      "Huntington Beach",
      "Imperial",
      "Imperial Beach",
      "Indio",
      "Inglewood",
      "Irvine",
      "Laguna Beach",
      "La Habra",
      "Lake Forest",
      "Lakeside",
      "Lamont",
      "Lancaster",
      "La Palma",
      "La Quinta",
      "La Verne",
      "Lemoore",
      "Lindsay",
      "Livermore",
      "Lodi",
      "Lomita",
      "Long Beach",
      "Los Altos",
      "Los Angeles",
      "Lynwood",
      "Madera",
      "Malibu",
      "Manhattan Beach",
      "Manteca",
      "Marina",
      "Martinez",
      "Merced",
      "Mill Valley",
      "Mission Viejo",
      "Modesto",
      "Montebello",
      "Monterey",
      "Morro Bay",
      "Mountain View",
      "Murrieta",
      "Napa",
      "Newman",
      "Newport Beach",
      "North Hills",
      "Norwalk",
      "Novato",
      "Oakdale",
      "Oakland",
      "Oakley",
      "Oceanside",
      "Orange",
      "Oxnard",
      "Palm Desert",
      "Palm Springs",
      "Palo Alto",
      "Paradise",
      "Paramount",
      "Pasadena",
      "Paso Robles",
      "Petaluma",
      "Pittsburg",
      "Pomona",
      "Ramona",
      "Rancho Cucamonga",
      "Redding",
      "Redlands",
      "Redondo Beach",
      "Redwood City",
      "Reedley",
      "Richmond",
      "Riverside",
      "Rocklin",
      "Roseville",
      "Sacramento",
      "Salinas",
      "San Bernardino",
      "San Clemente",
      "San Diego",
      "San Dimas",
      "San Francisco",
      "San Gabriel",
      "Sanger",
      "San Joaquin Hills",
      "San Jose",
      "San Luis Obispo",
      "San Pablo",
      "Santa Ana",
      "Santa Barbara",
      "Santa Clarita",
      "Santa Monica",
      "Santa Paula",
      "Santee",
      "Saratoga",
      "Seaside",
      "Selma",
      "Sherman Oaks",
      "Sierra Madre",
      "Sonoma",
      "South Lake Tahoe",
      "Stanford",
      "Stockton",
      "Studio City",
      "Sunnyvale",
      "Temecula",
      "Thousand Oaks",
      "Torrance",
      "Tracy",
      "Tulare",
      "Turlock",
      "Upland",
      "Vacaville",
      "Vallejo",
      "Van Nuys",
      "Ventura",
      "Victorville",
      "Visalia",
      "Vista",
      "Walnut",
      "Walnut Creek",
      "West Covina",
      "West Hollywood",
      "Winton",
      "Woodland"
    ],
    Colorado: [
      "Air Force Academy",
      "Alamosa",
      "Applewood",
      "Aspen",
      "Avon",
      "Basalt",
      "Berkley",
      "Breckenridge",
      "Brighton",
      "Broomfield",
      "Cañon City",
      "Carbondale",
      "Cascade-Chipita Park",
      "Castle Rock",
      "Castlewood",
      "Cedaredge",
      "Centennial",
      "Central City",
      "Cimarron Hills",
      "Colorado City",
      "Commerce City",
      "Cortez",
      "Craig",
      "Crested Butte",
      "Dove Valley",
      "Durango",
      "Eagle",
      "Edgewater",
      "Erie",
      "Estes Park",
      "Evans",
      "Evergreen",
      "Fairplay",
      "Firestone",
      "Fort Morgan",
      "Fraser",
      "Frisco",
      "Fruita",
      "Georgetown",
      "Glendale",
      "Glenwood Springs",
      "Granby",
      "Grand Junction",
      "Greeley",
      "Gunbarrel",
      "Gypsum",
      "Highlands Ranch",
      "Hot Sulphur Springs",
      "Idaho Springs",
      "Indian Hills",
      "Johnstown",
      "Ken Caryl",
      "Keystone",
      "Kittredge",
      "Lafayette",
      "Lake City",
      "Laporte",
      "Leadville North",
      "Littleton",
      "Loma",
      "Lone Tree",
      "Longmont",
      "Louisville",
      "Loveland",
      "Lyons",
      "Manitou Springs",
      "Merino",
      "Minturn",
      "Montrose",
      "Monument",
      "Mountain Village",
      "Nederland",
      "Northglenn",
      "Ouray",
      "Pagosa Springs",
      "Paonia",
      "Pueblo West",
      "Rifle",
      "Salida",
      "Sheridan",
      "Silverthorne",
      "Silverton",
      "Snowmass Village",
      "Southglenn",
      "Stratmoor",
      "Telluride",
      "The Pinery",
      "Todd Creek",
      "Twin Lakes",
      "Vail",
      "Walden",
      "Westcliffe",
      "West Pleasant View",
      "Wheat Ridge",
      "Windsor",
      "Arvada",
      "Aurora",
      "Boulder",
      "Colorado Springs",
      "Cripple Creek",
      "Denver",
      "Edwards",
      "Englewood",
      "Fort Collins",
      "Fountain",
      "Golden",
      "Lakewood",
      "Parker",
      "Pueblo",
      "Steamboat Springs",
      "Westminster"
    ],
    Connecticut: [
      "Ansonia",
      "Bethlehem Village",
      "Blue Hills",
      "Branford Center",
      "Bristol",
      "Canaan",
      "Central Waterford",
      "City of Milford (balance)",
      "Cos Cob",
      "Cromwell",
      "Darien",
      "East Haddam",
      "East Hartford",
      "East Haven",
      "Fairfield",
      "Farmington",
      "Glastonbury Center",
      "Guilford",
      "Guilford Center",
      "Kent",
      "Madison",
      "Manchester",
      "Meriden",
      "Middletown",
      "Mystic",
      "Naugatuck",
      "New Britain",
      "New Canaan",
      "New Fairfield",
      "New London",
      "New Milford",
      "New Preston",
      "North Branford",
      "North Haven",
      "North Stamford",
      "Norwalk",
      "Norwich",
      "Oakville",
      "Old Saybrook Center",
      "Orange",
      "Oxford",
      "Pawcatuck",
      "Plainfield",
      "Plainville",
      "Ridgefield",
      "Seymour",
      "Simsbury Center",
      "Southport",
      "South Windsor",
      "Stafford",
      "Stafford Springs",
      "Storrs",
      "Tolland",
      "Trumbull",
      "Waterford",
      "Weatogue",
      "West Haven",
      "Wethersfield",
      "Willimantic",
      "Windsor",
      "Windsor Locks",
      "Wolcott",
      "Woodbridge",
      "Woodbury",
      "Branford",
      "Bridgeport",
      "Cheshire",
      "Danbury",
      "Ellington",
      "Enfield",
      "Glastonbury",
      "Greenwich",
      "Hamden",
      "Hartford",
      "Milford",
      "New Haven",
      "Newington",
      "Old Saybrook",
      "Shelton",
      "Southbury",
      "Stamford",
      "Stratford",
      "Torrington",
      "Wallingford",
      "Waterbury",
      "West Hartford",
      "Westport",
      "Wilton",
      "Windham"
    ],
    Delaware: [
      "Cheswold",
      "Claymont",
      "Delaware City",
      "Dover",
      "Greenville",
      "Hockessin",
      "Lewes",
      "Long Neck",
      "Middletown",
      "Milford",
      "Newark",
      "New Castle",
      "Rehoboth Beach",
      "Riverview",
      "Smyrna",
      "Wilmington Manor",
      "Bear",
      "Bethany Beach",
      "Wilmington"
    ],
    Florida: [
      "Alafaya",
      "Allapattah",
      "Andover",
      "Anna Maria",
      "Apalachicola",
      "Asbury Lake",
      "Atlantic Beach",
      "Auburndale",
      "Aventura",
      "Avon Park",
      "Azalea Park",
      "Baldwin",
      "Bal Harbour",
      "Bartow",
      "Bay Harbor Islands",
      "Bay Hill",
      "Bay Pines",
      "Bayshore Gardens",
      "Bee Ridge",
      "Belleair",
      "Big Pine Key",
      "Biscayne Park",
      "Bithlo",
      "Boca Del Mar",
      "Boca Pointe",
      "Bokeelia",
      "Bonita Springs",
      "Broadview Park",
      "Broward Estates",
      "Buenaventura Lakes",
      "Butler Beach",
      "Campbell",
      "Cantonment",
      "Cape Canaveral",
      "Cape Coral",
      "Carol City",
      "Carrabelle",
      "Carrollwood",
      "Carrollwood Village",
      "Casselberry",
      "Celebration",
      "Cheval",
      "Citrus Ridge",
      "Cleveland",
      "Coconut Creek",
      "Coconut Grove",
      "Conway",
      "Cooper City",
      "Coral Gables",
      "Coral Springs",
      "Coral Terrace",
      "Country Club",
      "Country Walk",
      "Crawfordville",
      "Crystal Lake",
      "Crystal River",
      "Cudjoe Key",
      "Cutler",
      "Cypress Gardens",
      "Cypress Quarters",
      "Dade City",
      "Dade City North",
      "Dania Beach",
      "Davenport",
      "Daytona Beach Shores",
      "DeBary",
      "Deltona",
      "Doctor Phillips",
      "Dunedin",
      "Dunnellon",
      "East Lake",
      "East Lake-Orient Park",
      "East Perrine",
      "Eastpoint",
      "Eatonville",
      "Edgewood",
      "Egypt Lake-Leto",
      "El Portal",
      "Englewood",
      "Ensley",
      "Eustis",
      "Fairview Shores",
      "Fernandina Beach",
      "Fern Park",
      "Fish Hawk",
      "Flagami",
      "Flagler Beach",
      "Floral City",
      "Fort Myers",
      "Fort Myers Beach",
      "Fort Pierce South",
      "Fort Walton Beach",
      "Fountainebleau",
      "Four Corners",
      "Fruit Cove",
      "Fruitville",
      "Gandy",
      "Gateway",
      "Gibsonia",
      "Gibsonton",
      "Gladeview",
      "Glenvar Heights",
      "Golden Glades",
      "Goldenrod",
      "Goulds",
      "Grant-Valkaria",
      "Greater Northdale",
      "Greenacres City",
      "Groveland",
      "Gulf Breeze",
      "Gulfport",
      "Hallandale Beach",
      "Harlem",
      "Heathrow",
      "Hernando Beach",
      "Hialeah Gardens",
      "Highland Beach",
      "High Springs",
      "Hillsboro Beach",
      "Hobe Sound",
      "Holden Heights",
      "Holly Hill",
      "Homosassa",
      "Homosassa Springs",
      "Hudson",
      "Hunters Creek",
      "Immokalee",
      "Indialantic",
      "Indian Harbour Beach",
      "Indian Rocks Beach",
      "Interlachen",
      "Islamorada",
      "Ives Estates",
      "Jensen Beach",
      "June Park",
      "Juno Beach",
      "Kendale Lakes",
      "Kendall",
      "Kendall Green",
      "Kendall West",
      "Kenneth City",
      "Kensington Park",
      "Key Biscayne",
      "Key Largo",
      "Keystone",
      "Kings Point",
      "Lady Lake",
      "Laguna Beach",
      "Lake Belvedere Estates",
      "Lake Clarke Shores",
      "Lakeland Highlands",
      "Lake Lucerne",
      "Lake Magdalene",
      "Lake Mary",
      "Lake Park",
      "Lake Sarasota",
      "Lakeside",
      "Lake Wales",
      "Lake Worth",
      "Land O' Lakes",
      "Lauderdale-by-the-Sea",
      "Lauderdale Lakes",
      "Lauderhill",
      "Laurel",
      "Lealman",
      "Lee",
      "Leesburg",
      "Lehigh Acres",
      "Leisure City",
      "Lighthouse Point",
      "Limestone Creek",
      "Lochmoor Waterway Estates",
      "Longboat Key",
      "Loughman",
      "Lower Grand Lagoon",
      "Loxahatchee Groves",
      "Lutz",
      "Lynn Haven",
      "Madeira Beach",
      "Maitland",
      "Mangonia Park",
      "Marathon",
      "Marco",
      "Marco Island",
      "Margate",
      "Marianna",
      "Mascotte",
      "Meadow Woods",
      "Melbourne",
      "Melbourne Beach",
      "Melrose Park",
      "Merritt Island",
      "Miami Gardens",
      "Miami Lakes",
      "Miami Shores",
      "Miami Springs",
      "Middleburg",
      "Midway",
      "Milton",
      "Minneola",
      "Miramar",
      "Miramar Beach",
      "Montverde",
      "Myrtle Grove",
      "Naples Park",
      "Navarre",
      "Neptune Beach",
      "New Port Richey",
      "New Smyrna Beach",
      "Niceville",
      "Norland",
      "North Bay Village",
      "North Fort Myers",
      "North Miami",
      "North Miami Beach",
      "North Palm Beach",
      "North River Shores",
      "North Sarasota",
      "North Weeki Wachee",
      "Oak Hill",
      "Oakland",
      "Oakland Park",
      "Ocean City",
      "Ocean Ridge",
      "Ocoee",
      "Ojus",
      "Oldsmar",
      "Olympia Heights",
      "Opa-locka",
      "Orange City",
      "Orange Park",
      "Orangetree",
      "Orlovista",
      "Oviedo",
      "Palatka",
      "Palm Bay",
      "Palm Beach",
      "Palm Beach Gardens",
      "Palm Beach Shores",
      "Palm City",
      "Palm Coast",
      "Palmetto Bay",
      "Palmetto Estates",
      "Palm Harbor",
      "Palm River-Clair Mel",
      "Palm Springs North",
      "Palm Valley",
      "Panama City",
      "Parkland",
      "Pebble Creek",
      "Pembroke Park",
      "Pembroke Pines",
      "Pine Castle",
      "Pinecrest",
      "Pine Hills",
      "Pine Island Center",
      "Pine Island Ridge",
      "Pine Manor",
      "Pine Ridge",
      "Pinewood",
      "Plantation",
      "Plantation Mobile Home Park",
      "Poinciana",
      "Polk City",
      "Pompano Beach Highlands",
      "Ponce Inlet",
      "Port Charlotte",
      "Port Orange",
      "Port Saint Joe",
      "Port Saint Lucie",
      "Port Salerno",
      "Progress Village",
      "Punta Gorda",
      "Redington Shores",
      "Richmond West",
      "River Park",
      "Rock Island",
      "Rockledge",
      "Rotonda",
      "Royal Palm Beach",
      "Ruskin",
      "Safety Harbor",
      "Saint Augustine",
      "Saint Augustine Beach",
      "Saint Augustine South",
      "Saint Cloud",
      "Saint James City",
      "Saint Leo",
      "Saint Pete Beach",
      "Samoset",
      "San Carlos Park",
      "Sarasota Springs",
      "Satellite Beach",
      "Sawgrass",
      "Schall Circle",
      "Seaside",
      "Sebring",
      "Seminole",
      "Seminole Manor",
      "Sewall's Point",
      "Siesta Key",
      "Silver Lake",
      "Sky Lake",
      "South Bay",
      "South Brooksville",
      "South Daytona",
      "South Highpoint",
      "South Miami",
      "South Miami Heights",
      "South Pasadena",
      "South Patrick Shores",
      "South Sarasota",
      "Southwest Ranches",
      "Springfield",
      "Stock Island",
      "Sugarmill Woods",
      "Sun City Center",
      "Sunny Isles Beach",
      "Sunrise",
      "Sunset",
      "Sunshine Ranches",
      "Surfside",
      "Sweetwater",
      "Taft",
      "Tamarac",
      "Tamiami",
      "Tangelo Park",
      "Tarpon Springs",
      "Tedder",
      "Temple Terrace",
      "The Crossings",
      "The Hammocks",
      "The Meadows",
      "Thonotosassa",
      "Three Lakes",
      "Tice",
      "Timber Pines",
      "Titusville",
      "Town 'n' Country",
      "Trinity",
      "Twin Lakes",
      "Union Park",
      "University",
      "University Park",
      "Valparaiso",
      "Valrico",
      "Venice",
      "Venice Gardens",
      "Vero Beach",
      "Vero Beach South",
      "Villages of Oriole",
      "Villano Beach",
      "Villas",
      "Vineyards",
      "Virginia Gardens",
      "Wahneta",
      "Warrington",
      "Wekiwa Springs",
      "Wellington",
      "Wesley Chapel",
      "West and East Lealman",
      "Westchester",
      "West Little River",
      "West Melbourne",
      "West Miami",
      "Weston",
      "West Palm Beach",
      "West Park",
      "Whitfield",
      "Williamsburg",
      "Williston",
      "Wilton Manors",
      "Windermere",
      "Winston",
      "Winter Garden",
      "Winter Haven",
      "Winter Park",
      "Winter Springs",
      "Woodlawn Beach",
      "Wright",
      "Yulee",
      "Zephyrhills",
      "Altamonte Springs",
      "Apollo Beach",
      "Apopka",
      "Belle Glade",
      "Boca Raton",
      "Boynton Beach",
      "Bradenton",
      "Brandon",
      "Clearwater",
      "Clermont",
      "Cocoa",
      "Cocoa Beach",
      "Crestview",
      "Davie",
      "Daytona Beach",
      "Deerfield Beach",
      "DeLand",
      "Delray Beach",
      "Destin",
      "Doral",
      "Edgewater",
      "Estero",
      "Fort Lauderdale",
      "Gainesville",
      "Haines City",
      "Hallandale",
      "Hialeah",
      "Hollywood",
      "Homestead",
      "Jacksonville",
      "Jacksonville Beach",
      "Jupiter",
      "Key West",
      "Kissimmee",
      "Lake City",
      "Lakeland",
      "Largo",
      "Longwood",
      "Mango",
      "Miami",
      "Miami Beach",
      "Naples",
      "North Port",
      "Ocala",
      "Orlando",
      "Ormond Beach",
      "Palmetto",
      "Panama City Beach",
      "Pensacola",
      "Pinellas Park",
      "Plant City",
      "Pompano Beach",
      "Ponte Vedra Beach",
      "Riverview",
      "Saint Petersburg",
      "Sanford",
      "Sarasota",
      "Sebastian",
      "Spring Hill",
      "St. Petersburg",
      "Stuart",
      "Tallahassee",
      "Tampa",
      "Tavares"
    ],
    Georgia: [
      "Acworth",
      "Albany",
      "Athens",
      "Avondale Estates",
      "Baxley",
      "Berkeley Lake",
      "Blairsville",
      "Blue Ridge",
      "Braselton",
      "Buford",
      "Carrollton",
      "Chamblee",
      "Chatsworth",
      "Clarkston",
      "Clayton",
      "Cleveland",
      "College Park",
      "Columbus",
      "Commerce",
      "Cordele",
      "Covington",
      "Dacula",
      "Daisy",
      "Dallas",
      "Dawsonville",
      "Decatur",
      "Doraville",
      "Druid Hills",
      "Duluth",
      "Dunwoody",
      "East Newnan",
      "East Point",
      "Emerson",
      "Fairburn",
      "Fayetteville",
      "Gray",
      "Greensboro",
      "Grovetown",
      "Hapeville",
      "Harlem",
      "Hazlehurst",
      "Hiawassee",
      "Hiram",
      "Johns Creek",
      "Jonesboro",
      "Kings Bay Base",
      "Kingsland",
      "Lakeview",
      "Lilburn",
      "Lithia Springs",
      "Lithonia",
      "Loganville",
      "Mableton",
      "Martinez",
      "Milton",
      "Monroe",
      "Montgomery",
      "Moultrie",
      "Nelson",
      "Newnan",
      "Norcross",
      "North Atlanta",
      "Oakwood",
      "Panthersville",
      "Pearson",
      "Pine Mountain",
      "Pooler",
      "Powder Springs",
      "Ray City",
      "Rebecca",
      "Redan",
      "Remerton",
      "Rincon",
      "Ringgold",
      "Rome",
      "Sandy Springs",
      "Scottdale",
      "Shannon",
      "St. Marys",
      "Stockbridge",
      "Stone Mountain",
      "Suwanee",
      "Tybee Island",
      "Union City",
      "Villa Rica",
      "Vinings",
      "Warner Robins",
      "Watkinsville",
      "Waycross",
      "Winder",
      "Woodstock",
      "Alpharetta",
      "Americus",
      "Atlanta",
      "Augusta",
      "Bainbridge",
      "Calhoun",
      "Cartersville",
      "Conyers",
      "Dalton",
      "Douglasville",
      "Evans",
      "Forest Park",
      "Griffin",
      "Hinesville",
      "Jefferson",
      "Kennesaw",
      "La Grange",
      "Lawrenceville",
      "Macon",
      "Marietta",
      "McDonough",
      "Milledgeville",
      "Peachtree City",
      "Perry",
      "Riverdale",
      "Roswell",
      "Saint Simons Island",
      "Savannah",
      "Smyrna",
      "Snellville",
      "Statesboro",
      "Tifton",
      "Tucker",
      "Valdosta",
      "Vidalia"
    ],
    Hawaii: [
      "Anahola",
      "Haiku-Pauwela",
      "Halawa",
      "Halawa Heights",
      "Hale'iwa",
      "Hana",
      "He'eia",
      "Ka'anapali",
      "Kaanapali Landing",
      "Kahaluu-Keauhou",
      "Kahuku",
      "Kalaoa",
      "Kapaa",
      "Kapa'a",
      "Kapaau",
      "Kapolei",
      "Kekaha",
      "Keokea",
      "Kihei",
      "Kilauea",
      "Koloa",
      "Kualapu'u",
      "La'ie",
      "Lana'i City",
      "Lawa'i",
      "Leilani Estates",
      "Makakilo City",
      "Makawao",
      "Maunawili",
      "Mokuleia",
      "Nanawale Estates",
      "Napili-Honokowai",
      "Pearl City",
      "Princeville",
      "Pukalani",
      "Pupukea",
      "Schofield Barracks",
      "Volcano",
      "Waialua",
      "Waianae",
      "Waihee-Waiehu",
      "Waikapu",
      "Waikoloa",
      "Waikoloa Village",
      "Wailua",
      "Wailua Homesteads",
      "Waimanalo",
      "Waimanalo Beach",
      "Waipahu",
      "Waipi'o Acres",
      "Hilo",
      "Honolulu",
      "Kahului",
      "Kailua",
      "Kailua-Kona",
      "Kaneohe",
      "Lahaina",
      "Wahiawa",
      "Wailuku"
    ],
    Idaho: [
      "Burley",
      "Cascade",
      "Challis",
      "Coeur d'Alene",
      "Council",
      "Driggs",
      "Garden City",
      "Grangeville",
      "Hailey",
      "Idaho City",
      "Ketchum",
      "Lewiston",
      "McCall",
      "Moscow",
      "Mountain Home",
      "Nampa",
      "Paris",
      "Payette",
      "Plummer",
      "Ponderay",
      "Post Falls",
      "Preston",
      "Salmon",
      "Sandpoint",
      "Sugar City",
      "Sun Valley",
      "Twin Falls",
      "Victor",
      "Blackfoot",
      "Boise",
      "Caldwell",
      "Eagle",
      "Hayden",
      "Idaho Falls",
      "Jerome",
      "Kuna",
      "Meridian",
      "Pocatello",
      "Rexburg"
    ],
    Illinois: [
      "Algonquin",
      "Alsip",
      "Antioch",
      "Arlington Heights",
      "Aurora",
      "Barrington",
      "Belvidere",
      "Bensenville",
      "Berwyn",
      "Bloomingdale",
      "Bourbonnais",
      "Bridgeview",
      "Broadview",
      "Brookfield",
      "Buffalo Grove",
      "Burbank",
      "Calumet Park",
      "Canton",
      "Carbondale",
      "Carlyle",
      "Carol Stream",
      "Carpentersville",
      "Centralia",
      "Channahon",
      "Charleston",
      "Chatham",
      "Chicago Heights",
      "Chicago Ridge",
      "Clarendon Hills",
      "Country Club Hills",
      "Crest Hill",
      "Crestwood",
      "Crete",
      "Crystal Lawns",
      "Dakota",
      "Danville",
      "Darien",
      "Des Plaines",
      "Dixon",
      "Dolton",
      "Downers Grove",
      "East Moline",
      "East Peoria",
      "Edwardsville",
      "Effingham",
      "Elk Grove Village",
      "Elmhurst",
      "Elmwood Park",
      "Evergreen Park",
      "Fairview Heights",
      "Forest Park",
      "Fox Lake",
      "Franklin Park",
      "Freeport",
      "Galesburg",
      "Geneva",
      "Glen Carbon",
      "Glen Ellyn",
      "Glenview",
      "Glenwood",
      "Godfrey",
      "Golconda",
      "Granite City",
      "Gurnee",
      "Hanover Park",
      "Harvard",
      "Hawthorn Woods",
      "Hazel Crest",
      "Herrin",
      "Hickory Hills",
      "Highland Park",
      "Hoffman Estates",
      "Holiday Shores",
      "Homer Glen",
      "Hometown",
      "Homewood",
      "Jacksonville",
      "Joliet",
      "Junction",
      "Kankakee",
      "Kewanee",
      "Knollwood",
      "La Grange",
      "Lake Forest",
      "Lake Zurich",
      "Lemont",
      "Libertyville",
      "Lincoln",
      "Lincolnwood",
      "Lisle",
      "Lockport",
      "Loves Park",
      "Lynwood",
      "Lyons",
      "Machesney Park",
      "Marion",
      "Markham",
      "Maroa",
      "Mattoon",
      "Melrose Park",
      "Midlothian",
      "Minooka",
      "Mokena",
      "Moline",
      "Morton Grove",
      "Mount Prospect",
      "Mundelein",
      "Nauvoo",
      "New Lenox",
      "Niles",
      "Norridge",
      "North Aurora",
      "Northbrook",
      "North Chicago",
      "Northfield",
      "North Peoria",
      "Oak Forest",
      "Oak Lawn",
      "Oak Park",
      "Odin",
      "O'Fallon",
      "Ottawa",
      "Palatine",
      "Palos Heights",
      "Palos Hills",
      "Park Forest",
      "Park Ridge",
      "Pekin",
      "Peoria",
      "Plainfield",
      "Plano",
      "Pontiac",
      "Preston Heights",
      "Prospect Heights",
      "Rantoul",
      "Richton Park",
      "River Forest",
      "River Grove",
      "Rochelle",
      "Rock Island",
      "Rolling Meadows",
      "Romeoville",
      "Roselle",
      "Rosemont",
      "Round Lake",
      "Saint Charles",
      "Schaumburg",
      "Schiller Park",
      "South Elgin",
      "Southern View",
      "Sterling",
      "Stickney",
      "Sycamore",
      "Taylorville",
      "Tinley Park",
      "Vernon Hills",
      "Villa Park",
      "Walnut",
      "Warrenville",
      "Waterloo",
      "Wauconda",
      "Waukegan",
      "West Chicago",
      "Western Springs",
      "Westmont",
      "Wheeling",
      "Wilmette",
      "Winnetka",
      "Woodridge",
      "Wood River",
      "Woodstock",
      "Addison",
      "Alton",
      "Bartlett",
      "Batavia",
      "Belleville",
      "Bellwood",
      "Bloomington",
      "Blue Island",
      "Bolingbrook",
      "Bradley",
      "Calumet City",
      "Champaign",
      "Chicago",
      "Cicero",
      "Collinsville",
      "Crystal Lake",
      "Decatur",
      "Deerfield",
      "DeKalb",
      "Elgin",
      "Evanston",
      "Glencoe",
      "Glendale Heights",
      "Grayslake",
      "Harvey",
      "Hinsdale",
      "Huntley",
      "Justice",
      "Lombard",
      "Macomb",
      "Matteson",
      "McHenry",
      "Morris",
      "Morton",
      "Naperville",
      "Normal",
      "Orland Park",
      "Oswego",
      "Quincy",
      "Rockford",
      "Roscoe",
      "Shorewood",
      "Skokie",
      "Springfield",
      "Streamwood",
      "Urbana",
      "Westchester",
      "Wheaton",
      "Worth",
      "Yorkville",
      "Zion"
    ],
    Indiana: [
      "Auburn",
      "Avon",
      "Bedford",
      "Beech Grove",
      "Brazil",
      "Broad Ripple",
      "Brownsburg",
      "Cedar Lake",
      "Chesterton",
      "Clarksville",
      "Cloverdale",
      "Columbus",
      "Connersville",
      "Crawfordsville",
      "Culver",
      "Dyer",
      "East Chicago",
      "Elkhart",
      "English",
      "Francisco",
      "Frankfort",
      "Franklin",
      "Greencastle",
      "Hamilton",
      "Highland",
      "Hobart",
      "Huntington",
      "Jeffersonville",
      "Lafayette",
      "Lake Station",
      "Madison",
      "Marion",
      "Martinsville",
      "Merrillville",
      "Mishawaka",
      "Mooresville",
      "Morocco",
      "Muncie",
      "Newburgh",
      "New Castle",
      "New Haven",
      "Noblesville",
      "Peru",
      "Plainfield",
      "Plymouth",
      "Portage",
      "Richmond",
      "Seymour",
      "Shelbyville",
      "Speedway",
      "Syracuse",
      "Terre Haute",
      "Tipton",
      "Vincennes",
      "Warsaw",
      "Washington",
      "Westfield",
      "West Lafayette",
      "Zionsville",
      "Anderson",
      "Bloomington",
      "Carmel",
      "Crown Point",
      "Evansville",
      "Fishers",
      "Fort Wayne",
      "Gary",
      "Goshen",
      "Granger",
      "Greenfield",
      "Greenwood",
      "Griffith",
      "Hammond",
      "Indianapolis",
      "Jasper",
      "Kokomo",
      "Logansport",
      "Michigan City",
      "New Albany",
      "Schererville",
      "South Bend",
      "Valparaiso",
      "Wabash",
      "Yorktown"
    ],
    Iowa: [
      "Agency",
      "Altoona",
      "Ames",
      "Anamosa",
      "Atlantic",
      "Bettendorf",
      "Birmingham",
      "Boone",
      "Burlington",
      "Cedar Falls",
      "Cedar Rapids",
      "Clinton",
      "Coralville",
      "Des Moines",
      "Fort Dodge",
      "Fort Madison",
      "Grimes",
      "Indianola",
      "Johnston",
      "Keokuk",
      "Le Claire",
      "Luana",
      "Mason City",
      "Muscatine",
      "Nashua",
      "Newton",
      "North Liberty",
      "Norwalk",
      "Oskaloosa",
      "Ottumwa",
      "Pella",
      "Pleasant Hill",
      "Sioux City",
      "Storm Lake",
      "Toledo",
      "Waterloo",
      "Waverly",
      "West Des Moines",
      "Windsor Heights",
      "Ankeny",
      "Clive",
      "Council Bluffs",
      "Davenport",
      "Dubuque",
      "Iowa City",
      "Marion",
      "Marshalltown",
      "Spencer",
      "Urbandale",
      "Waukee"
    ],
    Kansas: [
      "Andover",
      "Arkansas City",
      "Atchison",
      "Bonner Springs",
      "Colony",
      "Derby",
      "Emporia",
      "Eureka",
      "Fort Riley North",
      "Gem",
      "Girard",
      "Great Bend",
      "Green",
      "Harper",
      "Hays",
      "Haysville",
      "Home",
      "Junction City",
      "Leavenworth",
      "Leawood",
      "Lenexa",
      "Leoti",
      "Lindsborg",
      "Long Island",
      "Lyons",
      "Maize",
      "Manhattan",
      "Merriam",
      "Mission Hills",
      "Newton",
      "Norton",
      "Olathe",
      "Ottawa",
      "Overland Park",
      "Paola",
      "Pittsburg",
      "Prairie Village",
      "Rock",
      "Salina",
      "Silver Lake",
      "Sterling",
      "Tonganoxie",
      "Westwood",
      "Winfield",
      "Dodge City",
      "Garden City",
      "Gardner",
      "Hutchinson",
      "Kansas City",
      "Lawrence",
      "Liberal",
      "McPherson",
      "Parsons",
      "Shawnee",
      "Topeka",
      "Wichita"
    ],
    Kentucky: [
      "Alexandria",
      "Audubon Park",
      "Bardstown",
      "Bellevue",
      "Berea",
      "Burlington",
      "Busy",
      "Calvin",
      "Campbellsville",
      "Clay City",
      "Danville",
      "Dawson Springs",
      "Dayton",
      "Elizabethtown",
      "Erlanger",
      "Florence",
      "Fort Campbell North",
      "Fort Knox",
      "Fort Thomas",
      "Georgetown",
      "Henderson",
      "Hopkinsville",
      "Lawrenceburg",
      "Liberty",
      "Lily",
      "Middlesboro",
      "Mount Washington",
      "Newport",
      "Nicholasville",
      "Okolona",
      "Paducah",
      "Paris",
      "Prospect",
      "Radcliff",
      "Richmond",
      "Shelbyville",
      "Shepherdsville",
      "Somerset",
      "Stanton",
      "Taylorsville",
      "Versailles",
      "Warsaw",
      "Williamstown",
      "Ashland",
      "Bowling Green",
      "Covington",
      "Frankfort",
      "Lexington",
      "Louisville",
      "Madisonville",
      "Mayfield",
      "Mount Vernon",
      "Murray",
      "Owensboro"
    ],
    Louisiana: [
      "Abbeville",
      "Alexandria",
      "Archibald",
      "Athens",
      "Ball",
      "Bastrop",
      "Bogalusa",
      "Broussard",
      "Cottonport",
      "Covington",
      "Crowley",
      "Denham Springs",
      "DeRidder",
      "Destrehan",
      "Donaldsonville",
      "Fort Polk South",
      "Gonzales",
      "Greenwood",
      "Gretna",
      "Hammond",
      "Harvey",
      "Houma",
      "Jefferson",
      "Jennings",
      "Kelly",
      "Kenner",
      "Lake Charles",
      "Luling",
      "Marrero",
      "Minden",
      "Morgan City",
      "New Iberia",
      "Old Jefferson",
      "Opelousas",
      "Pineville",
      "Ponchatoula",
      "Port Allen",
      "Port Sulphur",
      "Prairieville",
      "Raceland",
      "Red Chute",
      "Singer",
      "Sterlington",
      "Sunset",
      "Terrytown",
      "Thibodaux",
      "Village Saint George",
      "Westminster",
      "West Monroe",
      "White Castle",
      "Youngsville",
      "Baker",
      "Baton Rouge",
      "Bossier City",
      "Chalmette",
      "Eunice",
      "Lafayette",
      "Laplace",
      "Mandeville",
      "Metairie",
      "Monroe",
      "Natchitoches",
      "New Orleans",
      "Ruston",
      "Shreveport",
      "Slidell",
      "Sulphur",
      "Zachary"
    ],
    Maine: [
      "Auburn",
      "Augusta",
      "Bangor",
      "Bar Harbor",
      "Bethel",
      "Biddeford",
      "Boothbay Harbor",
      "Bridgton",
      "Bristol",
      "Brownville",
      "Brunswick",
      "Buxton",
      "Camden",
      "Cumberland Center",
      "Harpswell Center",
      "Kennebunkport",
      "Lewiston",
      "Limestone",
      "Machiasport",
      "Millinocket",
      "Northport",
      "North Windham",
      "Ogunquit",
      "Old Orchard Beach",
      "Oxford",
      "Phillips",
      "Portland",
      "Sanford",
      "Scarborough",
      "South Portland",
      "South Portland Gardens",
      "Trenton",
      "Waterboro",
      "Waterville",
      "West Paris",
      "Yarmouth",
      "York Beach",
      "Saco",
      "Westbrook"
    ],
    Maryland: [
      "Aberdeen",
      "Accokeek",
      "Arden on the Severn",
      "Bel Air",
      "Bel Air North",
      "Beltsville",
      "Brock Hall",
      "Brooklyn Park",
      "Cabin John",
      "California",
      "Cambridge",
      "Charlotte Hall",
      "Chillum",
      "Clarksburg",
      "Cockeysville",
      "Columbia",
      "Crofton",
      "Cumberland",
      "Damascus",
      "Easton",
      "Elkridge",
      "Elkton",
      "Ellicott City",
      "Forest Heights",
      "Friendly",
      "Glassmanor",
      "Glen Burnie",
      "Glenn Dale",
      "Greenbelt",
      "Hagerstown",
      "Hanover",
      "Huntingtown Town Center",
      "Hyattsville",
      "Jarrettsville",
      "Kingstown",
      "Kingsville",
      "Landover",
      "Lanham",
      "Lexington Park",
      "Lochearn",
      "Middle River",
      "Montgomery Village",
      "Mount Rainier",
      "North Bethesda",
      "North East",
      "Ocean City",
      "Odenton",
      "Oxon Hill",
      "Pasadena",
      "Perry Hall",
      "Pikesville",
      "Randallstown",
      "Reisterstown",
      "Rising Sun",
      "Riva",
      "Rockville",
      "Rossville",
      "Saint Charles",
      "Salisbury",
      "Severna Park",
      "Silver Spring",
      "South Bel Air",
      "Stevensville",
      "Suitland",
      "Sykesville",
      "Takoma Park",
      "Westminster",
      "Woodmore",
      "Annapolis",
      "Baltimore",
      "Bethesda",
      "Bowie",
      "Catonsville",
      "Clinton",
      "College Park",
      "Dundalk",
      "Edgewood",
      "Essex",
      "Frederick",
      "Gaithersburg",
      "Germantown",
      "Hunt Valley",
      "Laurel",
      "Olney",
      "Owings Mills",
      "Parkville",
      "Potomac",
      "Severn",
      "Towson",
      "Waldorf"
    ],
    Massachusetts: [
      "Abington",
      "Acton",
      "Acushnet",
      "Agawam",
      "Amherst",
      "Arlington",
      "Ashland",
      "Attleboro",
      "Baldwinville",
      "Barnstable",
      "Bedford",
      "Belmont",
      "Berkshire",
      "Beverly Cove",
      "Billerica",
      "Braintree",
      "Brimfield",
      "Burlington",
      "Cambridge",
      "Canton",
      "Carver",
      "Chelmsford",
      "Chelsea",
      "Cochituate",
      "Cohasset",
      "Concord",
      "Danvers",
      "Dudley",
      "East Bridgewater",
      "East Dennis",
      "Eastham",
      "East Longmeadow",
      "Easton",
      "East Sandwich",
      "Edgartown",
      "Everett",
      "Fairhaven",
      "Fall River",
      "Falmouth",
      "Foxborough",
      "Franklin",
      "Gardner",
      "Gloucester",
      "Grafton",
      "Hamilton",
      "Harvard",
      "Hatfield",
      "Haverhill",
      "Holliston",
      "Hubbardston",
      "Hudson",
      "Hull",
      "Ipswich",
      "Jamaica Plain",
      "Lanesborough",
      "Lawrence",
      "Leicester",
      "Lenox",
      "Leominster",
      "Lexington",
      "Littleton Common",
      "Longmeadow",
      "Ludlow",
      "Lynnfield",
      "Malden",
      "Mansfield",
      "Marblehead",
      "Marion",
      "Marshfield",
      "Mashpee",
      "Maynard",
      "Medford",
      "Methuen",
      "Middleboro",
      "Milford",
      "Millbury",
      "Millis",
      "Milton",
      "Nantucket",
      "Needham",
      "New Bedford",
      "Newburyport",
      "New Marlborough",
      "Norfolk",
      "North Adams",
      "Northampton",
      "North Andover",
      "North Brookfield",
      "North Eastham",
      "North Reading",
      "Norwell",
      "Oak Bluffs",
      "Oakham",
      "Otis",
      "Pittsfield",
      "Provincetown",
      "Raynham",
      "Reading",
      "Rehoboth",
      "Revere",
      "Salem",
      "Salisbury",
      "Seekonk",
      "Shrewsbury",
      "Somerset",
      "South Boston",
      "Southbridge",
      "South Hadley",
      "Southwick",
      "South Yarmouth",
      "Springfield",
      "Stoughton",
      "Stow",
      "Sudbury",
      "Swampscott",
      "Swansea",
      "Taunton",
      "Tewksbury",
      "Truro",
      "Tyngsboro",
      "Uxbridge",
      "Vineyard Haven",
      "Wakefield",
      "Wayland",
      "Wellfleet",
      "Westford",
      "West Springfield",
      "West Tisbury",
      "Westwood",
      "Weymouth",
      "Wilmington",
      "Woburn",
      "Wrentham",
      "Yarmouth",
      "Amesbury",
      "Beverly",
      "Boston",
      "Brockton",
      "Brookline",
      "Charlton",
      "Chicopee",
      "Dedham",
      "Dracut",
      "Easthampton",
      "Fitchburg",
      "Framingham",
      "Holden",
      "Holyoke",
      "Hyannis",
      "Lowell",
      "Lynn",
      "Medway",
      "Melrose",
      "Natick",
      "Newton",
      "Northbridge",
      "Norton",
      "Norwood",
      "Palmer",
      "Peabody",
      "Quincy",
      "Randolph",
      "Rockland",
      "Saugus",
      "Somerville",
      "Stoneham",
      "Waltham",
      "Watertown",
      "Webster",
      "Wellesley",
      "Westfield",
      "Weston",
      "Whitman",
      "Winthrop",
      "Worcester"
    ],
    Michigan: [
      "Allendale",
      "Alpena",
      "Atlanta",
      "Auburn Hills",
      "Baldwin",
      "Bangor",
      "Battle Creek",
      "Bay City",
      "Bellaire",
      "Berkley",
      "Beulah",
      "Big Rapids",
      "Birmingham",
      "Boyne City",
      "Brighton",
      "Buchanan",
      "Clarkston",
      "Clawson",
      "Clinton Township",
      "Clio",
      "Columbus",
      "Comstock Park",
      "Durand",
      "East Grand Rapids",
      "Escanaba",
      "Farmington",
      "Farmington Hills",
      "Fenton",
      "Ferndale",
      "Forest Hills",
      "Frankenmuth",
      "Frankfort",
      "Garden City",
      "Gaylord",
      "Grand Haven",
      "Grandville",
      "Grosse Pointe",
      "Grosse Pointe Farms",
      "Grosse Pointe Woods",
      "Harper Woods",
      "Haslett",
      "Hazel Park",
      "Highland Park",
      "Holly",
      "Holt",
      "Inkster",
      "Ionia",
      "Iron River",
      "Jackson",
      "Jenison",
      "Lake City",
      "Lake Fenton",
      "Lake Orion",
      "Leland",
      "Madison Heights",
      "Manton",
      "Marysville",
      "Melvindale",
      "Midland",
      "Monroe",
      "Mount Clemens",
      "Mount Morris",
      "Mount Pleasant",
      "Munising",
      "Nashville",
      "New Baltimore",
      "Niles",
      "Northville",
      "Oak Park",
      "Okemos",
      "Ortonville",
      "Owosso",
      "Paris",
      "Pearl Beach",
      "Petoskey",
      "Plymouth",
      "Port Huron",
      "Redford",
      "Rochester",
      "Rochester Hills",
      "Roseville",
      "Saint Clair Shores",
      "Saint Ignace",
      "Shelby",
      "Shorewood-Tower Hills-Harbert",
      "Southfield",
      "Southgate",
      "South Lyon",
      "Springfield",
      "Sterling Heights",
      "Sturgis",
      "Trenton",
      "Wakefield",
      "Waverly",
      "Wayne",
      "West Bloomfield",
      "West Bloomfield Township",
      "Wixom",
      "Wolverine Lake",
      "Wyandotte",
      "Ypsilanti",
      "Adrian",
      "Allen Park",
      "Ann Arbor",
      "Burton",
      "Cadillac",
      "Canton",
      "Coldwater",
      "Dearborn",
      "Dearborn Heights",
      "Detroit",
      "East Lansing",
      "Eastpointe",
      "Flint",
      "Fraser",
      "Grand Rapids",
      "Hamtramck",
      "Holland",
      "Kalamazoo",
      "Lansing",
      "Lincoln Park",
      "Livonia",
      "Marquette",
      "Muskegon",
      "Novi",
      "Pontiac",
      "Portage",
      "Romulus",
      "Royal Oak",
      "Saginaw",
      "Taylor",
      "Traverse City",
      "Troy",
      "Warren",
      "Waterford",
      "Westland"
    ],
    Minnesota: [
      "Albert Lea",
      "Andover",
      "Anoka",
      "Austin",
      "Bagley",
      "Baudette",
      "Bemidji",
      "Big Lake",
      "Blaine",
      "Bloomington",
      "Brainerd",
      "Brooklyn Park",
      "Buffalo",
      "Burnsville",
      "Champlin",
      "Chanhassen",
      "Chaska",
      "Cloquet",
      "Collegeville",
      "Cook",
      "Coon Rapids",
      "Deephaven",
      "Detroit Lakes",
      "Eagan",
      "Edina",
      "Elk River",
      "Ely",
      "Excelsior",
      "Falcon Heights",
      "Faribault",
      "Farmington",
      "Fergus Falls",
      "Finland",
      "Forest Lake",
      "Glenwood",
      "Grand Rapids",
      "Hastings",
      "Hibbing",
      "Inver Grove Heights",
      "Jordan",
      "Lake City",
      "Lauderdale",
      "Little Canada",
      "Maple Grove",
      "Marshall",
      "Medina",
      "Mendota Heights",
      "Monticello",
      "New Hope",
      "New Ulm",
      "North Branch",
      "Northfield",
      "North Saint Paul",
      "Owatonna",
      "Parkers Prairie",
      "Pierz",
      "Plymouth",
      "Prior Lake",
      "Richfield",
      "Rosemount",
      "Saint Louis Park",
      "Saint Michael",
      "Saint Peter",
      "Sartell",
      "Sauk Rapids",
      "Shakopee",
      "Vineland",
      "Waconia",
      "Walker",
      "West Coon Rapids",
      "Willmar",
      "Woodbury",
      "Worthington",
      "Cottage Grove",
      "Duluth",
      "Eden Prairie",
      "Hopkins",
      "Hugo",
      "Lakeville",
      "Mankato",
      "Minneapolis",
      "Minnetonka",
      "Moorhead",
      "Red Wing",
      "Rochester",
      "Saint Cloud",
      "Saint Paul",
      "Savage",
      "Winona"
    ],
    Mississippi: [
      "Arnold Line",
      "Bailey",
      "Brandon",
      "Brookhaven",
      "Byram",
      "Canton",
      "Carriere",
      "Clarksdale",
      "Cleveland",
      "Clinton",
      "Collins",
      "Columbus",
      "Corinth",
      "Crystal Springs",
      "D'Iberville",
      "Fulton",
      "Goodman",
      "Greenville",
      "Greenwood",
      "Horn Lake",
      "Madison",
      "Mendenhall",
      "Meridian",
      "Moss Point",
      "New Albany",
      "Ocean Springs",
      "Olive Branch",
      "Oxford",
      "Pascagoula",
      "Pearlington",
      "Picayune",
      "Ridgeland",
      "Ruth",
      "Southaven",
      "Starkville",
      "University",
      "West",
      "West Point",
      "Yazoo City",
      "Biloxi",
      "Gautier",
      "Gulfport",
      "Hattiesburg",
      "Hernando",
      "Jackson",
      "Laurel",
      "McComb",
      "Natchez",
      "Pearl",
      "Petal",
      "Tupelo",
      "Vicksburg"
    ],
    Missouri: [
      "Archie",
      "Arnold",
      "Ballwin",
      "Berkeley",
      "Blue Springs",
      "Bourbon",
      "California",
      "Camdenton",
      "Chesterfield",
      "Clarkson Valley",
      "Clayton",
      "Columbia",
      "Creve Coeur",
      "Dardenne Prairie",
      "Diamond",
      "Eldon",
      "Eureka",
      "Excelsior Springs",
      "Farmington",
      "Fayette",
      "Festus",
      "Fort Leonard Wood",
      "Gallatin",
      "Gladstone",
      "Grain Valley",
      "Grandview",
      "Green Park",
      "Hazelwood",
      "Hermitage",
      "Jackson",
      "Kennett",
      "Kimberling City",
      "Kirksville",
      "Lake Ozark",
      "Lake Saint Louis",
      "Lone Jack",
      "Marshall",
      "Maryland Heights",
      "Maryville",
      "Mexico",
      "Moberly",
      "Neosho",
      "Nixa",
      "North Kansas City",
      "Osage Beach",
      "Pacific",
      "Patton",
      "Poplar Bluff",
      "Raymore",
      "Saint Ann",
      "Sedalia",
      "St Louis",
      "St. Louis",
      "Sullivan",
      "Trenton",
      "Troy",
      "Union",
      "University City",
      "Warrensburg",
      "Webb City",
      "Wentzville",
      "West Plains",
      "Woodson Terrace",
      "Belton",
      "Bolivar",
      "Branson",
      "Cape Girardeau",
      "Florissant",
      "Fulton",
      "Hannibal",
      "Independence",
      "Jefferson City",
      "Joplin",
      "Kansas City",
      "Liberty",
      "Ozark",
      "Republic",
      "Rolla",
      "Saint Charles",
      "Saint Joseph",
      "Sikeston",
      "Springfield"
    ],
    Montana: [
      "Belgrade",
      "Bigfork",
      "Big Sky",
      "Butte",
      "Columbia Falls",
      "Glendive",
      "Hamilton",
      "Hardin",
      "Kalispell",
      "Libby",
      "Livingston",
      "Malta",
      "Montana City",
      "Philipsburg",
      "Power",
      "Stanford",
      "Virginia City",
      "West Yellowstone",
      "Whitefish",
      "Wisdom",
      "Billings",
      "Bozeman",
      "Great Falls",
      "Helena",
      "Missoula"
    ],
    Nebraska: [
      "Beatrice",
      "Bellevue",
      "Bennington",
      "Columbus",
      "Elkhorn",
      "Fremont",
      "Friend",
      "Grand Island",
      "Hastings",
      "La Vista",
      "Lexington",
      "Norfolk",
      "North Platte",
      "Ogallala",
      "Papillion",
      "Ralston",
      "Scottsbluff",
      "Valentine",
      "Kearney",
      "Lincoln",
      "Omaha"
    ],
    Nevada: [
      "Alamo",
      "Boulder City",
      "Dayton",
      "Elko",
      "Enterprise",
      "Fernley",
      "Gardnerville Ranchos",
      "Incline Village",
      "Kingsbury",
      "Laughlin",
      "Lovelock",
      "Moapa Town",
      "Moapa Valley",
      "Nellis Air Force Base",
      "North Las Vegas",
      "Pahrump",
      "Sandy Valley",
      "Spring Creek",
      "Spring Valley",
      "Summerlin South",
      "Sunrise Manor",
      "Tonopah",
      "Virginia City",
      "Whitney",
      "Winchester",
      "Beatty",
      "Carson City",
      "Henderson",
      "Las Vegas",
      "Paradise",
      "Reno",
      "Sparks",
      "Sun Valley"
    ],
    "New Hampshire": [
      "Alexandria",
      "Bedford",
      "Chesterfield",
      "Chichester",
      "Concord",
      "Deerfield",
      "Dover",
      "Durham",
      "Epping",
      "Exeter",
      "Freedom",
      "Gilford",
      "Gilmanton",
      "Greenfield",
      "Hampton Beach",
      "Hanover",
      "Haverhill",
      "Henniker",
      "Jefferson",
      "Keene",
      "Kensington",
      "Littleton",
      "Londonderry",
      "Madison",
      "Manchester",
      "Meredith",
      "Moultonborough",
      "Nashua",
      "New London",
      "North Conway",
      "Northfield",
      "North Hampton",
      "Peterborough",
      "Plaistow",
      "Portsmouth",
      "Rindge",
      "Rochester",
      "Rye",
      "Salem",
      "Somersworth",
      "Webster",
      "Windham",
      "Woodstock",
      "Derry",
      "Laconia",
      "Merrimack"
    ],
    "New Jersey": [
      "Allendale",
      "Atco",
      "Atlantic Highlands",
      "Audubon Park",
      "Avalon",
      "Avenel",
      "Avon-by-the-Sea",
      "Bayville",
      "Beach Haven",
      "Beachwood",
      "Bedminster",
      "Belleville",
      "Bellmawr",
      "Belmar",
      "Bergenfield",
      "Berkeley Heights",
      "Bernardsville",
      "Blackwood",
      "Bloomingdale",
      "Bogota",
      "Boonton",
      "Bound Brook",
      "Bradley Beach",
      "Bridgeton",
      "Brigantine",
      "Brooklawn",
      "Brownville",
      "Caldwell",
      "Califon",
      "Cape May",
      "Carlstadt",
      "Carteret",
      "Cedar Glen West",
      "Cedar Grove",
      "Cherry Hill Mall",
      "Chesilhurst",
      "Chester",
      "Cliffside Park",
      "Cliffwood Beach",
      "Clinton",
      "Collings Lakes",
      "Country Lake Estates",
      "Cresskill",
      "Delanco",
      "Dover",
      "Dunellen",
      "East Brunswick",
      "East Freehold",
      "East Hanover",
      "East Orange",
      "East Rutherford",
      "Echelon",
      "Edgewater",
      "Edgewater Park",
      "Egg Harbor City",
      "Ellisburg",
      "Elmwood Park",
      "Emerson",
      "Englewood",
      "Englewood Cliffs",
      "Fairfield",
      "Fair Haven",
      "Fair Lawn",
      "Fairview",
      "Fanwood",
      "Farmingdale",
      "Finderne",
      "Flemington",
      "Florham Park",
      "Fords",
      "Forked River",
      "Fort Lee",
      "Gibbsboro",
      "Glen Ridge",
      "Gloucester City",
      "Golden Triangle",
      "Greentree",
      "Guttenberg",
      "Hackensack",
      "Hackettstown",
      "Haddon Heights",
      "Haledon",
      "Hamburg",
      "Hamilton Square",
      "Hammonton",
      "Hanover",
      "Harrison",
      "Hasbrouck Heights",
      "Hawthorne",
      "Highland Park",
      "Highlands",
      "Hillside",
      "Hopatcong",
      "Irvington",
      "Iselin",
      "Island Heights",
      "Jackson",
      "Kearny",
      "Kendall Park",
      "Kenvil",
      "Keyport",
      "Lake Mohawk",
      "Lake Telemark",
      "Lakewood",
      "Lambertville",
      "Laurel Lake",
      "Lavallette",
      "Lawrenceville",
      "Leisuretowne",
      "Leisure Village",
      "Leisure Village East",
      "Leonardo",
      "Leonia",
      "Lincoln Park",
      "Lincroft",
      "Linden",
      "Little Falls",
      "Little Ferry",
      "Livingston",
      "Lodi",
      "Long Branch",
      "Lyndhurst",
      "Madison",
      "Madison Park",
      "Mahwah",
      "Manasquan",
      "Manville",
      "Maple Shade",
      "Maplewood",
      "Margate City",
      "Marlton",
      "Matawan",
      "Medford Lakes",
      "Mendham",
      "Merchantville",
      "Metuchen",
      "Middlesex",
      "Milford",
      "Milltown",
      "Millville",
      "Monmouth Beach",
      "Moorestown-Lenola",
      "Morris Plains",
      "Morristown",
      "Mount Arlington",
      "Mount Holly",
      "Mount Laurel",
      "National Park",
      "Neptune City",
      "New Brunswick",
      "New Milford",
      "New Providence",
      "Newton",
      "North Arlington",
      "North Beach Haven",
      "North Bergen",
      "Northfield",
      "North Haledon",
      "Northvale",
      "Nutley",
      "Oakhurst",
      "Oakland",
      "Ocean Acres",
      "Ocean Grove",
      "Old Bridge",
      "Old Tappan",
      "Oradell",
      "Orange",
      "Oxford",
      "Palisades Park",
      "Palmyra",
      "Parsippany",
      "Passaic",
      "Paulsboro",
      "Pemberton",
      "Pennington",
      "Pennsauken",
      "Pennsville",
      "Perth Amboy",
      "Phillipsburg",
      "Pine Beach",
      "Piscataway",
      "Plainfield",
      "Pleasantville",
      "Point Pleasant",
      "Point Pleasant Beach",
      "Pomona",
      "Pompton Lakes",
      "Prospect Park",
      "Rahway",
      "Ramblewood",
      "Raritan",
      "Red Bank",
      "Ridgefield Park",
      "Ridgewood",
      "Ringwood",
      "Riverdale",
      "River Edge",
      "Riverton",
      "Robertsville",
      "Rochelle Park",
      "Rockaway",
      "Roselle",
      "Roselle Park",
      "Rumson",
      "Saddle Brook",
      "Sayreville",
      "Sea Bright",
      "Sea Isle City",
      "Seaside Heights",
      "Seaside Park",
      "Sewaren",
      "Sewell",
      "Ship Bottom",
      "Short Hills",
      "Shrewsbury",
      "Singac",
      "Somerdale",
      "Somers Point",
      "Somerville",
      "South Belmar",
      "South Bound Brook",
      "South Orange",
      "South Plainfield",
      "South River",
      "Springdale",
      "Springfield",
      "Spring Lake",
      "Stanhope",
      "Strathmore",
      "Succasunna",
      "Surf City",
      "Swedesboro",
      "Teaneck",
      "Tenafly",
      "Totowa",
      "Tuckerton",
      "Turnersville",
      "Twin Rivers",
      "Union City",
      "Upper Montclair",
      "Upper Saddle River",
      "Ventnor City",
      "Vernon Valley",
      "Verona",
      "Vincentown",
      "Vineland",
      "Waldwick",
      "Wallington",
      "Wanamassa",
      "Wanaque",
      "Washington",
      "Weehawken",
      "West Caldwell",
      "West Cape May",
      "Westfield",
      "West Long Branch",
      "West Milford",
      "West New York",
      "Weston",
      "West Orange",
      "Westville",
      "Westwood",
      "Whitehouse Station",
      "Wildwood",
      "Wildwood Crest",
      "Williamstown",
      "Willingboro",
      "Winfield",
      "Woodbine",
      "Woodbury Heights",
      "Woodcliff Lake",
      "Woodstown",
      "Wyckoff",
      "Yorketown",
      "Asbury Park",
      "Atlantic City",
      "Bayonne",
      "Bloomfield",
      "Bridgewater",
      "Camden",
      "Cherry Hill",
      "Clark",
      "Clifton",
      "Collingswood",
      "Cranford",
      "Dumont",
      "Eatontown",
      "Edison",
      "Elizabeth",
      "Franklin Lakes",
      "Freehold",
      "Garfield",
      "Glassboro",
      "Glen Rock",
      "Haddonfield",
      "Hillsdale",
      "Hoboken",
      "Jersey City",
      "Marlboro",
      "Montclair",
      "Newark",
      "Ocean City",
      "Paramus",
      "Paterson",
      "Princeton",
      "Ramsey",
      "Ridgefield",
      "Rutherford",
      "Scotch Plains",
      "Secaucus",
      "Sicklerville",
      "Somerset",
      "Summit",
      "Toms River",
      "Trenton",
      "Union",
      "Wayne",
      "Woodbridge",
      "Woodbury"
    ],
    "New Mexico": [
      "Agua Fria",
      "Alamogordo",
      "Angel Fire",
      "Artesia",
      "Capitan",
      "Carlsbad",
      "Carrizozo",
      "Cliff",
      "Clovis",
      "Corrales",
      "Deming",
      "Enchanted Hills",
      "Gallup",
      "Hobbs",
      "Holloman Air Force Base",
      "La Cienega",
      "Las Vegas",
      "Lee Acres",
      "Los Lunas",
      "Lovington",
      "Mesilla",
      "Nambe",
      "Navajo",
      "North Valley",
      "Ohkay Owingeh",
      "Paradise Hills",
      "Pecos",
      "Portales",
      "Questa",
      "Raton",
      "Rio Rancho",
      "Roswell",
      "Ruidoso",
      "Sandia Heights",
      "Santa Fe",
      "Santa Rosa",
      "Santo Domingo Pueblo",
      "Silver City",
      "South Valley",
      "Sunland Park",
      "Taos",
      "Taos Pueblo",
      "Tucumcari",
      "University Park",
      "White Rock",
      "White Sands",
      "Albuquerque",
      "Española",
      "Farmington",
      "Las Cruces",
      "Los Alamos"
    ],
    "New York": [
      "Adams",
      "Alexandria Bay",
      "Amagansett",
      "Amityville",
      "Amsterdam",
      "Aquebogue",
      "Arlington",
      "Arverne",
      "Athens",
      "Atlantic Beach",
      "Baldwin Harbor",
      "Baldwinsville",
      "Barnum Island",
      "Bay Shore",
      "Bay Wood",
      "Beaverdam Lake-Salisbury Mills",
      "Bedford",
      "Bedford Hills",
      "Bellerose Terrace",
      "Bellmore",
      "Bellport",
      "Bensonhurst",
      "Bethpage",
      "Blasdell",
      "Bloomfield",
      "Borough of Queens",
      "Briarcliff Manor",
      "Bridgehampton",
      "Brighton",
      "Brightwaters",
      "Bronxville",
      "Brookville",
      "Cambria Heights",
      "Carle Place",
      "Carmel",
      "Catskill",
      "Cayuga Heights",
      "Centereach",
      "Central Islip",
      "Central Valley",
      "Cheektowaga",
      "Clark Mills",
      "Clayton",
      "Clinton",
      "Cohoes",
      "Cold Spring",
      "College Point",
      "Commack",
      "Coney Island",
      "Copiague",
      "Corning",
      "Cornwall-on-Hudson",
      "Country Knolls",
      "Coxsackie",
      "Croton-on-Hudson",
      "Crown Heights",
      "Deer Park",
      "Delhi",
      "Delmar",
      "Depew",
      "Dobbs Ferry",
      "Dover Plains",
      "Dundee",
      "East Atlantic Beach",
      "Eastchester",
      "East Farmingdale",
      "East Garden City",
      "East Hampton",
      "East Hills",
      "East Islip",
      "East Ithaca",
      "East Meadow",
      "East Moriches",
      "East New York",
      "East Northport",
      "East Rochester",
      "East Setauket",
      "Ellenville",
      "Elmira",
      "Elmont",
      "Elwood",
      "Endwell",
      "Fairmount",
      "Fairview",
      "Farmingdale",
      "Farmingville",
      "Far Rockaway",
      "Flanders",
      "Floral Park",
      "Florida",
      "Forest Hills",
      "Fort Drum",
      "Fort Montgomery",
      "Franklin Square",
      "Fredonia",
      "Fresh Meadows",
      "Gansevoort",
      "Garden City",
      "Garden City Park",
      "Geneva",
      "Glens Falls",
      "Glens Falls North",
      "Gloversville",
      "Gouverneur",
      "Great Neck",
      "Great Neck Estates",
      "Great Neck Plaza",
      "Great River",
      "Greene",
      "Green Island",
      "Greenlawn",
      "Greenport",
      "Greenport West",
      "Greenville",
      "Hadley",
      "Halesite",
      "Hamilton",
      "Hampton Bays",
      "Hampton Manor",
      "Hancock",
      "Hannawa Falls",
      "Harbor Isle",
      "Harris Hill",
      "Hartsdale",
      "Hastings-on-Hudson",
      "Hauppauge",
      "Haverstraw",
      "Haviland",
      "Heritage Hills",
      "Hewlett",
      "Hicksville",
      "Hillside Lake",
      "Hilton",
      "Holbrook",
      "Holtsville",
      "Honeoye Falls",
      "Hoosick Falls",
      "Hornell",
      "Horseheads North",
      "Howard Beach",
      "Hudson",
      "Huntington",
      "Hurley",
      "Hyde Park",
      "Inwood",
      "Islandia",
      "Island Park",
      "Islip",
      "Islip Terrace",
      "Jamaica",
      "Jefferson Valley-Yorktown",
      "Jericho",
      "Katonah",
      "Kenmore",
      "Kerhonkson",
      "Keuka Park",
      "Kew Gardens",
      "Kings Park",
      "Kings Point",
      "Kingston",
      "Kiryas Joel",
      "Lake Grove",
      "Lake Katrine",
      "Lake Placid",
      "Lake Pleasant",
      "Lake Success",
      "Larchmont",
      "Latham",
      "Levittown",
      "Lewiston",
      "Liberty",
      "Lido Beach",
      "Lindenhurst",
      "Little Neck",
      "Liverpool",
      "Livingston Manor",
      "Livonia",
      "Long Beach",
      "Long Island City",
      "Lowville",
      "Lynbrook",
      "Mahopac",
      "Mamaroneck",
      "Manchester",
      "Manhasset",
      "Manhasset Hills",
      "Manorville",
      "Maspeth",
      "Massapequa Park",
      "Massena",
      "Mastic",
      "Mastic Beach",
      "Mattituck",
      "Mayville",
      "Mechanicstown",
      "Medford",
      "Melrose Park",
      "Middle Island",
      "Middle Village",
      "Millbrook",
      "Miller Place",
      "Milton",
      "Mineola",
      "Mohawk",
      "Montauk",
      "Montgomery",
      "Monticello",
      "Mount Morris",
      "Mount Vernon",
      "Munsey Park",
      "Myers Corner",
      "Nanuet",
      "Napanoch",
      "Naples",
      "Nassau",
      "Nedrow",
      "Nesconset",
      "New City",
      "New Hartford",
      "New Hyde Park",
      "New Paltz",
      "New Rochelle",
      "New Windsor",
      "New York Mills",
      "Niagara Falls",
      "North Babylon",
      "North Bellmore",
      "North Bellport",
      "North Gates",
      "North Lindenhurst",
      "North Massapequa",
      "North Merrick",
      "North New Hyde Park",
      "Northport",
      "North Sea",
      "North Tonawanda",
      "Northwest Harbor",
      "Oakdale",
      "Oceanside",
      "Ogdensburg",
      "Old Westbury",
      "Olean",
      "Oneida",
      "Oriskany",
      "Ossining",
      "Oswego",
      "Oyster Bay",
      "Oyster Bay Cove",
      "Ozone Park",
      "Palenville",
      "Patchogue",
      "Pawling",
      "Peach Lake",
      "Pearl River",
      "Pelham",
      "Phelps",
      "Pittsford",
      "Plainview",
      "Pleasantville",
      "Poestenkill",
      "Point Lookout",
      "Port Chester",
      "Port Ewen",
      "Port Jefferson",
      "Port Jervis",
      "Port Washington",
      "Potsdam",
      "Pound Ridge",
      "Pulaski",
      "Purchase",
      "Queensbury",
      "Queens Village",
      "Red Hook",
      "Rego Park",
      "Remsenburg-Speonk",
      "Rhinebeck",
      "Ridge",
      "Rocky Point",
      "Roessleville",
      "Rome",
      "Ronkonkoma",
      "Rosedale",
      "Roslyn",
      "Roslyn Harbor",
      "Rotterdam",
      "Sag Harbor",
      "Salisbury",
      "Saranac Lake",
      "Scarsdale",
      "Schuylerville",
      "Seaford",
      "Selden",
      "Shelter Island",
      "Shinnecock Hills",
      "Shirley",
      "Shokan",
      "Shrub Oak",
      "Skaneateles",
      "Sloatsburg",
      "Solvay",
      "Southampton",
      "South Farmingdale",
      "South Hill",
      "Southold",
      "South Valley Stream",
      "South Wales",
      "Springs",
      "Spring Valley",
      "Stamford",
      "Stewart Manor",
      "Stone Ridge",
      "Stony Brook",
      "Stony Point",
      "Suffern",
      "Syosset",
      "Terryville",
      "The Bronx",
      "Ticonderoga",
      "Tivoli",
      "Tonawanda",
      "Troy",
      "Trumansburg",
      "Tuckahoe",
      "Tupper Lake",
      "Uniondale",
      "Upper Brookville",
      "Upper Nyack",
      "Valley Cottage",
      "Wading River",
      "Walden",
      "Walton Park",
      "Wappingers Falls",
      "Warrensburg",
      "Warwick",
      "Water Mill",
      "Watertown",
      "Watervliet",
      "Watkins Glen",
      "Wayland",
      "Webster",
      "West Albany",
      "West Babylon",
      "Westbury",
      "Westhampton",
      "Westhampton Beach",
      "West Haverstraw",
      "West Hempstead",
      "West Hills",
      "West Islip",
      "West Nyack",
      "West Point",
      "West Sayville",
      "Westvale",
      "Whitehall",
      "Whitestone",
      "Woodbury",
      "Woodhaven",
      "Woodmere",
      "Woodside",
      "Woodstock",
      "Yaphank",
      "Yorktown Heights",
      "Youngstown",
      "Zena",
      "Albany",
      "Amherst",
      "Astoria",
      "Babylon",
      "Baldwin",
      "Bayside",
      "Beacon",
      "Binghamton",
      "Bohemia",
      "Brentwood",
      "Brooklyn",
      "Buffalo",
      "Canandaigua",
      "Coram",
      "Cortland",
      "Elmhurst",
      "Endicott",
      "Freeport",
      "Glen Cove",
      "Harrison",
      "Hempstead",
      "Huntington Station",
      "Ithaca",
      "Jackson Heights",
      "Jamestown",
      "Manhattan",
      "Massapequa",
      "Melville",
      "Merrick",
      "Monsey",
      "Mount Kisco",
      "Mount Sinai",
      "Newburgh",
      "New York",
      "New York City",
      "Oneonta",
      "Peekskill",
      "Plattsburgh",
      "Poughkeepsie",
      "Ridgewood",
      "Riverhead",
      "Rochester",
      "Rockville Centre",
      "Roosevelt",
      "Rye",
      "Saratoga Springs",
      "Sayville",
      "Schenectady",
      "Smithtown",
      "Staten Island",
      "Sunnyside",
      "Syracuse",
      "Tarrytown",
      "Utica",
      "Valley Stream",
      "Wantagh",
      "White Plains",
      "Wyandanch",
      "Yonkers"
    ],
    "North Carolina": [
      "Albemarle",
      "Asheboro",
      "Atlantic Beach",
      "Badin",
      "Banner Elk",
      "Belmont",
      "Bent Creek",
      "Biltmore Forest",
      "Black Mountain",
      "Brevard",
      "Bryson City",
      "Burlington",
      "Butner",
      "Buxton",
      "Cape Carteret",
      "Carolina Beach",
      "Cary",
      "Cedar Point",
      "Clemmons",
      "Clinton",
      "Columbus",
      "Concord",
      "Conover",
      "Cornelius",
      "Cove Creek",
      "Cullowhee",
      "Currituck",
      "Denton",
      "East Spencer",
      "Elizabeth City",
      "Elkin",
      "Enochville",
      "Etowah",
      "Fairview",
      "Farmville",
      "Fearrington Village",
      "Fort Bragg",
      "Foscoe",
      "Franklin",
      "Fuquay-Varina",
      "Gamewell",
      "Goldsboro",
      "Gorman",
      "Harrisburg",
      "Henderson",
      "Hendersonville",
      "Holly Ridge",
      "Holly Springs",
      "Hope Mills",
      "Huntersville",
      "Indian Trail",
      "Jacksonville",
      "Jamestown",
      "Kannapolis",
      "Kernersville",
      "Kill Devil Hills",
      "Kings Grant",
      "Kings Mountain",
      "Kinston",
      "Kitty Hawk",
      "Knightdale",
      "Kure Beach",
      "Lake Lure",
      "Lake Norman of Catawba",
      "Laurinburg",
      "Leland",
      "Lenoir",
      "Lewisville",
      "Lexington",
      "Lillington",
      "Lincolnton",
      "Long Beach",
      "Longview",
      "Lowell",
      "Lumberton",
      "Maggie Valley",
      "Manteo",
      "Marshall",
      "Marvin",
      "Mebane",
      "Micro",
      "Midland",
      "Mocksville",
      "Monroe",
      "Morganton",
      "Morrisville",
      "Mount Airy",
      "Mount Holly",
      "Mount Pleasant",
      "Myrtle Grove",
      "Nags Head",
      "Nashville",
      "New Bern",
      "Newland",
      "Newton",
      "Ogden",
      "Pilot Mountain",
      "Pinehurst",
      "Pineville",
      "Piney Green",
      "Pittsboro",
      "Pumpkin Center",
      "Randleman",
      "Reidsville",
      "Roanoke Rapids",
      "Robbinsville",
      "Rockfish",
      "Rocky Mount",
      "Rocky Point",
      "Rose Hill",
      "Rowland",
      "Salisbury",
      "Sanford",
      "Seagate",
      "Selma",
      "Shallotte",
      "Silver Lake",
      "Smithfield",
      "Southern Pines",
      "Southern Shores",
      "Southport",
      "Spring Lake",
      "Spruce Pine",
      "Statesville",
      "Summerfield",
      "Sunset Beach",
      "Supply",
      "Surf City",
      "Swanquarter",
      "Tarboro",
      "Thomasville",
      "Tryon",
      "Unionville",
      "Wanchese",
      "Waxhaw",
      "Weddington",
      "Welcome",
      "West Raleigh",
      "Wilkesboro",
      "Windsor",
      "Winston-Salem",
      "Woodfin",
      "Wrightsville Beach",
      "Youngsville",
      "Zebulon",
      "Apex",
      "Asheville",
      "Boone",
      "Carrboro",
      "Chapel Hill",
      "Charlotte",
      "Clayton",
      "Davidson",
      "Durham",
      "Eden",
      "Elon",
      "Fayetteville",
      "Garner",
      "Gastonia",
      "Greensboro",
      "Greenville",
      "Havelock",
      "Hickory",
      "High Point",
      "Matthews",
      "Mooresville",
      "Raleigh",
      "Shelby",
      "Wake Forest",
      "Wilmington",
      "Wilson"
    ],
    "North Dakota": [
      "Bismarck",
      "Bowman",
      "Jamestown",
      "Mandan",
      "McClusky",
      "Medora",
      "Michigan",
      "New Town",
      "Watford City",
      "West Fargo",
      "Dickinson",
      "Fargo",
      "Grand Forks",
      "Minot",
      "Williston"
    ],
    Ohio: [
      "Amanda",
      "Amherst",
      "Ashland",
      "Ashtabula",
      "Athens",
      "Aurora",
      "Avon Lake",
      "Barberton",
      "Batavia",
      "Bay Village",
      "Beavercreek",
      "Bedford",
      "Bellaire",
      "Bellefontaine",
      "Bellville",
      "Bexley",
      "Blacklick Estates",
      "Bluffton",
      "Boardman",
      "Bowling Green",
      "Bratenahl",
      "Brecksville",
      "Broadview Heights",
      "Brooklyn",
      "Brooklyn Heights",
      "Brook Park",
      "Bucyrus",
      "Cambridge",
      "Canal Winchester",
      "Canfield",
      "Canton",
      "Centerville",
      "Chillicothe",
      "Circleville",
      "Clayton",
      "Cleveland Heights",
      "Columbiana",
      "Coshocton",
      "Curtice",
      "Cuyahoga Falls",
      "Defiance",
      "Delaware",
      "Dorset",
      "Dover",
      "Drexel",
      "Dublin",
      "East Canton",
      "East Cleveland",
      "Eastlake",
      "East Liverpool",
      "Englewood",
      "Etna",
      "Euclid",
      "Fairborn",
      "Fairfax",
      "Fairfield",
      "Fairlawn",
      "Fairport Harbor",
      "Fairview Park",
      "Fort Loramie",
      "Fostoria",
      "Franklin",
      "Fredericktown",
      "Fremont",
      "Gahanna",
      "Galion",
      "Gambier",
      "Geneva",
      "Geneva-on-the-Lake",
      "Georgetown",
      "Gibsonburg",
      "Girard",
      "Glouster",
      "Golf Manor",
      "Goshen",
      "Grandview Heights",
      "Greenville",
      "Grove City",
      "Hamilton",
      "Highland Hills",
      "Hilliard",
      "Holland",
      "Howland Center",
      "Huber Ridge",
      "Independence",
      "Ironton",
      "Kent",
      "Kenwood",
      "Kettering",
      "Kings Mills",
      "Kingston",
      "Lakewood",
      "Landen",
      "Lebanon",
      "Lewis Center",
      "Lexington",
      "Liberty Center",
      "Lima",
      "Logan",
      "London",
      "Loudonville",
      "Loveland",
      "Lyndhurst",
      "Malta",
      "Mansfield",
      "Maple Heights",
      "Marietta",
      "Marion",
      "Marysville",
      "Maumee",
      "Mayfield Heights",
      "McConnelsville",
      "McKinley Heights",
      "Miamitown",
      "Milan",
      "Milford",
      "Millersburg",
      "Minerva Park",
      "Mingo Junction",
      "Monroe",
      "Montrose-Ghent",
      "Mount Healthy",
      "Mount Vernon",
      "Napoleon",
      "New Albany",
      "Newark",
      "New California",
      "New Concord",
      "New Philadelphia",
      "Niles",
      "North Canton",
      "North College Hill",
      "North Madison",
      "North Olmsted",
      "North Ridgeville",
      "North Royalton",
      "North Zanesville",
      "Norwalk",
      "Oakwood",
      "Oberlin",
      "Obetz",
      "Olmsted Falls",
      "Oregon",
      "Ottawa Hills",
      "Oxford",
      "Painesville",
      "Parma",
      "Pataskala",
      "Peninsula",
      "Perrysburg",
      "Pickerington",
      "Piqua",
      "Poland",
      "Port Clinton",
      "Portsmouth",
      "Ravenna",
      "Reynoldsburg",
      "Rocky River",
      "Rossford",
      "Russia",
      "Saint Bernard",
      "Saint Clairsville",
      "Salem",
      "Sandusky",
      "Seven Hills",
      "Shade",
      "Shaker Heights",
      "Shiloh",
      "Sixteen Mile Stand",
      "Solon",
      "South Lebanon",
      "Springboro",
      "Springdale",
      "Springfield",
      "Steubenville",
      "Stow",
      "Streetsboro",
      "Strongsville",
      "Struthers",
      "Sugarcreek",
      "Sunbury",
      "Sylvania",
      "Tallmadge",
      "Trenton",
      "Trotwood",
      "Troy",
      "Twinsburg",
      "Upper Arlington",
      "Upper Sandusky",
      "Urbana",
      "Vandalia",
      "Van Wert",
      "Wadsworth",
      "Warren",
      "Warrensville Heights",
      "Washington Court House",
      "Westerville",
      "Whitehall",
      "Whitehouse",
      "Willoughby",
      "Wilmington",
      "Wooster",
      "Worthington",
      "Wright-Patterson AFB",
      "Zanesville",
      "Akron",
      "Alliance",
      "Avon",
      "Beachwood",
      "Berea",
      "Brunswick",
      "Celina",
      "Cincinnati",
      "Cleveland",
      "Columbus",
      "Dayton",
      "Elyria",
      "Findlay",
      "Heath",
      "Hudson",
      "Lancaster",
      "Lorain",
      "Macedonia",
      "Mason",
      "Massillon",
      "Medina",
      "Mentor",
      "Miamisburg",
      "Middletown",
      "Powell",
      "Sidney",
      "Tiffin",
      "Toledo",
      "Vermilion",
      "Westlake",
      "Wickliffe",
      "Xenia",
      "Youngstown"
    ],
    Oklahoma: [
      "Ardmore",
      "Bartlesville",
      "Bixby",
      "Blanchard",
      "Broken Arrow",
      "Broken Bow",
      "Calera",
      "Chandler",
      "Chickasha",
      "Claremore",
      "Collinsville",
      "Del City",
      "Durant",
      "El Reno",
      "Erick",
      "Eufaula",
      "Glenpool",
      "Goldsby",
      "Guymon",
      "Jenks",
      "Lawton",
      "Lone Grove",
      "Longtown",
      "McAlester",
      "Midwest City",
      "Moore",
      "Muse",
      "Nichols Hills",
      "Nowata",
      "Okmulgee",
      "Ponca City",
      "Prague",
      "Rose",
      "Sapulpa",
      "Shawnee",
      "Stillwater",
      "Stuart",
      "Tecumseh",
      "The Village",
      "Turley",
      "Warr Acres",
      "Weatherford",
      "Yukon",
      "Ada",
      "Altus",
      "Bethany",
      "Choctaw",
      "Duncan",
      "Edmond",
      "Elk City",
      "Enid",
      "Guthrie",
      "Miami",
      "Muskogee",
      "Mustang",
      "Norman",
      "Oklahoma City",
      "Owasso",
      "Sand Springs",
      "Tahlequah",
      "Tulsa",
      "Woodward"
    ],
    Oregon: [
      "Albany",
      "Aloha",
      "Amity",
      "Ashland",
      "Bandon",
      "Banks",
      "Canby",
      "Cannon Beach",
      "Carlton",
      "Cascade Locks",
      "Cave Junction",
      "Cedar Mill",
      "Central Point",
      "Chenoweth",
      "Clackamas",
      "Coos Bay",
      "Cornelius",
      "Corvallis",
      "Dallas",
      "Damascus",
      "Depoe Bay",
      "Deschutes River Woods",
      "Dundee",
      "Durham",
      "Eagle Point",
      "Estacada",
      "Florence",
      "Forest Grove",
      "Fossil",
      "Gearhart",
      "Grants Pass",
      "Hermiston",
      "Hines",
      "Jacksonville",
      "Joseph",
      "Keizer",
      "King City",
      "Klamath Falls",
      "La Grande",
      "Lake Oswego",
      "Lakeside",
      "La Pine",
      "Lents",
      "Lincoln City",
      "Madras",
      "McMinnville",
      "Medford",
      "Millersburg",
      "Milwaukie",
      "Monmouth",
      "Mount Angel",
      "Mount Hood Village",
      "Newberg",
      "Newport",
      "North Bend",
      "Oak Hills",
      "Oakridge",
      "Odell",
      "Oregon City",
      "Pacific City",
      "Philomath",
      "Phoenix",
      "Port Orford",
      "Prineville",
      "Raleigh Hills",
      "Reedsport",
      "Rockcreek",
      "Rogue River",
      "Roseburg",
      "Rose Lodge",
      "Saint Helens",
      "Salem",
      "Sandy",
      "Seaside",
      "Shady Cove",
      "Sherwood",
      "Silverton",
      "Sisters",
      "Springfield",
      "Sunriver",
      "Terrebonne",
      "Tillamook",
      "Troutdale",
      "Tualatin",
      "Veneta",
      "Waldport",
      "West Haven",
      "West Haven-Sylvan",
      "West Linn",
      "Wilsonville",
      "Woodburn",
      "Beaverton",
      "Bend",
      "Eugene",
      "Gresham",
      "Happy Valley",
      "Hillsboro",
      "Pendleton",
      "Portland",
      "Redmond",
      "Tigard"
    ],
    Pennsylvania: [
      "Akron",
      "Allison Park",
      "Altoona",
      "Ambler",
      "Annville",
      "Ardmore",
      "Arlington Heights",
      "Audubon",
      "Avis",
      "Back Mountain",
      "Bala-Cynwyd",
      "Bear Rocks",
      "Bellevue",
      "Berwyn",
      "Bethel Park",
      "Bethlehem",
      "Birchwood Lakes",
      "Bloomfield",
      "Blue Bell",
      "Brackenridge",
      "Braddock",
      "Bridgeport",
      "Bristol",
      "Broomall",
      "Bryn Mawr",
      "Burgettstown",
      "California",
      "Canonsburg",
      "Carlisle",
      "Carnot-Moon",
      "Castanea",
      "Chester",
      "Chesterbrook",
      "Chevy Chase Heights",
      "Chinchilla",
      "Churchville",
      "Clifton Heights",
      "Coatesville",
      "Cochranton",
      "Columbia",
      "Colwyn",
      "Conshohocken",
      "Coopersburg",
      "Coraopolis",
      "Cornwall",
      "Cornwells Heights",
      "Corry",
      "Cranberry Township",
      "Croydon",
      "Curtisville",
      "Devon",
      "Dillsburg",
      "Dormont",
      "Dover",
      "Downingtown",
      "Doylestown",
      "Drexel Hill",
      "Duncannon",
      "Duncansville",
      "Duquesne",
      "East Greenville",
      "East Petersburg",
      "East Stroudsburg",
      "East Texas",
      "East York",
      "Eddington",
      "Edgewood",
      "Elizabethtown",
      "Elysburg",
      "Emerald Lakes",
      "Emmaus",
      "Ephrata",
      "Feasterville",
      "Fernway",
      "Fort Washington",
      "Fox Chapel",
      "Fox Chase",
      "Fullerton",
      "Gettysburg",
      "Glenolden",
      "Greensburg",
      "Hallstead",
      "Hamburg",
      "Hanover",
      "Harleysville",
      "Hatboro",
      "Hawley",
      "Hershey",
      "Hokendauqua",
      "Homestead",
      "Hopwood",
      "Horsham",
      "Hulmeville",
      "Huntingdon",
      "Hyde Park",
      "Indiana",
      "Indian Mountain Lake",
      "Jenkintown",
      "Johnstown",
      "Kenilworth",
      "Kennett Square",
      "King of Prussia",
      "Kingston",
      "Knox",
      "Kutztown",
      "Lakemont",
      "Lancaster",
      "Langhorne",
      "Lansdowne",
      "Lebanon",
      "Lehighton",
      "Levittown",
      "Lewisburg",
      "Lewistown",
      "Lock Haven",
      "Loretto",
      "Malvern",
      "Manheim",
      "Marienville",
      "Mars",
      "Marysville",
      "McConnellstown",
      "McKeesport",
      "McSherrystown",
      "Meadville",
      "Mechanicsburg",
      "Media",
      "Middletown",
      "Milford",
      "Millbourne",
      "Mill Hall",
      "Millvale",
      "Monroeville",
      "Montgomeryville",
      "Montrose",
      "Mountainhome",
      "Mountain Top",
      "Mount Joy",
      "Mount Oliver",
      "Muhlenberg Park",
      "Murrysville",
      "Nanticoke",
      "Narberth",
      "Nazareth",
      "New Berlinville",
      "New Britain",
      "New Castle",
      "New Hope",
      "New Kensington",
      "New Stanton",
      "Newtown",
      "Northampton",
      "North Versailles",
      "North Wales",
      "Norwood",
      "Oakdale",
      "Oil City",
      "Palmyra",
      "Parkesburg",
      "Parkside",
      "Penndel",
      "Perkasie",
      "Pleasant Hills",
      "Pocono Pines",
      "Pottstown",
      "Pottsville",
      "Prospect",
      "Prospect Park",
      "Radnor",
      "Reading",
      "Reiffton",
      "Rennerdale",
      "Richboro",
      "Ridley Park",
      "Rochester",
      "Rockledge",
      "Rutherford",
      "Saint Marys",
      "Salunga",
      "Sanatoga",
      "Saw Creek",
      "Saylorsburg",
      "Sewickley",
      "Sharpsburg",
      "Skippack",
      "Skyline View",
      "Slippery Rock",
      "Springdale",
      "Springfield",
      "Stowe",
      "Stroudsburg",
      "Tannersville",
      "Tatamy",
      "Thompsonville",
      "Thorndale",
      "Throop",
      "Towamensing Trails",
      "Trainer",
      "Tremont",
      "Trevose",
      "Trexlertown",
      "Upland",
      "Valley View",
      "Warminster Heights",
      "Wayne",
      "Wescosville",
      "West Lawn",
      "West Mifflin",
      "Westmont",
      "West Norriton",
      "West View",
      "Wilkes-Barre",
      "Williamsport",
      "Willow Grove",
      "Woodbourne",
      "Wyncote",
      "Wyndmoor",
      "Yeadon",
      "York",
      "Youngsville",
      "Youngwood",
      "Allentown",
      "Bloomsburg",
      "Butler",
      "Chambersburg",
      "Darby",
      "Easton",
      "Erie",
      "Harrisburg",
      "Hazleton",
      "Hermitage",
      "Lansdale",
      "Norristown",
      "Philadelphia",
      "Phoenixville",
      "Pittsburgh",
      "Scranton",
      "Sharon",
      "State College",
      "West Chester"
    ],
    "Rhode Island": [
      "Central Falls",
      "Charlestown",
      "Coventry",
      "Cranston",
      "East Providence",
      "Greenville",
      "Jamestown",
      "Kingston",
      "Lincoln",
      "Middletown",
      "Narragansett",
      "Narragansett Pier",
      "Newport",
      "Newport East",
      "North Kingstown",
      "North Providence",
      "North Scituate",
      "North Smithfield",
      "Pawtucket",
      "Portsmouth",
      "Smithfield",
      "Wakefield-Peacedale",
      "Warren",
      "West Warwick",
      "Woonsocket",
      "Barrington",
      "Bristol",
      "Cumberland",
      "East Greenwich",
      "Johnston",
      "Providence",
      "Warwick",
      "Westerly"
    ],
    "South Carolina": [
      "Aiken",
      "Anderson",
      "Awendaw",
      "Bamberg",
      "Beaufort",
      "Berea",
      "Blythewood",
      "Boiling Springs",
      "Branchville",
      "Catawba",
      "Cayce",
      "Centerville",
      "Central",
      "Chesterfield",
      "Clinton",
      "Conway",
      "Dentsville",
      "Duncan",
      "Dunean",
      "Easley",
      "Elgin",
      "Five Forks",
      "Florence",
      "Folly Beach",
      "Forest Acres",
      "Forestbrook",
      "Fort Mill",
      "Gadsden",
      "Garden City",
      "Georgetown",
      "Greenville",
      "Greenwood",
      "Hanahan",
      "Hilton Head Island",
      "Inman Mills",
      "Irmo",
      "Isle of Palms",
      "Judson",
      "Kiawah Island",
      "Ladson",
      "Lake City",
      "Lake Wylie",
      "Lancaster",
      "Lexington",
      "Manning",
      "Mauldin",
      "Meggett",
      "Mount Pleasant",
      "Mullins",
      "Murrells Inlet",
      "North",
      "North Augusta",
      "North Charleston",
      "North Myrtle Beach",
      "Norway",
      "Port Royal",
      "Powdersville",
      "Prosperity",
      "Red Hill",
      "Ridgeland",
      "Roebuck",
      "Saint Andrews",
      "Sans Souci",
      "Seabrook Island",
      "Seneca",
      "Seven Oaks",
      "Shell Point",
      "Socastee",
      "Spartanburg",
      "Sullivans Island",
      "Sumter",
      "Surfside Beach",
      "Taylors",
      "Tigerville",
      "Timmonsville",
      "Travelers Rest",
      "Wade Hampton",
      "Ware Shoals",
      "Warrenville",
      "West Columbia",
      "Woodfield",
      "Yemassee",
      "York",
      "Bluffton",
      "Charleston",
      "Clemson",
      "Columbia",
      "Gaffney",
      "Goose Creek",
      "Greer",
      "Myrtle Beach",
      "Newberry",
      "Orangeburg",
      "Rock Hill",
      "Simpsonville",
      "Summerville"
    ],
    "South Dakota": [
      "Aberdeen",
      "Box Elder",
      "Brookings",
      "Custer",
      "Faith",
      "Huron",
      "Interior",
      "North Spearfish",
      "Onida",
      "Philip",
      "Rapid City",
      "Sioux Falls",
      "Spearfish",
      "Sturgis",
      "Vermillion",
      "Watertown",
      "Winner",
      "Yankton",
      "Mitchell",
      "Pierre"
    ],
    Tennessee: [
      "Arlington",
      "Athens",
      "Bartlett",
      "Belle Meade",
      "Blountville",
      "Bluff City",
      "Bon Aqua Junction",
      "Brentwood",
      "Brentwood Estates",
      "Bristol",
      "Charlotte",
      "Cleveland",
      "Clinton",
      "Collegedale",
      "Collierville",
      "Columbia",
      "Condon",
      "Cookeville",
      "Crossville",
      "Dyersburg",
      "East Brainerd",
      "East Chattanooga",
      "East Ridge",
      "Elizabethton",
      "Fairfield Glade",
      "Fairview",
      "Fall Branch",
      "Farragut",
      "Gallatin",
      "Gatlinburg",
      "Germantown",
      "Goodlettsville",
      "Greenbrier",
      "Greeneville",
      "Harrison",
      "Henderson",
      "Henry",
      "Jackson",
      "Jefferson City",
      "Johnson City",
      "Jonesborough",
      "Kingsport",
      "Kingston",
      "Lakeland",
      "Lakewood",
      "La Vergne",
      "Lawrenceburg",
      "Lebanon",
      "Lenoir City",
      "Loudon",
      "Louisville",
      "Manchester",
      "Martin",
      "McKenzie",
      "McMinnville",
      "Milan",
      "Millersville",
      "Millington",
      "Mohawk",
      "Monteagle",
      "Mount Juliet",
      "Mount Pleasant",
      "New South Memphis",
      "Nolensville",
      "Oak Hill",
      "Oak Ridge",
      "Oliver Springs",
      "Olivet",
      "Paris",
      "Pigeon Forge",
      "Portland",
      "Red Bank",
      "Roan Mountain",
      "Rural Hill",
      "Seymour",
      "Signal Mountain",
      "Smyrna",
      "Spencer",
      "Spring City",
      "Springfield",
      "Spring Hill",
      "Tullahoma",
      "Union City",
      "Unionville",
      "Vonore",
      "Walnut Hill",
      "Watertown",
      "White Bluff",
      "White House",
      "Whiteville",
      "Wildwood",
      "Chattanooga",
      "Clarksville",
      "Dickson",
      "Franklin",
      "Hendersonville",
      "Knoxville",
      "Lewisburg",
      "Maryville",
      "Memphis",
      "Morristown",
      "Murfreesboro",
      "Nashville",
      "Sevierville",
      "Shelbyville"
    ],
    Texas: [
      "Addison",
      "Alamo",
      "Alamo Heights",
      "Aldine",
      "Aledo",
      "Alton North (historical)",
      "Alvarado",
      "Alvord",
      "Anderson Mill",
      "Angleton",
      "Argyle",
      "Art",
      "Atascocita",
      "Athens",
      "Aubrey",
      "Azle",
      "Balch Springs",
      "Balcones Heights",
      "Barton Creek",
      "Bartonville",
      "Bay City",
      "Bayou Vista",
      "Bedford",
      "Bee Cave",
      "Beeville",
      "Bellmead",
      "Belton",
      "Benbrook",
      "Beverly",
      "Beverly Hills",
      "Blanco",
      "Blue Mound",
      "Boerne",
      "Bonham",
      "Borger",
      "Brenham",
      "Briarcliff",
      "Bridge City",
      "Brookshire",
      "Brookside Village",
      "Brownsville",
      "Brownwood",
      "Brushy Creek",
      "Bullard",
      "Bulverde",
      "Bunker Hill Village",
      "Burkburnett",
      "Burleson",
      "Cameron Park",
      "Canadian",
      "Canyon Lake",
      "Carrizo Springs",
      "Cedar Hill",
      "Center",
      "Chandler",
      "Channelview",
      "China",
      "Cibolo",
      "Cinco Ranch",
      "Citrus City",
      "Cleburne",
      "Clute",
      "Cockrell Hill",
      "College Station",
      "Colleyville",
      "Commerce",
      "Conroe",
      "Cooper",
      "Coppell",
      "Copperas Cove",
      "Corsicana",
      "Crosby",
      "Cross Mountain",
      "Cypress",
      "Dalworthington Gardens",
      "Decatur",
      "Deer Park",
      "Del Rio",
      "DeSoto",
      "Dickinson",
      "Dripping Springs",
      "Dublin",
      "Dumas",
      "Duncanville",
      "Eagle Mountain",
      "Eagle Pass",
      "Earth",
      "Edgecliff Village",
      "Edgewood",
      "Edinburg",
      "El Campo",
      "Elmendorf",
      "Elsa",
      "Emory",
      "Ennis",
      "Euless",
      "Everman",
      "Fairview",
      "Farmers Branch",
      "Farmersville",
      "Ferris",
      "Fifth Street",
      "Forest Hill",
      "Forney",
      "Fort Bliss",
      "Fort Hood",
      "Franklin",
      "Fredericksburg",
      "Freeport",
      "Fresno",
      "Friendswood",
      "Fulshear",
      "Galena Park",
      "Garden Ridge",
      "Garfield",
      "Gatesville",
      "Glen Rose",
      "Grand Prairie",
      "Greatwood",
      "Greenville",
      "Groesbeck",
      "Groves",
      "Haltom City",
      "Hamilton",
      "Happy",
      "Harker Heights",
      "Haskell",
      "Hawkins",
      "Hedwig Village",
      "Helotes",
      "Henderson",
      "Hideaway",
      "Highland Park",
      "Highland Village",
      "Hitchcock",
      "Hollywood Park",
      "Homestead Meadows North",
      "Horizon City",
      "Hornsby Bend",
      "Horseshoe Bay",
      "Hudson",
      "Hudson Bend",
      "Hunters Creek Village",
      "Huntsville",
      "Hurst",
      "Hutto",
      "Irving",
      "Italy",
      "Jacksboro",
      "Jacksonville",
      "Jersey Village",
      "Johnson City",
      "Jollyville",
      "Jonestown",
      "Justin",
      "Kemah",
      "Kerens",
      "Killeen",
      "Kingsville",
      "Krum",
      "Lackland Air Force Base",
      "Lago Vista",
      "La Grange",
      "Lake Brownwood",
      "Lake Dallas",
      "Lake Dunlap",
      "Lake Jackson",
      "Lakeway",
      "La Marque",
      "Lancaster",
      "La Porte",
      "Lavaca",
      "Leakey",
      "Leander",
      "Leonard",
      "Leon Valley",
      "Levelland",
      "Lewisville",
      "Lindsay",
      "Little Elm",
      "Little River-Academy",
      "Live Oak",
      "Livingston",
      "Lockhart",
      "Longview",
      "Lufkin",
      "Lumberton",
      "Magnolia",
      "Manchaca",
      "Mansfield",
      "Marfa",
      "Marion",
      "McKinney",
      "Meadows Place",
      "Melissa",
      "Menard",
      "Mercedes",
      "Mesquite",
      "Midlothian",
      "Mineral Wells",
      "Mission Bend",
      "Missouri City",
      "Monahans",
      "Mont Belvieu",
      "Mount Pleasant",
      "Muleshoe",
      "Nacogdoches",
      "Nassau Bay",
      "New Braunfels",
      "Northcrest",
      "North Richland Hills",
      "Nurillo",
      "Oak Cliff Place",
      "Oak Ridge North",
      "Odessa",
      "Olmos Park",
      "Onion Creek",
      "Orange",
      "Orange Grove",
      "Oyster Creek",
      "Palmhurst",
      "Palm Valley",
      "Palo Pinto",
      "Pampa",
      "Panorama Village",
      "Pantego",
      "Pecan Grove",
      "Pecos",
      "Pflugerville",
      "Pharr",
      "Piney Point Village",
      "Point",
      "Port Aransas",
      "Port Arthur",
      "Port Neches",
      "Port O'Connor",
      "Presidio",
      "Queen City",
      "Quinlan",
      "Quitman",
      "Ransom Canyon",
      "Raymondville",
      "Redland",
      "Red Oak",
      "Refugio",
      "Rendon",
      "Richland Hills",
      "Richmond",
      "Rio Grande City",
      "River Oaks",
      "Robstown",
      "Rockwall",
      "Rollingwood",
      "Rosenberg",
      "Rosharon",
      "Rowlett",
      "Royse City",
      "Runaway Bay",
      "Sachse",
      "Salado",
      "Sam Rayburn",
      "San Angelo",
      "Sanger",
      "San Juan",
      "San Marcos",
      "Santa Fe",
      "Schertz",
      "Seagoville",
      "Sealy",
      "Seguin",
      "Seven Points",
      "Shady Hollow",
      "Shamrock",
      "Shavano Park",
      "Shenandoah",
      "Socorro",
      "South Houston",
      "South Padre Island",
      "Southside Place",
      "Sparks",
      "Splendora",
      "Spring Valley",
      "Stafford",
      "Sugar Land",
      "Sweetwater",
      "Tahoka",
      "Taylor",
      "Teague",
      "Terrell",
      "Terrell Hills",
      "Texarkana",
      "Texas City",
      "The Colony",
      "The Hills",
      "The Woodlands",
      "Timberwood Park",
      "Tomball",
      "Tornillo",
      "Trinidad",
      "Trinity",
      "Universal City",
      "University Park",
      "Uvalde",
      "Valley Mills",
      "Vanderbilt",
      "Vega",
      "Vernon",
      "Victoria",
      "Vidor",
      "Watauga",
      "Waxahachie",
      "Webster",
      "Wells Branch",
      "West Lake Hills",
      "West University Place",
      "Westworth",
      "Whitehouse",
      "White Settlement",
      "Wimberley",
      "Windcrest",
      "Winnsboro",
      "Winters",
      "Wolfforth",
      "Woodcreek",
      "Woodway",
      "Wortham",
      "Wyldwood",
      "Wylie",
      "Abilene",
      "Alice",
      "Alief",
      "Allen",
      "Alvin",
      "Amarillo",
      "Andrews",
      "Anna",
      "Arlington",
      "Austin",
      "Baytown",
      "Beaumont",
      "Bellaire",
      "Bryan",
      "Buda",
      "Canyon",
      "Carrollton",
      "Cedar Park",
      "Converse",
      "Corpus Christi",
      "Crowley",
      "Dallas",
      "Denison",
      "Denton",
      "Donna",
      "El Paso",
      "Flower Mound",
      "Fort Worth",
      "Frisco",
      "Gainesville",
      "Galveston",
      "Garland",
      "Georgetown",
      "Grapevine",
      "Harlingen",
      "Hewitt",
      "Houston",
      "Humble",
      "Katy",
      "Keller",
      "Kerrville",
      "Kilgore",
      "Kingwood",
      "Kyle",
      "Laredo",
      "League City",
      "Lubbock",
      "Marshall",
      "McAllen",
      "Midland",
      "Mission",
      "Nederland",
      "Palestine",
      "Paris",
      "Pasadena",
      "Pearland",
      "Plainview",
      "Plano",
      "Prosper",
      "Richardson",
      "Rockport",
      "Round Rock",
      "San Antonio",
      "San Benito",
      "Seabrook",
      "Sherman",
      "Snyder",
      "Southlake",
      "Spring",
      "Stephenville",
      "Sulphur Springs",
      "Temple",
      "Tyler",
      "Waco",
      "Weatherford",
      "Weslaco",
      "Wichita Falls"
    ],
    Utah: [
      "Alpine",
      "Beaver",
      "Benjamin",
      "Blanding",
      "Bluebell",
      "Bluffdale",
      "Bountiful",
      "Brigham City",
      "Canyon Rim",
      "Cedar City",
      "Cedar Hills",
      "Centerfield",
      "Centerville",
      "Clearfield",
      "Clinton",
      "Cottonwood Heights",
      "Delta",
      "Eagle Mountain",
      "East Carbon City",
      "East Millcreek",
      "Erda",
      "Fairview",
      "Farmington",
      "Ferron",
      "Francis",
      "Granite",
      "Grantsville",
      "Heber",
      "Hildale",
      "Hill Air Force Bace",
      "Holladay",
      "Honeyville",
      "Ivins",
      "Junction",
      "Kamas",
      "Kanab",
      "Kearns",
      "LaVerkin",
      "Little Cottonwood Creek Valley",
      "Loa",
      "Manila",
      "Manti",
      "Marriott-Slaterville",
      "Midvale",
      "Midway",
      "Millcreek",
      "Moab",
      "Monticello",
      "Mount Olympus",
      "Mount Pleasant",
      "Murray",
      "North Ogden",
      "North Salt Lake",
      "Oakley",
      "Orangeville",
      "Panguitch",
      "Park City",
      "Perry",
      "Pleasant Grove",
      "Price",
      "Richfield",
      "Saint George",
      "Salem",
      "Sandy City",
      "Santa Clara",
      "Santaquin",
      "Saratoga Springs",
      "Smithfield",
      "Snyderville",
      "South Jordan Heights",
      "South Ogden",
      "South Salt Lake",
      "Spanish Fork",
      "Springville",
      "Summit Park",
      "Sunset",
      "Syracuse",
      "Tooele",
      "Uintah",
      "Vernal",
      "Virgin",
      "Washington",
      "Wendover",
      "West Bountiful",
      "West Haven",
      "West Jordan",
      "West Point",
      "West Valley City",
      "White City",
      "Willard",
      "Wolf Creek",
      "Woods Cross",
      "American Fork",
      "Draper",
      "Herriman",
      "Hurricane",
      "Kaysville",
      "Layton",
      "Lehi",
      "Lindon",
      "Logan",
      "Magna",
      "Ogden",
      "Orem",
      "Payson",
      "Provo",
      "Riverton",
      "Roy",
      "Salt Lake City",
      "Sandy",
      "South Jordan",
      "Taylorsville"
    ],
    Vermont: [
      "Barre",
      "Bellows Falls",
      "Brandon",
      "Bristol",
      "Burlington",
      "Chester",
      "Colchester",
      "Dover",
      "Essex Junction",
      "Fairfield",
      "Hardwick",
      "Jericho",
      "Johnson",
      "Lyndonville",
      "Manchester Center",
      "Middlebury (village)",
      "Montpelier",
      "Morristown",
      "North Bennington",
      "Poultney",
      "Randolph",
      "Rutland",
      "Swanton",
      "Vergennes",
      "Waterbury",
      "Wilder",
      "Williston",
      "Windsor",
      "Winooski",
      "Woodstock",
      "South Burlington"
    ],
    Virginia: [
      "Alexandria",
      "Amherst",
      "Annandale",
      "Arlington",
      "Ashburn",
      "Baileys Crossroads",
      "Big Stone Gap",
      "Blacksburg",
      "Bowling Green",
      "Bracey",
      "Brandermill",
      "Bristol",
      "Bull Run Mountain Estates",
      "Cape Charles",
      "Centreville",
      "Christiansburg",
      "Colonial Heights",
      "Culpeper",
      "Danville",
      "East Hampton",
      "Elkton",
      "Fairfax Station",
      "Forest",
      "Fort Belvoir",
      "Fort Hunt",
      "Fort Lee",
      "Fries",
      "Front Royal",
      "Gainesville",
      "Glen Allen",
      "Great Falls",
      "Greenbriar",
      "Harrisonburg",
      "Herndon",
      "Hollins",
      "Hollymead",
      "Hopewell",
      "Kings Park",
      "Lakeside",
      "Leesburg",
      "Lexington",
      "Lorton",
      "Louisa",
      "Luray",
      "Manassas Park",
      "Marshall",
      "Martinsville",
      "Matoaca",
      "McLean",
      "Merrifield",
      "Moon",
      "Nellysford",
      "North Shore",
      "Orange",
      "Painter",
      "Patrick Springs",
      "Pilot",
      "Poquoson",
      "Portsmouth",
      "Portsmouth Heights",
      "Potomac Mills",
      "Salem",
      "Short Pump",
      "Springfield",
      "Stanley",
      "Staunton",
      "Stuart",
      "Sugarland Run",
      "Tysons Corner",
      "University Center",
      "Vienna",
      "Vinton",
      "West Lynchburg",
      "Winchester",
      "Burke",
      "Chantilly",
      "Charlottesville",
      "Chesapeake",
      "Fairfax",
      "Falls Church",
      "Fredericksburg",
      "Hampton",
      "Lynchburg",
      "Manassas",
      "Mechanicsville",
      "Newport News",
      "Norfolk",
      "Oakton",
      "Petersburg",
      "Radford",
      "Reston",
      "Richmond",
      "Roanoke",
      "Sterling",
      "Suffolk",
      "Virginia Beach",
      "Waynesboro",
      "Williamsburg"
    ],
    Washington: [
      "Ames Lake",
      "Anacortes",
      "Arlington",
      "Bainbridge Island",
      "Battle Ground",
      "Bonney Lake",
      "Bothell",
      "Bryn Mawr-Skyway",
      "Buckley",
      "Burien",
      "Camano",
      "Camas",
      "Cashmere",
      "Centralia",
      "Chelan",
      "Cheney",
      "City of Sammamish",
      "Clearview",
      "Cle Elum",
      "Coupeville",
      "Darrington",
      "East Hill-Meridian",
      "East Wenatchee",
      "Eatonville",
      "Enumclaw",
      "Fall City",
      "Federal Way",
      "Ferndale",
      "Forks",
      "Friday Harbor",
      "Gig Harbor",
      "Gold Bar",
      "Goldendale",
      "Granite Falls",
      "Issaquah",
      "Kenmore",
      "Lake Stevens",
      "Lakewood",
      "Leavenworth",
      "Lofall",
      "Long Beach",
      "Longview",
      "Lynden",
      "Lynnwood",
      "Maple Valley",
      "Meadowdale",
      "Mercer Island",
      "Mill Creek",
      "Mill Plain",
      "Millwood",
      "Monroe",
      "Morton",
      "Moses Lake",
      "Mountlake Terrace",
      "Mount Vernon",
      "Mukilteo",
      "Navy Yard City",
      "Newcastle",
      "Newport",
      "Oak Harbor",
      "Opportunity",
      "Orchards",
      "Parkland",
      "Point Roberts",
      "Port Angeles",
      "Port Ludlow",
      "Port Orchard",
      "Port Townsend",
      "Poulsbo",
      "Pullman",
      "Quincy",
      "Ravensdale",
      "Redmond",
      "SeaTac",
      "Shelton",
      "Shoreline",
      "Snohomish",
      "Snoqualmie",
      "South Hill",
      "Sumas",
      "Tanner",
      "Tukwila",
      "Tulalip",
      "Tumwater",
      "University Place",
      "Vancouver",
      "Vashon",
      "Walnut Grove",
      "Washougal",
      "Wenatchee",
      "West Richland",
      "White Center",
      "White Salmon",
      "Woodland",
      "Auburn",
      "Bellevue",
      "Bellingham",
      "Bremerton",
      "Edmonds",
      "Ellensburg",
      "Everett",
      "Graham",
      "Kelso",
      "Kennewick",
      "Kent",
      "Kirkland",
      "Lacey",
      "Marysville",
      "Olympia",
      "Puyallup",
      "Renton",
      "Richland",
      "Sammamish",
      "Seattle",
      "Silverdale",
      "Spanaway",
      "Spokane",
      "Sunnyside",
      "Tacoma",
      "Walla Walla",
      "Woodinville",
      "Yakima"
    ],
    "West Virginia": [
      "Beckley",
      "Bluefield",
      "Bolivar",
      "Charleston",
      "Clarksburg",
      "Fairmont",
      "Friendly",
      "Hometown",
      "Ireland",
      "Junior",
      "Marlinton",
      "McMechen",
      "Parkersburg",
      "Reader",
      "Rio",
      "Star City",
      "Victor",
      "Weirton",
      "Weston",
      "Wheeling",
      "White Sulphur Springs",
      "Williamson",
      "Huntington",
      "Martinsburg",
      "Morgantown"
    ],
    Wisconsin: [
      "Appleton",
      "Ashwaubenon",
      "Baraboo",
      "Beaver Dam",
      "Belgium",
      "Beloit",
      "Brillion",
      "Brookfield",
      "Brown Deer",
      "Burlington",
      "Cadott",
      "Caledonia",
      "Cedarburg",
      "Chippewa Falls",
      "Crandon",
      "Cudahy",
      "De Pere",
      "Eagle River",
      "Eau Claire",
      "Elkhorn",
      "Fort Atkinson",
      "Franklin",
      "Germantown",
      "Grafton",
      "Greendale",
      "Green Lake",
      "Hartford",
      "Howard",
      "Hudson",
      "Janesville",
      "Kaukauna",
      "Kimberly",
      "Kohler",
      "Lake Delton",
      "Lake Geneva",
      "Manitowoc",
      "Marinette",
      "Menasha",
      "Menomonee Falls",
      "Menomonie",
      "Mequon",
      "Middleton",
      "Monroe",
      "Muskego",
      "Neenah",
      "New Berlin",
      "Oak Creek",
      "Oconomowoc",
      "Onalaska",
      "Platteville",
      "Pleasant Prairie",
      "Plover",
      "Plymouth",
      "Portage",
      "Port Washington",
      "Poynette",
      "Richfield",
      "River Falls",
      "Saint Croix Falls",
      "Salem",
      "Scandinavia",
      "Shorewood",
      "Slinger",
      "Somerset",
      "South Milwaukee",
      "Sparta",
      "Stevens Point",
      "Stoughton",
      "Suamico",
      "Sun Prairie",
      "Two Rivers",
      "Verona",
      "Waterloo",
      "Waukesha",
      "Waunakee",
      "Waupun",
      "Wausau",
      "Whiting",
      "Wisconsin Rapids",
      "Wrightstown",
      "Green Bay",
      "Kenosha",
      "La Crosse",
      "Madison",
      "Marshfield",
      "Milwaukee",
      "Oshkosh",
      "Racine",
      "Sheboygan",
      "Superior",
      "Sussex",
      "West Bend",
      "Whitewater"
    ],
    Wyoming: [
      "Cody",
      "Douglas",
      "Gillette",
      "Green River",
      "Hoback",
      "Jackson",
      "Lander",
      "Midwest",
      "Mills",
      "Mountain View",
      "Pinedale",
      "Powell",
      "Riverton",
      "Rock Springs",
      "Saratoga",
      "Story",
      "Wilson",
      "Worland",
      "Casper",
      "Cheyenne",
      "Laramie",
      "Moose Wilson Road",
      "Sheridan"
    ]
  },
  Uruguay: {
    "Departamento de Flores": ["Trinidad"],
    "Departamento de Rio Negro": ["Fray Bentos"],
    Durazno: ["Durazno"],
    Florida: ["Florida"],
    Rivera: ["Rivera"],
    Rocha: ["Castillos", "Rocha"],
    Salto: ["Salto"],
    Soriano: ["Dolores", "Mercedes"],
    "Treinta y Tres": ["Treinta y Tres"],
    Canelones: [
      "Barra de Carrasco",
      "Canelones",
      "Colonia Nicolich",
      "La Paz",
      "Las Toscas",
      "Pando",
      "Paso de Carrasco",
      "Progreso",
      "San Antonio",
      "Las Piedras"
    ],
    "Cerro Largo": ["Isidoro Noblia", "Rio Branco", "Melo"],
    Colonia: ["Colonia del Sacramento", "Nueva Helvecia", "Carmelo"],
    "Departamento de Montevideo": ["Montevideo"],
    "Departamento de Paysandu": ["Paysandú"],
    "Departamento de Rivera": ["Rivera"],
    "Departamento de Rocha": ["Chui", "Rocha"],
    "Departamento de Salto": ["Salto"],
    Lavalleja: ["Minas"],
    Maldonado: ["Pan de Azucar", "Punta del Este", "San Carlos", "Maldonado"],
    Montevideo: ["Montevideo"]
  },
  Uzbekistan: {
    Andijan: ["Marhamat"],
    Bukhara: ["Bukhara"],
    "Jizzakh Province": ["Jizzakh"],
    Jizzax: ["Gagarin", "Jizzax"],
    Namangan: ["Namangan"],
    Navoiy: ["Nurota"],
    "Navoiy Province": ["Navoiy"],
    Sirdaryo: ["Guliston"],
    Toshkent: ["Kyzyldzhar", "Parkent", "Salor"],
    Xorazm: ["Khiwa"],
    "Xorazm Viloyati": ["Gurlan", "Khiva"],
    Fergana: ["Oltiariq", "Fergana"],
    Karakalpakstan: ["Nukus"],
    "Samarqand Viloyati": ["Daxbet", "Samarqand", "Samarkand"],
    "Toshkent Shahri": ["Bektemir", "Tashkent"]
  },
  Vanuatu: {
    Malampa: ["Lakatoro"],
    Shefa: ["Port-Vila"],
    "Shefa Province": ["Port Vila"]
  },
  Venezuela: {
    Amazonas: ["La Esmeralda", "Puerto Ayacucho"],
    Apure: [
      "Biruaca",
      "Guasdualito",
      "San Fernando Apure",
      "San Fernando de Apure"
    ],
    Trujillo: ["La Quebrada", "Monte Carmelo", "Trujillo", "Valera"],
    Anzoátegui: [
      "Anaco",
      "Barcelona",
      "Boca de Uchire",
      "Cantaura",
      "El Tigre",
      "Lecherias",
      "Puerto La Cruz"
    ],
    Aragua: [
      "El Consejo",
      "La Victoria",
      "Ocumare de la Costa",
      "Palo Negro",
      "San Mateo",
      "Santa Cruz",
      "Santa Rita",
      "Turmero",
      "Villa de Cura",
      "Cagua",
      "Maracay"
    ],
    Barinas: [
      "Alto Barinas",
      "Barinitas",
      "Santa Catalina",
      "Socopo",
      "Barinas"
    ],
    Bolívar: [
      "Guasipati",
      "Santa Elena de Uairen",
      "Upata",
      "Ciudad Bolívar",
      "Ciudad Guayana",
      "Maripa"
    ],
    Capital: ["Caricuao", "Caracas"],
    Carabobo: [
      "Guigue",
      "Los Guayos",
      "Mariara",
      "Montalban",
      "Moron",
      "Naguanagua",
      "San Diego",
      "San Joaquin",
      "Tacarigua",
      "Guacara",
      "Puerto Cabello",
      "Valencia"
    ],
    Cojedes: ["Tinaquillo", "San Carlos"],
    "Delta Amacuro": ["Tucupita"],
    "Estado Trujillo": ["Trujillo", "Valera"],
    Falcón: [
      "Chichiriviche",
      "Punto Fijo",
      "Santa Cruz de los Taques",
      "Tucacas",
      "Yaracal",
      "Coro"
    ],
    Guárico: [
      "Altagracia de Orituco",
      "Calabozo",
      "Las Mercedes",
      "San Juan de los Morros",
      "Valle de La Pascua",
      "Zaraza"
    ],
    Lara: ["Carora", "El Tocuyo", "Los Rastrojos", "Sanare", "Barquisimeto"],
    Mérida: ["Ejido", "Merida", "Mucuchies", "Mucumpiz", "Tabay", "Mérida"],
    Miranda: [
      "Baruta",
      "Carrizal",
      "Caucagua",
      "Charallave",
      "Cua",
      "El Cafetal",
      "El Hatillo",
      "Guatire",
      "Higuerote",
      "La Dolorita",
      "Los Dos Caminos",
      "Los Teques",
      "Mamporal",
      "Ocumare",
      "Ocumare del Tuy",
      "San Antonio de Los Altos",
      "Chacao",
      "Guarenas",
      "Petare",
      "Santa Teresa"
    ],
    Monagas: ["Maturín"],
    "Nueva Esparta": [
      "El Valle del Espiritu Santo",
      "Juan Griego",
      "La Asuncion",
      "La Plaza Paraguachi",
      "Pampatar",
      "Punta de Piedras",
      "San Juan Bautista",
      "Porlamar"
    ],
    Portuguesa: ["Araure", "Villa Bruzual", "Acarigua", "Guanare"],
    Sucre: ["Carúpano", "Cumaná"],
    Táchira: [
      "Coloncito",
      "La Fria",
      "La Grita",
      "Queniquea",
      "San Antonio del Táchira",
      "Tariba",
      "Rubio",
      "San Cristóbal"
    ],
    Vargas: ["Caraballeda", "Catia La Mar", "La Guaira", "Maiquetia"],
    Yaracuy: ["Chivacoa", "Nirgua", "Yaritagua", "San Felipe"],
    Zulia: [
      "Cabimas",
      "Ciudad Ojeda",
      "El Corozo",
      "El Toro",
      "Lagunillas",
      "La Villa del Rosario",
      "Machiques",
      "San Carlos del Zulia",
      "San Francisco",
      "Santa Rita",
      "Maracaibo"
    ]
  },
  Vietnam: {
    "An Giang": ["Long Xuyen"],
    "Ba Ria-Vung Tau": ["Con Son", "Vung Tau"],
    "Binh Dinh": ["Qui Nhon"],
    "Binh Thuan": ["Phan Thiet"],
    "Da Nang": [
      "Da Nang",
      "Hoa Vang",
      "Lien Chieu",
      "Ngu Hanh Son",
      "Son Tra",
      "Thanh Khe"
    ],
    "Gia Lai": ["Ia Kha", "Pleiku"],
    "Ha Giang": ["Thi Tran GJong Van"],
    "Hai Phong": ["Haiphong", "Nui Doi"],
    "Ha Noi": [
      "Cau Giay",
      "Dong Anh",
      "Dong Da",
      "Hai BaTrung",
      "Hanoi",
      "Hoan Kiem",
      "Soc Son",
      "Tay Ho",
      "Thanh Xuan"
    ],
    "Hoa Binh": ["Thi Tran Muong Khen"],
    "Khanh Hoa": ["Cam Lam", "Cam Ranh", "Nha Trang"],
    "Kien Giang": ["Duong GJong"],
    "Kon Tum": ["Kon Tum"],
    "Lam Dong": ["Da Lat"],
    "Lao Cai": ["Sa Pa"],
    "Long An": [],
    "Ninh Binh": ["Thanh Pho Ninh Binh", "Thi Tran Me"],
    "Quang Nam": ["Hoi An"],
    "Quang Ninh": ["Cam Pha", "Thanh Pho Ha Long"],
    "Thai Binh": ["Thanh Pho Thai Binh"],
    "Thanh Pho Can Tho": ["Can Tho"],
    "Thanh Pho GJa Nang": ["Da Nang"],
    "Thanh Pho Hai Phong": ["Haiphong"],
    "Tinh Bac Ninh": ["Bac Ninh"],
    "Tinh Ba Ria-Vung Tau": ["Vũng Tàu"],
    "Tinh Ben Tre": ["Bến Tre"],
    "Tinh Binh Thuan": ["Phan Thiết"],
    "Tinh Dien Bien": ["Điện Biên Phủ"],
    "Tinh GJong Nai": ["Bien Hoa"],
    "Tinh Khanh Hoa": ["Cam Ranh", "Nha Trang"],
    "Tinh Kien Giang": ["Rach Gia"],
    "Tinh Lam GJong": ["Da Lat"],
    "Tinh Nghe An": ["Vinh"],
    "Tinh Quang Binh": ["Dong Hoi"],
    "Tinh Quang Nam": ["Hoi An"],
    "Tinh Quang Ngai": ["Quảng Ngãi"],
    "Tinh Soc Trang": ["Soc Trang"],
    "Tinh Thua Thien-Hue": ["Huế"],
    "Tinh Tra Vinh": ["Tra Vinh"],
    "Tinh Vinh Long": ["Vĩnh Long"],
    "Ho Chi Minh City": [
      "Quan Ba",
      "Quan Bay",
      "Quan Binh Thanh",
      "Quan Bon",
      "Quan Chin",
      "Quan Hai",
      "Quan Mot",
      "Quan Muoi",
      "Quan Nam",
      "Quan Phu Nhuan",
      "Quan Tan Phu",
      "Ho Chi Minh City"
    ],
    "Thanh Pho Ha Noi": ["Thi Tran But Son", "Hanoi"]
  },
  "Virgin Islands, U.S.": {
    "Saint Croix Island": ["Saint Croix"],
    "Saint John Island": ["Cruz Bay"],
    "Saint Thomas Island": ["Charlotte Amalie"]
  },
  "Western Sahara": {
    "Oued Ed-Dahab-Lagouira": ["Dakhla", "Laayoune / El Aaiun"]
  },
  Yemen: {
    "Al Jawf": [],
    Ibb: ["Ibb"],
    Laḩij: [],
    "Ma’rib": ["Ma'rib"],
    "Muhafazat al Hudaydah": ["Al Hudaydah"],
    "Muhafazat Hadramawt": ["Al Mukalla", "Az Zali`ah"],
    Omran: [],
    "Şa‘dah": ["Baqim as Suq", "Sa'dah", "Suq al Khamis"],
    Sanaa: ["Sanaa"],
    Shabwah: ["Ataq"],
    "Ta‘izz": [],
    Aden: ["Al Mansurah", "Aden"]
  },
  Zambia: {
    Copperbelt: ["Chililabombwe", "Chingola", "Kitwe", "Ndola"],
    Eastern: ["Petauke"],
    Lusaka: ["Lusaka"],
    Muchinga: ["Nakonde"],
    Northern: ["Kasama", "Mpika", "Mpulungu"],
    Southern: ["Livingstone"],
    "Southern Province": ["Livingstone", "Mazabuka"],
    Western: ["Mongu"],
    "Western Province": ["Mongu"],
    "Lusaka Province": ["Lusaka"]
  },
  Zimbabwe: {
    Manicaland: ["Chipinge", "Mutare"],
    "Mashonaland West": ["Chinhoyi", "Kariba"],
    Masvingo: ["Chiredzi"],
    "Masvingo Province": ["Masvingo"],
    "Matabeleland South Province": ["Beitbridge"],
    Midlands: ["Kwekwe"],
    "Midlands Province": ["Gweru"],
    Bulawayo: ["Bulawayo"],
    Harare: ["Epworth", "Harare"],
    "Matabeleland North": ["Dete", "Hwange", "Victoria Falls"]
  }
};
export default INSTAGRAM_LOCATIONS;