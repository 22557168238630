/* eslint-disable no-empty-pattern */
import ajax from "../helpers";

import { EmailAccount } from "../../models/emailAccount";

const state = {
  integrations: [],
  emails: {},
  emailAccounts: [],
  notifications: {},
};

const getters = {};

const actions = {
  async checkShopifyIntegration({ dispatch }, payload) {
    try {
      const result = await ajax.post(
        `api/integrations/shopify/complete`,
        payload
      );
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async requestIntegration({ dispatch }, payload) {
    try {
      const result = await ajax.post(
        `/api/integrations/${encodeURIComponent(payload.site)}/request`,
        {
          url: payload.url,
        }
      );
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return { error: err };
    }
  },
  async integrateShopify({ dispatch }, payload) {
    try {
      const result = await ajax.post(`/api/integrations/shopify/custom_app`, payload);
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async getIntegrations({ commit, dispatch }) {
    try {
      const { data } = await ajax.get("/api/integrations");
      if (data) {
        commit("SET_INTEGRATIONS", data);
      }
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async removeIntegration({ commit, dispatch }, payload) {
    await ajax.del(`/api/integrations/${encodeURIComponent(payload)}`);
    commit("REMOVE_INTEGRATION", payload);
  },
  async getConnectedEmails({ commit }) {
    const { data } = await ajax.get("/api/email-integrations");
    // const data = {
    //   gmail: [
    //     {address: "michelelacchia@gmail.com", token_expired: true},
    //     {address: "gg@gmail.com"}
    //   ],
    //   outlook: [
    //     {address: "kk@hotmail.com", token_expired: true},
    //     {address: "gg@outlook.com"}
    //   ]
    // }
    commit("SET_EMAILS", data);
    return data;
  },
  async requestEmail({ dispatch }, payload) {
    try {
      const { data } = await ajax.post(
        `/api/email-integrations/${encodeURIComponent(payload.name)}/request`,
        payload
      );
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async disconnectEmail({ commit, dispatch }, payload) {
    try {
      const { data } = await ajax.del(
        `/api/email-integrations/${encodeURIComponent(
          payload.name
        )}/${payload.address}`
      );
      commit("REMOVE_EMAIL", payload);
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async getNotifications({ commit }) {
    const { data } = await ajax.get("/api/notifications");
    commit("SET_NOTIFICATIONS", data);
    return data;
  },
  async requestNotification({ }, payload) {
    const { data } = await ajax.post(
      `/api/notifications/${encodeURIComponent(payload.name)}/request`,
      payload
    );
    return data;
  },
  async removeNotification({ commit, dispatch }, payload) {
    try {
      const { data } = await ajax.del(`/api/notifications/${encodeURIComponent(payload)}`);
      commit("REMOVE_NOTIFICATION", payload);
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async addGmailIntegration({ dispatch }, payload) {
    const requestPayload = {
      name: payload.fromName,
      password: payload.password
    }

    try {
      const { data } = await ajax.post(
        `/api/email-integrations/${encodeURIComponent(payload.email)}/connect_gmail/`,
        requestPayload
      );
      return data;
    } catch (err) {
      dispatch("setAlert", {
        showAlert: true,
        content: "Can't connect your email account. Please contact support",
        alertClass: "danger"
      }, { root: true });
      throw err;
    }
  }
};
const mutations = {
  SET_INTEGRATIONS(state, payload) {
    state.integrations = payload;
  },
  REMOVE_INTEGRATION(state, payload) {
    state.integrations = state.integrations.filter(e => e.id !== payload);
  },
  SET_EMAILS(state, payload) {
    let resultEmailAccounts = [];
    for (var emailAccountType in payload) {
        const emailAccounts = payload[emailAccountType];

        resultEmailAccounts = resultEmailAccounts.concat(
          emailAccounts.map((e) => new EmailAccount(null, emailAccountType, e.address, null))
        );
    }
    state.emailAccounts = resultEmailAccounts;
    state.emails = payload;
  },
  REMOVE_EMAIL(state, payload) {
    const index = state.emails[payload.name].findIndex(
      (e) => e.address === payload.address
    );
    if (index > -1) {
      state.emails[payload.name].splice(index, 1);
    }
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload;
  },
  REMOVE_NOTIFICATION(state, payload) {
    delete state.notifications[payload];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
