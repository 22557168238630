const MESSAGES = {
  "common-500":
    "Internal server error! Something bad happened. Please contact support at support@ninjaoutreach.com",
  "common-404": "The link is broken or the page has been moved.",
  "common-error-200": "Ooops! Something went wrong. Please try again or contact support",

  "campaign__name-blank": "Please enter the name of the campaign.",
  "campaign__budget-blank": "Please enter the budget of the campaign.",
  "campaign__budget-invalid": "The budget should be a positive number.",
  "campaign__currency-blank": "Please choose the currency of the campaign.",
  "campaign__name-existing": "A campaign with that name already exists.",
  "campaign__create_campaign-failed":
    "The campaign could not be created. Please contact support at support@ninjaoutreach.com",
  "campaign__update_campaign-failed":
    "The campaign properties could not be saved. Please contact support at support@ninjaoutreach.com",
  "campaign__add_lead-failed":
    "Could not add the influencers to the campaign. Please contact support at support@ninjaoutreach.com",
  "campaign__create_campaign_add_lead-failed":
    "Could not add the influencers to the campaign. Please contact support at support@ninjaoutreach.com",
  "campaign__rename_stage-failed":
    "Could not rename the stage. Please contact support at support@ninjaoutreach.com",
  "campaign__reorder_stages-failed":
    "Could not reorder the stages. Please contact support at support@ninjaoutreach.com",
  "campaign__delete_stage-failed":
    "Could not remove the stage. Please contact support at support@ninjaoutreach.com",
  "campaign__add_stage-failed":
    "Could not add the stage. Please contact support at support@ninjaoutreach.com",
  "campaign__move_lead-failed":
    "Could not move the influencer. Please contact support at support@ninjaoutreach.com",
  "campaign__change_lead_stage-failed":
    "Could not move the influencer. Please contact support at support@ninjaoutreach.com",
  "campaign__get_campaigns-failed":
    "Could not fetch campaigns data. Please contact support at support@ninjaoutreach.com",
  "campaign__get_single_campaign-failed":
    "Could not fetch campaign data. Please contact support at support@ninjaoutreach.com",
  "campaign__get_leads_preview-failed":
    "Could not fetch influencers data. Please contact support at support@ninjaoutreach.com",
  "campaign__get_single_lead-failed":
    "Could not fetch influencer data. Please contact support at support@ninjaoutreach.com",
  "campaign__save_lead_note-failed":
    "Could not save the note. Please contact support at support@ninjaoutreach.com",
  "campaign__delete_lead_note-failed":
    "Could not remove the note. Please contact support at support@ninjaoutreach.com",
  "campaign__create_lead_content_piece-failed":
    "Could not register the link. Please contact support at support@ninjaoutreach.com",
  "campaign__delete_lead_content_piece-failed":
    "Could not delete the link. Please contact support at support@ninjaoutreach.com",
  "campaign__update_lead_content_piece-failed":
    "Could not update the link. Please contact support at support@ninjaoutreach.com",
  "campaign__duplicate_tracking_link": "It seems this link is already there. Please add a different one.",
  "campaign__create_campaign-success": "Campaign created.",
  "campaign__update_campaign-success": "Campaign properties updated.",
  "campaign__add_lead-success": "Influencer(s) added to the existing campaign.",
  "campaign__create_campaign_add_lead-success": "Influencer(s) added to new campaign.",
  "campaign__add_stage-success": "Stage added.",
  "campaign__move_lead-success": "Lead moved.",
  "campaign__change_lead_stage-success": "Lead stage changed.",
  "campaign__save_lead_note-success": "Note saved.",
  "campaign__delete_lead_note-success": "Note removed.",
  "campaign__create_lead_content_piece-success": "Link(s) added.",
  "campaign__delete_lead_content_piece-success": "Link removed.",
  "campaign__update_lead_content_piece-success": "Link updated.",
  "campaigns__max-active-reached":
    "You reached the maximum number of active campaigns! Please upgrade your plan.",
  "influencer__search-failed":
    "Search failed. Please contact support at support@ninjaoutreach.com",
  "influencer__get_categories-failed":
    "Could not fetch categories data. Please contact support at support@ninjaoutreach.com",
  "influencer__get_countries-failed":
    "Could not fetch countries data. Please contact support at support@ninjaoutreach.com",
  "influencer__get_regions-failed":
    "Could not fetch regions data. Please contact support at support@ninjaoutreach.com",
  "influencer__get_cities-failed":
    "Could not fetch cities data. Please contact support at support@ninjaoutreach.com",
  "influencer__get_single_profile-failed":
    "Could not fetch profile data. Please contact support at support@ninjaoutreach.com",
  "influencer__get_single_profile_highlights-failed":
    "Could not fetch media data. Please contact support at support@ninjaoutreach.com",
  "influencer__export_profiles-failed":
    "Exporting the profiles failed. Please contact support at support@ninjaoutreach.com",
  "influencer__get_export_price-failed":
    "Could not fetch the export price. Please contact support at support@ninjaoutreach.com",
  "influencer__export_profiles-success": "Influencers data exported successfully.",

  "user__email-blank": "Please enter your email address.",
  "user__email-invalid":
    "Oops, this email looks invalid. Please double check the address.",
  "user__invite-token-invalid":
    "This invite looks invalid. Please ask whoever invited you to send a new invite.",
  "user__email_popup-failed": "We need to open a new window to allow you to connect your email. Please make sure your browser allows that.",
  "user__store_popup-failed": "We need to open a new window to allow you to connect your store. Please make sure your browser allows that.",
  "user__popup-failed": "The app needs to open a new window to continue. Please make sure your browser allows that.",
  "user__email-existing":
    "Another user with this email already exists. Maybe it's your evil twin, Spooky.",
  "user__password-blank": "Please enter your password.",
  "user__password-invalid": "Your password must be at least 6 characters.",
  "user__password_confirm-blank": "Please confirm your password.",
  "user__password_confirm-no_match":
    "Your password and confirmation password do not match.",
  "user__email_or_password-invalid":
    "You have entered an invalid username or password.",
  "user__login-failed": "Sign-in failed.",
  "user__registration-failed": "Registration failed.",
  "user__forgot-email": "Your Email is not Exists in our system.",
  "user__authentication-failed": "Invalid credentials, or the account does not exist. Double-check the email and password.",
  "user__authentication-expired": "Authentication expired.",
  "user__get_currencies-failed": "Could not fetch the currencies list.",
  "user__no-credits": "Oops! Not enough credits.",
  "user__no-credits-after-free-quota": "Oops! Not enough credits.",
  "user__buying_credits-failed":
    "Credits purchase failed. Can you check your card details?",
  "user__get_billing_plans-failed": "Could not fetch the billing plans.",
  "user__get_credit_packages-failed": "Could not fetch the credit packages.",
  "user__charge-failed": "The charge did not succeed. Please double-check with your bank and ensure that our charge wasn't blocked.",
  "user__add-card-failed": "Unknown error while adding the payment method. Please retry again in a later moment.",
  "user__card_number-invalid": "Card number is required",
  "user__card_expiry-invalid": "Month is required",
  "user__card_cvc-invalid": "CVC is required",
  "user__stripe_v3-loading_error": "Stripe library could not be loaded!",
  "user__subscription-failed": "There was a problem upgrading your subscription, please contact support at support@ninjaoutreach.com",
  "user__buying_credits-success": "The credits have been added to your account.",
  "user__subscription-success": "Subscription upgraded successfully.",
  "user__login-success": "Sign-in succesful.",
  "user__registration-success": "Registration successful.",
  "user__remove_payment_method-success": "Removed successfully.",
  "user__update_password-missing": "No password provided.",
  "user__update_password-length": "Must have at least 4 characters.",
  "user__update_password-no-match": "The new passwords do not match.",
  "user__update_password-wrong": "Invalid credentials: double-check your current password.",
  "user__update_password-same": "The new passord is identical to the current one; try a new one.",
  "user__team-invite-success": "Invite sent!",
  "user__team-update-success": "The user was updated!",
  "user__team-email-not-valid": "This email does not look valid; try a different one.",
  "user__team-email-existing": "An account with this email already exists; try a different one.",
  "user__team-permissions-updated": "Permissions updated!",
  "user__permission-error": "This action requires {{permission}} to {{scope}}. Contact your team manager to update your permissions.",
  "user__permission_account-error": "Your account does not have {{permission}} to {{scope}}",

  "integration__get_integrations-failed": "Could not fetch the integrations, please contact support at support@ninjaoutreach.com",
  "integration__request-failed": "Could not fetch the integration, please contact support at support@ninjaoutreach.com",
  "integration__delete_integration-failed": "Could not remove the integration, please contact support at support@ninjaoutreach.com",
  "integration-request__invalid-url": "Oops, it looks like the URL you have inserted is not valid",
  "integration-shopify__no-read-discounts": "It looks like we do not have the read_discounts permission. Please double check and try again.",
  "integration-shopify__no-read-orders": "It looks like we do not have the read_orders permission. Please double check and try again.",
  "integration-shopify__malformed-url": "It looks like the URL is not valid. Please copy the Example URL from Shopify exactly.",

  "email__message-blocked": "{{email_provider}} blocked the message as it is considered SPAM.",
  "email__account-suspended": "Your account is suspended or not verified. Follow the instructions in your inbox to restore its functionality.",
  "email__recipient-limit": "{{email_provider}} blocked the message as you have reached the daily recipients limit.",
  "email__message-limit": "{{email_provider}} blocked the message as you have reached the daily messages limit.",
  "email__unknown-error": "{{email_provider}} failed with an unknown error.",
  "email__send_message-success": "Message sent successfuly.",
  "email__content-empty": "The body of the email message cannot be empty!",
  "email__limit_exceeded": "You have reached the maximum number of emails for your trial period. Please upgrade to paid subscription to continue.",
  "email__subject-empty": "The subject of the email message cannot be empty!",

  "email__bulk-send-messages-success": "Your messages sent successfuly!",

  "workspace__change_status-success": "Status changed successfuly.",

  "settings__user_update-success": "Your account was updated successfuly."
};

export default MESSAGES;
