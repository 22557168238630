import { MESSAGES, ACCOUNT_SCOPES, ACCOUNT_ACCESS } from "@/constants";

let setErrorAlert = ({ dispatch }, payload) => {

  console.log("SetErrorAlert raised");

  const error = payload.response;
  const preDefinedMsg = error.data && error.data.hasOwnProperty("user_message_id") ?
    error.data.user_message_id ?
      (error.data.metadata && error.data.metadata.message) || MESSAGES[error.data.user_message_id[1]] :
      error.data.message :
    "";
  if (preDefinedMsg) {
    dispatch("setAlert", {
      showAlert: true,
      content: preDefinedMsg,
      alertClass: "danger"
    });
    return;
  } 
  if (error.status === 401) {
    dispatch("setAlert", {
      showAlert: true,
      content: MESSAGES["user__authentication-failed"],
      alertClass: "danger"
    });
    dispatch("user/signOut");
  } else if (error.status === 402) {
    dispatch("setAlert", {
      showAlert: true,
      content: MESSAGES["user__no-credits"],
      alertClass: "danger"
    });
  } else if (error.status === 403) {
    const { scope } = error.metadata || {};
    
    const permissionStr = (Array.isArray(scope) ? scope[0] : scope) || "";
    const permissionArray = permissionStr.split(":");
    if (permissionArray.length > 1) {
      const readableScope = ACCOUNT_SCOPES.find(e => e.id === permissionArray[0]);
      const readablePermission = ACCOUNT_ACCESS.find(e => e.id === permissionArray[1]);

      const message = MESSAGES["user__permission-error"]
        .replace("{{permission}}", readablePermission && readablePermission.label)
        .replace("{{scope}}", readableScope && readableScope.label);
      dispatch("setAlert", {
        showAlert: true,
        content: message,
        alertClass: "danger"
      });
    }
  } else if (error.status === 500) {
    dispatch("setAlert", {
      showAlert: true,
      content: MESSAGES["common-500"],
      alertClass: "danger"
    });
  } else if (error.status === 400) {
    dispatch("setAlert", {
      showAlert: true,
      content: MESSAGES["common-400"],
      alertClass: "danger"
    });
  } else {
    dispatch("setAlert", {
      showAlert: true,
      content: error.data,
      alertClass: "danger"
    });
  }
};

let setAlert = ({ commit }, payload) => {
  commit("ALERT_CHANGE", payload);
  console.log("SetAlert raised");
  setTimeout(() => {
    commit("ALERT_HIDE");
  }, 1000);
};
export { setErrorAlert };
export { setAlert };
