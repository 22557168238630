import ajax from "../helpers";
import { SOCIALS } from "../../constants";

const state = {
  searchResult: {
    hits: [],
    total: {}
  },
  exportList: [],
  viewIndex: 0,
  social: null,

  shownProfile: null,

  filter: {
    social: SOCIALS.instagram.id,
    instagram: {
      sort_index: 2,
      engagement_rate_lte: null,
      engagement_rate_gte: null,
      followers_lte: null,
      followers_gte: null,
      category_and_or: true,
      hashtags_and_or: true,
      categories: [],
      hashtags: [],
      location_country: null,
      location_region: null,
      location_city: null,
      has_emails: null,
      is_verified: null,
      is_business: null,
      gender: 2, // 0 - female, 1 - male, 2 - any
      keyword: "",
      username: "",
    },
    tiktok: {
      sort_index: 3,
      avg_engagement_rate_lte: null,
      avg_engagement_rate_gte: null,
      fans_lte: null,
      fans_gte: null,
      country: null,
      language: null,
      keyword: "",
      searchType: "keyword",
      hashtags: [],
    },
    youtube: {
      sort_index: 2,
      avg_engagement_rate_lte: null,
      avg_engagement_rate_gte: null,
      subscribers_lte: null,
      subscribers_gte: null,
      videos_per_week_lte: null,
      videos_per_week_gte: null,
      has_emails: true,
      has_instagram: false,
      has_twitch: false,
      has_facebook: false,
      has_twitter: false,
      has_patreon: false,
      has_pinterest: false,
      country: null,
      keyword: "",
    },
    updated: null
  }
};

const getters = {
  getTikTokUrlQuery: state => {
    var tiktokFilter = state.filter.tiktok;

    /* inputed string */
    let query = `query=${tiktokFilter.keyword}`;

    /* engagement and fans */
    if (tiktokFilter.avg_engagement_rate_lte !== null && tiktokFilter.avg_engagement_rate_lte !== "") {
      query += `&avg_engagement_rate_lte=${tiktokFilter.avg_engagement_rate_lte / 100}`;
    }
    if (tiktokFilter.avg_engagement_rate_gte !== null && tiktokFilter.avg_engagement_rate_gte !== "") {
      query += `&avg_engagement_rate_gte=${tiktokFilter.avg_engagement_rate_gte / 100}`;
    }
    if (tiktokFilter.fans_lte !== null && tiktokFilter.fans_lte !== "") {
      query += `&fans_lte=${tiktokFilter.fans_lte}`;
    }
    if (tiktokFilter.fans_gte !== null && tiktokFilter.fans_gte !== "") {
      query += `&fans_gte=${tiktokFilter.fansGTE}`;
    }
    /* language */
    if (tiktokFilter.language) {
      let languages = [];
      tiktokFilter.language.forEach((l) => languages.push(l.code));
      languages = languages.join();
      query += `&language=${languages}`;
    }
    /* location */
    if (tiktokFilter.country) {
      query += `&country=${tiktokFilter.country}`;
    }

    /* hashtags */
    if (tiktokFilter.currentHashtags && tiktokFilter.currentHashtags.length) {
      const hashtagsList = tiktokFilter.currentHashtags.map((obj) =>
        encodeURIComponent(obj.hashtag)
      );
      query += `&hashtags_or=${hashtagsList.toString()}`;
    }
    return query;
  }
};

const actions = {
  async getSearchResult({ commit, dispatch, state }, payload) {
    try {
      const hasInstagramFilters = (state) => {
        return state.filter["instagram"].engagement_rate_lte != null ||
          state.filter["instagram"].engagement_rate_gte != null ||
          state.filter["instagram"].followers_gte != null ||
          state.filter["instagram"].followers_lte != null ||
          state.filter["instagram"].location_country != null ||
          state.filter["instagram"].location_region != null ||
          state.filter["instagram"].location_city != null ||
          state.filter["instagram"].is_verified != null ||
          state.filter["instagram"].is_business != null ||
          state.filter["instagram"].keyword != "" ||
          state.filter["instagram"].categories.length != 0 ||
          state.filter["instagram"].hashtags.length != 0 ||
          state.filter["instagram"].has_emails != null;
      }

      const { data } = await ajax.get(`/api/influencers/${payload.social}/search?${payload.query}`);
      if (data.hits) {
        if (payload.social == "instagram" && !hasInstagramFilters(state))
          data.total.value += 6000000;

        commit(payload.is_new ? "SET_SEARCH_RESULT" : "ADD_SEARCH_RESULT", data);

        return data;
      } else {
        dispatch("setErrorAlert", data, { root: true });
        return null;
      }
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async getHashtagsList({ dispatch }, payload) {
    try {
      const { social, prefix } = payload;
      const { data } = await ajax.get(`/api/influencers/${social}/search/hashtags/${encodeURIComponent(prefix)}`);
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async getCategoriesList({ dispatch }, payload) {
    try {
      const { data } = await ajax.get(`/api/influencers/${payload.social}/search/categories`);
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async getSingleProfile({ dispatch, commit }, payload) {
    try {
      const uri = payload.social === SOCIALS.youtube.id ?
        `/api/influencers/${payload.social}/channel/${encodeURIComponent(payload.id)}` :
        `/api/influencers/${payload.social}/profile/${encodeURIComponent(payload.username)}`;
      const result = await ajax.get(uri);
      if (payload.is_search) {
        commit("SET_SEARCH_RESULT", {
          hits: [
            payload.social === SOCIALS.youtube.id ?
              result.data :
              result.data.profile
          ],
          total: {
            value: 1
          },
          took: payload.social === SOCIALS.youtube.id ?
            undefined : result.data.similar.took
        });
      }
      return result.data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async getSingleProfileHighlights({ dispatch }, payload) {
    try {
      const { data } = await ajax.get(
        `/api/influencers/${payload.social}/profile/${encodeURIComponent(payload.username)}/highlights`
      );
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getMetrics({ dispatch }, payload) {
    try {
      const { data } = await ajax.get(
        `/api/influencers/${payload.social}/profile/${encodeURIComponent(payload.username)}/metrics`
      );
      return data;
    } catch (err) {
      // dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async getAudience({ commit, dispatch }, payload) {
    try {
      const uri = payload.social === SOCIALS.youtube.id ?
        `/api/influencers/${payload.social}/channel/${encodeURIComponent(payload.id)}/audience` :
        `/api/influencers/${payload.social}/profile/${encodeURIComponent(payload.username)}/audience`;
      const result = await ajax.get(uri);

      commit("SET_AUDIENCE", result.data);
      return result.data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }

  },
  async getExportPrice({ dispatch }, payload) {
    try {
      const result = await ajax.post(
        "/api/influencers/export_price",
        payload
      );
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async exportProfiles({ dispatch }, payload) {
    try {
      const result = await ajax.post("/api/influencers/export", payload);
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  setSocial({ commit }, payload) {
    commit("SET_SOCIAL", payload)
  },
  setFilterSocial({ commit }, payload) {
    commit("SET_FILTER_SOCIAL", payload)
  },
  setFilterValue({ commit }, payload) {
    commit("SET_FILTER_VALUE", payload)
  },
  setViewIndex({ commit }, payload) {
    commit("SET_INFLUENCER_VIEW_INDEX", payload);
  },
  setSortIndex({ commit }, payload) {
    commit("SET_INFLUENCER_SORT_INDEX", payload);
  },
  setExportList({ commit }, payload) {
    commit("SET_EXPORT_LIST", payload);
  },
  addProfileToExportList({ commit }, payload) {
    commit("ADD_PROFILE_TO_EXPORT_LIST", payload);
  },
  removeProfileToExportList({ commit }, payload) {
    commit("REMOVE_PROFILE_TO_EXPORT_LIST", payload);
  },
  cleartSearchResult({ commit }) {
    commit("CLEAR_SEARCH_RESULT");
  },
  clearExportList({ commit }) {
    commit("CLEAR_EXPORT_LIST");
  },
  showProfile({ commit }, data) {
    commit("SET_SHOWN_PROFILE", data);
  },
  hideProfile({ commit }) {
    commit("SET_SHOWN_PROFILE", null);
  }
};

const mutations = {
  SET_SEARCH_RESULT(state, result) {
    result.hits.forEach(profile => {
      if (profile.business_email) {
        console.log(`Profile ${profile.username} has business email ${profile.business_email}`, profile.emails, profile.business_email);
      }
    })

    state.searchResult = result;
    state.searchResult.page = 1;
  },
  ADD_SEARCH_RESULT(state, result) {
    // todo: sh.sergey - it was commented because incrementing page also placed in the component.
    // It should be revised and moved from component to state, I think. For now it's easier just
    // to comment this place as less involved.
    //state.searchResult.page++;

    result.hits.forEach(profile => {
      if (profile.business_email) {
        console.log(`Profile ${profile.username} has business email ${profile.business_email}`, profile.emails, profile.business_email);
      }
    })


    state.searchResult.took = result.took;
    state.searchResult.total.value = result.total.value;
    result.hits.forEach(profile => {
      state.searchResult.hits.push(profile);
    });
  },
  SET_SOCIAL(state, payload) {
    state.social = payload;
  },
  SET_FILTER_SOCIAL(state, payload) {
    state.filter.social = payload;
  },
  SET_FILTER_VALUE(state, payload) {
    state.filter[payload.social][payload.name] = payload.value;
  },
  SET_INFLUENCER_VIEW_INDEX(state, payload) {
    state.viewIndex = payload;
  },
  SET_INFLUENCER_SORT_INDEX(state, payload) {
    state.filter[payload.social].sort_index = payload.sort_index;
  },
  SET_EXPORT_LIST(state, payload) {
    state.exportList = payload;
  },
  ADD_PROFILE_TO_EXPORT_LIST(state, payload) {
    const payloadId = payload.social === SOCIALS.youtube.id ? payload.id : payload.username;
    const index = state.exportList
      .map(profile => {
        const profileId = profile.social === SOCIALS.youtube.id ? profile.id : profile.username;
        return `${profile.social}@${profileId}`;
      })
      .indexOf(`${payload.social}@${payloadId}`);
    if (index < 0) {
      state.exportList.push(payload);
    }
  },
  REMOVE_PROFILE_TO_EXPORT_LIST(state, payload) {
    const payloadId = payload.social === SOCIALS.youtube.id ? payload.id : payload.username;
    const index = state.exportList
      .map(profile => {
        const profileId = profile.social === SOCIALS.youtube.id ? profile.id : profile.username;
        return `${profile.social}@${profileId}`;
      })
      .indexOf(`${payload.social}@${payloadId}`);
    if (index > -1) {
      state.exportList.splice(index, 1);
    }
  },
  CLEAR_EXPORT_LIST(state) {
    state.exportList = [];
  },
  CLEAR_SEARCH_RESULT(state) {
    state.searchResult = {
      hits: [],
      total: {}
    };
  },
  SET_SHOWN_PROFILE(state, data) {
    state.shownProfile = data;
  },
  SET_AUDIENCE(state, data) {
    state.shownProfile.audienceData = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
