import ajax from "../helpers";
import {
    ORDER_BY_ITEMS,
    ORDER_DIRECTIONS,
    PAGE_SIZES
} from "../../constants";

const state = {
    filter: {
        query: "",
        order_by: ORDER_BY_ITEMS[0],
        order_dir: ORDER_DIRECTIONS[0],
        page_size: PAGE_SIZES[0],
        archived: false,
        page: 1
    },
    campaign: {},
    lead: {},
    content_pieces: [],
    notes: [],
    coupons: [],
    threads: null,
    available_coupons: {},
    conversation: {
        from: null,
        to: [],
        cc: [],
        subject: "",
        thread_id: null,
        conversation_index: null,
        is_reply: false,
    }
};

const getters = {};

const actions = {
    async getCampaigns({
        dispatch
    }, payload) {
        try {
            let filter = `order_by=${payload.order_by}&order_dir=${payload.order_dir}`;
            //sh.sergey - paginstion is disabled in UI (&page_size=${payload.page_size}`);
            if (payload.page > 1) {
                filter += `&page=${payload.page}`;
            }
            if (payload.archived) {
                filter += `&archived=true`;
            }
            if (payload.query !== "" && payload.query) {
                filter += `&query=${payload.query}`;
            }
            const result = await ajax.get("/api/campaigns?" + filter);

            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async getCampaignNames({
        dispatch
    }) {
        try {
            const result = await ajax.get("/api/campaigns/names");
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async getSingleCampaign({
        dispatch
    }, payload) {
        try {
            const result = await ajax.get(
                "/api/campaigns/" + encodeURIComponent(payload)
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },

    async createCampaign({
        dispatch
    }, payload) {
        try {
            const result = await ajax.post(
                `/api/campaigns/${encodeURIComponent(payload.name)}`,
                payload
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async saveCampaign({
        dispatch
    }, payload) {
        try {
            const result = await ajax.post(
                "/api/campaigns/" + encodeURIComponent(payload.id) + "/save",
                payload
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return err.response;
        }
    },
    async archiveCampaign({
        dispatch
    }, payload) {
        try {
            const result = await ajax.post(
                "/api/campaigns/" + encodeURIComponent(payload.id) + "/archive",
                payload
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return err.response;
        }
    },
    async unarchiveCampaign({
        dispatch
    }, payload) {
        try {
            const result = await ajax.post(
                "/api/campaigns/" + encodeURIComponent(payload.id) + "/unarchive",
                payload
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return err.response;
        }
    },
    async removeLeadFromCampaign({
        dispatch
    }, payload) {
        try {
            const {
                campaign_id,
                id
            } = payload;
            const result = await ajax.del(
                `/api/campaigns/${encodeURIComponent(campaign_id)}/leads/${encodeURIComponent(id)}`,
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async addLeadToExistingCampaign({
        dispatch
    }, payload) {
        try {
            const result = await ajax.post(
                "/api/campaigns/" + encodeURIComponent(payload.campaign_id) + "/add",
                payload
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async addLeadToNewCampaign({
        dispatch
    }, payload) {
        try {
            const result = await ajax.post(
                `/api/campaigns/${encodeURIComponent(
          payload.campaign_name
        )}/create_add`,
                payload
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async getCampaignLeadsPreview({
        dispatch
    }, payload) {
        try {
            const {
                data
            } = await ajax.get(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/leads_preview"
            );
            return data;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async addCampaignStage({
        dispatch
    }, payload) {
        try {
            const result = await ajax.post(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/add_stage",
                payload
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async renameCampaignStage({
        dispatch
    }, payload) {
        try {
            const result = await ajax.post(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/rename_stage",
                payload
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async removeCampaignStage({
        dispatch
    }, payload) {
        try {
            const result = await ajax.post(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/delete_stage",
                payload
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async reorderCampaignStages({
        dispatch
    }, payload) {
        try {
            const result = await ajax.post(
                "/api/campaigns/" + encodeURIComponent(payload.campaign_id) + "/reorder_stages",
                payload
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async getSingleLead({
        commit,
        dispatch
    }, payload) {
        try {
            const {
                data
            } = await ajax.get(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/leads/" +
                encodeURIComponent(payload.lead_id)
            );
            if (data) {
                commit("SET_LEAD", data);
            }
            return data;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },

    async updateLead({
        dispatch,
        commit
    }, payload) {
        try {
            const result = await ajax.post(
                "/api/campaigns/" +
                payload.campaign_id +
                "/leads/" +
                payload.id +
                "/edit", {
                    data: payload.data
                }
            );
            if (result) {
                commit("SET_LEAD_DATA", payload.data);
            }
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },

    async changeLeadStage({
        dispatch
    }, payload) {
        try {
            const result = await ajax.post(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/leads/" +
                encodeURIComponent(payload.lead_id) +
                "/change_stage",
                payload
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async createLeadNote({
        dispatch
    }, payload) {
        try {
            const result = await ajax.post(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/leads/" +
                encodeURIComponent(payload.lead_id) +
                "/notes",
                payload
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async updateLeadNote({
        dispatch
    }, payload) {
        try {
            const result = await ajax.put(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/leads/" +
                encodeURIComponent(payload.lead_id) +
                "/notes/" +
                encodeURIComponent(payload.note_id),
                payload
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async deleteLeadNote({
        dispatch
    }, payload) {
        try {
            const result = await ajax.del(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/leads/" +
                encodeURIComponent(payload.lead_id) +
                "/notes/" +
                encodeURIComponent(payload.note_id)
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async createLeadContentPiece({
        commit,
        dispatch
    }, payload) {
        try {
            const result = await ajax.post(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/leads/" +
                encodeURIComponent(payload.lead_id) +
                "/content_pieces",
                payload
            );

            if (result.data) {
                commit("ADD_LEAD_CONTENT_PIECES", {
                    id: result.data.content_id,
                    lead_id: payload.lead_id,
                    campaign_id: payload.campaign_id,
                    url: payload.url,
                    cost: payload.cost
                });
            }
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async updateLeadContentPiece({
        commit,
        dispatch
    }, payload) {
        try {
            const result = await ajax.put(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/leads/" +
                encodeURIComponent(payload.lead_id) +
                "/content_pieces/" +
                encodeURIComponent(payload.id),
                payload
            );
            if (result.data) {
                commit("UPDATE_LEAD_CONTENT_PIECES", {
                    id: result.data.content_id,
                    lead_id: payload.lead_id,
                    campaign_id: payload.campaign_id,
                    url: payload.url,
                    cost: payload.cost
                });
            }
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async deleteLeadContentPiece({
        commit,
        dispatch
    }, payload) {
        try {
            const result = await ajax.del(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/leads/" +
                encodeURIComponent(payload.lead_id) +
                "/content_pieces/" +
                encodeURIComponent(payload.content_id)
            );
            if (result.data) {
                commit("DELETE_LEAD_CONTENT_PIECES", {
                    id: result.data.content_id,
                    lead_id: payload.lead_id,
                    campaign_id: payload.campaign_id
                });
            }
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },

    async getCampaignTrackingHistory({
        dispatch
    }, payload) {
        try {
            const {
                campaign_id,
                type,
                starting_from,
                resolution
            } = payload;
            const result = await ajax.get(
                `/api/campaigns/${encodeURIComponent(
          campaign_id
        )}/tracking_history?type=${encodeURIComponent(
          type
        )}&resolution=${encodeURIComponent(resolution)}${
          starting_from
            ? "&starting_from=" + encodeURIComponent(starting_from)
            : ""
        }`
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async getSalesHistory({
        dispatch
    }, payload) {
        try {
            const {
                campaign_id,
                type,
                starting_from,
                resolution
            } = payload;
            const result = await ajax.get(
                `/api/campaigns/${encodeURIComponent(
          campaign_id
        )}/sales_history?type=${encodeURIComponent(
          type
        )}&resolution=${encodeURIComponent(resolution)}${
          starting_from
            ? "&starting_from=" + encodeURIComponent(starting_from)
            : ""
        }`
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async getInfluencersHistory({
        dispatch
    }, payload) {
        try {
            const {
                campaign_id
            } = payload;

            // need to change on prod without _demo in the end
            const result = await ajax.get(
              `/api/campaigns/${encodeURIComponent(
                campaign_id
              )}/leads/sum_completed_stage_over_time`
            );

            //remove at production
        //     const result = await ajax.get(
        //         `/api/campaigns/${encodeURIComponent(
        //   campaign_id
        // )}/leads/sum_completed_stage_over_time_demo`
        //     );

            return result;

        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async getPostsHistory({
        dispatch
    }, payload) {
        try {
            const {
                campaign_id
            } = payload;


            const result = await ajax.get(
                `/api/campaigns/${encodeURIComponent(
                campaign_id
              )}/leads/sum_posts_over_time`
            );

            //     const result = await ajax.get(
            //         `/api/campaigns/${encodeURIComponent(
            //   campaign_id
            // )}/leads/sum_posts_over_time_demo`
            //     );



            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async getLeadTrackingHistory({
        dispatch
    }, payload) {
        try {
            const {
                data
            } = await ajax.get(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/leads/" +
                encodeURIComponent(payload.lead_id) +
                "/tracking_history" +
                (payload.starting_from ?
                    "?starting_from=" + encodeURIComponent(payload.starting_from) :
                    "") +
                (payload.starting_from ? "&" : "?") +
                (payload.type ? "type=" + encodeURIComponent(payload.type) : "")
            );
            return data;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },

    async getContentTrackingHistory({
        dispatch
    }, payload) {
        try {
            const {
                data
            } = await ajax.get(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/leads/" +
                encodeURIComponent(payload.lead_id) +
                "/content_pieces/" +
                encodeURIComponent(payload.content_id) +
                "/tracking_history" +
                (payload.starting_from ?
                    "?starting_from=" + encodeURIComponent(payload.starting_from) :
                    "") +
                (payload.starting_from ? "&" : "?") +
                (payload.type ? "type=" + encodeURIComponent(payload.type) : "")
            );
            return data;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },

    async getCampaignLeadsPerformance({
        dispatch
    }, payload) {
        try {
            const result = await ajax.get(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/leads_performance?metric=" +
                encodeURIComponent(payload.metric)
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async getCampaignContentPerformance({
        dispatch
    }, payload) {
        try {
            const result = await ajax.get(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/content_performance?metric=" +
                encodeURIComponent(payload.metric)
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async getCampaignCouponsPerformance({
        dispatch
    }, payload) {
        try {
            const result = await ajax.get(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/coupons_performance?metric=" +
                encodeURIComponent(payload.metric)
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },

    async moveLeadToStage({
        dispatch
    }, payload) {
        try {
            const result = await ajax.post(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/leads/" +
                encodeURIComponent(payload.lead_id) +
                "/move/" +
                encodeURIComponent(payload.action)
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async setCampaignActive({
        dispatch
    }, payload) {
        try {
            const result = await ajax.post(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/set_active"
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async setCampaignInactive({
        dispatch
    }, payload) {
        try {
            const result = await ajax.post(
                "/api/campaigns/" +
                encodeURIComponent(payload.campaign_id) +
                "/set_inactive"
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    async getAvailableCoupons({
        commit,
        dispatch
    }) {
        try {
            const {
                data
            } = await ajax.get(`/api/integrations/coupons`);
            if (data) {
                commit("SET_AVAILABLE_COUPONS", data);
            }
            return data;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },

    async addCoupon({
        commit
    }, payload) {
        const {
            campaign_id,
            lead_id,
            integration_id,
            code
        } = payload;
        const {
            data
        } = await ajax.post(
            `/api/campaigns/${encodeURIComponent(
        campaign_id
      )}/leads/${encodeURIComponent(lead_id)}/coupon/${encodeURIComponent(
        integration_id
      )}/${encodeURIComponent(code)}`,
            payload
        );
        commit("ADD_COUPON", data);
        return data;
    },
    async deleteCoupon({
        commit,
        dispatch
    }, payload) {
        try {
            const {
                campaign_id,
                lead_id,
                integration_id,
                code
            } = payload;
            const result = await ajax.del(
                `/api/campaigns/${encodeURIComponent(
          campaign_id
        )}/leads/${encodeURIComponent(lead_id)}/coupon/${encodeURIComponent(
          integration_id
        )}/${encodeURIComponent(code)}`
            );
            commit("DELETE_COUPON", payload);
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },

    // eslint-disable-next-line no-unused-vars
    async getThreads({
        commit
    }, payload) {
        const {
            data
        } = await ajax.get(`/api/email-integrations/${encodeURIComponent(payload.name)}/${encodeURIComponent(payload.address)}/threads/${encodeURIComponent(payload.lead_email)}`);
        commit("SET_THREADS", data);
        return data;
    },
    async markReadLead({
        dispatch
    }, payload) {
        try {
            const {
                campaign_id,
                id
            } = payload;
            const result = await ajax.post(
                `/api/campaigns/${encodeURIComponent(
          campaign_id
        )}/leads/${encodeURIComponent(id)}/mark_read`,
                payload
            );
            return result;
        } catch (err) {
            dispatch("setErrorAlert", err, {
                root: true
            });
            return null;
        }
    },
    //todo: sn.sergey - investigate about all of the usages these methods and probably replace with the same actions from inbox.js
    async replyEmail(state, payload) {
        const {
            name,
            address,
            message_id
        } = payload;
        const result = await ajax.post(
            `/api/email-integrations/${encodeURIComponent(name)}/${address}/${encodeURIComponent(message_id)}/create_reply`,
            payload
        );
        return result;
    },
    async sendEmail(state, payload) {
        const {
            address,
            name
        } = payload;
        const result = await ajax.post(
            `/api/email-integrations/${encodeURIComponent(name)}/${address}/create_send`,
            payload
        );
        return result;
    },
    setThreads({
        commit
    }, payload) {
        commit("SET_CONVERSATION", payload);
    },
    setConversation({
        commit
    }, payload) {
        commit("SET_CONVERSATION", payload);
    },
    setLeadStage({
        commit
    }, payload) {
        commit("SET_LEAD_STAGE", payload);
    },
    setLeadData({
        commit
    }, payload) {
        commit("SET_LEAD_DATA", payload);
    },
    setCoupons({
        commit
    }, payload) {
        commit("SET_COUPONS", payload);
    },
    setQuery({
        commit
    }, payload) {
        commit("SET_CAMPAIGN_SEARCH_QUERY", payload);
    },
    setOrderyBy({
        commit
    }, payload) {
        commit("SET_CAMPAIGN_SEARCH_ORDER_BY", payload);
    },
    setOrderDirection({
        commit
    }, payload) {
        commit("SET_CAMPAIGN_SEARCH_ORDER_DIR", payload);
    },
    setPageSize({
        commit
    }, payload) {
        commit("SET_CAMPAIGN_SEARCH_PAGE_SIZE", payload);
    },
    setPageNumber({
        commit
    }, payload) {
        commit("SET_CAMPAIGN_SEARCH_PAGE_NUMBER", payload);
    },
    setShowArchived({
        commit
    }, payload) {
        commit("SET_CAMPAIGN_SHOW_ARCHIVED", payload);
    },
    setFilter({
        commit
    }, payload) {
        commit("SET_CAMPAIGN_FILTER", payload);
    },
};

const mutations = {
    SET_CAMPAIGN_SEARCH_QUERY(state, payload) {
        state.filter.query = payload;
    },
    SET_CAMPAIGN_SEARCH_ORDER_BY(state, payload) {
        state.filter.order_by = payload;
    },
    SET_CAMPAIGN_SEARCH_ORDER_DIR(state, payload) {
        state.filter.order_dir = payload;
    },
    SET_CAMPAIGN_SEARCH_PAGE_SIZE(state, payload) {
        state.filter.page_size = payload;
    },
    SET_CAMPAIGN_SHOW_ARCHIVED(state, payload) {
        state.filter.archived = payload;
    },
    SET_CAMPAIGN_SEARCH_PAGE_NUMBER(state, payload) {
        state.filter.page = payload;
    },
    SET_CAMPAIGN_FILTER(state, payload) {
        state.filter = payload;
    },
    SET_LEAD(state, payload) {
        state.campaign = payload.campaign;
        state.lead = payload.lead;
        state.content_pieces = payload.content_pieces;
        state.notes = payload.notes;
        state.coupons = payload.coupons;
        state.threads = null;
    },
    SET_LEAD_STAGE(state, payload) {
        state.lead.stage = payload;
    },
    SET_LEAD_DATA(state, payload) {
        state.lead.data = payload;
    },
    SET_THREADS(state, payload) {
        state.threads = payload;
    },
    SET_CONVERSATION(state, payload) {
        state.conversation = {
            ...state.conversation,
            ...payload
        }
    },
    ADD_LEAD_CONTENT_PIECES(state, payload) {
        state.content_pieces.push(payload);
    },
    UPDATE_LEAD_CONTENT_PIECES(state, payload) {
        const index = state.content_pieces.map(e => e.id).indexOf(payload.id);
        state.content_pieces[index] = payload;
    },
    DELETE_LEAD_CONTENT_PIECES(state, payload) {
        const index = state.content_pieces.map(e => e.id).indexOf(payload.id);
        if (index > -1) {
            state.content_pieces.splice(index, 1);
        }
    },
    SET_AVAILABLE_COUPONS(state, payload) {
        state.available_coupons = payload;
    },
    ADD_COUPON(state, payload) {
        state.coupons.push(payload);
    },
    DELETE_COUPON(sate, payload) {
        const index = state.coupons.map(c => c.code).indexOf(payload.code);
        if (index > -1) {
            state.coupons.splice(index, 1);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};