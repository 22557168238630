<template>
  <div :class="[componentClasses]">
    <slot name="nav"></slot>
    <slot name="content"></slot>
  </div>
</template>


<script>
export default {
  name: "piils-layout",
  props: {
    componentClasses: {
      type: [String, Object],
      default: "",
      description: "Component css classes"
    }
  }
};
</script>
