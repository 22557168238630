import ajax from "../helpers";

const state = {
  integrations: {},
  availablePlans: []
}

const getters = {};

const actions = {
  async getPaymentMethods({ dispatch }) {
    try {
      const result = await ajax.get("/api/billing/payment_methods");
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async removePaymentMethod({ dispatch }, payload) {
    try {
      const result = await ajax.del(`/api/billing/payment_methods/${encodeURIComponent(payload)}`);
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async getBillingPlans({ dispatch }) {
    try {
      const result = await ajax.get("/api/billing/plans");
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async getCreditPackages({ dispatch }) {
    try {
      const result = await ajax.get("/api/billing/credit_packages");
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async buyCredits({ dispatch }, payload) {
    try {
      const result = await ajax.post("/api/billing/buy_credits", payload);
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async addNewPaymentMethod({ dispatch }, payload) {
    try {
      const result = await ajax.post("/api/billing/payment_methods", payload);
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async startSubscription({ dispatch }, payload) {
    try {
      const result = await ajax.post(
        "/api/billing/start_subscription",
        payload
      );
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async getNewBillingPlans({ commit, dispatch }, payload) {
    try {
      let testResult = [];
      testResult.push({
        id: 1,
        price: 895,
        featured: false,
        name: "Starter",
        support: "Basic",
        accountManager: false,
        seats: 2,
        influencerSearch: "Micro-Mega",
        shopifyIntegration: false,
        _3dCartIntegration: false,
        WooCommerceIntegration: false,
        analytics: "yes",
        creativesAndContent: "Self use",
        minimizationManager: "no"
      });
      testResult.push({
        id: 2,
        price: 1690,
        featured: true,
        name: "Premium",
        support: "Executive assistant",
        accountManager: true,
        seats: 8,
        influencerSearch: "Micro-Mega",
        shopifyIntegration: true,
        _3dCartIntegration: true,
        WooCommerceIntegration: true,
        analytics: "yes",
        creativesAndContent: "yes",
        minimizationManager: "no"
      });
      testResult.push({
        id: 3,
        price: null,
        featured: false,
        name: "Professional",
        support: "Executive assistant",
        accountManager: true,
        seats: 25,
        influencerSearch: "Nano-Mega",
        shopifyIntegration: false,
        _3dCartIntegration: false,
        WooCommerceIntegration: false,
        analytics: "Monthly tailor-made reports",
        creativesAndContent: "yes",
        minimizationManager: "Full management"
      });

      commit("PLANS_RECEIVED", testResult);
      const result = await ajax.get("/api/billing/plans");
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  }, 
};

const mutations = {
  PLANS_RECEIVED(state, payload) {
    state.availablePlans = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
