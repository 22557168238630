import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import billing from "./modules/billing";
import integration from "./modules/integration";
import influencer from "./modules/influencer";
import campaign from "./modules/campaign";
import home from "./modules/home";
import inbox from "./modules/inbox";
import workspace from "./modules/workspace";
import * as actions from "./actions";
import mutations from "./mutations";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
const state = {
  alert: {
    showAlert: false,
    alertClass: "success",
    alertIconClass: "icon fa fa-check",
    headerText: "Alert!",
    content: "Test content",
    customButton: null
  }
};

export default new Vuex.Store({
  state,
  actions,
  mutations,
  modules: {
    user,
    billing,
    integration,
    influencer,
    campaign,
    inbox,
    home,
    workspace
  },
  strict: debug
});
