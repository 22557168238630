import {
  ENGAGEMENT_RULES,
  ENGAGEMENT_CLASSES,
  MONTH_NAMES,
  ACCOUNT_SCOPES,
  ACCOUNT_ACCESS,
  MESSAGES,
} from "@/constants";
/* get formatted string results */

export function getFormattedBigNumber(num) {
  if (num >= 1e6) {
    return `${Math.floor(num / 1e5) / 10}M`;
  } else if (num >= 1e3) {
    return `${Math.floor(num / 1e2) / 10}K`;
  }
  return Math.floor(num);
}

export function getFormattedNormalNumber(n, c, d, t) {
  let s, i, j;
  (c = isNaN((c = Math.abs(c))) ? 2 : c),
    (d = d == undefined ? "." : d),
    (t = t == undefined ? "," : t),
    (s = n < 0 ? "-" : ""),
    (i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))))),
    (j = (j = i.length) > 3 ? j % 3 : 0);

  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
      Math.abs(n - i)
        .toFixed(c)
        .slice(2)
      : "")
  );
}

export function getPercentage(num) {

  return num.toFixed(2);

}

export function getFormattedRate(rate, c = 2, a = true, hasSymboal = true) {
  if (rate === "N/A") return rate;
  if (rate < 0.001 && a) return "<0.1" + (hasSymboal && "%");
  return (rate * 100).toFixed(c) + (hasSymboal && "%");
}

export function getFullName(profile) {
  return !profile.full_name || profile.full_name === ""
    ? "–"
    : profile.full_name;
}

export function getEngagementRateClass(profile) {
  let className = null;
  ENGAGEMENT_RULES.forEach(rule => {
    if (rule.followers < profile.followers) {
      if (profile.engagement_rate < rule.rates[0]) {
        className = ENGAGEMENT_CLASSES.poor.name;
        return;
      } else if (profile.engagement_rate < rule.rates[1]) {
        className = ENGAGEMENT_CLASSES.average.name;
        return;
      } else if (profile.engagement_rate < rule.rates[2]) {
        className = ENGAGEMENT_CLASSES.good.name;
        return;
      } else if (
        profile.engagement_rate >= rule.rates[2] ||
        profile.engagement_rate < 1
      ) {
        className = ENGAGEMENT_CLASSES.excellent.name;
      }
      className = ENGAGEMENT_CLASSES.suspicious.name;
    }
  });
  return className || "zero";
}

export function getCampaignROI(campaign) {
  let imv = (campaign.imv_coef * campaign.impressions) / 1000;
  let revenue = campaign.gross_revenue * 1 ? campaign.gross_revenue * 1 : imv;
  let roi;
  if (revenue && campaign.spend * 1 !== 0) {
    roi =
      (revenue - campaign.spend - campaign.total_refunded) / campaign.spend;
  } else if (revenue && campaign.budget * 1 !== 0) {
    roi =
      (revenue - campaign.budget - campaign.total_refunded) / campaign.budget;
  } else {
    roi = "N/A";
  }
  return roi;
}

export function getMainEmail(profile) {
  if (profile.business_email) return profile.business_email;
  if (profile.emails) return;
  if (profile.length) return profile.emails[0];
  return;
}

export function getTimeObject(str) {
  var date = new Date(str);
  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();
  var hour = date.getHours();
  var minute = date.getMinutes();
  if (minute < 10) minute = "0" + minute;

  if (day > 3 && day < 21) {
    day += "th";
  } else {
    switch (day % 10) {
      case 1:
        day += "st";
        break;
      case 2:
        day += "nd";
        break;
      case 3:
        day += "rd";
        break;
      default:
        day += "th";
    }
  }

  return {
    year: year,
    month: MONTH_NAMES[monthIndex].short,
    day: day,
    hour: hour,
    minute: minute
  };
}

/* object operation */

export function equalObjects(x, y) {
  if (x === y) return true;
  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  if (x.constructor !== y.constructor) return false;
  for (var p in x) {
    if (!x.hasOwnProperty(p)) continue;
    if (!y.hasOwnProperty(p)) return false;
    if (x[p] === y[p]) continue;
    if (typeof x[p] !== "object") return false;
    if (!equalObjects(x[p], y[p])) return false;
  }
  for (p in y) {
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
  }
  return true;
}

export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

export const generateItems = (count, creator) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(creator(i));
  }
  return result;
};

export const getFullLocation = profile => {
  let { location_city, location_region, location_country } = profile;
  return `${location_city ? location_city : ""}${location_region && location_city ? ", " : ""
    }${location_region ? location_region : ""}${location_region && location_country ? ", " : ""
    }${location_country ? location_country : ""}`;
};

export const resizedataUrl = (datas, wantedWidth, wantedHeight) => {
  return new Promise(async resolve => {
    var img = document.createElement("img");
    img.setAttribute("crossorigin", "anonymous");
    img.onload = function () {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      canvas.width = wantedWidth;
      canvas.height = wantedHeight;
      ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
      var dataURI = canvas.toDataURL();
      resolve(dataURI);
    };
    img.src = datas;
  });
};

export const isValidUrl = str => {
  let pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const getPermissionErrorMessage = (permissionStr, messageId = "user__permission-error") => {
  const permissionArray = permissionStr.split(":");
  const readableScope = ACCOUNT_SCOPES.find(e => e.id === permissionArray[0]);
  const readablePermission = ACCOUNT_ACCESS.find(e => e.id === permissionArray[1]);

  const message = MESSAGES[messageId]
    .replace("{{permission}}", readablePermission && readablePermission.label)
    .replace("{{scope}}", readableScope && readableScope.label);
  return message;
};

export function objectToQueryString([params]) {
  var queryString = Object.keys(params).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
  }).join('&');
  return queryString;
}

// 02/03/2022
export function dateIsoToShort(dateString) {
  if (dateString == null)
    return "";

  var locale = window.navigator.userLanguage || window.navigator.language;
  var options = { year: "numeric", month: "numeric", day: "numeric" };
  return new Date(dateString).toLocaleDateString(locale, options);
}

export function dateIsoToLong(dateString) {
  if (dateString == null)
    return "";

  var locale = window.navigator.userLanguage || window.navigator.language;
  var options = {
    year: "numeric", month: "numeric", day: "numeric",
    hour: "numeric", minute: "numeric", second: "numeric"
  };
  return new Date(dateString).toLocaleDateString(locale, options);
}

export function dateIsoToShortTime(dateString) {
  if (dateString == null)
    return "";

  var locale = window.navigator.userLanguage || window.navigator.language;
  var options = { hour: '2-digit', minute: '2-digit' };
  return new Date(dateString).toLocaleTimeString(locale, options);
}

export function onLogoImageError(e) {
  e.target.src = "/app/img/avatar/ninjainfluence-default.png";
}

// sh.sergey - in the case of search by particular username,
// backend can return like an "fake" empty object.
// So, conditions are only one object in the hits without properties
export const isFakeObjectHits = (hits) => {
  return hits.length == 1 && Object.keys(hits[0]).length === 0;
}

export function wrapEmailContentToHtmlPage(htmlContent) {
  return `<!DOCTYPE html>
    <html xmlns="http://www.w3.org/1999/xhtml">
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    </head>
    <body> ${htmlContent} </body></html>`;
}

export class RequestInfo {
  constructor() { }

  inProgress = false;
  isDone = false;
  error = false;
  errorMessage = "";

  start() {
    this.isDone = false;
    this.inProgress = true;
    this.error = false;
    this.errorMessage = ""
  }

  done() {
    this.inProgress = false;
    this.isDone = true;
    this.error = false;
    this.errorMessage = ""
  }

  fail(errorMessage) {
    this.inProgress = false;
    this.isDone = true;
    this.error = true;
    this.errorMessage = errorMessage
  }

  reset() {
    this.isDone = false;
    this.inProgress = false;
    this.error = false;
    this.errorMessage = ""
  }
}
