const TIKTOK_COUNTRIES = [
  "Albania",
  "Andorra",
  "Bangladesh",
  "Bulgaria",
  "China",
  "Croatia",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "Greece",
  "Hungary",
  "India",
  "Indonesia",
  "Iran, Islamic Republic of",
  "Israel",
  "Italy",
  "Japan",
  "Korea, Republic of",
  "Latvia",
  "Lithuania",
  "Nepal",
  "Netherlands",
  "North Macedonia",
  "Norway",
  "Pakistan",
  "Philippines",
  "Poland",
  "Romania",
  "Russian Federation",
  "Slovakia",
  "Slovenia",
  "Somalia",
  "South Africa",
  "Sweden",
  "Taiwan",
  "Turkey",
  "Ukraine",
  "Vietnam"
];
export default TIKTOK_COUNTRIES;
