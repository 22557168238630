/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import ajax from "../helpers";

import { EmailThread } from "../../models/emailMessagesModels";
import { InboxFilter } from "../../models/inboxModels";


const state = {
  /* common */
  unreads: 0,
  /* inbox filters start */

  inboxFilter: new InboxFilter(),
  inboxFiltersReady: false,
  currentCampaign: null,
  currentStage: null,
  currentStatus: null,
  currentEmailAccount: null,
  currentLead: null,
  hasEmail: true,
  /* inbox filters end */

  // Threads filters
  userEmail: null,
  leadEmail: null,
  //--
  campaigns: [],
  leads: [],
  messages: {},
  conversation: null,
  emailThreads: [],
  selectedThread: null,
  replyMessage: null
};

const getters = {};

const actions = {
  async replyEmail(state, payload) {
    const { name, address, message_id } = payload;
    const result = await ajax.post(
      `/api/email-integrations/${encodeURIComponent(name)}/${address}/${encodeURIComponent(message_id)}/create_reply`,
      payload
    );
    return result;
  },
  async sendEmail(state, payload) {
    const { address, name } = payload;
    const result = await ajax.post(
      `/api/email-integrations/${encodeURIComponent(name)}/${address}/create_send`,
      payload
    );
    return result;
  },
  async getUnreads({ commit }) {
    const { data } = await ajax.get("api/email-integrations/inbox/unread");
    commit("SET_INBOX_UNREADS", data ? data.unread : 0);
    return data;
  },
  async getCampaigns({ commit }) {

    const { data } = await ajax.get("api/campaigns/names");
    commit("SET_INBOX_CAMPAIGNS", data);
    return data;
  },
  async getLeads({ commit }, payload) {
    const { campaign_id, stage_id, unread, has_emails, page, email } = payload;
    const status = unread === "any" ? "" : `status=${unread}`;
    const { data } = await ajax.get(`api/email-integrations/inbox/leads?page=${page}&campaign_id=${encodeURIComponent(campaign_id)}&stage_id=${encodeURIComponent(stage_id)}${unread ? `&${status}` : ""}` +
      `${has_emails ? `&has_emails=${has_emails}` : ""}` +
      `${email && email != "Any" ? `&email=${email}` : ""}`);
    return data;
  },

  async filterLeads({ commit }, payload) {
    var requestUrl = `api/email-integrations/inbox/leads?page=${state.inboxFilter.page}`;

    if (state.inboxFilter.campaignId)
      requestUrl += `&campaign_id=${encodeURIComponent(state.inboxFilter.campaignId)}`;
    if (state.inboxFilter.campaignStageId)
      requestUrl += `&stage_id=${encodeURIComponent(state.inboxFilter.campaignStageId)}`;

    const status = state.inboxFilter.status === "any" ? "" : `status=${state.inboxFilter.status}`;
    requestUrl += status;

    if (state.inboxFilter.hasEmail) {
      requestUrl += `&has_emails=${state.inboxFilter.hasEmail}`;
    }

    if (state.inboxFilter.emailAccount && state.inboxFilter.emailAccount.email != "Any") {
      requestUrl += `&hemail=${state.inboxFilter.emailAccount.email}`;
    }

    const { data } = await ajax.get(requestUrl);
    commit("SET_LEADS", data);

  },
  async getMessages({ commit }, payload) {

    const { lead_email, user_email, lead_id } = payload;

    let userEmailFilter = user_email ? `&email=${lead_email}&email=${user_email}` : "";

    const { data } = await ajax.get(`/api/email-integrations/inbox/messages?lead_id=${lead_id}` +
      userEmailFilter);

    commit("SET_INBOX_MESSAGES", data);
    return data;
  },
  async markReadMessage({ commit, dispatch }, payload) {
    try {
      const { thread_id, message_id } = payload;
      const result = await ajax.post(
        `/api/email-integrations/inbox/messages/${encodeURIComponent(
          thread_id
        )}/leads/${encodeURIComponent(message_id)}/mark_read`
      );
      commit("SET_INBOX_MESSAGE_MARK_READ", payload);
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async markReadThread({ commit, dispatch }, payload) {
    try {
      const { campaign_id, lead_id, thread_id } = payload;
      const { data } = await ajax.post(
        `/api/email-integrations/inbox/messages/${encodeURIComponent(
          thread_id
        )}/mark_read?campaign_id=${encodeURIComponent(campaign_id)}&lead_id=${encodeURIComponent(lead_id)}`
      );
      commit("SET_INBOX_THREAD_MARK_READ", thread_id);
      return data;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  async markReadLead({ commit, dispatch }, payload) {
    try {
      const { campaign_id, id } = payload;
      const result = await ajax.post(
        `/api/campaigns/${encodeURIComponent(
          campaign_id
        )}/leads/${encodeURIComponent(id)}/mark_read`,
        payload
      );
      if (result) {
        commit("SET_INBOX_LEAD_MARK_READ", { id });
      }
      return result;
    } catch (err) {
      dispatch("setErrorAlert", err, { root: true });
      return null;
    }
  },
  setMessage({ commit }, payload) {
    commit("SET_INBOX_MESSAGE", payload);
  },
  setCampaign({ commit }, payload) {
    commit("SET_INBOX_CAMPAIGN", payload)
  },
  setEmailAccount({ commit }, payload) {
    commit("SET_INBOX_EMAILACCOUNT", payload)
  },
  setStage({ commit }, payload) {
    commit("SET_INBOX_STAGE", payload)
  },
  setStatus({ commit }, payload) {
    commit("SET_INBOX_STATUS", payload)
  },
  setHasEmail({ commit }, payload) {
    commit("SET_INBOX_HAS_EMAIL", payload);
  },
  setLeads({ commit }, payload) {
    const { data, page } = payload;
    commit(page > 1 ? "APPEND_INBOX_LEADS" : "SET_INBOX_LEADS", data);
  },
  selectLead({ commit }, payload) {
    commit("SET_INBOX_LEAD", payload)
  },
  selectThread({ commit }, payload) {
    commit("SET_LEAD_THREAD", payload)
  },
};
const mutations = {
  SET_INBOX_UNREADS(state, payload) {
    state.unreads = payload;
  },
  SET_INBOX_CAMPAIGNS(state, payload) {
    state.campaigns = payload;
  },

  SET_INBOX_LEADS(state, payload) {
    payload.forEach((lead) => {
      if (lead.data.emails == "<this field is only available in the premium tier>")
        lead.data.emails = [];
    });

    state.leads = payload;
  },
  APPEND_INBOX_LEADS(state, payload) {
    state.leads = state.leads.concat(payload);
  },
  SET_INBOX_MESSAGES(state, payload) {
    const decoder = new TextDecoder();

    const emailThreads = [];

    for (var thread in payload) {
      const messageArray = payload[thread];
      const emailThread = new EmailThread(thread, messageArray);
      emailThreads.push(emailThread);
    }

    state.emailThreads = emailThreads;

    emailThreads.sort((a, b) => {
      if (a.updatedDate < b.updatedDate) {
        return 1;
      }
      if (a.updatedDate > b.updatedDate) {
        return -1;
      }
      return 0;
    });

    state.messages = payload;
  },
  SET_INBOX_MESSAGE(state, payload, commit) {
    const { reply_id, message } = payload;

    if (state.selectedThread) { /* insert reply message */
      state.selectedThread.emailMessages.push(message);
    } else { /* add new thread with the message (conversation) */
      const emailThread = new EmailThread(message.thread_id, [message]);
      state.emailThreads.unshift(emailThread);
    }
    //state.messages = { ...state.messages };
  },
  SET_INBOX_CAMPAIGN(state, payload) {
    state.currentCampaign = payload;
    state.inboxFilter.campaign = payload;
  },
  SET_INBOX_EMAILACCOUNT(state, payload) {
    state.currentEmailAccount = payload;
    state.inboxFilter.emailAccount = payload;
  },
  SET_INBOX_STAGE(state, payload) {
    state.currentStage = payload;
    state.inboxFilter.campaignStage = payload;
  },
  SET_INBOX_STATUS(state, payload) {
    state.currentStatus = payload;
    state.inboxFilter.status = payload;
  },
  SET_INBOX_HAS_EMAIL(state, payload) {
    state.hasEmail = payload;
    state.inboxFilter.hasEmail = payload;
  },
  SET_INBOX_LEAD(state, payload) {
    if (state.currentLead && state.currentLead.id == payload.id)
      return
      
    state.currentLead = payload;
    // Cleanup Inbox workspace (will be set after fetching threads&messages)
    state.selectedThread = null;
  },
  SET_INBOX_LEAD_MARK_READ(state, payload) {
    const { id } = payload;
    if (state.currentLead.id === id) {
      state.currentLead.unread_messages = 0;
    }
    const index = state.leads.map(e => e.id).indexOf(id);
    if (index > -1) {
      state.leads[index].unread_messages = 0;
    }
  },
  SET_INBOX_MESSAGE_MARK_READ(state, payload) {
    const { thread_id, message_id } = payload;
    if (!state.messages || !state.messages[thread_id]) return;
    const index = state.messages[thread_id].map(e => e.id).indexOf(message_id);
    if (index > -1) {
      state.messages[thread_id][index].unread = false;
    }
  },
  SET_INBOX_THREAD_MARK_READ(state, payload) {
    if (!state.messages || !state.messages[payload]) return;
    state.messages[payload].forEach(conversation => {
      conversation.unread = false;
    });
  },
  SET_LEAD_THREAD(state, payload) {
    state.selectedThread = payload;
    if (state.selectedThread)
      state.selectedThread.selected = true;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
