import { startsWith } from "core-js/core/string";
import ajax from "../helpers";

const state = {
  Influencer_stats:{},
  influencers_reached:{},
  user_campains_stats:{},
  leads_overview:{},
  latest_communictions:{},
  campaigns:{},
  loading_influencer_stats: true,
  loading_influencers_reached: true,
  loading_user_campaigns_stats: true,

}

const getters = {};

const actions = {

  async getNewInfluencerCount({ commit }) {
    const { data } = await ajax.get("api/influencers/db_stats");
    commit("SET_INFLUENCER_COUNT", data);
    return data;
  },

  async InfluencersReached({ commit }) {
    const { data } = await ajax.get("/api/email-integrations/inbox/influencers_reached");
    commit("SET_INFLUENCER_REACHED", data);
    return data;
  },


  async userCampainsStats({ commit }) {
    const { data } = await ajax.get("/api/campaigns/user_campains_stats");
    commit("SET_CAMPAINS_STATS", data);
    return data;
  },

  async leadsOverview({ commit }) {
    const { data } = await ajax.get("/api/campaigns/leads_overview?page_size=7");
    commit("SET_LEADS_OVERVIEW", data);
    return data;
  },

  async latestCommunictions({ commit }) {
    const { data } = await ajax.get("/api/email-integrations/inbox/latest_communictions");
    commit("SET_LATEST_COMMUNICTIONS", data);
    return data;
  },

  async getCampaigns({ commit }) {
    const { data } = await ajax.get("api/campaigns?order_by=ROI&order_dir=descending&page_size=7");
    commit("SET_CAMPAIGNS", data);
    return data;
  },



  

};

const mutations = {

  SET_INFLUENCER_COUNT(state, payload) {
    state.loading_influencer_stats = false;
    state.Influencer_stats = payload;
  },
  SET_INFLUENCER_REACHED(state, payload) {
    state.loading_influencers_reached = false;
    state.influencers_reached = payload;
  },
  SET_CAMPAINS_STATS(state, payload) {
    state.loading_user_campaigns_stats = false;
    state.user_campains_stats = JSON.parse(payload) ;
  },
  SET_LEADS_OVERVIEW(state, payload) {
    state.leads_overview = JSON.parse(payload)
  },
  SET_LATEST_COMMUNICTIONS(state, payload) {
    state.latest_communictions =JSON.parse(payload.latest_messages);
    
  },

  SET_CAMPAIGNS(state, payload) {
    state.campaigns = JSON.parse(JSON.stringify(payload.campaigns))

  },



};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
