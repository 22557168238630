
import NotificationPlugin from "@/components/NotificationPlugin/index";
import globalDirectives from "@/plugins/globalDirectives";
import globalComponents from "./globalComponents";
import BaseButton from "@/components/BaseButton";
import BaseCheckbox from "@/components/BaseCheckbox";
import BaseInput from "@/components/BaseInput";
import BaseSpinner from "@/components/BaseSpinner";
import TopNav from "@/components/TopNav";
import LeftNav from "@/components/LeftNav";
import "@/assets/scss/pages/_auth.scss";

export default {
  install(Vue) {
    Vue.use(globalComponents);
    Vue.use(globalDirectives);
    Vue.use(NotificationPlugin);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseSpinner.name, BaseSpinner);
    Vue.component(TopNav.name, TopNav);
    Vue.component(LeftNav.name, LeftNav);
  }
};
