<template>
  <nav
    class="modal-topbar"
  >
    <div class="modal-topbar__left">
      <slot name="left"></slot>
    </div>
    <div class="modal-topbar__center">
      <slot name="center"></slot>
    </div>
    <div class="modal-topbar__right">
      <slot name="right"></slot>
    </div>
  </nav>
</template>
<script>
export default {
  name: "modal-topbar",
};
</script>


