import { dateIsoToShort } from "../utils/index.js"

export class EmailThread {
    constructor(id, emailMessages) {
        this.id = id;
        this.emailMessages = emailMessages;

        this.selected = false;
        this.hasUnread = false;

        this.fromName = "";
        this.fromAddress = "";
        this.subject = "";
        this.updatedDate = null;
        this.updatedDateShort = "";
        this.bodyPreview = "";

        this.toName = "";
        this.toAddress = "";

        this.lastMessageSubject = "";

        this.emailMessages.forEach((message) => {
            if (!message.body) {
                message.hasBody = false;
                //message.body = "<span class='font-italic' style='font-size: 0.8em;'>(Message body is not presented)</span>";
            } else {
                message.hasBody = true;
            }

            if (message.source === "gmail") {
                //todo: sh.sergey - quick fix to avoid display From in the wrong encoding
                if (message.from_name.startsWith("=?utf-8?B?")) {
                    message.from_name = message.from_address;
                }
            }
        });

        if (this.emailMessages.length > 0) {
            let lastMessage = emailMessages[this.emailMessages.length - 1];

            this.fromName = lastMessage.from
                ? lastMessage.from.name
                : lastMessage.from_name;

            this.fromAddress = lastMessage.from
                ? lastMessage.from.address
                : lastMessage.from_address;


            this.lastMessageSubject = lastMessage.subject
            this.updatedDate = new Date(lastMessage.updated_at || lastMessage.sent_at || lastMessage.created_at)
            this.updatedDateShort = dateIsoToShort(this.updatedDate);
            this.bodyPreview = lastMessage.body_preview;


            let firstMessage = emailMessages[0];
            if (firstMessage.to_recipients && firstMessage.to_recipients.length > 0) {
                this.toName = firstMessage.to_recipients[0].name;
                this.toAddress = firstMessage.to_recipients[0].address;
            }

            this.subject = firstMessage.subject;
        }
    }
}

export class EmailMessage {
    constructor() {

    }
}