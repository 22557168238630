<template>
  <component
    :is="disabledTooltip && disabled ? 'a' : tag"
    :type="tag === 'button' ? nativeType : ''"
    @click="isBusy || disabled ? undefined : handleClick()"
    class="btn"
    :disabled="(disabled && !disabledTooltip) || isBusy"
    :class="classes"
    v-b-tooltip.hover
    :title="disabled && disabledTooltip"
  >
    <span
      class="btn-inner--icon"
      v-if="$slots.icon || (icon && $slots.default)"
    >
      <slot name="icon">
        <base-spinner v-if="isBusy" :isSmall="true"></base-spinner>
        <i v-else :class="icon"></i>
      </slot>
    </span>

    <template v-else-if="!$slots.default">
      <base-spinner v-if="isBusy" :isSmall="true" class="mr-2"></base-spinner>
      <i v-else :class="icon"></i>
    </template>
    <span
      class="btn-inner--text"
      v-if="$slots.icon || (icon && $slots.default)"
    >
      <slot>
        {{ isBusy && busyText ? busyText : text }}
      </slot>
    </span>
    <template v-if="!$slots.icon && !icon">
      <base-spinner
        v-if="isBusy && $slots.default"
        :isSmall="true"
        class="mr-2"
      ></base-spinner>
      <slot v-if="!$slots.icon && !icon"></slot>
    </template>
  </component>
</template>
<script>
export default {
  name: "base-button",
  props: {
    tag: {
      type: String,
      default: "button",
      description: "Button tag (default -> button)"
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "Button disabled"
    },
    disabledTooltip: {
      type: String,
      default: "",
      description: "Button disabled tooltip"
    },
    isBusy: {
      type: Boolean,
      default: false,
      description: "Whether button is busy"
    },
    busyText: {
      type: String,
      default: "",
      description: "Buttion busy text"
    },
    type: {
      type: String,
      default: "default",
      description: "Button type (e,g primary, danger etc)"
    },
    size: {
      type: String,
      default: "",
      description: "Button size lg|sm"
    },
    textColor: {
      type: String,
      default: "",
      description: "Button text color (e.g primary, danger etc)"
    },
    nativeType: {
      type: String,
      default: "button",
      description: "Button native type (e.g submit,button etc)"
    },
    icon: {
      type: String,
      default: "",
      description: "Button icon"
    },
    text: {
      type: String,
      default: "",
      description: "Button text in case not provided via default slot"
    },
    outline: {
      type: Boolean,
      default: false,
      description: "Whether button style is outline"
    },
    rounded: {
      type: Boolean,
      default: false,
      description: "Whether button style is rounded"
    },
    iconOnly: {
      type: Boolean,
      default: false,
      description: "Whether button contains only an icon"
    },
    block: {
      type: Boolean,
      default: false,
      description: "Whether button is of block type"
    }
  },
  computed: {
    classes() {
      let btnClasses = [
        { "btn-block": this.block },
        { "rounded-circle": this.rounded },
        { "btn-icon-only": this.iconOnly },
        { [`text-${this.textColor}`]: this.textColor },
        { "btn-icon": this.icon || this.$slots.icon },
        { "disabled-alpha": this.disabled || this.isBusy },
        this.type && !this.outline ? `btn-${this.type}` : "",
        this.outline ? `btn-outline-${this.type}` : ""
      ];
      if (this.size) {
        btnClasses.push(`btn-${this.size}`);
      }
      return btnClasses;
    }
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    }
  }
};
</script>
<style scoped lang="scss">
.disabled-alpha {
  opacity: 0.15;
  color: #fff !important;
  cursor: not-allowed;

  &.btn-black {
    opacity: 0.7;
    color: initial !important;
  }
}
.btn-transparent,
.btn-white {
  &.disabled-alpha {
    color: inherit !important;
  }
}

.material-icons-outlined {
  vertical-align: top;
}
</style>
