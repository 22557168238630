export default {
  ALERT_HIDE(state) {
    state.alert.showAlert = false;
    // reset customButton to null to prevent button from showing up
    // in alert popups where customButton is not passed in alert obj
    state.alert.customButton = null;
  },
  ALERT_CHANGE(state, payload) {
    if (payload.hasOwnProperty("alertIconClass")) {
      state.alert.alertIconClass = payload.alertIconClass;
    }
    if (payload.hasOwnProperty("alertClass")) {
      state.alert.alertClass = payload.alertClass;
    }
    if (payload.hasOwnProperty("showAlert")) {
      state.alert.showAlert = payload.showAlert;
    }
    if (payload.hasOwnProperty("content")) {
      state.alert.content = payload.content;
    }
    if (payload.hasOwnProperty("headerText")) {
      state.alert.headerText = payload.headerText;
    }
    if (payload.hasOwnProperty("customButton")) {
      state.alert.customButton = payload.customButton;
    }
  }
};
