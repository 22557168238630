import MESSAGES_TEXT from "./message";
import TIKTOK_COUNTRIES_LIST from "./tiktok-countries";
import TIKTOK_LANGUAGES_LIST from "./tiktok-languages";
import YOUTUBE_COUNTRIES_LIST from "./youtube-countries";
import CURRENCY_SYMBOLS_LIST from "./currency-symbols";
import INSTAGRAM_LOCATIONS_LIST from "./instagram-locations";

/* influencers search and profile */

export const SOCIALS = {
    instagram: {
        id: "instagram",
        name: "Instagram",
        image: "/app/img/icons/svg/instagram.svg",
        tracking_link_placeholder: "e.g. https://www.instagram.com/p/B4vHRWeFpSq/",
        order: 0,
        enabled: true
    },
    tiktok: {
        id: "tiktok",
        name: "TikTok",
        image: "/app/img/icons/svg/tiktok.svg",
        tracking_link_placeholder: "e.g. https://www.tiktok.com/@charlidamelio/video/6790555622279515397",
        order: 1,
        enabled: true
    },
    youtube: {
        id: "youtube",
        name: "YouTube",
        image: "/app/img/icons/svg/youtube.svg",
        tracking_link_placeholder: "e.g. https://youtu.be/Vk2x92k3",
        order: 2,
        enabled: true
    }
}

export const PROFILE_TYPES = {
    business: [
        { id: "all", label: "All" },
        { id: "is_business_account", label: "Only business profiles" },
        { id: "is_not_business_account", label: "Only personal profiles" },
    ],
    verified: [
        { id: "all", label: "All" },
        { id: "is_verified", label: "Only verified profiles" },
        { id: "is_not_verified", label: "Only unverified profiles" },
    ],
    contact: [
        { id: "has_emails", label: "Only profiles with emails" },
        { id: "all", label: "All" },
    ],
}

export const MESSAGES = MESSAGES_TEXT;
export const TIKTOK_COUNTRIES = TIKTOK_COUNTRIES_LIST;
export const TIKTOK_LANGUAGES = TIKTOK_LANGUAGES_LIST;
export const YOUTUBE_COUNTRIES = YOUTUBE_COUNTRIES_LIST;
export const CURRENCY_SYMBOLS = CURRENCY_SYMBOLS_LIST;
export const INSTAGRAM_LOCATIONS = INSTAGRAM_LOCATIONS_LIST;
export const INPUT_ENGAGEMENT_RATE_STEP = 1;
export const INPUT_ENGAGEMENT_RATE_MIN = 0;
export const INPUT_ENGAGEMENT_RATE_MAX = 300;

export const INPUT_FOLLOWERS_STEP = 1;
export const INPUT_FOLLOWERS_MIN = 0;
export const INPUT_FOLLOWERS_MAX = 1000000000;

export const INPUT_FANS_STEP = 1;
export const INPUT_FANS_MIN = 0;
export const INPUT_FANS_MAX = 1000000000;

export const INPUT_SUBSCRIBERS_STEP = 1;
export const INPUT_SUBSCRIBERS_MIN = 0;
export const INPUT_SUBSCRIBERS_MAX = 1000000000;

export const INPUT_VIDEOS_PER_WEEK_STEP = 1;
export const INPUT_VIDEOS_PER_WEEK_MIN = 0;
export const INPUT_VIDEOS_PER_WEEK_MAX = 100;

export const INFLUENCERS_VIEWS_LIST = [{
        label: "Gallery View",
        value: "gallery",
        class: "fas fa-list-ul"
    },
    {
        label: "Table View",
        value: "table",
        class: "fas fa-table"
    }
];

export const SOCIAL_INFLUENCERS_SORTS = {
    instagram: [{
            label: "Followers",
            value: "followers"
        },
        {
            label: "Engagement",
            value: "engagement_rate"
        },
        {
            label: "Relevance",
            value: "relevance"
        }
    ],
    youtube: [{
            label: "Subscribers",
            value: "subscribers"
        },
        {
            label: "Engagement",
            value: "avg_engagement_rate"
        },
        {
            label: "Relevance",
            value: "relevance"
        }
    ],
    tiktok: [{
            label: "Fans",
            value: "fans"
        },
        {
            label: "Videos",
            value: "videos"
        },
        {
            label: "Hearts per video",
            value: "hearts_per_video"
        },
        {
            label: "Relevance",
            value: "relevance"
        }
    ]
};

export const NOKEYWORDS_INFLUENCERS_SORTS = {
    instagram: SOCIAL_INFLUENCERS_SORTS["instagram"][0],
    youtube: SOCIAL_INFLUENCERS_SORTS["youtube"][0],
    tiktok: SOCIAL_INFLUENCERS_SORTS["tiktok"][0],
};

export const ENGAGEMENT_CLASSES = {
    poor: {
        name: "poor",
        color: "red"
    },
    average: {
        name: "average",
        color: "orange"
    },
    good: {
        name: "good",
        color: "blue"
    },
    excellent: {
        name: "excellent",
        color: "green"
    },
    suspicious: {
        name: "suspicious",
        color: "teal"
    }
};

export const ENGAGEMENT_RULES = [{
        followers: 100,
        rates: [0.050926, 0.101852, 0.203704]
    },
    {
        followers: 1000,
        rates: [0.03449145, 0.0689829, 0.147154]
    },
    {
        followers: 10000,
        rates: [0.01579585, 0.0445148, 0.08891929999999999]
    },
    {
        followers: 100000,
        rates: [0.0087429, 0.021637299999999998, 0.045525300000000005]
    },
    {
        followers: 1000000,
        rates: [0.00701169, 0.0174356, 0.0376352]
    },
    {
        followers: 10000000,
        rates: [0.0059704975, 0.015940950000000002, 0.035808650000000004]
    },
    {
        followers: 100000000,
        rates: [0.00847671, 0.01726755, 0.036529650000000004]
    },
    {
        followers: 1000000000,
        rate: [0.014303799999999998, 0.0182666, 0.0318431]
    }
];

export const PIE_CHART_OPTION = {
    responsive: false,
    maintainAspectRatio: false,
    width: 120,
    height: 120,
    cutoutPercentage: 0,
    tooltips: {
        enabled: false
    },
    animation: {
        animateRotate: true
    }
};

export const ENGAGEMENT_CHART_OPTION = {
    responsive: false,
    maintainAspectRatio: false,
    width: 120,
    height: 120,
    cutoutPercentage: 75,
    tooltips: {
        enabled: false
    },
    animation: {
        animateRotate: true
    }
};

export const ENGAGEMENT_CHART_DATA = {
    zero: {
        percent: 0,
        datasets: [{
            backgroundColor: [ENGAGEMENT_CLASSES.poor.color, "#e6e6e6"],
            borderWidth: [0, 0],
            data: [0, 1]
        }]
    },
    poor: {
        percent: 20,
        datasets: [{
            backgroundColor: [ENGAGEMENT_CLASSES.poor.color, "#e6e6e6"],
            borderWidth: [0, 0],
            data: [0.2, 0.8]
        }]
    },
    average: {
        percent: 40,
        datasets: [{
            backgroundColor: [ENGAGEMENT_CLASSES.average.color, "#e6e6e6"],
            borderWidth: [0, 0],
            data: [0.4, 0.6]
        }]
    },
    good: {
        percent: 60,
        datasets: [{
            backgroundColor: [ENGAGEMENT_CLASSES.good.color, "#e6e6e6"],
            borderWidth: [0, 0],
            data: [0.6, 0.4]
        }]
    },
    excellent: {
        percent: 80,
        datasets: [{
            backgroundColor: [ENGAGEMENT_CLASSES.excellent.color, "#e6e6e6"],
            borderWidth: [0, 0],
            data: [0.8, 0.2]
        }]
    },
    suspicious: {
        percent: 100,
        datasets: [{
            backgroundColor: [ENGAGEMENT_CLASSES.suspicious.color, "#e6e6e6"],
            borderWidth: [0, 0],
            data: [1, 0]
        }]
    }
};
export const PROFILE_FIELD_CATEGORIES = {
    instagram: [
        { name: "contact_details", label: "Contact details" },
        { name: "profile_data", label: "Profile data" },
        { name: "metrics", label: "Metrics" },
        { name: "stats", label: "Additional stats" },
    ],
    youtube: [
        { name: "channel_data", label: "Channel data" },
        { name: "social_data", label: "Social data" },
        { name: "metrics", label: "Metrics" },
        { name: "stats", label: "Additional stats" },
        { name: "contact_details", label: "Contact details" },
    ],
    tiktok: [
        { name: "profile_data", label: "Profile data" },
        { name: "metrics", label: "Metrics" },
        { name: "stats", label: "Additional stats" },
        { name: "contact_details", label: "Contact details" },
    ],
};

export const PROFILE_FIELDS = [
    /* Instagram */
    /* Personal data */
    {
        name: "user_id",
        label: "User ID",
        category: "profile_data",
        social: SOCIALS.instagram.id
    },
    {
        name: "username",
        label: "Username",
        category: "profile_data",
        social: SOCIALS.instagram.id
    },
    {
        name: "full_name",
        label: "Full name",
        category: "profile_data",
        social: SOCIALS.instagram.id
    },
    {
        name: "biography",
        label: "Biography",
        category: "profile_data",
        social: SOCIALS.instagram.id
    },
    {
        name: "website",
        label: "Bio link",
        category: "profile_data",
        social: SOCIALS.instagram.id
    },
    {
        name: "followers",
        label: "Followers",
        category: "profile_data",
        social: SOCIALS.instagram.id
    },
    {
        name: "following",
        label: "Following",
        category: "profile_data",
        social: SOCIALS.instagram.id
    },
    {
        name: "posts",
        label: "Number of posts",
        category: "profile_data",
        social: SOCIALS.instagram.id
    },
    {
        name: "profile_pic_url",
        label: "Profile picture URL",
        category: "profile_data",
        social: SOCIALS.instagram.id
    },
    {
        name: "profile_pic_url_hd",
        label: "Profile picture URL (HD)",
        category: "profile_data",
        social: SOCIALS.instagram.id
    },
    {
        name: "is_private",
        label: "Is private?",
        category: "profile_data",
        social: SOCIALS.instagram.id
    },
    {
        name: "is_verified",
        label: "Is verified?",
        category: "profile_data",
        social: SOCIALS.instagram.id
    },
    {
        name: "deleted",
        label: "Is deleted?",
        category: "profile_data",
        social: SOCIALS.instagram.id
    },
    {
        name: "is_business_account",
        label: "Is business account?",
        category: "profile_data",
        social: SOCIALS.instagram.id
    },
    {
        name: "business_category",
        label: "Business category",
        category: "profile_data",
        social: SOCIALS.instagram.id
    },

    /* Metrics */
    {
        name: "followers_to_following",
        label: "Followers to following ratio",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "likes_per_post",
        label: "Likes per post",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "likes_per_post_image",
        label: "Likes per post (images)",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "likes_per_post_video",
        label: "Likes per post (videos)",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "likes_per_post_pct",
        label: "Likes per post (%)",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "replies_per_post",
        label: "Replies per post",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "replies_per_post_image",
        label: "Replies per post (images)",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "replies_per_post_video",
        label: "Replies per post (videos)",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "replies_per_post_pct",
        label: "Replies per post (%)",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "views_per_video_pct",
        label: "Views per video (%)",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "engagement_rate",
        label: "Engagement rate",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "engagement_rate_image",
        label: "Engagement rate (images)",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "engagement_rate_video",
        label: "Engagement rate (videos)",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "posts_per_week",
        label: "Posts per week",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "likes_variation_pct",
        label: "Likes variation (%)",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "replies_per_100_likes",
        label: "Replies per 100 likes",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "last_12_like_reply_pairs",
        label: "Last 12 likes-replies",
        category: "metrics",
        social: SOCIALS.instagram.id
    },
    {
        name: "content_type",
        label: "Content type",
        category: "metrics",
        social: SOCIALS.instagram.id
    },

    /* stats */
    {
        name: "first_name",
        label: "First name",
        category: "stats",
        social: SOCIALS.instagram.id
    },
    {
        name: "last_name",
        label: "Last name",
        category: "stats",
        social: SOCIALS.instagram.id
    },
    {
        name: "birth_year",
        label: "Birth year",
        category: "stats",
        social: SOCIALS.instagram.id
    },
    { name: "gender", label: "Gender", category: "stats", social: SOCIALS.instagram.id },
    // { name: "ethnicity", label: "Ethnicity", category: "stats" },
    {
        name: "main_language",
        label: "Main language",
        category: "stats",
        social: SOCIALS.instagram.id
    },
    {
        name: "languages",
        label: "Languages",
        category: "stats",
        social: SOCIALS.instagram.id
    },
    {
        name: "cost_post",
        label: "Cost post",
        category: "stats",
        social: SOCIALS.instagram.id
    },
    {
        name: "cost_video",
        label: "Cost video",
        category: "stats",
        social: SOCIALS.instagram.id
    },
    {
        name: "location_country",
        label: "Country",
        category: "stats",
        social: SOCIALS.instagram.id
    },
    {
        name: "location_region",
        label: "Region",
        category: "stats",
        social: SOCIALS.instagram.id
    },
    {
        name: "location_city",
        label: "City",
        category: "stats",
        social: SOCIALS.instagram.id
    },
    {
        name: "categories",
        label: "Categories",
        category: "stats",
        social: SOCIALS.instagram.id
    },
    // { name: "audience_age_gender", label: "Audience age gender", category: "stats", social: SOCIALS.instagram.id },
    // { name: "audience_languages", label: "Audience languages", category: "stats", social: SOCIALS.instagram.id },
    // { name: "audience_location", label: "Audience location", category: "stats", social: SOCIALS.instagram.id },
    // { name: "audience_type", label: "Audience type", category: "stats", social: SOCIALS.instagram.id },

    /* Contact details */
    {
        name: "emails",
        label: "Emails",
        category: "contact_details",
        social: SOCIALS.instagram.id
    },
    {
        name: "business_email",
        label: "Business email",
        category: "contact_details",
        social: SOCIALS.instagram.id
    },
    {
        name: "business_phone_number",
        label: "Business phone number",
        category: "contact_details",
        social: SOCIALS.instagram.id
    },
    {
        name: "business_address",
        label: "Business address",
        category: "contact_details",
        social: SOCIALS.instagram.id
    },

    /* Tiktok */
    /* Profile data */
    {
        name: "username",
        label: "Username",
        category: "profile_data",
        social: SOCIALS.tiktok.id
    },
    {
        name: "nickname",
        label: "Nick name",
        category: "profile_data",
        social: SOCIALS.tiktok.id
    },
    {
        name: "signature",
        label: "Signature",
        category: "profile_data",
        social: SOCIALS.tiktok.id
    },
    {
        name: "fans",
        label: "Fans",
        category: "profile_data",
        social: SOCIALS.tiktok.id
    },
    {
        name: "following",
        label: "Following",
        category: "profile_data",
        social: SOCIALS.tiktok.id
    },
    {
        name: "hearts",
        label: "Hearts",
        category: "profile_data",
        social: SOCIALS.tiktok.id
    },
    {
        name: "vdeos",
        label: "Videos",
        category: "profile_data",
        social: SOCIALS.tiktok.id
    },
    {
        name: "is_verified",
        label: "Verified",
        category: "profile_data",
        social: SOCIALS.tiktok.id
    },
    {
        name: "covers_small",
        label: "Covers small",
        category: "profile_data",
        social: SOCIALS.tiktok.id
    },
    {
        name: "covers_medium",
        label: "Covers medium",
        category: "profile_data",
        social: SOCIALS.tiktok.id
    },
    /* Metrics */
    {
        name: "hearts_per_fan",
        label: "Hearts per fan",
        category: "metrics",
        social: SOCIALS.tiktok.id
    },
    {
        name: "hearts_per_video",
        label: "Hearts per video",
        category: "metrics",
        social: SOCIALS.tiktok.id
    },
    {
        name: "fans_to_following",
        label: "Fans to following",
        category: "metrics",
        social: SOCIALS.tiktok.id
    },
    {
        name: "videos_per_week",
        label: "Videos per week",
        category: "metrics",
        social: SOCIALS.tiktok.id
    },
    {
        name: "avg_comments",
        label: "Avg comments",
        category: "metrics",
        social: SOCIALS.tiktok.id
    },
    {
        name: "avg_likes",
        label: "Avg likes",
        category: "metrics",
        social: SOCIALS.tiktok.id
    },
    {
        name: "avg_duration",
        label: "Avg duration",
        category: "metrics",
        social: SOCIALS.tiktok.id
    },
    {
        name: "avg_engagement_rate",
        label: "Avg engagement rate",
        category: "metrics",
        social: SOCIALS.tiktok.id
    },
    /* Additional stats */
    {
        name: "main_language",
        label: "Main language",
        category: "stats",
        social: SOCIALS.tiktok.id
    },
    {
        name: "languages",
        label: "Languages",
        category: "stats",
        social: SOCIALS.tiktok.id
    },
    {
        name: "country",
        label: "Country",
        category: "stats",
        social: SOCIALS.tiktok.id
    },
    {
        name: "hashtags",
        label: "Hashtags",
        category: "stats",
        social: SOCIALS.tiktok.id
    },
    /* Additional stats */
    {
        name: "emails",
        label: "Emails",
        category: "contact_details",
        social: SOCIALS.tiktok.id
    },
    /* YouTube */
    /* Channel data */
    {
        name: "id",
        label: "Channel ID",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "username",
        label: "Username",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "title",
        label: "Title",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "description",
        label: "Description",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "subscribers",
        label: "Subscribers",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "views",
        label: "Views",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "videos",
        label: "Videos",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "joined_at",
        label: "Joined at",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "country",
        label: "Country",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "is_paid",
        label: "Paid",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "is_family_safe",
        label: "Family safe",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "is_business_account",
        label: "Business account",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "is_verified",
        label: "Verified",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "keywords",
        label: "Keywords",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "avatar_url",
        label: "Avatar URL",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "tv_banner_url",
        label: "TV banner URL",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "mobile_banner_url",
        label: "Mobile banner URL",
        category: "channel_data",
        social: SOCIALS.youtube.id
    },

    /* Social data */

    {
        name: "facebook_id",
        label: "Facebook ID",
        category: "social_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "instagram_id",
        label: "Instagram ID",
        category: "social_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "twitter_id",
        label: "Twitter ID",
        category: "social_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "snapchat_id",
        label: "Snapchat ID",
        category: "social_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "twitch_id",
        label: "Twitch ID",
        category: "social_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "patreon_id",
        label: "Patreon ID",
        category: "social_data",
        social: SOCIALS.youtube.id
    },
    {
        name: "pinterest_id",
        label: "Pinterest ID",
        category: "social_data",
        social: SOCIALS.youtube.id
    },

    /* Metrics */

    {
        name: "views_per_subscriber",
        label: "Views per subscriber",
        category: "metrics",
        social: SOCIALS.youtube.id
    },
    {
        name: "views_per_video",
        label: "Views per video",
        category: "metrics",
        social: SOCIALS.youtube.id
    },
    {
        name: "videos_per_week",
        label: "Videos per week",
        category: "metrics",
        social: SOCIALS.youtube.id
    },
    {
        name: "avg_views",
        label: "Avg views",
        category: "metrics",
        social: SOCIALS.youtube.id
    },

    {
        name: "avg_comments",
        label: "Avg comments",
        category: "metrics",
        social: SOCIALS.youtube.id
    },
    {
        name: "avg_likes",
        label: "Avg likes",
        category: "metrics",
        social: SOCIALS.youtube.id
    },
    {
        name: "avg_dislikes",
        label: "Avg dislikes",
        category: "metrics",
        social: SOCIALS.youtube.id
    },
    {
        name: "avg_reactions",
        label: "Avg reactions",
        category: "metrics",
        social: SOCIALS.youtube.id
    },
    {
        name: "avg_duration",
        label: "Avg duration",
        category: "metrics",
        social: SOCIALS.youtube.id
    },
    {
        name: "avg_likes_pct",
        label: "Avg likes in percent",
        category: "metrics",
        social: SOCIALS.youtube.id
    },
    {
        name: "avg_views_per_reaction",
        label: "Avg views per reaction",
        category: "metrics",
        social: SOCIALS.youtube.id
    },
    {
        name: "avg_views_per_comment",
        label: "Avg views per comment",
        category: "metrics",
        social: SOCIALS.youtube.id
    },
    {
        name: "avg_engagement_rate",
        label: "Avg engagement rate",
        category: "metrics",
        social: SOCIALS.youtube.id
    },

    /* Additional stats */

    {
        name: "main_category",
        label: "Main category",
        category: "stats",
        social: SOCIALS.youtube.id
    },
    {
        name: "categories",
        label: "Categories",
        category: "stats",
        social: SOCIALS.youtube.id
    },
    {
        name: "main_language",
        label: "Main language",
        category: "stats",
        social: SOCIALS.youtube.id
    },
    {
        name: "languages",
        label: "Languages",
        category: "stats",
        social: SOCIALS.youtube.id
    },
    /* Contact details */

    {
        name: "emails",
        label: "Emails",
        category: "contact_details",
        social: SOCIALS.youtube.id
    },
];

export const PAGE_SIZES = [
    { label: "10 per Page", value: 10 },
    { label: "20 per Page", value: 20 },
    { label: "50 per Page", value: 50 }
];

export const ORDER_DIRECTIONS = [
    { label: "Descending", value: "descending" },
    { label: "Ascending", value: "ascending" }

];

export const ORDER_BY_ITEMS = [
    { label: "Last updated", value: "updated_at" },
    { label: "Name", value: "name" }
];

export const GENDERS = [ // 0 - famale, 1 - male, 2 - any
    { label: "Female", id: "female" },
    { label: "Male", id: "male" },
    { label: "Any", id: "any" },
];

export const MONTH_NAMES = [
    { full: "January", short: "Jan", number: "1" },
    { full: "February", short: "Feb", number: "2" },
    { full: "March", short: "Mar", number: "3" },
    { full: "April", short: "Apr", number: "4" },
    { full: "May", short: "May", number: "5" },
    { full: "June", short: "Jun", number: "6" },
    { full: "July", short: "Jul", number: "7" },
    { full: "August", short: "Aug", number: "8" },
    { full: "September", short: "Sep", number: "9" },
    { full: "October", short: "Oct", number: "10" },
    { full: "November", short: "Nov", number: "11" },
    { full: "December", short: "Dec", number: "12" }
];

export const COLORS_LIST = [
    { id: "red", label: "Red", value: "#f44336" },
    { id: "pink", label: "Pink", value: "#E91E63" },
    { id: "purple", label: "Purple", value: "#9C27B0" },
    { id: "deep_purple", label: "Deep purple", value: "#673AB7" },
    { id: "indigo", label: "Indigo", value: "#3F51B5" },
    { id: "blue", label: "Blue", value: "#2196F3" },
    { id: "light_blue", label: "Light blue", value: "#03A9F4" },
    { id: "cyan", label: "Cyan", value: "#00BCD4" },
    { id: "teal", label: "Teal", value: "#009688" },
    { id: "green", label: "Green", value: "#4CAF50" },
    { id: "light_grean", label: "Light green", value: "#8BC34A" },
    { id: "lime", label: "Lime", value: "#CDDC39" },
    { id: "yellow", label: "Yellow", value: "#FFEB3B" },
    { id: "amber", label: "Amber", value: "#FFC107" },
    { id: "orange", label: "Orange", value: "#FF9800" },
    { id: "deep_orange", label: "Deep orange", value: "#FF5722" },
    { id: "brown", label: "Brown", value: "#795548" },
    { id: "gray", label: "Gray", value: "#9E9E9E" },
    { id: "blue_gray", label: "Blue gray", value: "#607D8B" }
];

export const CAMPAIGN_LEAD_CONTENT = {
    email: "email",
    contract: "contract",
    coupon: "coupon",
    notes: "notes",
    tracking: "tracking",
    pricing: "pricing"
};

export const MESSAGE_TYPES = {
    error: "error",
    success: "success"
};

/* campaign tracking panel */
export const CAMPAIGN_TRACKING_METRICS = [
    { name: "engagements", label: "Engagements", background_color: "transparent", primary_color: "#A7E8F0", show: false },
    { name: "likes", label: "Likes", background_color: "transparent", primary_color: "#FF6F00", show: true },
    { name: "video_views", label: "Video views", background_color: "transparent", primary_color: "#673AB7", show: false },
];

export const CAMPAIGN_SALES_METRICS = [
    { name: "gross_revenue", label: "Revenue", background_color: "transparent", primary_color: "#00BCD4", show: true },
    { name: "sales", label: "Sales", background_color: "transparent", primary_color: "#2A7FE8", show: false },
    { name: "total_refunded", label: "Total refunded", background_color: "transparent", primary_color: "#FF6F00", show: false },
    { name: "refunds", label: "Refunds", background_color: "transparent", primary_color: "#673AB7", show: false }

];

export const CAMPAIGN_CHART_TYPES = [
    { name: "cumulative", label: "Cumulative" },
    { name: "instant", label: "Instant" }
];

export const CAMPAIGN_CHART_PERIODS = [
    { label: "1D", value: 24 * 60 * 60, resolution: "hour" },
    { label: "1W", value: 7 * 24 * 60 * 60, resolution: "hour" },
    { label: "1M", value: 30 * 24 * 60 * 60, resolution: "day" },
    { label: "3M", value: 3 * 30 * 24 * 60 * 60, resolution: "day" },
    { label: "6M", value: 182 * 24 * 60 * 60, resolution: "day" },
    { label: "1Y", value: 365 * 24 * 60 * 60, resolution: "week" },
    { label: "MAX", value: null, resolution: "week" }
];

/* settings menu items and url params */
export const SETTINGS_ITEMS = {
    my_account: { label: "My Account", value: "my_account", scope: "" },
    team: { label: "Team", value: "team", scope: "team:view" },
    invite_team: { label: "Invite team", value: "invite", scope: "team:full" },
    billing: { label: "Billing", value: "billing", scope: "billing:full" },
    plans: { label: "Plans", value: "plans", scope: "billing:full" },
    credit_usage: { label: "Credit usage", value: "credit_usage", scope: "billing:full" },
    customization: { label: "Customization", value: "variables", scope: "customization:full" },
    integrations: { label: "E-commerce integrations", value: "integrations", scope: "integrations:view" },
    emails: { label: "Emails", value: "email", scope: "integrations:view" },
    notifications: { label: "Notification channels", value: "notifications", scope: "integrations:view" },
    buy_credits: { label: "Buy credits", value: "buy_credits", scope: "billing:full" },
    set_plan: { label: "Select plans", value: "set_plan", scope: "billing:full" },
    add_card: { label: "Add payment method", value: "add_card", scope: "billing:full" },
    add_integration: { label: "Add integration", value: "new", scope: "integrations:full" },
    update_password: { label: "Update password", value: "update_password", scope: "" },
}

export const ACCOUNT_ACCESS = [{
        id: "no",
        label: "No access"
    },
    {
        id: "view",
        label: "View access"
    },
    {
        id: "full",
        label: "Full access"
    }
]
export const ACCOUNT_SCOPES = [{
        id: "team",
        label: "Team",
        access: [0, 1, 2],
        description: [
            "view existing team members",
            "view and edit team members and their permissions, invite new team members",
        ]
    },
    {
        id: "billing",
        label: "Billing",
        access: [0, 2],
        description: [
            "view and add payment methods, subscribe to plans",
        ]
    },
    {
        id: "integrations",
        label: "Integrations",
        access: [0, 1, 2],
        description: [
            "view existing integrations (e-commerce, notification channels, connected emails)",
            "add and remove integrations",
        ]
    },
    {
        id: "search",
        label: "Searching",
        access: [0, 2],
        description: [
            "search influencers",
        ]
    },
    {
        id: "campaigns",
        label: "Campaigns",
        access: [0, 1, 2],
        description: [
            "view existing campaigns, their pipeline and the tracking statistics",
            "add and remove influencers, create and activate campaigns",
        ]
    },
    {
        id: "exports",
        label: "Exports",
        access: [0, 2],
        description: [
            "export data from the application and pay for it with existing payment methods",
        ]
    },
    {
        id: "emails",
        label: "Emails",
        access: [0, 1, 2],
        description: [
            "view email conversations",
            "view and reply to email conversations",
        ]
    },
];


export const ACCOUNT_PERMISSION_PRESETS = [{
        id: "admin",
        label: "Admin",
        scopes: [
            "team:full",
            "billing:full",
            "integrations:full",
            "search:full",
            "campaigns:full",
            "exports:full",
            "emails:full",
        ]
    },
    {
        id: "va",
        label: "Virtual assistant",
        scopes: [
            "search:full",
            "campaigns:full",
            "emails:full",
        ]
    },
];

export const ACCOUNT_ROLES = [
   "Viewer", "Editor", "Manager"
]

/* credit usage types */
export const CREDIT_USAGE_TYPES = {
    tracking: "Tracking",
    export: "Export",
    instagram_search: "Instagram Search",
    instagram_profile: "Instagram Profiles",
    youtube_search: "YouTube Search",
    youtube_profile: "YouTube Channels",
    tiktok_search: "TikTok Search",
    tiktok_profile: "TikTok Profiles",
}

/* emails list */
export const AVAILABLE_EMAILS = {
    outlook: {
        id: "outlook",
        name: "Outlook",
        image: "/app/img/icons/common/outlook.svg",
    },
    gmail: {
        id: "gmail",
        name: "Gmail",
        image: "/app/img/icons/common/gmail.svg",
    },
}

/* notifications list */
export const AVAILABLE_NOTIFICATIONS = {
    slack: {
        id: "slack",
        name: "Slack",
        image: "/app/img/icons/common/slack.svg"
    }
}

/* integrations list */
export const AVAILABLE_INTEGRATIONS = {
    woocommerce: {
        id: "woocommerce",
        name: "WooCommerce",
        image: "/app/img/icons/common/woocommerce.svg",
        type: 1, //the user inputs a URL, then clicks a button
        domains: []
    },
    shopify: {
        id: "shopify",
        name: "Shopify",
        image: "/app/img/icons/common/shopify.svg",
        domains: [],
        type: 3, //shopify
    },
    ecwid: {
        name: "Ecwid",
        id: "ecwid",
        image: "/app/img/icons/common/ecwid.svg",
        domains: []
    },
    "3dcart": {
        name: "3dcart",
        id: "3dcart",
        image: "/app/img/icons/common/3dcart.svg",
        input_label: "You will find your store URL in Settings > General > Secure URL. You will be redirected there: if the page does not exist double-check the URL you have provided.",
        domains: ["3dcart.net"],
        type: 1, //the user inputs a URL, then clicks a button
    },
    bigcartel: {
        id: "bigcartel",
        name: "Big Cartel",
        image: "/app/img/icons/common/bigcartel.svg",
        domains: [],
    }
}

/* comming soon integrations list */
export const COMING_SOON_INTEGRATIONS = {
    bigcommerce: {
        id: "bigcommerce",
        name: "BigCommerce",
        image: "/app/img/icons/common/bigcommerce.svg",
        domains: [],
        type: 1, //the user inputs a URL, then clicks a button
    },
    magento: {
        id: "magento",
        name: "Magento",
        image: "/app/img/icons/common/magento.svg",
        domains: [],
        type: 2, //the user inputs some values
    },
    prestashop: {
        id: "prestashop",
        name: "PrestaShop",
        image: "/app/img/icons/common/prestashop.svg",
        domains: []
    },
    volusion: {
        id: "volusion",
        name: "Volusion",
        image: "/app/img/icons/common/volusion.svg",
        domains: []
    },
    squarespace: {
        id: "squarespace",
        name: "Squarespace",
        image: "/app/img/icons/common/squarespace.svg",
        domains: [],
    },
    wix: {
        id: "wix",
        name: "Wix",
        image: "/app/img/icons/common/wix.svg",
        domains: [],
    },
    weebly: {
        id: "weebly",
        name: "Weebly",
        image: "/app/img/icons/common/weebly.svg",
        domains: [],
    },
    ekm: {
        id: "ekm",
        name: "EKM",
        image: "/app/img/icons/common/ekm.svg",
        domains: [],
    },
    xcart: {
        id: "xcart",
        name: "X-cart",
        image: "/app/img/icons/common/xcart.svg",
        domains: [],
    },
    bluepark: {
        id: "bluepark",
        name: "Bluepark",
        image: "/app/img/icons/common/bluepark-temp.gif",
        domains: [],
    },
    "1-1-ionos": {
        id: "1-1-ionos",
        name: "1&1 IONOS",
        image: "/app/img/icons/common/1-1-ionos.svg",
        domains: [],
    },
    shopwired: {
        id: "shopwired",
        name: "ShopWired",
        image: "/app/img/icons/common/shopwired.png",
        domains: [],
    },
    "123-reg": {
        id: "123-reg",
        name: "123Reg",
        image: "/app/img/icons/common/123-reg.png",
        domains: [],
    },

}